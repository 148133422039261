<template>
  <ul class="tabs">
    <li
      v-for="(tab, i) in tabs"
      :key="`${tab.name}-${i}`"
      :class="{ active: tab.name === value }"
      class="tabs-item"
    >
      <button class="button" @click="$emit('input', tab.name)">
        {{ tab.label }}
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped lang="scss">
.tabs {
  @apply flex items-center justify-center text-xs;

  &-item {
    @apply mx-4;
    .button {
      @apply uppercase font-semibold cursor-pointer;
    }

    &.active {
      @apply text-primary;
    }
  }
}
</style>
