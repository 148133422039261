<template>
  <div>
    <report-table
      :query="{ brandId: id, placeIds: [placeId] }"
      hide-checkbox
      hide-edit-action
      class="my-4"
      @open="handleReportClick"
    />
    <div class="text-center">
      <btn
        class="w-50 secondary"
        @click="
          $router.push({
            name: 'Reports',
          })
        "
      >
        {{ $t(`place-dashboard.all-reports`) }}
      </btn>
    </div>
  </div>
</template>

<script>
import { ReportTable } from '@/views/Brand/Applications/Report/_components/ReportTable';
import Btn from '@/components/Button/Button';
import { ClientRouteName } from '@/router/router.constants';

export default {
  name: 'ReportsTable',
  components: {
    ReportTable,
    Btn,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    placeId: {
      type: String,
      required: true,
    },
  },
  setup(_, ctx) {
    const handleReportClick = (reportId) => {
      ctx.root.$router.push({
        name: ClientRouteName.REPORT_DETAILS,
        params: { reportId },
      });
    };

    return { handleReportClick };
  },
};
</script>
