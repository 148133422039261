import { computed, onBeforeUnmount, ref, } from '@vue/composition-api';
import { updateOffer } from '@/api/requests/offers';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { useMessageFiles } from '@/views/Brand/Applications/Report/_composables/use-message-files';
import { destroyPlaceResourcesStore, usePlaceResourcesStore, } from '@/store/place-resources';
import { destroyObjectResourcesStore, useObjectResourcesStore, } from '@/store/object-resources';
import { isMessageDataEmpty } from '@/views/Brand/Applications/Report/_utils/message';
import { addReportMessage } from '@/api/requests/reports';
import { mapId } from '@/util/common';
import { EMPTY_WYSIWYG_VALUE } from '@/components/WysiwygInput/wysiwyg.constants';
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { useBrand } from '@/composables';
const { useGetters } = createNamespacedHelpers('user');
export const useReportChatMessage = (props, ctx) => {
    const { reportId, messages, validateActionRequiredUser, requireActionUser, disableActionRequiredMode, reportMessage, } = props;
    const message = ref('');
    const selectedOffer = ref(null);
    const showOfferModal = ref(false);
    const showAddAssetsBottomMenu = ref(false);
    const fileTokens = ref([]);
    const { brand } = useBrand();
    const { isContractorUser, isSystemAdmin } = useGetters([
        'isContractorUser',
        'isSystemAdmin',
    ]);
    const canAddOffer = computed(() => isContractorUser.value || isSystemAdmin.value);
    const selectOffer = (offer) => {
        selectedOffer.value = offer;
        showOfferModal.value = false;
        showAddAssetsBottomMenu.value = false;
    };
    const getMentionSuggestions = createMentionUserFn({
        accessToBrandId: brand.value.id,
    });
    const { files, handleAddFile, handleRemoveFile, getFileTokens, uploadingFiles, clearUploadedTokens, clearFiles, } = useMessageFiles(ctx, reportMessage);
    const { resolvedPlaces, resetPlacesState } = usePlaceResourcesStore();
    const { resolvedObjects, resetObjectsState } = useObjectResourcesStore();
    resetPlacesState();
    resetObjectsState();
    const isMessageEmpty = computed(() => {
        return isMessageDataEmpty({
            message,
            files,
            places: resolvedPlaces,
            objects: resolvedObjects,
        });
    });
    const setFileTokens = (tokens) => (fileTokens.value = tokens);
    const sendingInProgress = ref(false);
    const handleMessageSend = async () => {
        if (!validateActionRequiredUser.value) {
            return ctx.root.$toast.error(ctx.root.$t('report.missing-action-required-user'));
        }
        sendingInProgress.value = true;
        const offerId = selectedOffer.value?.id || undefined;
        const createdMessage = await addReportMessage({
            reportId: reportId.value,
            message: message.value,
            fileTokens: fileTokens.value,
            placeIds: mapId(resolvedPlaces.value),
            objectIds: mapId(resolvedObjects.value),
            ...(requireActionUser.value && {
                requiredActionUserId: requireActionUser.value.id,
            }),
            offerId,
        });
        if (offerId !== undefined) {
            await updateOffer(offerId, { reportId: reportId.value });
        }
        disableActionRequiredMode();
        clearUploadedTokens();
        messages.value = [...messages.value, createdMessage];
        message.value = EMPTY_WYSIWYG_VALUE;
        selectedOffer.value = null;
        showOfferModal.value = false;
        clearFiles();
        resetPlacesState();
        resetObjectsState();
        sendingInProgress.value = false;
        requireActionUser.value = null;
    };
    const onMessageUpdated = (updatedMessage) => {
        const updatedMessageIndex = messages.value.findIndex(({ id }) => id === updatedMessage.id);
        Vue.set(messages.value, updatedMessageIndex, updatedMessage);
    };
    const onMessageDeleted = (deletedMessageId) => {
        messages.value = messages.value.filter(({ id }) => id !== deletedMessageId);
    };
    onBeforeUnmount(() => {
        destroyPlaceResourcesStore();
        destroyObjectResourcesStore();
    });
    return {
        message,
        showAddAssetsBottomMenu,
        selectedOffer,
        showOfferModal,
        isMessageEmpty,
        getMentionSuggestions,
        handleMessageSend,
        onMessageUpdated,
        onMessageDeleted,
        files,
        handleAddFile,
        handleRemoveFile,
        canAddOffer,
        selectOffer,
        sendingInProgress,
        getFileTokens,
        uploadingFiles,
        setFileTokens,
    };
};
