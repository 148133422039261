import { computed, defineComponent } from '@vue/composition-api';
import PlacePopup from './PlacePopup.vue';
import ObjectPopup from './ObjectPopup.vue';
export default defineComponent({
    components: {
        PlacePopup,
        ObjectPopup,
    },
    props: {
        type: String,
        id: String,
        hidePreviewButton: Boolean,
        close: Function,
    },
    setup(props) {
        const contentType = computed(() => {
            return `${props.type}Popup`;
        });
        return { contentType };
    },
});
