<template>
  <div>
    <template v-if="objectFormOpened">
      <offer-object-tab
        ref="objectForm"
        :edited-content="editedContent"
        :selected-object-type="selectedObjectType"
        :loading="loadingObject"
        :report-id="report ? report.id : ''"
        @close-object-tab="closeObjectTab"
        @submit="(data, tags) => handleObjectSubmission(data, tags, true)"
      />
    </template>
    <template v-else>
      <div v-show="activeTab === PLACE_TAB">
        <offer-place-filters @filter-places="placeFilters = $event" />
        <offer-places
          :is-selected="
            (place) =>
              isIncludedInOffer(place, selectedPlaceIds, removedPlaceIds)
          "
          :query="query"
          class="mt-4"
          @select="onSelectPlaces([$event])"
        />
      </div>
      <div v-show="activeTab === OBJECT_TAB">
        <offer-objects
          :is-included-in-offer="isIncludedInOffer"
          :columns="objectColumns"
          :edit="edit"
          :force-update="forceUpdateObjects"
          :report="report"
          @select-object="onSelectObjects"
          @open-object-tab="openObjectTab"
          @edit-object="editObject"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import offerObjectsMixin from '@/views/Brand/Applications/mixins/offerObjects';
import { PLACE_TAB, OBJECT_TAB, BTN_THEMES } from '@/store/modules/ui';
import OfferObjectTab from '@/views/Brand/Applications/Components/Offers/OfferObjectTab';
import OfferObjects from '@/views/Brand/Applications/Components/Offers/OfferObjects';
import OfferPlaceFilters from '@/views/Brand/Applications/Components/Offers/OfferPlaceFilters';
import {
  TABLE_STYLE,
  EXPAND_BUTTON_POSITION,
  EXPAND_BUTTON_STYLE,
} from '@/constants/ui';
import OfferPlaces from '@/views/Brand/Applications/Components/Offers/OfferPlaces';

export default {
  components: {
    OfferPlaces,
    OfferPlaceFilters,
    OfferObjects,
    OfferObjectTab,
  },
  props: {
    placeColumns: {
      type: Object,
      default: () => ({}),
    },
    objectColumns: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      default: () => ({}),
    },
    activeTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      TABLE_STYLE,
      EXPAND_BUTTON_POSITION,
      EXPAND_BUTTON_STYLE,
      PLACE_TAB,
      OBJECT_TAB,
      BTN_THEMES,
      allPlaces: [],
      placeFilters: {},
    };
  },
  mixins: [offerObjectsMixin],
  computed: {
    ...mapGetters('attachment', ['uploads']),
    ...mapState('offer', {
      offer: 'currentOffer',
    }),
    ...mapState('offerResources', ['forceCheckboxUpdate']),
    ...mapGetters('brand', ['currentBrandId']),
    ...mapGetters('offerResources', [
      'selectedPlaceIds',
      'selectedObjectIds',
      'removedPlaceIds',
      'removedObjectIds',
    ]),
    query() {
      return {
        ...this.placeFilters,
        brandId: this.currentBrandId(this.$route),
        ...(this.edit && { assignedOfferId: this.offer.id }),
      };
    },
  },
  methods: {
    ...mapActions('offerResources', [
      'updateObjectSelection',
      'updatePlaceSelection',
    ]),
    isIncludedInOffer(item, addedIds, removedIds) {
      if (this.edit) {
        return (
          (item.isAssignedToOffer && !removedIds.includes(item.id)) ||
          addedIds.includes(item.id)
        );
      }

      return addedIds.includes(item.id);
    },
    isCurrentlySelected(item, addedIds, removedIds) {
      return (
        addedIds.includes(item.id) ||
        (!!item.isAssignedToOffer && !removedIds.includes(item.id))
      );
    },
    onSelectItems({ items, pageItems, callback, addedIds, removedIds }) {
      if (items.length === 0) {
        pageItems.forEach((item) => {
          callback({ item, selected: false });
        });
      }

      const selectAll = items.length > 1;

      items.forEach((item) => {
        callback({
          item,
          selected:
            selectAll || !this.isCurrentlySelected(item, addedIds, removedIds),
        });
      });
    },
    onSelectObjects(items, pageItems = []) {
      this.onSelectItems({
        items,
        pageItems,
        callback: this.updateObjectSelection,
        addedIds: this.selectedObjectIds,
        removedIds: this.removedObjectIds,
      });
    },
    onSelectPlaces(items) {
      this.onSelectItems({
        items,
        pageItems: this.allPlaces,
        callback: this.updatePlaceSelection,
        addedIds: this.selectedPlaceIds,
        removedIds: this.removedPlaceIds,
      });
    },
  },
};
</script>
