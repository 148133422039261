import { NotificationType, } from '@/api/requests/notifications';
import VueI18n from '@/i18n';
import { handleUpdatedReportStatusNotification, handleAddedNewStructureNotification, handleTaggedInCommentNotification, handleAddedNewCommentNotification, handleOfferNotification, handlePlaceCreatedNotification, handleObjectCreatedNotification, handleObjectStatusChangedNotification, handleUserAssignedToReportNotification, handleUserMarkedInReportMessageNotification, handleOfferAssignedToReportNotification, handleAddNewReportMessage, handleAddRequiredActionMessage, } from '@/layouts/_components/Notifications/_util/notifications';
import { handleAnsweredRequiredActionMessage } from '@/layouts/_components/Notifications/_util/notifications/answered-required-action-message';
export const handleTranslations = (notificationType, translationPlaceholders) => {
    return {
        title: VueI18n.t(`notifications.${notificationType}.title`, translationPlaceholders).toString(),
        body: VueI18n.t(`notifications.${notificationType}.body`, translationPlaceholders).toString(),
    };
};
export const parseNotification = (notification) => {
    switch (notification.type) {
        case NotificationType.UPDATED_REPORT_STATUS: {
            return handleUpdatedReportStatusNotification(notification);
        }
        case NotificationType.ADDED_NEW_STRUCTURE: {
            return handleAddedNewStructureNotification(notification);
        }
        case NotificationType.TAGGED_IN_COMMENT: {
            return handleTaggedInCommentNotification(notification);
        }
        case NotificationType.ADDED_NEW_COMMENT: {
            return handleAddedNewCommentNotification(notification);
        }
        case NotificationType.CREATED_OFFER:
        case NotificationType.UPDATED_OFFER:
        case NotificationType.UPDATED_OFFER_STATUS: {
            return handleOfferNotification(notification);
        }
        case NotificationType.PLACE_CREATED: {
            return handlePlaceCreatedNotification(notification);
        }
        case NotificationType.OBJECT_CREATED: {
            return handleObjectCreatedNotification(notification);
        }
        case NotificationType.OBJECT_ACCEPTED:
        case NotificationType.OBJECT_REVERTED:
        case NotificationType.OBJECT_REJECTED: {
            return handleObjectStatusChangedNotification(notification);
        }
        case NotificationType.USER_ASSIGNED_TO_REPORT: {
            return handleUserAssignedToReportNotification(notification);
        }
        case NotificationType.USER_MARKED_IN_REPORT_MESSAGE: {
            return handleUserMarkedInReportMessageNotification(notification);
        }
        case NotificationType.OFFER_ASSIGNED_TO_REPORT: {
            return handleOfferAssignedToReportNotification(notification);
        }
        case NotificationType.ADDED_NEW_REPORT_MESSAGE:
            return handleAddNewReportMessage(notification);
        case NotificationType.ADDED_REQUIRED_ACTION_MESSAGE:
            return handleAddRequiredActionMessage(notification);
        case NotificationType.ANSWERED_REQUIRED_ACTION_MESSAGE:
            return handleAnsweredRequiredActionMessage(notification);
        default: {
            throw new Error('Notification handler not found');
        }
    }
};
