export const USER_STATUS_MAP = {
  ACTIVE: 'registered',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
};

export const USER_STATUS_COLORS = {
  [USER_STATUS_MAP.ACTIVE]: 'bg-green',
  [USER_STATUS_MAP.INACTIVE]: 'bg-gray-dark',
  [USER_STATUS_MAP.ARCHIVED]: 'bg-gray-dark',
};
