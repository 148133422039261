import { defineComponent } from '@vue/composition-api';
import CollapseMenuItem from '@/components/CollapseMenu/CollapseMenuItem.vue';
export default defineComponent({
    components: { CollapseMenuItem },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
});
