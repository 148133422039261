import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import { defineComponent } from '@vue/composition-api';
import { OfferObjectStatus, } from '@/api/requests/offers';
import { Btn, BtnTheme } from '@/components/Button';
import { useObjectAccept } from '@/composables';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { Icon } from '@/components/Icon';
import { mdiRestore } from '@mdi/js';
export default defineComponent({
    name: 'ObjectAcceptButton',
    components: { LoadingSpinner, Btn, ConfirmModal, Icon },
    props: {
        offerId: { type: String, required: true },
        level: {
            type: String,
            required: true,
        },
        relation: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        return {
            ...useObjectAccept(ctx, {
                relation: props.relation,
                level: props.level,
            }),
            OfferObjectStatus,
            BtnTheme,
            mdiRestore,
        };
    },
});
