export const PAGE_404 = '404 Page';
export const PAGE_500 = '500 Page';

export default [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
    meta: {
      isAuthForbidden: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword.vue'
      ),
    meta: {
      isAuthForbidden: true,
    },
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () =>
      import(/* webpackChunkName: "NewPassword" */ '@/views/NewPassword.vue'),
    meta: {
      isAuthForbidden: true,
    },
  },
  {
    path: '/account-not-configured',
    name: 'AccountNotConfigured',
    component: () =>
      import(
        /* webpackChunkName: "AccountNotConfigured" */ '@/views/AccountNotConfigured.vue'
      ),
    meta: {
      isAuthRequired: true,
    },
  },
  {
    path: '/500',
    name: PAGE_500,
    component: () =>
      import(/* webpackChunkName: "ErrorPage" */ '@/views/static/500.vue'),
    meta: {
      group: 'InternalError',
    },
  },
  {
    path: '**',
    name: PAGE_404,
    component: () =>
      import(/* webpackChunkName: "ErrorPage" */ '@/views/static/404.vue'),
    meta: {
      group: 'notFound',
    },
  },
];
