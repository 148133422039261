export const createReportDetailsRoute = (brandId, reportId) => ({
    name: 'ReportDetails',
    params: {
        id: brandId,
        reportId,
    },
});
export const createStructuresRoute = (brandId) => ({
    name: 'Structures',
    params: {
        id: brandId,
    },
});
export const createObjectDetailsRoute = (brandId, objectId, objectTypeId) => ({
    name: 'ObjectDetails',
    params: {
        id: brandId,
        objectId,
        objectTypeId,
    },
});
export const createOfferDetailsRoute = (brandId, offerId) => ({
    name: 'OfferDetails',
    params: {
        id: brandId,
        offerId,
    },
});
export const createPlaceDetailsRoute = (brandId, placeId) => ({
    name: 'PlaceDetails',
    params: {
        id: brandId,
        placeId,
    },
});
export const createObjectInOfferDetailsRoute = (brandId, objectId, objectTypeId, offerId) => ({
    name: 'ObjectInOfferDetails',
    params: {
        id: brandId,
        objectId,
        objectTypeId,
        offerId,
    },
});
export const isRouteName = (ctx, routeName) => ctx.root.$route.name === routeName;
