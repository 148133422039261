<template>
  <object-details-template offer-object>
    <template #header>
      <object-offer-header
        v-if="currentOffer"
        :content="object"
        :offer="currentOffer"
      />
    </template>
  </object-details-template>
</template>

<script>
import objectStatus from '@/views/Brand/Objects/mixins/objectStatus';
import {
  fetchObjectData,
  fetchOfferData,
} from '@/views/Brand/Objects/mixins/fetchObjectData';
import VueStore from '@/store';
import { mapState } from 'vuex';
import ObjectOfferHeader from '@/views/Brand/Objects/_components/Offer/ObjectOfferHeader';
import ObjectDetailsTemplate from '@/views/Brand/Objects/Components/ObjectDetailsTemplate';

export default {
  components: {
    ObjectDetailsTemplate,
    ObjectOfferHeader,
  },
  mixins: [objectStatus],
  computed: {
    ...mapState('offer', ['currentOffer']),
    ...mapState('object', { object: 'details' }),
  },
  async beforeRouteEnter(to, from, next) {
    await fetchObjectData(to);
    await fetchOfferData(to);

    next();
  },
  async beforeRouteUpdate(to, from, next) {
    await fetchOfferData(to);
    await fetchObjectData(to);

    next();
  },
  async beforeRouteLeave(to, from, next) {
    if (to.meta.matchName !== 'Offers') {
      await VueStore.dispatch('offer/clearState');
    }

    next();
  },
};
</script>
