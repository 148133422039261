var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"message",class:_vm.resolveMessageStyle,on:{"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},_vm.onLongPressHandlers),[(_vm.isRequiredActionMessage)?_c('action-required-info',{staticClass:"md:pl-12 pb-2"}):_vm._e(),(_vm.displayAuthor)?_c('chat-user-area',{staticClass:"mb-2",attrs:{"updated-at":new Date(_vm.message.updatedAt),"user":_vm.message.author}}):_vm._e(),(!_vm.isEditingThisMessage)?_c('wysiwyg-input',{staticClass:"message-content",attrs:{"dense":"","readonly":"","hide-toolbar":"","borderless":"","content-class":"py-0.5 px-0 min-h-0","transparent":"","get-mention-suggestions":_vm.getMentionSuggestions},model:{value:(_vm.message.message),callback:function ($$v) {_vm.$set(_vm.message, "message", $$v)},expression:"message.message"}},[_c('message-resources-display',{attrs:{"message":_vm.message},on:{"image-load":function($event){return _vm.$emit('additional-content-loaded')}}})],1):[(_vm.isRequiredActionMessage && !_vm.requireActionUser)?_c('action-required-users-select',{staticClass:"ml-12",model:{value:(_vm.requireActionUser),callback:function ($$v) {_vm.requireActionUser=$$v},expression:"requireActionUser"}}):_vm._e(),_c('wysiwyg-input',{staticClass:"md:ml-12",class:{ 'border border-red': _vm.isRequiredActionMessage },attrs:{"height":_vm.WysiwygInputHeight.ONE_LINE,"get-mention-suggestions":_vm.getMentionSuggestions,"content-class":"py-2 px-4","content-limit":""},on:{"add-file":_vm.handleAddFile},scopedSlots:_vm._u([(!_vm.isMobileView)?{key:"toolbar-append",fn:function(){return [_c('chat-message-edit-buttons',{attrs:{"disable-save-btn":_vm.isMessageEmpty ||
            _vm.uploadInProgress ||
            (_vm.isRequiredActionMessage && !_vm.requireActionUser)},on:{"cancel":function($event){return _vm.onEditCancel()},"save":function($event){return _vm.onEditSave()}}})]},proxy:true}:null],null,true),model:{value:(_vm.editedMessage.message),callback:function ($$v) {_vm.$set(_vm.editedMessage, "message", $$v)},expression:"editedMessage.message"}},[_c('message-resources',{attrs:{"files":_vm.files,"uploading-files":_vm.uploadingFiles},on:{"remove-file":_vm.handleRemoveFile}})],1),(_vm.isMobileView)?_c('chat-message-edit-buttons',{staticClass:"text-right mb-3",attrs:{"disable-save-btn":_vm.isMessageEmpty ||
        _vm.uploadInProgress ||
        (_vm.isRequiredActionMessage && !_vm.requireActionUser)},on:{"cancel":function($event){return _vm.onEditCancel()},"save":function($event){return _vm.onEditSave()}}}):_vm._e()],(!_vm.isEditingThisMessage && _vm.isMessageAuthor)?_c('additional-message-actions',{attrs:{"messages":_vm.messages,"is-hovered":_vm.isHovered,"is-required-action-message":_vm.isRequiredActionMessage,"is-authorized-to-create-action-required":_vm.isAuthorizedToCreateActionRequired,"disable-delete":_vm.disableDelete,"show-mobile-menu":_vm.showEditMessageMenu},on:{"close-mobile-message-menu":function($event){_vm.showEditMessageMenu = false},"create-required-action":_vm.onRequiredActionCreate,"delete-required-action":_vm.onRequiredActionDelete,"delete-message":_vm.onMessageDelete,"on-edit":_vm.onEditClick}}):_vm._e(),(_vm.isRequiredActionMessage && _vm.requireActionUser)?_c('action-required-user',{ref:"actionRequiredElement",staticClass:"md:pl-12 py-2",attrs:{"is-edit":_vm.isEditingThisMessage,"user":_vm.requireActionUser,"created-at":_vm.message.requiredActionMarkedAt},on:{"close":function($event){_vm.requireActionUser = null}}}):_vm._e(),(!_vm.isMobileView)?_c('action-required-response-input',{attrs:{"is-required-action-message":_vm.isRequiredActionMessage,"get-mention-suggestions":_vm.getMentionSuggestions,"is-required-action-user":_vm.isRequiredActionUser,"report":_vm.report,"input-class":"md:ml-12"},on:{"add-response":_vm.addResponseToActionRequired,"file-tokens-update":function($event){_vm.responseFileTokens = $event}},model:{value:(_vm.requireActionUserResponseMessage),callback:function ($$v) {_vm.requireActionUserResponseMessage=$$v},expression:"requireActionUserResponseMessage"}}):(
      _vm.isRequiredActionMessage &&
      !_vm.hideAddResponseButton &&
      _vm.report.requiredActionMessageCursor
    )?_c('btn',{staticClass:"w-full",attrs:{"theme":_vm.BTN_THEMES.PRIMARY,"size":"sm"},on:{"click":function($event){return _vm.$emit('add-message-response', _vm.message)}}},[_vm._v(" "+_vm._s(_vm.$t('report.add-response'))+" ")]):_vm._e(),_c('action-required-response',{attrs:{"response-message-id":_vm.message.requiredActionMessageId},on:{"additional-content-loaded":function($event){return _vm.$emit('additional-content-loaded')}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }