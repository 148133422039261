<template>
  <sidebar-layout
    right-sidebar
    footer
    :sidebar-tabs="sidebarTabs"
    :active-tab="activeTab"
    content-class="p-0"
    header-class="border-b-0"
    @set-tab="activeTab = $event"
  >
    <template #sidebarHeader>
      <tabs-header v-model="activeTab" :tabs="sidebarTabs" />
    </template>
    <template #sidebar>
      <offer-tabs
        :active-tab="activeTab"
        :show-objects="canReportContainObjects(report)"
        :place-columns="placeColumns"
        :object-columns="objectColumns"
        :report="report"
        edit
      />
    </template>
    <template #contentHeader>
      <page-header
        :title="offer.title"
        :created-at="offer.createdAt"
        :updated-at="offer.updatedAt"
        :route="
          report && { name: 'ReportDetails', params: { reportId: report.id } }
        "
        :route-label="
          report &&
          `${$t('add-brand-offer-view.report')}: #${report.numberByBrand}`
        "
      />
    </template>
    <template>
      <offer-form
        v-if="offer"
        id="editOfferForm"
        :clear="submissionEndSuccessfully"
        :loading="submissionInProgress"
        :content="offer"
        edit
        @on-submit="handleSubmission"
      />
    </template>
    <template #footer>
      <div class="flex justify-between">
        <btn
          :disabled="submissionInProgress"
          :is-loading="submissionInProgress"
          class="w-30 mr-1 md:w-48 md:mr-3"
          type="submit"
          form="editOfferForm"
          @click="redirectAfterSubmission = true"
        >
          <span v-if="!submissionInProgress">{{ $t('common.save') }}</span>
        </btn>
        <router-link :to="{ name: 'Offers' }">
          <btn :theme="BTN_THEMES.NONE">{{ $t('common.cancel') }}</btn>
        </router-link>
      </div>
    </template>
  </sidebar-layout>
</template>
<script>
import Btn from '@/components/Button/Button';
import VueStore from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import offerMixin from '@/views/Brand/Applications/mixins/offerView';
import { OFFER_STATUS_COLORS } from '@/constants/offer';
import OfferTabs from '@/views/Brand/Applications/Components/Offers/OfferTabs';
import OfferForm from '@/views/Brand/Applications/Forms/OfferForm';
import PageHeader from '@/components/PageHeader/PageHeader';
import SidebarLayout from '@/layouts/SidebarLayout/SidebarLayout';
import TabsHeader from '@/components/TabsHeader/TabsHeader';
import resourcesMixin from '@/views/Brand/Applications/mixins/resources';

export default {
  components: {
    SidebarLayout,
    OfferTabs,
    OfferForm,
    Btn,
    PageHeader,
    TabsHeader,
  },
  mixins: [offerMixin, resourcesMixin],
  computed: {
    ...mapState('applications', {
      report: 'currentReport',
      statusColors: 'statusColors',
    }),
    ...mapState('offer', {
      offer: 'currentOffer',
    }),
    ...mapGetters('user', ['hasAdminRights']),
    ...mapGetters('offerResources', [
      'removedPlaceIds',
      'removedObjectIds',
      'selectedObjectIds',
      'selectedPlaceIds',
    ]),
    activeColor() {
      return OFFER_STATUS_COLORS[this.offer.status];
    },
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('offer/fetchOffer', to.params.offerId);

    next();
  },
  watch: {
    report: {
      immediate: true,
      handler(value) {
        this.showObjectsTab = value
          ? this.canReportContainObjects(value)
          : true;
      },
    },
  },
  async mounted() {
    if (this.offer.report) await this.fetchReport(this.offer.report.id);

    this.activeTab = this.sidebarTabs[0]?.name;
  },
  methods: {
    ...mapActions('applications', ['fetchObject', 'fetchReport']),
    ...mapActions('offer', ['editOffer']),
    async archiveOffer() {
      await this.editOffer({
        data: {
          status: 'archived',
        },
        id: this.offer.id,
      });

      this.$router.push({ name: 'Offers' });
    },
    async handleSubmission(formData) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;

      try {
        await this.editOffer({ data: formData, id: this.offer.id });

        await this.updateResources({
          offerId: this.offer.id,
          addedPlaceIds: this.selectedPlaceIds,
          removedPlaceIds: this.removedPlaceIds,
          addedObjectIds: this.selectedObjectIds,
          removedObjectIds: this.removedObjectIds,
        });

        this.submissionEndSuccessfully = true;

        this.$router.push({ name: 'Offers' });

        this.$toast.success(this.$t(`add-brand-offer-view.offer-updated`));
      } catch {
        this.submissionEndSuccessfully = false;
        this.$toast.error(this.$t('common.error-api'));
      }

      this.submissionInProgress = false;
    },
  },
};
</script>
