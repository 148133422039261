import { defineComponent, } from '@vue/composition-api/dist/vue-composition-api';
import OverflowWrapper from '@/components/OverflowWrapper/OverflowWrapper.vue';
import FilePreview from '@/views/Brand/Applications/Components/Preview/FilePreview/FilePreview.vue';
import { CloseBadge } from '@/components/Badge';
import { isImageFile } from '@/util/files';
import { PlacePreview } from '@/views/Brand/Applications/Components/Preview/PlacePreview';
import { ObjectPreview } from '@/views/Brand/Applications/Components/Preview/ObjectPreview';
import OfferPreview from '@/views/Brand/Applications/Components/Preview/OfferPreview/OfferPreview.vue';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
export default defineComponent({
    name: 'MessageResources',
    components: {
        OverflowWrapper,
        CloseBadge,
        FilePreview,
        PlacePreview,
        ObjectPreview,
        OfferPreview,
    },
    props: {
        files: {
            type: Array,
            default: () => [],
        },
        uploadingFiles: {
            type: Array,
            default: () => [],
        },
        offer: {
            type: Object,
        },
    },
    setup() {
        const { resolvedPlaces, removePlace } = usePlaceResourcesStore();
        const { resolvedObjects, removeObject } = useObjectResourcesStore();
        return {
            isImageFile,
            resolvedPlaces,
            removePlace,
            resolvedObjects,
            removeObject,
        };
    },
});
