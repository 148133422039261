import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { computed } from '@vue/composition-api';
import { DEFAULT_LIMIT } from '@/constants/pagination';
const { useState, useGetters, useActions } = createNamespacedHelpers('routes');
export const useRouteQuery = (ctx) => {
    const { loading, previousRoute } = useState(['loading', 'previousRoute']);
    const { routeQuery: savedQuery, pagination, previousPage: savedPreviousPage, } = useGetters(['routeQuery', 'pagination', 'previousPage']);
    const { setRoutePagination, updateFilter, clearRouteFilters, resetRouteFilters, setInitialRouteFilters, setPreviousRoute, setPreviousPage: handleSetPreviousPage, } = useActions([
        'setRoutePagination',
        'updateFilter',
        'setPreviousRoute',
        'setPreviousPage',
        'clearRouteFilters',
        'resetRouteFilters',
        'setInitialRouteFilters',
    ]);
    const routeQuery = computed(() => {
        return savedQuery.value(ctx.root.$route);
    });
    const selectLimit = (limit) => {
        setRoutePagination({ route: ctx.root.$route, pagination: { limit } });
    };
    const changePage = (val) => {
        setRoutePagination({
            route: ctx.root.$route,
            pagination: { page: val },
        });
    };
    const setupPreviousPage = () => {
        handleSetPreviousPage({
            ...ctx.root.$route,
            pagination: { page: activePage.value },
        });
    };
    const activePage = computed(() => pagination.value(ctx.root.$route)?.page || 1);
    const previousPage = computed(() => savedPreviousPage.value?.pagination?.page || 1);
    const limit = computed(() => pagination.value(ctx.root.$route)?.limit || DEFAULT_LIMIT);
    return {
        routeQuery,
        selectLimit,
        setRoutePagination,
        changePage,
        activePage,
        previousPage,
        setupPreviousPage,
        limit,
        updateQueryParameter: updateFilter,
        loading,
        clearRouteFilters,
        resetRouteFilters,
        setInitialRouteFilters,
        previousRoute,
        setPreviousRoute,
    };
};
