import { computed } from '@vue/composition-api';
import { FormComponentType, } from '@/views/_components/CustomForms';
export const useCustomForm = (values, components) => {
    const getCheckboxValue = (val, component) => {
        const valueArray = JSON.parse(val);
        return valueArray
            .map((value, index) => component.items[index])
            .filter((label, index) => valueArray[index])
            .join(', ');
    };
    const getComponentValue = (val, component) => {
        switch (component.type) {
            case FormComponentType.Checkbox:
                return getCheckboxValue(val, component);
            default:
                return val;
        }
    };
    const formFields = computed(() => {
        if (!components)
            return [];
        return components.map((component, i) => {
            return {
                title: component.title,
                value: getComponentValue(values[i], component),
            };
        });
    });
    return { formFields };
};
