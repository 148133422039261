import VueStore from '@/store';

export const fetchPlaceData = async (route) => {
  await VueStore.dispatch('brand/clearPlace');
  const queryPlaceId = route.params.placeId;
  const brandId = VueStore.getters['brand/currentBrandId'](route);
  const placeId =
    queryPlaceId || (await VueStore.dispatch('brand/getFirstPlaceId', brandId));

  if (placeId) {
    await Promise.all([
      VueStore.dispatch('brand/fetchBrandPlaceDetails', placeId),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: placeId,
        target: 'places',
        type: 'document',
      }),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: placeId,
        target: 'places',
        type: 'multimedia',
      }),
    ]);
  }
};
