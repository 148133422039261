import i18n from '@/i18n';
import { emailRegex } from '@/util/form';

export const email = (email) => {
  // eslint-disable-next-line no-useless-escape
  return email.match(emailRegex)
    ? `<a class="text-links" href="mailto:${email}">${email}</b>`
    : email;
};

const getLocale = () => {
  return i18n.locale === 'en' ? 'en-US' : 'pl-PL';
};

export const date = (dateString) => {
  return new Date(dateString).toLocaleDateString(getLocale());
};

export const dateTime = (dateString) => {
  return new Date(dateString).toLocaleString(getLocale());
};

export const uppercase = (text) => {
  return `<span class="uppercase">${text}</span>`;
};

export const fontNormal = (text) => {
  return `<span class="font-normal">${text}</span>`;
};

export const customClass = (text, classname) => {
  return `<span class="${classname}">${text}</span>`;
};

export const label = (text, status) => {
  const statusClass = status === 'registered' ? 'success' : 'error';
  return `<span class="px-3 py-0.5 font-normal border text-3xs capitalize rounded-full border-${statusClass} text-${statusClass}">${text}</span>`;
};

export const customLabel = ({
  text,
  color = 'text-gray-dark',
  borderColor = 'border-gray-dark',
  backgroundColor = '',
  customClass = '',
}) => {
  return `<span class="px-3 py-0.5 font-normal border text-3xs text-center whitespace-nowrap capitalize rounded-full ${borderColor} ${color} ${backgroundColor} ${customClass}">${text}</span>`;
};

export const extension = (path) => {
  return path.match(/\.([^.]+)$/)[1];
};

const toDegreesMinutesAndSeconds = (coordinate) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + '° ' + minutes + "' " + seconds;
};

export const convertDMS = (lat, lng) => {
  const latitude = toDegreesMinutesAndSeconds(lat);
  const latitudeCardinal = lat >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng);
  const longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return (
    latitude +
    '" ' +
    latitudeCardinal +
    ' ' +
    longitude +
    '" ' +
    longitudeCardinal
  );
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const link = (string) => {
  return `<a href="${string}">{{ string }}</a>`;
};

export const acceptance = (
  value,
  applicable = true,
  notApplicableText = '-',
) => {
  if (applicable) {
    return `<div class="text-center">${value ? '&#10004;' : '&#10007;'}</div>`;
  }

  return `<div class="text-center text-gray-dark text-sm">${notApplicableText}</div>`;
};

export const splitArrayIntoChunks = (arr, length) => {
  let chunks = new Array(Math.ceil(arr.length / length)).fill('');
  chunks = chunks.map((_, i) => arr.slice(i * length, (i + 1) * length));

  return chunks;
};

export const getPropertyArray = (items = [], property) => {
  return items.map((item) => item[property]);
};

export const ellipsis = (message, maxLength) => {
  const croppedMessage = message.slice(0, maxLength);

  if (croppedMessage.length < message.length) return `${croppedMessage}...`;

  return croppedMessage;
};

export const getPlainText = (message) => {
  return message
    .replace(/<.*>/g, '')
    .replace(/@.{36}/g, '')
    .trim();
};

export const getMessageSnippet = (message, length) => {
  return ellipsis(getPlainText(message), length);
};

export const mergeProperties = (value, option) => {
  return Array.isArray(value)
    ? value.map((el) => option[el]).join(' ')
    : option[value];
};

export const formatMoney = (number, currency) => {
  const [integerPart, decimalPart] = (number || 0).toString().split('.');

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const formattedNumber = decimalPart
    ? `${formattedInteger}.${decimalPart} ${currency}`
    : `${formattedInteger} ${currency}`;

  return formattedNumber;
};
