<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      :id="id"
      ref="form"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <main-fields
        :form-type="formType"
        :mode="mode"
        :loading="loading"
        @set-values="formValues = $event"
      />

      <offer-fields
        v-if="formType === FORM_TYPE.REPORT"
        v-model="addOffer"
        :components="offerComponents"
        :mode="mode"
        :loading="loading"
        @set-components="offerComponents = $event"
      />
      <form-footer :loading="loading" :mode="mode" :last-route="lastRoute" />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import OfferFields from '@/views/Brand/Settings/Forms/Components/OfferFields';
import { FORM_MODE, FORM_TYPE } from '@/constants/form';
import MainFields from '@/views/Brand/Settings/Forms/Components/MainFields';
import FormFooter from '@/views/Brand/Settings/Forms/Components/FormFooter';
import cloneDeep from 'lodash.clonedeep';
import { getPropertyArray } from '@/util/formatters';
import { getUpdatedValues } from '@/util/form';

export default {
  components: {
    MainFields,
    OfferFields,
    FormFooter,
    ValidationObserver,
  },
  props: {
    categoryId: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    lastRoute: {
      type: Object,
      required: true,
    },
    formType: {
      type: String,
      required: true,
      validate: (type) => Object.values(FORM_TYPE).includes(type),
    },
    mode: {
      type: String,
      required: true,
      validate: (mode) => Object.values(FORM_MODE).includes(mode),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addOffer: false,
      offerId: null,
      offerComponents: [],
      FORM_TYPE,
      formValues: {},
    };
  },
  computed: {
    ...mapState('brand', {
      currentForm: 'currentForm',
      currentOfferForm: 'currentOfferForm',
    }),
    ...mapGetters('brand', ['currentBrandId']),
    brandId() {
      return this.currentBrandId(this.$route);
    },
    selectedCategoryId() {
      return this.currentForm?.category?.id || this.categoryId || null;
    },
    updatedOfferForm() {
      return this.currentForm.offerFormId ? !this.addOffer : this.addOffer;
    },
    includeOfferFormId() {
      return this.mode === FORM_MODE.EDIT
        ? this.updatedOfferForm
        : this.offerId;
    },
    defaultPayload() {
      return {
        categoryId: this.selectedCategoryId,
      };
    },
    currentFormValues() {
      if (this.mode === FORM_MODE.ADD) return {};

      return this.getCurrentValues(this.currentForm);
    },
    currentOfferFormValues() {
      if (this.mode === FORM_MODE.ADD || !this.currentOfferForm) return {};

      return this.getCurrentValues(this.currentOfferForm);
    },
    payload() {
      const data = {
        type: this.formType,
        ...this.defaultPayload,
        ...this.formValues,
        ...(this.includeOfferFormId && { offerFormId: this.offerId }),
        ...(this.mode === FORM_MODE.ADD && { brandId: this.brandId }),
      };

      return {
        data: getUpdatedValues(this.currentFormValues, data),
        ...(this.mode === FORM_MODE.EDIT && { formId: this.currentForm.id }),
      };
    },
    offerPayload() {
      const offerFormValues = { ...this.formValues };
      delete offerFormValues.decisionMakersIds;
      const isFormEdited =
        this.mode === FORM_MODE.EDIT &&
        this.offerId &&
        this.currentForm.offerFormId;

      const data = {
        ...offerFormValues,
        ...this.defaultPayload,
        type: FORM_TYPE.OFFER,
        components: this.offerComponents,
        ...(!isFormEdited && { brandId: this.brandId }),
      };

      return {
        data: getUpdatedValues(this.currentOfferFormValues, data),
        ...(isFormEdited && { formId: this.currentForm.offerFormId }),
      };
    },
  },
  watch: {
    addOffer(value) {
      if (value && this.currentOfferForm) {
        this.offerId = this.currentOfferForm.id;
      } else if (!value) {
        this.offerId = null;
      }
    },
    currentOfferForm: {
      handler(value) {
        if (value) {
          this.addOffer = true;

          this.offerComponents = cloneDeep(value.components);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCurrentValues(form) {
      return {
        ...form,
        categoryId: form.category?.id || null,
        decisionMakersIds: getPropertyArray(form.decisionMakers, 'id'),
      };
    },
    async onSubmit() {
      this.$emit('submit', {
        addOffer: this.formType === FORM_TYPE.REPORT && this.addOffer,
        payload: {
          [FORM_TYPE.OFFER]: this.offerPayload,
          [this.formType]: this.payload,
        },
      });
    },
  },
};
</script>
