import { computed, ref } from '@vue/composition-api';
import { FILTER_PARAMS } from '@/constants/filters';
import { CONTRACTOR_TYPE } from '@/store/modules/user';
import isEmpty from 'lodash.isempty';
export const useObjectFilters = (ctx) => {
    const hiddenFilterTags = [
        FILTER_PARAMS.SORT_BY,
        FILTER_PARAMS.INTERNAL_STATUS,
    ];
    const filters = ref({
        [FILTER_PARAMS.STRUCTURE]: null,
        [FILTER_PARAMS.OBJECT_TYPES]: [],
        [FILTER_PARAMS.PRICE_FROM]: null,
        [FILTER_PARAMS.PRICE_TO]: null,
        [FILTER_PARAMS.PLACES]: [],
        [FILTER_PARAMS.CONTRACTOR]: null,
        [FILTER_PARAMS.TAGS]: [],
        [FILTER_PARAMS.SEARCH]: '',
        [FILTER_PARAMS.INTERNAL_STATUS]: null,
        [FILTER_PARAMS.SORT_BY]: null,
        [FILTER_PARAMS.IS_IMPORTANT]: false,
    });
    const errors = ref({
        [FILTER_PARAMS.PRICE_FROM]: null,
        [FILTER_PARAMS.PRICE_TO]: null,
    });
    const query = computed(() => {
        return {
            type: CONTRACTOR_TYPE,
        };
    });
    const multipleFilters = computed(() => {
        let multipleFilters = [];
        Object.entries(filters.value).map(([key, value]) => {
            if (Array.isArray(value)) {
                if (isEmpty(value)) {
                    return;
                }
                value.map((multipleFilterValue) => {
                    multipleFilters = [...multipleFilters, [key, multipleFilterValue]];
                });
            }
        });
        return multipleFilters;
    });
    const singleFilters = computed(() => {
        return Object.entries(filters.value).filter(([, value]) => {
            return !Array.isArray(value) && value;
        });
    });
    const omitHiddenFilterTags = (filterTags) => {
        return filterTags.filter(([key]) => {
            return (!hiddenFilterTags.includes(key) &&
                Object.values(FILTER_PARAMS).includes(key));
        });
    };
    const filterTags = computed(() => {
        const mergedFilters = [...multipleFilters.value, ...singleFilters.value];
        return omitHiddenFilterTags(mergedFilters);
    });
    const removeActiveFilter = (param, value) => {
        if (Array.isArray(filters.value[param])) {
            filters.value[param] = filters.value[param].filter((filterItem) => filterItem.id !== value.id);
            return;
        }
        filters.value[param] = null;
    };
    const validatePriceFrom = (priceFrom) => {
        const priceTo = filters.value[FILTER_PARAMS.PRICE_TO];
        errors.value[FILTER_PARAMS.PRICE_FROM] = null;
        if (priceFrom >= 0 && (!priceTo || priceFrom < priceTo)) {
            filters.value = {
                [FILTER_PARAMS.PRICE_FROM]: priceFrom,
                ...filters.value,
            };
        }
        else {
            errors.value[FILTER_PARAMS.PRICE_FROM] = ctx.root.$t('filters.price-error');
        }
    };
    const validatePriceTo = (priceTo) => {
        const priceFrom = filters.value[FILTER_PARAMS.PRICE_FROM];
        errors.value[FILTER_PARAMS.PRICE_TO] = null;
        if (priceTo >= 0 && (!priceFrom || priceFrom < priceTo)) {
            filters.value = {
                [FILTER_PARAMS.PRICE_TO]: priceTo,
                ...filters.value,
            };
        }
        else {
            errors.value[FILTER_PARAMS.PRICE_TO] = ctx.root.$t('filters.price-error');
        }
    };
    const validators = computed(() => ({
        [FILTER_PARAMS.PRICE_FROM]: validatePriceFrom,
        [FILTER_PARAMS.PRICE_TO]: validatePriceTo,
    }));
    return {
        filters,
        errors,
        query,
        filterTags,
        removeActiveFilter,
        validators,
    };
};
