import { Modal } from '@/components/Modal';
import { defineComponent, onMounted, ref, } from '@vue/composition-api';
import { useBrand, useMobileView } from '@/composables';
import { useAttachment } from '@/composables/use-attachment';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import PlaceDetails from '@/views/Brand/Places/PlaceDetails.vue';
import PlaceDashboard from '@/views/Brand/PlaceDashboard/PlaceDashboard.vue';
export default defineComponent({
    components: { PlaceDashboard, PlaceDetails, LoadingSpinner, Modal },
    props: {
        place: {
            type: Object,
            required: true,
        },
        showDashboard: Boolean,
        show: Boolean,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const { fetchBrandPlaceDetails } = useBrand();
        const { fetchAttachments } = useAttachment(ctx);
        const loading = ref(true);
        onMounted(async () => {
            try {
                loading.value = true;
                await Promise.all([
                    fetchAttachments({
                        id: props.place.id,
                        target: 'places',
                        type: 'document',
                    }),
                    fetchAttachments({
                        id: props.place.id,
                        target: 'places',
                        type: 'multimedia',
                    }),
                    fetchBrandPlaceDetails(props.place.id),
                ]);
            }
            finally {
                loading.value = false;
            }
        });
        return {
            isMobileView,
            loading,
        };
    },
});
