import { defineComponent } from '@vue/composition-api';
import { IconSize, Icon } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
import debounce from 'lodash.debounce';
import { hexWithAlpha } from '@/util/styles';
export default defineComponent({
    components: { Icon },
    props: {
        autocompleteValue: String,
        selectedValues: {
            type: Array,
        },
        id: String,
        multiple: Boolean,
        disabled: Boolean,
        optionLabelName: String,
        autocomplete: Boolean,
        placeholder: String,
        singleLine: Boolean,
        loading: Boolean,
        hideAutocomplete: Boolean,
    },
    setup(props, ctx) {
        const debounceInput = debounce((input) => {
            ctx.emit('input', input);
        }, 500);
        const onInput = (input) => {
            debounceInput(input);
        };
        const handleClose = (selection) => {
            if (props.loading) {
                return;
            }
            ctx.emit('unselect', selection);
        };
        return {
            IconSize,
            mdiClose,
            onInput,
            handleClose,
            hexWithAlpha,
        };
    },
});
