import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

export default {
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
};
