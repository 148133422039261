import { useAuthUser, useToggle } from '@/composables';
import { computed, ref } from '@vue/composition-api';
import { getReport, updateReportMessage, } from '@/api/requests/reports';
export const useActionRequired = (props) => {
    const { isObjektoAdmin, isContractor, isBrandAdmin, authUserId } = useAuthUser();
    const selectedUser = ref(props?.user || null);
    const userResponseMessage = ref('');
    const isRequiredActionUser = selectedUser.value?.id === authUserId.value;
    const editedRequireActionMessage = ref(null);
    const { state: isActionRequiredModeEnabled, enable: enableActionRequiredMode, disable: disableActionRequiredMode, } = useToggle(false);
    const isAuthorizedToCreateActionRequired = computed(() => isBrandAdmin.value || isContractor.value || isObjektoAdmin.value);
    const isActionRequiredInReport = async (id) => {
        const { hasRequiredActionMessage } = await getReport(id);
        return Boolean(hasRequiredActionMessage);
    };
    const validateActionRequiredUser = computed(() => isActionRequiredModeEnabled.value ? Boolean(selectedUser.value) : true);
    const markMessageAsActionRequired = async (message, fileTokens) => {
        if (!editedRequireActionMessage.value || !selectedUser.value) {
            throw new Error('Edit message or action required user does not exist');
        }
        return await updateReportMessage(editedRequireActionMessage.value.id, {
            message,
            fileTokens,
            requiredActionUserId: selectedUser.value?.id,
        });
    };
    const clearRequireActionData = () => {
        selectedUser.value = null;
        editedRequireActionMessage.value = null;
        disableActionRequiredMode();
    };
    return {
        isRequiredActionUser,
        editedRequireActionMessage,
        isAuthorizedToCreateActionRequired,
        isActionRequiredModeEnabled,
        validateActionRequiredUser,
        clearRequireActionData,
        isActionRequiredInReport,
        markMessageAsActionRequired,
        enableActionRequiredMode,
        disableActionRequiredMode,
        requireActionUser: selectedUser,
        requireActionUserResponseMessage: userResponseMessage,
    };
};
