import { defineComponent, watch } from '@vue/composition-api';
import { WysiwygInput, WysiwygInputHeight } from '@/components/WysiwygInput';
import { Btn } from '@/components/Button';
import { Icon, IconSize } from '@/components/Icon';
import { mdiCheck } from '@mdi/js';
import { BTN_THEMES } from '@/store/modules/ui';
import { getTextFromHtml } from '@/util/text';
import { MessageResources } from '@/views/Brand/Applications/Report/_components/MessageResources';
import { useMessageFiles } from '@/views/Brand/Applications/Report/_composables/use-message-files';
import { ReportStatus } from '@/api/requests/reports';
export default defineComponent({
    components: {
        MessageResources,
        Icon,
        Btn,
        WysiwygInput,
    },
    props: {
        isRequiredActionMessage: Boolean,
        getMentionSuggestions: {
            type: Function,
            required: true,
        },
        isRequiredActionUser: {
            type: Boolean,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        report: Object,
        inputClass: String,
        showDetailsButton: Boolean,
    },
    setup(_, ctx) {
        const { files, handleRemoveFile, handleAddFile, uploadingFiles, getFileTokens, } = useMessageFiles(ctx);
        watch(files, async (value) => {
            if (value) {
                const tokens = await getFileTokens();
                ctx.emit('file-tokens-update', tokens);
            }
        }, { deep: true, immediate: true });
        return {
            files,
            uploadingFiles,
            handleAddFile,
            handleRemoveFile,
            getTextFromHtml,
            WysiwygInputHeight,
            IconSize,
            ReportStatus,
            mdiCheck,
            BTN_THEMES,
        };
    },
});
