import { ResourceType } from '@/constants/resources';
import { computed, defineComponent } from '@vue/composition-api';
import ResourcesList from '@/views/Brand/Applications/Components/Resources/ResourcesList.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { useBrand } from '@/composables';
import { DEFAULT_LIMIT } from '@/constants/pagination';
import { PLACES_API_PATH } from '@/api/requests/places';
import { OBJECTS_API_PATH } from '@/api/requests/objects';
export default defineComponent({
    components: { FetchData, ResourcesList },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        removedIds: {
            type: Array,
            default: () => [],
        },
        resourceType: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        reportId: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        validateResources: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { brand } = useBrand();
        const resourceUrl = computed(() => {
            const urlMap = {
                [ResourceType.OBJECTS]: OBJECTS_API_PATH,
                [ResourceType.PLACES]: PLACES_API_PATH,
            };
            return urlMap[props.resourceType];
        });
        const resourceQuery = computed(() => ({
            brandId: brand.value?.id,
            reportId: props.reportId,
            relatedReportId: props.reportId,
            limit: DEFAULT_LIMIT,
        }));
        const getTotal = (pagination) => {
            if (!pagination)
                return 0;
            return pagination.total + props.value.length - props.removedIds.length;
        };
        return {
            resourceUrl,
            resourceQuery,
            brand,
            getTotal,
        };
    },
});
