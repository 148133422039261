<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, reset }">
      <form
        :id="id"
        ref="form"
        class="form bg-neutral p-4"
        @submit.prevent="handleSubmit(pushFormData)"
        @reset.prevent="reset"
      >
        <offer-place-object-list
          :include-objects="includeObjects"
          :loading="loading"
          :edit="edit"
          offer
        />
        <offer-form-fields
          :title="title"
          :description="description"
          :brand-id="brandId"
          :loading="loading"
          :edit="edit"
          @update-title="title = $event"
          @update-description="description = $event"
        />
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import form from '@/views/Brand/Applications/mixins/form';
import { mapActions } from 'vuex';
import { OFFER_STATUS_MAP } from '@/constants/offer';
import objectStatus from '@/views/Brand/Objects/mixins/objectStatus';
import OfferFormFields from '@/views/Brand/Applications/Components/Offers/OfferFormFields';
import { ValidationObserver } from 'vee-validate';
import OfferPlaceObjectList from '@/views/Brand/Applications/Components/Offers/OfferPlaceObjectList';

export default {
  components: {
    OfferPlaceObjectList,
    OfferFormFields,
    ValidationObserver,
  },
  mixins: [form, objectStatus],
  props: {
    clear: Boolean,
    selectedReport: {
      type: Object,
      default: null,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forms: [],
      form: null,
      status: OFFER_STATUS_MAP.ACTIVE,
      title: '',
      description: '',
      show: false,
    };
  },
  computed: {
    includeObjects() {
      if (!this.form) return true;
      return this.form.canContainObjects;
    },
  },
  watch: {
    clear: {
      async handler(value) {
        if (value) {
          this.clearForm();
        }
      },
    },
    form: {
      handler(value) {
        if (!this.edit) {
          this.components = value?.components || [];
          this.formValues = value
            ? value.components.map((component) =>
                this.getDefaultValue(component),
              )
            : [];
        }

        this.$emit('set-form', value);
      },
    },
    report: {
      immediate: true,
      async handler(value) {
        if (value?.offerFormId) {
          const { data } = await this.fetchForm(value.offerFormId);

          this.form = data;
        } else {
          this.form = null;
        }
      },
    },
    content: {
      immediate: true,
      async handler(data) {
        const { values, creator, components, report, title, description } =
          data;

        this.formValues = this.getFormValues(values, components);
        this.user = creator;
        this.components = [...(components || [])];
        this.report = report;
        this.title = title;
        this.description = description;
      },
    },
  },
  async mounted() {
    if (this.selectedReport) this.report = this.selectedReport;
  },
  methods: {
    ...mapActions('applications', [
      'fetchForm',
      'fetchReport',
      'fetchBrandForms',
    ]),
    pushFormData() {
      this.$emit(
        'on-submit',
        {
          title: this.title,
          ...(this.report && this.form
            ? {
                formId: this.form.id,
                formVersion: this.form.version,
                values: this.formValues,
              }
            : {}),
          status: OFFER_STATUS_MAP.ACTIVE,
          ...(this.report && { reportId: this.report.id }),
          description: this.description,
          brandId: this.brandId,
        },
        {
          includeObjects: this.includeObjects,
        },
      );
    },
    clearForm() {
      this.formValues = [];
      this.form = null;
      this.user = null;
      this.report = null;
      this.title = '';
      this.description = '';

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    getDefaultValue({ type, items }) {
      return type === 'checkbox_list'
        ? JSON.stringify(items.map(() => false))
        : '';
    },
    getFormValues(values, components) {
      if (!components?.length) return [];

      return values || components.map(this.getDefaultValue);
    },
  },
};
</script>
