import { Icon } from '@/components/Icon';
import { computed, defineComponent, onUnmounted, ref, watch, } from '@vue/composition-api';
import { MenuItems, ReportFilters, ReportMenuItem, REPORTS_LIST_ROUTE_NAME, } from '@/views/Brand/Applications/Components/Sidebar/sidebar.constants';
import { IconSize } from '@/components/Icon/icon.constants';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { getRequiredActionMessages, ReportStatus, } from '@/api/requests/reports';
import { useAuthUser, useBrand, useMobileView, useUi } from '@/composables';
import { mdiAlertOctagonOutline, mdiCheck, mdiFormatListBulleted, mdiInboxOutline, } from '@mdi/js';
import FilterGroup from '@/components/Filters/FilterGroup.vue';
import { REPORTS_TAB_NAME, ReportTab, reportTabIndexMap, } from '@/views/Brand/Applications/Components/ReportMenu/ReportMenuItems.constants';
import BackdropLoader from '@/components/BackdropLoader/BackdropLoader.vue';
const { useActions } = createNamespacedHelpers('routes');
export default defineComponent({
    components: {
        BackdropLoader,
        FilterGroup,
        Icon,
    },
    props: {
        horizontal: Boolean,
        actionRequiredMessagesUpdatesCounter: Number,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const isLoading = ref(true);
        const requiredActionMessagesCount = ref(0);
        const reportId = computed(() => ctx.root.$route.params.reportId);
        const { updateFilter } = useActions(['updateFilter']);
        const { brand } = useBrand();
        const { setTab } = useUi();
        const { isContractor, authUserId } = useAuthUser();
        const activeMenuItem = ref(ReportMenuItem.ALL);
        const hasRequiredActionMessages = (item) => {
            return (requiredActionMessagesCount.value > 0 &&
                item.name === ReportMenuItem.ACTION_REQUIRED);
        };
        const filters = ref({
            [ReportFilters.SORT_BY]: null,
            [ReportFilters.STATUS]: [ReportStatus.PENDING, ReportStatus.ACTIVE],
        });
        const reportListRoute = computed(() => {
            return {
                name: REPORTS_LIST_ROUTE_NAME,
                params: { id: brand.value?.id },
                path: `/brand/${brand.value?.id}/reports`,
            };
        });
        const isReportListView = computed(() => ctx.root.$route.name === REPORTS_LIST_ROUTE_NAME);
        const reportItems = computed(() => [
            {
                name: ReportMenuItem.ACTION_REQUIRED,
                label: ctx.root.$t('brand-reports-list-view.action-required'),
                icon: mdiAlertOctagonOutline,
            },
            {
                name: ReportMenuItem.ALL,
                label: ctx.root.$t('brand-reports-list-view.all'),
                icon: mdiInboxOutline,
            },
            ...(isContractor.value
                ? [
                    {
                        name: ReportMenuItem.MY_TASKS,
                        label: ctx.root.$t('brand-reports-list-view.my-tasks'),
                        icon: mdiFormatListBulleted,
                    },
                ]
                : []),
            {
                name: ReportMenuItem.FINISHED,
                label: ctx.root.$t('brand-reports-list-view.finished'),
                icon: mdiCheck,
            },
        ]);
        const applyStatusFilter = (item) => {
            const filterValue = {
                [ReportMenuItem.ALL]: [ReportStatus.PENDING, ReportStatus.ACTIVE],
                [ReportMenuItem.FINISHED]: [ReportStatus.FINISHED],
                [ReportMenuItem.MY_TASKS]: [ReportStatus.ACTIVE, ReportStatus.PENDING],
                [ReportMenuItem.ACTION_REQUIRED]: [],
            };
            updateFilter({
                route: reportListRoute.value,
                parameter: ReportFilters.STATUS,
                value: filterValue[item],
            });
        };
        const applySupervisorFilter = (item) => {
            const getSupervisorValue = () => {
                if (item !== ReportMenuItem.MY_TASKS) {
                    return null;
                }
                return isMobileView.value ? authUserId.value : { id: authUserId.value };
            };
            updateFilter({
                route: reportListRoute.value,
                parameter: ReportFilters.SUPERVISOR,
                value: getSupervisorValue(),
            });
        };
        const isActive = (item) => activeMenuItem.value === item;
        const setReportTab = (name) => {
            setTab({
                name: REPORTS_TAB_NAME,
                index: name === ReportMenuItem.ACTION_REQUIRED
                    ? reportTabIndexMap[ReportTab.ACTION_REQUIRED]
                    : reportTabIndexMap[ReportTab.REPORTS_LIST],
            });
        };
        const onItemClick = (name) => {
            applyStatusFilter(name);
            applySupervisorFilter(name);
            activeMenuItem.value = name;
            setReportTab(name);
            if (!isReportListView.value) {
                ctx.root.$router.push({ name: REPORTS_LIST_ROUTE_NAME });
            }
        };
        watch(() => props.actionRequiredMessagesUpdatesCounter, async () => {
            const { pagination } = await getRequiredActionMessages({
                limit: 1,
                brandId: brand.value.id,
            });
            requiredActionMessagesCount.value = pagination.total;
            isLoading.value = false;
            if (requiredActionMessagesCount.value > 0 && !reportId.value) {
                activeMenuItem.value = ReportMenuItem.ACTION_REQUIRED;
                onItemClick(ReportMenuItem.ACTION_REQUIRED);
            }
        }, { immediate: true });
        onUnmounted(() => {
            applySupervisorFilter(null);
        });
        watch(activeMenuItem, () => {
            ctx.emit('updated-menu-item', activeMenuItem.value);
        });
        return {
            filters,
            brand,
            IconSize,
            MenuItems,
            isLoading,
            reportItems,
            activeMenuItem,
            ReportMenuItem,
            requiredActionMessagesCount,
            isActive,
            onItemClick,
            hasRequiredActionMessages,
        };
    },
});
