import { computed, defineComponent } from '@vue/composition-api';
import { PreviewWrapper } from '@/views/Brand/Applications/Components/Preview/PreviewWrapper';
import { getFullAddress } from '@/util/common';
export default defineComponent({
    name: 'PlacePreview',
    components: { PreviewWrapper },
    props: {
        place: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const image = computed(() => ({
            src: props.place.cdnImageUrl,
            alt: ctx.root.$i18n
                .t('place.place-alt', { name: props.place.name })
                .toString(),
        }));
        return { image, getFullAddress };
    },
});
