<template>
  <div class="card" :class="{ md }">
    <div class="card-header" :class="headerClass">
      <slot name="header"></slot>
    </div>
    <div :class="contentClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    md: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: 'p-4',
    },
    headerClass: {
      type: String,
      default: 'border-b',
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  @apply border-gray-light rounded-lg border overflow-x-hidden bg-white;

  &-header {
    @apply bg-gray-lighter border-gray-light px-4 py-3;
  }

  &.md {
    @apply rounded-xl;

    .card-header {
      @apply py-2;
    }
  }
}
</style>
