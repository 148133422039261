import { ref } from '@vue/composition-api';
import MapSettingControl from '@/components/Map/components/map-settings/MapSettings.vue';
import { MAP_STYLE, mapStyles } from '@/components/Map';
import { MAP_SETTING_EVENT } from '@/components/Map/constants';
import { createVueComponentControl } from '@/components/Map/utils/create-vue-component-control';
import { SearchBoxEvent, } from '@/components/Map/types';
import { useMobileView } from '@/composables';
import { useMapState } from '@/components/Map/composables/use-map-state';
export const useMapControls = (props) => {
    const { map, disabledSettings } = props;
    const { setSetting } = useMapState();
    const mapStyle = ref(mapStyles[MAP_STYLE.STANDARD]);
    const searchBoxListenerHandle = ref(null);
    const { isMobileView } = useMobileView();
    const handleSettingsChange = (option) => {
        const [setting, isEnabled] = Object.entries(option)[0];
        setSetting({ setting, isEnabled });
    };
    const handleMapStyleChange = (selectedMapStyle) => (mapStyle.value = selectedMapStyle);
    const clearControlsListeners = () => {
        if (searchBoxListenerHandle.value) {
            google.maps.event.removeListener(searchBoxListenerHandle.value);
            searchBoxListenerHandle.value = null;
        }
    };
    const setSearchBoxListeners = (searchBox) => {
        searchBoxListenerHandle.value = searchBox.addListener(SearchBoxEvent.PLACES_CHANGED, () => {
            const places = searchBox.getPlaces();
            if (places?.length === 0) {
                return;
            }
            const bounds = new google.maps.LatLngBounds();
            places?.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    return;
                }
                if (place.geometry.viewport) {
                    return bounds.union(place.geometry.viewport);
                }
                bounds.extend(place.geometry.location);
            });
            map.value?.fitBounds(bounds);
        });
    };
    const initializeSearchControl = () => {
        const { LEFT_BOTTOM, TOP_CENTER } = google.maps.ControlPosition;
        const searchBoxControl = document.createElement('input');
        searchBoxControl.setAttribute('placeholder', 'Search for places');
        map.value?.controls[isMobileView.value ? LEFT_BOTTOM : TOP_CENTER].push(searchBoxControl);
        const searchBox = new google.maps.places.SearchBox(searchBoxControl);
        setSearchBoxListeners(searchBox);
    };
    const initializeSettingsControl = () => {
        const settingsControl = createVueComponentControl({
            component: MapSettingControl,
            props: {
                map,
                mapStyle,
                disabledSettings,
            },
            tagName: 'div',
            eventListeners: [
                {
                    name: MAP_SETTING_EVENT.SETTING_CHANGED,
                    handler: handleSettingsChange,
                },
                {
                    name: MAP_SETTING_EVENT.SET_MAP_STYLE,
                    handler: handleMapStyleChange,
                },
            ],
        });
        map.value?.controls[google.maps.ControlPosition.LEFT_TOP].push(settingsControl);
    };
    const initializeMapControls = () => {
        initializeSettingsControl();
        initializeSearchControl();
    };
    return {
        clearControlsListeners,
        initializeMapControls,
    };
};
