var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title":_vm.header,"sub-title":_vm.object.objectType.name,"created-at":_vm.object.createdAt,"updated-at":_vm.object.updatedAt,"route":{
      name: 'Objects',
      params: { objectTypeId: _vm.object.objectType.id },
    },"route-label":_vm.$t('object-details-view.objects')},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('navigation-arrow',{attrs:{"direction":_vm.CONTROLLER_DIRECTION.PREV,"disabled":!_vm.prevObject,"to":{
          name: 'ObjectDetails',
          params: {
            objectId: _vm.prevObject,
            objectTypeId: _vm.object.objectType.id,
          },
        }}})]},proxy:true},{key:"right",fn:function(){return [_c('navigation-arrow',{attrs:{"direction":_vm.CONTROLLER_DIRECTION.NEXT,"disabled":!_vm.nextObject,"to":{
          name: 'ObjectDetails',
          params: {
            objectId: _vm.nextObject,
            objectTypeId: _vm.object.objectType.id,
          },
        }}})]},proxy:true}])}),_c('div',{staticClass:"flex items-center justify-between mt-4"},[_c('field-with-label',{staticClass:"flex-grow flex-shrink",attrs:{"id":"localization","label":_vm.$t('object-details-view.localization'),"value":_vm.address}}),_c('status-toggler',{staticClass:"flex-grow-0 flex-shrink-0 ml-2",attrs:{"options":_vm.getStatusOptions(_vm.status || _vm.object.internalStatus),"colors":_vm.OBJECT_STATUS_COLORS,"status-translation":_vm.statusConfig.translation,"current-status":_vm.status || _vm.object.internalStatus,"compact":_vm.isMobileView},on:{"change-status":_vm.onStatusUpdate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }