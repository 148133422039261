import { ref } from '@vue/composition-api';
import { useToggle } from '@/composables';
export const useTextEditorLink = (editor) => {
    const link = ref('');
    const { state: isLinkModalEnabled, toggle: toggleLinkModal, enable: enableLinkModal, disable: disableLinkModal, } = useToggle(false);
    const handleEnableLinkModal = () => {
        const previousUrl = editor.value.getAttributes('link').href;
        link.value = previousUrl;
        enableLinkModal();
    };
    const setLink = (url) => {
        if (!editor.value) {
            return;
        }
        if (url === null) {
            return;
        }
        if (url === '') {
            editor.value.chain().focus().extendMarkRange('link').unsetLink().run();
            return;
        }
        editor.value
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run();
    };
    const onSetLinkSubmit = (url) => {
        setLink(url);
        disableLinkModal();
    };
    return {
        onSetLinkSubmit,
        isLinkModalEnabled,
        toggleLinkModal,
        link,
        handleEnableLinkModal,
        disableLinkModal,
    };
};
