import { ResourceType } from '@/constants/resources';
import { Icon, IconSize } from '@/components/Icon';
import { computed, defineComponent } from '@vue/composition-api';
import Btn from '@/components/Button/Button.vue';
import { BTN_THEMES } from '@/store/modules/ui';
import { mdiPlus, mdiClose } from '@mdi/js';
export default defineComponent({
    components: { Icon, Btn },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        resourceType: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        total: {
            type: Number,
        },
        showTotal: {
            type: Boolean,
            default: false,
        },
        removedIds: {
            type: Array,
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
        validateResources: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const formatResourceLabel = (value) => {
            const properties = {
                [ResourceType.OBJECTS]: 'internalNumber',
                [ResourceType.PLACES]: 'name',
            };
            return value[properties[props.resourceType]];
        };
        const validationMessage = computed(() => {
            const properties = {
                [ResourceType.OBJECTS]: 'mustContainObjects',
                [ResourceType.PLACES]: 'mustContainPlaces',
            };
            return ctx.root.$t(`resources.${properties[props.resourceType]}`);
        });
        return {
            mdiPlus,
            mdiClose,
            formatResourceLabel,
            IconSize,
            BTN_THEMES,
            validationMessage,
        };
    },
});
