import { useValidation, useForm, useBrand } from '@/composables';
import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CategorySelector from '@/views/Brand/Applications/Components/Categories/CategorySelector.vue';
import CategoryForm from '@/views/Brand/Applications/Components/Categories/CategoryForm.vue';
import Resources from '@/views/Brand/Applications/Components/Resources/Resources.vue';
import { useReportFormResources } from '@/views/Brand/Applications/Report/_composables/use-report-form-resources';
import { useCategoryForm } from '@/views/Brand/Applications/Report/_composables/use-category-form';
import { getUsersList } from '@/api/requests/users';
import { useAuthUser } from '@/composables/use-auth-user';
import { useMessageFiles } from '@/views/Brand/Applications/Report/_composables/use-message-files';
import WysiwygEditor from '@/views/Brand/Applications/Forms/Components/WysiwygEditor.vue';
import { EMPTY_WYSIWYG_VALUE } from '@/components/WysiwygInput/wysiwyg.constants';
const EMPTY_FORM = {
    title: '',
    description: EMPTY_WYSIWYG_VALUE,
    resources: {
        objects: [],
        places: [],
        removedObjectIds: [],
        removedPlaceIds: [],
    },
    categoryForm: null,
    fileTokens: [],
};
export default defineComponent({
    components: {
        WysiwygEditor,
        Resources,
        CategoryForm,
        CategorySelector,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        users: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: null,
        },
        message: {
            type: Object,
            default: null,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { authUserId } = useAuthUser();
        useValidation(ctx);
        const referenceForm = ref({ ...EMPTY_FORM });
        const validateCategories = ref(false);
        const validateResources = ref(false);
        const category = ref(null);
        const editedForm = ref(null);
        const loadingContent = ref(false);
        const unsavedCategoryFormChanges = ref(false);
        if (props.report && props.message) {
            useCategoryForm(props.report, {
                editedForm,
                taskForm: referenceForm,
                category,
                loading: loadingContent,
            });
            referenceForm.value = {
                ...referenceForm.value,
                description: props.message?.message,
            };
        }
        const { form, hasUnsavedChanges, unsavedChanges } = useForm(referenceForm);
        useReportFormResources(form);
        watch(category, (value, prevValue) => {
            if (prevValue) {
                form.value = { ...EMPTY_FORM };
                editedForm.value = null;
                validateResources.value = false;
            }
            ctx.emit('category-updated', value);
        });
        const pushFormData = async () => {
            const payload = {
                ...form.value,
                title: category.value?.name || '',
            };
            ctx.emit('submit', payload);
        };
        // eslint-disable-next-line
        const onSubmit = (handleSubmit) => {
            validateCategories.value = true;
            validateResources.value = true;
            handleSubmit(pushFormData);
        };
        const onResetCategories = (reset) => {
            validateCategories.value = false;
            reset();
        };
        const isLoading = computed(() => props.loading || loadingContent.value);
        const mentions = computed(() => {
            return props.message?.users || [];
        });
        const getMentionSuggestions = async (q) => {
            const { results } = await getUsersList({
                q,
                ...(props.report && { relatedReportId: props.report.id }),
                accessToBrandId: brand.value?.id,
                omitUserIds: [props.report?.creator.id || authUserId.value],
            });
            return results;
        };
        const unsavedCategoryChanges = computed(() => {
            return ((unsavedCategoryFormChanges.value && !!category.value) ||
                !!props.report ||
                form.value.description !== EMPTY_WYSIWYG_VALUE ||
                form.value.fileTokens.length > 0);
        });
        const setCategoryFormChanges = (isFormEmpty) => {
            unsavedCategoryFormChanges.value = !isFormEmpty;
        };
        const { files, handleAddFile, handleRemoveFile, getFileTokens, clearFiles, } = useMessageFiles(ctx);
        return {
            form,
            unsavedChanges,
            hasUnsavedChanges,
            validateCategories,
            category,
            isLoading,
            onSubmit,
            onResetCategories,
            reportId: props.report?.id || null,
            editedForm,
            mentions,
            getMentionSuggestions,
            files,
            handleAddFile,
            handleRemoveFile,
            getFileTokens,
            clearFiles,
            setCategoryFormChanges,
            unsavedCategoryChanges,
            validateResources,
        };
    },
});
