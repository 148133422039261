<template>
  <content-wrapper>
    <portal :to="PortalTarget.SIDEBAR">
      <object-types-sidebar />
    </portal>
    <div class="flex justify-between pb-10">
      <div class="flex-grow">
        <div class="flex mb-2">
          <breadcrumbs
            :path="[
              {
                name: $t('object-types-view.heading'),
                url: { name: 'ObjectTypes' },
              },
            ]"
            class="mt-2 mb-1"
          ></breadcrumbs>
          <filter-toggler class="mr-4" />
        </div>
        <fetch-data
          v-slot="{ isLoading, pagination }"
          url="object-types"
          :params="computedQuery"
          :force-update="listUpdatesCounter"
          :debounce="FILTER_DEBOUNCE"
          @data-deliver="updateList"
        >
          <div class="container">
            <div v-if="isLoading" class="spinner-area">
              <icon-spinner
                class="spinner stroke-primary"
                width="64px"
                height="64px"
              />
            </div>
            <div
              v-if="objectTypesList.length"
              class="flex flex-wrap"
              :class="{ 'opacity-50': isLoading }"
            >
              <card
                v-for="objectType in objectTypesList"
                :key="objectType.id"
                :image="objectType.cdnImageUrl"
                :alt="
                  $t('object-types-view.object-type-alt', {
                    name: objectType.name,
                  })
                "
                class="mt-4 mr-4"
                cover-img
                :link="{
                  name: 'Objects',
                  query: { objectTypeIds: [objectType.id] },
                }"
              >
                {{ objectType.name }}
              </card>
            </div>
            <div class="flex w-full my-3 justify-center">
              <pagination
                v-if="objectTypesList.length"
                :page-count="lastPage"
                :current-page="routeQuery.page || 1"
                class="mt-2"
                @change-page="changePage"
              ></pagination>
            </div>
            <entries-counter
              v-if="pagination"
              url="object-types"
              :to="pagination.to"
              :from="pagination.from"
              :filtered-entries="pagination.total"
              :initial-query="{ brandId }"
            />
          </div>
        </fetch-data>
      </div>
    </div>
  </content-wrapper>
</template>

<script>
import Card from '@/components/Card/Card.vue';
import ObjectTypesSidebar from '@/views/Brand/ObjectTypes/ObjectTypesSidebar.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import EntriesCounter from '@/components/Table/EntriesCounter';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { mapGetters } from 'vuex';
import cardPagination from '@/mixins/query/cardPagination';
import { PortalTarget } from '@/constants/portal';
import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper';

export default {
  name: 'ObjectTypes',
  components: {
    Card,
    ObjectTypesSidebar,
    FetchData,
    IconSpinner,
    Pagination,
    Breadcrumbs,
    EntriesCounter,
    FilterToggler,
    ContentWrapper,
  },
  data() {
    return {
      objectTypesList: [],
      listUpdatesCounter: 0,
      lastPage: 0,
      PortalTarget,
    };
  },
  mixins: [cardPagination],
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    brandId() {
      return this.currentBrandId(this.$route);
    },
    computedQuery() {
      return {
        ...this.routeQuery,
        brandId: this.brandId,
      };
    },
  },
  methods: {
    updateList(data) {
      this.objectTypesList = data.results;
      this.lastPage = data.pagination.lastPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @apply flex flex-wrap relative;
}

.spinner-area {
  @apply py-48 w-full flex justify-center absolute z-50;
}
</style>
