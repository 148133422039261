import { FILTER_PARAMS, SORT } from '@/constants/filters';

export default {
  computed: {
    sortOptions() {
      return [
        {
          label: this.$t(`filters.${SORT.CREATED_AT_DESC}`),
          value: SORT.CREATED_AT_DESC,
        },
        {
          label: this.$t(`filters.${SORT.CREATED_AT_ASC}`),
          value: SORT.CREATED_AT_ASC,
        },
        { label: this.$t(`filters.${SORT.NAME_DESC}`), value: SORT.NAME_DESC },
        { label: this.$t(`filters.${SORT.NAME_ASC}`), value: SORT.NAME_ASC },
      ];
    },
    sortByConfig() {
      return {
        valueGetters: {
          [FILTER_PARAMS.SORT_BY]: this.getSortOption,
        },
      };
    },
  },
  created() {
    this.$set(this.filters, FILTER_PARAMS.SORT_BY, this.sortOptions[0]);
  },
  methods: {
    getSortOption(sortValue) {
      return this.sortOptions.find((option) => option.value === sortValue);
    },
  },
};
