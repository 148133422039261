import { defineComponent, ref, watch } from '@vue/composition-api';
import { SelectInput } from '@/components/Inputs';
import { EmailNotificationSettingRatio, } from '@/api/requests/notifications';
import { updateNotificationSetting } from '@/api/requests/users';
export default defineComponent({
    components: { SelectInput },
    props: {
        type: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const notificationRatioOptions = [
            {
                label: ctx.root.$t('notifications.all-the-time'),
                value: EmailNotificationSettingRatio.ALL_THE_TIME,
            },
            {
                label: ctx.root.$t('notifications.once-a-day'),
                value: EmailNotificationSettingRatio.ONCE_A_DAY,
            },
            {
                label: ctx.root.$t('notifications.disabled'),
                value: EmailNotificationSettingRatio.DISABLED,
            },
        ];
        const selectRatio = ref(notificationRatioOptions.find((option) => option.value === props.value));
        watch(selectRatio, async () => {
            if (!selectRatio.value) {
                return;
            }
            const { value: ratio } = selectRatio.value;
            try {
                await updateNotificationSetting({
                    type: props.type,
                    ratio,
                });
                ctx.root.$toast.success(ctx.root.$t('notifications.update-success'));
            }
            catch (e) {
                ctx.root.$toast.error(ctx.root.$t('notifications.update-error'));
            }
        });
        return { notificationRatioOptions, selectRatio };
    },
});
