import { createPlaceDetailsRoute } from '@/router/utils';
import { handleTranslations, } from '@/layouts/_components/Notifications/_util/notification-factory';
export const handlePlaceCreatedNotification = (notification) => {
    const { type, payload: { brand, place }, } = notification;
    return {
        getTranslations() {
            const translationPlaceholders = {
                ...place,
            };
            return handleTranslations(type, translationPlaceholders);
        },
        getRoute() {
            return createPlaceDetailsRoute(brand.id, place.id);
        },
    };
};
