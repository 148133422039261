export var ObjectsTabName;
(function (ObjectsTabName) {
    ObjectsTabName["LIST"] = "list";
    ObjectsTabName["GROUPS"] = "groups";
    ObjectsTabName["MAP"] = "map";
})(ObjectsTabName || (ObjectsTabName = {}));
export var ObjectDetailsPlacePreview;
(function (ObjectDetailsPlacePreview) {
    ObjectDetailsPlacePreview["POPOVER"] = "popover";
    ObjectDetailsPlacePreview["TEXT"] = "text";
    ObjectDetailsPlacePreview["LINK"] = "link";
})(ObjectDetailsPlacePreview || (ObjectDetailsPlacePreview = {}));
