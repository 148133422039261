<template>
  <div
    class="table-container"
    :class="{
      'table-overflow': dashboard && rows.length !== 0,
      'min-h-60': loaderVisible,
    }"
  >
    <icon-spinner
      v-if="loaderVisible"
      class="spinner stroke-primary"
      width="64px"
      height="64px"
    />
    <table
      v-if="rows.length"
      ref="table"
      class="table-auto"
      :class="[
        {
          'opacity-50': loaderVisible,
          'expandable-table': expandableRows,
        },
        tableStyle,
      ]"
    >
      <tr :class="{ 'labels-hidden': hideLabels }">
        <th v-if="checkbox" class="checkbox" @click.stop>
          <div class="ml-3">
            <checkbox
              v-if="selectAllBtn && !singleSelect"
              v-model="selectAll"
              table-header
              size="sm"
              :disabled="disabled"
              without-spacing
              @input="onSelectAll(selectAll)"
            />
          </div>
        </th>
        <th
          v-if="
            expandableRows &&
            expandButtonPosition === EXPAND_BUTTON_POSITION.START
          "
          class="expandable-header"
        ></th>
        <th v-if="withThumbs" class="thumb"></th>
        <th
          v-for="(col, colIndex) in computedCols"
          :key="`column-${colIndex}-${col}`"
          :class="[
            ...(cols[colIndex].colClass || []),
            sortingEnabled && 'sort-column',
            cols[colIndex].status && 'status-column',
            cols[colIndex].compact && 'compact',
          ]"
          @click="changeSortingAttr(colIndex)"
        >
          <sort-icon
            :rotate="sortingType === 'asc'"
            :show="colIndex === sortingColumn"
            :text="col"
          />
        </th>
        <th
          v-if="
            expandableRows &&
            expandButtonPosition === EXPAND_BUTTON_POSITION.END
          "
          class="expandable-header"
        ></th>
        <th
          v-if="withButtons"
          class="buttons"
          :class="{ ellipsis: ellipsisOnly || mobileView }"
          :style="!ellipsisOnly && !mobileView && actionButtonsStyle"
        ></th>
        <th v-if="removeBtns"></th>
      </tr>
      <template v-for="(row, index) in computedRows">
        <tr
          :key="`row-${row.id}`"
          :class="{
            'expanded-row-reference':
              expandableRows && expandedRows.includes(row.id),
          }"
          @click="$emit('record-click', row.id, rows[index])"
        >
          <td v-if="checkbox" class="checkbox" @click.stop>
            <checkbox
              size="sm"
              table
              :disabled="disabled"
              :value="
                selectedIds.includes(row[selectionKey]) ||
                isSelected(formattedRows[index])
              "
              @input="
                selectRow(
                  row[selectionKey],
                  selectedIds.includes(row[selectionKey]),
                )
              "
            />
          </td>
          <td
            v-if="
              expandableRows &&
              expandButtonPosition === EXPAND_BUTTON_POSITION.START
            "
            class="expand-cell"
          >
            <expand-button
              :is-expanded="expandedRows.includes(row.id)"
              :button-style="expandButtonStyle"
              @expand="onExpandRow(row.id)"
            />
          </td>

          <template v-if="withThumbs">
            <td class="thumb">
              <thumbnail
                :rows="rows"
                :index="index"
                :thumb-key="thumbKey"
                :thumb-cdn="thumbCdn"
                :contain-img="containImg"
              />
            </td>
          </template>
          <table-cell
            v-for="(val, valIndex) in row.values"
            :key="`row-${row.id}-val-${valIndex}`"
            :column="cols[valIndex]"
            :row="rows[index]"
            :value="val"
            :status-options="statusOptions"
            :status-colors="statusColors"
            :status-config="statusConfig"
            @change-status="(status) => $emit('change-status', status, row)"
          />
          <td
            v-if="
              expandableRows &&
              expandButtonPosition === EXPAND_BUTTON_POSITION.END
            "
            class="expand-cell end"
          >
            <expand-button
              :is-expanded="expandedRows.includes(row.id)"
              :button-style="expandButtonStyle"
              @expand="onExpandRow(row.id)"
            />
          </td>
          <template v-if="withButtons && !ellipsisOnly && !mobileView">
            <td class="buttons">
              <span class="buttons-wrapper">
                <table-button
                  v-for="btn in row.btns"
                  :key="`btn-${btn.icon}`"
                  :icon="btn.icon"
                  :disabled="btn.disabled"
                  :modal="getModal(btn.modal, rows[index])"
                  :handler="
                    () => {
                      btn.handler(row.id);
                    }
                  "
                  @click.native.stop="setCurrentRowData(row)"
                />
              </span>
            </td>
          </template>
          <td
            v-if="ellipsisOnly || (mobileView && withButtons)"
            class="relative"
          >
            <ellipsis-menu
              :items="getEllipsisItems(row, index)"
              icon-fill="fill-primary"
              class="mr-4"
              append-to-body
              @click.native.stop="setCurrentRowData(row)"
            />
          </td>
          <td v-if="removeBtns" class="flex justify-end">
            <button type="button" @click="$emit('remove', rows[index])">
              <icon :icon="mdiMinusCircleOutline" class="text-primary"></icon>
            </button>
          </td>
        </tr>
        <tr
          v-if="expandableRows && expandedRows.includes(row.id)"
          :key="`row-${row.id}-expandable`"
        >
          <td :colspan="computedColspan" class="expanded-row">
            <hr class="border-gray-light mb-4" />
            <slot
              name="details"
              :row="rows.find(({ id }) => id === row.id)"
            ></slot>
          </td>
        </tr>
        <!--        Empty row separates every two rows in expandable version-->
        <tr
          v-if="expandableRows"
          :key="`row-${row.id}-separator`"
          class="expandable-table-separator"
        >
          <th></th>
        </tr>
      </template>
      <tfoot>
        <tr v-if="showSum">
          <td :colspan="computedColspan - 2"></td>
          <td>
            <span class="text-xs">
              {{ $t('brand-offer-details-view.net') }}:
            </span>
          </td>
          <td>
            <span class="text-xs">
              {{ getNetValue(sum, 23) }} {{ $t('common.currency') }}
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
    <p
      v-if="rows.length === 0 && !loaderVisible"
      :class="{ 'dashboard-no-data': dashboard }"
      class="pt-2"
    >
      {{ $t(noDataTextKey) }}
    </p>
  </div>
</template>

<script>
import { Checkbox } from '@/components/Inputs';
import TableButton from '@/components/Table/TableGrid/components/TableButton';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { Icon } from '@/components/Icon';
import Thumbnail from '@/components/Table/TableGrid/components/Thumbnail';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import SortIcon from '@/components/Table/TableGrid/components/SortIcon';
import { mapActions, mapState } from 'vuex';
import { select, sort, actions, expand, status } from './mixins';
import TableCell from '@/components/Table/TableGrid/components/TableCell';
import { tableProps } from '@/components/Table/mixins/tableProps';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton';
import { TABLE_STYLE } from '@/constants/ui';
import { mdiMinusCircleOutline } from '@mdi/js';

export default {
  name: 'TableGrid',
  components: {
    ExpandButton,
    SortIcon,
    Thumbnail,
    Checkbox,
    TableButton,
    IconSpinner,
    Icon,
    EllipsisMenu,
    TableCell,
  },
  mixins: [select, sort, actions, expand, status],
  props: tableProps,
  data() {
    return {
      mdiMinusCircleOutline,
      TABLE_STYLE,
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),

    formattedRows() {
      return this.rowFormatter
        ? this.rows.map((row) => this.rowFormatter(row))
        : this.rows;
    },
    computedCols() {
      if (this.rows.length) {
        const colsObj = {};

        Object.entries(this.cols)
          .filter((_, i) => this.isColVisible(i))
          .forEach(([key, value]) => {
            colsObj[key] = typeof value === 'object' ? value.label : value;
          });

        return colsObj;
      }
      return [];
    },
    colIterator() {
      return this.computedCols.length
        ? this.computedCols
        : Object.keys(this.computedCols);
    },
    computedRows() {
      let rows = [];

      this.formattedRows.forEach((rowObj) => {
        let row = { values: {}, id: null, btns: [] };

        this.colIterator.forEach((key) => {
          if (rowObj[key] !== undefined) {
            this.$set(row.values, key, rowObj[key]);
          }
        });

        if (rowObj.id) this.$set(row, 'id', rowObj.id);

        if (this.withButtons && !this.mobileView) {
          const actions = this.getActions(rowObj);

          Object.entries(actions).forEach(([key, action]) => {
            row.btns.push(this.getActionButton(key, action));
          });
        }
        rows.push(row);
      });

      return rows;
    },
    computedColspan() {
      let colspan = Object.keys(this.computedCols).length;

      [
        this.checkbox,
        this.expandableRows,
        this.withThumbs,
        this.removeBtns,
        this.withButtons,
      ].forEach((property) => {
        if (property) colspan++;
      });

      return colspan;
    },
  },
  beforeDestroy() {
    this.clearCurrentRowData();
  },
  methods: {
    ...mapActions('table', ['setCurrentRowData', 'clearCurrentRowData']),
    getNetValue(value, tax) {
      return parseFloat(value * (1 - tax / 100)).toFixed(2);
    },
    isColVisible(index) {
      if (!this.mobileView) return true;

      if (typeof this.mobileCols === 'number') return index < this.mobileCols;

      return this.mobileCols.includes(index);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins/style';

.dashboard-no-data {
  @apply text-center text-gray-dark m-6 italic text-xl font-normal;
}

.flat {
  @apply text-sm;
  border-spacing: 0;

  th {
    @apply pb-2 pt-3 text-gray-darker border-b border-gray-light;
  }

  td {
    @apply h-10 border-t-0 font-medium;

    &:last-child,
    &:first-child {
      @apply rounded-none border-l-0 border-r-0;
    }
  }
}

.table-overflow {
  height: 376px;
}

.table-fixed table {
  table-layout: fixed;
}
</style>
