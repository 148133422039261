import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBrand, useForm } from '@/composables';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Resources from '@/views/Brand/Applications/Components/Resources/Resources.vue';
import { TextInput } from '@/components/Inputs';
import { useReportFormResources } from '@/views/Brand/Applications/Report/_composables/use-report-form-resources';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { useAuthUser } from '@/composables/use-auth-user';
import WysiwygEditor from '@/views/Brand/Applications/Forms/Components/WysiwygEditor.vue';
import { EMPTY_WYSIWYG_VALUE } from '@/components/WysiwygInput/wysiwyg.constants';
const EMPTY_FORM = {
    title: '',
    description: EMPTY_WYSIWYG_VALUE,
    resources: {
        objects: [],
        places: [],
        removedObjectIds: [],
        removedPlaceIds: [],
    },
    fileTokens: [],
};
export default defineComponent({
    components: {
        WysiwygEditor,
        TextInput,
        ValidationProvider,
        ValidationObserver,
        Resources,
    },
    props: {
        users: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: null,
        },
        message: {
            type: Object,
            default: null,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { authUserId } = useAuthUser();
        const referenceForm = ref({ ...EMPTY_FORM });
        const loadingContent = ref(false);
        if (props.report) {
            referenceForm.value = {
                ...referenceForm.value,
                title: props.report.title,
            };
            referenceForm.value = {
                ...referenceForm.value,
                description: props.message?.message,
            };
        }
        const { form, hasUnsavedChanges, unsavedChanges } = useForm(referenceForm);
        useReportFormResources(form);
        const pushFormData = () => {
            ctx.emit('submit', form.value);
        };
        const isLoading = computed(() => props.loading || loadingContent.value);
        const mentions = computed(() => {
            return props.message?.users || [];
        });
        const getMentionSuggestions = createMentionUserFn({
            ...(props.report && { relatedReportId: props.report.id }),
            accessToBrandId: brand.value?.id,
            omitUserIds: [props.report?.creator.id || authUserId.value],
        });
        return {
            form,
            unsavedChanges,
            hasUnsavedChanges,
            pushFormData,
            reportId: props.report?.id || null,
            isLoading,
            mentions,
            getMentionSuggestions,
        };
    },
});
