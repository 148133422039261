<template>
  <fetch-data
    v-slot="{ results, pagination, isLoading }"
    :url="content"
    :params="computedQuery"
    :force-update="forceUpdate"
    :debounce="routeComponent ? FILTER_DEBOUNCE : false"
    @data-deliver="$emit('data-deliver', $event)"
  >
    <table-grid
      v-bind="props"
      :table-style="tableStyle"
      :loader-visible="isLoading || loading"
      :with-buttons="actions"
      :rows="results"
      :sorting="tableSorting"
      sorting-enabled
      @sort-change="changeSorting"
      @record-click="$emit('record-click', $event)"
      @select="(keys, rows, options) => $emit('select', keys, rows, options)"
      @change-status="(status, row) => $emit('change-status', status, row)"
      @remove="$emit('remove', $event)"
    >
      <template #details="props">
        <slot name="details" v-bind="props"></slot>
      </template>
    </table-grid>

    <table-pagination
      :path="content"
      :pagination="pagination"
      :is-loading="isLoading"
      :query="computedQuery"
      class="px-4 md:px-0"
    />
  </fetch-data>
</template>

<script>
import TableGrid from '@/components/Table/TableGrid/TableGrid.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import isEmpty from 'lodash.isempty';
import props from '@/components/Table/mixins/tableProps';
import sort from '@/components/Table/mixins/sort';
import query from '@/components/Table/mixins/query';
import TablePagination from '@/views/_components/Table/TablePagination';

export default {
  components: {
    TablePagination,
    TableGrid,
    FetchData,
  },
  mixins: [props, sort, query],
  props: {
    actions: {
      type: [Boolean, Object, Function],
      default: false,
    },
    content: {
      type: String,
      required: true,
    },
    forceUpdate: {
      type: [Boolean, Number],
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    entriesCounter: {
      type: Boolean,
      default: true,
    },
    tableStyle: String,
  },
  methods: {
    isNotEmpty(val) {
      return !isEmpty(val);
    },
  },
};
</script>

<style scoped>
.pagination-wrapper {
  @apply flex justify-center mt-2;
}
</style>
