var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fetch-data',{staticClass:"relative",attrs:{"url":"places","params":_vm.placesQuery,"force-update":_vm.listUpdatesCounter,"debounce":_vm.Debounce.DEFAULT},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [(results && results.length)?_c('table-display',{staticClass:"mt-4",attrs:{"rows":results,"columns":_vm.columns,"sort":_vm.sortConfig,"is-row-expanded":_vm.isPlaceDetailsExpanded,"is-row-clickable":false},on:{"record-click":function($event){return _vm.$emit('open', $event.id)},"sort":_vm.sort},scopedSlots:_vm._u([{key:"checkboxHeader",fn:function(){return [_c('div',{staticClass:"-ml-1 -mt-2"},[_c('checkbox',{attrs:{"table-header":"","without-spacing":""},on:{"input":function($event){return _vm.onSelectAll(results)}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)]},proxy:true},{key:"expandButtonCell",fn:function(ref){
var row = ref.row;
return [_c('expand-button',{attrs:{"is-expanded":_vm.isPlaceDetailsExpanded(row)},on:{"expand":function($event){return _vm.toggleExpandPlaceDetails(row)}}})]}},{key:"checkboxCell",fn:function(ref){
var row = ref.row;
return [_c('checkbox',{attrs:{"table":"","value":_vm.selectedEntries.some(function (entry) { return entry.id === row.id; })},on:{"input":function($event){return _vm.changeState(row, $event)}}})]}},{key:"actionsCell",fn:function(ref){
var row = ref.row;
return [_c('table-actions',{attrs:{"actions":_vm.getPlaceActions(row)}})]}},{key:"expandedRow",fn:function(ref){
var row = ref.row;
return [_c('place-preview',{attrs:{"place-id":row ? row.id : ''}})]}}],null,true)}):(!isLoading)?_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('common.no-results')))]):_vm._e(),_c('table-pagination',{attrs:{"path":"places","pagination":pagination,"is-loading":isLoading,"query":_vm.placesQuery}}),(isLoading)?_c('table-loader'):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }