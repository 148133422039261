import { defineComponent } from '@vue/composition-api';
import { Badge } from '@/components/Badge';
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
export default defineComponent({
    name: 'CloseBadge',
    components: {
        Badge,
        Icon,
    },
    setup() {
        return { mdiClose, IconSize };
    },
});
