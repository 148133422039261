<template>
  <div>
    <div
      v-for="(component, index) in value"
      :key="`component-${index}`"
      class="w-3/5 mt-8 py-3 px-4 border border-gray-light border-dashed bg-white"
    >
      <form-component-creator
        :component="component"
        :disabled="disabled"
        @deleteComponent="deleteComponent(index)"
        @updateComponent="updateComponent($event, index)"
        @cloneComponent="cloneComponent"
      />
    </div>

    <div
      class="w-3/5 mt-8 py-3 px-4 border border-gray-light border-dashed flex justify-center"
    >
      <button
        type="button"
        :disabled="disabled"
        class="bg-primary rounded-full p-1"
        @click="onAddFormComponent"
      >
        <icon :icon="mdiPlus" :size="IconSize.XL" class="text-white" />
      </button>
    </div>
  </div>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import { FormComponentCreator } from '@/views/_components/CustomForms';
import { mdiPlus } from '@mdi/js';

export default {
  components: {
    Icon,
    FormComponentCreator,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { mdiPlus, IconSize };
  },
  methods: {
    onAddFormComponent() {
      this.$emit('input', [
        ...this.value,
        {
          type: 'text_input',
          isRequired: false,
          title: '',
          description: '',
        },
      ]);
    },
    cloneComponent(component) {
      this.$emit('input', [
        ...this.value,
        {
          type: component.type,
          isRequired: component.isRequired,
          title: component.title,
          items: component.items,
          description: component.description,
        },
      ]);
    },
    deleteComponent(index) {
      this.$emit(
        'input',
        this.value.filter((_, i) => i !== index),
      );
    },
    updateComponent({ component }, index) {
      this.$emit(
        'input',
        this.value.map((item, i) => {
          return i === index ? component : item;
        }),
      );
    },
  },
};
</script>
