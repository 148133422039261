import { getObjectRoute, getPlaceRoute } from '@/util/routing';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      places: [],
      place: null,
      placeSearch: '',
      objectSearch: '',
      activeTab: null,
      showObjectsTab: true,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    ...mapGetters('ui', ['isSidebarOpen']),
    brandId() {
      return this.currentBrandId(this.$route);
    },
    placeColumns() {
      return {
        number: {
          label: this.$t('add-brand-report-view.no'),
          routerLink: {
            getRoute: getPlaceRoute,
          },
        },
        name: {
          label: this.$t('add-brand-report-view.name'),
        },
      };
    },
    objectColumns() {
      return {
        internalNumber: {
          label: this.$t('add-brand-report-view.no'),
          routerLink: {
            getRoute: getObjectRoute,
          },
          colClass: ['w-24'],
        },
        place: {
          label: this.$t('add-brand-report-view.place'),
          routerLink: {
            getRoute: (object) => getPlaceRoute(object.place),
          },
          formatter: ({ number }) => number,
        },
        internalStatus: {
          label: this.$t('object-status.status'),
          formatter: (value) => this.$t(`object-status.${value}`),
          status: true,
          colClass: ['w-24'],
          disabled: true,
        },
      };
    },
  },
  methods: {
    ...mapActions('ui', ['toggleSidebar']),
    getID(entry) {
      return entry.id;
    },
    onSetForm(form) {
      if (!form) this.showObjectsTab = true;
      else {
        this.showObjectsTab = form.canContainObjects;
      }
    },
    canReportContainObjects(report) {
      if (!report || report.canContainObjects === undefined) return true;
      return report.canContainObjects;
    },
    onOpenSidebar(tab) {
      this.activeTab = tab;

      if (!this.isSidebarOpen) this.toggleSidebar();
    },
  },
};
