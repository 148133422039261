var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container",class:{
    'table-overflow': _vm.dashboard && _vm.rows.length !== 0,
    'min-h-60': _vm.loaderVisible,
  }},[(_vm.loaderVisible)?_c('icon-spinner',{staticClass:"spinner stroke-primary",attrs:{"width":"64px","height":"64px"}}):_vm._e(),(_vm.rows.length)?_c('table',{ref:"table",staticClass:"table-auto",class:[
      {
        'opacity-50': _vm.loaderVisible,
        'expandable-table': _vm.expandableRows,
      },
      _vm.tableStyle ]},[_c('tr',{class:{ 'labels-hidden': _vm.hideLabels }},[(_vm.checkbox)?_c('th',{staticClass:"checkbox",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"ml-3"},[(_vm.selectAllBtn && !_vm.singleSelect)?_c('checkbox',{attrs:{"table-header":"","size":"sm","disabled":_vm.disabled,"without-spacing":""},on:{"input":function($event){return _vm.onSelectAll(_vm.selectAll)}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}}):_vm._e()],1)]):_vm._e(),(
          _vm.expandableRows &&
          _vm.expandButtonPosition === _vm.EXPAND_BUTTON_POSITION.START
        )?_c('th',{staticClass:"expandable-header"}):_vm._e(),(_vm.withThumbs)?_c('th',{staticClass:"thumb"}):_vm._e(),_vm._l((_vm.computedCols),function(col,colIndex){return _c('th',{key:("column-" + colIndex + "-" + col),class:(_vm.cols[colIndex].colClass || []).concat( [_vm.sortingEnabled && 'sort-column'],
          [_vm.cols[colIndex].status && 'status-column'],
          [_vm.cols[colIndex].compact && 'compact'] ),on:{"click":function($event){return _vm.changeSortingAttr(colIndex)}}},[_c('sort-icon',{attrs:{"rotate":_vm.sortingType === 'asc',"show":colIndex === _vm.sortingColumn,"text":col}})],1)}),(
          _vm.expandableRows &&
          _vm.expandButtonPosition === _vm.EXPAND_BUTTON_POSITION.END
        )?_c('th',{staticClass:"expandable-header"}):_vm._e(),(_vm.withButtons)?_c('th',{staticClass:"buttons",class:{ ellipsis: _vm.ellipsisOnly || _vm.mobileView },style:(!_vm.ellipsisOnly && !_vm.mobileView && _vm.actionButtonsStyle)}):_vm._e(),(_vm.removeBtns)?_c('th'):_vm._e()],2),_vm._l((_vm.computedRows),function(row,index){return [_c('tr',{key:("row-" + (row.id)),class:{
          'expanded-row-reference':
            _vm.expandableRows && _vm.expandedRows.includes(row.id),
        },on:{"click":function($event){return _vm.$emit('record-click', row.id, _vm.rows[index])}}},[(_vm.checkbox)?_c('td',{staticClass:"checkbox",on:{"click":function($event){$event.stopPropagation();}}},[_c('checkbox',{attrs:{"size":"sm","table":"","disabled":_vm.disabled,"value":_vm.selectedIds.includes(row[_vm.selectionKey]) ||
              _vm.isSelected(_vm.formattedRows[index])},on:{"input":function($event){_vm.selectRow(
                row[_vm.selectionKey],
                _vm.selectedIds.includes(row[_vm.selectionKey])
              )}}})],1):_vm._e(),(
            _vm.expandableRows &&
            _vm.expandButtonPosition === _vm.EXPAND_BUTTON_POSITION.START
          )?_c('td',{staticClass:"expand-cell"},[_c('expand-button',{attrs:{"is-expanded":_vm.expandedRows.includes(row.id),"button-style":_vm.expandButtonStyle},on:{"expand":function($event){return _vm.onExpandRow(row.id)}}})],1):_vm._e(),(_vm.withThumbs)?[_c('td',{staticClass:"thumb"},[_c('thumbnail',{attrs:{"rows":_vm.rows,"index":index,"thumb-key":_vm.thumbKey,"thumb-cdn":_vm.thumbCdn,"contain-img":_vm.containImg}})],1)]:_vm._e(),_vm._l((row.values),function(val,valIndex){return _c('table-cell',{key:("row-" + (row.id) + "-val-" + valIndex),attrs:{"column":_vm.cols[valIndex],"row":_vm.rows[index],"value":val,"status-options":_vm.statusOptions,"status-colors":_vm.statusColors,"status-config":_vm.statusConfig},on:{"change-status":function (status) { return _vm.$emit('change-status', status, row); }}})}),(
            _vm.expandableRows &&
            _vm.expandButtonPosition === _vm.EXPAND_BUTTON_POSITION.END
          )?_c('td',{staticClass:"expand-cell end"},[_c('expand-button',{attrs:{"is-expanded":_vm.expandedRows.includes(row.id),"button-style":_vm.expandButtonStyle},on:{"expand":function($event){return _vm.onExpandRow(row.id)}}})],1):_vm._e(),(_vm.withButtons && !_vm.ellipsisOnly && !_vm.mobileView)?[_c('td',{staticClass:"buttons"},[_c('span',{staticClass:"buttons-wrapper"},_vm._l((row.btns),function(btn){return _c('table-button',{key:("btn-" + (btn.icon)),attrs:{"icon":btn.icon,"disabled":btn.disabled,"modal":_vm.getModal(btn.modal, _vm.rows[index]),"handler":function () {
                    btn.handler(row.id);
                  }},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setCurrentRowData(row)}}})}),1)])]:_vm._e(),(_vm.ellipsisOnly || (_vm.mobileView && _vm.withButtons))?_c('td',{staticClass:"relative"},[_c('ellipsis-menu',{staticClass:"mr-4",attrs:{"items":_vm.getEllipsisItems(row, index),"icon-fill":"fill-primary","append-to-body":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setCurrentRowData(row)}}})],1):_vm._e(),(_vm.removeBtns)?_c('td',{staticClass:"flex justify-end"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('remove', _vm.rows[index])}}},[_c('icon',{staticClass:"text-primary",attrs:{"icon":_vm.mdiMinusCircleOutline}})],1)]):_vm._e()],2),(_vm.expandableRows && _vm.expandedRows.includes(row.id))?_c('tr',{key:("row-" + (row.id) + "-expandable")},[_c('td',{staticClass:"expanded-row",attrs:{"colspan":_vm.computedColspan}},[_c('hr',{staticClass:"border-gray-light mb-4"}),_vm._t("details",null,{"row":_vm.rows.find(function (ref) {
                    var id = ref.id;

                    return id === row.id;
        })})],2)]):_vm._e(),(_vm.expandableRows)?_c('tr',{key:("row-" + (row.id) + "-separator"),staticClass:"expandable-table-separator"},[_c('th')]):_vm._e()]}),_c('tfoot',[(_vm.showSum)?_c('tr',[_c('td',{attrs:{"colspan":_vm.computedColspan - 2}}),_c('td',[_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('brand-offer-details-view.net'))+": ")])]),_c('td',[_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getNetValue(_vm.sum, 23))+" "+_vm._s(_vm.$t('common.currency'))+" ")])])]):_vm._e()])],2):_vm._e(),(_vm.rows.length === 0 && !_vm.loaderVisible)?_c('p',{staticClass:"pt-2",class:{ 'dashboard-no-data': _vm.dashboard }},[_vm._v(" "+_vm._s(_vm.$t(_vm.noDataTextKey))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }