import { defineComponent, ref, watch } from '@vue/composition-api';
import { getFullName } from '@/util/user';
import { Direction, Keys, } from '@/components/WysiwygInput/wysiwyg.constants';
import MentionItem from '@/components/WysiwygInput/_components/MentionItem.vue';
import { useScrollOptions } from '@/components/WysiwygInput/_composables/use-scroll-options';
export default defineComponent({
    name: 'MentionList',
    components: { MentionItem },
    props: {
        items: {
            type: Array,
            required: true,
        },
        command: {
            type: Function,
            required: true,
        },
    },
    setup(props, ctx) {
        const selectedIndex = ref(0);
        const { option, scrollContainer } = useScrollOptions(selectedIndex);
        const handleArrowNavigation = (direction) => {
            selectedIndex.value =
                (selectedIndex.value + direction) % props.items.length;
        };
        const selectUser = (user) => {
            if (user) {
                ctx.emit('mention', user);
                props.command({ id: user.id, label: getFullName(user) });
            }
        };
        // This method is used by render function (suggestion.js)
        const onKeyDown = ({ event }) => {
            switch (event.key) {
                case Keys.ARROW_UP:
                    handleArrowNavigation(Direction.UP);
                    return true;
                case Keys.ARROW_DOWN:
                    handleArrowNavigation(Direction.DOWN);
                    return true;
                case Keys.ENTER:
                    selectUser(props.items[selectedIndex.value]);
                    return true;
                default:
                    return false;
            }
        };
        watch(() => props.items, () => {
            selectedIndex.value = 0;
        }, { immediate: true });
        return {
            selectedIndex,
            getFullName,
            selectUser,
            onKeyDown,
            option,
            scrollContainer,
        };
    },
});
