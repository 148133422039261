<template>
  <div class="h-full">
    <edit-category-sidebar
      v-if="editData"
      v-model="showEditSidebar"
      :category="editData"
      :root-category-id="categoryId"
      @save-changes="onEdit"
    />
    <overlap-sidebar
      width="50%"
      side="right"
      :is-open="showFormSidebar"
      class="pt-0"
      @close="onAddForm"
    >
      <tabs class="max-h-full overflow-scroll pb-48">
        <tab :name="$t('categories-view.form-list')" selected>
          <category-sidebar
            :selected-items="selectedForms"
            class="static"
            @submit="onSubmitForms"
          />
        </tab>
      </tabs>
    </overlap-sidebar>
    <div class="px-10 pb-36 pt-8">
      <div class="mb-6">
        <breadcrumbs
          v-if="brand"
          :path="[
            {
              name: `${$t('categories-view.categories')}`,
              url: { name: 'Categories' },
            },
            { name: brand.name },
            { name: `${$t('categories-view.edit-category')}` },
          ]"
        />
      </div>
      <div class="min-w-full md:min-w-1/2 inline-block">
        <tree-category-add
          v-if="currentCategory && children"
          :key="componentKey"
          class="mt-4"
          :label="currentCategory.name"
          :nodes="children"
          :has-children="currentCategory.hasChildren"
          :parent-id="currentCategory.id"
          :icon="!iconIsDeleted ? currentCategory.iconUrl : null"
          root
          :depth="-1"
          @categoryUpdated="onUpdateCategory"
          @editCategory="onEditCategory"
          @addForm="onAddForm"
          @openForm="onAddForm"
          @forceRerender="forceRerender"
        />
      </div>
    </div>
    <footer-menu class="bg-white">
      <div class="flex justify-between">
        <div class="flex items-center">
          <btn
            :disabled="loading"
            :is-loading="loading"
            class="w-48 mr-3"
            @click="saveTree"
          >
            <span v-if="!loading">{{ $t('common.save') }}</span>
          </btn>
        </div>
        <router-link :to="{ name: 'Categories' }">
          <btn theme="none">{{ $t('common.cancel') }}</btn>
        </router-link>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar.vue';
import TreeCategoryAdd from '@/components/Tree/TreeCategoryAdd';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import CategorySidebar from '@/components/Tree/CategorySidebar';
import VueStore from '@/store';
import { matchRouteWithName } from '@/util/routing';
import EditCategorySidebar from '@/views/Brand/Settings/Categories/Components/EditCategorySidebar';

export default {
  name: 'EditCategory',
  components: {
    EditCategorySidebar,
    CategorySidebar,
    Breadcrumbs,
    Btn,
    FooterMenu,
    OverlapSidebar,
    Tabs,
    Tab,
    TreeCategoryAdd,
  },
  props: {
    categoryId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showEditSidebar: false,
      categoryName: '',
      iconUrl: '',
      iconIsDeleted: false,
      loading: false,
      editData: null,
      showFormSidebar: false,
      children: [],
      componentKey: 0,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      categories: 'categories',
      forms: 'forms',
      selectedForms: 'selectedForms',
      currentCategory: 'currentCategory',
      currentTreeCategory: 'currentTreeCategory',
    }),
    ...mapGetters('brand', ['currentBrandId']),
    isSelectedCategoryRoot() {
      return this.editData?.id && this.editData.id === this.categoryId;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!matchRouteWithName(to, 'Categories')) {
      VueStore.dispatch('brand/clearCategories');
    }
    next();
  },
  async mounted() {
    await this.loadChildren();
  },
  methods: {
    ...mapActions('brand', [
      'fetchBrandCategory',
      'editCategory',
      'deleteCategoryIcon',
      'setMainCategory',
      'fetchBrandSubCategory',
      'editCategoryWithForm',
      'fetchMainCategory',
    ]),
    async loadChildren() {
      const { data } = await this.fetchBrandCategory({
        brandId: this.currentBrandId(this.$route),
        categoryId: this.categoryId,
      });

      this.children = data?.results;
    },
    async forceRerender() {
      await this.loadChildren();
      this.componentKey += 1;
    },
    async onUpdateCategory() {
      const { data } = await this.fetchBrandSubCategory({
        brandId: this.currentBrandId(this.$route),
        categoryId: this.$route.params.categoryId,
      });

      this.children = data?.results;
    },
    onEditCategory(data) {
      this.showEditSidebar = true;
      this.showFormSidebar = false;
      this.editData = data;
    },
    getIconUrl(iconUrl) {
      if (!iconUrl) {
        return null;
      }
      if (iconUrl === this.editData.iconUrl) {
        return undefined;
      }
      return iconUrl;
    },
    async onEdit({ name, iconUrl }) {
      const { data } = await this.editCategory({
        id: this.editData.id,
        data: {
          name,
          iconUrl: this.getIconUrl(iconUrl),
        },
      });

      this.$toast.success(this.$t('categories-view.category-edited'));

      if (this.isSelectedCategoryRoot) this.setMainCategory(data);
      else this.forceRerender();

      this.showEditSidebar = false;
      this.editData = null;
    },
    onAddForm() {
      this.showEditSidebar = false;
      this.showFormSidebar = !this.showFormSidebar;
    },
    saveTree() {
      this.$toast.success(this.$t('categories-view.category-saved'));
      this.$router.push({ name: 'Categories' });
    },
    async onSubmitForms(data) {
      const categoryId = this.currentTreeCategory;
      const promiseTimeOut = new Promise((resolve) => {
        setTimeout(resolve, 1000);
        /*
            this is a hack as backend probably takes some time to recalculate
            form/category dependencies and sends old data
         */
      });

      await Promise.allSettled([
        data.add.map((value) =>
          this.editCategoryWithForm({
            formId: value,
            categoryId,
          }),
        ),
        data.remove.map((value) =>
          this.editCategoryWithForm({
            formId: value,
            categoryId: null,
          }),
        ),
        promiseTimeOut,
      ]);

      this.showFormSidebar = false;
      this.$toast.success(this.$t('categories-view.category-edited'));

      this.forceRerender();
    },
  },
};
</script>
