<template>
  <div class="file-uploader-container">
    <btn class="w-50" theme="secondary" @click.native="openModal">
      {{ $t('common.add') }} {{ $t(`file-upload.${type}`) }}
    </btn>
    <portal v-if="showModal" to="modals">
      <modal :show.sync="showModal">
        <div class="w-full px-12">
          <h3 class="mt-6">
            {{ $t('common.add') }} {{ $t(`file-upload.${type}`) }}
          </h3>
          <label class="text-center pt-2 pb-0 w-full block">
            <span class="label">{{ $t('file-upload.name') }}</span>
            <text-input
              v-model="AttachmentName"
              :placeholder="$t('file-upload.name-placeholder')"
            ></text-input>
          </label>
          <label class="text-center py-2 w-full block">
            <span class="label">{{ $t('file-upload.file') }}</span>
            <upload-area
              v-model="file"
              :is-uploading.sync="isUploading"
              :is-error.sync="isError"
              :accepted-files="acceptedFiles"
            />
          </label>
          <div class="w-full flex justify-center">
            <btn
              :disabled="isUploading || !file"
              class="w-48 mb-6"
              @click="
                addDocument({
                  target: target,
                  params: {
                    name: AttachmentName ? AttachmentName : file.name,
                    type: type,
                    url: file.url,
                  },
                })
              "
            >
              {{ $t('common.add') }}
            </btn>
          </div>
        </div>
      </modal>
    </portal>
    <upload-list
      :type="type"
      :list="list"
      :use-deletion-queue="useDeletionQueue"
    ></upload-list>
  </div>
</template>

<script>
import UploadList from '@/components/Uploader/UploadList.vue';
import UploadArea from '@/components/Uploader/UploadArea.vue';
import Modal from '@/components/Modal/Modal.vue';
import Btn from '@/components/Button/Button.vue';
import { TextInput } from '@/components/Inputs';
import { mapActions } from 'vuex';
import { EventBus } from '@/util/event-bus.js';

export default {
  name: 'FileUploader',
  components: {
    UploadList,
    UploadArea,
    Btn,
    Modal,
    TextInput,
  },
  props: {
    type: {
      type: String,
      default: 'document',
    },
    target: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    useDeletionQueue: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      isUploading: false,
      isError: false,
      file: null,
      AttachmentName: '',
    };
  },
  computed: {
    acceptedFiles() {
      switch (this.type) {
        case 'multimedia':
          return 'image/*,video/*,audio/*';
        case 'report':
          return 'image/*,video/*,audio/*,.ppt,.pptx,.xls,.xlsx,.doc,.docx,.pdf,.txt';
        default:
          return '.ppt,.pptx,.xls,.xlsx,.doc,.docx,.pdf,.txt';
      }
    },
  },
  methods: {
    ...mapActions('attachment', ['addToUploadQueue']),
    addDocument(payload) {
      this.addToUploadQueue(payload);
      EventBus.$emit('file-upload');
      this.showModal = false;
      this.AttachmentName = '';
      this.file = null;
    },
    openModal() {
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-uploader-container {
  @apply mt-6;
}
.label {
  @apply text-gray-darkest block my-2;
}
</style>
