import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';

export const checkFiltersMatch = (filters, initial) => {
  if (!filters || isEmpty(filters)) {
    return !initial || isEmpty(initial);
  }

  return Object.entries(filters).reduce((match, [key, value]) => {
    if (!value) return match && !initial?.[key];
    else if (!initial?.[key]) return false;

    return match && isEqual(value, initial[key]);
  }, true);
};

export const getQueryFromFilters = (
  filters,
  formatters,
  applyFormatters = true,
) => {
  const result = {};

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        result[key] =
          applyFormatters && formatters[key] ? formatters[key](value) : value;
      }
    });
  }

  return result;
};

export const getRouteKey = (route) => {
  const params = Object.entries(route.params)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .map(([key, value]) => `${key}-${value}`);

  // NOTE: Adding params is essential - f.e. filters for objects within each object type should be independent from each other
  return `${route.name}-${params.join('--')}`;
};

export const getIntValue = (value, defaultValue) => {
  const int = parseInt(value);
  return isNaN(int) ? defaultValue : int;
};

export const getAppliedFilters = (filters, defaultFilters = {}) => {
  const result = { ...defaultFilters };

  Object.entries(filters).forEach(([key, value]) => {
    if (![null, undefined].includes(value)) result[key] = value;
  });

  return result;
};
