var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"structureForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"pb-4 mt-4 flex justify-end"},[_c('btn',{staticClass:"w-30",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.btnText))])],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mb-5",attrs:{"label":_vm.$t('structures-view.name'),"placeholder":_vm.$t('structures-view.name-input-placeholder'),"error-message":errors[0],"required":""},model:{value:(_vm.structureName),callback:function ($$v) {_vm.structureName=$$v},expression:"structureName"}})]}}],null,true)}),(!_vm.root && _vm.brand)?_c('select-input',{staticClass:"my-4",attrs:{"id":"coordinator","url":"users","query":{
        brandId: _vm.brand.id,
      },"option-value-name":"id","label":_vm.$t('structures-view.coordinators'),"clearable":"","autocomplete":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var selection = ref.selection;
return [_c('span',[_vm._v(_vm._s(selection.firstName)+" "+_vm._s(selection.lastName))])]}},{key:"option",fn:function(ref){
      var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName))])]}}],null,true),model:{value:(_vm.coordinator),callback:function ($$v) {_vm.coordinator=$$v},expression:"coordinator"}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }