<template>
  <div
    class="card"
    :class="{
      'cursor-pointer': link,
      [cardClass]: true,
      'number-title': numberTitle,
    }"
    @click="onClick"
  >
    <div class="card-image-container" @click="redirect(link)">
      <div class="card-image">
        <image-view
          :src="image"
          :cover-img="coverImg"
          :alt="alt"
          :size="ImageSize.CARD"
        />
      </div>
    </div>
    <div class="bottom-actions" :class="{ highlight }">
      <span
        v-if="number"
        class="card-title absolute m-auto left-0 right-0 w-full text-center secondary"
      >
        {{ number }}
      </span>
      <div :class="[linkClass]" @click="redirect(link)">
        <span class="link-span">
          <slot />
        </span>
      </div>
      <ellipsis-menu
        v-if="contextMenu"
        append-to-body
        class="mr-1"
        :items="contextMenu"
      />
    </div>
  </div>
</template>

<script>
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { ImageView, ImageSize } from '@/components/ImageView';

export default {
  name: 'Card',
  components: {
    EllipsisMenu,
    ImageView,
  },
  props: {
    name: {
      type: String,
      default: 'client',
    },
    image: {
      type: String,
      default: null,
    },
    link: {
      type: [Object, Boolean],
      default: false,
    },
    contextMenu: {
      type: [Object, Boolean],
      default: false,
    },
    number: {
      type: Number,
      default: 0,
    },
    cardClass: {
      type: String,
      default: 'h-60 w-60',
    },
    linkClass: {
      type: String,
      default: 'link',
    },
    coverImg: {
      type: Boolean,
      default: false,
    },
    numberTitle: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ImageSize,
    };
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    redirect(link) {
      if (link) {
        this.$router.push(link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @apply min-w-28 rounded overflow-hidden border-solid border border-gray-light box-border outline-none text-gray-darkest bg-white flex flex-col justify-between;

  &-image-container {
    @apply flex items-center justify-center h-full overflow-hidden;
  }

  &-image {
    @apply h-full w-full flex justify-center items-center overflow-hidden;
    .image {
      @apply w-full h-full min-w-28 object-contain;

      &.cover {
        @apply object-cover;
      }
    }
  }

  .bottom-actions {
    @apply relative border-t border-gray-light w-full h-full font-semibold flex-shrink-0;
    height: 56px;

    &.highlight {
      @apply border border-green border-t-4 rounded-b;
    }
  }

  .link {
    @apply h-full w-full flex items-center justify-center text-center px-4;

    .link-span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.number-title {
    .bottom-actions {
      @apply relative h-auto;

      .card-title {
        top: -14px;
        width: max-content;
        @apply px-2 text-primary rounded bg-white;
      }

      .link {
        @apply h-auto;
        margin: 10px 0;
      }
    }
  }
}
</style>
