import { Icon, IconSize } from '@/components/Icon';
import { getFullName } from '@/util/user';
import { defineComponent } from '@vue/composition-api';
import { mdiClose, mdiPlus } from '@mdi/js';
export default defineComponent({
    components: { Icon },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        removedUserIds: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isCategoryForm: Boolean,
    },
    setup() {
        return { IconSize, mdiClose, mdiPlus, getFullName };
    },
});
