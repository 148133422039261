import { defineComponent, ref } from '@vue/composition-api';
import { Modal } from '@/components/Modal';
import { TextInput } from '@/components/Inputs';
import { Btn, BtnTheme } from '@/components/Button';
import { addObjectsToGroup, createObjectsGroup, updateObjectsGroup, } from '@/api/requests/objects';
import { useBrand } from '@/composables';
import isEmpty from 'lodash.isempty';
import { ObjectsGroupModalActionType } from '@/views/Brand/Objects/_components/ObjectsGroupModals';
export default defineComponent({
    components: { Modal, TextInput, Btn },
    props: {
        actionType: {
            type: String,
            required: true,
        },
        selectedObjects: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Boolean,
            required: true,
        },
        objectsGroupToEdit: {
            type: Object,
        },
    },
    setup(props, ctx) {
        const { objectsGroupToEdit, selectedObjects, actionType } = props;
        const name = ref(objectsGroupToEdit?.name ?? '');
        const description = ref(objectsGroupToEdit?.description ?? '');
        const { brand } = useBrand();
        const handleClose = () => {
            ctx.emit('close');
            name.value = '';
            description.value = '';
        };
        const handleEditObjectsGroup = async () => {
            if (!objectsGroupToEdit) {
                return;
            }
            try {
                await updateObjectsGroup(objectsGroupToEdit.id, {
                    name: name.value,
                    description: description.value,
                });
                ctx.root.$toast.success(ctx.root.$t('objects-groups.edit-success'));
                ctx.emit('submit');
            }
            catch (e) {
                ctx.root.$toast.error(ctx.root.$t('objects-groups.edit-error'));
            }
        };
        const handleCreateObjectsGroup = async () => {
            try {
                const { id } = await createObjectsGroup({
                    name: name.value,
                    description: description.value,
                    brandId: brand.value.id,
                });
                if (!isEmpty(selectedObjects)) {
                    await addObjectsToGroup(id, {
                        objectIds: props.selectedObjects,
                    });
                }
                ctx.root.$toast.success(ctx.root.$t('objects-groups.create-success'));
                ctx.emit('submit');
            }
            catch (e) {
                ctx.root.$toast.error(ctx.root.$t('objects-groups.create-error'));
            }
        };
        const actionHandlersMap = {
            [ObjectsGroupModalActionType.CREATE_GROUP]: handleCreateObjectsGroup,
            [ObjectsGroupModalActionType.EDIT_GROUP]: handleEditObjectsGroup,
        };
        const contentMap = {
            confirmBtn: {
                [ObjectsGroupModalActionType.CREATE_GROUP]: ctx.root.$t('common.add'),
                [ObjectsGroupModalActionType.EDIT_GROUP]: ctx.root.$t('common.save'),
            },
            title: {
                [ObjectsGroupModalActionType.CREATE_GROUP]: ctx.root.$t('objects-groups.create-group'),
                [ObjectsGroupModalActionType.EDIT_GROUP]: ctx.root.$t('objects-groups.edit-group'),
            },
        };
        const handleSubmit = async () => {
            await actionHandlersMap[actionType]();
            handleClose();
        };
        return {
            name,
            description,
            BtnTheme,
            handleClose,
            handleSubmit,
            contentMap,
        };
    },
});
