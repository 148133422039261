import { computed, watch } from '@vue/composition-api';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
export const useReportFormResources = (form) => {
    const { addedPlaces, removedPlaces } = usePlaceResourcesStore();
    const { addedObjects, removedObjects } = useObjectResourcesStore();
    const resources = computed(() => ({
        places: addedPlaces.value,
        removedPlaceIds: removedPlaces.value.map(({ id }) => id),
        objects: addedObjects.value,
        removedObjectIds: removedObjects.value.map(({ id }) => id),
    }));
    const setResources = () => {
        form.value = {
            ...form.value,
            resources: resources.value,
        };
    };
    watch(resources, setResources, { immediate: true });
};
