<template>
  <ul class="breadcrumbs">
    <li v-for="(item, index) in path" :key="index" class="breadcrumb">
      <router-link :to="item.url ? item.url : '#'">{{ item.name }}</router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Breadcrumbs',
  props: {
    path: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  .breadcrumb {
    &:first-child {
      a {
        @apply text-3xl text-gray-darkest ml-0 mr-3;
      }
    }
    &:not(:first-child) {
      display: inline-block;

      a {
        @apply mr-3;
      }
      a::before {
        content: '/';
        position: relative;
        left: -4px;
      }
    }
    a {
      @apply text-base text-gray-dark leading-none;
      white-space: nowrap;
    }
  }

  @screen md {
    @apply flex items-end;
  }

  &.one-column {
    @apply items-center flex-col;
    &:first-child {
      a {
        @apply mr-0;

        &:before {
          content: none;
        }
      }
    }
  }
}
</style>
