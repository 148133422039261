import { computed, defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import { getStructuresList, setStructureObjectTypeBudget, } from '@/api/requests/structures';
import ArrowSvg from '@/assets/icons/arrow-down.svg';
import { Icon, IconSize } from '@/components/Icon';
import PencilSvg from '@/assets/icons/pencil.svg';
import { BtnTheme } from '@/components/Btn';
import { TextInput } from '@/components/Inputs';
import { mdiCheck, mdiClose } from '@mdi/js';
import { BudgetActions } from '@/views/Brand/Places/_components/budget-tree-node/_components/budget-actions';
import { ExpandedBudgetStructure } from '@/views/Brand/Places/_components/budget-tree-node/_components/expanded-budget-structure';
import { formatMoney } from '@/util/formatters';
import { getBudgetStructureLevel } from '@/views/Brand/Places/_components/budget-tree-node/_utils';
import { useRouteQuery } from '@/composables';
export default defineComponent({
    name: 'BudgetTreeNode',
    components: {
        ExpandedBudgetStructure,
        TextInput,
        Icon,
        BudgetActions,
    },
    props: {
        structure: {
            type: Object,
            required: true,
        },
        objectType: Object,
        editLevel: Number,
    },
    setup(props, ctx) {
        const params = ctx.root.$route.params;
        const isExpanded = ref(false);
        const isLoading = ref(false);
        const isEdit = ref(false);
        const budget = ref(0);
        const childBudgetStructure = ref([]);
        const isChildBudgetDataVisible = ref(false);
        const currentLevel = getBudgetStructureLevel(props.structure.path);
        const isLevelEditable = computed(() => currentLevel === props.editLevel);
        const { routeQuery } = useRouteQuery(ctx);
        const isBudgetDataVisible = ({ totalBudget, path }) => {
            const structureLevel = getBudgetStructureLevel(path);
            const isLevelEditable = structureLevel === props.editLevel;
            return totalBudget !== undefined || isLevelEditable;
        };
        const showBudgetData = computed(() => isChildBudgetDataVisible.value ||
            isLevelEditable.value ||
            (!props.editLevel && props.structure.places.length > 0));
        const onEdit = (structure) => {
            budget.value = structure.budget || 0;
            isEdit.value = true;
        };
        const onAccept = async (structure) => {
            if (!props.objectType) {
                return;
            }
            await setStructureObjectTypeBudget(structure.id, props.objectType.id, {
                budget: parseInt(budget.value.toString()),
            });
            ctx.emit('on-accept');
            await fetchStructure();
            isEdit.value = false;
        };
        const onReject = () => {
            budget.value = 0;
            isEdit.value = false;
        };
        const fetchStructure = async () => {
            const { results } = await getStructuresList({
                brandId: params.id,
                parentId: props.structure.id,
                objectTypeId: props.objectType?.id,
                withBudget: true,
                containPlace: routeQuery.value.q,
            });
            childBudgetStructure.value = results;
        };
        watch(() => ({ objectType: props.objectType }), fetchStructure);
        watch(routeQuery, async () => {
            if (isExpanded.value) {
                await fetchStructure();
            }
            isExpanded.value = false;
        });
        const toggleStructure = async () => {
            isExpanded.value = !isExpanded.value;
            if (isExpanded.value) {
                isLoading.value = true;
                try {
                    await fetchStructure();
                }
                finally {
                    isLoading.value = false;
                }
            }
        };
        const refetchNodes = async () => {
            ctx.emit('on-accept');
            await fetchStructure();
        };
        onMounted(async () => {
            await fetchStructure();
            isChildBudgetDataVisible.value = childBudgetStructure.value.some((structure) => {
                return isBudgetDataVisible(structure);
            });
        });
        return {
            isExpanded,
            IconSize,
            ArrowSvg,
            formatMoney,
            toggleStructure,
            childBudgetStructure,
            isLoading,
            currentLevel,
            PencilSvg,
            BtnTheme,
            isLevelEditable,
            isEdit,
            budget,
            mdiCheck,
            mdiClose,
            onEdit,
            onAccept,
            onReject,
            refetchNodes,
            showBudgetData,
        };
    },
});
