import { uploadReportFile, } from '@/api/requests/reports';
import { computed, ref, watch } from '@vue/composition-api';
import { fileNameRegex, getFileDataUrl, getUploadTokens } from '@/util/files';
import { MAX_ALLOWED_FILE_SIZE, MAX_ALLOWED_FILES_COUNT, } from '@/constants/report';
import { v4 } from 'uuid';
import clonedeep from 'lodash.clonedeep';
import axios from 'axios';
import { useAbort } from '@/composables/use-abort';
export const useMessageFiles = (ctx, message) => {
    const existingMessageFiles = ref([]);
    const uploadingFiles = ref([]);
    const newLocalFiles = ref([]);
    const uploadedTokens = ref(new Map());
    const responseFileTokens = ref([]);
    const { addSource: addFileSource, getSourceCancelToken: getFileCancelToken, abort: abortFileUpload, } = useAbort();
    watch(() => message, () => {
        existingMessageFiles.value =
            message && message.value ? clonedeep(message.value.files) : [];
    }, { deep: true, immediate: true });
    const urlParsedMessageFiles = computed(() => {
        return existingMessageFiles.value.map((file) => ({
            ...file,
            url: file.url ?? '',
        }));
    });
    const files = computed(() => [
        ...urlParsedMessageFiles.value,
        ...newLocalFiles.value,
    ]);
    const isUploading = (fileName) => {
        return uploadingFiles.value.some((uploadedFile) => uploadedFile.name === fileName);
    };
    const validateDuplicates = (file) => {
        const isExistingMessageDuplicated = existingMessageFiles.value.some((existingFile) => {
            return existingFile.fileName === file.name;
        });
        const isNewLocalFileDuplicated = newLocalFiles.value.some((existingFile) => {
            return existingFile.fileName === file.name;
        });
        return isExistingMessageDuplicated || isNewLocalFileDuplicated;
    };
    const handleAddFile = async (e) => {
        const target = e.target;
        const addedFiles = [];
        if (!target.files || files.value.length === MAX_ALLOWED_FILES_COUNT) {
            ctx.root.$toast.error(ctx.root.$i18n.t('image-uploader.amount-error'));
            return;
        }
        for (const file of Array.from(target.files)) {
            if (file.size > MAX_ALLOWED_FILE_SIZE) {
                ctx.root.$toast.error(ctx.root.$i18n.t('image-uploader.size-error'));
                return;
            }
            if (validateDuplicates(file)) {
                ctx.root.$toast.error(ctx.root.$i18n.t('file-upload.duplicate-error'));
                return;
            }
            if (!new RegExp(fileNameRegex).test(file.name)) {
                ctx.root.$toast.error(ctx.root.$i18n.t('file-upload.name-error'));
                return;
            }
            const fileDataUrl = await getFileDataUrl(file);
            addedFiles.push({
                id: `local-${v4()}`,
                fileName: file.name,
                fileSize: file.size,
                url: fileDataUrl,
                file,
            });
        }
        newLocalFiles.value = [...newLocalFiles.value, ...addedFiles];
    };
    const handleRemoveFile = (file) => {
        existingMessageFiles.value = existingMessageFiles.value.filter(({ id }) => id !== file.id);
        newLocalFiles.value = newLocalFiles.value.filter(({ id }) => id !== file.id);
        removeUploadingFile(file.fileName);
        uploadedTokens.value.delete(file.fileName);
        abortFileUpload(file.fileName);
    };
    const getFileTokens = async () => {
        await Promise.all(newLocalFiles.value.map(async ({ file }) => {
            if (isUploading(file.name)) {
                return;
            }
            try {
                addFileSource(file.name);
                const fileUploadData = {
                    file,
                    setFileProgress: setUploadingFile,
                    cancelToken: getFileCancelToken(file.name),
                };
                const uploadToken = await uploadReportFile(fileUploadData);
                ctx.root.$toast.success(ctx.root.$i18n.t('file-upload.success'));
                uploadedTokens.value.set(file.name, uploadToken);
            }
            catch (e) {
                if (axios.isCancel(e)) {
                    return ctx.root.$toast.warning(ctx.root.$i18n.t('file-upload.abort'));
                }
                ctx.root.$toast.error(ctx.root.$i18n.t('file-upload.error'));
                removeUploadingFile(file.name);
            }
        }));
        const newFileTokens = Array.from(uploadedTokens.value, ([, token]) => {
            return token;
        });
        return [...getUploadTokens(existingMessageFiles.value), ...newFileTokens];
    };
    const clearFiles = () => {
        newLocalFiles.value = [];
    };
    const updateUploadingFile = (existingFileIndex, uploadingMessageFile) => {
        uploadingFiles.value.splice(existingFileIndex, 1, uploadingMessageFile);
    };
    const addUploadingFile = (uploadingMessageFile) => {
        uploadingFiles.value.push(uploadingMessageFile);
    };
    const removeUploadingFile = (fileName) => {
        uploadingFiles.value = uploadingFiles.value.filter(({ name }) => name !== fileName);
    };
    const getUploadingFile = (name) => {
        const uploadingFileIndex = uploadingFiles.value.findIndex((file) => file.name === name);
        const uploadingFile = uploadingFiles.value[uploadingFileIndex];
        return { uploadingFile, uploadingFileIndex };
    };
    const setUploadingFile = (uploadingMessageFile) => {
        const { uploadingFile, uploadingFileIndex } = getUploadingFile(uploadingMessageFile.name);
        if (!uploadingFile) {
            addUploadingFile(uploadingMessageFile);
            return;
        }
        updateUploadingFile(uploadingFileIndex, uploadingMessageFile);
    };
    const clearUploadedTokens = () => {
        uploadedTokens.value.clear();
    };
    return {
        files,
        uploadingFiles,
        responseFileTokens,
        getUploadingFile,
        handleAddFile,
        handleRemoveFile,
        getFileTokens,
        clearFiles,
        clearUploadedTokens,
    };
};
