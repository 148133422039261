import FilterToggler from '@/components/Filters/FilterToggler.vue';
import MapSidebar from '@/views/Brand/Map/MapSidebar.vue';
import { OBJECT_LOCALIZATION } from '@/constants/object';
import { PortalTarget } from '@/constants/portal';
import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBrand, useRouteQuery } from '@/composables';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';
import { ObjectStatus } from '@/api/requests/objects';
export default defineComponent({
    name: 'Map',
    components: {
        ClusteredMap,
        MapSidebar,
        FilterToggler,
        ContentWrapper,
    },
    setup(_, ctx) {
        const { brand } = useBrand();
        const { routeQuery } = useRouteQuery(ctx);
        const areAllDataSetsDelivered = ref(false);
        const computedPlacesUrl = computed(() => {
            return routeQuery.value.placeId
                ? `places/${routeQuery.value.placeId}`
                : 'places';
        });
        const computedPlacesQuery = computed(() => {
            const mapQuery = { ...routeQuery.value };
            delete mapQuery.page;
            delete mapQuery.objectTypeIds;
            return mapQuery.placeId
                ? {}
                : {
                    ...mapQuery,
                    brandId: brand.value.id,
                    isPositionDefined: true,
                };
        });
        const computedObjectsQuery = computed(() => {
            const mapQuery = { ...routeQuery.value };
            const { placeId } = mapQuery;
            delete mapQuery.page;
            delete mapQuery.objectTypes;
            delete mapQuery.placeId;
            return {
                ...mapQuery,
                internalStatus: ObjectStatus.ACTIVE,
                brandId: brand.value.id,
                ...(placeId && { placeIds: placeId }),
                localizations: [OBJECT_LOCALIZATION.OUTSIDE],
                limit: 10000,
            };
        });
        return {
            brand,
            routeQuery,
            PortalTarget,
            MapDataType,
            computedPlacesUrl,
            computedPlacesQuery,
            computedObjectsQuery,
            areAllDataSetsDelivered,
        };
    },
});
