import { computed, ref } from '@vue/composition-api';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
export const useSelectOptions = (props, ctx) => {
    const loadedOptions = ref([]);
    const { updatesCounter: optionsUpdateCounter, update } = useUpdatesCounter();
    const parsedOptions = computed(() => {
        return [...props.options, ...loadedOptions.value];
    });
    const selectedValues = computed(() => {
        if (props.value == null) {
            return [];
        }
        return Array.isArray(props.value) ? props.value : [props.value];
    });
    const isOptionSelected = (element) => {
        return selectedValues.value.some((selectedValue) => selectedValue[props.optionValueName] === element[props.optionValueName]);
    };
    const unselectValue = (element) => {
        ctx.emit('input', (props.value || []).filter((propValue) => propValue !== element));
        update();
    };
    const clearValues = () => {
        ctx.emit('input', null);
        update();
    };
    const handleSelect = (element, callback) => {
        if (props.disableItem?.(element)) {
            return;
        }
        update();
        if (!props.multiple) {
            ctx.emit('input', element);
            callback();
            return;
        }
        if (!Array.isArray(props.value)) {
            ctx.emit('input', [element]);
        }
        else if (isOptionSelected(element)) {
            ctx.emit('input', props.value.filter((propValue) => propValue[props.optionValueName] !== element[props.optionValueName]));
        }
        else {
            ctx.emit('input', [...props.value, element]);
        }
        callback();
    };
    return {
        isOptionSelected,
        selectedValues,
        loadedOptions,
        parsedOptions,
        unselectValue,
        clearValues,
        handleSelect,
        optionsUpdateCounter,
        update,
    };
};
