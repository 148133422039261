<template>
  <div class="upload-item" :class="{ 'to-delete': toDelete }">
    <image-modal
      :image-url="url"
      :alt="$slots.default[0].text"
      :full-height="false"
    >
      <div
        v-if="displayThumbnails && isMultimedia"
        class="thumbnail cursor-pointer"
      >
        <img :src="url" />
      </div>
    </image-modal>

    <div class="upload-container">
      <div class="flex items-center h-full" @click="download">
        <div class="extension-icon">
          <span class="uppercase text-primary text-3xs">{{ extension }}</span>
        </div>
        <div class="upload-info">
          <h3 class="upload-title" :title="$slots.default[0].text">
            <slot></slot>
          </h3>
          <p v-if="!oldFile" class="add-label">
            {{ $t('file-upload.to-save') }}
          </p>
          <p v-else-if="!toDelete" class="upload-date">{{ date }}</p>
          <p v-else class="upload-restore">
            <span class="remove-label">{{ $t('file-upload.to-delete') }}</span>
            <button
              class="restore-btn"
              @click.stop="removeAttachmentFromDeleteQueue(id)"
            >
              {{ $t('file-upload.restore') }}
            </button>
          </p>
        </div>
      </div>
      <context-menu v-if="!readOnly && !toDelete" class="h-full">
        <li>
          <a :href="url" target="_blank">{{ $t('file-upload.download') }}</a>
        </li>
        <li @click="onDelete(id)">{{ $t('common.delete') }}</li>
      </context-menu>
    </div>
  </div>
</template>

<script>
import ContextMenu from '@/components/Menus/ContextMenu.vue';
import { extension } from '@/util/formatters';
import { mapActions } from 'vuex';
import { ImageModal } from '@/components/Modal';
import { EventBus } from '@/util/event-bus.js';
import { isMultimediaFile } from '@/util/files';

export default {
  components: {
    ContextMenu,
    ImageModal,
  },
  props: {
    date: {
      type: [String, Date],
      required: true,
    },
    oldFile: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'document',
    },
    id: {
      type: [String, Date],
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    instantDeletion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toDelete: false,
      imgLoaded: false,
    };
  },
  computed: {
    extension() {
      const ext = extension(this.url);
      switch (ext) {
        case 'svg+xml':
          return 'svg';
        case 'x-msdownload':
          return 'exe';
        default:
          return ext;
      }
    },
    displayThumbnails() {
      return (
        ['multimedia', 'report'].includes(this.type) &&
        isMultimediaFile(extension(this.url))
      );
    },
  },
  methods: {
    ...mapActions('attachment', [
      'addToDeleteQueue',
      'removeFromDeleteQueue',
      'removeFromUploadQueue',
    ]),
    onDelete(id) {
      if (this.oldFile) {
        if (!this.instantDeletion) {
          this.addToDeleteQueue({ id });
          this.toDelete = true;
        } else EventBus.$emit('file-delete', id);
      } else {
        this.removeFromUploadQueue(id);
      }
    },
    removeAttachmentFromDeleteQueue(id) {
      this.removeFromDeleteQueue(id);
      this.toDelete = false;
    },
    isMultimedia() {
      return isMultimediaFile(this.extension);
    },
    download() {
      if (!this.toDelete && !this.isMultimedia()) {
        open(this.url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss">
.upload-item {
  @apply w-64 mt-3.5 mx-2 bg-white rounded border-gray-light border flex flex-col relative;
  .thumbnail {
    @apply w-full h-36 bg-gray-lighter rounded-t overflow-hidden flex;
    img {
      @apply object-cover w-full;
    }
  }
  &.to-delete {
    @apply cursor-default;
    background: rgba(255, 255, 255, 0.5);
    .upload-title,
    .extension-icon,
    .thumbnail {
      @apply pointer-events-none opacity-40;
    }
    &:hover {
      .restore-btn {
        @apply block;
      }
      .remove-label {
        @apply hidden;
      }
    }
  }
}
.upload-container {
  @apply flex items-center justify-between h-14 cursor-pointer;
}
.upload-info {
  @apply ml-2 overflow-x-hidden relative;
  width: 173px;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 100%;
    box-shadow: -20px 0px 9px -10px white inset;
  }
}
.upload-title {
  @apply mt-px text-sm font-semibold leading-tight whitespace-no-wrap;
}
.upload-date {
  @apply text-xs text-gray-dark leading-tight;
}
.add-label {
  @apply text-gray-darkest text-xs leading-tight;
}
.upload-restore {
  @apply text-xs text-gray-dark leading-tight;
  .restore-btn {
    @apply hidden text-gray-darkest;
    &:hover {
      text-decoration: underline;
    }
  }
  .remove-label {
    @apply text-red;
  }
}

.extension-icon {
  @apply border border-primary rounded h-7 w-7 ml-3 mr-1 my-2 flex flex-shrink-0 items-center justify-center bg-white;
}
</style>
