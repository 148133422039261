import { ref } from '@vue/composition-api';
import { removeObjectsFromGroup } from '@/api/requests/objects';
import { useAuthUser } from '@/composables/use-auth-user';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
const { useActions: useCommonActions } = createNamespacedHelpers('common');
export const useObjectActions = (props, ctx) => {
    const { isGroupObjectsList } = props;
    const { update, updatesCounter } = useUpdatesCounter();
    const deletionInProgress = ref(false);
    const { isAdmin } = useAuthUser();
    const onEditBtnClick = (object) => {
        ctx.root.$router.push({
            name: 'EditObject',
            params: { objectId: object.id, objectTypeId: object.objectTypeId },
        });
    };
    const { delete: deleteObject } = useCommonActions(['delete']);
    const handleObjectRemove = async (id) => {
        deletionInProgress.value = true;
        try {
            await deleteObject({ type: 'objects', id });
            update();
            ctx.root.$toast.success(ctx.root.$t('object-list-view.removed-successfully'));
        }
        finally {
            deletionInProgress.value = false;
        }
    };
    const handleGroupObjectRemove = async (id) => {
        try {
            const { objectsGroupId } = ctx.root.$router.currentRoute.params;
            await removeObjectsFromGroup(objectsGroupId, { objectIds: [id] });
            update();
            ctx.root.$toast.success(ctx.root.$t('object-list-view.removed-successfully'));
        }
        catch (e) {
            alert('error');
        }
    };
    const getDeleteObjectModalContent = (object) => {
        if (isGroupObjectsList) {
            return {
                title: ctx.root.$t('object-list-view.remove-group-object-title'),
                description: ctx.root.$t('object-list-view.remove-group-object-description'),
            };
        }
        return {
            title: `${ctx.root.$t('common.delete')} ${object.objectType?.name}`,
            description: ctx.root.$t('confirm-delete-modal.modal-description', {
                type: ctx.root.$t(`confirm-delete-modal.object`),
                additionalText: `(${object.internalNumber})`,
            }),
        };
    };
    const getObjectActions = (object) => {
        if (!isAdmin.value)
            return [];
        const { title, description } = getDeleteObjectModalContent(object);
        const objectActions = [
            {
                name: 'delete',
                handler: () => isGroupObjectsList
                    ? handleGroupObjectRemove(object.id)
                    : handleObjectRemove(object.id),
                modal: {
                    type: 'confirm',
                    title,
                    description,
                    btnText: ctx.root.$t('common.delete'),
                },
                icon: DeleteSvg,
            },
        ];
        if (!isGroupObjectsList) {
            objectActions.unshift({
                name: 'edit',
                handler: () => onEditBtnClick(object),
                icon: EditSvg,
            });
        }
        return objectActions;
    };
    return {
        getObjectActions,
        updatesCounter,
        update,
    };
};
