var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProfileLayout',{staticClass:"mb-16",attrs:{"title":_vm.$t('profile-vacations.title'),"header":_vm.$t('profile-vacations.header'),"description":_vm.$t('profile-vacations.description')}},[(_vm.isLoading)?_c('backdrop-loader'):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"flex flex-col gap-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('switch-input',{attrs:{"label-position":_vm.LabelPosition.RIGHT,"label":_vm.$t('profile-vacations.toggle-label')},model:{value:(_vm.form.isEnabled),callback:function ($$v) {_vm.$set(_vm.form, "isEnabled", $$v)},expression:"form.isEnabled"}}),_c('div',{staticClass:"flex flex-col gap-2 md:flex-row md:gap-10 mt-5 mb-2"},[_c('ValidationProvider',{attrs:{"rules":{ required: _vm.form.isEnabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-input',{staticClass:"w-full md:w-64",attrs:{"disabled-dates":_vm.startDateDisabledDates,"error-message":errors[0],"disabled":!_vm.form.isEnabled,"placeholder":_vm.$t('profile-vacations.date-from'),"label":((_vm.$t('profile-vacations.date')) + " (" + (_vm.$t(
              'profile-vacations.date-from'
            )) + ")")},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})]}}],null,true)}),_c('div',{staticClass:"flex items-center"},[_c('checkbox',{staticClass:"mt-5",attrs:{"disabled":!_vm.form.isEnabled},model:{value:(_vm.form.isEndDateEnabled),callback:function ($$v) {_vm.$set(_vm.form, "isEndDateEnabled", $$v)},expression:"form.isEndDateEnabled"}}),_c('ValidationProvider',{attrs:{"rules":{ required: _vm.form.isEndDateEnabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('date-input',{staticClass:"w-full md:w-64",attrs:{"clearable":"","error-message":errors[0],"disabled-dates":_vm.endDateDisabledDates,"disabled":!_vm.form.isEndDateEnabled || !_vm.form.isEnabled,"placeholder":_vm.$t('profile-vacations.date-to'),"label":((_vm.$t('profile-vacations.date')) + " (" + (_vm.$t(
                'profile-vacations.date-to'
              )) + ")")},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"rules":{ required: _vm.form.isEnabled, max: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('text-input',{attrs:{"error-message":errors[0],"disabled":!_vm.form.isEnabled,"type":"textarea","label":_vm.$t('profile-vacations.message')},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}],null,true)}),_c('footer-menu',{staticClass:"bg-white flex flex-row-reverse md:flex-row justify-end"},[_c('btn',{staticClass:"w-full md:w-50",attrs:{"theme":_vm.BtnTheme.PRIMARY,"size":_vm.BtnSize.LG,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }