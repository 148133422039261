var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fetch-data',{staticClass:"relative",attrs:{"url":"objects/groups/all","params":_vm.objectsQuery,"force-update":_vm.listUpdatesCounter,"debounce":_vm.Debounce.DEFAULT},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [(results && results.length)?_c('table-display',{staticClass:"mt-4",attrs:{"rows":results,"columns":_vm.columns,"table-style":_vm.TableStyle.Flat,"sort":_vm.sortConfig},on:{"record-click":function($event){return _vm.$router.push({
          name: 'ObjectsGroup',
          params: { objectsGroupId: $event.id },
        })},"sort":_vm.sort},scopedSlots:_vm._u([{key:"actionsCell",fn:function(ref){
        var row = ref.row;
return [_c('table-actions',{attrs:{"actions":_vm.getObjectsGroupActions(row)}})]}}],null,true)}):(!isLoading)?_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('common.no-results')))]):_vm._e(),_c('table-pagination',{attrs:{"path":"objects/groups/all","pagination":pagination,"is-loading":isLoading,"query":_vm.objectsQuery}}),(isLoading)?_c('table-loader'):_vm._e()]}}])}),(_vm.showCreateGroupModal || _vm.showEditObjectsGroupModal)?_c('portal',{attrs:{"to":"modals"}},[_c('objects-group-modal',{attrs:{"show":_vm.showCreateGroupModal,"action-type":_vm.ObjectsGroupModalActionType.CREATE_GROUP},on:{"close":function($event){return _vm.$emit('close-group-modal')},"submit":function($event){return _vm.update()}}}),_c('objects-group-modal',{attrs:{"objects-group-to-edit":_vm.objectsGroupToEdit,"show":_vm.showEditObjectsGroupModal,"action-type":_vm.ObjectsGroupModalActionType.EDIT_GROUP},on:{"close":function($event){_vm.showEditObjectsGroupModal = false},"submit":function($event){return _vm.update()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }