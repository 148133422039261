var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-header',{staticClass:"mt-5",attrs:{"title":_vm.offerHeader,"created-at":_vm.currentOffer.createdAt,"updated-at":_vm.currentOffer.updatedAt,"route":_vm.currentOffer.report
      ? {
          name: 'ReportDetails',
          params: { reportId: _vm.currentOffer.report.id },
        }
      : null,"route-label":_vm.currentOffer.report
      ? ((_vm.$t('add-brand-offer-view.report')) + ": #" + (_vm.currentOffer.report.numberByBrand))
      : ''},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('navigation-arrow',{attrs:{"direction":_vm.CONTROLLER_DIRECTION.PREV,"disabled":!_vm.offers.prev,"to":{
        name: _vm.ClientRouteName.OFFER_DETAILS,
        params: { offerId: _vm.offers.prev },
        query: _vm.query,
      }}})]},proxy:true},{key:"right",fn:function(){return [_c('navigation-arrow',{attrs:{"direction":_vm.CONTROLLER_DIRECTION.NEXT,"disabled":!_vm.offers.next,"to":{
        name: _vm.ClientRouteName.OFFER_DETAILS,
        params: { offerId: _vm.offers.next },
        query: _vm.query,
      }}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }