<template>
  <div>
    <page-header
      :title="header"
      :sub-title="content.objectType.name"
      :created-at="content.createdAt"
      :updated-at="content.updatedAt"
      :route="{ name: 'OfferDetails', params: { offerId: activeOfferId } }"
      :route-label="`${$t('object-details-view.offer')}: #${
        offer.internalNumber
      }`"
    >
      <template #left>
        <navigation-arrow
          :direction="CONTROLLER_DIRECTION.PREV"
          :disabled="!prevObjectId"
          :to="{
            name: 'ObjectInOfferDetails',
            params: { objectId: prevObjectId },
          }"
        />
      </template>
      <template #right>
        <navigation-arrow
          :direction="CONTROLLER_DIRECTION.NEXT"
          :disabled="!nextObjectId"
          :to="{
            name: 'ObjectInOfferDetails',
            params: { objectId: nextObjectId },
          }"
        />
      </template>
    </page-header>
    <div class="flex items-center justify-between mt-4">
      <field-with-label
        id="localization"
        class="flex-grow flex-shrink"
        :label="$t('object-details-view.localization')"
        :value="address"
      />
      <status-toggler
        class="flex-grow-0 flex-shrink-0 ml-2"
        :options="getStatusOptions(status || content.internalStatus)"
        :colors="OBJECT_STATUS_COLORS"
        :status-translation="statusConfig.translation"
        :current-status="status || content.internalStatus"
        @change-status="onStatusUpdate"
      />
    </div>
  </div>
</template>
<script>
import StatusToggler from '@/components/StatusToggler/StatusToggler';
import objectStatus from '@/views/Brand/Objects/mixins/objectStatus';
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/PageHeader/PageHeader';
import FieldWithLabel from '@/components/Expandable/components/FieldWithLabel';
import NavigationArrow from '@/components/NavigationArrow/NavigationArrow';
import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
import { getFullAddress } from '@/util/common';

export default {
  components: {
    FieldWithLabel,
    PageHeader,
    StatusToggler,
    NavigationArrow,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CONTROLLER_DIRECTION,
      status: null,
    };
  },
  computed: {
    ...mapGetters('offer', ['activeOfferId', 'nextObjectId', 'prevObjectId']),
    ...mapState('object', { object: 'details' }),
    header() {
      return `${this.$t('object-details-view.object').toUpperCase()} ${
        this.content.internalNumber
      }`;
    },
    address() {
      return getFullAddress(this.content);
    },
  },
  mixins: [objectStatus],
  methods: {
    ...mapActions('object', ['updateObject']),
    async onStatusUpdate(status) {
      await this.updateObject({
        objectId: this.object.id,
        data: {
          internalStatus: status,
        },
      });
      this.status = status;
      this.$toast.success(this.$t(`object-details-view.status-updated`));
    },
  },
};
</script>
