import { OBJECT_STATUS_COLORS, OBJECT_STATUS_MAP } from '@/constants/object';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      OBJECT_STATUS_MAP,
      OBJECT_STATUS_COLORS,
    };
  },
  computed: {
    ...mapGetters('user', ['hasAdminRights']),
    statusConfig() {
      return {
        translation: 'object-status',
      };
    },
  },
  methods: {
    ...mapActions('object', ['updateObject']),
    getStatusOptions(currentStatus) {
      if (!this.hasAdminRights) return [];

      const statuses = Object.values(OBJECT_STATUS_MAP);

      return statuses.filter(
        (status) =>
          ![currentStatus, OBJECT_STATUS_MAP.IN_OFFER].includes(status),
      );
    },
    async updateStatus(id, status) {
      await this.updateObject({
        objectId: id,
        data: {
          internalStatus: status,
        },
      });
    },
  },
};
