import { defineComponent, computed, } from '@vue/composition-api/dist/vue-composition-api';
import { isToday, isYesterday, isThisYear, format } from 'date-fns';
import { getDateFnsLocale } from '@/i18n';
export default defineComponent({
    name: 'ChatDateDivider',
    props: {
        date: {
            type: Date,
            required: true,
        },
    },
    setup(props, ctx) {
        const { $i18n } = ctx.root;
        const parsedDate = computed(() => {
            if (isToday(props.date)) {
                return $i18n.t('common.today');
            }
            if (isYesterday(props.date)) {
                return $i18n.t('common.yesterday');
            }
            if (isThisYear(props.date)) {
                return format(props.date, 'PPPP', { locale: getDateFnsLocale() });
            }
            return format(props.date, 'PPP', { locale: getDateFnsLocale() });
        });
        return { parsedDate };
    },
});
