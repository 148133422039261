<template>
  <div class="scroll-container" :class="{ [direction]: true }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'y',
      validate: (value) => ['x', 'y'].includes(value),
    },
  },
};
</script>
<style lang="scss" scoped>
.scrollable-container {
  &.x {
    @apply overflow-x-auto overflow-y-hidden;

    &::-webkit-scrollbar {
      @apply h-0.5;
    }

    &::-webkit-scrollbar-thumb {
      @apply w-16;
    }
  }
  &.y {
    @apply overflow-y-scroll overflow-x-hidden;

    &::-webkit-scrollbar {
      @apply w-0.5;
    }

    &::-webkit-scrollbar-thumb {
      @apply h-16;
    }
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-primary;
  }
}
</style>
