var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {}, _obj[_vm.inputStyle] = true, _obj['input-focus'] =  _vm.isFocused, _obj['input-error'] =  _vm.isError, _obj )},[(_vm.label)?_c('label',{staticClass:"flex",attrs:{"for":_vm.id}},[_c('span',{staticClass:"text-input-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.label && _vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),(_vm.helperLabel)?_c('strong',{staticClass:"text-input-helper-label"},[_vm._v(" "+_vm._s(_vm.helperLabel)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-wrapper"},[(_vm.type !== 'textarea')?_c('input',{ref:"input",staticClass:"text-input input-border",class:[
        { error: _vm.isError },
        _vm.treeCategory ? 'input-tree' : '',
        _vm.className ],attrs:{"id":_vm.id,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"name":_vm.name,"step":_vm.step},domProps:{"value":_vm.value},on:{"change":_vm.handleInput,"input":_vm.handleInput,"focus":_vm.onFocus,"blur":_vm.onBlur}}):_c('textarea',{ref:"input",staticClass:"text-input text-area",class:[
        { error: _vm.isError },
        _vm.treeCategory ? 'input-tree' : '',
        _vm.className ],attrs:{"id":_vm.id,"type":_vm.type,"placeholder":_vm.placeholder,"rows":_vm.rows,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"change":_vm.handleInput,"input":_vm.handleInput,"focus":_vm.onFocus,"blur":_vm.onBlur}}),(_vm.errorMsg)?_c('span',{staticClass:"text-input-error"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('div',{staticClass:"icon-wrapper"},[(_vm.icon)?_c('icon',{staticClass:"input-icon",attrs:{"icon":_vm.icon},nativeOn:{"click":function($event){return _vm.$refs.input.focus()}}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }