import { Btn, BtnTheme } from '@/components/Button';
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
export default {
    components: { Icon, Btn },
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        closeButton: {
            type: Boolean,
            default: false,
        },
        closeButtonClass: String,
    },
    setup() {
        return { mdiClose, BtnTheme, IconSize };
    },
};
