import { SystemRole } from '@/api/requests/users';
import { addSupervisorToReport, deleteSupervisorFromReport, } from '@/api/requests/reports';
import { useAuthUser } from '@/composables';
import { computed, ref } from '@vue/composition-api';
import { SYSTEM_ROLES } from '@/store/modules/user';
import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
export const useSupervisor = (props, ctx) => {
    const { hasRole } = useAuthUser();
    const activeSupervisor = ref(props.supervisor);
    const activeSupervisorToggleHandle = async (user, reportId) => {
        if (!activeSupervisor.value) {
            return;
        }
        if (reportId && !props.editOnSave) {
            await deleteSupervisorFromReport(reportId, activeSupervisor.value.id);
        }
        if (user.id !== activeSupervisor.value?.id &&
            reportId &&
            !props.editOnSave) {
            await addSupervisorToReport(reportId, user.id);
            activeSupervisor.value = user;
            return;
        }
        activeSupervisor.value = null;
    };
    const toggleSupervisorHandle = async (user, reportId, supervisor) => {
        ctx.emit(RECIPIENTS_EVENTS.UPDATE_SUPERVISOR, supervisor?.id === user.id ? null : user);
        if (!reportId) {
            return;
        }
        if (activeSupervisor.value) {
            await activeSupervisorToggleHandle(user, reportId);
            return;
        }
        if (!props.editOnSave) {
            await addSupervisorToReport(reportId, user.id);
        }
        activeSupervisor.value = user;
    };
    const getSupervisorStatus = (user, reportId, supervisor) => {
        const isSupervisorModel = supervisor && (!reportId || props.editOnSave);
        if (isSupervisorModel) {
            return supervisor?.id === user.id;
        }
        return activeSupervisor.value?.id === user.id;
    };
    const isSupervisorToggleDisabled = computed(() => {
        return !(hasRole(SystemRole.CONTRACTOR) ||
            hasRole(SystemRole.ADMIN) ||
            hasRole(SystemRole.SUPER_ADMIN));
    });
    const showSupervisorColumn = (users) => {
        return users.some((user) => user.systemRole === SystemRole.CONTRACTOR);
    };
    const showSupervisorToggle = (user, users) => {
        return (user.systemRole === SYSTEM_ROLES.CONTRACTOR &&
            props.enableSupervisorUpdate &&
            showSupervisorColumn(users));
    };
    return {
        toggleSupervisorHandle,
        isSupervisorToggleDisabled,
        getSupervisorStatus,
        showSupervisorColumn,
        showSupervisorToggle,
    };
};
