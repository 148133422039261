import Notification from '@/layouts/_components/Notifications/Notification.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'NotificationsList',
    components: {
        Notification,
    },
    props: {
        notifications: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
    },
});
