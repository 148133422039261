import { ref, watch } from '@vue/composition-api';
import { useConfirmation } from '@/composables';
export const useTaskEditor = (props, ctx) => {
    const formId = ref('add-task-form');
    const loading = ref(false);
    const taskFormReference = ref(null);
    const { requestConfirmation, actionWithConfirmation, onConfirm } = useConfirmation();
    const close = () => ctx.emit('close');
    const confirmViewChange = (handler, omit) => taskFormReference.value
        ? actionWithConfirmation(
        // @ts-ignore
        taskFormReference.value.hasUnsavedChanges(omit), handler)
        : handler();
    watch(() => props.closeReportRequest, () => {
        confirmViewChange(props.closeReportCallback);
    });
    return {
        loading,
        formId,
        requestConfirmation,
        confirmViewChange,
        close,
        onConfirm,
        taskFormReference,
    };
};
