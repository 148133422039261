<template>
  <modal-wrapper v-slot="{ runWithModal }" :content="modal || {}">
    <button
      :disabled="disabled"
      class="table-btn focus:outline-none block"
      @click="modal ? runWithModal(handler, modal) : handler()"
    >
      <icon v-show="!disabled" :icon="mappedIcon" class="text-primary mr-4" />
    </button>
  </modal-wrapper>
</template>

<script>
import { Icon } from '@/components/Icon';
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import { mdiEyeOutline } from '@mdi/js';

export default {
  components: {
    Icon,
    ModalWrapper,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: [Object, Boolean],
      default: null,
    },
    handler: {
      type: Function,
      default: () => ({}),
    },
  },
  computed: {
    mappedIcon() {
      const iconMap = {
        edit: EditSvg,
        delete: DeleteSvg,
        preview: mdiEyeOutline,
      };

      return iconMap[this.icon];
    },
  },
};
</script>
