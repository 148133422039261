<template>
  <span @click.stop>
    <image-modal :image-url="image" :alt="$t('common.thumbnail-alt')">
      <div class="h-10 w-10">
        <image-view
          :src="image"
          :cover-img="!containImg"
          without-padding
          :shape="ImageShape.ROUNDED"
          :alt="$t('common.thumbnail-alt')"
          :size="thumbCdn ? ImageSize.THUMB : null"
        />
      </div>
    </image-modal>
  </span>
</template>

<script>
import ImageModal from '@/components/Modal/ImageModal';
import ImageView from '@/components/ImageView/ImageView.vue';
import { ImageSize, ImageShape } from '@/components/ImageView/image.constants';

export default {
  components: {
    ImageModal,
    ImageView,
  },
  props: {
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      required: true,
    },
    thumbKey: {
      type: String,
      required: true,
    },
    thumbCdn: {
      type: [Number, Boolean],
      default: false,
    },
    containImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ImageSize,
      ImageShape,
    };
  },
  computed: {
    image() {
      return this.rows[this.index][this.thumbKey];
    },
  },
};
</script>
