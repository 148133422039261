import { ref } from '@vue/composition-api';
import debounce from 'lodash.debounce';
export const useKeyboardNavigation = ({ options, handleSelect, openOptionArea, autocompleteValue, ctx, }) => {
    const focusedOptionIndex = ref(null);
    const focusNextOption = () => {
        openOptionArea();
        if (focusedOptionIndex.value === null) {
            focusedOptionIndex.value = 0;
        }
        else if (focusedOptionIndex.value < options.value.length - 1) {
            focusedOptionIndex.value += 1;
        }
        else {
            focusedOptionIndex.value = 0;
        }
    };
    const focusPrevOption = () => {
        openOptionArea();
        if (focusedOptionIndex.value === null) {
            focusedOptionIndex.value = options.value.length - 1;
        }
        else if (focusedOptionIndex.value > 0) {
            focusedOptionIndex.value -= 1;
        }
        else {
            focusedOptionIndex.value = options.value.length - 1;
        }
    };
    const debouncedEnterEvent = debounce(() => {
        if (autocompleteValue.value) {
            ctx.emit('enter', {
                enteredValue: autocompleteValue.value,
                options: options.value,
            });
        }
    }, 800);
    const handleEnter = () => {
        openOptionArea();
        if (focusedOptionIndex.value === null) {
            debouncedEnterEvent();
            return;
        }
        const selectedOption = options.value[focusedOptionIndex.value];
        if (selectedOption) {
            handleSelect(selectedOption);
        }
        else {
            debouncedEnterEvent();
        }
    };
    return { focusedOptionIndex, focusNextOption, focusPrevOption, handleEnter };
};
