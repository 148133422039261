import api from '@/api';
export const getStructuresList = async (params) => {
    const { data } = await api.get('structures', {
        params,
    });
    return data;
};
export const getRootStructure = async (brandId) => {
    const { results } = await getStructuresList({ brandId, parentId: 'null' });
    return results[0];
};
export const updateStructure = async (structureId, payload) => {
    const { data } = await api.patch(`structures/${structureId}`, payload);
    return data;
};
export const setStructureObjectTypeBudget = async (structureId, objectTypeId, payload) => {
    const { data } = await api.put(`structures/${structureId}/objectType/${objectTypeId}/budget`, payload);
    return data;
};
export const getStructuresLevels = async (params) => {
    const { data } = await api.get(`structures/levels`, { params });
    return data;
};
