<template>
  <div
    class="bg-gray-lighter flex flex-col md:flex-row"
    :style="{ marginBottom: `${footerHeight}px` }"
  >
    <div
      class="border-gray-light overflow-auto md:pb-10 px-4 md:pl-12"
      :style="!mobileView && { ...style, width: leftWidth + '%' }"
    >
      <div v-if="leftLoading" class="spinner-area">
        <icon-spinner
          class="spinner stroke-primary"
          width="64px"
          height="64px"
        />
      </div>
      <slot v-else name="leftSide" />
    </div>
    <div
      class="border-t md:border-t-0 md:border-l border-gray-light overflow-auto"
      :class="rightFluid ? '' : 'md:pb-10 md:pr-12'"
      :style="!mobileView && { ...style, width: rightWidth + '%' }"
    >
      <div v-if="rightLoading" class="spinner-area">
        <icon-spinner
          class="spinner stroke-primary"
          width="64px"
          height="64px"
        />
      </div>
      <slot v-else name="rightSide" />
    </div>
    <slot></slot>
    <footer-menu v-if="footer" ref="footer">
      <slot name="footer"></slot>
    </footer-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import FooterMenu from '@/components/Footer/FooterMenu';
export default {
  components: {
    FooterMenu,
    IconSpinner,
  },
  props: {
    leftLoading: {
      type: Boolean,
      default: false,
    },
    rightLoading: {
      type: Boolean,
      default: false,
    },
    leftWidth: {
      type: Number,
      default: 50,
    },
    rightWidth: {
      type: Number,
      default: 50,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    rightFluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footerHeight: 0,
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
      offsets: 'offsets',
    }),
    navbarHeight() {
      if (this.$route.matched.find(({ name }) => name === 'Brand')) {
        return this.offsets.navBarBrand;
      }
      return this.offsets.navBar;
    },
    style() {
      return {
        height: `calc(100vh - ${this.footerHeight + this.navbarHeight}px)`,
      };
    },
  },
  mounted() {
    if (this.footer) {
      const { height } = this.$refs.footer.$el.getBoundingClientRect();
      this.footerHeight = height;
    }
  },
};
</script>

<style lang="scss">
.spinner-area {
  @apply w-full h-full flex justify-center items-center;
}
</style>
