import { defineComponent, ref, onBeforeUnmount } from '@vue/composition-api';
import MessageTemplate from '@/components/MessageTemplate/MessageTemplate.vue';
import Btn from '@/components/Button/Button.vue';
import TaskForm from '@/views/Brand/Applications/Forms/TaskForm.vue';
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import Recipients from '@/views/Brand/Applications/Components/Recipients/Recipients.vue';
import { addReportDescription, handleReportReceivers, handleReportResources, } from '@/views/Brand/Applications/Report/_utils/add-report-assets';
import { createChatReport, createFormReport } from '@/api/requests/reports';
import { useTaskEditor } from '@/views/Brand/Applications/Report/_composables/use-task-editor';
import { useBrand } from '@/composables';
import { useReportRecipients } from '@/views/Brand/Applications/Report/_composables/use-report-recipients';
import { useAuthUser } from '@/composables/use-auth-user';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import isEmpty from 'lodash.isempty';
import { SystemRole } from '@/api/requests/users';
import { AssignEmptyPlaceModal } from '@/views/Brand/Applications/Report/_components/AssignEmptyPlaceModal';
import { useReportResources } from '@/views/Brand/Applications/Report/_composables/use-report-resources';
import { getTrimmedText } from '@/util/text';
import FetchData from '@/components/DataProvider/FetchData.vue';
import BackdropLoader from '@/components/BackdropLoader/BackdropLoader.vue';
import { BtnTheme } from '@/components/Btn';
export default defineComponent({
    computed: {
        BtnTheme() {
            return BtnTheme;
        },
    },
    components: {
        BackdropLoader,
        FetchData,
        AssignEmptyPlaceModal,
        Recipients,
        ConfirmModal,
        TaskForm,
        MessageTemplate,
        Btn,
    },
    props: {
        closeReportRequest: {
            type: Number,
            default: 0,
        },
        closeReportCallback: {
            type: Function,
            default: () => { },
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        messageClass: String,
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { authUserId, hasRole, isAdmin } = useAuthUser();
        const uploadInProgress = ref(false);
        const isCategorySelected = ref(false);
        const showAssignEmptyPlaceModal = ref(false);
        const reportId = ref('');
        const taskFormFields = ref(null);
        const { mustContainObjects, mustContainPlaces } = useReportResources();
        const isPlaceManager = hasRole(SystemRole.PLACE_MANAGER);
        const { users, supervisor, decisionMakerIds, onAddUser, addFormDecisionMakers, addFormUsers, onSelectUsers, onSetUsers, removeUsers, } = useReportRecipients();
        const { formId, loading, requestConfirmation, confirmViewChange, onConfirm, close, taskFormReference, } = useTaskEditor(props, ctx);
        const addReportAssets = async (id, { resources, description, fileTokens, }) => {
            try {
                await Promise.all([
                    handleReportReceivers({
                        reportId: id,
                        users: users.value,
                        decisionMakerIds: decisionMakerIds.value,
                    }),
                    handleReportResources(id, resources),
                    addReportDescription({
                        reportId: id,
                        message: description,
                        fileTokens,
                    }),
                ]);
            }
            catch {
                ctx.emit('form-submit', id);
                ctx.root.$toast.success(ctx.root.$t('add-task.task-added'));
                ctx.root.$toast.error(ctx.root.$t('add-task.added-with-error'));
                throw new Error('Some data could not be submitted');
            }
        };
        const assignUpdatedPlaceResources = (places) => {
            const form = taskFormFields.value;
            if (!form) {
                return;
            }
            const resources = { ...form.resources, places };
            const updatedTaskChatForm = {
                ...form,
                resources,
            };
            showAssignEmptyPlaceModal.value = false;
            addReportAssets(reportId.value, updatedTaskChatForm);
        };
        const onUpdatedResourcesSubmit = (places) => {
            assignUpdatedPlaceResources(places);
            ctx.emit('form-submit', reportId.value);
            ctx.root.$toast.success(ctx.root.$t('add-task.task-added'));
        };
        const validatePlaceManagerResources = (form) => !(isEmpty(form.resources.places) && isPlaceManager);
        const validateRequiredResources = (resources) => {
            const { objects, places } = resources;
            if (mustContainObjects.value && isEmpty(objects)) {
                return false;
            }
            if (mustContainPlaces.value && isEmpty(places) && !isPlaceManager) {
                return false;
            }
            return true;
        };
        const saveTaskForm = (id, form) => {
            reportId.value = id;
            taskFormFields.value = form;
        };
        const onSubmitChatForm = async (form) => {
            loading.value = true;
            try {
                const { id } = await createChatReport({
                    title: getTrimmedText(form.title),
                    brandId: brand.value.id,
                    supervisorId: supervisor.value?.id,
                });
                if (!validatePlaceManagerResources(form)) {
                    saveTaskForm(id, form);
                    showAssignEmptyPlaceModal.value = true;
                    return;
                }
                await addReportAssets(id, form);
                ctx.emit('form-submit', id);
                ctx.root.$toast.success(ctx.root.$t('add-task.task-added'));
            }
            finally {
                loading.value = false;
            }
        };
        const onSubmitCategoryForm = async (form) => {
            loading.value = true;
            try {
                if (form.categoryForm) {
                    const { formVersionId, values } = form.categoryForm;
                    if (!validateRequiredResources(form.resources)) {
                        return;
                    }
                    const { id } = await createFormReport({
                        formVersionId,
                        values,
                        title: getTrimmedText(form.title),
                        supervisorId: supervisor.value?.id,
                    });
                    if (!validatePlaceManagerResources(form)) {
                        saveTaskForm(id, form);
                        showAssignEmptyPlaceModal.value = true;
                        return;
                    }
                    await addReportAssets(id, form);
                    ctx.emit('form-submit', id);
                    ctx.root.$toast.success(ctx.root.$t('add-task.task-added'));
                }
            }
            finally {
                loading.value = false;
            }
        };
        const { resetPlacesState } = usePlaceResourcesStore();
        const { resetObjectsState } = useObjectResourcesStore();
        const onCategoryUpdate = (category) => {
            isCategorySelected.value = category !== null;
        };
        onBeforeUnmount(() => {
            resetPlacesState();
            resetObjectsState();
        });
        return {
            brand,
            users,
            supervisor,
            loading,
            formId,
            isAdmin,
            requestConfirmation,
            onAddUser,
            onSubmitChatForm,
            onSubmitCategoryForm,
            confirmViewChange,
            close,
            onConfirm,
            taskFormReference,
            addFormDecisionMakers,
            addFormUsers,
            decisionMakerIds,
            authUserId,
            onSelectUsers,
            onSetUsers,
            uploadInProgress,
            onCategoryUpdate,
            isCategorySelected,
            showAssignEmptyPlaceModal,
            onUpdatedResourcesSubmit,
            reportId,
            removeUsers,
        };
    },
});
