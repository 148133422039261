import { defineComponent } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import { Btn, BtnShape, BtnTheme } from '@/components/Btn';
import { mdiClose } from '@mdi/js';
export default defineComponent({
    components: { Icon, Btn },
    props: {
        title: {
            type: String,
            default: '',
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        return { IconSize, mdiClose, BtnShape, BtnTheme };
    },
});
