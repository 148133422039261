import { ref, watch } from '@vue/composition-api';
import { getUsersList } from '@/api/requests/users';
export const useRemovePlaceResource = (props) => {
    const editedReportPlacesSet = ref(new Set());
    watch(props.fetchedPlaces, () => (editedReportPlacesSet.value = new Set(props.fetchedPlaces.value)));
    watch(props.toggleUpdateCounter, () => {
        if (!props.toggledPlace.value) {
            return;
        }
        if (editedReportPlacesSet.value.has(props.toggledPlace.value)) {
            editedReportPlacesSet.value.delete(props.toggledPlace.value);
            return;
        }
        editedReportPlacesSet.value.add(props.toggledPlace.value);
    }, { deep: true });
    const getToggledPlaceUsers = async () => {
        if (!props.toggledPlace?.value) {
            return [];
        }
        const { results } = await getUsersList({
            excludeSelf: true,
            placeIds: [props.toggledPlace.value.id],
            ...(props.reportId && { relatedReportId: props.reportId }),
        });
        return results;
    };
    const getEditedReportPlacesUsers = async () => Promise.all(Array.from(editedReportPlacesSet.value).map(async (place) => {
        const { results } = await getUsersList({
            excludeSelf: true,
            placeIds: [place.id],
            ...(props.reportId && { relatedReportId: props.reportId }),
        });
        return { place: place.id, users: results };
    }));
    const getNewReportPlacesUsers = async () => Promise.all(props.addedPlaces.value.map(async (place) => {
        const { results } = await getUsersList({
            excludeSelf: true,
            placeIds: [place.id],
            ...(props.reportId && { relatedReportId: props.reportId }),
        });
        return { place: place.id, users: results };
    }));
    const getPlacesUsers = async () => {
        if (props.fetchedPlaces.value.length > 0) {
            return await getEditedReportPlacesUsers();
        }
        return await getNewReportPlacesUsers();
    };
    return {
        getToggledPlaceUsers,
        getPlacesUsers,
    };
};
