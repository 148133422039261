<template>
  <with-side-navigations
    :view-width="50"
    :side-bar-width="50"
    side="right"
    footer
  >
    <template #sideNavigation="{ setButton }">
      <div>
        <tabs v-model="activeTab">
          <template v-if="userFormOpened">
            <tab :name="$t('edit-place-view.add-user')" :reference="USER_TAB">
              <add-user-tab
                ref="userForm"
                @close-user-tab="closeUserTab(setButton)"
                @add-user="onAddUser"
              />
            </tab>
          </template>
          <template v-else>
            <tab
              :name="$t('add-place-view.coordinates')"
              :reference="COORDINATES_TAB"
            >
              <clustered-map
                v-model="coordinates"
                interactive
                :interactive-marker="{ icon: DEFAULT_PLACE_MARKER }"
                :disabled-settings="[
                  MapSettings.SHOW_OBJECT_NUMBER_LABEL,
                  MapSettings.SHOW_PLACE_NUMBER_LABEL,
                ]"
              />
            </tab>
            <tab
              :name="$t('add-place-view.multimedia')"
              :reference="MULTIMEDIA_TAB"
            >
              <file-uploader
                :list="uploadQueue"
                type="multimedia"
                target="places"
              />
            </tab>
            <tab
              :name="$t('add-place-view.documents')"
              :reference="DOCUMENTS_TAB"
            >
              <file-uploader
                :list="uploadQueue"
                type="document"
                target="places"
              />
            </tab>
          </template>
        </tabs>
      </div>
    </template>
    <template #default="{ setButton }">
      <div class="container-fluid bg-neutral">
        <breadcrumbs
          :path="[
            { name: $t('add-place-view.places'), url: placesRoute },
            { name: brand ? brand.name : '', url: placesRoute },
            { name: $t('add-place-view.add-place') },
          ]"
          class="mt-2 mb-1"
        ></breadcrumbs>

        <place-form
          id="placeForm"
          :brand="brand"
          :content="computedContent"
          :clear="placeSubmissionEndSuccessfully"
          :users-list-key="usersListKey"
          :coordinates="coordinates"
          @open-user-form="openUserForm(setButton)"
          @on-submit="handlePlaceSubmission"
          @set-tab="activeTab = $event"
          @set-coordinates="coordinates = $event"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="
              redirectAfterPlaceSubmission && placeSubmissionInProgress
            "
            type="submit"
            form="placeForm"
            class="md:w-48"
            @click="redirectAfterPlaceSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
          <btn
            :is-loading="
              !redirectAfterPlaceSubmission && placeSubmissionInProgress
            "
            type="submit"
            form="placeForm"
            class="w-48 mx-3"
            theme="secondary"
            @click="redirectAfterPlaceSubmission = false"
          >
            {{ $t('place-form.save-and-add-another') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.push(placesRoute)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </template>
  </with-side-navigations>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import PlaceForm from '@/views/Brand/Places/Forms/PlaceForm.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import FileUploader from '@/components/Uploader/FileUploader.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';
import {
  COORDINATES_TAB,
  DOCUMENTS_TAB,
  MULTIMEDIA_TAB,
  USER_TAB,
} from '@/store/modules/ui';
import AddUserTab from '@/views/Brand/Places/Components/AddUserTab';
import { ClusteredMap, MapSettings } from '@/components/Map';
import { DEFAULT_PLACE_MARKER } from '@/components/Map/constants/common';

export default {
  name: 'AddPlace',
  components: {
    AddUserTab,
    ClusteredMap,
    Breadcrumbs,
    Btn,
    PlaceForm,
    Tabs,
    Tab,
    WithSideNavigations,
    FileUploader,
  },
  data() {
    return {
      redirectAfterPlaceSubmission: false,
      placeSubmissionInProgress: false,
      placeSubmissionEndSuccessfully: false,
      userFormOpened: false,
      newUser: null,
      usersListKey: 1,
      activeTab: COORDINATES_TAB,
      coordinates: null,
      COORDINATES_TAB,
      MULTIMEDIA_TAB,
      DOCUMENTS_TAB,
      USER_TAB,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
    ...mapState('attachment', ['uploadQueue', 'deleteQueue']),
    placesRoute() {
      return this.brand
        ? { name: 'Places', params: { id: this.brand.id } }
        : {};
    },
    computedContent() {
      return { user: this.newUser };
    },
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('attachment/clearUploadQueue');
    next();
  },
  methods: {
    ...mapActions('brand', ['addBrandPlace']),
    ...mapActions('attachment', ['saveAttachments', 'clearUploadQueue']),
    ...mapActions('ui', ['setSidebar']),
    openUserForm(setButton) {
      this.setSidebar(true);
      setButton({
        show: true,
        onClick: () => {
          this.$refs.userForm.closeUserForm();
        },
      });
      this.userFormOpened = true;

      this.$nextTick(() => {
        this.activeTab = USER_TAB;
      });
    },
    closeUserTab(setButton) {
      this.userFormOpened = false;
      this.activeTab = COORDINATES_TAB;
      this.showConfirmationModal = false;

      setButton({ show: false });
    },
    onAddUser(user) {
      this.newUser = user;
      this.usersListKey += 1;
    },
    async handlePlaceSubmission(formData, { tagsToSave }) {
      this.placeSubmissionEndSuccessfully = false;
      this.placeSubmissionInProgress = true;
      try {
        const { data } = await this.addBrandPlace({
          ...formData,
          tags: tagsToSave.map(({ name }) => name),
        });
        if (this.uploadQueue.length) {
          await this.saveAttachments({
            id: data.id,
          });

          await this.clearUploadQueue();
        }
        this.$toast.success(this.$t('add-place-view.added-successfully'));
        if (this.redirectAfterPlaceSubmission)
          this.$router.push(this.placesRoute);

        this.placeSubmissionEndSuccessfully = true;
      } finally {
        this.placeSubmissionInProgress = false;
      }
    },
  },
  setup() {
    return { DEFAULT_PLACE_MARKER, MapSettings };
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
