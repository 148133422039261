import { ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables/use-auth-user';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
const { useActions: useCommonActions } = createNamespacedHelpers('common');
export const useObjectsGroupActions = (ctx) => {
    const objectsGroupToEdit = ref();
    const showEditObjectsGroupModal = ref(false);
    const { updatesCounter, update } = useUpdatesCounter();
    const { delete: deleteObjectsGroup } = useCommonActions(['delete']);
    const { isAdmin } = useAuthUser();
    const onRemoveBtnClick = async (id) => {
        try {
            await deleteObjectsGroup({ type: 'objects/groups', id });
            update();
            ctx.root.$toast.success(ctx.root.$t('objects-groups.remove-success'));
        }
        catch (e) {
            ctx.root.$toast.success(ctx.root.$t('objects-groups.remove-error'));
        }
    };
    const getObjectsGroupActions = (objectGroup) => {
        if (!isAdmin.value)
            return [];
        return [
            {
                name: 'edit',
                handler: () => {
                    showEditObjectsGroupModal.value = true;
                    objectsGroupToEdit.value = objectGroup;
                },
                icon: EditSvg,
            },
            {
                name: 'delete',
                handler: () => onRemoveBtnClick(objectGroup.id),
                modal: {
                    type: 'confirm',
                    title: `${ctx.root.$t('common.delete')} ${objectGroup.name}`,
                    description: ctx.root.$t('confirm-delete-modal.modal-description', {
                        type: ctx.root.$t(`confirm-delete-modal.objects-group`),
                    }),
                    btnText: ctx.root.$t('common.delete'),
                },
                icon: DeleteSvg,
            },
        ];
    };
    return {
        getObjectsGroupActions,
        updatesCounter,
        showEditObjectsGroupModal,
        objectsGroupToEdit,
    };
};
