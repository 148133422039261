import { ValidationProvider } from 'vee-validate';
import { CustomFormElement } from '@/views/_components/CustomForms';
import { defineComponent } from '@vue/composition-api';
import { useValidation } from '@/composables';
export default defineComponent({
    components: {
        CustomFormElement,
        ValidationProvider,
    },
    props: {
        components: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        useValidation(ctx);
        const updateValues = (fieldValue, index) => {
            const updatedValues = [...props.value];
            updatedValues[index] = fieldValue;
            ctx.emit('input', updatedValues);
        };
        return { updateValues };
    },
});
