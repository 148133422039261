let uid = 0;
export const getUid = () => uid++;
export const getFullAddress = (input) => input.postalCode
    ? `${input.address}, ${input.postalCode} ${input.city}`
    : `${input.address}, ${input.city}`;
export const mapId = (arr) => arr.map(({ id }) => id);
/**
 * Generates a random number within a specified range with a specified number of decimal places.
 *
 * @param {number} min - The minimum value of the range (inclusive).
 * @param {number} max - The maximum value of the range (exclusive).
 * @param {number} decimalFloor - The number of decimal places to floor the generated number to.
 * @returns {number} - The generated random number.
 */
export const generateRandomNumber = (min, max, decimalFloor) => {
    const randomNumber = Math.random() * (max - min) + min;
    return Number(randomNumber.toFixed(decimalFloor));
};
/**
 * Get the nested value from an object using an accessor array.
 * @param object - The input object to retrieve the nested value from.
 * @param accessor - An array of strings representing the keys or properties to access in a nested object.
 *
 * @template TObject - The type of the input object.
 * @template TNestedValue - The type of the nested value to retrieve.
 */
export const getNestedValue = (object, accessor) => {
    let result = object;
    for (const key of accessor) {
        if (!(key in result)) {
            console.error(`Error: Key '${key}' not found in object.`);
            return null;
        }
        result = result[key];
    }
    return result;
};
/**
 * Checks if a value is one of the enum values.
 *
 * @param {T} value - The value to check.
 * @param {Record<string, T>} enumObject - The enum object to compare against.
 * @returns {boolean} - `true` if the value is one of the enum values, `false` otherwise.
 */
export const isEnumValue = (value, enumObject) => Object.values(enumObject).includes(value);
/**
 * Creates a new array containing unique objects based on a specified key within each object.
 *
 * @template T
 * @param {T[]} arr - The input array of objects.
 * @param {keyof T} key - The key within each object used for uniqueness comparison.
 * @returns {T[]} - An array of unique objects based on the specified key.
 */
export const getUniqueListBy = (arr, key) => [
    ...new Map(arr.map((item) => [item[key], item])).values(),
];
