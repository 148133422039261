<template>
  <objekto-logo class="fill-primary h-10" />
</template>

<script>
import ObjektoLogo from '@/assets/Objekto_Logo.svg';

export default {
  components: { ObjektoLogo },
};
</script>
