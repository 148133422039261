import { PublicClientApplication } from '@azure/msal-browser';
import azureConfig from '@/config/azure-config';
import api from '@/api';

export const AZURE_DOMAINS = [process.env.VUE_APP_AMREST_URL];

export default {
  namespaced: true,
  state: {
    accessToken: '',
    msalInstance: {},
    status: '',
    statusMap: {
      inProgress: 'in_progress',
      error: 'error',
      success: 'success',
    },
  },
  mutations: {
    SET_MSAL_INSTANCE(state) {
      state.msalInstance = new PublicClientApplication(azureConfig);
    },
    SET_AUTHENTICATION_STATUS(state, status) {
      state.status = status;
    },
    SET_ACTIVE_USER(state) {
      const [account] = state.msalInstance.getAllAccounts();

      if (account) state.msalInstance.setActiveAccount(account);
    },
  },
  getters: {
    azureAuthEnabled() {
      return AZURE_DOMAINS.includes(window.location.origin);
    },
    azureAuthInProgress(state) {
      return state.status && state.status !== state.statusMap.success;
    },
  },
  actions: {
    setupAzureIntegration({ commit }) {
      commit('SET_MSAL_INSTANCE');
    },
    async authenticateWithAzure({ state, commit }) {
      commit('SET_AUTHENTICATION_STATUS', state.statusMap.inProgress);

      try {
        await state.msalInstance.loginRedirect({});
      } catch {
        commit('SET_AUTHENTICATION_STATUS', state.statusMap.error);
      }
    },
    async setAzureUser({ state, commit, dispatch }) {
      try {
        const redirectResponse =
          await state.msalInstance.handleRedirectPromise();

        if (redirectResponse) {
          const { idToken } = redirectResponse;

          if (!idToken) throw new Error();

          commit('SET_ACTIVE_USER');

          const { data } = await api.post('users/login/azure-ad', { idToken });

          commit('SET_AUTHENTICATION_STATUS', state.statusMap.success);

          const { token, user } = data;

          dispatch('user/setUser', user, { root: true });
          dispatch('user/setToken', token, { root: true });
        }
      } catch {
        commit('SET_AUTHENTICATION_STATUS', state.statusMap.error);
      }
    },
  },
};
