<template>
  <div class="container-fluid">
    <div class="md:w-1/2 pt-8 pb-64 px-4 md:px-12">
      <router-link
        :to="{ name: 'Users' }"
        class="text-gray-dark text-xs inline-flex items-center mb-2"
      >
        <icon :icon="mdiChevronLeft" class="text-gray-dark" />
        {{ $t('common.back') }}
      </router-link>
      <loading-spinner class-names="min-h-56 w-88" :is-loading="!user">
        <div v-if="user" class="md:flex mt-1">
          <div class="avatar">
            <img src="@/assets/default-avatar.png" class="m-0" />
          </div>
          <div>
            <h2 class="flex items-center">
              {{ user.firstName }} {{ user.lastName }}
              <span class="label" :class="[labelClasses]">
                {{ labelContent }}
              </span>
            </h2>
            <ul class="user-info">
              <li>
                <span>{{ $t('common.phone') }}:</span>
                <b>{{ user.phone }}</b>
              </li>
              <li>
                <span>{{ $t('common.email') }}:</span>
                <b>
                  <a class="text-links" :href="`mailto:${user.email}`">
                    {{ user.email }}
                  </a>
                </b>
              </li>
              <li>
                <span>{{ $t('common.internal-role') }}:</span>
                <b class="uppercase">
                  {{ user.internalRole ? user.internalRole : '-' }}
                </b>
              </li>
              <li>
                <span>{{ $t('common.role') }}:</span>
                <b class="uppercase">
                  {{ $t(`roles.${user.role}`) }}
                </b>
              </li>
            </ul>
            <div v-if="hasAdminRights" class="mt-5 flex">
              <router-link
                class="w-10"
                :to="{ name: 'EditUser', params: { userId: user.id } }"
              >
                <icon :icon="EditSvg" class="text-primary mr-4" />
              </router-link>
              <!--              <icon icon-name="delete" class="w-6 h-6 fill-primary mr-4" />-->
            </div>
          </div>
        </div>
      </loading-spinner>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { mapState, mapGetters } from 'vuex';
import VueStore from '@/store';
import { mdiChevronLeft } from '@mdi/js';
import EditSvg from '@/assets/icons/edit.svg';

export default {
  name: 'UserDetails',
  components: {
    Icon,
    LoadingSpinner,
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('brand/fetchBrandUserDetails', to.params['userId']);
    next();
  },
  data() {
    return { mdiChevronLeft, EditSvg };
  },
  computed: {
    ...mapState('brand', ['user']),
    ...mapGetters('user', ['hasAdminRights']),
    labelClasses() {
      if (this.user && this.user.status === 'registered')
        return 'border-green text-green';
      return '';
    },
    labelContent() {
      return this.user && this.$t(`user-status.${this.user.status}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
h2 {
  @apply text-3xl mt-1;
}
.avatar {
  @apply mb-8 flex justify-center;

  @screen md {
    @apply w-52 mr-8 mb-0 block;
  }
}
.label {
  @apply px-6 py-1 font-normal border text-3xs capitalize rounded-full ml-4 mt-1;
}
.user-info {
  @apply ml-1 mt-3;
  li {
    @apply my-2 text-sm leading-snug;
    span {
      @apply w-20 text-gray-dark inline-block;
    }
    b {
      @apply font-semibold;
    }
  }
}
</style>
