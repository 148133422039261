<template>
  <div>
    <div
      v-if="isLoading"
      class="flex items-center justify-center min-h-16"
      :class="classNames"
    >
      <icon-spinner
        class="spinner"
        :class="iconClassNames"
        width="64px"
        height="64px"
      ></icon-spinner>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';

export default {
  components: {
    IconSpinner,
  },
  props: {
    classNames: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    iconClassNames: {
      type: String,
      default: 'stroke-primary',
    },
  },
};
</script>
