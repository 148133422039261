export var TAG_STYLE;
(function (TAG_STYLE) {
    TAG_STYLE["ROUNDED"] = "rounded";
    TAG_STYLE["RECTANGULAR"] = "rectangular";
})(TAG_STYLE || (TAG_STYLE = {}));
export var TAG_THEME;
(function (TAG_THEME) {
    TAG_THEME["NONE"] = "none";
    TAG_THEME["YELLOW_LIGHT"] = "yellow-light";
})(TAG_THEME || (TAG_THEME = {}));
