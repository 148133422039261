var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex"},[_c('ValidationProvider',{staticClass:"w-full mb-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"flex-grow",attrs:{"placeholder":_vm.$t('forms.title'),"error-message":errors[0],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formElement.title),callback:function ($$v) {_vm.$set(_vm.formElement, "title", $$v)},expression:"formElement.title"}})]}}])}),_c('select-input',{staticClass:"ml-12 w-2/5",attrs:{"options":_vm.options,"option-label-name":"name","option-value-name":"type","selected-label":"name","disabled":_vm.disabled},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}})],1),(_vm.formElement.type && _vm.formElement.type === 'text_input')?_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full",attrs:{"type":"textarea","error-message":errors[0],"disabled":_vm.disabled,"required":"","autosize":""},model:{value:(_vm.formElement.description),callback:function ($$v) {_vm.$set(_vm.formElement, "description", $$v)},expression:"formElement.description"}})]}}],null,false,901380267)})],1):_vm._e(),(_vm.formElement.type && _vm.formElement.type === 'radio_list')?_c('div',{staticClass:"mt-4"},[_vm._l((_vm.formElement.items),function(item,index){return _c('div',{key:index,staticClass:"flex mb-5"},[_c('radio-button',{attrs:{"name":"radio","disabled":_vm.disabled}}),_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full",attrs:{"error-message":errors[0],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formElement.items[index]),callback:function ($$v) {_vm.$set(_vm.formElement.items, index, $$v)},expression:"formElement.items[index]"}})]}}],null,true)}),_c('button',{attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeItem(index)}}},[(index > 0)?_c('icon',{staticClass:"text-primary m-2 cursor-pointer",attrs:{"icon":_vm.DeleteSvg}}):_vm._e()],1)],1)}),_c('button',{attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.addItem}},[_c('icon',{staticClass:"text-primary mr-3 cursor-pointer",attrs:{"icon":_vm.mdiPlusCircleOutline}})],1)],2):_vm._e(),(_vm.formElement.type && _vm.formElement.type === 'checkbox_list')?_c('div',{staticClass:"mt-4"},[_vm._l((_vm.formElement.items),function(item,index){return _c('div',{key:index,staticClass:"flex mb-5"},[_c('checkbox',{attrs:{"name":"checkbox","disabled":_vm.disabled}}),_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"w-full",attrs:{"error-message":errors[0],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formElement.items[index]),callback:function ($$v) {_vm.$set(_vm.formElement.items, index, $$v)},expression:"formElement.items[index]"}})]}}],null,true)}),_c('button',{attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeItem(index)}}},[(index > 0)?_c('icon',{staticClass:"text-primary m-2 cursor-pointer",attrs:{"icon":_vm.DeleteSvg}}):_vm._e()],1)],1)}),_c('button',{attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.addItem}},[_c('icon',{staticClass:"text-primary mr-3 cursor-pointer",attrs:{"icon":_vm.mdiPlusCircleOutline}})],1)],2):_vm._e(),_c('div',{staticClass:"mt-4 pt-3 border-t border-t-gray-light flex items-center"},[_c('button',{attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.onDelete}},[_c('icon',{staticClass:"text-primary mr-3",attrs:{"icon":_vm.DeleteSvg}})],1),_c('button',{attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.onClone}},[_c('icon',{staticClass:"text-primary mr-6",attrs:{"icon":_vm.DocumentSvg}})],1),_c('checkbox',{attrs:{"size":"sm","disabled":_vm.disabled},model:{value:(_vm.formElement.isRequired),callback:function ($$v) {_vm.$set(_vm.formElement, "isRequired", $$v)},expression:"formElement.isRequired"}}),_c('p',{staticClass:"text-sm font-semibold"},[_vm._v(_vm._s(_vm.$t('forms.required')))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }