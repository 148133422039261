import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { ProfileLayout } from '@/views/Profile/_layouts/profile-layout';
import { DateInput, LabelPosition, SwitchInput, TextInput, } from '@/components/Inputs';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import { Btn, BtnTheme, BtnSize } from '@/components/Btn';
import Checkbox from '@/components/Inputs/Checkbox/Checkbox.vue';
import { createUserVacation, getUserVacation } from '@/api/requests/users';
import { BackdropLoader } from '@/components/BackdropLoader';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { useValidation } from '@/composables';
export default defineComponent({
    components: {
        BackdropLoader,
        ValidationObserver,
        ValidationProvider,
        Checkbox,
        Btn,
        FooterMenu,
        TextInput,
        DateInput,
        SwitchInput,
        ProfileLayout,
    },
    setup(_, ctx) {
        useValidation(ctx);
        const isLoading = ref(true);
        const form = ref({
            isEnabled: false,
            startDate: new Date().toISOString(),
            endDate: null,
            message: '',
        });
        onMounted(async () => {
            try {
                const data = await getUserVacation();
                form.value = {
                    isEnabled: data.isMessageEnabled,
                    isEndDateEnabled: Boolean(data.isEndDateEnabled),
                    startDate: data.startDate,
                    endDate: data.endDate,
                    message: data.message || '',
                };
            }
            finally {
                isLoading.value = false;
            }
        });
        const onSubmit = async () => {
            try {
                isLoading.value = true;
                await createUserVacation({
                    startDate: form.value.startDate,
                    isEndDateEnabled: form.value.isEndDateEnabled,
                    endDate: form.value.endDate,
                    message: form.value.message,
                    isMessageEnabled: form.value.isEnabled,
                });
                ctx.root.$toast.success(ctx.root.$t('profile-vacations.success'));
            }
            finally {
                isLoading.value = false;
            }
        };
        const startDateDisabledDates = (date) => {
            date.setDate(date.getDate() + 1);
            return date < new Date();
        };
        const endDateDisabledDates = (date) => {
            date.setDate(date.getDate() + 1);
            return date < new Date(form.value.startDate);
        };
        watch(() => form.value.startDate, () => {
            if (!form.value.endDate) {
                return;
            }
            if (new Date(form.value.startDate) > new Date(form.value.endDate)) {
                form.value.endDate = form.value.startDate;
            }
        });
        return {
            isLoading,
            form,
            LabelPosition,
            BtnTheme,
            BtnSize,
            onSubmit,
            startDateDisabledDates,
            endDateDisabledDates,
        };
    },
});
