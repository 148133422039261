<template>
  <div class="flex my-3" :class="{ fill }">
    <div class="flex">
      <vue-dropzone
        v-show="false"
        id="dropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        @vdropzone-success="handleImg"
        @vdropzone-file-added="onStart"
        @vdropzone-complete="onComplete"
        @vdropzone-error="onError"
      ></vue-dropzone>
    </div>
    <div class="mr-6">
      <label v-if="!withoutLabel" class="block my-2">
        <span v-if="label">{{ label }}</span>
        <span v-else>
          {{ icon ? $t('image-uploader.icon') : $t('image-uploader.logo') }}
        </span>
        <span v-if="required" class="text-red">*</span>
      </label>
      <btn
        :id="addBtnId"
        size="sm"
        :is-loading="isUploading || isDeleting"
        class="w-32 btn-add"
        theme="secondary"
      >
        {{ $t('common.add') }}
      </btn>
      <p v-if="!error" class="info">
        <span v-if="icon">
          {{ $t('image-uploader.size') }}
          <b>104x104{{ $t('image-uploader.icon-unit') }}</b>
        </span>
      </p>
      <p v-else class="info error">
        <span v-if="sizeError">{{ $t('image-uploader.size-error') }}</span>
        <span v-else-if="isError">{{ isError }}</span>
        <span v-else>{{ $t('image-uploader.upload-error') }}</span>
      </p>
      <button v-if="imgUrl" class="btn-delete" @click="deleteImg">
        <icon :icon="DeleteSvg" class="text-primary mr-1" />
        {{ $t('image-uploader.delete') }}
      </button>
    </div>
    <div
      class="logo-container"
      :class="[{ error }, padding, previewDimensions]"
    >
      <image-view v-if="!imgUrl" :src="placeholderImage" alt="uploaded-image" />
      <img v-else :src="`${imgUrl}?v=${ver}`" class="max-w-full" />
    </div>
  </div>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import { Icon } from '@/components/Icon';
import ImageView from '@/components/ImageView/ImageView.vue';
import vueDropzone from 'vue2-dropzone';
import { apiUrl } from '@/api';
import DeleteSvg from '@/assets/icons/delete.svg';
import {
  LOCAL_STORAGE_KEY,
  useLocalStorage,
} from '@/composables/use-local-storage';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ImageUploader',
  components: {
    Btn,
    Icon,
    vueDropzone,
    ImageView,
  },
  model: {
    event: 'update:img-url',
    prop: 'imgUrl',
  },
  props: {
    maxSize: {
      type: Number,
      default: 100,
    },
    padding: {
      type: String,
      default: null,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    placeholderImage: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    isError: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    withoutLabel: {
      type: Boolean,
      default: false,
    },
    previewDimensions: {
      type: String,
      default: 'w-56 h-46',
    },
    acceptedFiles: {
      type: String,
      default: 'image/*',
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { storedValue } = useLocalStorage(LOCAL_STORAGE_KEY.OBJEKTO);
    const authToken = storedValue.value?.user.token;
    const addBtnId = `add-btn-${uuidv4()}`;

    return {
      DeleteSvg,
      isUploading: false,
      isDeleting: false,
      sizeError: false,
      error: false,
      uploadedImg: null,
      ver: 1.0,
      addBtnId,
      dropzoneOptions: {
        url: `${apiUrl}/files`,
        headers: { Authorization: authToken },
        maxFilesize: 100,
        timeout: 0,
        acceptedFiles: this.acceptedFiles,
        clickable: `#${addBtnId}`,
      },
    };
  },
  watch: {
    isError(newVal) {
      if (newVal) this.error = true;
    },
  },
  methods: {
    handleImg(file, response) {
      this.$emit('read-file', file);
      this.ver++;
      this.$emit('update:img-url', response.url);
    },
    deleteImg() {
      this.isDeleting = true;
      this.$emit('update:img-url', '');
      this.isDeleting = false;
    },
    onStart() {
      this.isUploading = true;
      this.sizeError = false;
      this.error = false;
    },
    onComplete() {
      this.isUploading = false;
    },
    onError(file, msg) {
      this.error = true;
      if (msg.match(/too big/)) this.sizeError = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  @apply flex justify-center items-center border border-gray-light bg-white rounded overflow-hidden;
  &.error {
    @apply border-red;
  }
}
.fill {
  .logo-container {
    img {
      @apply object-cover h-full w-full;
    }
  }
}
.btn-delete {
  @apply mt-3 text-sm font-semibold flex items-center;
  &:focus {
    @apply outline-none;
  }
}
.info {
  @apply mt-2 text-xs text-gray-darker;
  &.error {
    @apply text-red;
  }
}
</style>
