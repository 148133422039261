import { defineComponent } from '@vue/composition-api';
import NavItems from '@/layouts/Components/NavItems.vue';
import { PortalTarget } from '@/constants/portal';
import { useAuthUser, useUi } from '@/composables';
import { mdiMagnify } from '@mdi/js';
import { TextInput } from '@/components/Inputs';
export default defineComponent({
    components: { NavItems, TextInput },
    props: {
        items: {
            type: Array,
            required: true,
        },
        isActive: {
            type: Function,
            required: true,
        },
        activeCategory: {
            type: Number,
            required: true,
        },
        searchValue: {
            type: String,
            required: true,
        },
    },
    setup() {
        const { toggleSideMenu } = useUi();
        const { logout } = useAuthUser();
        return { PortalTarget, toggleSideMenu, mdiMagnify, logout };
    },
});
