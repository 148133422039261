import { ref } from '@vue/composition-api';
import { getNotifications, readAllNotifications, readNotification, } from '@/api/requests/notifications';
export const useNotifications = () => {
    const notifications = ref([]);
    const pagination = ref(null);
    const page = ref(1);
    const unread = ref(0);
    const fetchNotifications = async (reset = false) => {
        const data = await getNotifications({
            limit: 10,
            page: page.value,
        });
        notifications.value = [
            ...(reset ? [] : notifications.value),
            ...data.results,
        ];
        pagination.value = data.pagination;
    };
    const getUnreadNotificationCount = async () => {
        const { pagination } = await getNotifications({
            limit: 1,
            isRead: false,
        });
        unread.value = pagination.total;
    };
    const markAllNotificationsAsRead = async () => {
        await readAllNotifications();
        notifications.value = notifications.value.map((notification) => ({
            ...notification,
            isRead: true,
        }));
        unread.value = 0;
    };
    const markNotificationAsRead = async (notificationId) => {
        await readNotification(notificationId);
        notifications.value = notifications.value.map((notification) => ({
            ...notification,
            isRead: notification.id === notificationId || notification.isRead,
        }));
        unread.value = Math.max(0, unread.value - 1);
    };
    return {
        notifications,
        pagination,
        page,
        unread,
        fetchNotifications,
        getUnreadNotificationCount,
        markAllNotificationsAsRead,
        markNotificationAsRead,
    };
};
