<template>
  <div
    class="layout"
    :class="{
      'flex-row-reverse': rightSidebar,
      'pb-24': footer,
      'pb-4': !footer,
    }"
  >
    <div class="sidebar" :class="{ 'w-full': isSidebarOpen && isMobileView }">
      <sidebar-toggler />
      <content-card
        v-show="isSidebarOpen"
        class="mt-4"
        content-class=" px-2 md:px-5"
        md
      >
        <template #header>
          <slot name="sidebarHeader"></slot>
        </template>
        <div class="sidebar-content">
          <slot name="sidebar"></slot>
        </div>
      </content-card>
    </div>
    <content-card
      v-if="!isMobileView || (isMobileView && !isSidebarOpen)"
      class="content"
      :content-class="contentClass"
      :header-class="headerClass"
    >
      <template #header><slot name="contentHeader"></slot></template>
      <slot></slot>
    </content-card>
    <footer-menu v-if="footer">
      <slot name="footer"></slot>
    </footer-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LEFT_SIDEBAR, RIGHT_SIDEBAR } from '@/store/modules/ui';
import SidebarToggler from '@/layouts/SidebarLayout/components/SidebarToggler';
import ContentCard from '@/components/ContentCard/ContentCard';
import FooterMenu from '@/components/Footer/FooterMenu';
import { useMobileView } from '@/composables';

export default {
  name: 'SidebarLayout',
  components: {
    ContentCard,
    SidebarToggler,
    FooterMenu,
  },
  props: {
    footer: {
      type: Boolean,
      default: false,
    },
    rightSidebar: {
      type: Boolean,
      default: false,
    },
    sidebarTabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: 'p-4',
    },
    headerClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      LEFT_SIDEBAR,
      RIGHT_SIDEBAR,
      isCustomButton: false,
      onButtonClick: () => {},
    };
  },
  computed: {
    ...mapGetters('ui', ['isSidebarOpen', 'isMobileViewActive']),
    leftSidebar() {
      return !this.rightSidebar;
    },
  },
  methods: {
    ...mapActions('ui', ['toggleSidebar']),
    setButton({ show, onClick }) {
      this.isCustomButton = show;
      this.onButtonClick = onClick ? onClick : () => {};
    },
  },
  setup() {
    const { isMobileView } = useMobileView();
    return { isMobileView };
  },
};
</script>

<style lang="scss" scoped>
.layout {
  @apply flex items-start pt-4;

  @screen md {
    @apply px-12;
  }

  .sidebar {
    @apply flex-shrink-0 flex-grow-0;

    &-content {
      @apply overflow-y-scroll;

      @screen md {
        @apply w-110;
      }
    }
  }

  .content {
    @apply flex-grow flex-shrink;

    @screen md {
      @apply mr-12;
    }
  }
}
</style>
