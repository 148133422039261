import { DEFAULT_MAP_SETTINGS_VALUES } from '@/components/Map/constants';

export default {
  namespaced: true,
  state: {
    settings: new Map(DEFAULT_MAP_SETTINGS_VALUES),
  },
  getters: {
    settings: (state) => state.settings,
  },
  mutations: {
    SET_SETTING(state, { setting, isEnabled }) {
      state.settings = new Map(state.settings.set(setting, isEnabled));
    },
  },
  actions: {
    setSetting({ commit }, payload) {
      commit('SET_SETTING', payload);
    },
  },
};
