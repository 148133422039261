import { ref, watch, nextTick, onMounted, computed, } from '@vue/composition-api';
import debounce from 'lodash.debounce';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
const SCROLL_OFFSET = 100;
export var ScrollDirection;
(function (ScrollDirection) {
    ScrollDirection["TOP"] = "top";
    ScrollDirection["BOTTOM"] = "bottom";
})(ScrollDirection || (ScrollDirection = {}));
export var ListDirection;
(function (ListDirection) {
    ListDirection["BACKWARD"] = "backward";
    ListDirection["FORWARD"] = "forward";
})(ListDirection || (ListDirection = {}));
export const useInfinityScroll = () => {
    const scrollContainer = ref(null);
    const prevScrollHeight = ref(0);
    const scrollDirection = ref(ScrollDirection.TOP);
    const { updatesCounter: bottomTouches, update: touchBottom } = useUpdatesCounter();
    const { updatesCounter: topTouches, update: touchTop } = useUpdatesCounter();
    const triggers = computed(() => ({
        bottomTouches,
        topTouches,
    }));
    const trackTopAndBottomBoundariesTouches = () => {
        if (!scrollContainer.value) {
            return;
        }
        const isTopReached = scrollContainer.value?.scrollTop <= SCROLL_OFFSET;
        const isBottomReached = scrollContainer.value.scrollTop + scrollContainer.value.clientHeight >=
            scrollContainer.value.scrollHeight - SCROLL_OFFSET;
        if (isTopReached) {
            touchTop();
            scrollDirection.value = ScrollDirection.TOP;
        }
        if (isBottomReached) {
            touchBottom();
            scrollDirection.value = ScrollDirection.BOTTOM;
        }
    };
    const scrollToBottom = () => {
        setTimeout(() => {
            scrollContainer.value?.scrollTo({
                top: scrollContainer.value?.scrollHeight,
            });
        }, 0);
    };
    const scrollToEnd = () => {
        if (scrollContainer.value) {
            const { scrollHeight, offsetHeight } = scrollContainer.value;
            scrollContainer.value.scrollTo({
                top: scrollHeight - offsetHeight,
            });
            prevScrollHeight.value = scrollHeight;
        }
    };
    const persistScrollPosition = () => {
        nextTick(() => {
            if (scrollContainer.value) {
                const { scrollHeight } = scrollContainer.value;
                scrollContainer.value.scrollTo({
                    top: scrollHeight - prevScrollHeight.value,
                });
                prevScrollHeight.value = scrollHeight;
            }
        });
    };
    onMounted(() => {
        scrollContainer.value?.addEventListener('scroll', debounce(() => trackTopAndBottomBoundariesTouches(), 500));
    });
    watch(scrollContainer, () => {
        nextTick(() => {
            scrollToEnd();
        });
    });
    return {
        scrollContainer,
        scrollToEnd,
        scrollToBottom,
        persistScrollPosition,
        scrollDirection,
        triggers,
    };
};
