import { onUnmounted } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
const maxAllowedFileSize = 1000 * 1024 * 1024;
const maxFilesAllowed = 10;
export const useAttachment = (ctx) => {
    const { useActions, useState } = createNamespacedHelpers('attachment');
    const { fetchAttachments, addToUploadQueue, removeFromUploadQueue, clearUploadQueue, addToDeleteQueue, clearDeleteQueue, } = useActions([
        'fetchAttachments',
        'addToUploadQueue',
        'removeFromUploadQueue',
        'clearUploadQueue',
        'addToDeleteQueue',
        'clearDeleteQueue',
    ]);
    const { attachments, uploadQueue, deleteQueue } = useState([
        'attachments',
        'uploadQueue',
        'deleteQueue',
    ]);
    onUnmounted(() => {
        clearUploadQueue();
        clearDeleteQueue();
    });
    const handleAddFile = async (e) => {
        const target = e.target;
        if (!target.files || uploadQueue.value.length === maxFilesAllowed) {
            ctx.root.$toast.error(ctx.root.$t('image-uploader.amount-error'));
            return;
        }
        const newFile = target.files[0];
        if (newFile.size > maxAllowedFileSize) {
            ctx.root.$toast.error(ctx.root.$t('image-uploader.size-error'));
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const target = e.target;
            addToUploadQueue({
                target: 'report',
                params: {
                    file: newFile,
                    previewImage: target.result,
                },
            });
        };
        reader.readAsDataURL(newFile);
    };
    const handleFileRemove = (id) => {
        if (uploadQueue.value.find((file) => file.id === id)) {
            removeFromUploadQueue(id);
        }
        else {
            addToDeleteQueue({ id });
        }
    };
    return {
        attachments,
        files: uploadQueue,
        filesToDelete: deleteQueue,
        handleAddFile,
        handleFileRemove,
        clearUploadQueue,
        clearDeleteQueue,
        fetchAttachments,
    };
};
