import { RadioButton } from '@/components/Inputs';
import { computed, defineComponent } from '@vue/composition-api';
import { getCellStyle, getCellValue, } from '@/views/Brand/Places/_components/budget-summary/_utils';
export default defineComponent({
    components: { RadioButton },
    props: {
        column: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
        objectType: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const cellValue = computed(() => getCellValue(ctx, props.column, props.stats));
        return { cellValue, getCellStyle };
    },
});
