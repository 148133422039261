export default {
  props: {
    ellipsisOnly: {
      type: Boolean,
      default: false,
    },
    withButtons: {
      type: [Object, Boolean, Function],
      default: false,
    },
    removeBtns: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    maxActionItems() {
      return Math.max(
        ...this.formattedRows.map((row) => {
          return Object.keys(this.getActions(row)).length;
        }),
      );
    },
    actionButtonsStyle() {
      return {
        width: `${this.maxActionItems * 2 + 2}rem`,
      };
    },
  },
  methods: {
    getEllipsisItems(row, index) {
      const items = {};
      const actions = this.getActions(this.rows[index]);

      Object.entries(actions).forEach(([key, action]) => {
        const translationKey = `ellipsis-menu.${key}`;
        const name =
          this.$t(translationKey) === translationKey
            ? key
            : this.$t(translationKey);

        items[key] = {
          name,
          handler: () =>
            typeof action === 'function'
              ? action(row.id)
              : action.handler(row.id),
          modal: this.getModal(action.modal, this.rows[index]) || false,
        };
      });

      return items;
    },
    getActions(row) {
      return typeof this.withButtons === 'function'
        ? this.withButtons(row)
        : this.withButtons;
    },
    getModal(modal, row) {
      return typeof modal === 'function' ? modal(row) : modal;
    },
    getActionButton(key, action) {
      return {
        icon: key,
        handler: typeof action === 'function' ? action : action.handler,
        modal: action.modal || false,
        disabled: action.disabled || false,
      };
    },
  },
};
