<template>
  <footer-menu>
    <div class="flex justify-between">
      <div class="flex items-center">
        <btn
          :disabled="loading"
          :is-loading="loading"
          class="w-48 mr-3"
          type="submit"
        >
          {{ btnText }}
        </btn>
      </div>
      <btn :theme="BTN_THEMES.NONE" :to="lastRoute">
        {{ $t('common.cancel') }}
      </btn>
    </div>
  </footer-menu>
</template>

<script>
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import Btn from '@/components/Button/Button';
import { BTN_THEMES } from '@/store/modules/ui';
import { FORM_MODE } from '@/constants/form';

export default {
  components: { FooterMenu, Btn },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
      validate: (mode) => Object.values(FORM_MODE).includes(mode),
    },
    lastRoute: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      BTN_THEMES,
    };
  },
  computed: {
    btnText() {
      return this.mode === FORM_MODE.ADD
        ? this.$t('common.add')
        : this.$t('common.save');
    },
  },
};
</script>
