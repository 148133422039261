import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import { BtnSize, BtnTheme } from '@/components/Btn';
import { defineComponent, onMounted, ref, } from '@vue/composition-api';
import { getNextReport, getPreviousReport, } from '@/api/requests/reports';
import { ClientRouteName } from '@/router/router.constants';
import NavigationArrow from '@/components/NavigationArrow/NavigationArrow.vue';
import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
export default defineComponent({
    components: { NavigationArrow, Tooltip },
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const query = ctx.root.$route.query;
        const reports = ref({
            prev: null,
            next: null,
        });
        const getReports = async () => {
            const params = ctx.root.$route.params;
            const [{ id: prevId }, { id: nextId }] = await Promise.all([
                getPreviousReport(props.report.id, { brandId: params.id, ...query }),
                getNextReport(props.report.id, { brandId: params.id, ...query }),
            ]);
            reports.value.prev = prevId;
            reports.value.next = nextId;
        };
        onMounted(() => {
            getReports();
        });
        return {
            BtnTheme,
            BtnSize,
            mdiChevronLeft,
            mdiChevronRight,
            reports,
            CONTROLLER_DIRECTION,
            ClientRouteName,
            query,
        };
    },
});
