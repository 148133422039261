<template>
  <settings-content-wrapper>
    <div
      class="pb-4 mb-4 flex justify-between h-16 border-b border-b-gray-light"
    >
      <breadcrumbs
        :path="[
          {
            name: `${$t('categories-view.categories')}`,
          },
        ]"
        class="self-start"
      />
      <router-link :to="{ name: 'AddCategory' }">
        <btn class="w-30 self-end">{{ $t('common.add') }}</btn>
      </router-link>
    </div>
    <div v-if="!categories">
      <loading-spinner is-loading class="h-10 w-10" />
    </div>
    <div v-if="categories" class="flex bg-neutral flex-wrap">
      <tree-category-root
        v-for="category in categories.results"
        :id="category.id"
        :key="category.id"
        :icon="category.cdnIconUrl"
        :category="category"
        :name="category.name"
        :context-menu="{
          edit: {
            name: $t('common.edit'),
            handler: () => {
              onEditCategory(category.id, category);
            },
          },
        }"
        class="mr-4 mb-4 max-w-50"
        :active="selectedCategory === category.id"
        @close="selectedCategory = null"
        @click="
          selectedCategory =
            category.id === selectedCategory ? null : category.id
        "
      />
    </div>
    <div v-if="categories && categories.results && !categories.results.length">
      <h3>{{ $t('categories-view.no-categories') }}</h3>
    </div>
  </settings-content-wrapper>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import TreeCategoryRoot from '@/components/Tree/TreeCategoryRoot';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import VueStore from '@/store';
import { matchRouteWithName } from '@/util/routing';
import SettingsContentWrapper from '@/layouts/BrandLayout/Components/SettingsContentWrapper.vue';

export default {
  name: 'Categories',
  components: {
    SettingsContentWrapper,
    Breadcrumbs,
    Btn,
    TreeCategoryRoot,
    LoadingSpinner,
  },
  async beforeRouteEnter(to, from, next) {
    const brandId = VueStore.getters['brand/currentBrandId'](to);

    await VueStore.dispatch('brand/fetchBrandForms', brandId);
    //
    // const { data } = await VueStore.dispatch('brand/fetchMainCategory', {
    //   brandId,
    // });

    await VueStore.dispatch('brand/fetchBrandCategory', {
      brandId,
      categoryId: null,
    });
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (!matchRouteWithName(to, 'Categories')) {
      VueStore.dispatch('brand/clearCategories');
    }
    next();
  },
  data() {
    return {
      selectedCategoryId: '',
      selectedCategory: null,
    };
  },
  computed: {
    ...mapState('brand', {
      categories: 'categories',
      forms: 'forms',
      error: 'error',
    }),
  },
  methods: {
    ...mapActions('brand', ['clearCategories']),
    ...mapMutations('brand', ['SET_CURRENT_CATEGORY', 'SET_CATEGORIES']),
    onEditCategory(id, category = false) {
      if (category) this.SET_CURRENT_CATEGORY({ data: category });

      this.$router.push({ name: 'EditCategory', params: { categoryId: id } });
    },
  },
};
</script>
