var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-neutral min-h-full"},[_c('overlap-sidebar',{attrs:{"width":"50%","side":"right","is-open":_vm.isOpen},on:{"close":_vm.toggleSidebar}},[_c('tabs',{staticClass:"pb-48"},[_c('tab',{attrs:{"name":_vm.$t('categories-view.add-category'),"selected":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onAddCategory)}}},[_c('div',{staticClass:"pb-4 mt-4 flex justify-end"},[_c('btn',{staticClass:"w-30",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('common.add')))])],1),_c('image-uploader',{attrs:{"icon":"","img-url":_vm.iconUrl},on:{"update:imgUrl":function($event){_vm.iconUrl=$event},"update:img-url":function($event){_vm.iconUrl=$event}}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('categories-view.name'),"placeholder":_vm.$t('categories-view.name-input-placeholder'),"error-message":errors[0]},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}})]}}],null,true)})],1)]}}])})],1)],1)],1),_c('div',{staticClass:"px-10 py-8"},[_c('div',{staticClass:"mb-6"},[(_vm.brand)?_c('breadcrumbs',{attrs:{"path":[
          {
            name: ("" + (_vm.$t('categories-view.categories'))),
            url: { name: 'Categories' },
          },
          { name: _vm.brand.name },
          { name: ("" + (_vm.$t('categories-view.add-category'))) } ]}}):_vm._e()],1),(!_vm.isCategoryCreated)?_c('div',{staticClass:"w-50 h-50 flex items-center justify-center border border-dashed border-gray-light bg-white cursor-pointer",on:{"click":function($event){_vm.isOpen = true}}},[_c('icon',{attrs:{"icon":_vm.mdiPlus,"size":_vm.IconSize.XL}})],1):_c('div',{staticClass:"w-3/5"},[_c('tree-category-add',{staticClass:"mt-4",attrs:{"label":_vm.newCategory.name,"nodes":_vm.newCategory.children,"parent-id":_vm.newCategory.id,"icon":_vm.newCategory.iconUrl,"root":"","depth":-1},on:{"categoryUpdated":_vm.onUpdateCategory}})],1)]),_c('footer-menu',{staticClass:"bg-white"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('btn',{staticClass:"w-48 mr-3",attrs:{"disabled":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.save')))])])],1),_c('router-link',{attrs:{"to":{ name: 'Categories' }}},[_c('btn',{attrs:{"theme":"none"}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }