import DefaultMapImage from '@/assets/images/maps/default.png';
import SatelliteMapImage from '@/assets/images/maps/satellite.png';
import SilverMapImage from '@/assets/images/maps/silver.png';
export var MAP_TYPE;
(function (MAP_TYPE) {
    MAP_TYPE["SATELLITE"] = "satellite";
    MAP_TYPE["DEFAULT"] = "roadmap";
})(MAP_TYPE || (MAP_TYPE = {}));
export var MAP_TYPE_OPTION;
(function (MAP_TYPE_OPTION) {
    MAP_TYPE_OPTION["TERRAIN"] = "terrain";
    MAP_TYPE_OPTION["HYBRID"] = "hybrid";
})(MAP_TYPE_OPTION || (MAP_TYPE_OPTION = {}));
export var MAP_STYLE;
(function (MAP_STYLE) {
    MAP_STYLE["SILVER"] = "silver";
    MAP_STYLE["STANDARD"] = "standard";
})(MAP_STYLE || (MAP_STYLE = {}));
export const mapStyles = {
    [MAP_STYLE.STANDARD]: {
        name: MAP_STYLE.STANDARD,
        label: 'maps.styles.standard',
        options: [],
        image: DefaultMapImage,
    },
    [MAP_TYPE.SATELLITE]: {
        name: MAP_TYPE.SATELLITE,
        label: 'maps.styles.satellite',
        options: [
            {
                label: 'maps.styles.labels',
                typeId: MAP_TYPE_OPTION.HYBRID,
            },
        ],
        image: SatelliteMapImage,
    },
    [MAP_STYLE.SILVER]: {
        name: MAP_STYLE.SILVER,
        label: 'maps.styles.silver',
        options: [],
        image: SilverMapImage,
    },
    [MAP_TYPE.DEFAULT]: {
        name: MAP_TYPE.DEFAULT,
        label: 'maps.styles.default',
        options: [
            {
                label: 'maps.styles.terrain',
                typeId: MAP_TYPE_OPTION.TERRAIN,
            },
        ],
        image: DefaultMapImage,
    },
};
export const customMapStyles = {
    [MAP_STYLE.SILVER]: [
        {
            elementType: 'geometry',
            stylers: [
                {
                    color: '#F5F5F5',
                },
            ],
        },
        {
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#616161',
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#F5F5F5',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#BDBDBD',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#EEEEEE',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#757575',
                },
            ],
        },
        {
            featureType: 'poi.business',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#E5E5E5',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9E9E9E',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#FFFFFF',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#757575',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#DADADA',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#616161',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9E9E9E',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#E5E5E5',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#EEEEEE',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#C9C9C9',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9E9E9E',
                },
            ],
        },
    ],
    [MAP_STYLE.STANDARD]: [
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.business',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
    ],
};
