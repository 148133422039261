<template>
  <checkbox
    :value="inputValue"
    :disabled="!toggleEnabled"
    :theme="CheckboxTheme.SECONDARY"
    @input="onInput"
  />
</template>
<script>
import { Checkbox, CheckboxTheme } from '@/components/Inputs';

const DEFAULT_CONFIG = {
  enableToggle: false,
  onToggle: () => {},
};

export default {
  components: { Checkbox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputValue: this.value,
      CheckboxTheme,
      updateKey: 0,
    };
  },
  computed: {
    computedConfig() {
      return {
        ...DEFAULT_CONFIG,
        ...this.config,
      };
    },
    toggleEnabled() {
      // HACK: force update property without reload or component's rerender
      this.updateKey;

      if (typeof this.computedConfig.enableToggle === 'function')
        return this.computedConfig.enableToggle(this.inputValue, this.row);

      return this.computedConfig.enableToggle;
    },
  },
  methods: {
    onInput() {
      this.computedConfig.onToggle(this.row, this.inputValue);

      this.updateKey++;
    },
  },
};
</script>
