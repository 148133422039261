import api from '@/api';
export const PLACES_API_PATH = 'places';
export const getPlacesList = async (params) => {
    const { data } = await api.get('places', { params });
    return data;
};
export const getSinglePlace = async (placeId) => {
    const { data } = await api.get(`places/${placeId}`);
    return data;
};
export const setPlaceObjectTypeBudget = async (placeId, objectTypeId, payload) => await api.put(`places/${placeId}/objectType/${objectTypeId}/budget`, payload);
