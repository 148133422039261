var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.brand)?_c('overlap-sidebar',{attrs:{"side":"right","width":_vm.sidebarWidth,"is-open":_vm.isOpen},on:{"close":_vm.toggleSidebar}},[_c('tabs',{staticClass:"max-h-full overflow-scroll pb-48"},[_c('tab',{attrs:{"name":_vm.$t(("structures-view." + _vm.title)),"selected":""}},[(_vm.place)?_c('places-form',{attrs:{"selected-items":_vm.selectedItems,"edit-mode":_vm.mode === 'edit'},on:{"submit":_vm.onSubmitPlaces}}):_c('structure-form',{attrs:{"data":_vm.structureData,"root":_vm.rootEdit,"edit-mode":_vm.mode === 'edit'},on:{"submit":_vm.onSubmitStructure}})],1)],1)],1):_vm._e(),(_vm.brand)?_c('div',{staticClass:"px-4 md:px-10 pt-8 pb-36"},[_c('div',{staticClass:"mb-6"},[_c('breadcrumbs',{attrs:{"path":[
          {
            name: ("" + (_vm.$t('structures-view.structures'))),
            url: { name: 'Structures' },
          },
          {
            name: _vm.brand.name,
          },
          {
            name: ("" + (_vm.$t('structures-view.add-structure'))),
          } ]}})],1),_c('div',{staticClass:"pr-10 min-w-full md:min-w-1/2 inline-block"},[(_vm.rootStructure)?_c('tree-structure-add',{staticClass:"mt-4",attrs:{"label":_vm.rootStructure.name,"places":_vm.rootStructure.places,"parent-id":_vm.rootStructure.id,"has-children":_vm.rootStructure.hasChildren,"root":"","depth":0,"disabled":_vm.loading},on:{"addStructure":_vm.onAddStructure,"addPlace":_vm.onAddPlace,"editStructure":_vm.onEditStructure,"editPlaces":_vm.onEditPlaces,"deleteStructure":_vm.deleteStructure}}):_vm._e()],1)]):_vm._e(),_c('footer-menu',{staticClass:"bg-white"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('btn',{staticClass:"w-48 mr-3",attrs:{"disabled":_vm.loading || !_vm.unsavedChanges,"is-loading":_vm.loading},on:{"click":_vm.onSaveChanges}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('structures-view.publish')))]):_vm._e()])],1),_c('router-link',{attrs:{"to":{ name: 'Structures' }}},[_c('btn',{attrs:{"theme":"none"}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }