import { defineComponent } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import Popup from './components/Popup.vue';
const { useState } = createNamespacedHelpers('ui');
export default defineComponent({
    components: {
        Popup,
    },
    props: {
        sideBarWidth: {
            type: Number,
            default: 328,
        },
        value: {
            type: Boolean,
            default: false,
        },
        showResources: {
            type: Boolean,
            default: false,
        },
        showResourcesToggler: {
            type: Boolean,
            default: true,
        },
        sidebarClass: {
            type: String,
            default: 'p-4',
        },
    },
    setup() {
        const { mobileView } = useState(['mobileView']);
        return {
            mobileView,
        };
    },
});
