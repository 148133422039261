<template>
  <div class="h-full">
    <overlap-sidebar
      v-if="brand"
      side="right"
      :width="sidebarWidth"
      :is-open="isOpen"
      @close="toggleSidebar"
    >
      <tabs class="max-h-full overflow-scroll pb-48">
        <tab :name="$t(`structures-view.${title}`)" selected>
          <places-form
            v-if="place"
            :selected-items="selectedItems"
            :edit-mode="mode === 'edit'"
            @submit="onSubmitPlaces"
          />
          <structure-form
            v-else
            :data="structureData"
            :root="rootEdit"
            :edit-mode="mode === 'edit'"
            @submit="onSubmitStructure"
          />
        </tab>
      </tabs>
    </overlap-sidebar>
    <div v-if="brand" class="px-4 md:px-10 pt-8 pb-36">
      <div class="mb-6">
        <breadcrumbs
          :path="[
            {
              name: `${$t('structures-view.structures')}`,
              url: { name: 'Structures' },
            },
            {
              name: brand.name,
            },
            {
              name: `${$t('structures-view.add-structure')}`,
            },
          ]"
        />
      </div>
      <div class="pr-10 min-w-full md:min-w-1/2 inline-block">
        <tree-structure-add
          v-if="rootStructure"
          class="mt-4"
          :label="rootStructure.name"
          :places="rootStructure.places"
          :parent-id="rootStructure.id"
          :has-children="rootStructure.hasChildren"
          root
          :depth="0"
          :disabled="loading"
          @addStructure="onAddStructure"
          @addPlace="onAddPlace"
          @editStructure="onEditStructure"
          @editPlaces="onEditPlaces"
          @deleteStructure="deleteStructure"
        />
      </div>
    </div>
    <footer-menu class="bg-white">
      <div class="flex justify-between">
        <div class="flex items-center">
          <btn
            :disabled="loading || !unsavedChanges"
            :is-loading="loading"
            class="w-48 mr-3"
            @click="onSaveChanges"
          >
            <span v-if="!loading">{{ $t('structures-view.publish') }}</span>
          </btn>
        </div>
        <router-link :to="{ name: 'Structures' }">
          <btn theme="none">{{ $t('common.cancel') }}</btn>
        </router-link>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import difference from 'lodash.difference';
import { mapActions, mapGetters, mapState } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import TreeStructureAdd from '@/components/Tree/TreeStructureAdd.vue';
import StructureForm from '@/views/Brand/Settings/Structures/Forms/StructureForm.vue';
import PlacesForm from '@/views/Brand/Settings/Structures/Forms/PlacesForm';
import VueStore from '@/store';

export default {
  name: 'AddStructure',
  components: {
    PlacesForm,
    StructureForm,
    Breadcrumbs,
    Btn,
    FooterMenu,
    OverlapSidebar,
    Tabs,
    Tab,
    TreeStructureAdd,
  },
  async beforeRouteEnter(to, from, next) {
    const id = to.params.id || VueStore.getters['user/brand'];
    await VueStore.dispatch('structures/fetchRootStructure', id);

    next();
  },
  async beforeRouteLeave(to, from, next) {
    await VueStore.dispatch('structures/clearState');

    next();
  },
  data() {
    return {
      isOpen: false,
      parentId: '',
      loading: false,
      mode: 'add',
      place: false,
      emptyStructure: { user: {}, name: '' },
      structureData: this.emptyStructure,
      selectedItems: [],
      rootEdit: false,
    };
  },
  computed: {
    ...mapState('brand', {
      brandDetails: 'details',
      error: 'error',
    }),
    ...mapState('structures', {
      rootStructure: 'rootStructure',
    }),
    ...mapState('user', {
      userBrand: 'brand',
      userBrandDetails: 'brandDetails',
    }),
    ...mapGetters('structures', ['unsavedChanges']),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    brand() {
      return this.brandDetails || this.userBrandDetails;
    },
    title() {
      if (this.place) return 'place-list';

      return `${this.mode}-${
        this.rootStructure && this.parentId === this.rootStructure.id
          ? 'structure'
          : 'substructure'
      }`;
    },
    sidebarWidth() {
      return this.mobileView ? '90%' : '50%';
    },
    brandId() {
      return this.$route.params.id || this.userBrand;
    },
  },
  methods: {
    ...mapActions('structures', [
      'clearState',
      'fetchRootStructure',
      'saveChanges',
      'saveToStructureList',
      'addStructure',
      'editStructure',
      'deleteStructure',
      'updatePlace',
    ]),
    setMode(mode = 'add', place = false) {
      this.mode = mode;
      this.place = place;
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;

      this.setMode();
    },
    async onSubmitStructure(payload, { user }) {
      if (this.mode === 'add') {
        const { data } = await this.addStructure({
          id: this.parentId,
          data: payload,
        });
        this.toggleSidebar();

        this.saveToStructureList(data);
      } else {
        const removedUser = !payload.userId && user;

        await this.editStructure({
          id: this.parentId,
          data: {
            ...payload,
            ...(removedUser && {
              userId: null,
            }),
          },
        });

        this.toggleSidebar();

        this.$toast.success(this.$t('structures-view.structure-edited'));

        this.fetchRootStructure(this.brandId);
      }
    },
    onSubmitPlaces(places) {
      const removedPlaces = difference(this.selectedItems, places);
      const newPlaces = difference(places, this.selectedItems);

      Promise.all([
        ...newPlaces.map((place) => {
          return this.updatePlace({
            id: place,
            data: {
              structureId: this.parentId,
            },
          });
        }),
        ...removedPlaces.map((place) => {
          return this.updatePlace({
            id: place,
            data: {
              structureId: null,
            },
          });
        }),
      ]).then(() => {
        this.toggleSidebar();

        this.fetchRootStructure(this.brandId);

        this.$toast.success(this.$t('structures-view.place-success'));
      });
    },
    onSaveChanges() {
      this.loading = true;
      this.saveChanges()
        .then(() => {
          this.clearState();
          this.fetchRootStructure(this.brandId);

          this.$toast.success(this.$t('structures-view.save-success'));
          this.loading = false;
        })
        .catch(() => {
          this.fetchRootStructure(this.brandId);
          this.loading = false;
        });
    },
    onAddStructure(id) {
      this.setMode();

      this.structureData = { ...this.emptyStructure };

      this.rootEdit = false;

      this.onOpenSidebar(id);
    },
    onAddPlace(id) {
      this.setMode('add', true);
      this.selectedItems = [];

      this.onOpenSidebar(id);
    },
    onEditStructure({ id, data, isRoot }) {
      this.setMode('edit');
      this.structureData = data;
      this.rootEdit = isRoot;

      this.onOpenSidebar(id);
    },
    onEditPlaces(id, places) {
      this.setMode('edit', true);

      this.selectedItems = places.map((place) => place.id);

      this.onOpenSidebar(id);
    },
    onOpenSidebar(parentId) {
      this.isOpen = true;
      this.parentId = parentId;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar-header {
  h1 {
    @apply text-primary uppercase text-sm font-semibold border-b-2 inline-block border-primary;
  }
}
</style>
