import { onUnmounted, ref } from '@vue/composition-api';
export const useHoverPopover = (leaveDelay = 300, enterDelay = 100) => {
    const popover = ref(false);
    const enterTimeout = ref(null);
    const leaveTimeout = ref(null);
    const onMouseEnter = () => {
        // Note: using window to avoid type casting (NodeJS.Timeout type leak)
        if (leaveTimeout.value)
            window.clearTimeout(leaveTimeout.value);
        enterTimeout.value = window.setTimeout(() => {
            popover.value = true;
        }, enterDelay);
    };
    const onMouseLeave = () => {
        if (enterTimeout.value)
            window.clearTimeout(enterTimeout.value);
        leaveTimeout.value = window.setTimeout(() => {
            popover.value = false;
        }, leaveDelay);
    };
    onUnmounted(() => {
        if (enterTimeout.value)
            window.clearTimeout(enterTimeout.value);
        if (leaveTimeout.value)
            window.clearTimeout(leaveTimeout.value);
    });
    return {
        popover,
        onMouseEnter,
        onMouseLeave,
    };
};
