<template>
  <router-link :to="route">
    <div class="card" :class="{ accepted: isAccepted }">
      <div class="card-image-wrapper">
        <image-view
          :src="image"
          :alt="$t('object.object-alt', { internalNumber: number })"
          :size="ImageSize.CARD"
          cover-img
          without-padding
        />
      </div>
      <div class="card-content">
        <h5 class="object-number">{{ number }}</h5>
        <p class="object-info">{{ city }}</p>
        <p class="object-info">{{ address }}</p>
      </div>
    </div>
  </router-link>
</template>
<script>
import { ImageView, ImageSize } from '@/components/ImageView';

export default {
  components: { ImageView },
  props: {
    isAccepted: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    route: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      default: 0,
    },
    city: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ImageSize,
    };
  },
};
</script>
<style scoped lang="scss">
.card {
  @apply w-36;

  &-image-wrapper {
    @apply h-36 overflow-hidden rounded-md flex items-center justify-center;
  }

  &-image {
    @apply object-cover h-full;
  }

  &-content {
    @apply text-center py-2;

    .object-number {
      @apply text-sm font-semibold;
    }

    .object-info {
      @apply text-2xs;
    }
  }

  &.accepted {
    .object-number,
    .object-info {
      @apply text-green;
    }
  }
}
</style>
