import { computed, defineComponent, toRefs, } from '@vue/composition-api';
import { TableCell } from './_components/table-cell';
import { useBudgetSummary } from '@/views/Brand/Places/_components/budget-summary/_composables/useBudgetSummary';
import { OBJECT_TYPE_COLUMN, OBJECT_TYPES_COLUMNS, SUMMARY_COLUMNS, } from '@/views/Brand/Places/_components/budget-summary/_constants';
import { getCellStyle, getCellValue, } from '@/views/Brand/Places/_components/budget-summary/_utils';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { useAuthUser } from '@/composables';
import { SystemRole } from '@/api/requests/users';
import { useViewer } from '@/composables/use-viewer';
export default defineComponent({
    components: { TableCell, LoadingSpinner },
    props: {
        objectType: Object,
        updatesCounter: {
            type: Number,
            required: true,
        },
    },
    setup(props, ctx) {
        const { viewer, isPreviewMode } = useViewer(ctx);
        const { hasRole } = useAuthUser();
        const { updatesCounter } = toRefs(props);
        const { objectTypesStats, summaryStats, isLoading } = useBudgetSummary(ctx, updatesCounter);
        const hideStats = computed(() => {
            if (isPreviewMode.value) {
                return (viewer.value.systemRole === SystemRole.PLACE_MANAGER ||
                    viewer.value.systemRole === SystemRole.STRUCTURE_MANAGER);
            }
            else {
                return (hasRole(SystemRole.PLACE_MANAGER) ||
                    hasRole(SystemRole.STRUCTURE_MANAGER));
            }
        });
        const cellValue = (column) => getCellValue(ctx, column, summaryStats.value);
        const columns = computed(() => hideStats.value
            ? OBJECT_TYPES_COLUMNS.filter((col) => col.key === OBJECT_TYPE_COLUMN.NAME)
            : OBJECT_TYPES_COLUMNS);
        return {
            summaryStats,
            objectTypesStats,
            columns,
            SUMMARY_COLUMNS,
            hideStats,
            getCellValue,
            cellValue,
            getCellStyle,
            isLoading,
        };
    },
});
