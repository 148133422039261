import { RESOURCE_LIMIT } from '@/constants/pagination';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBrand, useExpandDetails } from '@/composables';
import PlacePreview from '@/views/Brand/Places/PlacePreview.vue';
import { ExpandButtonStyle } from '@/components/Table/TableGrid/table.constants';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { Checkbox } from '@/components/Inputs';
import { GenericTable } from '@/components/Table/__new__/_components/TableGeneric.vue';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton.vue';
import { ResourcesPagination } from '@/views/_components/Table';
import { TableStyle } from '@/components/Table/__new__';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
export default defineComponent({
    components: {
        TableLoader,
        ResourcesPagination,
        PlacePreview,
        Checkbox,
        FetchData,
        TableDisplay: GenericTable(),
        ExpandButton,
    },
    props: {
        query: {
            type: Object,
            default: () => ({}),
        },
        isSelected: {
            type: Function,
            required: true,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const activePage = ref(1);
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'number',
                    label: ctx.root.$t('brand-places-list-view.number').toString(),
                    cellClass: 'font-bold pl-0',
                },
                {
                    key: 'name',
                    label: ctx.root.$t('brand-places-list-view.name').toString(),
                    cellClass: 'font-bold',
                    gridColumn: '1fr',
                },
                {
                    key: 'expandButton',
                },
            ];
        });
        const placeQuery = computed(() => ({
            brandId: brand.value?.id,
            ...props.query,
            limit: RESOURCE_LIMIT,
            page: activePage.value,
        }));
        const { isExpanded: isPlaceDetailsExpanded, toggleExpandedItems: toggleExpandPlaceDetails, } = useExpandDetails((place) => place.id);
        return {
            placeQuery,
            columns,
            isPlaceDetailsExpanded,
            toggleExpandPlaceDetails,
            ExpandButtonStyle,
            TableStyle,
            activePage,
            RESOURCE_LIMIT,
        };
    },
});
