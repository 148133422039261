import api from '@/api';
import { getSingleUser } from '@/api/requests/users';
import { getOrganizationsList } from '@/api/requests/organizations';
import { loadAllResults } from '@/api/requests/pagination';

export default {
  namespaced: true,
  actions: {
    async addAdmin(ctx, params) {
      await api.post('/admins', params);
    },
    async updateAdmin(ctx, params) {
      await api.patch(`/admins/${params.id}`, params.data);
    },
    async getAdmin(ctx, userId) {
      const user = await getSingleUser(userId);

      user.relatedOrganizations = await loadAllResults(getOrganizationsList, {
        managedByUserId: userId,
      });

      return user;
    },
  },
};
