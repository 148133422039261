import api from '@/api';
import difference from 'lodash.difference';
import chunk from 'lodash.chunk';
import {
  getSingleUser,
  createContractorUser,
  updateContractorUser,
} from '@/api/requests/users';

export const SUBCONTRACTOR = 'subcontractor';
export const REGULAR = 'regular';

export default {
  namespaced: true,
  state: {
    brands: [],
    brandsData: {},
    rootStructure: null,
    error: false,
    structures: {},
    places: {},
    details: null,
    user: null,
    placesToRemove: {},
    placesToAdd: {},
  },
  getters: {
    allIds: () => (dictionary) => {
      return Object.values(dictionary).reduce(
        (result, elements) => [...result, ...elements],
        [],
      );
    },
    addedPlacesIds: (state, getters) => getters.allIds(state.placesToAdd),
    removedPlacesIds: (state, getters) => getters.allIds(state.placesToRemove),
    structuresIds: (state, getters) =>
      getters.allIds(state.structures).map(({ id }) => id),
  },
  mutations: {
    SET_BRANDS(state, brands) {
      const currentBrandIds = state.brands.map((brand) => brand.id);
      const newBrandIds = brands.map((brand) => brand.id);

      const newBrands = difference(newBrandIds, currentBrandIds);
      const removedBrands = difference(currentBrandIds, newBrandIds);

      removedBrands.forEach((brand) => {
        delete state.placesToAdd[brand];
        delete state.structures[brand];
      });

      newBrands.forEach((brand) => {
        state.structures[brand] = [];
        state.placesToAdd[brand] = [];

        if (!state.placesToRemove[brand]) state.placesToRemove[brand] = [];
      });

      state.structures = { ...state.structures };

      state.brands = brands;
    },
    ADD_STRUCTURE(state, { brandId, data }) {
      const currentStructures = state.structures[brandId] || [];

      if (!currentStructures.find((structure) => structure.id === data.id)) {
        state.structures[brandId] = [...currentStructures, data];

        state.structures = { ...state.structures };
      }
    },
    REMOVE_STRUCTURE(state, { brandId, id }) {
      state.structures[brandId] = state.structures[brandId].filter(
        (structure) => structure.id !== id,
      );

      state.structures = { ...state.structures };
    },
    ADD_PLACE(state, { id, brandId, removeItemFromState }) {
      const items = removeItemFromState
        ? state.placesToAdd[brandId].filter((item) => item !== id)
        : [...state.placesToAdd[brandId], id];

      state.placesToAdd = {
        ...state.placesToAdd,
        [brandId]: [...new Set(items)],
      };
    },
    REMOVE_PLACE(state, { id, brandId, removeItemFromState }) {
      const items = removeItemFromState
        ? state.placesToRemove[brandId].filter((item) => item !== id)
        : [...state.placesToRemove[brandId], id];

      state.placesToRemove = {
        ...state.placesToRemove,
        [brandId]: [...new Set(items)],
      };
    },
    SET_ROOT_STRUCTURE(state, { data }) {
      state.rootStructure = data?.results[0];
    },
    SET_CONTRACTOR(state, { data }) {
      state.details = data;

      state.error = false;
    },
    SET_CONTRACTOR_USER(state, user) {
      state.user = user;
    },
    SET_ERROR(state) {
      state.error = true;
    },
    CLEAR_STATE(state) {
      state.error = false;
      state.brandsData = {};
      state.rootStructure = null;
      state.brands = [];
      state.details = null;
      state.user = null;
      state.structures = {};
    },
    CLEAR_PLACES(state) {
      state.placesToRemove = [];
      state.placesToAdd = [];
    },
  },
  actions: {
    setBrands({ commit }, brands) {
      commit('SET_BRANDS', brands);
    },
    async fetchRootStructure({ commit }, id) {
      commit('SET_ROOT_STRUCTURE', { data: null });
      try {
        const params = new URLSearchParams();
        params.append('parentId', null);
        params.append('brandId', id);

        commit('SET_ROOT_STRUCTURE', await api.get('/structures', { params }));
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    async fetchPlaces(ctx, params) {
      return api.get('places', { params });
    },
    async fetchContractorDetails({ commit }, id) {
      commit('CLEAR_STATE');
      commit('SET_CONTRACTOR', { data: null });

      try {
        const contractor = await api.get(`/organizations/${id}`);
        commit('SET_CONTRACTOR', contractor);

        const { relatedContractorBrands, relatedContractorStructures } =
          contractor.data;

        commit('SET_BRANDS', relatedContractorBrands);

        relatedContractorStructures.forEach((structure) => {
          commit('ADD_STRUCTURE', {
            brandId: structure.brand.id,
            data: structure,
          });
        });
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    clearState({ commit }) {
      commit('CLEAR_STATE');
    },
    clearPlaces({ commit }) {
      commit('CLEAR_PLACES');
    },
    removeStructure({ commit }, payload) {
      commit('REMOVE_STRUCTURE', payload);
    },
    addStructure({ commit }, payload) {
      commit('ADD_STRUCTURE', payload);
    },
    removePlace({ commit }, payload) {
      commit('REMOVE_PLACE', payload);
    },
    addPlace({ commit }, payload) {
      commit('ADD_PLACE', payload);
    },
    addContractor(ctx, data) {
      return api.post(`/organizations`, data);
    },
    editContractor(ctx, { data, contractorId }) {
      return api.patch(`/organizations/${contractorId}`, data);
    },
    addContractorUser(ctx, data) {
      return createContractorUser(data);
    },
    editContractorUser(ctx, { userId, data }) {
      return updateContractorUser(userId, data);
    },
    async fetchContractorUser({ commit }, { userId }) {
      commit('SET_CONTRACTOR_USER', await getSingleUser(userId));
    },
    deleteLogo(ctx, contractorId) {
      return api.delete(`/contractors/${contractorId}/logo`);
    },
    async addPlaceToContractor(ctx, { contractorId, data }) {
      const chunks = chunk(data.placeIds, 100);

      await Promise.all(
        chunks.map((placeIds) =>
          api.post(`/contractors/${contractorId}/places`, { placeIds }),
        ),
      );
    },
    async removePlaceFromContractor(ctx, { contractorId, data }) {
      const chunks = chunk(data, 100);
      const placeIds = chunks.map((array) =>
        array.map((id) => `placeIds=${id}`).join('&'),
      );

      await Promise.all(
        placeIds.map((query) =>
          api.delete(`/contractors/${contractorId}/places?${query}`),
        ),
      );
    },
    fetchContractor(ctx, id) {
      return api.get(`/organizations/${id}`);
    },
  },
};
