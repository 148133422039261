<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="w-1/4 mr-4"
    :rules="{ regex: postalCodeRegex }"
  >
    <text-input
      v-model="computedPostalCode"
      :placeholder="$t('object-form.address-placeholder-2')"
      :error-message="errors[0]"
      :disabled="isLoading || disabled"
      class="my-4"
    />
  </ValidationProvider>
</template>
<script>
import { postalCodeRegex } from '@/util/form';
import { extend, ValidationProvider } from 'vee-validate';
import { TextInput } from '@/components/Inputs';
import { regex } from 'vee-validate/dist/rules';

export default {
  components: { ValidationProvider, TextInput },
  props: {
    isLoading: Boolean,
    value: {
      type: String,
      default: null,
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      postalCodeRegex,
      postalCode: '',
    };
  },
  computed: {
    computedPostalCode: {
      get() {
        return this.postalCode || null;
      },
      set(value) {
        this.postalCode = value;
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        this.postalCode = val;
      },
      immediate: true,
    },
    computedPostalCode: {
      handler(val) {
        if (val !== this.value) this.$emit('input', val);
      },
    },
  },
  created() {
    extend('regex', {
      ...regex,
      message: this.$t('errors.error-regex'),
    });
  },
};
</script>
