<template>
  <div v-if="hasDefinedPosition(object)">
    <div class="p-5">
      <clustered-map
        v-if="showRouteOnMap"
        height="h-120"
        :route-start="{
          position: { lat: object.lat, lng: object.lng },
          customIcon: object.objectType.iconUrl,
          markerColor: routeStartMarkerColor,
          directionAngle: object.directionAngle,
          label: object.internalNumber,
          labelFormatter: (label) => `#${label}`,
          type: MapDataType.OBJECT,
        }"
        :route-end="{
          position: {
            lat: object.place.lat,
            lng: object.place.lng,
          },
          label: object.place.number,
          labelFormatter: (label) => `#${label}`,
          type: MapDataType.PLACE,
        }"
      />
      <clustered-map
        v-else
        height="h-120"
        :data-sets="[
          {
            name: 'objects',
            url: `objects/${object.id}`,
            type: MapDataType.OBJECT,
            hidePreviewButton: true,
            iconAccessor: ['objectType', 'iconUrl'],
            colorAccessor: ['objectType', 'color'],
            labelAccessor: ['internalNumber'],
            labelFormatter: (label) => `#${label}`,
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import { OBJECT_LOCALIZATION } from '@/constants/object';
import { mapState } from 'vuex';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';

export default {
  components: {
    ClusteredMap,
  },
  props: {
    separatedMaps: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('object', {
      object: 'details',
      objectType: 'objectType',
    }),
    routeStartMarkerColor() {
      return this.object.objectType.color;
    },
    showRouteOnMap() {
      return (
        this.object.localization === OBJECT_LOCALIZATION.OUTSIDE &&
        this.hasDefinedPosition(this.object.place)
      );
    },
  },
  methods: {
    hasDefinedPosition(el) {
      return el?.lat && el?.lng;
    },
  },
  setup() {
    return { MapDataType };
  },
};
</script>
