import { ref } from '@vue/composition-api';
export const useToggle = (initialState = false) => {
    const state = ref(initialState);
    const toggle = () => {
        state.value = !state.value;
    };
    const disable = () => {
        state.value = false;
    };
    const enable = () => {
        state.value = true;
    };
    return { state, toggle, enable, disable };
};
