import { computed, ref, watch } from '@vue/composition-api';
import { Direction } from '@/components/WysiwygInput/wysiwyg.constants';
export const useScrollOptions = (selectedIndex) => {
    const option = ref(null);
    const scrollContainer = ref(null);
    const selectedOption = computed(() => {
        return option.value?.[selectedIndex.value]?.$el || null;
    });
    const selectedOptionBoundary = computed(() => {
        if (selectedOption.value) {
            const top = selectedIndex.value * selectedOption.value.scrollHeight;
            return {
                top,
                bottom: top + selectedOption.value.scrollHeight,
            };
        }
        return null;
    });
    const containerBoundary = computed(() => {
        if (scrollContainer.value) {
            const { scrollTop, offsetHeight } = scrollContainer.value;
            return {
                top: scrollTop,
                bottom: scrollTop + offsetHeight,
            };
        }
        return null;
    });
    watch(selectedIndex, (index, prevIndex) => {
        const direction = Math.sign(index - prevIndex);
        scrollIntoView(direction);
    });
    const getMinRequiredScroll = (option, container, direction) => {
        const inContainer = option.top >= container.top && option.bottom <= container.bottom;
        if (inContainer)
            return 0;
        if (direction === Direction.DOWN) {
            return option.bottom - container.bottom;
        }
        return container.top - option.top;
    };
    const scrollIntoView = (direction) => {
        if (scrollContainer.value &&
            selectedOptionBoundary.value &&
            containerBoundary.value) {
            const scroll = getMinRequiredScroll(selectedOptionBoundary.value, containerBoundary.value, direction);
            scrollContainer.value.scrollTo({
                top: containerBoundary.value.top + scroll,
                behavior: 'smooth',
            });
        }
    };
    return { option, scrollContainer };
};
