import routeQuery from '@/mixins/query/routeQuery';
import { DEFAULT_CARD_LIMIT, ROUTE_CARD_LIMIT } from '@/constants/pagination';
import { FILTER_DEBOUNCE } from '@/constants/filters';

export default {
  mixins: [routeQuery],
  data() {
    return {
      FILTER_DEBOUNCE,
    };
  },
  computed: {
    limit() {
      return ROUTE_CARD_LIMIT[this.$route.name] || DEFAULT_CARD_LIMIT;
    },
  },
  mounted() {
    this.setRoutePagination({
      route: this.$route,
      pagination: { limit: this.limit },
    });
  },
  methods: {
    changePage(page) {
      this.setRoutePagination({ route: this.$route, pagination: { page } });
    },
  },
};
