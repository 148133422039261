var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fetch-data',{attrs:{"url":_vm.url,"params":_vm.query},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
return [(isLoading || _vm.loading)?_c('div',{staticClass:"flex justify-center"},[_c('LoadingSpinner',{staticClass:"w-12 h-12",attrs:{"is-loading":""}})],1):[_c('div',{staticClass:"wrapper"},_vm._l((results),function(object){return _c('offer-object-card',{key:object.id,staticClass:"card",attrs:{"image":object.cdnImageUrl,"is-accepted":_vm.acceptedByCentral(object) || _vm.acceptedObjects.includes(object.id),"route":{
          name: 'ObjectInOfferDetails',
          params: {
            objectId: object.id,
            offerId: _vm.currentOffer.id,
          },
        },"number":object.internalNumber,"address":object.address,"city":object.city}})}),1)]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }