import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { getReportMessage } from '@/api/requests/reports';
import { WysiwygInput } from '@/components/WysiwygInput';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { useBrand } from '@/composables';
import UserAvatar from '@/views/_components/UserAvatar.vue';
import { getFullName } from '@/util/user';
import { date } from '@/util/formatters';
import { MessageResourcesDisplay } from '@/views/Brand/Applications/Report/_components/MessageResourcesDisplay';
export default defineComponent({
    components: { MessageResourcesDisplay, UserAvatar, WysiwygInput },
    props: {
        responseMessageId: String,
    },
    setup(props, ctx) {
        const message = ref(null);
        const { brand } = useBrand();
        const getMentionSuggestions = createMentionUserFn({
            accessToBrandId: brand.value.id,
        });
        onMounted(async () => {
            if (!props.responseMessageId) {
                return;
            }
            const data = await getReportMessage(props.responseMessageId);
            ctx.emit('additional-content-loaded');
            message.value = data;
        });
        const scrollToActionRequiredMessage = () => {
            if (!props.responseMessageId) {
                return;
            }
            //TODO: handle case when el is paginated and not in current fetched page
            document.getElementById(props.responseMessageId)?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        };
        return {
            message,
            getMentionSuggestions,
            getFullName,
            date,
            scrollToActionRequiredMessage,
        };
    },
});
