export const createMarkerContent = (icon, label) => {
    if (icon === null) {
        return null;
    }
    if (!label) {
        const content = document.createElement('img');
        content.src = icon;
        return content;
    }
    const content = document.createElement('div');
    content.innerHTML = `
  <div class="bg-white rounded-1 p-0.5 shadow-card font-semibold text-center" style="font-size: 0.8rem">${label}</div>
  <img src="${icon}" alt="Marker icon" class="mx-auto" />
`;
    return content;
};
