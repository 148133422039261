import Btn from '@/components/Button/Button.vue';
import { Icon } from '@/components/Icon';
import { defineComponent } from '@vue/composition-api';
import { mdiPlus } from '@mdi/js';
export default defineComponent({
    name: 'AddReportButton',
    components: {
        Btn,
        Icon,
    },
    props: {
        openPopup: Function,
        text: {
            type: String,
            default: 'common.add',
        },
        buttonClass: {
            type: String,
            default: 'w-26',
        },
    },
    setup(props) {
        const handleClick = () => {
            if (props.openPopup)
                props.openPopup();
        };
        return { mdiPlus, handleClick };
    },
});
