import api from '@/api';
import { getPropertyArray, splitArrayIntoChunks } from '@/util/formatters';

export default {
  namespaced: true,
  state: {
    placesToSelect: [],
    placesToRemove: [],
    objectsToSelect: [],
    objectsToRemove: [],
    objectsAddedInOffer: [],
    forceCheckboxUpdate: 0,
  },
  mutations: {
    SET_SELECTED_OBJECTS(state, objects) {
      state.objectsToSelect = objects;
    },
    SET_REMOVED_OBJECTS(state, objects) {
      state.objectsToRemove = objects;
    },
    SET_SELECTED_PLACES(state, places) {
      state.placesToSelect = places;
    },
    SET_REMOVED_PLACES(state, places) {
      state.placesToRemove = places;
    },
    REPLACE_OFFER_OBJECT(state, { object, index }) {
      state.objectsAddedInOffer = [
        ...state.objectsAddedInOffer.slice(0, index),
        object,
        ...state.objectsAddedInOffer.slice(index + 1),
      ];
    },
    ADD_OFFER_OBJECT(state, object) {
      state.objectsAddedInOffer.push(object);
    },
    CLEAR_OFFER_STATE(state) {
      state.objectsAddedInOffer = [];
      state.placesToSelect = [];
      state.placesToRemove = [];
      state.objectsToSelect = [];
      state.objectsToRemove = [];
      state.forceCheckboxUpdate = 0;
    },
    SET_UPDATE_COUNT(state, payload) {
      state.forceCheckboxUpdate = payload;
    },
  },
  getters: {
    selectedPlaceIds: (state) => getPropertyArray(state.placesToSelect, 'id'),
    removedPlaceIds: (state) => getPropertyArray(state.placesToRemove, 'id'),
    selectedObjectIds: (state) => getPropertyArray(state.objectsToSelect, 'id'),
    removedObjectIds: (state) => getPropertyArray(state.objectsToRemove, 'id'),
    offerObjectIds: (state) =>
      getPropertyArray(state.objectsAddedInOffer, 'id'),
  },
  actions: {
    async addObjectsToOffer(ctx, { offerId, ids }) {
      const chunks = splitArrayIntoChunks(ids, 100);

      await Promise.all(
        chunks.map((ids) => {
          return api.post(
            `offers/${offerId}/objects?${ids
              .map((id) => `objectIds=${id}`)
              .join('&')}`,
          );
        }),
      );
    },
    async deleteOfferPlaces(ctx, { offerId, ids }) {
      const chunks = splitArrayIntoChunks(ids, 100);

      await Promise.all(
        chunks.map((ids) => {
          return api.delete(
            `offers/${offerId}/places?${ids
              .map((id) => `placeIds=${id}`)
              .join('&')}`,
          );
        }),
      );
    },
    async deleteOfferObjects(ctx, { offerId, ids }) {
      const chunks = splitArrayIntoChunks(ids, 100);

      await Promise.all(
        chunks.map((ids) => {
          return api.delete(
            `offers/${offerId}/objects?${ids
              .map((id) => `objectIds=${id}`)
              .join('&')}`,
          );
        }),
      );
    },
    async addPlacesToOffer(ctx, { offerId, ids }) {
      const chunks = splitArrayIntoChunks(ids, 100);

      await Promise.all(
        chunks.map((ids) => {
          return api.post(
            `offers/${offerId}/places?${ids
              .map((id) => `placeIds=${id}`)
              .join('&')}`,
          );
        }),
      );
    },
    async updateResources(
      { dispatch },
      {
        offerId,
        addedPlaceIds,
        removedPlaceIds,
        addedObjectIds,
        removedObjectIds,
      },
    ) {
      const actionMap = {
        addPlacesToOffer: addedPlaceIds,
        deleteOfferPlaces: removedPlaceIds,
        addObjectsToOffer: addedObjectIds,
        deleteOfferObjects: removedObjectIds,
      };

      await Promise.all(
        Object.entries(actionMap)
          .filter(([, value]) => value && value.length > 0)
          .map(([key, value]) => dispatch(key, { offerId, ids: value })),
      );
    },
    updateSelection({ commit }, { item, selected, config }) {
      const { selectedItems, removedItems, removeMutation, selectMutation } =
        config;

      const isRemoved = getPropertyArray(removedItems, 'id').includes(item.id);
      const isAdded = getPropertyArray(selectedItems, 'id').includes(item.id);

      if (selected) {
        if (!isAdded && !isRemoved)
          commit(selectMutation, [...selectedItems, item]);
        else if (isRemoved) {
          commit(
            removeMutation,
            removedItems.filter(({ id }) => id !== item.id),
          );
        }
      } else {
        if (isAdded)
          commit(
            selectMutation,
            selectedItems.filter(({ id }) => id !== item.id),
          );
        else {
          commit(removeMutation, [...removedItems, item]);
        }
      }
    },
    updatePlaceSelection({ state, dispatch }, payload) {
      dispatch('updateSelection', {
        ...payload,
        config: {
          selectedItems: state.placesToSelect,
          removedItems: state.placesToRemove,
          removeMutation: 'SET_REMOVED_PLACES',
          selectMutation: 'SET_SELECTED_PLACES',
        },
      });
    },
    updateObjectSelection({ state, dispatch }, payload) {
      dispatch('updateSelection', {
        ...payload,
        config: {
          selectedItems: state.objectsToSelect,
          removedItems: state.objectsToRemove,
          removeMutation: 'SET_REMOVED_OBJECTS',
          selectMutation: 'SET_SELECTED_OBJECTS',
        },
      });
    },
    updateObjectsAddedInOffer({ getters, commit }, object) {
      const index = getters.offerObjectIds.indexOf(object.id);

      if (index === -1) {
        commit('ADD_OFFER_OBJECT', object);
      } else {
        commit('REPLACE_OFFER_OBJECT', { object, index });
      }
    },
    clearOfferState({ commit }) {
      commit('CLEAR_OFFER_STATE');
    },
    forceUpdateCheckboxes({ commit, state }) {
      commit('SET_UPDATE_COUNT', state.forceCheckboxUpdate + 1);
    },
  },
};
