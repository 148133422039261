import ImageView from '@/components/ImageView/ImageView.vue';
import ModalDisplay from '@/components/Modal/ModalDisplay.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
export default defineComponent({
    components: {
        LoadingSpinner,
        ModalDisplay,
        ImageView,
    },
    props: {
        imageUrl: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            required: true,
        },
        fullHeight: {
            type: Boolean,
            default: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const showModal = ref(false);
        const imgLoaded = ref(false);
        const classNames = computed(() => {
            return { 'h-full': props.fullHeight, 'w-full': props.fullHeight };
        });
        return {
            showModal,
            imgLoaded,
            classNames,
        };
    },
});
