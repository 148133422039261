<template>
  <transition :name="`slide-${side}`">
    <div
      v-if="isOpen"
      class="sidebar-overlay"
      :class="[displayLevel, showOverlay ? 'overlay-background' : 'bg-none']"
      @click.self="$emit('close')"
    >
      <div
        class="overlap-sidebar"
        :style="{ width: `${width}` }"
        :class="[side, displayLevel]"
      >
        <div
          class="overlap-sidebar-inner"
          :class="{ 'overlap-sidebar-padding': !stretched }"
        >
          <slot />
          <button
            type="button"
            class="sidebar-toggle focus:outline-none"
            :class="toggleButton"
            :style="{ left: `${width}` }"
            @click="$emit('close')"
          >
            <icon :icon="mdiClose" :size="IconSize.SM" />
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';

export default {
  components: {
    Icon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      default: 'left',
    },
    width: {
      type: String,
      default: '384px',
    },
    displayLevel: {
      type: String,
      default: 'z-30',
    },
    stretched: Boolean,
    showOverlay: Boolean,
  },
  data() {
    return { mdiClose, IconSize };
  },
  computed: {
    toggleButton() {
      return `sidebar-toggle-${this.side}`;
    },
  },
  methods: {
    closeSidebar() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/_variables.scss';
.sidebar-overlay {
  @apply fixed w-screen h-screen top-0 left-0;
}

.overlay-background {
  background: rgba($primary-dark, 0.4);
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.overlap-sidebar {
  @apply h-full fixed bg-white overflow-y-auto;
  &-padding {
    @apply px-4 pb-10 pt-20;
  }

  &-inner {
    @apply h-full overflow-auto;
    @screen md {
      @apply px-10;
    }
  }
}
.left {
  @apply border-r border-r-gray-light left-0;
}
.right {
  @apply border-l border-l-gray-light right-0;
}
.sidebar-toggle {
  @apply rounded-full fixed top-20 -ml-4 w-8 h-8 shadow-input text-primary bg-white flex justify-center items-center;
}
.sidebar-toggle-left {
  @apply -right-8;
}
.sidebar-toggle-right {
  @apply -left-8;
}

.slide-left-enter {
  transform: translateX(-100%);
}
.slide-right-enter {
  transform: translateX(100%);
}
.slide-left-leave-to {
  transform: translateX(calc(-100% - 20px));
}
.slide-right-leave-to {
  transform: translateX(calc(100% + 20px));
}
</style>
