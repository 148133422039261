import { defineComponent, computed } from '@vue/composition-api';
import { formElementProps } from '@/components/Inputs/_components/FormElement/form-element.constants';
export default defineComponent({
    name: 'FormElement',
    props: {
        ...formElementProps,
    },
    setup(props) {
        const isError = computed(() => props.error || Boolean(props.errorMessage));
        return { isError };
    },
});
