export default {
  namespaced: true,
  state: {
    focusedRowData: null,
  },
  mutations: {
    SET_FOCUSED_ROW(state, { values }) {
      state.focusedRowData = values;
    },
  },
  actions: {
    setCurrentRowData({ commit }, row) {
      commit('SET_FOCUSED_ROW', row);
    },
    clearCurrentRowData({ commit }) {
      commit('SET_FOCUSED_ROW', { values: {} });
    },
  },
};
