import { EMPTY_WYSIWYG_VALUE } from '@/components/WysiwygInput/wysiwyg.constants';
export const isMessageDataEmpty = (data) => {
    const { message, files, places, objects } = data;
    if (files.value.length > 0 ||
        places.value.length > 0 ||
        objects.value.length > 0) {
        return false;
    }
    return message.value === EMPTY_WYSIWYG_VALUE;
};
