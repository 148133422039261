import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
export const useDecisionMaker = (props, ctx) => {
    const getDecisionMakerStatus = (user, decisionMakerIds) => {
        if (user?.isReportDecisionMaker && !props.editOnSave) {
            return !props.removedDecisionMakerIds.includes(user.id);
        }
        return decisionMakerIds.includes(user.id);
    };
    const toggleDecisionMakers = (id, ids, event) => {
        if (ids.includes(id)) {
            ctx.emit(event, ids.filter((item) => item !== id));
        }
        else {
            ctx.emit(event, [...ids, id]);
        }
    };
    const toggleDecisionMakerStatus = (user, decisionMakerIds) => {
        toggleDecisionMakers(user.id, decisionMakerIds, RECIPIENTS_EVENTS.UPDATE_DECISION_MAKERS);
        ctx.emit(user.isReportDecisionMaker
            ? RECIPIENTS_EVENTS.REMOVE_DECISION_MAKER
            : RECIPIENTS_EVENTS.ADD_DECISION_MAKER, user.id);
    };
    return {
        getDecisionMakerStatus,
        toggleDecisionMakerStatus,
    };
};
