var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.show},on:{"update:show":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"pt-3 pb-4 text-header"},[_vm._v(_vm._s(_vm.$t('report.add-offer')))]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"offerFormRef",staticClass:"w-full flex flex-col text-left",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addOffer)},"reset":reset}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{attrs:{"id":"offers","url":"/offers","query":_vm.query,"option-value-name":"id","is-error":errors[0],"placeholder":_vm.$t('report.select-offer'),"autocomplete":"","autofocus":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(selection.internalNumber))]),_vm._v(" - "+_vm._s(selection.title)+" ")]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(option.internalNumber))]),_vm._v(" - "+_vm._s(option.title)+" ")]}}],null,true),model:{value:(_vm.selectedOffer),callback:function ($$v) {_vm.selectedOffer=$$v},expression:"selectedOffer"}})]}}],null,true)}),_c('btn',{staticClass:"add-button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }