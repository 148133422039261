var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasDefinedPosition(_vm.object))?_c('div',[_c('div',{staticClass:"p-5"},[(_vm.showRouteOnMap)?_c('clustered-map',{attrs:{"height":"h-120","route-start":{
        position: { lat: _vm.object.lat, lng: _vm.object.lng },
        customIcon: _vm.object.objectType.iconUrl,
        markerColor: _vm.routeStartMarkerColor,
        directionAngle: _vm.object.directionAngle,
        label: _vm.object.internalNumber,
        labelFormatter: function (label) { return ("#" + label); },
        type: _vm.MapDataType.OBJECT,
      },"route-end":{
        position: {
          lat: _vm.object.place.lat,
          lng: _vm.object.place.lng,
        },
        label: _vm.object.place.number,
        labelFormatter: function (label) { return ("#" + label); },
        type: _vm.MapDataType.PLACE,
      }}}):_c('clustered-map',{attrs:{"height":"h-120","data-sets":[
        {
          name: 'objects',
          url: ("objects/" + (_vm.object.id)),
          type: _vm.MapDataType.OBJECT,
          hidePreviewButton: true,
          iconAccessor: ['objectType', 'iconUrl'],
          colorAccessor: ['objectType', 'color'],
          labelAccessor: ['internalNumber'],
          labelFormatter: function (label) { return ("#" + label); },
        } ]}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }