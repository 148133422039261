export const DEFAULT_STATS = {
    monthlyBudget: 0,
    monthlyExpenses: 0,
    budgetUtilization: 0,
    annualExpenses: 0,
};
export const SUMMARY_COLUMNS = [
    {
        key: 'monthlyBudget',
    },
    {
        key: 'monthlyExpenses',
    },
    {
        key: 'budgetUtilization',
        isPercent: true,
    },
    {
        key: 'annualExpenses',
    },
];
export var OBJECT_TYPE_COLUMN;
(function (OBJECT_TYPE_COLUMN) {
    OBJECT_TYPE_COLUMN["NAME"] = "name";
    OBJECT_TYPE_COLUMN["MONTHLY_BUDGET"] = "monthlyBudget";
    OBJECT_TYPE_COLUMN["MONTHLY_EXPENSES"] = "monthlyExpenses";
    OBJECT_TYPE_COLUMN["BUDGET_UTILIZATION"] = "budgetUtilization";
    OBJECT_TYPE_COLUMN["ANNUAL_EXPENSES"] = "annualExpenses";
})(OBJECT_TYPE_COLUMN || (OBJECT_TYPE_COLUMN = {}));
export const OBJECT_TYPES_COLUMNS = [
    {
        key: OBJECT_TYPE_COLUMN.NAME,
        name: 'name',
        headerClass: 'w-1/3',
    },
    {
        key: OBJECT_TYPE_COLUMN.MONTHLY_BUDGET,
        name: 'monthly-budget',
    },
    {
        key: OBJECT_TYPE_COLUMN.MONTHLY_EXPENSES,
        name: 'monthly-expenses',
    },
    {
        key: OBJECT_TYPE_COLUMN.BUDGET_UTILIZATION,
        name: 'used',
        isPercent: true,
    },
    {
        key: OBJECT_TYPE_COLUMN.ANNUAL_EXPENSES,
        name: 'annual-expenses',
    },
];
