<template>
  <div>
    <btn
      :is-loading="userSubmissionInProgress"
      type="submit"
      form="brandUserForm"
      class="w-48 mt-4 mr-3"
    >
      {{ $t('common.save') }}
    </btn>
    <user-form
      id="brandUserForm"
      ref="userForm"
      :key="clearCounter"
      class="mb-64"
      :enforced-role="PLACE_ADMIN"
      @submit="handleUserSubmission"
    />
    <yes-no-modal
      :show="showConfirmationModal"
      :content="modalContent"
      @update:show="showConfirmationModal = $event"
    />
  </div>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import UserForm from '@/views/Brand/Users/Forms/UserForm.vue';
import { mapActions, mapState } from 'vuex';
import isEqual from 'lodash.isequal';
import { YesNoModal } from '@/components/Modal';
import { createClientUser } from '@/api/requests/users';
import { PLACE_ADMIN } from '@/store/modules/user';

export default {
  name: 'AddUserTab',
  components: {
    YesNoModal,
    Btn,
    UserForm,
  },
  data() {
    return {
      referenceUserForm: {},
      showConfirmationModal: false,
      userSubmissionInProgress: false,
      userSubmissionEndSuccessfully: false,
      clearCounter: 0,
      PLACE_ADMIN,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
    modalContent() {
      return {
        title: this.$t('add-place-view.modal-title'),
        description: this.$t('add-place-view.modal-description'),
        btns: {
          yes: () => this.$emit('close-user-tab'),
          no: () => {
            this.showConfirmationModal = false;
          },
        },
      };
    },
  },
  mounted() {
    this.referenceUserForm = this.getUserForm();
  },
  methods: {
    ...mapActions('brand', ['addBrandUser']),
    getUserForm() {
      const { formData } = this.$refs.userForm;

      return formData;
    },
    isUserFormFilled() {
      const form = this.getUserForm();

      return !isEqual(form, this.referenceUserForm);
    },
    closeUserForm() {
      if (this.isUserFormFilled()) {
        this.showConfirmationModal = true;
      } else {
        this.$emit('close-user-tab');
      }
    },
    async handleUserSubmission(formData) {
      this.userSubmissionInProgress = true;
      this.userSubmissionEndSuccessfully = false;
      try {
        const createdUser = await createClientUser({
          ...formData,
          brandId: this.brand.id,
        });

        this.$emit('add-user', createdUser);

        this.$toast.success(this.$t('add-brand-user-view.added-successfully'));

        this.$emit('close-user-tab');

        this.userSubmissionEndSuccessfully = true;
        this.clearCounter += 1;
      } finally {
        this.userSubmissionInProgress = false;
      }
    },
  },
};
</script>
