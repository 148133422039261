import { defineComponent } from '@vue/composition-api';
import { Btn, BtnTheme } from '@/components/Button';
export default defineComponent({
    components: { Btn },
    props: {
        disableSaveBtn: Boolean,
        size: {
            type: String,
            default: 'xs',
        },
    },
    setup() {
        return { BtnTheme };
    },
});
