var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.show,"is-full-screen":""},on:{"update:show":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('add-task.add-user')))]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"userFormRef",staticClass:"w-full flex flex-col",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addUser)},"reset":reset}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{attrs:{"id":"users-autocomplete","url":"users","query":_vm.usersQuery,"prepend-icon":_vm.mdiMagnify,"autocomplete":"","disable-item":_vm.isUserSelected,"error-message":errors[0],"placeholder":_vm.$t('add-task.user-placeholder'),"option-value-name":"id","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [_vm._v(" "+_vm._s(_vm.getFullName(selection))+" ")]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('user-option',{attrs:{"user":option,"is-user-selected":_vm.isUserSelected}})]}}],null,true),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})]}}],null,true)}),_c('btn',{staticClass:"add-button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }