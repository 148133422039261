<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      ref="structureForm"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <div class="pb-4 mt-4 flex justify-end">
        <btn class="w-30" type="submit">{{ btnText }}</btn>
      </div>
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="structureName"
          :label="$t('structures-view.name')"
          :placeholder="$t('structures-view.name-input-placeholder')"
          :error-message="errors[0]"
          class="mb-5"
          required
        />
      </ValidationProvider>
      <select-input
        v-if="!root && brand"
        id="coordinator"
        v-model="coordinator"
        url="users"
        :query="{
          brandId: brand.id,
        }"
        option-value-name="id"
        :label="$t('structures-view.coordinators')"
        class="my-4"
        clearable
        autocomplete
      >
        <template #selection="{ selection }">
          <span>{{ selection.firstName }} {{ selection.lastName }}</span>
        </template>

        <template #option="{ option }">
          <span>{{ option.firstName }} {{ option.lastName }}</span>
        </template>
      </select-input>
    </form>
  </ValidationObserver>
</template>
<script>
import Btn from '@/components/Button/Button.vue';
import { TextInput, SelectInput } from '@/components/Inputs';
import isEmpty from 'lodash.isempty';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';

export default {
  components: {
    Btn,
    TextInput,
    SelectInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        user: {},
        name: '',
      }),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coordinator: this.data.user,
      structureName: this.data.name,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      structures: 'structures',
      error: 'error',
    }),
    btnText() {
      const action = this.editMode ? 'save' : 'add';

      return this.$t(`common.${action}`);
    },
  },
  watch: {
    data(value) {
      this.coordinator = !isEmpty(value.user) ? value.user : null;
      this.structureName = value.name;
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  methods: {
    onSubmit() {
      this.$emit(
        'submit',
        {
          ...(this.coordinator ? { userId: this.coordinator.id } : {}),
          name: this.structureName,
        },
        this.data,
      );

      this.$refs.structureForm.reset();
    },
  },
};
</script>
