import { computed, defineComponent, ref } from '@vue/composition-api';
import Pagination from '@/components/Pagination/Pagination.vue';
import EntriesCounter from '@/components/Table/EntriesCounter.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import Card from '@/components/Card/Card.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { useBrand, useCardPagination, useRouteQuery } from '@/composables';
import { FILTER_DEBOUNCE } from '@/constants/filters';
import isEmpty from 'lodash.isempty';
export default defineComponent({
    components: { Pagination, EntriesCounter, FetchData, IconSpinner, Card },
    setup(_, ctx) {
        const { brand } = useBrand();
        const { changePage } = useCardPagination(ctx);
        const { routeQuery } = useRouteQuery(ctx);
        const lastPage = ref(0);
        const updateList = (data) => {
            lastPage.value = data.pagination.lastPage;
        };
        const computedQuery = computed(() => {
            return {
                ...routeQuery.value,
                brandId: brand.value?.id,
            };
        });
        return {
            computedQuery,
            FILTER_DEBOUNCE,
            changePage,
            lastPage,
            updateList,
            isEmpty,
        };
    },
});
