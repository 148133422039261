import Btn from '@/components/Btn/Btn.vue';
import { BtnSize, BtnTheme } from '@/components/Btn';
import Modal from '@/components/Modal/__new__/Modal.vue';
import { defineComponent, ref } from '@vue/composition-api';
import AddTask from '@/views/Brand/Applications/Report/AddTask.vue';
import { useObjectResourcesStore } from '@/store/object-resources';
export default defineComponent({
    components: { AddTask, Modal, Btn },
    props: {
        object: {
            type: Object,
            required: true,
        },
        theme: {
            type: String,
            default: BtnTheme.PRIMARY,
        },
    },
    setup(props) {
        const showReportModal = ref(false);
        const { onObjectSelection } = useObjectResourcesStore();
        const { flushPendingObjects } = useObjectResourcesStore();
        const handleOpenReportModal = () => {
            showReportModal.value = true;
            setTimeout(() => {
                onObjectSelection([props.object.id], [props.object]);
                flushPendingObjects();
            }, 300);
        };
        return { BtnTheme, showReportModal, handleOpenReportModal, BtnSize };
    },
});
