import { defineComponent, onBeforeMount, onBeforeUnmount, ref, watch, } from '@vue/composition-api';
import { WysiwygInput, WysiwygInputHeight } from '@/components/WysiwygInput';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { extend, ValidationProvider } from 'vee-validate';
import { useMessageFiles } from '@/views/Brand/Applications/Report/_composables/use-message-files';
import { useAuthUser, useBrand, useMobileView } from '@/composables';
import { required } from 'vee-validate/dist/rules';
import OverflowWrapper from '@/components/OverflowWrapper/OverflowWrapper.vue';
import FilePreview from '@/views/Brand/Applications/Components/Preview/FilePreview/FilePreview.vue';
import { isImageFile } from '@/util/files';
export default defineComponent({
    components: {
        FilePreview,
        WysiwygInput,
        ValidationProvider,
        OverflowWrapper,
    },
    props: {
        value: String,
        report: {
            type: Object,
        },
        message: {
            type: Object,
        },
    },
    setup(props, ctx) {
        const { $i18n } = ctx.root;
        const { brand } = useBrand();
        const { authUserId } = useAuthUser();
        const { isMobileView } = useMobileView();
        const rawText = ref('');
        const getMentionSuggestions = createMentionUserFn({
            ...(props.report && { relatedReportId: props.report.id }),
            accessToBrandId: brand.value.id,
            omitUserIds: [props.report?.creator.id || authUserId.value],
        });
        const messageRef = ref(props.message);
        watch(() => props.message, (value) => {
            if (value)
                messageRef.value = value;
        });
        const { files, uploadingFiles, handleAddFile, handleRemoveFile, getFileTokens, clearFiles, } = useMessageFiles(ctx, messageRef.value ? messageRef : undefined);
        onBeforeMount(() => {
            extend('requiredDescription', {
                ...required,
                message: $i18n.t('errors.error-empty').toString(),
                validate: () => !!rawText.value,
            });
        });
        watch(files, async (value) => {
            if (value) {
                ctx.emit('upload-in-progress', true);
                const tokens = await getFileTokens();
                ctx.emit('update-files', tokens);
                ctx.emit('upload-in-progress', false);
            }
        }, { deep: true, immediate: true });
        onBeforeUnmount(() => {
            clearFiles();
        });
        return {
            rawText,
            getMentionSuggestions,
            files,
            uploadingFiles,
            handleAddFile,
            handleRemoveFile,
            WysiwygInputHeight,
            isImageFile,
            isMobileView,
        };
    },
});
