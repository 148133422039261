<template>
  <div>
    <slot
      :filtersApplied="filtersApplied($route)"
      :resetFilters="resetFilters"
    ></slot>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import {
  DEFAULT_QUERY_FORMATTERS,
  DEFAULT_ROUTE_FILTERS,
  DEFAULT_VALUE_GETTERS,
} from '@/constants/filters';
import { getParamsFromLocation } from '@/util/routing';
import { getAppliedFilters } from '@/util/filters';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    clearOnReload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlParams: null,
    };
  },
  computed: {
    ...mapState('routes', ['filters']),
    ...mapGetters('routes', [
      'routeFilters',
      'filtersApplied',
      'initialRouteFilters',
    ]),
    userFiltersApplied() {
      return this.filtersApplied(this.$route);
    },
    filterConfig() {
      return {
        queryFormatters: {
          ...DEFAULT_QUERY_FORMATTERS,
          ...this.config.queryFormatters,
        },
        valueGetters: {
          ...DEFAULT_VALUE_GETTERS,
          ...this.config.valueGetters,
        },
      };
    },
  },
  async created() {
    if (this.filtersApplied(this.$route) && !this.clearOnReload) {
      this.$emit('input', this.routeFilters(this.$route));
    } else {
      this.urlParams = getParamsFromLocation() || {};

      const routeQueries = Object.entries(this.$route.query).map(
        ([key, value]) => {
          return [key, Array.isArray(value) ? value.join(', ') : value];
        },
      );

      const initialQuery = {
        ...getAppliedFilters(
          this.value,
          DEFAULT_ROUTE_FILTERS[this.$route.name],
        ),
        ...this.$route.query,
      };

      await Promise.all(
        Object.entries(this.urlParams)
          .concat(routeQueries)
          .map(async ([key, value]) => {
            if ({}.hasOwnProperty.call(this.value, key)) {
              initialQuery[key] = await this.getValueFromQuery(key, value);
            }
          }),
      );

      this.setInitialRouteFilters({
        route: this.$route,
        query: getAppliedFilters(
          this.value,
          DEFAULT_ROUTE_FILTERS[this.$route.name],
        ),
      });

      this.setRouteFilters({
        route: this.$route,
        query: initialQuery,
      });

      this.$emit('input', cloneDeep(initialQuery));
    }
  },
  mounted() {
    this.setRouteConfig({ route: this.$route, config: this.filterConfig });
  },
  methods: {
    ...mapActions('routes', [
      'setRouteFilters',
      'resetRouteFilters',
      'setInitialRouteFilters',
      'setRouteConfig',
      'setLoading',
      'setRoutePagination',
    ]),
    resetFilters() {
      this.resetRouteFilters(this.$route);

      this.$emit('input', cloneDeep(this.initialRouteFilters(this.$route)));
    },
    getValueFromQuery(key, value) {
      const formatter = this.filterConfig.valueGetters[key];

      return formatter ? formatter(value) : value;
    },
  },
};
</script>
