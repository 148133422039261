import { defineComponent, computed, } from '@vue/composition-api/dist/vue-composition-api';
import FilePreview from '@/views/Brand/Applications/Components/Preview/FilePreview/FilePreview.vue';
import { PlacePreview } from '@/views/Brand/Applications/Components/Preview/PlacePreview';
import { ObjectPreview } from '@/views/Brand/Applications/Components/Preview/ObjectPreview';
import OfferPreview from '@/views/Brand/Applications/Components/Preview/OfferPreview/OfferPreview.vue';
import { isImageFile } from '@/util/files';
import ImageModal from '@/components/Modal/ImageModal.vue';
import DownloadButton from '@/views/Brand/Applications/Components/Preview/DownloadButton/DownloadButton.vue';
import { ImageView, ImageSize } from '@/components/ImageView';
export default defineComponent({
    name: 'MessageResources',
    components: {
        ImageView,
        DownloadButton,
        ImageModal,
        FilePreview,
        PlacePreview,
        ObjectPreview,
        OfferPreview,
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const imagesOnly = computed(() => {
            if (props.message.places.length > 0 ||
                props.message.objects.length > 0 ||
                props.message.offer) {
                return false;
            }
            return props.message.files.every((file) => isImageFile(file.fileName));
        });
        return { imagesOnly, ImageSize };
    },
});
