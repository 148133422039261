export var IconSize;
(function (IconSize) {
    IconSize["XXS"] = "xxs";
    IconSize["XS"] = "xs";
    IconSize["SM"] = "sm";
    IconSize["MD"] = "md";
    IconSize["LG"] = "lg";
    IconSize["XL"] = "xl";
    IconSize["2XL"] = "2xl";
    IconSize["3XL"] = "3xl";
    IconSize["4XL"] = "4xl";
    IconSize["5XL"] = "5xl";
})(IconSize || (IconSize = {}));
export const ICON_SIZE_MAP = {
    [IconSize.XXS]: '0.75rem',
    [IconSize.XS]: '1rem',
    [IconSize.SM]: '1.25rem',
    [IconSize.MD]: '1.5rem',
    [IconSize.LG]: '1.75rem',
    [IconSize.XL]: '2rem',
    [IconSize['2XL']]: '2.25rem',
    [IconSize['3XL']]: '2.5rem',
    [IconSize['4XL']]: '2.75rem',
    [IconSize['5XL']]: '3rem',
};
