var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',{staticClass:"h-full",attrs:{"query":_vm.routeQuery},on:{"select-limit":_vm.selectLimit}},[_c('portal',{attrs:{"to":_vm.PortalTarget.SIDEBAR}},[_c('places-sidebar',{class:{ hidden: _vm.activeTab === _vm.PlacesTabName.BUDGETS }})],1),_c('div',[_c('div',{staticClass:"flex gap-2 items-center justify-end"},[_c('div',{staticClass:"flex md:items-center justify-end flex-col-reverse md:flex-row gap-4 md:gap-8"},[_c('div',{staticClass:"flex gap-4 justify-end"},[(_vm.isObjektoAdmin)?_c('popover',{attrs:{"offset":_vm.isMobileView ? '-100,10' : '0, 10'},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var attrs = ref.attrs;
var togglePopoverVisibility = ref.togglePopoverVisibility;
return [_c('btn2',_vm._b({attrs:{"size":"sm"},on:{"click":function($event){return togglePopoverVisibility()}}},'btn2',attrs,false),[_c('icon',{staticClass:"text-gray-darkest",attrs:{"icon":_vm.mdiTrayArrowDown}})],1)]}}],null,false,2750687250)},[_c('showing-menu',{attrs:{"items":_vm.exportMenuItems}})],1):_vm._e(),_c('filter-toggler')],1),_c('div',{staticClass:"flex gap-4"},[_c('btn',{attrs:{"theme":"secondary","disabled":_vm.selectedPlaces.length === 0 || _vm.isContractor},on:{"click":_vm.createReport}},[_vm._v(" "+_vm._s(_vm.$t('brand-places-list-view.create-report'))+" ")]),(_vm.structureAdminOrHigherAccess)?_c('btn',{staticClass:"w-26",on:{"click":function($event){return _vm.$router.push({ name: 'AddPlace' })}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")]):_vm._e()],1)])]),_c('slot-tabs',{staticClass:"mt-10 md:mt-0",attrs:{"tabs":_vm.tabs,"compressed-header":"","active-tab":_vm.activeTab},on:{"select-tab":function($event){_vm.activeTab = $event.name}},scopedSlots:_vm._u([{key:"budgets",fn:function(){return [_c('budgets')]},proxy:true},{key:"list",fn:function(){return [_c('places-table',{model:{value:(_vm.selectedPlaces),callback:function ($$v) {_vm.selectedPlaces=$$v},expression:"selectedPlaces"}})]},proxy:true},{key:"map",fn:function(){return [(_vm.brand && _vm.brand.id)?_c('clustered-map',{attrs:{"fit-bounds":"","data-sets":[
            {
              name: 'places',
              url: 'places',
              query: _vm.mapQuery,
              type: _vm.MapDataType.PLACE,
              fetchAll: true,
              labelAccessor: ['number'],
              labelFormatter: function (label) { return ("#" + label); },
            } ]}}):_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }