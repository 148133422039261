import { defineComponent } from '@vue/composition-api';
import { LabelPosition, SwitchSize, } from './switch-input.constants';
import { Icon } from '@/components/Icon';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { mdiInformationOutline } from '@mdi/js';
export default defineComponent({
    name: 'SwitchInput',
    components: { Tooltip, Icon },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        labelPosition: {
            type: String,
            default: LabelPosition.LEFT,
        },
        theme: {
            type: String,
        },
        size: {
            type: String,
            default: SwitchSize.SMALL,
        },
        tooltipContent: {
            type: String,
            default: '',
        },
    },
    setup() {
        return { LabelPosition, mdiInformationOutline };
    },
});
