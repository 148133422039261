<template>
  <div>
    <slot :run-with-modal="runWithModal" />
    <portal v-if="modalShow" to="modals">
      <component
        :is="componentName"
        :content="modalContent"
        :show.sync="modalShow"
      >
        <slot name="modalContent" />
      </component>
    </portal>
  </div>
</template>

<script>
import { capitalize } from '@/util/formatters';
import * as components from '@/components/Modal/index';

export default {
  components: { ...components },
  props: {
    activate: {
      type: Object,
      default: null,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalContent: {},
      modalShow: false,
    };
  },
  computed: {
    componentName() {
      return `${this.getModalType()}Modal`;
    },
  },
  watch: {
    activate(modalObject) {
      if (modalObject) this.runWithModal(null, modalObject);
      else this.modalShow = false;
    },
    content(value) {
      this.modalContent = {
        ...this.modalContent,
        ...value,
      };
    },
  },
  methods: {
    capitalize,
    getModalType() {
      const type = this.modalContent?.type;
      return type ? this.capitalize(type) : '';
    },
    runWithModal(callback, content) {
      this.modalShow = true;
      this.modalContent = {
        ...content,
        handler: () => {
          if (callback) callback();
          this.modalShow = false;
        },
      };
    },
  },
};
</script>
