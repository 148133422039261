import { Icon } from '@/components/Icon';
import { computed, defineComponent } from '@vue/composition-api';
import { useClient, useBrand, useAuthUser, useSidebar } from '@/composables';
import { mdiChevronLeft } from '@mdi/js';
export default defineComponent({
    name: 'BrandLogo',
    components: { Icon },
    props: {
        backIcon: Boolean,
        backRoute: Object,
        lightBackground: Boolean,
    },
    setup(_, ctx) {
        const { brand } = useBrand();
        const { client } = useClient();
        const { isContractor, isObjektoAdmin } = useAuthUser();
        const { showSidebar } = useSidebar(ctx);
        const clientRoute = computed(() => {
            return client.value
                ? { name: 'ClientDetails', params: { id: client.value.id } }
                : { name: 'Clients' };
        });
        const allowClientPreview = computed(() => {
            return isObjektoAdmin.value || isContractor.value;
        });
        return {
            mdiChevronLeft,
            brand,
            clientRoute,
            allowClientPreview,
            showSidebar,
        };
    },
});
