<template>
  <div class="menu" :class="menuClass">
    <slot name="prepend" />
    <modal-wrapper
      v-for="(menuItem, index) in items"
      :key="index"
      v-slot="{ runWithModal }"
      :content="menuItem.modal || {}"
      class="menu-inner"
    >
      <button
        class="menu-item"
        :disabled="menuItem.disabled"
        @touchstart.stop
        @mousedown.stop
        @click.prevent="onClick(menuItem, runWithModal)"
      >
        {{ menuItem.name }}
      </button>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';

export default {
  name: 'ShowingMenu',
  components: {
    ModalWrapper,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
    menuClass: {
      type: String,
      default: 'min-w-28',
    },
  },
  methods: {
    onClick(item, runWithModal) {
      item.modal ? runWithModal(item.handler, item.modal) : item.handler();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  @apply rounded box-border shadow-ellipsis py-2 z-100;
  background-color: theme('colors.secondary');

  &-inner {
    @apply flex flex-col;
  }
}

.menu-item {
  @apply px-3 py-1.5 items-center justify-start;
  text-align: left;
  cursor: pointer;

  &:disabled {
    @apply cursor-not-allowed text-gray-dark;
  }

  &:hover:enabled {
    @apply bg-gray-lighter;
  }

  &:focus {
    outline: none;
  }
}
</style>
