import api from '@/api';
export var ObjectStatus;
(function (ObjectStatus) {
    ObjectStatus["ACTIVE"] = "active";
    ObjectStatus["INACTIVE"] = "inactive";
    ObjectStatus["IN_OFFER"] = "in-offer";
})(ObjectStatus || (ObjectStatus = {}));
export const OBJECTS_API_PATH = 'objects';
export const updateObject = async (objectId, payload) => {
    await api.patch(`objects/${objectId}`, payload);
};
export const getObjectsList = async (params) => {
    const { data } = await api.get('objects', { params });
    return data;
};
export const getSingleObject = async (objectId, params) => {
    const { data } = await api.get(`objects/${objectId}`, { params });
    return data;
};
export const getObjectsGroupList = async (params) => {
    const { data } = await api.get('objects/groups/all', {
        params,
    });
    return data;
};
export const getObjectGroup = async (groupId) => {
    const { data } = await api.get(`objects/groups/${groupId}`);
    return data;
};
export const createObjectsGroup = async (payload) => {
    const { data } = await api.post('objects/groups', payload);
    return data;
};
export const addObjectsToGroup = async (groupId, payload) => {
    const { data } = await api.post(`objects/groups/${groupId}`, payload);
    return data;
};
export const updateObjectsGroup = async (groupId, payload) => {
    const { data } = await api.patch(`objects/groups/${groupId}`, payload);
    return data;
};
export const removeObjectsFromGroup = async (groupId, payload) => {
    await api.delete(`objects/groups/${groupId}/objects`, {
        data: payload,
    });
};
export const updateObjectImportant = async (objectId, payload) => {
    await api.patch(`objects/${objectId}/important`, payload);
};
