<template>
  <expandable-row-preview
    :id="placeId"
    :url="`places/${placeId}`"
    :force-update="forceUpdateKey"
    :sidebar="sidebar"
    :hide-details-btn="hideDetailsBtn"
    :get-title="(data) => data.name"
    @data-deliver="tags = $event.tags"
    @open-details="$router.push({ name: 'PlaceDetails', params: { placeId } })"
  >
    <template #details="{ data }">
      <div class="md:flex flex-wrap">
        <ul class="md:pr-6 lg:pr-10">
          <li class="info">
            <label>{{ $t(`place-preview.internal-number`) }}:</label>
            <p>{{ data.number }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.address`) }}:</label>
            <p>{{ data.address }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.postal-code`) }}:</label>
            <p>{{ data.postalCode || '-' }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.city`) }}:</label>
            <p>{{ data.city }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.coordinates`) }}:</label>
            <p>{{ convertDMS(data.lat, data.lng) }}</p>
          </li>
        </ul>
        <ul class="md:pr-6 lg:pr-10">
          <li class="info">
            <label>{{ $t(`place-preview.structure`) }}:</label>
            <p>{{ data.structure ? data.structure.name : '-' }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.place-manager`) }}:</label>
            <p>{{ getPlaceManager(data.user) }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.concept`) }}:</label>
            <p>{{ data.concept || '-' }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.type`) }}:</label>
            <p>{{ data.type || '-' }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`place-preview.size`) }}:</label>
            <p>{{ data.size || '-' }}</p>
          </li>
        </ul>
      </div>
    </template>
    <div v-if="!sidebar" class="flex w-1/2">
      <select-input
        id="place-tags"
        v-model="tags"
        :disable-item="isOptionSelected"
        :loading="loadingTags"
        :query="{ brandId, relation: 'place' }"
        url="tags"
        option-label-name="name"
        option-value-name="id"
        :placeholder="$t('place-preview.tags-placeholder')"
        class="mt-4"
        autocomplete
        multiple
        clearable
        @input="handleTagsUpdate"
        @enter="handleEnter"
      />
    </div>
  </expandable-row-preview>
</template>
<script>
import { convertDMS } from '@/util/formatters';
import { mapActions, mapState } from 'vuex';
import manageTagsMixin from '@/mixins/manageTagsMixin';
import ExpandableRowPreview from '@/components/Table/ExpandableRowPreview';
import { SelectInput } from '@/components/Inputs';
import difference from 'lodash.difference';

export default {
  components: {
    ExpandableRowPreview,
    SelectInput,
  },
  props: {
    placeId: {
      type: String,
      default: '',
    },
    hideDetailsBtn: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [manageTagsMixin],
  data() {
    return {
      forceUpdateKey: 0,
    };
  },
  computed: {
    ...mapState('user', {
      userBrand: 'brand',
    }),
    brandId() {
      return this.$route.params.id || this.userBrand;
    },
  },
  methods: {
    ...mapActions('tags', ['addPlaceTag', 'deletePlaceTag']),
    convertDMS,
    getPlaceManager(user) {
      return user ? `${user.firstName} ${user.lastName}` : '-';
    },
    isOptionSelected(option) {
      return option && this.loadingTags;
    },
    onAddTag(tag, updateTag) {
      this.saveTag(
        tag,
        (tagName) => this.addPlaceTag({ placeId: this.placeId, tagName }),
        updateTag,
      );
    },
    onDeleteTag(tag) {
      this.removeTag(tag, (tagId) =>
        this.deletePlaceTag({ placeId: this.placeId, tagId }),
      );
    },
    handleTagsUpdate(event) {
      const newTags = difference(event, this.allTags);
      const removedTags = difference(this.allTags, event);
      newTags.forEach((tag) => {
        this.onAddTag(tag, false);
      });
      removedTags.forEach((tag) => {
        this.onDeleteTag(tag);
      });
    },
    handleEnter(value) {
      this.createNewTag(value, () => {
        this.onAddTag({ name: value.enteredValue }, true);
      });
    },
  },
  watch: {
    tags: {
      immediate: true,
      handler(newTags) {
        if (newTags) {
          this.allTags = newTags;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  @apply w-full text-sm my-1;

  @screen sm {
    @apply flex;
  }
  label {
    @apply text-gray-dark font-normal w-32 pr-2 break-words flex-shrink-0;
  }
  p {
    @apply font-semibold;
  }
}
</style>
