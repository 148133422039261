var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('infinite-data',{staticClass:"relative",attrs:{"url":"objects","query":_vm.objectQuery,"container-selector":_vm.containerSelector,"enable-query-watcher":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [(items && items.length)?_c('table-display',{attrs:{"rows":items,"columns":_vm.columns,"is-row-expanded":_vm.isObjectDetailsExpanded,"table-style":_vm.TableStyle.Resources,"row-size":_vm.getObjectRowSize()},on:{"record-click":function($event){return _vm.$emit('open', $event.id)}},scopedSlots:_vm._u([{key:"checkboxCell",fn:function(ref){
var row = ref.row;
return [_c('checkbox',{attrs:{"table":"","value":_vm.isObjectSelected(row)},on:{"input":function($event){return _vm.onSelectObject(row)}}})]}},{key:"placeCell",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.name)+" ")]}},{key:"internalStatusCell",fn:function(ref){
var value = ref.value;
return [_c('status-toggler',{attrs:{"compact":"","colors":_vm.OBJECT_STATUS_COLORS,"options":[],"current-status":value,"status-translation":"object-status","disabled":""}})]}},{key:"expandButtonCell",fn:function(ref){
var row = ref.row;
return [_c('expand-button',{attrs:{"is-expanded":_vm.isObjectDetailsExpanded(row),"button-style":_vm.ExpandButtonStyle.ARROW},on:{"expand":function($event){return _vm.toggleExpandObjectDetails(row)}}})]}},{key:"expandedRow",fn:function(ref){
var row = ref.row;
return [_c('object-preview',{attrs:{"object-id":row ? row.id : '',"hide-details-btn":"","sidebar":""}})]}}],null,true)}):_vm._e()]}},{key:"no-results",fn:function(){return [_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('common.no-results'))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }