var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-between items-end"},[_c('form',{staticClass:"w-62"},[_c('text-input',{staticClass:"mt-6",attrs:{"placeholder":_vm.$t('common.search'),"prepend-icon":_vm.mdiMagnify,"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('btn',{staticClass:"w-30",attrs:{"type":"submit"},nativeOn:{"click":function($event){return _vm.onAssignForms.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1),(_vm.brand)?_c('table-list',{key:_vm.componentKey,staticClass:"hover-click justify-start",attrs:{"content":("brands/" + (_vm.brand.id) + "/forms"),"checkbox":"","single-select":"","query":_vm.query,"per-page":10,"cols":{
      createdAt: {
        label: _vm.$t('categories-view.createdAt'),
        formatter: _vm.date,
      },
      title: _vm.$t('categories-view.title'),
    },"actions":{
      edit: _vm.onEditBtnClick,
      duplicate: _vm.onDuplicateBtnClick,
    },"selected-ids":_vm.selectedFormIds,"ellipsis-only":""},on:{"select":function($event){_vm.formsToAdd = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }