import { BudgetPlaceCard } from '@/views/Brand/Places/_components/budget-tree-node/_components/budget-place-card';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'ExpandedBudgetStructure',
    components: { LoadingSpinner, BudgetPlaceCard },
    props: {
        isLoading: {
            type: Boolean,
            required: true,
        },
        budgetStructure: {
            type: Object,
            required: true,
        },
        childBudgetStructures: {
            type: Array,
            required: true,
        },
        objectType: Object,
        editLevel: Number,
    },
});
