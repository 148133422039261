import Modal from '@/components/Modal/__new__/Modal.vue';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';
import { defineComponent } from '@vue/composition-api';
import ObjectWithPopover from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/ObjectWithPopover.vue';
export default defineComponent({
    components: { ObjectWithPopover, InfiniteData, Modal },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            required: true,
        },
        reportId: {
            type: String,
            required: true,
        },
    },
});
