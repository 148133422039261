var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.action && _vm.status !== _vm.OfferObjectStatus.REVERTED)?_c('status-btn',{attrs:{"can-user-revert-action":_vm.canUserRevertAction,"loading":_vm.loading,"status":_vm.status,"user":_vm.user,"date":_vm.formattedDate},on:{"revert":function($event){_vm.runActionWithModal(
        _vm.$t('object-details-in-offer-view.confirm-object-revert'),
        _vm.revert
      )}}}):(_vm.canUserTakeAction)?_c('decision-btns',{on:{"accept":function($event){_vm.runActionWithModal(
        _vm.$t('object-details-in-offer-view.confirm-object-accept'),
        _vm.accept
      )},"reject":function($event){_vm.runActionWithModal(
        _vm.$t('object-details-in-offer-view.confirm-object-reject'),
        _vm.reject
      )}}}):_c('span',{staticClass:"no-action"},[_vm._v(" "+_vm._s(_vm.$t("object-details-in-offer-view.no-action"))+" ")]),(_vm.showConfirmModal && _vm.modalContent)?_c('portal',{attrs:{"to":"modals"}},[_c('confirm-modal',{attrs:{"show":_vm.showConfirmModal,"title":_vm.modalContent.title},on:{"confirm":_vm.modalContent.action,"close":_vm.onCloseModal}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }