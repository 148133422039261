<template>
  <router-link :to="to === '#' ? to : { name: to }" :class="classNames">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    activeSub: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Boolean,
      default: false,
    },
    subnav: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
  },
  computed: {
    classNames() {
      return {
        active: this.active,
        category: this.category,
        'active-sub': this.activeSub,
        'sub-item': this.subnav,
        'nav-item': !this.subnav,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.nav-item,
.sub-item {
  @apply mx-2.5 px-1.5 flex items-center self-stretch relative h-12 my-1 bg-transparent transition-colors duration-150;

  @screen md {
    @apply h-full my-0;
  }
}

.nav-item {
  &:hover {
    &::after {
      @apply border-2 border-gray-dark rounded-b-lg bg-gray-dark;
      transform: rotate(180deg);
    }
  }

  &.active {
    &::after {
      @apply border-2 border-gray-darkest rounded-b-lg bg-gray-darkest;
      transform: rotate(180deg);
    }
  }

  &:hover,
  &.active {
    &::after {
      @apply absolute bottom-0 right-0 w-0.5 h-full;
      left: -1rem;
      content: '';

      @screen md {
        @apply right-0 w-full h-0.5;
        left: 0;
        content: '';
      }
    }
  }
}

.sub-item {
  @apply pl-8;

  @screen md {
    @apply text-white text-opacity-60 px-4;
  }

  @screen md {
    @apply px-7;
  }

  &:hover,
  &.active-sub {
    @apply bg-primary text-white rounded;

    @screen md {
      @apply bg-transparent text-opacity-100;
    }
  }
}

.category {
  &.active {
    @apply font-semibold rounded;
    background-color: theme('colors.primary.light');

    @screen md {
      @apply font-normal rounded-none;
      background-color: unset;
    }
  }
}
</style>
