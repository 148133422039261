import { defineComponent, ref, computed } from '@vue/composition-api';
import { Modal } from '@/components/Modal';
import { SelectInput } from '@/components/Inputs';
import { Btn, BtnTheme } from '@/components/Button';
import { useBrand } from '@/composables';
import { addObjectsToGroup } from '@/api/requests/objects';
export default defineComponent({
    components: { Modal, Btn, SelectInput },
    props: {
        selectedObjects: {
            type: Array,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, ctx) {
        const selectedGroup = ref(null);
        const addObjectsClicked = ref(false);
        const isError = ref(false);
        const addedObjectsCount = ref(0);
        const { brand } = useBrand();
        const modalTitle = computed(() => {
            if (addObjectsClicked.value) {
                if (isError.value) {
                    return ctx.root.$t('objects-groups.add-error');
                }
                return ctx.root.$t('objects-groups.add-success-modal.title');
            }
            return ctx.root.$t('objects-groups.add-to-group');
        });
        const handleClose = () => {
            ctx.emit('close');
            selectedGroup.value = null;
        };
        const handleSubmit = async () => {
            if (!selectedGroup.value) {
                return handleClose();
            }
            try {
                const data = await addObjectsToGroup(selectedGroup.value.id, {
                    objectIds: props.selectedObjects,
                });
                addedObjectsCount.value = data.addedObjectsCount;
                ctx.emit('submit');
            }
            catch (e) {
                isError.value = true;
            }
            finally {
                addObjectsClicked.value = true;
            }
        };
        return {
            selectedGroup,
            BtnTheme,
            handleClose,
            handleSubmit,
            brand,
            addObjectsClicked,
            modalTitle,
            isError,
            addedObjectsCount,
        };
    },
});
