import { defineComponent, computed } from '@vue/composition-api';
import { IconSize, ICON_SIZE_MAP } from './icon.constants';
export default defineComponent({
    props: {
        icon: {
            type: [String, Object],
            required: true,
        },
        size: {
            type: String,
            default: IconSize.MD,
        },
        border: Boolean,
    },
    setup(props) {
        const isMdiIcon = computed(() => typeof props.icon === 'string');
        const parsedSize = computed(() => ICON_SIZE_MAP[props.size]);
        return { isMdiIcon, parsedSize };
    },
});
