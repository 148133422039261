import { defineComponent } from '@vue/composition-api';
import TabsHeader from '@/components/ResourcesWrapper/_components/Header.vue';
import Filters from '@/components/ResourcesWrapper/_components/Filters.vue';
import { useMobileView } from '@/composables';
export default defineComponent({
    components: { Filters, TabsHeader },
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
        },
        headerClass: {
            type: String,
            default: 'py-3',
        },
        contentClass: {
            type: String,
            default: 'p-4',
        },
        filtersApplied: {
            type: Boolean,
            default: false,
        },
        selectorId: {
            type: String,
            default: '',
        },
        message: String,
        title: String,
        filterDivider: Boolean,
        hideFiltersBtn: Boolean,
        hideHeader: Boolean,
    },
    setup() {
        const { isMobileView } = useMobileView();
        return { isMobileView };
    },
});
