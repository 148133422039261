import { computed, ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables/use-auth-user';
import { ROLE_ACCEPT_MAP } from '@/views/Brand/Objects/_components/Offer/accept-offer.constants';
import { OfferObjectStatus, updateOfferObjectRelation, } from '@/api/requests/offers';
import { dateTime } from '@/util/formatters';
import { getFullName } from '@/util/user';
export const useObjectAccept = (ctx, { relation, level }) => {
    const showConfirmModal = ref(false);
    const loading = ref(false);
    const { hasRole, authUserId, isObjektoAdmin } = useAuthUser();
    const canUserTakeAction = computed(() => {
        const requiredRole = ROLE_ACCEPT_MAP[level];
        return hasRole(requiredRole) || isObjektoAdmin.value;
    });
    const action = computed(() => {
        const levelStatus = `${level}Status`;
        return relation[levelStatus] || null;
    });
    const canUserRevertAction = computed(() => {
        return action.value?.userId === authUserId.value;
    });
    const status = computed(() => {
        return action.value?.status || null;
    });
    const formattedDate = computed(() => {
        return action.value ? dateTime(new Date(action.value?.createdAt)) : '';
    });
    const user = computed(() => {
        return action.value ? getFullName(action.value.user) : '';
    });
    const modalContent = ref(null);
    const runActionWithModal = async (title, action) => {
        showConfirmModal.value = true;
        modalContent.value = {
            title,
            action: async () => {
                loading.value = true;
                try {
                    showConfirmModal.value = false;
                    await action();
                    ctx.emit('update-relation');
                }
                finally {
                    loading.value = false;
                }
            },
        };
    };
    const revert = async () => {
        if (canUserTakeAction.value) {
            await updateOfferObjectRelation(relation.id, level, OfferObjectStatus.REVERTED);
        }
    };
    const reject = async () => {
        await updateOfferObjectRelation(relation.id, level, OfferObjectStatus.REJECTED);
    };
    const accept = async () => {
        await updateOfferObjectRelation(relation.id, level, OfferObjectStatus.ACCEPTED);
    };
    const onCloseModal = () => {
        showConfirmModal.value = false;
        modalContent.value = null;
    };
    return {
        loading,
        action,
        status,
        user,
        showConfirmModal,
        formattedDate,
        onCloseModal,
        accept,
        reject,
        revert,
        canUserTakeAction,
        canUserRevertAction,
        modalContent,
        runActionWithModal,
    };
};
