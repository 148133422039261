import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper.vue';
import { computed, defineComponent, onMounted, ref, } from '@vue/composition-api';
import { useRouteQuery } from '@/composables';
import { ObjectsTable } from '@/views/Brand/Objects/_components/ObjectsTable';
import ObjectsSidebar from '@/views/Brand/Objects/ObjectsSidebar.vue';
import { PortalTarget } from '@/constants/portal';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { getObjectGroup } from '@/api/requests/objects';
import { mdiChevronLeft } from '@mdi/js';
import { Icon } from '@/components/Icon';
import router from '@/router';
import VueStore from '@/store';
export default defineComponent({
    name: 'ObjectsGroup',
    components: {
        ObjectsTable,
        ObjectsSidebar,
        ContentWrapper,
        Icon,
        IconSpinner,
    },
    setup(props, ctx) {
        const { routeQuery, selectLimit } = useRouteQuery(ctx);
        const { objectsGroupId } = ctx.root.$router.currentRoute.params;
        const objectGroup = ref(null);
        const isObjectGroupLoading = ref(true);
        onMounted(async () => {
            objectGroup.value = await getObjectGroup(objectsGroupId);
            isObjectGroupLoading.value = false;
        });
        const query = computed(() => ({
            groupId: objectsGroupId,
            ...routeQuery.value,
        }));
        const handleGoBackClick = () => {
            router.push({ name: 'Objects' });
        };
        return {
            query,
            selectLimit,
            PortalTarget,
            objectsGroupId,
            objectGroup,
            isObjectGroupLoading,
            mdiChevronLeft,
            handleGoBackClick,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(() => {
            VueStore.dispatch('routes/setPreviousRoute', to);
        });
    },
});
