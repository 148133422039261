import { defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import ArrowSvg from '@/assets/icons/arrow-down.svg';
import { Icon, IconSize } from '@/components/Icon';
import { Accordion } from '@/components/Accordion';
import { Checkbox } from '@/components/Inputs';
import { MapStylesPopup } from '@/components/Map/components/map-settings/index';
import { customMapStyles, MAP_TYPE, } from '@/components/Map';
import { MAP_SETTING_EVENT, MapSettings } from '@/components/Map/constants';
import { useMapState } from '@/components/Map/composables/use-map-state';
export default defineComponent({
    components: {
        MapStylesPopup,
        Accordion,
        Checkbox,
        Icon,
    },
    props: {
        map: {
            type: Object,
            required: true,
        },
        mapStyle: {
            type: Object,
            required: true,
        },
        disabledSettings: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, ctx) {
        const showMapStyles = ref(false);
        const mapOption = ref(false);
        const { settings, setSetting } = useMapState();
        const settingsOptions = Object.values(MapSettings);
        const isSettingDisabled = (setting) => {
            return props.disabledSettings.some((disabledSetting) => disabledSetting === setting);
        };
        const setDefaultMapStyle = () => {
            props.map.value?.setMapTypeId(MAP_TYPE.DEFAULT);
            props.map.value?.setOptions({
                styles: customMapStyles[props.mapStyle.value.name],
            });
        };
        const optionChangeHandle = (option) => {
            if (mapOption.value && option.typeId) {
                return props.map?.value?.setMapTypeId(option.typeId);
            }
            return props.map?.value?.setMapTypeId(props.mapStyle?.value.name);
        };
        const settingChangeHandle = (setting, isEnabled) => {
            setSetting({
                setting,
                isEnabled,
            });
            ctx.emit(MAP_SETTING_EVENT.SETTING_CHANGED, {
                [setting]: isEnabled,
            });
        };
        const initializeSettingsValues = () => {
            Array.from(settings.value).map(([setting, isEnabled]) => {
                setSetting({
                    setting,
                    isEnabled,
                });
            });
        };
        onMounted(() => {
            setDefaultMapStyle();
            initializeSettingsValues();
        });
        watch(() => props.mapStyle, () => {
            mapOption.value = false;
        }, { deep: true });
        return {
            settingsOptions,
            mapOption,
            settings,
            MapSettings,
            ArrowSvg,
            IconSize,
            showMapStyles,
            MAP_SETTING_EVENT,
            isSettingDisabled,
            optionChangeHandle,
            settingChangeHandle,
        };
    },
});
