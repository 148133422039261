import { computed, defineComponent } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import { Btn, BtnTheme } from '@/components/Button';
import { ExpandButtonStyle } from '@/components/Table/TableGrid/table.constants';
import { mdiPlus, mdiMinus, mdiChevronUp, mdiChevronDown } from '@mdi/js';
export default defineComponent({
    components: { Icon, Btn },
    props: {
        isExpanded: {
            type: Boolean,
            default: false,
        },
        buttonStyle: {
            type: String,
        },
    },
    setup(props) {
        const iconName = computed(() => {
            if (props.buttonStyle === ExpandButtonStyle.ARROW) {
                return props.isExpanded ? mdiChevronUp : mdiChevronDown;
            }
            return props.isExpanded ? mdiMinus : mdiPlus;
        });
        return { iconName, BtnTheme };
    },
});
