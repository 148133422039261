<template>
  <div
    v-if="show"
    ref="popupOverlay"
    class="popup-overlay"
    :class="{ 'items-center': centered }"
    @click.self="close"
  >
    <loading-spinner
      v-if="!isLoaded"
      :is-loading="!isLoaded"
      class="justify-center w-full flex"
    />
    <div
      v-show="isLoaded"
      :class="[type]"
      :style="{
        height: height || 'auto',
      }"
      class="popup"
    >
      <div
        ref="popupInner"
        class="inner"
        :style="{
          width,
          height: height || 'auto',
        }"
      >
        <button
          class="close focus:outline-none absolute top-0 right-0"
          @click="close"
        >
          <icon class="cursor-pointer" :icon="mdiClose"></icon>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { mdiClose } from '@mdi/js';

export default {
  components: {
    Icon,
    LoadingSpinner,
  },
  props: {
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: true,
    },
    isLoaded: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'normal',
    },
  },
  data() {
    return {
      mdiClose,
      popupOverlayHeight: 0,
      popupHeight: 0,
    };
  },
  computed: {
    scrollbarWidth() {
      return window.innerWidth - document.documentElement.clientWidth;
    },
    centered() {
      return this.popupHeight + 180 < this.popupOverlayHeight;
    },
  },
  watch: {
    isLoaded: {
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.popupHeight = this.$refs.popupInner.clientHeight;
            this.popupOverlayHeight = this.$refs.popupOverlay.clientHeight;
          }
        });
      },
    },
  },
  mounted() {
    document.body.style.paddingRight = `${this.scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';
  },
  destroyed() {
    document.body.style.paddingRight = '0';
    document.body.style.overflow = 'auto';
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/_variables.scss';
.popup-overlay {
  @apply fixed w-screen h-screen top-0 left-0 z-100 overflow-y-auto flex justify-center items-center;
  background: rgba($primary-dark, 0.4);
}
.popup {
  @apply z-50 flex items-center;
  .close {
    @apply p-4;
  }
  .inner {
    @apply bg-white w-screen rounded-lg p-6 min-h-60 flex flex-col items-center justify-center relative;

    @screen sm {
      @apply w-136;
    }

    p {
      @apply w-full text-center;
    }
  }
  h2,
  h3,
  h4 {
    @apply text-center mb-1;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-header;
  }
  h4 {
    @apply text-xl;
  }
}
</style>
