<template>
  <div
    class="content-wrapper"
    :class="{
      'horizontal-spacing': !withoutHorizontalSpacing,
      'h-full': fillHeight,
    }"
  >
    <brand-search-bar
      :query="query"
      @select-limit="$emit('select-limit', $event)"
    />
    <slot></slot>
  </div>
</template>

<script>
import BrandSearchBar from '@/layouts/BrandLayout/Components/BrandSearchBar.vue';
export default {
  components: { BrandSearchBar },
  props: {
    query: Object,
    withoutHorizontalSpacing: Boolean,
    fillHeight: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  @apply pt-16 pb-8 flex-grow duration-500 ease-in overflow-auto relative;

  @screen md {
    @apply ml-sidebar;
  }
  @screen xxl {
    @apply ml-sidebar;
  }

  &.horizontal-spacing {
    @apply px-4;

    @screen md {
      @apply px-6;
    }
  }
}
</style>
