export const KFC = {
  primary: '#db0012',
  primaryHover: '#ac0c19',
  primaryLight: '#fdf2f3',
  links: '#8f000c',
};

export const NAVY = {
  primary: '#1e306e',
  primaryHover: '#1c2853',
  primaryLight: '#e6ebfc',
  links: '#015BD7',
};
