<template>
  <settings-content-wrapper>
    <div
      class="flex items-center justify-between mb-4 pb-4 border-b border-b-gray-light"
    >
      <breadcrumbs
        :path="[
          {
            name: `${$t('theme-view.theme')}`,
          },
        ]"
      />
      <btn
        class="w-30"
        type="submit"
        form="theme-form"
        :disabled="!unsavedChanges || submissionInProgress"
      >
        {{ $t('common.save') }}
      </btn>
    </div>
    <div class="inline-block min-w-full md:min-w-1/2">
      <p class="font-semibold">{{ $t('theme-view.configure-theme') }}:</p>
      <ThemeForm
        id="theme-form"
        :theme="theme"
        :disabled="submissionInProgress"
        @update-theme="updateTheme"
        @submit="saveTheme"
      />

      <btn
        v-if="unsavedChanges"
        theme="secondary"
        class="w-30 self-end mt-8"
        :disabled="submissionInProgress"
        @click="revertTheme"
      >
        {{ $t('theme-view.revert') }}
      </btn>
    </div>
  </settings-content-wrapper>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Btn from '@/components/Button/Button';
import { mapActions, mapGetters, mapState } from 'vuex';
import isEqual from 'lodash.isequal';
import ThemeForm from '@/views/Brand/Settings/Theme/Forms/ThemeForm';
import SettingsContentWrapper from '@/layouts/BrandLayout/Components/SettingsContentWrapper';

export default {
  components: { SettingsContentWrapper, ThemeForm, Breadcrumbs, Btn },
  data() {
    return {
      theme: {},
      basicTheme: {},
      submissionInProgress: false,
    };
  },
  computed: {
    ...mapState('ui', {
      referenceTheme: 'theme',
    }),
    ...mapGetters('brand', ['brandTheme']),
    ...mapGetters('user', { userBrand: 'brand' }),
    brandId() {
      return this.$route.params.id || this.userBrand;
    },
    unsavedChanges() {
      return !isEqual(this.theme, this.brandTheme);
    },
  },
  mounted() {
    this.theme = { ...this.brandTheme };
  },
  methods: {
    ...mapActions('ui', ['changeTheme']),
    ...mapActions('brand', ['updateBrand', 'fetchBrandInfo']),
    async saveTheme() {
      this.submissionInProgress = true;

      try {
        await this.updateBrand({
          brandId: this.brandId,
          data: {
            styles: this.theme,
          },
        });

        await this.fetchBrandInfo(this.brandId);

        this.$toast.success(this.$t('theme-view.theme-changed'));
      } finally {
        this.submissionInProgress = false;
      }
    },
    updateTheme(theme) {
      this.theme = { ...theme };

      this.previewTheme();
    },
    previewTheme() {
      this.changeTheme(this.theme);
    },
    revertTheme() {
      this.theme = { ...this.brandTheme };

      this.previewTheme();
    },
  },
};
</script>
