import { defineComponent } from '@vue/composition-api';
import { customMapStyles, MAP_TYPE, mapStyles, } from '@/components/Map/constants/map-styles';
import { isEnumValue } from '@/util/common';
import { MAP_SETTING_EVENT } from '@/components/Map/constants';
export default defineComponent({
    props: {
        map: {
            type: Object,
            required: true,
        },
        mapStyle: {
            type: Object,
            required: true,
        },
        withOptions: Boolean,
    },
    setup(props, ctx) {
        const mapStyleTiles = Object.values(mapStyles);
        const map = props.map?.value;
        const mapStyleClickHandle = (selectedMapStyle) => {
            if (props.mapStyle.value.name === selectedMapStyle.name || !map) {
                return;
            }
            ctx.emit(MAP_SETTING_EVENT.SET_MAP_STYLE, selectedMapStyle);
            if (isEnumValue(selectedMapStyle.name, MAP_TYPE)) {
                map.setMapTypeId(selectedMapStyle.name);
                map.setOptions({ styles: [] });
                return;
            }
            map.setMapTypeId(MAP_TYPE.DEFAULT);
            map.setOptions({
                styles: customMapStyles[selectedMapStyle.name],
            });
        };
        return { mapStyleClickHandle, mapStyleTiles };
    },
});
