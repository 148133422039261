<template>
  <div>
    <fetch-data
      v-if="brand"
      v-slot="{ results, isLoading, pagination }"
      url="/categories"
      :params="{
        brandId: brand.id,
        parentId,
        hasDescendantWithForm: true,
        limit: 8,
      }"
    >
      <div v-if="isLoading" class="w-full flex justify-center">
        <loading-spinner is-loading class="h-10 w-10" />
      </div>
      <category-list
        v-show="!selectedCategory"
        :categories="results"
        :loading="loading"
        :show-pagination="
          pagination && pagination.currentPage < pagination.lastPage
        "
        @select="$emit('select', $event)"
        @load-more="() => (showCategoryModal = true)"
      />
    </fetch-data>
    <category-tree
      v-if="selectedCategory && selectedCategory.hasChildren"
      :parent-id="selectedCategory.id"
      :selected-categories="selectedCategories"
      :level="level + 1"
      :loading="loading"
      @select="$emit('select', $event)"
    />
    <portal v-if="showCategoryModal" to="modals">
      <category-modal
        id="category-modal"
        :show="showCategoryModal"
        :parent-id="parentId"
        @select="onSelectCategory"
        @close="() => (showCategoryModal = false)"
      />
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FetchData from '@/components/DataProvider/FetchData';
import CategoryList from '@/components/FormElements/CategoryList';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import CategoryModal from '@/views/Brand/Applications/Components/Categories/CategoryModal';

export default {
  name: 'CategoryTree',
  components: { CategoryModal, CategoryList, FetchData, LoadingSpinner },
  props: {
    parentId: {
      type: String,
      default: null,
    },
    level: {
      type: Number,
      default: 0,
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
    selectedCategory() {
      return this.selectedCategories[this.level];
    },
  },
  data() {
    return {
      showCategoryModal: false,
    };
  },
  methods: {
    onSelectCategory(category) {
      this.$emit('select', category);
      this.showCategoryModal = false;
    },
  },
};
</script>
