var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-uploader-container"},[_c('btn',{staticClass:"w-50",attrs:{"theme":"secondary"},nativeOn:{"click":function($event){return _vm.openModal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" "+_vm._s(_vm.$t(("file-upload." + _vm.type)))+" ")]),(_vm.showModal)?_c('portal',{attrs:{"to":"modals"}},[_c('modal',{attrs:{"show":_vm.showModal},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('div',{staticClass:"w-full px-12"},[_c('h3',{staticClass:"mt-6"},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" "+_vm._s(_vm.$t(("file-upload." + _vm.type)))+" ")]),_c('label',{staticClass:"text-center pt-2 pb-0 w-full block"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('file-upload.name')))]),_c('text-input',{attrs:{"placeholder":_vm.$t('file-upload.name-placeholder')},model:{value:(_vm.AttachmentName),callback:function ($$v) {_vm.AttachmentName=$$v},expression:"AttachmentName"}})],1),_c('label',{staticClass:"text-center py-2 w-full block"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('file-upload.file')))]),_c('upload-area',{attrs:{"is-uploading":_vm.isUploading,"is-error":_vm.isError,"accepted-files":_vm.acceptedFiles},on:{"update:isUploading":function($event){_vm.isUploading=$event},"update:is-uploading":function($event){_vm.isUploading=$event},"update:isError":function($event){_vm.isError=$event},"update:is-error":function($event){_vm.isError=$event}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('div',{staticClass:"w-full flex justify-center"},[_c('btn',{staticClass:"w-48 mb-6",attrs:{"disabled":_vm.isUploading || !_vm.file},on:{"click":function($event){return _vm.addDocument({
                target: _vm.target,
                params: {
                  name: _vm.AttachmentName ? _vm.AttachmentName : _vm.file.name,
                  type: _vm.type,
                  url: _vm.file.url,
                },
              })}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])],1)])])],1):_vm._e(),_c('upload-list',{attrs:{"type":_vm.type,"list":_vm.list,"use-deletion-queue":_vm.useDeletionQueue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }