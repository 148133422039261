import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useGetters, useActions, useState } = createNamespacedHelpers('reportResources');
export const useReportResources = () => {
    const { selectPlace, selectObject, setPlaces, setObjects, setRemovedPlaces, setRemovedObjects, clearReportResources, selectAllObjects, selectAllPlaces, removeSelectedPlace, removeSelectedObject, setEditedMessageId, setPlacesRequirement, setObjectsRequirement, } = useActions([
        'selectPlace',
        'selectObject',
        'setPlacesRequirement',
        'setObjectsRequirement',
        'setPlaces',
        'setObjects',
        'setRemovedPlaces',
        'setRemovedObjects',
        'clearReportResources',
        'selectAllObjects',
        'selectAllPlaces',
        'removeSelectedPlace',
        'removeSelectedObject',
        'setEditedMessageId',
    ]);
    const { selectedPlaceIds, removedPlaceIds, removedObjectIds, selectedObjectIds, isEditing, } = useGetters([
        'selectedPlaceIds',
        'removedPlaceIds',
        'removedObjectIds',
        'selectedObjectIds',
        'isEditing',
    ]);
    const { selectedPlaces, selectedObjects, removedPlaces, removedObjects, editedMessageId, mustContainObjects, mustContainPlaces, } = useState([
        'selectedPlaces',
        'selectedObjects',
        'removedPlaces',
        'removedObjects',
        'editedMessageId',
        'mustContainObjects',
        'mustContainPlaces',
    ]);
    return {
        // actions
        selectPlace,
        selectObject,
        setPlaces,
        setObjects,
        setRemovedPlaces,
        setRemovedObjects,
        clearReportResources,
        selectAllObjects,
        selectAllPlaces,
        removeSelectedPlace,
        removeSelectedObject,
        setEditedMessageId,
        setPlacesRequirement,
        setObjectsRequirement,
        // getters
        selectedPlaceIds,
        removedPlaceIds,
        removedObjectIds,
        selectedObjectIds,
        isEditing,
        // state
        selectedPlaces,
        selectedObjects,
        removedPlaces,
        removedObjects,
        editedMessageId,
        mustContainObjects,
        mustContainPlaces,
    };
};
