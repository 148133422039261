<template>
  <td>
    <span v-if="isEmpty && !column.allowEmptyValues">-</span>
    <template v-else-if="column.routerLink">
      <div
        v-if="routerLinkConfig.isArray && Array.isArray(value)"
        class="ellipsis-overflow"
      >
        <router-link
          v-for="(item, i) in value"
          :key="i"
          :to="routerLinkConfig.getRoute(item)"
        >
          <span v-html="getHtmlValue(item, true)" />
          <span v-if="i !== value.length - 1">,</span>
        </router-link>
      </div>
      <router-link v-else :to="routerLinkConfig.getRoute(row)">
        <span v-html="getHtmlValue(value)" />
      </router-link>
    </template>
    <status-toggler
      v-else-if="column.status"
      :options="statusOptions(value)"
      :current-status="value"
      :colors="statusColors"
      :compact="column.compact"
      :status-translation="computedStatusConfig.translation"
      :disabled="column.disabled"
      @change-status="(status) => $emit('change-status', status)"
      @click.native.stop
    />
    <table-cell-checkbox
      v-else-if="column.checkbox"
      :value="value"
      :config="column.checkboxConfig"
      :row="row"
    />
    <span v-else class="ellipsis-overflow" v-html="getHtmlValue(value)" />
  </td>
</template>
<script>
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { format, merge } from '@/components/Table/TableGrid/utils';
import { status } from '../mixins';
import TableCellCheckbox from '@/components/Table/TableGrid/components/TableCellCheckbox';

export default {
  components: { TableCellCheckbox, StatusToggler },
  props: {
    value: {
      type: [String, Array, Object, Number, Boolean],
      default: null,
    },
    column: {
      type: [Object, String],
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [status],
  computed: {
    isEmpty() {
      return [null, undefined].includes(this.value);
    },
    routerLinkConfig() {
      return {
        isArray: false,
        ...this.column.routerLink,
      };
    },
  },
  methods: {
    format,
    merge,
    getHtmlValue(value, isArray = false) {
      if (isArray) {
        return this.routerLinkConfig.arrayFormatter
          ? this.routerLinkConfig.arrayFormatter(value, this.row)
          : value;
      }
      return format(
        this.column.merge ? merge(this.row, this.column.merge) : value,
        this.column.formatter,
        this.row,
      );
    },
  },
};
</script>
<style scoped>
.ellipsis-overflow {
  @apply max-w-full overflow-hidden whitespace-no-wrap block;
  text-overflow: ellipsis;
}
</style>
