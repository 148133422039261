export var SwitchInputTheme;
(function (SwitchInputTheme) {
    SwitchInputTheme["GREEN"] = "green";
    SwitchInputTheme["YELLOW"] = "yellow";
})(SwitchInputTheme || (SwitchInputTheme = {}));
export var SwitchSize;
(function (SwitchSize) {
    SwitchSize["SMALL"] = "small";
    SwitchSize["MEDIUM"] = "medium";
})(SwitchSize || (SwitchSize = {}));
export var LabelPosition;
(function (LabelPosition) {
    LabelPosition["LEFT"] = "left";
    LabelPosition["RIGHT"] = "right";
})(LabelPosition || (LabelPosition = {}));
