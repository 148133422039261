var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-display',{staticClass:"mt-4",attrs:{"rows":_vm.data,"columns":_vm.columns,"is-row-expanded":_vm.isObjectDetailsExpanded,"table-style":_vm.TableStyle.Resources},on:{"record-click":function($event){return _vm.$emit('open', $event.id)}},scopedSlots:_vm._u([{key:"checkboxCell",fn:function(ref){
var row = ref.row;
return [_c('checkbox',{attrs:{"table":"","value":_vm.isObjectSelected(row)},on:{"input":function($event){return _vm.$emit('select', row)}}})]}},{key:"placeCell",fn:function(ref){
var value = ref.value;
return [_c('place-with-popover',{attrs:{"place":value,"display-property":"number"}})]}},{key:"internalStatusCell",fn:function(ref){
var value = ref.value;
return [_c('status-toggler',{attrs:{"compact":"","colors":_vm.OBJECT_STATUS_COLORS,"options":[],"current-status":value,"status-translation":"object-status","disabled":""}})]}},{key:"expandButtonCell",fn:function(ref){
var row = ref.row;
return [_c('expand-button',{attrs:{"is-expanded":_vm.isObjectDetailsExpanded(row),"button-style":_vm.ExpandButtonStyle.ARROW},on:{"expand":function($event){return _vm.toggleExpandObjectDetails(row)}}})]}},{key:"actionsCell",fn:function(ref){
var row = ref.row;
return [_c('table-actions',{attrs:{"actions":_vm.getActions(row),"ellipsis-only":""}})]}},{key:"expandedRow",fn:function(ref){
var row = ref.row;
return [_c('object-preview',{attrs:{"object-id":row ? row.id : '',"hide-details-btn":"","sidebar":""}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }