<template>
  <div>
    <page-header
      :title="header"
      :sub-title="object.objectType.name"
      :created-at="object.createdAt"
      :updated-at="object.updatedAt"
      :route="{
        name: 'Objects',
        params: { objectTypeId: object.objectType.id },
      }"
      :route-label="$t('object-details-view.objects')"
    >
      <template #left>
        <navigation-arrow
          :direction="CONTROLLER_DIRECTION.PREV"
          :disabled="!prevObject"
          :to="{
            name: 'ObjectDetails',
            params: {
              objectId: prevObject,
              objectTypeId: object.objectType.id,
            },
          }"
        />
      </template>
      <template #right>
        <navigation-arrow
          :direction="CONTROLLER_DIRECTION.NEXT"
          :disabled="!nextObject"
          :to="{
            name: 'ObjectDetails',
            params: {
              objectId: nextObject,
              objectTypeId: object.objectType.id,
            },
          }"
        />
      </template>
    </page-header>
    <div class="flex items-center justify-between mt-4">
      <field-with-label
        id="localization"
        class="flex-grow flex-shrink"
        :label="$t('object-details-view.localization')"
        :value="address"
      />
      <status-toggler
        class="flex-grow-0 flex-shrink-0 ml-2"
        :options="getStatusOptions(status || object.internalStatus)"
        :colors="OBJECT_STATUS_COLORS"
        :status-translation="statusConfig.translation"
        :current-status="status || object.internalStatus"
        :compact="isMobileView"
        @change-status="onStatusUpdate"
      />
    </div>
  </div>
</template>
<script>
import StatusToggler from '@/components/StatusToggler/StatusToggler';
import objectStatus from '@/views/Brand/Objects/mixins/objectStatus';
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/PageHeader/PageHeader';
import FieldWithLabel from '@/components/Expandable/components/FieldWithLabel';
import NavigationArrow from '@/components/NavigationArrow/NavigationArrow';
import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
import { getFullAddress } from '@/util/common';
import { useMobileView } from '@/composables';

export default {
  components: {
    FieldWithLabel,
    PageHeader,
    StatusToggler,
    NavigationArrow,
  },
  data() {
    return {
      CONTROLLER_DIRECTION,
      status: null,
    };
  },
  computed: {
    ...mapGetters('offer', ['nextObjectId', 'prevObjectId', 'activeOfferId']),
    ...mapState('object', { object: 'details' }),
    ...mapGetters('object', ['nextObject', 'prevObject']),
    header() {
      return `${this.$t('object-details-view.object').toUpperCase()} ${
        this.object.internalNumber
      }`;
    },
    address() {
      return getFullAddress(this.object);
    },
  },
  mixins: [objectStatus],
  methods: {
    ...mapActions('object', ['updateObject']),
    async onStatusUpdate(status) {
      await this.updateObject({
        objectId: this.object.id,
        data: {
          internalStatus: status,
        },
      });
      this.status = status;
      this.$toast.success(this.$t(`object-details-view.status-updated`));
    },
  },
  setup() {
    const { isMobileView } = useMobileView();

    return { isMobileView };
  },
};
</script>
