import { computed, defineComponent } from '@vue/composition-api';
import { SystemRole } from '@/api/requests/users';
import { getFullName } from '@/util/user';
import UserAvatar from '@/views/_components/UserAvatar.vue';
export default defineComponent({
    components: { UserAvatar },
    props: {
        user: {
            type: Object,
            required: true,
        },
        isUserSelected: {
            type: Function,
            default: () => false,
        },
    },
    setup(props, ctx) {
        const role = computed(() => {
            const { systemRole, internalRole, homeOrganization } = props.user;
            if (systemRole === SystemRole.CONTRACTOR)
                return homeOrganization?.name;
            if (internalRole)
                return internalRole;
            const i18nKey = systemRole.toLowerCase().split('_').join('-');
            return ctx.root.$t(`roles.${i18nKey}`);
        });
        const userFullName = computed(() => getFullName(props.user));
        return { userFullName, role };
    },
});
