export var BtnTheme;
(function (BtnTheme) {
    BtnTheme["PRIMARY"] = "primary";
    BtnTheme["SECONDARY"] = "secondary";
    BtnTheme["TERTIARY"] = "tertiary";
    BtnTheme["QUATERNARY"] = "quaternary";
    BtnTheme["YELLOW_LIGHT"] = "yellow-light";
    BtnTheme["YELLOWISH"] = "yellowish";
    BtnTheme["NONE"] = "none";
})(BtnTheme || (BtnTheme = {}));
