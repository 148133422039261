import { ref } from '@vue/composition-api';
import { DEFAULT_INTERACTIVE_MARKER_PATH, MARKER_DIRECTION_ARROW, POSITION_INPUT_FRACTION_DIGITS, } from '@/components/Map/constants/common';
import { rotateSVG, setSvgFillColor, stringifyAsset } from '@/util/svg';
import { MapSettings } from '@/components/Map';
import { useMapState } from '@/components/Map/composables/use-map-state';
import { createMarkerContent } from '@/components/Map/utils/create-marker-content';
export const useInteractiveMap = (props, ctx) => {
    const { map } = props;
    const interactiveMarker = ref(null);
    const markerListenerHandle = ref(null);
    const { settings } = useMapState();
    const isLocalMarker = (icon) => icon.includes('/img/marker');
    const setInteractiveCursor = () => {
        map.value?.setOptions({ draggableCursor: 'crosshair' });
    };
    const setInteractiveMapListeners = (marker) => {
        markerListenerHandle.value = map.value?.addListener('click', async (event) => {
            await setInteractiveMarker(event.latLng.toJSON(), marker);
        });
    };
    const clearInteractiveMapListeners = () => {
        if (markerListenerHandle.value) {
            google.maps.event.removeListener(markerListenerHandle.value);
            markerListenerHandle.value = null;
        }
    };
    const getInteractiveMarkerIcon = async (marker) => {
        const directionMarker = await stringifyAsset(MARKER_DIRECTION_ARROW);
        const isDirectionIcon = marker?.directionAngle != null &&
            settings.value.get(MapSettings.SHOW_OBJECT_DIRECTION);
        if (isDirectionIcon) {
            return rotateSVG(directionMarker, marker?.directionAngle);
        }
        if (!marker?.icon) {
            return DEFAULT_INTERACTIVE_MARKER_PATH;
        }
        if (!marker?.color || !isLocalMarker(marker?.icon)) {
            return marker?.icon;
        }
        const stringifiedMarker = await stringifyAsset(marker?.icon);
        return setSvgFillColor(stringifiedMarker, marker?.color);
    };
    const createInteractiveMarker = async (position, marker) => {
        const icon = await getInteractiveMarkerIcon(marker);
        interactiveMarker.value = new google.maps.marker.AdvancedMarkerElement({
            position,
            map: map.value,
            content: createMarkerContent(icon),
        });
    };
    const setInteractiveMarker = async (position, marker) => {
        clearInteractiveMarker();
        await createInteractiveMarker(position, marker);
        ctx.emit('input', {
            lat: parseFloat(position.lat.toFixed(POSITION_INPUT_FRACTION_DIGITS)),
            lng: parseFloat(position.lng.toFixed(POSITION_INPUT_FRACTION_DIGITS)),
        });
    };
    const clearInteractiveMarker = () => {
        if (interactiveMarker.value) {
            interactiveMarker.value.map = null;
        }
    };
    const initializeInteractiveMarker = async (position, marker) => {
        await createInteractiveMarker(position, marker);
        setInteractiveCursor();
        setInteractiveMapListeners(marker);
    };
    return {
        initializeInteractiveMarker,
        setInteractiveCursor,
        clearInteractiveMapListeners,
        clearInteractiveMarker,
        setInteractiveMarker,
    };
};
