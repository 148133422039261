import { computed, defineComponent } from '@vue/composition-api';
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { Icon } from '@/components/Icon';
import { Btn, BtnTheme } from '@/components/Button';
import { useMobileView } from '@/composables';
export default defineComponent({
    components: { Btn, Icon, EllipsisMenu, ModalWrapper },
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        ellipsisOnly: Boolean,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const ellipsisActions = computed(() => {
            const result = {};
            props.actions.forEach(({ name, handler, modal }) => {
                result[name] = {
                    name: ctx.root.$t(`ellipsis-menu.${name}`).toString(),
                    handler,
                    modal,
                };
            });
            return result;
        });
        const onClick = (action, runWithModal) => {
            action.modal
                ? runWithModal(action.handler, action.modal)
                : action.handler();
        };
        return { isMobileView, BtnTheme, ellipsisActions, onClick };
    },
});
