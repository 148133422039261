import { defineComponent } from '@vue/composition-api';
import Modal from '@/components/Modal/Modal.vue';
import TextInput from '@/components/Inputs/TextInput/TextInput.vue';
import Btn from '@/components/Button/Button.vue';
import { update } from 'autosize';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { useValidation } from '@/composables';
export default defineComponent({
    methods: { update },
    components: { Btn, TextInput, Modal, ValidationProvider, ValidationObserver },
    props: {
        value: {
            type: String,
            default: '',
        },
        show: Boolean,
    },
    setup(_, ctx) {
        useValidation(ctx);
    },
});
