import Btn from '@/components/Button/Button.vue';
import { Icon, IconSize } from '@/components/Icon';
import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { BtnSize } from '@/components/Btn';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    components: { Btn, Icon },
    props: {
        direction: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        to: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            default: BtnSize.MD,
        },
    },
    setup(props) {
        const iconDirectionMap = {
            [CONTROLLER_DIRECTION.NEXT]: mdiChevronRight,
            [CONTROLLER_DIRECTION.PREV]: mdiChevronLeft,
        };
        return {
            IconSize,
            directionIcon: iconDirectionMap[props.direction],
        };
    },
});
