import { ResourceType } from '@/constants/resources.ts';
import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import FiltersWrapper from '@/components/FiltersWrapper/FiltersWrapper.vue';
import ObjectFilters from '@/views/Brand/Applications/Components/Filters/ObjectFilters.vue';
import PlaceFilters from '@/views/Brand/Applications/Components/Filters/PlaceFilters.vue';
import cloneDeep from 'lodash.clonedeep';
import { EMPTY_OBJECT_FILTERS, EMPTY_PLACE_FILTERS, } from '@/views/Brand/Applications/Components/Resources/resources.constants';
export default defineComponent({
    components: {
        PlaceFilters,
        ObjectFilters,
        FiltersWrapper,
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                [ResourceType.OBJECTS]: {},
                [ResourceType.PLACES]: {},
            }),
        },
        activeTab: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const updatedFilters = ref(cloneDeep(props.value));
        watch(() => props.value, (value) => {
            updatedFilters.value = cloneDeep(value);
        }, {
            deep: true,
        });
        const applyFilters = () => {
            ctx.emit('input', updatedFilters.value);
            ctx.emit('close');
        };
        const filtersContentTag = computed(() => {
            const components = {
                [ResourceType.OBJECTS]: 'ObjectFilters',
                [ResourceType.PLACES]: 'PlaceFilters',
            };
            return components[props.activeTab];
        });
        const clearFilters = () => {
            if (props.activeTab === ResourceType.PLACES)
                updatedFilters.value[ResourceType.PLACES] = {
                    ...EMPTY_PLACE_FILTERS,
                    q: updatedFilters.value[ResourceType.PLACES].q,
                };
            else if (props.activeTab === ResourceType.OBJECTS)
                updatedFilters.value[ResourceType.OBJECTS] = {
                    ...EMPTY_OBJECT_FILTERS,
                    q: updatedFilters.value[ResourceType.OBJECTS].q,
                };
        };
        return {
            ResourceType,
            filtersContentTag,
            applyFilters,
            updatedFilters,
            clearFilters,
        };
    },
});
