import { Icon } from '@/components/Icon';
import { ActionRequiredInfo } from '@/views/Brand/Applications/Report/Details/_components/action-required-info';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { mdiChevronRight, mdiClose, mdiMagnify } from '@mdi/js';
import { useAuthUser, useBrand, useMobileView } from '@/composables';
import { getFullName } from '@/util/user';
import { DropdownPosition } from '@/components/Inputs/SelectInput/_components/SelectDropdown';
import { ActionRequiredUsersSelect } from '@/views/Brand/Applications/Report/Details/_components/action-required-users-select';
import ActionRequiredUsersModal from '@/views/Brand/Applications/Report/Details/_components/action-required-users-modal/ActionRequiredUsersModal.vue';
import ActionRequiredUser from '@/views/Brand/Applications/Report/Details/_components/action-required-user/ActionRequiredUser.vue';
export default defineComponent({
    components: {
        ActionRequiredUser,
        ActionRequiredUsersModal,
        ActionRequiredUsersSelect,
        ActionRequiredInfo,
        Icon,
    },
    props: {
        value: {
            type: Object,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const showUsersModal = ref(false);
        const { isMobileView } = useMobileView();
        const { reportId } = ctx.root.$route.params;
        const { authUserId } = useAuthUser();
        const usersQuery = computed(() => {
            return {
                ...(reportId && { relatedReportId: reportId }),
                accessToBrandId: brand.value.id,
                omitUserIds: [authUserId.value],
            };
        });
        const isUserSelected = (option) => {
            return props.value ? props.value.id === option.id : false;
        };
        return {
            mdiClose,
            mdiMagnify,
            mdiChevronRight,
            getFullName,
            isUserSelected,
            usersQuery,
            showUsersModal,
            DropdownPosition,
            isMobileView,
        };
    },
});
