import Modal from '@/components/Modal/__new__/Modal.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { mdiChevronLeft, mdiCheck } from '@mdi/js';
import { Icon } from '@/components/Icon';
import { BASE_INPUT_STYLE } from '@/components/Inputs/_components/BaseInput';
import { Debounce } from '@/constants/debounce';
import { useBrand } from '@/composables';
import { addReportMessage } from '@/api/requests/reports';
import ChatMessage from '@/views/Brand/Applications/Report/Chat/ChatMessage/ChatMessage.vue';
import { Btn, BtnTheme } from '@/components/Btn';
import { WysiwygInput, WysiwygInputHeight } from '@/components/WysiwygInput';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
export default defineComponent({
    components: {
        WysiwygInput,
        Btn,
        ChatMessage,
        Icon,
        Modal,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        message: Object,
    },
    setup(props, ctx) {
        const response = ref('');
        const { brand } = useBrand();
        const { reportId } = ctx.root.$route.params;
        const getMentionSuggestions = createMentionUserFn({
            accessToBrandId: brand.value,
        });
        const addResponseToActionRequired = async () => {
            if (!props.message) {
                throw new Error('Message that you want to add response is not provided');
            }
            const data = await addReportMessage({
                requiredActionMessageId: props.message.id,
                reportId: reportId,
                message: response.value,
                fileTokens: [],
                placeIds: [],
                objectIds: [],
            });
            ctx.emit('on-response-added', data);
            ctx.emit('input', false);
        };
        return {
            mdiChevronLeft,
            mdiCheck,
            BtnTheme,
            response,
            BASE_INPUT_STYLE,
            Debounce,
            WysiwygInputHeight,
            getMentionSuggestions,
            addResponseToActionRequired,
        };
    },
});
