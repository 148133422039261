import { ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ObjectStatus, updateObject } from '@/api/requests/objects';
import { OBJECT_STATUS_COLORS } from '@/constants/object.js';
const { useGetters: useGetters } = createNamespacedHelpers('user');
export const useObjectStatus = () => {
    const { hasAdminRights } = useGetters(['hasAdminRights']);
    const statusConfig = ref({
        translation: 'object-status',
    });
    const getStatusOptions = (currentStatus) => {
        if (!hasAdminRights.value)
            return [];
        const statuses = Object.values(ObjectStatus);
        return statuses.filter((status) => ![currentStatus, ObjectStatus.IN_OFFER].includes(status));
    };
    const updateStatus = async (id, status) => {
        await updateObject(id, { internalStatus: status });
    };
    return {
        getStatusOptions,
        statusConfig,
        updateStatus,
        ObjectStatus,
        OBJECT_STATUS_COLORS,
    };
};
