import { watch } from '@vue/composition-api';
import { usePromise } from '@/composables';
import { getCategory } from '@/api/requests/categories';
export const useCategoryForm = (report, { editedForm, taskForm, category, loading }) => {
    const { form, values } = report;
    taskForm.value = {
        ...taskForm.value,
        categoryForm: {
            formVersionId: form?.formVersionId || '',
            values: values || [],
        },
    };
    editedForm.value = form;
    if (form?.categoryId) {
        const { result, loading: loadingCategory } = usePromise(getCategory(form.categoryId));
        watch(loadingCategory, (loadingCategory) => {
            loading.value = loadingCategory;
        });
        watch(result, (value) => {
            category.value = value;
        });
    }
};
