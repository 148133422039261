<template>
  <div v-click-outside="closeMenu" class="context-menu">
    <button class="h-full" @click="showMenu = !showMenu">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <ul v-if="showMenu" class="menu" @click="showMenu = false">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  @apply px-2;
  &:focus {
    @apply outline-none;
  }
  span {
    @apply h-1 w-1 rounded-full my-2px mx-1 block bg-gray-dark;
  }
}
.context-menu {
  @apply relative flex items-center;
}
.menu {
  @apply shadow-context-menu rounded bg-white absolute z-50 right-0 top-0 mt-2 mr-4 min-w-28 py-2;
  li {
    @apply cursor-pointer px-3 py-1;
    &:hover {
      @apply bg-gray-lighter;
    }
  }
}
</style>
