<template>
  <div class="tree-menu w-full">
    <div
      v-if="label"
      :style="indent"
      :class="{
        'text-primary': !allNodes,
        'text-gray-dark': disabled || !isPublished,
      }"
      class="flex items-center font-semibold mb-4"
    >
      <icon
        :icon="iconName"
        class="text-gray-dark mr-2 cursor-pointer"
        @click.native="showChildren = !showChildren"
      />
      <div class="px-4 py-2 h-8 flex flex-grow items-center justify-between">
        {{ label }}
        <div v-if="!disabled" class="flex">
          <tooltip v-if="!root && coordinator" :label="tooltipLabel">
            <icon :icon="mdiInformationOutline" class="text-primary" />
          </tooltip>
          <ellipsis-menu
            :absolute="false"
            class="mr-1 text-primary"
            :items="menuItems"
          />
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner
        is-loading
        class="h-10 w-10 mb-8"
        :style="indentAddNew"
      />
    </div>
    <div v-else-if="error">
      <p class="mb-4">{{ $t('common.error-api') }}.</p>
      <btn @click="fetchStructures">{{ $t('structures-view.reload') }}</btn>
    </div>
    <div v-else v-show="showChildren">
      <div v-if="allNodes.length">
        <tree-structure
          v-for="node in allNodes"
          :key="node.id"
          :coordinator="node.users[0]"
          :has-children="node.hasChildren"
          :places="node.places"
          :label="node.name"
          :parent-id="node.id"
          :depth="depth + 1"
          :disabled="disabled"
          :is-published="node.isPublished"
          @addStructure="onCreateSubstructure"
          @editStructure="onEditStructure"
          @addPlace="onAddPlace"
          @editPlaces="onEditPlaces"
          @deleteStructure="onDeleteStructure"
        ></tree-structure>
      </div>
      <div
        v-if="!places.length"
        :style="indentAddNew"
        :class="{ 'text-primary': !allNodes, 'text-gray-dark': disabled }"
        class="flex items-center font-semibold cursor-pointer mb-4"
      >
        <div
          class="px-4 ml-2 py-2 h-16 border border-dashed border-gray-light flex flex-grow items-center justify-between"
        >
          <btn
            theme="secondary"
            :size="mobileView ? 'sm' : 'md'"
            class="px-4 whitespace-no-wrap"
            :disabled="disabled"
            @click="onCreateSubstructure(parentId)"
          >
            {{ root ? $t('tree.add-structure') : $t('tree.add-substructure') }}
          </btn>
          <div class="relative">
            <ellipsis-menu
              v-if="!disabled"
              :absolute="false"
              class="mr-1 text-primary"
              :items="{
                addPlace: {
                  name: $t('tree.add-facility'),
                  handler: () => {
                    onAddPlace(parentId);
                  },
                },
              }"
            />
          </div>
        </div>
      </div>
      <ul
        v-else
        :style="indentList"
        :class="disabled ? 'text-gray-dark' : 'text-primary'"
      >
        <li v-for="(facility, index) in places" :key="index" class="my-2">
          <router-link
            :to="{ name: 'PlaceDetails', params: { placeId: facility.id } }"
          >
            {{ facility.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <div
      v-if="pagination && pagination.total > nodes.length"
      class="flex justify-items-start py-4"
      :style="indentAddNew"
    >
      <tooltip label="$t('structures-view.more')">
        <button :disabled="isLoading || disabled" @click="fetchStructures">
          <icon :icon="mdiDotsHorizontal" class="text-primary" />
        </button>
      </tooltip>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { Icon } from '@/components/Icon';
import { mapState } from 'vuex';
import Tooltip from '@/components/Tooltip/Tooltip';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import treeStructure from '@/components/Tree/mixins/treeStructure';
import { mdiInformationOutline, mdiDotsHorizontal } from '@mdi/js';

export default {
  name: 'TreeStructure',
  components: {
    Tooltip,
    Btn,
    EllipsisMenu,
    Icon,
    LoadingSpinner,
  },
  mixins: [treeStructure],
  props: {
    root: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiInformationOutline,
      mdiDotsHorizontal,
    };
  },
  computed: {
    ...mapState('structures', {
      structureList: 'structureList',
      deletedStructures: 'deletedStructures',
    }),
    unpublishedNodes() {
      return this.structureList.filter(
        (structure) => structure.parentId === this.parentId,
      );
    },
    allNodes() {
      return [...this.nodes, ...this.unpublishedNodes].filter(
        (node) => !this.deletedStructures.includes(node.id),
      );
    },
    menuItems() {
      const menu = {
        edit: {
          name: this.$t('common.edit'),
          handler: () => {
            this.onEditStructure({
              id: this.parentId,
              data: {
                name: this.label,
                user: this.coordinator,
              },
              isRoot: this.root,
            });
          },
        },
      };

      if (!this.root && !this.allNodes.length && !this.places.length) {
        menu.delete = {
          name: this.$t('common.delete'),
          handler: () => {
            this.onDeleteStructure(this.parentId);
          },
        };
      }

      if (this.places.length) {
        menu.editPlaces = {
          name: this.$t('structures-view.edit-places'),
          handler: () => this.onEditPlaces(this.parentId, this.places),
        };
      }

      return menu;
    },
  },
  methods: {
    onCreateSubstructure(id) {
      this.$emit('addStructure', id);
    },
    onEditStructure(payload) {
      this.$emit('editStructure', payload);
    },
    onAddPlace(id) {
      this.$emit('addPlace', id);
    },
    onDeleteStructure(id) {
      this.$emit('deleteStructure', id);
    },
    onEditPlaces(id, places) {
      this.$emit('editPlaces', id, places);
    },
  },
};
</script>
