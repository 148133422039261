<template>
  <div>
    <label v-if="label" :for="id">
      <p class="text-input-label">
        {{ label }}
        <span v-if="label && required" class="required">*</span>
      </p>
    </label>
    <div class="w-full relative">
      <input
        :id="id"
        ref="input"
        class="text-input"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
      />
      <span v-if="errorMsg" class="text-input-error">
        {{ errorMsg }}
      </span>
      <div class="swatches">
        <v-swatches
          :value="value"
          swatches="text-advanced"
          show-fallback
          fallback-input-type="color"
          fallback-ok-class="theme-color-btn"
          fallback-input-class="theme-color-input"
          :fallback-ok-text="$t('common.save')"
          :disabled="disabled"
          @input="$emit('input', $event)"
        >
          <div
            slot="trigger"
            class="swatches-color"
            :style="{ backgroundColor: value }"
          ></div>
        </v-swatches>
      </div>
    </div>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches';

export default {
  components: {
    VSwatches,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    isError: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    return {
      color: '#fff2cc',
    };
  },
  computed: {
    errorMsg() {
      return typeof this.isError === 'string' ? this.isError : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  @apply py-2.5 pl-4 pr-2 h-10 w-full rounded border-solid border border-gray-light box-border outline-none text-gray-darkest;
  &::placeholder {
    @apply text-gray-darker;
  }
  &:focus {
    box-shadow: 0px 1px 0px 0px theme('colors.primary.default');
    @apply border-b border-b-primary;
  }
  &.error {
    box-shadow: 0px 1px 0px 0px theme('colors.red.500');
    @apply border-b border-b-red;
  }
  &-label {
    @apply text-base mb-2;
    .required {
      @apply text-red;
    }
  }
}

.text-input-error {
  @apply text-red absolute left-0 bottom-0 -mb-5 text-xs;
}
.swatches {
  @apply flex h-full items-center absolute right-0 top-0;

  &-color {
    @apply h-8 w-8 rounded mr-1 mt-1.5 cursor-pointer border-gray-dark border;
  }
}

.theme-color-input {
  @apply h-8 cursor-pointer border-gray-dark rounded;
}

.theme-color-btn {
  @apply bg-primary mt-4 font-semibold;
}
</style>
