import { defineComponent } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
import ChatParticipants from '@/views/Brand/Applications/Report/Chat/ChatParticipants.vue';
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';
import { ReportStatus } from '@/api/requests/reports';
import { ResourceType } from '@/constants/resources';
import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
export default defineComponent({
    name: 'ChatReportMobileMenu',
    components: {
        Icon,
        ChatParticipants,
        ModalWrapper,
    },
    props: {
        toggleMenu: Function,
        reportUsers: {
            type: Array,
            required: true,
        },
        report: {
            type: Object,
            required: true,
        },
        itemsOptions: {
            type: Object,
            required: true,
        },
        toggleDetails: Function,
        isDetailsOpened: Boolean,
        closeReport: Function,
    },
    setup(props, ctx) {
        const onClick = (item, runWithModal) => {
            item.modal ? runWithModal(item.handler, item.modal) : item.handler();
        };
        const toggleDetailsPopup = () => {
            ctx.emit('toggle-menu');
            ctx.emit('toggle-details');
        };
        return {
            mdiClose,
            ReportStatus,
            ResourceType,
            onClick,
            toggleDetailsPopup,
            RECIPIENTS_EVENTS,
        };
    },
});
