<template>
  <btn
    :class="{ 'w-10': isSidebarOpen }"
    class="center fixed bottom-20 right-4 mb-5 md:mb-0 md:static z-10"
    @click="toggleSidebar"
  >
    <span class="center" :class="{ 'md:pr-2': !isSidebarOpen }">
      <icon :icon="iconName" :size="IconSize.XL" />
      <span class="text-xxs md:text-xs uppercase">{{ btnText }}</span>
    </span>
  </btn>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Btn from '@/components/Button/Button';
import { Icon, IconSize } from '@/components/Icon';
import { BTN_THEMES } from '@/store/modules/ui';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export default {
  components: { Icon, Btn },
  data() {
    return {
      IconSize,
      mdiChevronLeft,
      mdiChevronRight,
      BTN_THEMES,
    };
  },
  computed: {
    ...mapGetters('ui', ['isSidebarOpen']),
    iconName() {
      return this.isSidebarOpen ? mdiChevronRight : mdiChevronLeft;
    },
    btnText() {
      return this.isSidebarOpen ? '' : this.$t('common.resources');
    },
  },
  methods: {
    ...mapActions('ui', ['toggleSidebar']),
  },
};
</script>
<style scoped>
.center {
  @apply flex items-center;
}
</style>
