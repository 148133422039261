<template>
  <div class="tree-menu">
    <div
      v-if="label"
      :style="indent"
      :class="{ 'text-primary': !nodes }"
      class="flex items-center font-semibold cursor-pointer mb-4"
      @click="toggleChildren(id)"
    >
      <icon
        v-if="hasChildren || formsList.length"
        :icon="iconName"
        class="mr-2 flex-shrink-0"
        :class="nodes ? 'fill-gray-dark' : 'fill-primary'"
      />
      {{ label }}
    </div>
    <div v-if="show">
      <tree-category
        v-for="node in children"
        :id="node.id"
        :key="node.label"
        :nodes="node.children"
        :has-children="node.hasChildren"
        :label="node.name"
        :depth="depth + 1"
      />
      <div
        v-for="form in formsList"
        :key="form.id"
        class="flex mb-4"
        :style="{ marginLeft: `${(depth + 1) * 32}px` }"
      >
        <icon :icon="DocumentSvg" class="flex-shrink-0 mr-2 text-primary" />
        <router-link
          :to="{ name: 'EditForm', params: { formId: form.id } }"
          class="text-primary font-semibold"
        >
          {{ form.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import { mapActions, mapGetters, mapState } from 'vuex';
import DocumentSvg from '@/assets/icons/document.svg';

export default {
  name: 'TreeCategory',
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    nodes: {
      type: Array,
      default: () => [],
    },
    depth: {
      type: Number,
      default: 0,
    },
    showChildren: {
      type: Boolean,
    },
    hasChildren: {
      type: Boolean,
    },
  },
  data() {
    return {
      DocumentSvg,
      children: this.nodes,
      show: this.showChildren,
      formsList: [],
    };
  },
  computed: {
    ...mapState('brand', {
      forms: 'forms',
    }),
    ...mapGetters('brand', ['currentBrandId']),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    marginBase() {
      return this.mobileView ? 16 : 32;
    },
    indent() {
      return { marginLeft: `${this.depth * this.marginBase}px` };
    },
    iconName: function () {
      if (!this.nodes) {
        return 'document';
      } else {
        return this.show ? 'arrowDown' : 'arrowRight';
      }
    },
  },
  async mounted() {
    if (this.id) {
      const categoryId = this.id;

      const { data } = await this.fetchBrandCategoryForms({
        brandId: this.currentBrandId(this.$route),
        categoryId,
        type: 'report',
      });
      this.formsList = data?.results;
    }
  },
  methods: {
    ...mapActions('brand', [
      'fetchBrandCategoryForms',
      'fetchBrandSubCategory',
    ]),
    async toggleChildren(categoryId) {
      if (this.hasChildren) {
        const { data } = await this.fetchBrandSubCategory({
          brandId: this.currentBrandId(this.$route),
          categoryId,
        });
        this.children = data?.results;
      }
      this.show = !this.show;
    },
  },
};
</script>
