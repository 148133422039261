import { defineComponent, watch } from '@vue/composition-api';
import EntriesCounter from '@/components/Table/EntriesCounter.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
export default defineComponent({
    components: { EntriesCounter, Pagination },
    props: {
        path: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            default: 1,
        },
        limit: {
            type: Number,
            required: true,
        },
        pagination: {
            type: Object,
        },
        isLoading: Boolean,
        dataNotEmpty: Boolean,
        query: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, ctx) {
        watch(() => props.pagination, (value) => {
            if (value && props.value > value.lastPage) {
                ctx.emit('input', 1);
            }
        }, {
            deep: true,
            immediate: true,
        });
    },
});
