<template>
  <div class="w-10">
    <popover>
      <template #trigger="{ attrs, togglePopoverVisibility, isOpened }">
        <tooltip :disabled="isOpened" :label="$t('navbar-brand.lang')">
          <btn v-bind="attrs" theme="none" @click="togglePopoverVisibility">
            <icon :icon="mdiWeb" class="fill-gray-darkest" />
          </btn>
        </tooltip>
      </template>
      <showing-menu
        class="text-sm px-2 text-left"
        :items="actions"
        menu-class="min-w-12"
      />
    </popover>
  </div>
</template>

<script>
import { getSupportedLocales } from '@/util/i18n/supported-locales';
import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { mdiWeb } from '@mdi/js';
import { Popover } from '@/components/Popover';
import ShowingMenu from '@/components/EllipsisMenu/ShowingMenu.vue';

export default {
  components: { ShowingMenu, Popover, Btn, Icon, Tooltip },
  setup(props, ctx) {
    const locales = getSupportedLocales();
    let actions = {};

    locales.map((locale) => {
      actions[locale.code] = {
        name: locale.name,
        handler() {
          ctx.root.$i18n.locale = locale.code;
        },
      };
    });

    return {
      actions,
      mdiWeb,
    };
  },
};
</script>
<style lang="scss" scoped>
.locale-dropdown {
  @apply bg-white rounded shadow px-2 py-3 z-60 border border-gray-light;

  li {
    @apply cursor-pointer py-1;

    &.active {
      @apply text-primary;
    }
  }
}
</style>
