import { Btn } from '@/components/Btn';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { getFullName } from '@/util/user';
import { getUserAvatar } from '@/util/avatars';
import UsersModal from '@/views/Brand/Applications/Components/Recipients/UsersModal.vue';
import { addDecisionMaker, updateReportUsers, deleteDecisionMaker, removeUsersFromReport, } from '@/api/requests/reports';
import { ImageView, ImageSize } from '@/components/ImageView';
import { REPORT_USER_AVATARS_COUNT } from '@/constants/report';
import { useMobileView } from '@/composables';
import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
export default defineComponent({
    components: { UsersModal, Btn, ImageView },
    props: {
        reportUsers: {
            type: Array,
            required: true,
        },
        report: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const showModal = ref(false);
        const loading = ref(false);
        const usersUpdate = ref(0);
        const { isMobileView } = useMobileView();
        const removeUserFromReport = async (id) => {
            loading.value = true;
            try {
                await removeUsersFromReport(props.report.id, {
                    userIds: [id],
                });
                usersUpdate.value++;
                ctx.emit('users-updated');
            }
            finally {
                loading.value = false;
            }
        };
        const removeDecisionMakerFromReport = async (id) => {
            loading.value = true;
            try {
                await deleteDecisionMaker(props.report.id, id);
                ctx.emit('users-updated');
            }
            finally {
                loading.value = false;
            }
        };
        const addDecisionMakerToReport = async (id) => {
            loading.value = true;
            try {
                await addDecisionMaker(props.report.id, id);
                ctx.emit('users-updated');
            }
            finally {
                loading.value = false;
            }
        };
        const addUserToReport = async (id) => {
            loading.value = true;
            try {
                await updateReportUsers(props.report.id, { users: [{ userId: id }] });
                usersUpdate.value++;
                ctx.emit('users-updated');
            }
            finally {
                loading.value = false;
            }
        };
        const onClose = () => {
            showModal.value = false;
            if (usersUpdate.value)
                ctx.emit('users-updated');
        };
        const limitedReportUsers = computed(() => {
            if (props.reportUsers) {
                return props.reportUsers.slice(0, REPORT_USER_AVATARS_COUNT);
            }
            return [];
        });
        return {
            getUserAvatar,
            getFullName,
            showModal,
            loading,
            removeUserFromReport,
            removeDecisionMakerFromReport,
            addDecisionMakerToReport,
            addUserToReport,
            onClose,
            ImageSize,
            limitedReportUsers,
            isMobileView,
            RECIPIENTS_EVENTS,
        };
    },
});
