import { createNamespacedHelpers } from 'vuex-composition-helpers';
export const useMapState = () => {
    const { useActions, useGetters } = createNamespacedHelpers('map');
    const { setSetting } = useActions(['setSetting']);
    const { settings } = useGetters(['settings']);
    return {
        settings,
        setSetting,
    };
};
