import { defineComponent, watch } from '@vue/composition-api';
import { useRouteQuery } from '@/composables';
import EntriesCounter from '@/components/Table/EntriesCounter.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
export default defineComponent({
    components: { EntriesCounter, Pagination },
    props: {
        path: {
            type: String,
            required: true,
        },
        pagination: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        dataNotEmpty: {
            type: Boolean,
            default: false,
        },
        query: {
            type: Object,
            default: () => ({}),
        },
        showEntriesCounter: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, ctx) {
        const { changePage, activePage, limit, routeQuery } = useRouteQuery(ctx);
        watch(() => props.pagination, (value) => {
            if (value && activePage.value > value.lastPage) {
                changePage(1);
            }
        }, {
            deep: true,
            immediate: true,
        });
        return {
            changePage,
            activePage,
            limit,
            routeQuery,
        };
    },
});
