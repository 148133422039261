import api from '@/api';
import { getPropertyArray } from '@/util/formatters';

export default {
  namespaced: true,
  state: {
    currentOffer: null,
    objectsInCurrentOffer: [],
    objectsPagination: null,
    activeObjectId: null,
    objectsOfferId: null,
    placesToSelect: [],
    placesToRemove: [],
    objectsToSelect: [],
    objectsToRemove: [],
    objectsAddedInOffer: [],
    forceCheckboxUpdate: 0,
    offerList: [],
  },
  mutations: {
    SET_CURRENT_OFFER(state, { data }) {
      state.currentOffer = data;
    },
    SET_OBJECTS_IN_OFFER(state, { results, pagination }) {
      state.objectsInCurrentOffer = [
        ...state.objectsInCurrentOffer,
        ...results,
      ];
      state.objectsPagination = pagination;
    },
    SET_ACTIVE_OBJECT(state, id) {
      state.activeObjectId = id;
    },
    SET_OBJECTS_OFFER_ID(state, id) {
      state.objectsOfferId = id;
    },
    CLEAR_OBJECTS_IN_OFFER(state) {
      state.objectsInCurrentOffer = [];
      state.objectsPagination = null;
    },
    SET_OFFER_LIST(state, offers) {
      state.offerList = offers;
    },
  },
  getters: {
    activeObjectIndex: (state) => {
      return state.objectsInCurrentOffer.findIndex(
        ({ id }) => id === state.activeObjectId,
      );
    },
    lastObjectIndex: (state) => {
      return state.objectsInCurrentOffer.length - 1;
    },
    nextObjectId: (state, getters) => {
      const nextIndex = getters.activeObjectIndex + 1;

      if (nextIndex > getters.lastObjectIndex) return null;

      return state.objectsInCurrentOffer[nextIndex].id;
    },
    prevObjectId: (state, getters) => {
      const prevIndex = getters.activeObjectIndex - 1;

      if (prevIndex < 0) return null;

      return state.objectsInCurrentOffer[prevIndex].id;
    },
    activeOfferId: (state) => state.objectsOfferId,
    activeObject: (state, getters) => {
      return state.objectsInCurrentOffer[getters.activeObjectIndex];
    },
    selectedPlaceIds: (state) => getPropertyArray(state.placesToSelect, 'id'),
    removedPlaceIds: (state) => getPropertyArray(state.placesToRemove, 'id'),
    selectedObjectIds: (state) => getPropertyArray(state.objectsToSelect, 'id'),
    removedObjectIds: (state) => getPropertyArray(state.objectsToRemove, 'id'),
    offerObjectIds: (state) =>
      getPropertyArray(state.objectsAddedInOffer, 'id'),
    currentOfferIndex: (state) =>
      state.offerList.indexOf(state.currentOffer.id),
    nextOffer: (state, getters) => {
      if (
        !state.currentOffer ||
        [-1, state.offerList.length - 1].includes(getters.currentOfferIndex)
      )
        return null;

      return state.offerList[getters.currentOfferIndex + 1];
    },
    prevOffer: (state, getters) => {
      if (!state.currentOffer || [-1, 0].includes(getters.currentOfferIndex))
        return null;

      return state.offerList[getters.currentOfferIndex - 1];
    },
  },
  actions: {
    async fetchOffer({ commit }, offerId) {
      const offer = await api.get(`offers/${offerId}`);
      commit('SET_CURRENT_OFFER', offer);

      return offer;
    },
    editOffer(ctx, { id, data }) {
      return api.patch(`offers/${id}`, data);
    },
    addOffer(ctx, data) {
      return api.post(`offers`, data);
    },
    async fetchAllOfferObjects(
      { commit, state, dispatch },
      { offerId, brandId, page = 1 },
    ) {
      try {
        commit('SET_OBJECTS_OFFER_ID', offerId);

        const { data } = await api.get('objects', {
          params: {
            offerId,
            brandId,
            page,
          },
        });

        commit('SET_OBJECTS_IN_OFFER', data);

        const { to, total } = state.objectsPagination;

        if (total > to) {
          await dispatch('fetchAllOfferObjects', {
            offerId,
            brandId,
            page: page + 1,
          });
        }
      } catch {
        commit('CLEAR_OBJECTS_IN_OFFER');
      }
    },
    setActiveObject({ commit }, id) {
      commit('SET_ACTIVE_OBJECT', id);
    },
    clearState({ commit }) {
      commit('CLEAR_OBJECTS_IN_OFFER');
      commit('SET_ACTIVE_OBJECT', null);
      commit('SET_OBJECTS_OFFER_ID', null);
      commit('SET_CURRENT_OFFER', { data: null });
    },
    fetchOffers(ctx, params) {
      return api.get('offers', { params });
    },
    fetchObjectRelations(ctx, { params, offerId }) {
      return api.get(`/offers/${offerId}/object-relations`, { params });
    },
    fetchSingleObjectRelations(ctx, { offerId, objectId }) {
      return api.get(`/offers/${offerId}/object-relations/${objectId}`);
    },
    async acceptOffer(ctx, { relationId, type }) {
      await api.post(
        `/offers/object-relations/${relationId}/${type}/accept`,
        {},
      );
    },
    async rejectOffer(ctx, { relationId, type }) {
      await api.post(
        `/offers/object-relations/${relationId}/${type}/reject`,
        {},
      );
    },
    setOfferList({ commit }, offers) {
      commit('SET_OFFER_LIST', getPropertyArray(offers, 'id'));
    },
    clearOfferObjectsList({ commit }) {
      commit('CLEAR_OBJECTS_IN_OFFER');
    },
  },
};
