import cloneDeep from 'lodash.clonedeep';
const toggleEditedResource = (resource, currentValues) => {
    if (currentValues.removed.find(({ id }) => id === resource.id)) {
        return {
            ...currentValues,
            removed: currentValues.removed.filter(({ id }) => id !== resource.id),
        };
    }
    return {
        ...currentValues,
        removed: [...currentValues.removed, resource],
    };
};
const isResourceIncluded = (resource, list, compareKey) => {
    return !!list.find((selectedItem) => selectedItem[compareKey] === resource[compareKey]);
};
export const onSelectResource = (item, currentValues, isEdited) => {
    if (isEdited(item))
        return toggleEditedResource(item, currentValues);
    else if (isResourceIncluded(item, currentValues.selected, 'id')) {
        return {
            ...currentValues,
            selected: currentValues.selected.filter(({ id }) => id !== item.id),
        };
    }
    return {
        ...currentValues,
        selected: [...currentValues.selected, item],
    };
};
export const setStateForMultipleResources = (resources, currentValues, { select, isEdited }) => {
    const updatedState = cloneDeep(currentValues);
    resources.forEach((resource) => {
        if (isEdited(resource)) {
            if (select) {
                updatedState.removed = updatedState.removed.filter(({ id }) => resource.id !== id);
            }
            else if (!isResourceIncluded(resource, updatedState.removed, 'id')) {
                updatedState.removed.push(resource);
            }
        }
        else {
            if (!select) {
                updatedState.selected = updatedState.selected.filter(({ id }) => resource.id !== id);
            }
            else if (!isResourceIncluded(resource, updatedState.selected, 'id')) {
                updatedState.selected.push(resource);
            }
        }
    });
    return updatedState;
};
