import { defineComponent, computed } from '@vue/composition-api';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { BTN_LOADING_SPINNER_SIZE_MAP, BtnShape, BtnSize, } from '@/components/Btn/btn.constants';
export default defineComponent({
    name: 'Btn',
    components: {
        IconSpinner,
    },
    props: {
        to: Object,
        type: String,
        shape: {
            type: String,
            default: BtnShape.ROUNDED,
        },
        size: {
            type: String,
            default: BtnSize.MD,
        },
        icon: Boolean,
        theme: String,
        isLoading: Boolean,
    },
    setup(props) {
        const tag = computed(() => props.to === undefined ? 'button' : 'router-link');
        const parsedType = computed(() => {
            if (props.type) {
                return props.type;
            }
            if (props.to === undefined) {
                return 'button';
            }
            return undefined;
        });
        const btnClass = computed(() => {
            return [
                'btn',
                props.shape,
                props.size,
                { icon: props.icon },
                props.theme,
            ];
        });
        const spinnerSize = computed(() => BTN_LOADING_SPINNER_SIZE_MAP[props.size]);
        return { tag, parsedType, spinnerSize, btnClass };
    },
});
