import ChatMessage from '@/views/Brand/Applications/Report/Chat/ChatMessage/ChatMessage.vue';
import ChatDateDivider from '@/views/Brand/Applications/Report/Chat/ChatDateDivider.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { getReportMessages, } from '@/api/requests/reports';
import { isSameDay } from 'date-fns';
import InfiniteCursorData from '@/components/DataProvider/InfiniteCursorData.vue';
import { ListDirection } from '@/composables/use-infinity-scroll';
import { useInfiniteData } from '@/store/_composables/use-infinite-data';
import { CHAT_MESSAGES_PER_PAGE } from '@/views/Brand/Applications/Report/_constants';
import { AddActionRequiredResponseModal } from '@/views/Brand/Applications/Report/Details/_components/add-action-required-response-modal';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { useRequiredMessageViewportListener } from '@/views/Brand/Applications/Report/Chat/ChatMessageList/_composables/use-required-message-viewport-listener';
import { scrollToBottom } from '@/util/dom';
import debounce from 'lodash.debounce';
export default defineComponent({
    components: {
        AddActionRequiredResponseModal,
        InfiniteCursorData,
        ChatMessage,
        ChatDateDivider,
    },
    props: {
        report: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, ctx) {
        const { forwardCursor } = useInfiniteData();
        const showMessageDetailsModal = ref(false);
        const { update, updatesCounter } = useUpdatesCounter();
        const responseTargetMessage = ref(null);
        const isInitialAdditionalContentLoaded = ref(false);
        const { actionRequiredElement, scrollContainerElement } = useRequiredMessageViewportListener(ctx);
        const shouldDisplayDateDivider = (index) => {
            return (index === 0 ||
                !isSameDay(new Date(props.value[index - 1].createdAt), new Date(props.value[index].createdAt)));
        };
        const handleAddResponseMessage = async (message) => {
            // if there is no messages to fetch at the bottom of list append new message
            if (!forwardCursor.value) {
                return ctx.emit('input', [...props.value, message]);
            }
            // else refetch and set initial data TODO:
            const { results } = await getReportMessages(props.report.id, {
                direction: 'backward',
                perPage: CHAT_MESSAGES_PER_PAGE,
            });
            ctx.emit('input', results.reverse());
        };
        const shouldDisplayMessageAuthor = (index) => {
            return (index === 0 ||
                props.value[index - 1].author.id !== props.value[index].author.id ||
                shouldDisplayDateDivider(index));
        };
        const handleMessagesDataDeliver = ({ results, direction, }) => {
            const updatedMessages = direction === ListDirection.BACKWARD
                ? [...results, ...props.value]
                : [...props.value, ...results];
            ctx.emit('input', updatedMessages);
        };
        const scrollToChatBottom = debounce(() => {
            if (!scrollContainerElement.value) {
                return;
            }
            setTimeout(() => {
                scrollToBottom(scrollContainerElement.value?.$el);
            }, 0);
        }, 200);
        watch(() => props.value, (value, prevValue) => {
            // Scroll to container's bottom when new message is added
            if (value.length && prevValue.length) {
                const lastMessage = value[value.length - 1];
                const prevLastMessage = prevValue[prevValue.length - 1];
                if (lastMessage.id !== prevLastMessage.id) {
                    scrollToChatBottom();
                }
            }
        });
        const handleAddMessageResponse = (message) => {
            showMessageDetailsModal.value = true;
            responseTargetMessage.value = message;
        };
        const handleMessageResponseAdded = () => {
            ctx.emit('input', []);
            update();
        };
        const handleAdditionalContentLoaded = debounce(() => {
            if (isInitialAdditionalContentLoaded.value) {
                return;
            }
            scrollToChatBottom();
            isInitialAdditionalContentLoaded.value = true;
        }, 100);
        return {
            CHAT_MESSAGES_PER_PAGE,
            actionRequiredElement,
            updatesCounter,
            scrollContainerElement,
            handleAdditionalContentLoaded,
            handleAddResponseMessage,
            handleAddMessageResponse,
            handleMessagesDataDeliver,
            shouldDisplayDateDivider,
            shouldDisplayMessageAuthor,
            showMessageDetailsModal,
            responseTargetMessage,
            handleMessageResponseAdded,
            scrollToChatBottom,
        };
    },
});
