import { computed, defineComponent, ref } from '@vue/composition-api';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import ObjectDetailsModal from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/ObjectDetailsModal.vue';
export default defineComponent({
    components: {
        ObjectDetailsModal,
        Tooltip,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const showModal = ref(false);
        const activeObject = ref(null);
        const label = computed(() => `${props.object.address}, ${props.object.postalCode || ''} ${props.object.city}`);
        const handleObjectClick = (object) => {
            activeObject.value = object;
            showModal.value = true;
        };
        return {
            activeObject,
            handleObjectClick,
            label,
            showModal,
        };
    },
});
