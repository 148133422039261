import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Modal from '@/components/Modal/__new__/Modal.vue';
import Btn from '@/components/Button/Button.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBrand, useValidation } from '@/composables';
import { SelectInput } from '@/components/Inputs';
export default defineComponent({
    components: {
        ValidationObserver,
        ValidationProvider,
        Btn,
        Modal,
        SelectInput,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        useValidation(ctx);
        const { brand } = useBrand();
        const selectedOffer = ref(null);
        const offerFormRef = ref(null);
        const addOffer = () => {
            ctx.emit('input', selectedOffer.value);
            selectedOffer.value = null;
            if (offerFormRef.value)
                offerFormRef.value.reset();
        };
        const query = computed(() => ({
            brandId: brand.value?.id,
            reportId: 'null',
        }));
        return {
            query,
            selectedOffer,
            addOffer,
            offerFormRef,
        };
    },
});
