var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"toggle",class:_vm.theme},[(_vm.labelPosition === _vm.LabelPosition.LEFT)?_c('span',{staticClass:"toggle-label mr-4"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"toggle-input",class:[
      {
        disabled: _vm.disabled,
        error: _vm.error,
        'hover:border-gray-dark': !_vm.value && !_vm.error,
        selected: _vm.value,
      },
      _vm.size ]},[_c('input',{staticClass:"form-checkbox focus:shadow-none focus:border-gray-light",attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"change":function($event){return _vm.$emit('input', !_vm.value)}}}),_c('div',{staticClass:"circle",class:{ selected: _vm.value }})]),(_vm.labelPosition === _vm.LabelPosition.RIGHT)?_c('span',{staticClass:"toggle-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(Boolean(_vm.tooltipContent))?_c('tooltip',{attrs:{"label":_vm.tooltipContent}},[_c('icon',{staticClass:"text-primary ml-2",attrs:{"icon":_vm.mdiInformationOutline}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }