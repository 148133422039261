import { defineComponent, onUnmounted, watch } from '@vue/composition-api';
import api from '@/api';
import { ListDirection, useInfinityScroll, } from '@/composables/use-infinity-scroll';
import { useInfiniteData } from '@/store/_composables/use-infinite-data';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { useToggle } from '@/composables/use-toggle';
export default defineComponent({
    components: { LoadingSpinner },
    props: {
        url: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
            default: () => ({}),
        },
        updatesCounter: Number,
    },
    setup(props, ctx) {
        const { backwardCursor, isBackwardListCompleted, setIsBackwardListCompleted, isForwardListCompleted, setIsForwardListCompleted, setBackwardCursor, forwardCursor, setForwardCursor, } = useInfiniteData();
        const { state: isForwardListLoading, enable: enableForwardListLoader, disable: disableForwardListLoader, } = useToggle();
        const { state: isBackwardListLoading, enable: enableBackwardListLoader, disable: disableBackwardListLoader, } = useToggle();
        const { scrollContainer, triggers, scrollToBottom, persistScrollPosition } = useInfinityScroll();
        const fetchBackwardList = async () => {
            if (isBackwardListCompleted.value) {
                return;
            }
            enableBackwardListLoader();
            const { data } = await api.get(props.url, {
                params: {
                    ...props.query,
                    ...(backwardCursor.value && {
                        cursor: backwardCursor.value,
                    }),
                    direction: ListDirection.BACKWARD,
                },
            });
            persistScrollPosition();
            setBackwardCursor(data.nextPageCursor);
            ctx.emit('data-deliver', {
                results: data.results.reverse(),
                direction: ListDirection.BACKWARD,
            });
            disableBackwardListLoader();
            if (data.results.length < props.query.perPage) {
                setIsBackwardListCompleted(true);
            }
        };
        const fetchForwardList = async () => {
            if (isForwardListCompleted.value || !forwardCursor.value) {
                return;
            }
            enableForwardListLoader();
            const { data } = await api.get(props.url, {
                params: {
                    ...props.query,
                    ...(forwardCursor.value && {
                        cursor: forwardCursor.value,
                    }),
                    direction: ListDirection.FORWARD,
                },
            });
            persistScrollPosition();
            setForwardCursor(data.nextPageCursor);
            ctx.emit('data-deliver', {
                results: data.results,
                direction: ListDirection.FORWARD,
            });
            disableForwardListLoader();
            if (data.results.length < props.query.perPage) {
                setIsForwardListCompleted(true);
            }
        };
        watch(triggers.value.topTouches, fetchBackwardList);
        watch(triggers.value.bottomTouches, fetchForwardList);
        watch(() => props.updatesCounter, async () => {
            setIsBackwardListCompleted(false);
            await fetchBackwardList();
            scrollToBottom();
        }, { immediate: true });
        onUnmounted(() => {
            setIsBackwardListCompleted(false);
            setIsForwardListCompleted(false);
        });
        return {
            scrollContainer,
            isForwardListLoading,
            isBackwardListLoading,
        };
    },
});
