import { computed, defineComponent } from '@vue/composition-api';
import { SelectInput } from '@/components/Inputs';
import UserOption from '@/views/Brand/Applications/Components/Recipients/UserOption.vue';
import { useAuthUser, useBrand } from '@/composables';
import { DropdownPosition } from '@/components/Inputs/SelectInput/_components/SelectDropdown';
import { getFullName } from '@/util/user';
import { mdiMagnify } from '@mdi/js';
export default defineComponent({
    components: { UserOption, SelectInput },
    props: {
        value: {
            type: Object,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { reportId } = ctx.root.$route.params;
        const { authUserId } = useAuthUser();
        const usersQuery = computed(() => {
            return {
                ...(reportId && { reportId: reportId }),
                accessToBrandId: brand.value.id,
                omitUserIds: [authUserId.value],
            };
        });
        return {
            usersQuery,
            DropdownPosition,
            getFullName,
            mdiMagnify,
        };
    },
});
