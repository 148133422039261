import DashboardLayout from '@/layouts/DashboardLayout/DashboardLayout.vue';

import { ADMIN, SUPER_ADMIN } from '@/store/modules/user';
import { providePagination } from '@/util/routing';
import { PAGE_404 } from '@/router/routes/static.route';

export default [
  {
    path: '/dashboard',
    component: DashboardLayout,
    meta: {
      isAuthRequired: true,
      requiredRole: ADMIN,
    },
    redirect: PAGE_404,
    children: [
      {
        path: 'clients',
        name: 'Clients',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "Clients" */ '@/views/Dashboard/Clients/Clients.vue'
          ),
        meta: {
          contractorAccess: true,
        },
      },
      {
        path: 'clients/new',
        name: 'AddClient',
        component: () =>
          import(
            /* webpackChunkName: "AddClient" */ '@/views/Dashboard/Clients/AddClient.vue'
          ),
        meta: {
          requiredRole: ADMIN,
          matchName: 'Clients',
        },
      },
      {
        path: 'clients/:id/details',
        name: 'ClientDetails',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "ClientDetails" */ '@/views/Dashboard/Clients/ClientDetails.vue'
          ),
        meta: {
          matchName: 'Clients',
          contractorAccess: true,
        },
      },
      {
        path: 'clients/:id/edit',
        name: 'EditClient',
        component: () =>
          import(
            /* webpackChunkName: "EditClient" */ '@/views/Dashboard/Clients/EditClient.vue'
          ),
        meta: {
          matchName: 'Clients',
        },
      },
      {
        path: 'clients/:id/brands/new',
        name: 'AddBrand',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "AddBrand" */ '@/views/Dashboard/Brands/AddBrand.vue'
          ),
        meta: {
          matchName: 'Clients',
        },
      },
      {
        path: 'clients/:id/brands/:brandId/edit',
        name: 'EditBrand',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "EditBrand" */ '@/views/Dashboard/Brands/EditBrand.vue'
          ),
        meta: {
          matchName: 'Clients',
        },
      },
      {
        path: 'admins',
        name: 'Admins',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "Admins" */ '@/views/Dashboard/Admins/Admins.vue'
          ),
        meta: {
          requiredRole: SUPER_ADMIN,
        },
      },
      {
        path: 'admin/new',
        name: 'AddAdmin',
        component: () =>
          import(
            /* webpackChunkName: "AddAdmin" */ '@/views/Dashboard/Admins/AddAdmin.vue'
          ),
        meta: {
          requiredRole: SUPER_ADMIN,
          matchName: 'Admin',
        },
      },
      {
        path: 'admin/edit/:id',
        name: 'EditAdmin',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "EditAdmin" */ '@/views/Dashboard/Admins/EditAdmin.vue'
          ),
        meta: {
          requiredRole: SUPER_ADMIN,
          matchName: 'Admin',
        },
      },
      {
        path: 'object-types',
        name: 'GeneralObjectTypes',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "ObjectTypes" */ '@/views/Dashboard/ObjectTypes/ObjectTypes.vue'
          ),
      },
      {
        path: 'object-types/new',
        name: 'AddObjectType',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "AddObjectType" */ '@/views/Dashboard/ObjectTypes/AddObjectType.vue'
          ),
        meta: {
          matchName: 'GeneralObjectTypes',
          requiredRole: ADMIN,
        },
      },
      {
        path: 'object-types/:objectTypeId/edit',
        name: 'EditObjectType',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "EditObjectType" */ '@/views/Dashboard/ObjectTypes/EditObjectType.vue'
          ),
        meta: {
          matchName: 'GeneralObjectTypes',
          requiredRole: ADMIN,
        },
      },
      {
        path: 'contractors',
        name: 'Contractors',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "Contractors" */ '@/views/Dashboard/Contractors/Contractors.vue'
          ),
      },
      {
        path: 'contractors/new',
        name: 'AddContractor',
        component: () =>
          import(
            /* webpackChunkName: "AddContractor" */ '@/views/Dashboard/Contractors/AddContractor.vue'
          ),
        meta: {
          matchName: 'Contractors',
        },
      },
      {
        path: 'contractors/:contractorId/edit',
        name: 'EditContractor',
        component: () =>
          import(
            /* webpackChunkName: "EditContractor" */ '@/views/Dashboard/Contractors/EditContractor.vue'
          ),
        meta: {
          matchName: 'Contractors',
        },
      },
      {
        path: 'contractors/:contractorId/details',
        name: 'ContractorDetails',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "ContractorDetails" */ '@/views/Dashboard/Contractors/ContractorDetails.vue'
          ),
        meta: {
          matchName: 'Contractors',
        },
      },
      {
        path: 'contractors/:contractorId/users/new',
        name: 'AddContractorUser',
        component: () =>
          import(
            /* webpackChunkName: "AddContractorUser" */ '@/views/Dashboard/Contractors/AddContractorUser.vue'
          ),
        meta: {
          matchName: 'Contractors',
        },
      },
      {
        path: 'contractors/:contractorId/users/:userId/edit',
        name: 'EditContractorUser',
        component: () =>
          import(
            /* webpackChunkName: "EditContractorUser" */ '@/views/Dashboard/Contractors/EditContractorUser.vue'
          ),
        meta: {
          matchName: 'Contractors',
        },
      },
      {
        path: 'contractors/:contractorId/users/:userId/details',
        name: 'ContractorUserDetails',
        component: () =>
          import(
            /* webpackChunkName: "ContractorUserDetails" */ '@/views/Dashboard/Contractors/ContractorUserDetails.vue'
          ),
        meta: {
          matchName: 'Contractors',
        },
        props: true,
      },
    ],
  },
];
