import Vue from 'vue';
import { Icon } from '@/components/Icon';
import StarSvg from '@/assets/icons/star.svg';
import StarOutlineSvg from '@/assets/icons/star-outline.svg';
export default Vue.extend({
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        badge: {
            type: Boolean,
            default: false,
        },
    },
    components: { Icon },
    setup() {
        return {
            StarSvg,
            StarOutlineSvg,
        };
    },
});
