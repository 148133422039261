<template>
  <fetch-data v-slot="{ results, isLoading }" :url="url" :params="query">
    <div v-if="isLoading || loading" class="flex justify-center">
      <LoadingSpinner is-loading class="w-12 h-12" />
    </div>
    <template v-else>
      <div class="wrapper">
        <offer-object-card
          v-for="object in results"
          :key="object.id"
          :image="object.cdnImageUrl"
          :is-accepted="
            acceptedByCentral(object) || acceptedObjects.includes(object.id)
          "
          :route="{
            name: 'ObjectInOfferDetails',
            params: {
              objectId: object.id,
              offerId: currentOffer.id,
            },
          }"
          :number="object.internalNumber"
          :address="object.address"
          :city="object.city"
          class="card"
        />
      </div>
    </template>
  </fetch-data>
</template>

<script>
import FetchData from '@/components/DataProvider/FetchData';
import isEmpty from 'lodash.isempty';
import { mapGetters, mapState } from 'vuex';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import OfferObjectCard from '@/views/Brand/Applications/Components/Offers/OfferObjectCard';
import { OfferObjectStatus } from '@/api/requests/offers';

export default {
  components: { OfferObjectCard, LoadingSpinner, FetchData },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    acceptedObjects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activePage: 1,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    ...mapState('offer', ['currentOffer']),
    query() {
      return {
        offerId: this.currentOffer.id,
        limit: 20,
        page: this.activePage,
      };
    },
    url() {
      return `objects`;
    },
  },
  methods: {
    isEmpty,
    changePage(page) {
      this.activePage = page;
    },
    acceptedByCentral(object) {
      return (
        object.offerRelation?.centralStatus?.status ===
        OfferObjectStatus.ACCEPTED
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @apply flex w-full flex-wrap;
}

.card {
  @apply flex-shrink-0 mr-4 mt-4;
}
</style>
