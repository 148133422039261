var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"label",class:[_vm.theme, { 'table-header': _vm.tableHeader }]},[_c('input',{staticClass:"form-checkbox focus:shadow-none focus:border-gray-light cursor-pointer",class:[
      (_vm.theme + "-input"),
      _vm.size,
      {
        disabled: _vm.disabled,
        error: _vm.error,
        'hover:border-gray-dark': !_vm.value && !_vm.error,
        table: _vm.table,
        indeterminate: _vm.indeterminate,
        checked: _vm.value,
        loading: _vm.loading,
        'mr-3': !_vm.withoutSpacing,
      } ],attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"change":function($event){return _vm.$emit('input', !_vm.value)}}}),_vm._t("default"),(_vm.loading)?_c('loading-spinner',{staticClass:"loader",attrs:{"icon-class-names":"stroke-gray-darkest","is-loading":"","class-names":"h-3 w-3"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }