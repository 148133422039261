var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [(_vm.brand)?_c('form',{ref:"form",staticClass:"form py-1 pb-48",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('image-uploader',{staticClass:"mt-6",class:{ fill: _vm.imageUrl },attrs:{"label":_vm.$t('place-form.logo-label')},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}}),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.place-number'),"placeholder":_vm.$t('place-form.place-number-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}})]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.place-name'),"placeholder":_vm.$t('place-form.place-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{staticClass:"my-4",attrs:{"id":"placeStructure","url":"structures","query":{ brandId: _vm.brand.id, hasChildren: false },"label":_vm.$t('place-form.structure'),"placeholder":_vm.$t('place-form.structure-placeholder'),"error-message":errors[0],"option-value-name":"id","option-label-name":"name","required":"","autocomplete":""},model:{value:(_vm.selectedStructure),callback:function ($$v) {_vm.selectedStructure=$$v},expression:"selectedStructure"}})]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.address'),"placeholder":_vm.$t('place-form.address-placeholder-1'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)})],1),_c('div',{staticClass:"flex -mt-1"},[_c('div',{staticClass:"input w-1/4 mr-4"},[_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.postalCodeRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"placeholder":_vm.$t('place-form.address-placeholder-2'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.postalCode),callback:function ($$v) {_vm.$set(_vm.form, "postalCode", $$v)},expression:"form.postalCode"}})]}}],null,true)})],1),_c('div',{staticClass:"input w-3/4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"placeholder":_vm.$t('place-form.address-placeholder-3'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-end -mt-1"},[_c('div',{staticClass:"input w-1/2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.gps'),"helper-label":("(" + (_vm.$t('place-form.gps-helper-text')) + ")"),"placeholder":_vm.$t('place-form.lat-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"type":"number","min":"-90","max":"90","step":"any","required":"","debounce":""},on:{"focus":function($event){return _vm.$emit('set-tab', _vm.COORDINATES_TAB)},"input":_vm.emitPlaceCoordinates},model:{value:(_vm.form.lat),callback:function ($$v) {_vm.$set(_vm.form, "lat", $$v)},expression:"form.lat"}})]}}],null,true)})],1),_c('div',{staticClass:"input w-1/2 ml-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"placeholder":_vm.$t('place-form.lng-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"type":"number","min":"-180","max":"180","step":"any","required":"","debounce":""},on:{"focus":function($event){return _vm.$emit('set-tab', _vm.COORDINATES_TAB)},"input":_vm.emitPlaceCoordinates},model:{value:(_vm.form.lng),callback:function ($$v) {_vm.$set(_vm.form, "lng", $$v)},expression:"form.lng"}})]}}],null,true)})],1)]),_c('div',{staticClass:"input"},[_c('div',{staticClass:"flex mt-4"},[_c('select-input',{attrs:{"id":"place-tags","query":{ relation: 'place', brandId: _vm.brand.id },"url":"tags","label":_vm.$t('place-preview.tags'),"placeholder":_vm.$t('place-preview.tags-placeholder'),"option-label-name":"name","option-value-name":"id","autocomplete":"","multiple":"","clearable":""},on:{"enter":_vm.handleEnter},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)]),_c('div',{staticClass:"flex items-end"},[_c('div',{staticClass:"w-3/4"},[_c('select-input',{key:_vm.usersListKey,staticClass:"w-3/4 my-4",attrs:{"id":"placeAdmin","url":"users","query":{ brandId: _vm.brand.id },"label":_vm.$t('place-form.place-manager'),"placeholder":_vm.$t('place-form.place-manager-placeholder'),"option-value-name":"id","clearable":"","autocomplete":"","no-margin":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [_c('span',[_vm._v(_vm._s(selection.firstName)+" "+_vm._s(selection.lastName))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName))])]}}],null,true),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('div',{staticClass:"w-1/4 pl-4"},[_c('btn',{staticClass:"w-full mb-4",attrs:{"theme":"secondary","type":"button"},on:{"click":function($event){return _vm.$emit('open-user-form')}}},[_vm._v(" "+_vm._s(_vm.$t('place-form.add-new'))+" ")])],1)]),_c('div',{staticClass:"input"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.place-concept'),"placeholder":_vm.$t('place-form.place-concept-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading},model:{value:(_vm.form.concept),callback:function ($$v) {_vm.$set(_vm.form, "concept", $$v)},expression:"form.concept"}})]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.place-type'),"placeholder":_vm.$t('place-form.place-type-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}],null,true)})],1),_c('div',{staticClass:"input"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('place-form.place-size'),"placeholder":_vm.$t('place-form.place-size-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading},model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}})]}}],null,true)})],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }