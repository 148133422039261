import {
  actions,
  expand,
  select,
  status,
} from '@/components/Table/TableGrid/mixins';
import { TABLE_STYLE } from '@/constants/ui';

export const tableProps = {
  rows: {
    type: Array,
    default() {
      return [];
    },
  },
  cols: {
    type: Object,
    default: () => ({}),
  },
  loaderVisible: {
    type: Boolean,
    default: false,
  },
  withThumbs: {
    type: Boolean,
    default: false,
  },
  thumbKey: {
    type: String,
    default: 'imageUrl',
  },
  thumbCdn: {
    type: [Number, Boolean],
    default: false,
  },
  hideLabels: {
    type: Boolean,
    default: false,
  },
  mobileCols: {
    type: [Number, Array],
    default: 2,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  containImg: {
    type: Boolean,
    default: false,
  },
  rowFormatter: {
    type: Function,
    default: null,
  },
  noDataTextKey: {
    type: String,
    default: 'table-grid.no-data',
  },
  dashboard: {
    type: Boolean,
    default: false,
  },
  showSum: {
    type: Boolean,
    default: false,
  },
  sum: {
    type: Number,
    default: 0,
  },
  isChecked: {
    type: Boolean,
    default: false,
  },
  costTable: {
    type: Boolean,
    default: false,
  },
  // TODO: set default and remove redundant style once we migrate all views to new design
  tableStyle: {
    type: String,
    validate: (value) => Object.values(TABLE_STYLE).includes(value),
  },
};

export default {
  props: {
    ...select.props,
    ...actions.props,
    ...expand.props,
    ...status.props,
    ...tableProps,
  },
  computed: {
    props() {
      const props = {};

      Object.keys({
        ...tableProps,
        ...select.props,
        ...actions.props,
        ...expand.props,
        ...status.props,
      }).forEach((key) => (props[key] = this.$props[key]));

      return props;
    },
  },
};
