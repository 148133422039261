var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMobileView)?_c('bottom-menu',{attrs:{"show":_vm.showMobileMenu,"items":_vm.mobileMessageMenuItems},on:{"close":function($event){return _vm.$emit(_vm.Emit.CLOSE_MOBILE_MESSAGE_MENU)}}}):_c('span',{staticClass:"more-button-area"},[_c('popover',{attrs:{"offset":"-50, 10"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var attrs = ref.attrs;
var togglePopoverVisibility = ref.togglePopoverVisibility;
var isOpened = ref.isOpened;
return [(_vm.isHovered || isOpened)?_c('btn',_vm._b({staticClass:"border border-gray-light bg-white",attrs:{"theme":_vm.BtnTheme.NONE,"size":"xs"},on:{"click":function($event){return togglePopoverVisibility()}}},'btn',attrs,false),[_c('icon',{attrs:{"icon":_vm.mdiDotsVertical}})],1):_vm._e()]}}])},[_c('ol',{staticClass:"tooltip-area"},[_c('li',{staticClass:"tooltip-item hover:bg-gray-lighter",on:{"click":function($event){return _vm.$emit(_vm.Emit.ON_EDIT)}}},[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]),(!_vm.disableDelete)?_c('li',{staticClass:"tooltip-item hover:bg-gray-lighter",on:{"click":function($event){return _vm.$emit(_vm.Emit.DELETE_MESSAGE)}}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]):_vm._e(),(_vm.isRequiredActionMessage)?_c('li',{staticClass:"tooltip-item hover:bg-gray-lighter",on:{"click":function($event){return _vm.$emit(_vm.Emit.DELETE_REQUIRED_ACTION)}}},[_vm._v(" "+_vm._s(_vm.$t('report.remove-required-action'))+" ")]):(_vm.isAuthorizedToCreateActionRequired)?_c('li',{staticClass:"tooltip-item hover:bg-gray-lighter",class:{
            'text-gray-light cursor-not-allowed': _vm.hasRequiredActionMessage,
          },on:{"click":function($event){_vm.hasRequiredActionMessage
              ? function () {}
              : _vm.$emit(_vm.Emit.CREATE_REQUIRED_ACTION)}}},[_vm._v(" "+_vm._s(_vm.$t('report.action-required'))+" ")]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }