import BrandHeader from './Components/BrandHeader.vue';
import { NAVY } from '@/themes';
import { fetchBrandData } from '@/views/Brand/util';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import BrandLogo from '@/layouts/BrandLayout/Components/BrandLogo.vue';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar.vue';
import { computed, defineComponent, onUnmounted, ref, watch, } from '@vue/composition-api';
import { useMobileView, useRouteQuery, useSidebar, useUi } from '@/composables';
import { PortalTarget } from '@/constants/portal';
import { ClientRouteName } from '@/router/router.constants';
import { isRouteName } from '@/router/utils';
export default defineComponent({
    name: 'BrandLayout',
    components: {
        BrandHeader,
        LoadingSpinner,
        BrandLogo,
        OverlapSidebar,
    },
    async beforeRouteEnter(to, from, next) {
        await fetchBrandData(to);
        next();
    },
    async beforeRouteUpdate(to, from, next) {
        await fetchBrandData(to);
        next();
    },
    setup(_, ctx) {
        const { offset, sidebar, changeTheme, toggleSidebar, sideMenu, toggleSideMenu, } = useUi();
        const { isMobileView } = useMobileView();
        const { loading } = useRouteQuery(ctx);
        const { showSidebar } = useSidebar(ctx);
        const sidebarRef = ref(null);
        const isReportList = computed(() => isRouteName(ctx, ClientRouteName.REPORTS));
        const isReportDetails = computed(() => isRouteName(ctx, ClientRouteName.REPORT_DETAILS));
        watch(isMobileView, () => {
            if (showSidebar) {
                toggleSidebar();
            }
        });
        onUnmounted(() => {
            changeTheme(NAVY);
        });
        return {
            sidebarRef,
            offset,
            sidebar,
            sideMenu,
            changeTheme,
            toggleSidebar,
            toggleSideMenu,
            isMobileView,
            loading,
            showSidebar,
            PortalTarget,
            isReportList,
            isReportDetails,
        };
    },
});
