import api from '@/api';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    uploadQueue: [],
    deleteQueue: [],
    attachments: {},
  },
  mutations: {
    ADD_UPLOAD_QUEUE(state, payload) {
      state.uploadQueue.unshift(payload);
    },
    SET_UPLOAD_QUEUE(state, payload) {
      state.uploadQueue = payload;
    },
    REMOVE_UPLOAD_QUEUE(state, payload) {
      state.uploadQueue = state.uploadQueue.filter((el) => el.id !== payload);
    },
    ADD_DELETE_QUEUE(state, payload) {
      state.deleteQueue.push(payload);
    },
    REMOVE_DELETE_QUEUE(state, payload) {
      const index = state.deleteQueue.indexOf(payload);
      if (index > -1) {
        state.deleteQueue.splice(index, 1);
      }
    },
    SET_DELETE_QUEUE(state, payload) {
      state.deleteQueue = payload;
    },
    SET_ATTACHMENTS(state, { type, attachments }) {
      Vue.set(
        state.attachments,
        type,
        attachments.data.length === 0
          ? attachments.data
          : attachments.data.results,
      );
    },
    CLEAR_ATTACHMENTS(state) {
      state.attachments = {};
    },
  },
  getters: {
    uploads: (state) => (fileType) => {
      return [
        ...state.uploadQueue.filter(({ type }) => type === fileType),
        ...((state.attachments && state.attachments[fileType]) || []),
      ];
    },
    computedAttachments: (state, getters) => (fileType) => {
      return getters
        .uploads(fileType)
        .filter((file) => !state.deleteQueue.includes(file.id));
    },
  },
  actions: {
    async addToUploadQueue({ commit }, { target, params }) {
      const createdDate = new Date();
      commit('ADD_UPLOAD_QUEUE', {
        target,
        createdAt: createdDate,
        id: createdDate.toString(),
        ...params,
      });
    },
    removeFromUploadQueue({ commit }, id) {
      commit('REMOVE_UPLOAD_QUEUE', id);
    },
    async addToDeleteQueue({ commit }, { id }) {
      commit('ADD_DELETE_QUEUE', id);
    },
    removeFromDeleteQueue({ commit }, id) {
      commit('REMOVE_DELETE_QUEUE', id);
    },
    async fetchAttachments({ commit }, { target, id, type, limit = 100 }) {
      commit('SET_UPLOAD_QUEUE', []);
      commit('SET_DELETE_QUEUE', []);

      commit('SET_ATTACHMENTS', {
        type,
        attachments: await api.get(`${target}/${id}/attachments`, {
          params: { type, limit },
        }),
      });
    },
    clearAttachmentList({ commit }) {
      commit('CLEAR_ATTACHMENTS');
    },
    clearUploadQueue({ commit }) {
      commit('SET_UPLOAD_QUEUE', []);
    },
    clearDeleteQueue({ commit }) {
      commit('SET_DELETE_QUEUE', []);
    },
    saveAttachments({ state }, { id }) {
      return Promise.all(
        state.uploadQueue.map((el) =>
          api.post(`${el.target}/${id}/attachments`, {
            name: el.name,
            type: el.type,
            url: el.url,
          }),
        ),
      );
    },
    async deleteAttachments({ dispatch, state }) {
      await Promise.all(
        state.deleteQueue.map((id) => {
          return dispatch('deleteAttachment', id);
        }),
      );
    },
    async addAttachment(ctx, { target, id, params }) {
      await api.post(`${target}/${id}/attachments`, params);
    },
    async deleteAttachment(ctx, id) {
      await api.delete(`attachments/${id}`);
    },
    async deleteReportAttachments({ dispatch, state }, { id: reportId }) {
      await Promise.all(
        state.deleteQueue.map((attachmentId) => {
          return dispatch('deleteReportAttachment', { attachmentId, reportId });
        }),
      );
    },
    async deleteReportAttachment(ctx, { attachmentId, reportId }) {
      await api.delete(`reports/${reportId}/attachments/${attachmentId}`);
    },
  },
};
