<template>
  <div class="container-fluid">
    <div class="md:w-1/2 pt-8 pb-64 px-4 md:px-12">
      <breadcrumbs
        :path="[
          { name: $t('edit-brand-user-view.users'), url: { name: 'Users' } },
          { name: brand ? brand.name : '', url: brandRoute },
          { name: $t('edit-brand-user-view.edit-user') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <user-form
        id="brandUserForm"
        :is-loading="submissionInProgress"
        :user="brandUser"
        @submit="handleSubmission"
      />
    </div>
    <footer-menu class="bg-white">
      <div class="flex justify-between">
        <div class="flex">
          <modal-wrapper :activate="modal" :content="modalContent">
            <btn
              :is-loading="submissionInProgress"
              class="w-48 mr-3"
              type="submit"
              form="brandUserForm"
            >
              {{ $t('common.save') }}
            </btn>
            <template #modalContent>
              <ol class="list-decimal mt-1">
                <li
                  v-for="item in oldItemsSets[brandUser.role]"
                  :key="item.id"
                  class="mt-1"
                >
                  {{ item.name }}
                  <b v-if="wasPlaceAdmin">({{ item.number }})</b>
                </li>
              </ol>
              <p class="mt-2">
                {{ $t('edit-brand-user-view.modal.continue') }}
              </p>
            </template>
          </modal-wrapper>
        </div>
        <btn theme="none" @click="$router.push(lastRoute)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import UserForm from '@/views/Brand/Users/Forms/UserForm.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';
import { PLACE_ADMIN, STRUCTURE_ADMIN } from '@/store/modules/user';
import difference from 'lodash.difference';
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';
import { updateClientUser } from '@/api/requests/users';

export default {
  name: 'EditUser',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    UserForm,
    ModalWrapper,
  },
  data() {
    return {
      submissionInProgress: false,
      submissionEndSuccessfully: false,
      lastRoute: null,
      modal: null,
      oldItemsSets: {},
      needConfirmation: false,
      PLACE_ADMIN,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      brandUser: 'user',
    }),
    brandRoute() {
      if (this.brand) return { name: 'Users', params: { id: this.brand.id } };
      else return {};
    },

    wasPlaceAdmin() {
      return this.brandUser.role === PLACE_ADMIN;
    },
    modalContent() {
      return {
        ...(this.modal && {
          type: 'yesNo',
          title: this.$t('edit-brand-user-view.modal.heading'),
          description: this.wasPlaceAdmin
            ? this.$t('edit-brand-user-view.modal.text-places')
            : this.$t('edit-brand-user-view.modal.text-structures'),
          ...this.modal,
        }),
      };
    },
  },
  watch: {
    brandUser: {
      handler(data) {
        if (data) {
          this.oldItemsSets[PLACE_ADMIN] = [...data.places];
          this.oldItemsSets[STRUCTURE_ADMIN] = [...data.structures];
        }
      },
      immediate: true,
    },
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('brand/fetchBrandUserDetails', to.params['userId']);
    next((vm) => {
      vm.lastRoute = from.path !== '/' ? from : { name: 'Users' };
    });
  },
  methods: {
    ...mapActions('brand', ['updateBrandUser', 'updateBrandPlace']),
    ...mapActions('structures', ['updateStructureUser']),
    async isConfirmationNeeded(condition, oldRole) {
      if (condition && this.oldItemsSets[oldRole].length) {
        return new Promise((resolve, reject) => {
          this.modal = {
            btns: {
              yes: resolve,
              no: reject,
            },
          };
        });
      }
      return Promise.resolve();
    },
    getPlacePayload(place, userId) {
      return {
        placeId: place.id,
        data: place.toDelete ? { userId: null } : { userId: userId },
      };
    },
    getStructurePayload(structure, userId) {
      return {
        id: structure.id,
        userId: structure.toDelete ? null : userId,
      };
    },
    getRoleActions(role) {
      switch (role) {
        case PLACE_ADMIN:
          return {
            getPayload: this.getPlacePayload,
            update: this.updateBrandPlace,
            set: 'places',
          };
        case STRUCTURE_ADMIN:
          return {
            getPayload: this.getStructurePayload,
            update: this.updateStructureUser,
            set: 'structures',
          };
        default:
          return {};
      }
    },
    async handleSubmission(formData) {
      const placeOrStructureAdminChanged =
        formData.role !== this.brandUser.role &&
        [PLACE_ADMIN, STRUCTURE_ADMIN].includes(this.brandUser.role);
      this.isConfirmationNeeded(
        placeOrStructureAdminChanged,
        this.brandUser.role,
      ).then(
        async () => {
          this.modal = null;
          this.submissionInProgress = true;
          try {
            await updateClientUser(this.brandUser.id, formData);

            this.$toast.success(
              this.$t('edit-brand-user-view.updated-successfully'),
            );
            await this.$router.push(this.brandRoute);

            this.submissionEndSuccessfully = true;
          } finally {
            this.submissionInProgress = false;
          }
        },
        () => {
          this.modal = null;
        },
      );
    },
    calculateObjects(newSet, oldSet) {
      const newObjects = difference(newSet, oldSet);
      let objectsToDelete = difference(oldSet, newSet);
      objectsToDelete = objectsToDelete.map((obj) => ({
        ...obj,
        toDelete: true,
      }));
      return [...newObjects, ...objectsToDelete];
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
