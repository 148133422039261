export var ReportTab;
(function (ReportTab) {
    ReportTab["REPORTS_LIST"] = "reports-list";
    ReportTab["ACTION_REQUIRED"] = "action-required";
})(ReportTab || (ReportTab = {}));
export const REPORTS_TAB_NAME = 'reports';
export const reportTabIndexMap = {
    [ReportTab.REPORTS_LIST]: 0,
    [ReportTab.ACTION_REQUIRED]: 1,
};
