<template>
  <expandable-row-preview
    :id="objectId"
    :url="`objects/${objectId}`"
    :force-update="forceUpdateKey"
    :sidebar="sidebar"
    :hide-details-btn="hideDetailsBtn"
    :get-title="getAddress"
    @data-deliver="tags = $event.tags"
    @open-details="onOpenDetails"
  >
    <template #actions="{ data }">
      <create-report-with-object-button
        :object="data"
        class="mt-2 md:ml-4 md:mt-0"
        :theme="BtnTheme.PRIMARY_OUTLINE"
      />
    </template>
    <template #details="{ data }">
      <div class="md:flex flex-wrap">
        <ul class="md:pr-6 lg:pr-10">
          <li class="info">
            <label>{{ $t(`object-preview.internal-number`) }}:</label>
            <p>{{ data.internalNumber }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`object-preview.place`) }}:</label>
            <p>
              <router-link
                :to="{
                  name: 'PlaceDetails',
                  params: { placeId: data.place.id },
                }"
                class="text-primary"
              >
                {{ data.place.number }}
              </router-link>
            </p>
          </li>
          <li class="info">
            <label>{{ $t(`object-preview.coordinates`) }}:</label>
            <p>{{ data.lat }}, {{ data.lng }}</p>
          </li>
          <li class="info">
            <label>{{ $t(`object-preview.monthly-price`) }}:</label>
            <p>{{ data.monthlyPrice }} PLN</p>
          </li>
          <li class="info">
            <label>{{ $t(`object-preview.contractor`) }}:</label>
            <p>
              {{ getContractors(data) }}
            </p>
          </li>
        </ul>
        <div v-if="data.formVersion">
          <custom-form-preview
            :components="data.formVersion.components"
            :values="data.values"
            :max-fields="5"
            :format-label="(label) => `${label}:`"
            item-class-name="w-full text-sm my-1 sm:flex"
            label-class-name="text-gray-dark font-normal w-32 pr-2 break-words flex-shrink-0 overflow-hidden text-ellipsis"
            value-class-name="font-semibold"
          />
        </div>
        <div class="info mt-6">
          <label>{{ $t(`object-preview.video`) }}:</label>
          <p>
            <a
              v-if="data.videoUrl"
              target="blank"
              :href="data.videoUrl"
              class="text-blue-700"
            >
              {{ data.videoUrl }}
            </a>
            <span v-else>-</span>
          </p>
        </div>
      </div>
    </template>
    <template #default>
      <div v-if="!sidebar" class="flex w-1/2">
        <select-input
          id="object-tags"
          v-model="tags"
          :query="{ brandId, relation: 'object' }"
          url="tags"
          option-label-name="name"
          option-value-name="id"
          :placeholder="$t('place-preview.tags-placeholder')"
          class="mt-4"
          autocomplete
          multiple
          clearable
          @input="handleTagsUpdate"
          @enter="handleEnter"
        />
      </div>
    </template>
  </expandable-row-preview>
</template>
<script>
import { convertDMS, link } from '@/util/formatters';
import { mapActions, mapState } from 'vuex';
import manageTagsMixin from '@/mixins/manageTagsMixin';
import ExpandableRowPreview from '@/components/Table/ExpandableRowPreview';
import { CustomFormPreview } from '@/views/_components/CustomForms';
import { SelectInput } from '@/components/Inputs';
import difference from 'lodash.difference';
import CreateReportWithObjectButton from '@/views/Brand/Objects/_components/create-report-with-object-button/create-report-with-object-button.vue';
import { BtnTheme } from '@/components/Btn';

export default {
  components: {
    CreateReportWithObjectButton,
    ExpandableRowPreview,
    CustomFormPreview,
    SelectInput,
  },
  props: {
    objectTypeId: {
      type: String,
      default: '',
    },
    objectId: {
      type: String,
      default: '',
    },
    hideDetailsBtn: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [manageTagsMixin],
  data() {
    return {
      forceUpdateKey: 0,
      allTags: [],
    };
  },
  computed: {
    ...mapState('user', {
      userBrand: 'brand',
    }),
    brandId() {
      return this.$route.params.id || this.userBrand;
    },
  },
  methods: {
    ...mapActions('tags', ['addObjectTag', 'deleteObjectTag']),
    convertDMS,
    link,
    getAddress(data) {
      const { postalCode, city, address } = data;

      return `${address}, ${postalCode || ''} ${city}`;
    },
    getContractors(data) {
      return data.contractors?.length
        ? data.contractors.map(({ name }) => name).join(', ')
        : '-';
    },
    onAddTag(tag, updateTags) {
      this.saveTag(
        tag,
        (tagName) => this.addObjectTag({ objectId: this.objectId, tagName }),
        updateTags,
      );
    },
    onDeleteTag(tag) {
      this.removeTag(tag, (tagId) =>
        this.deleteObjectTag({ objectId: this.objectId, tagId }),
      );
    },
    onOpenDetails() {
      this.$router.push({
        name: 'ObjectDetails',
        params: { objectId: this.objectId, objectTypeId: this.objectTypeId },
      });
    },
    handleTagsUpdate(event) {
      const newTags = difference(event, this.allTags);
      const removedTags = difference(this.allTags, event);
      newTags.forEach((tag) => {
        this.onAddTag(tag, false);
      });
      removedTags.forEach((tag) => {
        this.onDeleteTag(tag);
      });
    },
    handleEnter(value) {
      this.createNewTag(value, () => {
        this.onAddTag({ name: value.enteredValue }, true);
      });
    },
  },
  watch: {
    tags: {
      immediate: true,
      handler(newTags) {
        if (newTags) {
          this.allTags = newTags;
        }
      },
    },
  },
  setup() {
    return { BtnTheme };
  },
};
</script>
<style scoped lang="scss">
.info {
  @apply w-full text-sm my-1;

  @screen sm {
    @apply flex;
  }
  label {
    @apply text-gray-dark font-normal w-32 pr-2 break-words flex-shrink-0;
  }
  p {
    @apply font-semibold;
  }
}
</style>
