import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button.vue';
import { ImageView, ImageSize } from '@/components/ImageView';
import EditSvg from '@/assets/icons/edit.svg';
import { mdiClose } from '@mdi/js';
import { useAuthUser } from '@/composables';
import { getSinglePlace } from '@/api/requests/places';
import { onMounted, ref } from '@vue/composition-api/dist/vue-composition-api';
import { defineComponent } from '@vue/composition-api';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
export default defineComponent({
    components: {
        Btn,
        Icon,
        ImageView,
        LoadingSpinner,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        hidePreviewButton: Boolean,
    },
    setup(props) {
        const { isAdmin } = useAuthUser();
        const place = ref(null);
        const isLoading = ref(true);
        onMounted(async () => {
            place.value = await getSinglePlace(props.id);
            isLoading.value = false;
        });
        return { EditSvg, ImageSize, mdiClose, isAdmin, place, isLoading };
    },
});
