var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white md:px-4 py-8 pb-4 rounded-2 mb-2"},[_c('div',{staticClass:"max-h-132 overflow-y-auto",attrs:{"id":"cost-table"}},[_c('infinite-data',{key:_vm.updateKey,attrs:{"url":_vm.url,"query":_vm.objectQuery,"container-selector":"#cost-table"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('table-display',{attrs:{"columns":_vm.columns,"rows":items,"table-style":_vm.TableStyle.Flat,"to":_vm.getObjectRoute},on:{"mouse-enter-row":function($event){return _vm.$emit('mouse-enter-row', $event)},"mouse-leave-row":function($event){return _vm.$emit('mouse-leave-row', $event)}},scopedSlots:_vm._u([{key:"centralHeader",fn:function(){return [_c('span',{staticClass:"block text-center"},[_vm._v(" "+_vm._s(_vm.$t('offer-costs.central'))+" ")])]},proxy:true},{key:"structureHeader",fn:function(){return [_c('span',{staticClass:"block text-center"},[_vm._v(" "+_vm._s(_vm.$t('offer-costs.structure'))+" ")])]},proxy:true},{key:"placeHeader",fn:function(){return [_c('span',{staticClass:"block text-center"},[_vm._v(" "+_vm._s(_vm.$t('offer-costs.place'))+" ")])]},proxy:true},{key:"centralStatusCell",fn:function(ref){
var row = ref.row;
return [_c('object-accept-cell',{attrs:{"level":_vm.AcceptanceLevel.CENTRAL,"object-relation":row.offerRelation,"offer-id":_vm.currentOffer.id},on:{"update-relation":function($event){return _vm.onRelationUpdate(true)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})]}},{key:"structureStatusCell",fn:function(ref){
var row = ref.row;
return [_c('object-accept-cell',{attrs:{"level":_vm.AcceptanceLevel.STRUCTURE,"object-relation":row.offerRelation,"offer-id":_vm.currentOffer.id},on:{"update-relation":function($event){return _vm.onRelationUpdate(false)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})]}},{key:"placeStatusCell",fn:function(ref){
var row = ref.row;
return [_c('object-accept-cell',{attrs:{"level":_vm.AcceptanceLevel.PLACE,"object-relation":row.offerRelation,"offer-id":_vm.currentOffer.id},on:{"update-relation":function($event){return _vm.onRelationUpdate(false)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})]}}],null,true)})]}}])})],1),_c('div',{staticClass:"text-right w-full p-4"},[_vm._v(" "+_vm._s(_vm.$t('offer-costs.net-value'))+": "),_c('span',{staticClass:"font-bold ml-4"},[_vm._v(_vm._s(_vm.formattedSum))])])])}
var staticRenderFns = []

export { render, staticRenderFns }