import { computed, defineComponent, onMounted, onUnmounted, watch, } from '@vue/composition-api';
import { useBrand, useMobileView, useRouteQuery, useSort } from '@/composables';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { GenericTable, TableStyle } from '@/components/Table/__new__';
import { TableActions, TablePagination } from '@/views/_components/Table';
import { mdiChevronDown } from '@mdi/js';
import { Debounce } from '@/constants/debounce';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import { ObjectsGroupModal } from '@/views/Brand/Objects/_components/ObjectsGroupModals';
import { useObjectsGroupActions } from '@/views/Brand/Objects/_composables/use-objects-group-actions';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { ObjectsGroupModalActionType } from '@/views/Brand/Objects/_components/ObjectsGroupModals';
export default defineComponent({
    components: {
        TableLoader,
        TablePagination,
        TableActions,
        FetchData,
        ObjectsGroupModal,
        TableDisplay: GenericTable(),
    },
    props: {
        showCreateGroupModal: Boolean,
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { isMobileView } = useMobileView();
        const { sortConfig, sortBy, sort, setSortBy } = useSort();
        const { updatesCounter: editGroupUpdateCounter, update } = useUpdatesCounter();
        const { routeQuery, clearRouteFilters, updateQueryParameter } = useRouteQuery(ctx);
        const { getObjectsGroupActions, updatesCounter, showEditObjectsGroupModal, objectsGroupToEdit, } = useObjectsGroupActions(ctx);
        const listUpdatesCounter = computed(() => updatesCounter.value + editGroupUpdateCounter.value);
        onMounted(() => {
            clearRouteFilters(ctx.root.$route);
        });
        onUnmounted(() => {
            clearRouteFilters(ctx.root.$route);
        });
        watch(routeQuery, (value) => {
            if (value.sortBy && sortBy !== value.sortBy)
                setSortBy(value.sortBy);
        });
        watch(sortBy, (value) => {
            updateQueryParameter({
                route: ctx.root.$route,
                parameter: 'sortBy',
                value: { value },
            });
        });
        const columns = computed(() => {
            return [
                {
                    key: 'name',
                    label: ctx.root.$t('objects-groups.col-name').toString(),
                    cellClass: `font-bold ${isMobileView.value ? 'pr-0' : 'pr-10'}`,
                    gridColumn: '0.3fr',
                    sortable: true,
                },
                {
                    key: 'description',
                    label: ctx.root.$t('objects-groups.col-description').toString(),
                    cellClass: `font-bold ${isMobileView.value ? 'pr-0' : 'pr-10'}`,
                    gridColumn: '1fr',
                    sortable: true,
                },
                {
                    key: 'objectsCount',
                    formatter: (count) => count ?? 0,
                    label: ctx.root.$t('objects-groups.col-objects-count').toString(),
                    cellClass: 'font-bold',
                    gridColumn: '0.2fr',
                },
                {
                    key: 'actions',
                    cellClass: isMobileView.value ? 'pl-0 pr-4' : 'flex items-center',
                },
            ];
        });
        const objectsQuery = computed(() => {
            return {
                ...routeQuery.value,
                brandId: brand.value?.id,
            };
        });
        return {
            Debounce,
            getObjectsGroupActions,
            objectsQuery,
            columns,
            isMobileView,
            mdiChevronDown,
            TableStyle,
            sortConfig,
            showEditObjectsGroupModal,
            sort,
            ObjectsGroupModalActionType,
            objectsGroupToEdit,
            update,
            listUpdatesCounter,
            editGroupUpdateCounter,
        };
    },
});
