<template>
  <div v-if="currentPage <= pageCount" class="pagination">
    <button
      class="pagination-button mr-3"
      :class="{ disabled: isPreviousButtonDisabled }"
      :disabled="isPreviousButtonDisabled"
      @click="changePage(currentPage - 1)"
    >
      <icon :icon="mdiChevronLeft" />
    </button>

    <pages
      :current-page="currentPage"
      :page-count="pageCount"
      @change-page="changePage"
    />

    <button
      class="pagination-button ml-3"
      :class="{ disabled: isNextButtonDisabled }"
      :disabled="isNextButtonDisabled"
      @click="changePage(currentPage + 1)"
    >
      <icon :icon="mdiChevronRight" />
    </button>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import Pages from '@/components/Pagination/Pages';
import { DEFAULT_PAGE } from '@/constants/pagination';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export default {
  components: { Pages, Icon },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isPreviousButtonDisabled() {
      return this.currentPage === 1;
    },
    isNextButtonDisabled() {
      return this.currentPage === this.pageCount;
    },
  },
  watch: {
    currentPage: {
      handler(value) {
        if (value > this.pageCount) this.changePage(DEFAULT_PAGE);
      },
      immediate: true,
    },
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', page);
    },
  },
  data() {
    return { mdiChevronLeft, mdiChevronRight };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  @apply flex items-center text-gray-darkest text-xs;

  &-button {
    @apply outline-none text-gray-darkest;
    &.disabled {
      @apply text-gray-dark;
    }
  }
}
</style>
