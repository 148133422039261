import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { mdiCalendarMonth } from '@mdi/js';
import { IconSize, Icon } from '@/components/Icon';
import InputLabel from '@/components/Inputs/_components/InputLabel/InputLabel.vue';
import { v4 as uuidv4 } from 'uuid';
import { FormElement, formElementProps, } from '@/components/Inputs/_components/FormElement';
export default defineComponent({
    components: { FormElement, InputLabel, Icon, DatePicker },
    props: {
        value: String,
        placeholder: String,
        disabled: Boolean,
        label: String,
        disabledDates: Function,
        clearable: Boolean,
        ...formElementProps,
    },
    setup() {
        const inputRef = ref();
        const inputId = `date-input-${uuidv4()}`;
        onMounted(() => {
            //disable keyboard on mobile on input focus
            inputRef.value?.$refs?.input.setAttribute('readonly', 'readonly');
        });
        return { mdiCalendarMonth, IconSize, inputId, inputRef };
    },
});
