import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapState, mapGetters } from 'vuex';
import { customClass, fontNormal } from '@/util/formatters';
import { PLACE_TAB, OBJECT_TAB } from '@/store/modules/ui';
import { getObjectRoute, getPlaceRoute } from '@/util/routing';

export default {
  props: {
    selectedPlaces: {
      type: Array,
      default: () => [],
    },
    selectedObjects: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    loading: Boolean,
    offer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      formValues: [],
      isLoading: false,
      report: null,
      PLACE_TAB,
      OBJECT_TAB,
    };
  },
  computed: {
    ...mapGetters('user', ['hasAdminRights']),
    ...mapState('user', {
      userBrand: 'brand',
      userId: 'id',
    }),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    brandId() {
      return this.$route.params.id || this.userBrand;
    },
    columns() {
      return {
        number: {
          label: this.$t('add-brand-report-view.no'),
          routerLink: {
            getRoute: getPlaceRoute,
          },
          colClass: ['w-18', 'md:w-24'],
        },
        name: {
          label: this.$t('add-brand-report-view.name'),
          formatter: (value) => customClass(value, 'font-semibold'),
        },
        address: {
          label: this.$t('add-brand-report-view.address'),
          merge: {
            props: ['address', 'postalCode', 'city'],
            format: `$0, $1 $2`,
          },
          formatter: fontNormal,
        },
      };
    },
    objectColumns() {
      return {
        internalNumber: {
          label: this.$t('add-brand-report-view.no'),
          routerLink: {
            getRoute: getObjectRoute,
          },
          colClass: ['w-12'],
        },
        address: {
          label: this.$t('add-brand-report-view.address'),
          merge: {
            props: ['address', 'city'],
            format: `$0, $1`,
          },
          formatter: fontNormal,
        },
        internalStatus: {
          label: this.$t('object-status.status'),
          status: true,
          disabled: true,
          colClass: ['w-30'],
        },
        place: {
          label: this.$t('add-brand-report-view.place'),
          routerLink: {
            getRoute: (item) => getPlaceRoute(item.place),
          },
          formatter: ({ number }) => number,
        },
      };
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  mounted() {
    if (!this.hasAdminRights) {
      this.user = {
        id: this.userId,
      };
    }
  },
};
