export const drawSvgInsideRectangle = (svgString, { width, height, padding, background, borderRadius }) => {
    const parser = new DOMParser();
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('width', `${width}px`);
    svg.setAttribute('height', `${height}px`);
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('width', '100%');
    rect.setAttribute('height', '100%');
    rect.setAttribute('fill', background);
    rect.setAttribute('rx', borderRadius);
    const parsedSvg = parser.parseFromString(svgString, 'image/svg+xml').documentElement;
    parsedSvg.setAttribute('x', padding.toString());
    parsedSvg.setAttribute('y', padding.toString());
    parsedSvg.setAttribute('width', `${width - 2 * padding}px`);
    parsedSvg.setAttribute('height', `${height - 2 * padding}px`);
    svg.appendChild(rect);
    svg.appendChild(parsedSvg);
    return new XMLSerializer().serializeToString(svg);
};
export const parseToSerializedSvgDataURL = (svgDoc) => {
    const serializedSvg = new XMLSerializer().serializeToString(svgDoc);
    return ('data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(serializedSvg));
};
export const setSvgFillColor = (svg, color) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
    const pathEl = svgDoc.querySelector('path');
    if (pathEl) {
        pathEl.setAttribute('fill', color);
    }
    return parseToSerializedSvgDataURL(svgDoc);
};
export const stringifyAsset = async (assetSource) => {
    const response = await fetch(assetSource);
    return await response.text();
};
export const svgStringToFile = (svgString, filename) => {
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    return new File([blob], filename, { type: 'image/svg+xml' });
};
export const rotateSVG = (svgString, degrees) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;
    let transformAttr = svgElement.getAttribute('transform') || '';
    const newRotation = `rotate(${degrees})`;
    if (transformAttr.includes('rotate')) {
        transformAttr = transformAttr.replace(/rotate\([^)]+\)/, newRotation);
    }
    else {
        transformAttr += ` ${newRotation}`;
    }
    svgElement.setAttribute('transform', transformAttr);
    return parseToSerializedSvgDataURL(svgDoc);
};
