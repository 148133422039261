<template>
  <div class="border-t-gray-dark border-t mt-10">
    <div class="flex items-center">
      <h2 class="text-2xl my-8 mr-4">
        {{ title }}
      </h2>
      <switch-input
        :value="value"
        :disabled="loading"
        @input="$emit('input', $event)"
      />
    </div>

    <form-creator
      v-if="value"
      :value="components"
      :disabled="loading"
      @input="$emit('set-components', $event)"
    />
  </div>
</template>
<script>
import { SwitchInput } from '@/components/Inputs';
import FormCreator from '@/views/Brand/Settings/Forms/Components/FormCreator';
import { FORM_MODE } from '@/constants/form';

export default {
  components: { SwitchInput, FormCreator },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    components: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      required: true,
      validate: (mode) => Object.values(FORM_MODE).includes(mode),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      const action =
        this.mode === FORM_MODE.ADD
          ? this.$t('forms.add-form')
          : this.$t('forms.edit-form');

      return `${action} ${this.$t('forms.form-offer_adding')}`;
    },
  },
};
</script>
