import { ref } from '@vue/composition-api';
export const useSelection = (compareKey) => {
    const selectedEntries = ref([]);
    const unselect = (entry) => {
        selectedEntries.value = selectedEntries.value.filter((selectedEntry) => selectedEntry[compareKey] !== entry[compareKey]);
    };
    const select = (entry) => {
        selectedEntries.value.push(entry);
    };
    const isSelected = (entry) => selectedEntries.value.find((selectedEntry) => selectedEntry[compareKey] === entry[compareKey]);
    const changeState = (data, state) => {
        const entries = Array.isArray(data) ? data : [data];
        entries.forEach((entry) => {
            if (isSelected(entry) && !state) {
                unselect(entry);
            }
            else if (!isSelected(entry) && state) {
                select(entry);
            }
        });
    };
    return {
        changeState,
        selectedEntries,
    };
};
