<template>
  <split-layout v-if="place" :left-width="57" :right-width="43">
    <template #leftSide>
      <div class="pb-16 pt-5">
        <div class="md:pr-10 h-full">
          <div
            class="mb-12 flex flex-col-reverse md:flex-row gap-4 items-stretch"
          >
            <div class="place-logo">
              <image-modal
                v-if="withImageModalPreview"
                :image-url="place.cdnImageUrl"
                :alt="$t('place.place-alt', { name: place.name })"
                full-width
              >
                <image-view
                  :src="place.cdnImageUrl"
                  :size="ImageSize.CARD"
                  cover-img
                  :alt="$t('place.place-alt', { name: place.name })"
                />
              </image-modal>
              <image-view
                v-else
                :src="place.cdnImageUrl"
                :size="ImageSize.CARD"
                cover-img
                :alt="$t('place.place-alt', { name: place.name })"
              />
            </div>
            <div>
              <h1 class="text-3xl leading-tight">
                {{ place.name }}
              </h1>
              <div class="flex mt-3">
                <div class="md:mr-20">
                  <div class="text-sm font-semibold flex">
                    <icon
                      :icon="mdiHomeOutline"
                      class="mt-px mr-2 text-gray-dark hidden md:block"
                    />
                    <div>
                      <p>
                        {{ place.address }}, {{ place.postalCode }}
                        {{ place.city }}
                      </p>
                      <p class="mb-2"></p>
                      <div class="flex">
                        <span class="info-line">
                          {{ $t('place-details-view.place-number') }}:
                        </span>
                        <div>{{ place.number }}</div>
                      </div>
                      <div class="flex">
                        <span class="info-line">
                          {{ $t('place-details-view.structure') }}:
                        </span>
                        <div>
                          {{ place.structure ? place.structure.name : '-' }}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="info-line">
                          {{ $t('place-details-view.place-manager') }}:
                        </div>
                        <div>
                          {{ place.user ? place.user.firstName : '-' }}
                          {{ place.user ? place.user.lastName : '' }}
                        </div>
                      </div>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.concept') }}:
                        </span>
                        {{ place.concept ? place.concept : '-' }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.type') }}:
                        </span>
                        {{ place.type ? place.type : '-' }}
                      </p>
                      <p>
                        <span class="info-line">
                          {{ $t('place-details-view.size') }}:
                        </span>
                        {{ place.size ? place.size : '-' }}
                      </p>
                    </div>
                  </div>
                  <div v-if="hasAdminRights" class="flex mt-5">
                    <router-link
                      :to="{
                        name: 'EditPlace',
                        params: { placeId: place.id },
                      }"
                    >
                      <icon
                        :icon="EditSvg"
                        :disabled="deletionInProgress"
                        class="mr-4 text-primary"
                      />
                    </router-link>
                    <modal-wrapper
                      v-slot="{ runWithModal }"
                      :content="getConfirmModalObject('place', place.name)"
                      class="cursor-pointer"
                    >
                      <icon
                        :icon="DeleteSvg"
                        class="mr-4 text-primary"
                        :disabled="deletionInProgress"
                        @click.native="
                          runWithModal(
                            onRemoveBtnClick,
                            getConfirmModalObject('place', place.name),
                          )
                        "
                      />
                    </modal-wrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-8">
            <h4 class="mt-3">{{ $t('place-details-view.tags') }}:</h4>
            <ul class="flex">
              <template v-if="place.tags.length">
                <tag
                  v-for="tag in place.tags"
                  :key="tag.id"
                  :delete-btn="false"
                >
                  {{ tag.name }}
                </tag>
              </template>
              <span v-else>-</span>
            </ul>
          </div>
          <div class="my-8">
            <btn class="w-40" @click="onAddReport">
              {{ $t('add-brand-report-view.add-report') }}
            </btn>
          </div>
          <tabs class="mt-4.5 mb-16">
            <tab :name="$t('place-dashboard.reports')" selected>
              <reports-table
                :id="details.id"
                :place-id="place.id"
                hide-edit-action
                @open="
                  $router.push({
                    name: 'ReportDetails',
                    params: { reportId: $event },
                  })
                "
              />
            </tab>
            <tab :name="$t('place-dashboard.offers')">
              <offers-table
                :id="details.id"
                :place-id="place.id"
                @open="
                  $router.push({
                    name: 'OfferDetails',
                    params: { offerId: $event },
                  })
                "
              />
            </tab>
          </tabs>
          <objects-tabs
            :brand-id="details.id"
            :place-id="place.id"
            only-active
          />
        </div>
      </div>
    </template>
    <template #rightSide>
      <div class="pl-12 pr-4 xxl:pl-16 xxl:pr-6">
        <clustered-map
          height="h-120"
          :data-sets="[
            {
              name: 'places',
              url: `places/${place.id}`,
              query: { isPositionDefined: true },
              type: MapDataType.PLACE,
              fetchAll: false,
              hidePreviewButton: true,
              labelAccessor: ['number'],
              labelFormatter: (label) => `#${label}`,
            },
            {
              name: 'objects',
              url: 'objects/map',
              query: mapObjectsQuery,
              type: MapDataType.OBJECT,
              fetchAll: true,
              labelAccessor: ['internalNumber'],
              labelFormatter: (label) => `#${label}`,
            },
          ]"
        />
        <div class="mt-4">
          <btn
            class="w-50 secondary"
            @click="
              $router.push({
                name: 'Map',
              })
            "
          >
            {{ $t('place-dashboard.show-map') }}
          </btn>
        </div>
      </div>
      <tabs class="xxl:pl-16 xxl:pr-6 mt-4.5 pl-12">
        <tab :name="$t('place-details-view.multimedia')" selected>
          <upload-list
            type="multimedia"
            :list="attachments.multimedia"
            read-only
            class="mt-6"
          />
        </tab>
        <tab :name="$t('place-details-view.documents')">
          <upload-list
            type="document"
            :list="attachments.document"
            read-only
            class="mt-6"
          />
        </tab>
      </tabs>
    </template>
  </split-layout>
  <well v-else class="h-full flex content-center justify-center items-center">
    <p>{{ $t('place-details-view.missing-place-info') }}</p>
  </well>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Icon } from '@/components/Icon';
import SplitLayout from '@/layouts/SplitLayout.vue';
import ImageModal from '@/components/Modal/ImageModal.vue';
import Btn from '@/components/Button/Button.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import ObjectsTabs from '@/views/Brand/PlaceDashboard/ObjectsTabs';
import UploadList from '@/components/Uploader/UploadList.vue';
import Tag from '@/components/Tag/Tag';
import ReportsTable from '@/views/Brand/PlaceDashboard/ReportsTable';
import OffersTable from '@/views/Brand/PlaceDashboard/OffersTable';
import ModalWrapper from '@/components/Modal/ModalWrapper';
import ConfirmModalMixin from '@/components/Modal/mixins/ConfirmModal';
import { ImageView, ImageSize } from '@/components/ImageView';
import Well from '@/components/Well/Well.vue';
import { fetchPlaceData } from '@/views/Brand/PlaceDashboard/util';
import { OBJECT_LOCALIZATION, OBJECT_STATUS_MAP } from '@/constants/object';
import { mdiHomeOutline } from '@mdi/js';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';

export default {
  name: 'PlaceDashboard',
  components: {
    Tag,
    ObjectsTabs,
    Icon,
    SplitLayout,
    ImageModal,
    Btn,
    Tab,
    Tabs,
    UploadList,
    ReportsTable,
    OffersTable,
    ModalWrapper,
    ClusteredMap,
    Well,
    ImageView,
  },
  mixins: [ConfirmModalMixin],
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    withImageModalPreview: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiHomeOutline,
      EditSvg,
      DeleteSvg,
      deletionInProgress: false,
      ImageSize,
    };
  },
  computed: {
    ...mapState('brand', {
      place: 'place',
      details: 'details',
    }),
    ...mapState('applications', ['statusColors']),
    ...mapState('attachment', ['attachments']),
    ...mapGetters('user', ['hasAdminRights', 'isContractorUser']),
    mapObjectsQuery() {
      return {
        placeIds: this.place.id,
        localizations: [OBJECT_LOCALIZATION.OUTSIDE],
        internalStatus: OBJECT_STATUS_MAP.ACTIVE,
      };
    },
  },
  async beforeRouteEnter(to, from, next) {
    await fetchPlaceData(to);
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    await fetchPlaceData(to);

    next();
  },
  methods: {
    ...mapActions('common', ['delete']),
    onAddReport() {
      this.$router.push({
        name: 'Reports',
        params: {
          addReport: true,
          placesToSelect: [this.place],
        },
      });
    },
    async onRemoveBtnClick() {
      this.deletionInProgress = true;
      try {
        await this.delete({ type: 'places', id: this.place.id });

        this.$router.push({ name: 'Places' });

        this.$toast.success(
          this.$t('brand-places-list-view.removed-successfully'),
        );
      } finally {
        this.deletionInProgress = false;
      }
    },
  },
  setup() {
    return { MapDataType };
  },
};
</script>

<style lang="scss" scoped>
.spinner-area {
  @apply py-48 w-full flex justify-center;
}

.place-logo {
  @apply w-60 h-48 mr-8 bg-white border border-gray-light rounded flex items-center justify-center overflow-hidden;
}

.info-line {
  @apply text-gray-darker font-normal inline-block min-w-36 whitespace-no-wrap;
}
</style>
