import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { ReportStatus, updateReportStatus, } from '@/api/requests/reports';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import ChatHeader from '@/views/Brand/Applications/Report/Chat/chat-header/ChatHeader.vue';
import { Btn, BtnTheme } from '@/components/Btn';
import { Icon } from '@/components/Icon';
import { WysiwygInput, WysiwygInputHeight } from '@/components/WysiwygInput';
import MessageResources from '@/views/Brand/Applications/Report/_components/MessageResources/MessageResources.vue';
import { ChatMessageList } from '@/views/Brand/Applications/Report/Chat/ChatMessageList';
import ReportsLayout from '@/views/Brand/Applications/Report/_components/ReportsLayout.vue';
import { useReportPopup } from '@/views/Brand/Applications/Report/_composables/use-report-popup';
import { useReportResources } from '@/views/Brand/Applications/Report/_composables/use-report-resources';
import SendSvg from '@/assets/icons/send.svg';
import { useReportChatMessage } from '@/views/Brand/Applications/Report/_composables/use-report-chat-message';
import { createReportDetailsRoute } from '@/router/utils';
import { useReportChatData } from '@/views/Brand/Applications/Report/_composables/use-report-chat-data';
import { useAuthUser, useMobileView } from '@/composables';
import isEmpty from 'lodash.isempty';
import { mdiPlus, mdiClose } from '@mdi/js';
import { PortalTarget } from 'portal-vue';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import { useActionRequired } from '@/views/Brand/Applications/Report/_composables/use-action-required';
import { ActionRequiredHeader } from '@/views/Brand/Applications/Report/Details/_components/action-required-header';
import { ActionRequiredScrollBadge } from '@/views/Brand/Applications/Report/Details/_components/action-required-scroll-badge';
import { AdditionalChatActions } from '@/views/Brand/Applications/Report/Details/_components/additional-chat-actions';
import { FileDropZone } from '@/components/FileDropZone';
export default defineComponent({
    name: 'ReportDetails',
    components: {
        FileDropZone,
        AdditionalChatActions,
        ActionRequiredScrollBadge,
        ActionRequiredHeader,
        ReportsLayout,
        ChatMessageList,
        MessageResources,
        LoadingSpinner,
        ChatHeader,
        Btn,
        Icon,
        WysiwygInput,
    },
    setup(_, ctx) {
        const { isMobileView } = useMobileView();
        const reportId = computed(() => ctx.root.$route.params.reportId);
        const brandId = computed(() => ctx.root.$route.params.id);
        const uploadInProgress = ref(false);
        const messages = ref([]);
        const edit = ref(false);
        const { isAdmin, isContractor } = useAuthUser();
        const isActionRequiredMessageInViewport = ref(true);
        const showReportOptions = computed(() => isAdmin.value || isContractor.value);
        const { requireActionUser, isActionRequiredModeEnabled, editedRequireActionMessage, validateActionRequiredUser, isAuthorizedToCreateActionRequired, enableActionRequiredMode, disableActionRequiredMode, markMessageAsActionRequired, clearRequireActionData, } = useActionRequired();
        const { data, loading, error, fetchUsers, refreshReportData, incompleteReport, } = useReportChatData(reportId, messages);
        const { message, files, getFileTokens, setFileTokens, uploadingFiles, selectedOffer, showAddAssetsBottomMenu, sendingInProgress, onMessageUpdated, ...reportChatMessageProperties } = useReportChatMessage({
            reportId,
            messages,
            requireActionUser,
            validateActionRequiredUser,
            disableActionRequiredMode,
            reportMessage: editedRequireActionMessage,
        }, ctx);
        const { isEditing: isEditingResources } = useReportResources();
        const { addedPlaces } = usePlaceResourcesStore();
        const { addedObjects } = useObjectResourcesStore();
        watch(files, async (value) => {
            if (value) {
                uploadInProgress.value = true;
                const tokens = await getFileTokens();
                setFileTokens(tokens);
                uploadInProgress.value = false;
            }
        }, { deep: true, immediate: true });
        const { reportPopup, closeReportCallback, closeReportRequest, onChangeRoute, onCloseReportPopup, } = useReportPopup();
        const onEdit = () => {
            edit.value = true;
            reportPopup.value = true;
        };
        const onAddFormSubmit = async (id) => {
            reportPopup.value = false;
            await ctx.root.$router.push(createReportDetailsRoute(brandId.value, id));
        };
        const onEditFormSubmit = () => {
            reportPopup.value = false;
            refreshReportData();
        };
        watch(reportPopup, (value) => {
            if (!value)
                edit.value = false;
        });
        const handleUpdateReportStatus = async (status) => {
            if (data.value?.report) {
                await updateReportStatus(data.value.report.id, status);
                data.value = {
                    ...data.value,
                    report: {
                        ...data.value.report,
                        status,
                    },
                };
            }
        };
        const closeReport = () => handleUpdateReportStatus(ReportStatus.FINISHED);
        const reopenReport = async () => {
            await handleUpdateReportStatus(ReportStatus.PENDING);
            await refreshReportData();
        };
        const showMessageResources = computed(() => {
            const anyFilesAdded = !(isEmpty(files.value) || isEmpty(uploadingFiles.value));
            const anyResourcesAdded = !isEmpty(addedObjects.value) || !isEmpty(addedPlaces.value);
            const anyRequiredMessageFilesAdded = Boolean(editedRequireActionMessage.value &&
                editedRequireActionMessage.value?.files.length > 0);
            return (!isEditingResources.value &&
                (anyFilesAdded ||
                    anyResourcesAdded ||
                    !!selectedOffer.value ||
                    anyRequiredMessageFilesAdded));
        });
        const handleCloseActionRequiredMode = () => {
            requireActionUser.value = null;
            sendingInProgress.value = false;
            disableActionRequiredMode();
        };
        const handleMobileRequiredMessageEdit = (requiredActionMessage) => {
            editedRequireActionMessage.value = requiredActionMessage;
            message.value = requiredActionMessage.message;
            isActionRequiredModeEnabled.value = true;
        };
        const handleMarkMessageAsActionRequired = async () => {
            const tokens = await getFileTokens();
            const updatedMessage = await markMessageAsActionRequired(message.value, tokens);
            onMessageUpdated(updatedMessage);
            message.value = '';
            setFileTokens([]);
            clearRequireActionData();
        };
        return {
            mdiClose,
            mdiPlus,
            isEmpty,
            PortalTarget,
            requireActionUser,
            isAuthorizedToCreateActionRequired,
            editedRequireActionMessage,
            isActionRequiredModeEnabled,
            handleCloseActionRequiredMode,
            handleMarkMessageAsActionRequired,
            isActionRequiredMessageInViewport,
            onMessageUpdated,
            showReportOptions,
            sendingInProgress,
            isMobileView,
            SendSvg,
            brandId,
            BtnTheme,
            loading,
            error,
            data,
            fetchUsers,
            isEditingResources,
            reportId,
            message,
            messages,
            reportPopup,
            closeReportCallback,
            closeReportRequest,
            onChangeRoute,
            onEdit,
            edit,
            onAddFormSubmit,
            onEditFormSubmit,
            onCloseReportPopup,
            closeReport,
            reopenReport,
            ReportStatus,
            refreshReportData,
            WysiwygInputHeight,
            incompleteReport,
            files,
            uploadInProgress,
            uploadingFiles,
            showMessageResources,
            selectedOffer,
            enableActionRequiredMode,
            disableActionRequiredMode,
            showAddAssetsBottomMenu,
            handleMobileRequiredMessageEdit,
            ...reportChatMessageProperties,
        };
    },
    beforeRouteLeave(to, from, next) {
        this.onChangeRoute(next);
    },
    beforeRouteUpdate(to, from, next) {
        this.onChangeRoute(() => {
            next();
            this.refreshReportData();
        });
    },
});
