var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"p-16"},[_c('loading-spinner',{attrs:{"is-loading":""}})],1):_c('div',{staticClass:"p-1 popup-content relative"},[_c('button',{staticClass:"absolute top-0 right-0",on:{"click":function($event){return _vm.$emit('close')}}},[_c('icon',{staticClass:"mr-2 text-primary",attrs:{"icon":_vm.mdiClose}})],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"h-32 w-36 rounded mr-3 overflow-hidden flex",class:{ 'popup-image-placeholder': !_vm.object.cdnImageUrl }},[_c('image-view',{attrs:{"src":_vm.object.cdnImageUrl,"alt":_vm.$t('object.object-alt', { internalNumber: _vm.object.internalNumber }),"size":_vm.ImageSize.CARD,"without-padding":""}})],1),_c('div',{staticClass:"px-1 w-64 flex flex-col justify-between"},[_c('div',[_c('h5',{staticClass:"text-2xs text-gray-darker"},[_vm._v(" "+_vm._s(_vm.object.objectType ? _vm.object.objectType.name : '-')+" ")]),_c('h3',{staticClass:"text-sm font-semibold my-0.5"},[_vm._v(" "+_vm._s(_vm.object.internalNumber)+" ")]),_c('ul',{staticClass:"text-sm mt-2"},[_c('li',[_vm._v(_vm._s(_vm.object.address))]),_c('li',[_vm._v(_vm._s(_vm.object.postalCode)+" "+_vm._s(_vm.object.city))])])]),_c('div',[_c('h3',{staticClass:"text-sm font-semibold mt-1"},[_c('span',{staticClass:"font-normal text-gray-darker uppercase"},[_vm._v(" "+_vm._s(_vm.$t('maps.price'))+": ")]),_vm._v(" "+_vm._s(_vm.object.monthlyPrice ? _vm.object.monthlyPrice : '-')+" "+_vm._s(_vm.$t('common.currency'))+" ")]),_c('h3',{staticClass:"text-sm font-semibold mt-1"},[_c('span',{staticClass:"font-normal text-gray-darker uppercase"},[_vm._v(" "+_vm._s(_vm.$t('maps.place'))+": ")]),(_vm.object.place)?_c('router-link',{staticClass:"text-primary",attrs:{"to":{
              name: 'PlaceDetails',
              params: { placeId: _vm.object.place.id },
            }}},[_vm._v(" "+_vm._s(_vm.object.place.number)+" ")]):_c('span',[_vm._v("-")])],1)])])]),(_vm.object.objectType)?_c('div',{staticClass:"flex items-center mt-4",class:_vm.hidePreviewButton ? 'justify-end' : 'justify-between'},[(!_vm.hidePreviewButton)?_c('btn',{staticClass:"w-32",attrs:{"theme":"secondary","to":{
        name: 'ObjectDetails',
        params: { objectId: _vm.object.id, objectTypeId: _vm.object.objectType.id },
      }}},[_vm._v(" "+_vm._s(_vm.$t('maps.show'))+" ")]):_vm._e(),(_vm.isAdmin)?_c('div',{staticClass:"flex"},[_c('router-link',{attrs:{"to":{
          name: 'EditObject',
          params: { objectId: _vm.object.id, objectTypeId: _vm.object.objectType.id },
        }}},[_c('icon',{staticClass:"mr-4 text-primary",attrs:{"icon":_vm.EditSvg}})],1)],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }