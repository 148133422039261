import ObjectsSidebar from '@/views/Brand/Objects/ObjectsSidebar.vue';
import { ObjectsTable } from '@/views/Brand/Objects/_components/ObjectsTable';
import { computed, defineComponent, onMounted, ref, } from '@vue/composition-api';
import { useBrand, useMobileView, useRouteQuery } from '@/composables';
import { useAuthUser } from '@/composables/use-auth-user';
import { PortalTarget } from '@/constants/portal';
import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper.vue';
import { mdiTrayArrowDown } from '@mdi/js';
import { Debounce } from '@/constants/debounce';
import SlotTabs from '@/components/Tabs/SlotTabs.vue';
import { TabsPosition } from '@/components/Tabs/tabs.constants';
import ObjectsCardsList from '@/views/Brand/Objects/_components/ObjectsCardsList/ObjectsCardsList.vue';
import { ListViewSelect, ListViewType } from '@/components/ListViewSelect';
import { ObjectsGroupsTable } from '@/views/Brand/Objects/_components/ObjectsGroupsTable';
import router from '@/router';
import { ObjectsActionButtons, ObjectsHeaderActions, } from '@/views/Brand/Objects/_components/ObjectsActionButtons';
import { SlotSwitch } from '@/components/SlotSwitch';
import { ObjectsTabName } from '@/views/Brand/Objects/_constants';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { SystemRole } from '@/api/requests/users';
import { useViewer } from '@/composables/use-viewer';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';
export default defineComponent({
    name: 'Objects',
    components: {
        ClusteredMap,
        ObjectsGroupsTable,
        SlotSwitch,
        ObjectsCardsList,
        ListViewSelect,
        SlotTabs,
        ObjectsActionButtons,
        ObjectsTable,
        ObjectsSidebar,
        ContentWrapper,
    },
    setup(props, ctx) {
        const { isAdmin, isContractor, isObjektoAdmin, hasRole } = useAuthUser();
        const { hasRole: hasViewerRole } = useViewer(ctx);
        const { isMobileView } = useMobileView();
        const { routeQuery, selectLimit, previousRoute, setPreviousRoute } = useRouteQuery(ctx);
        const { brand } = useBrand();
        const showCreateGroupModal = ref(false);
        const { update, updatesCounter } = useUpdatesCounter();
        const selectedObjects = ref([]);
        const activeList = ref(ListViewType.BASIC);
        const activeTab = ref(previousRoute.value?.name === 'ObjectsGroup'
            ? ObjectsTabName.GROUPS
            : ObjectsTabName.LIST);
        onMounted(() => {
            setPreviousRoute(null);
        });
        const placesMapQuery = computed(() => {
            const { structureId, placeIds, tags, contractorId } = routeQuery.value;
            return {
                structureId,
                tags,
                contractorId,
                placesIds: placeIds,
                brandId: brand.value?.id,
            };
        });
        const objectsMapQuery = computed(() => {
            const mapQuery = { ...routeQuery.value };
            if (mapQuery.page) {
                delete mapQuery.page;
                delete mapQuery.limit;
            }
            return {
                ...mapQuery,
                objectTypeIds: routeQuery.value.objectTypeIds,
                brandId: brand.value?.id,
            };
        });
        const actionButtonsHandlers = {
            [ObjectsHeaderActions.ADD]: {
                [ObjectsTabName.LIST]: () => router.push({ name: 'AddObject' }),
                [ObjectsTabName.GROUPS]: () => (showCreateGroupModal.value = true),
                [ObjectsTabName.MAP]: () => router.push({ name: 'AddObject' }),
            },
        };
        const listViewItems = {
            [ListViewType.BASIC]: () => (activeList.value = ListViewType.BASIC),
            [ListViewType.TILES]: () => (activeList.value = ListViewType.TILES),
        };
        const tabs = computed(() => {
            const isBrandAdmin = hasRole(SystemRole.BRAND_ADMIN) ||
                hasViewerRole(SystemRole.BRAND_ADMIN);
            const showGroups = (isAdmin.value && !isBrandAdmin) || isObjektoAdmin.value;
            return {
                [ObjectsTabName.LIST]: {
                    name: ObjectsTabName.LIST,
                    label: ctx.root.$t('brand-places-list-view.list'),
                },
                ...(showGroups && {
                    [ObjectsTabName.GROUPS]: {
                        name: ObjectsTabName.GROUPS,
                        label: ctx.root.$t('brand-places-list-view.groups'),
                    },
                }),
                [ObjectsTabName.MAP]: {
                    name: ObjectsTabName.MAP,
                    label: ctx.root.$t('brand-places-list-view.map'),
                },
            };
        });
        const selectTab = (tab) => {
            activeTab.value = tab.name;
            update();
        };
        return {
            MapDataType,
            mdiTrayArrowDown,
            isMobileView,
            objectsMapQuery,
            placesMapQuery,
            isAdmin,
            isObjektoAdmin,
            isContractor,
            brand,
            routeQuery,
            selectedObjects,
            selectLimit,
            PortalTarget,
            Debounce,
            tabs,
            TabsPosition,
            listViewItems,
            activeList,
            activeTab,
            ListViewType,
            ObjectsTabName,
            actionButtonsHandlers,
            showCreateGroupModal,
            updatesCounter,
            update,
            selectTab,
        };
    },
});
