import { ref, computed, watch } from '@vue/composition-api';
import { hasUnsavedChanges as areFormsEqual } from '@/util/form';
import cloneDeep from 'lodash.clonedeep';
export const useForm = (defaultValues) => {
    const form = ref(cloneDeep(defaultValues.value));
    const hasUnsavedChanges = (omit) => {
        const baseForm = cloneDeep(form.value);
        const currentForm = cloneDeep(defaultValues.value);
        if (omit) {
            omit.forEach((property) => {
                delete baseForm[property];
                delete currentForm[property];
            });
        }
        return areFormsEqual(currentForm, baseForm);
    };
    const unsavedChanges = computed(() => hasUnsavedChanges());
    watch(defaultValues, () => {
        form.value = cloneDeep(defaultValues.value);
    });
    return { unsavedChanges, form, hasUnsavedChanges };
};
