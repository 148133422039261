var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"show":_vm.show},on:{"update:show":function($event){return _vm.$emit('close')}}},[_c('h2',{staticClass:"text-header"},[_vm._v(_vm._s(_vm.$t('add-task.all-categories')))]),_c('InfiniteData',{attrs:{"url":"/categories","query":{
      brandId: _vm.brand.id,
      parentId: _vm.parentId,
      hasDescendantWithForm: true,
      limit: 20,
    },"container-selector":("#" + _vm.id),"custom-spinner":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var items = ref.items;
return [_c('div',{staticClass:"h-42 overflow-auto mb-6",attrs:{"id":_vm.id}},[_c('category-list',{staticClass:"border-none",attrs:{"categories":items},on:{"select":function($event){return _vm.$emit('select', $event)}}})],1)]}},{key:"spinner",fn:function(){return [_c('div',{staticClass:"w-full flex justify-center"},[_c('loading-spinner',{staticClass:"h-10 w-10",attrs:{"is-loading":""}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }