<template>
  <span>
    {{ text }}
    <span v-if="show" class="sort-icon" :class="{ rotate }">
      <icon :icon="mdiMenuDown" :size="IconSize.XS" class="text-primary" />
    </span>
  </span>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiMenuDown } from '@mdi/js';

export default {
  components: {
    Icon,
  },
  props: {
    rotate: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return { IconSize, mdiMenuDown };
  },
};
</script>
<style scoped>
.rotate {
  @apply transform rotate-180;
}

.sort-icon {
  @apply ml-1 absolute;
  top: 0.5rem;
  left: -0.05rem;
}
</style>
