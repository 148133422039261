import { computed, ref } from '@vue/composition-api';
export var SortDir;
(function (SortDir) {
    SortDir["ASC"] = "asc";
    SortDir["DESC"] = "desc";
})(SortDir || (SortDir = {}));
var SortSign;
(function (SortSign) {
    SortSign["ASC"] = "+";
    SortSign["DESC"] = "-";
})(SortSign || (SortSign = {}));
const SORT_DIR_TO_SIGN_MAP = {
    [SortDir.ASC]: SortSign.ASC,
    [SortDir.DESC]: SortSign.DESC,
};
const SORT_SIGN_TO_DIR_MAP = {
    [SortSign.ASC]: SortDir.ASC,
    [SortSign.DESC]: SortDir.DESC,
};
export const useSort = () => {
    const sortConfig = ref(null);
    const sort = (field) => {
        if (sortConfig.value === null || sortConfig.value?.field !== field) {
            sortConfig.value = {
                field,
                dir: SortDir.ASC,
            };
            return;
        }
        if (sortConfig.value.dir === SortDir.ASC) {
            sortConfig.value = { ...sortConfig.value, dir: SortDir.DESC };
        }
        else {
            sortConfig.value = null;
        }
    };
    const sortBy = computed(() => {
        if (!sortConfig.value)
            return;
        const { dir, field } = sortConfig.value;
        const sign = dir ? SORT_DIR_TO_SIGN_MAP[dir] : '';
        return `${sign}${field}`;
    });
    const setSortBy = (sortBy) => {
        const dir = SORT_SIGN_TO_DIR_MAP[sortBy.charAt(0)];
        if (!dir)
            return;
        sortConfig.value = {
            field: sortBy.slice(1),
            dir,
        };
    };
    return {
        sortConfig,
        sortBy,
        setSortBy,
        sort,
    };
};
