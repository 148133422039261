<template>
  <div>
    <filters
      @search="onSearch"
      @open-filters="filtersOpened = !filtersOpened"
    />
    <status-select
      v-if="filtersOpened"
      v-model="objectStatus"
      with-clear-button
      show-in-offer
      @input="updateFilters"
    />
    <select-input
      v-if="filtersOpened"
      v-model="tags"
      :query="{ brandId: currentBrandId($route), relation: 'object' }"
      :placeholder="$t('add-brand-report-view.tags-placeholder')"
      url="tags"
      autocomplete
      multiple
      option-value-name="id"
      option-label-name="name"
    />
  </div>
</template>
<script>
import StatusSelect from '@/views/Brand/Objects/Forms/Components/StatusSelect';
import { mapGetters } from 'vuex';
import { SelectInput } from '@/components/Inputs';
import Filters from '@/components/ResourcesWrapper/_components/Filters.vue';

export default {
  components: { StatusSelect, SelectInput, Filters },
  data() {
    return {
      objectStatus: '',
      search: '',
      tags: [],
      filtersOpened: false,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    objectFilters() {
      return {
        ...(this.search && { q: this.search }),
        ...(this.tags.length && { tags: this.tags.map(({ name }) => name) }),
        ...(this.objectStatus && {
          internalStatus:
            typeof this.objectStatus === 'string'
              ? this.objectStatus
              : this.objectStatus.value,
        }),
      };
    },
  },
  methods: {
    onSearch(value) {
      this.search = value;

      this.updateFilters();
    },
    updateFilters() {
      this.$emit('filter-objects', this.objectFilters);
    },
  },
  watch: {
    objectFilters: {
      handler() {
        this.updateFilters();
      },
      deep: true,
    },
  },
};
</script>
