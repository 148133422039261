import VueStore from '@/store';

export const fetchObjectData = async (path) => {
  await Promise.all([
    VueStore.dispatch('attachment/fetchAttachments', {
      id: path.params['objectId'],
      target: 'objects',
      type: 'document',
    }),
    VueStore.dispatch('attachment/fetchAttachments', {
      id: path.params['objectId'],
      target: 'objects',
      type: 'multimedia',
    }),
    VueStore.dispatch('object/fetchObjectDetails', path.params['objectId']),
  ]);

  const currentObjectType = VueStore.state.object.objectType;

  const objectType = VueStore.state.object.details?.objectType;

  const shouldFetchObjectTypeData =
    objectType &&
    (!currentObjectType || currentObjectType.id !== objectType.id);

  if (shouldFetchObjectTypeData)
    await VueStore.dispatch('object/fetchObjectTypeDetails', objectType.id);
};

export const fetchOfferData = async (path) => {
  const { offerId, objectId } = path.params;

  const activeOfferId = VueStore.getters['offer/activeOfferId'];

  if (!activeOfferId) await VueStore.dispatch('offer/fetchOffer', offerId);

  await VueStore.dispatch('offer/setActiveObject', objectId);

  // TODO: refactor - util for changing previewed items
  if (offerId !== activeOfferId) {
    await VueStore.dispatch('offer/clearOfferObjectsList');
    await VueStore.dispatch('offer/fetchAllOfferObjects', {
      offerId,
      brandId: VueStore.getters['brand/currentBrandId'](path),
    });
  }
};
