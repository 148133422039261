import { MENTION_CLASS_NAME } from '@/components/WysiwygInput/wysiwyg.constants';
const uuidRegExp = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
const mentionRegExpGlobal = new RegExp(`<span data-type="mention" class="${MENTION_CLASS_NAME}" data-id="(${uuidRegExp})" data-label="(.*?)">@(.*?)</span>`, 'g');
const mentionRegExp = new RegExp(`<span data-type="mention" class="${MENTION_CLASS_NAME}" data-id="(${uuidRegExp})" data-label="(.*?)">@(.*?)</span>`);
const nameLabel = 'NAME_LABEL';
const idLabel = 'ID_LABEL';
const replaceLabels = (text, labelType) => {
    let textWithReplacedLabels = text;
    (text.match(mentionRegExpGlobal) || []).forEach((mention) => {
        const replacementData = mention.match(mentionRegExp) || [];
        const newLabel = labelType === idLabel ? replacementData[1] : replacementData[2];
        const updatedValue = mention.replace(`@${replacementData[3]}`, `@${newLabel}`);
        textWithReplacedLabels = textWithReplacedLabels.replace(mention, updatedValue);
    });
    return textWithReplacedLabels;
};
export const insertUserNames = (text) => replaceLabels(text, nameLabel);
export const replaceUserNamesWithIds = (text) => replaceLabels(text, idLabel);
