<template>
  <div class="layout">
    <content-card :content-class="contentClass">
      <template #header><slot name="header"></slot></template>
      <slot name="content"></slot>
    </content-card>
  </div>
</template>

<script>
import ContentCard from '@/components/ContentCard/ContentCard';
export default {
  components: { ContentCard },
  props: {
    contentClass: {
      type: String,
      default: 'p-4',
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  max-width: 848px;
  @apply mx-auto mt-6 mb-12;
}
</style>
