import { useResources } from '@/store/_composables/use-resources';
const usePlaceResources = () => {
    const { selectionMode: placeSelectionMode, setSelectionMode: setPlaceSelectionMode, selectionKey: placeSelectionKey, setSelectionKey: setPlaceSelectionKey, existingResources: existingPlaces, setExistingResources: setExistingPlaces, addedResources: addedPlaces, removedResources: removedPlaces, pendingResources: pendingPlaces, clearPendingResources: clearPendingPlaces, resolvedResources: resolvedPlaces, removeResource: removePlace, toggleResource: togglePlace, isResourceSelected: isPlaceSelected, onResourceSelection: onPlaceSelection, flushPendingResources: flushPendingPlaces, resetResourcesState: resetPlacesState, } = useResources();
    return {
        placeSelectionMode,
        setPlaceSelectionMode,
        placeSelectionKey,
        setPlaceSelectionKey,
        existingPlaces,
        addedPlaces,
        removedPlaces,
        pendingPlaces,
        clearPendingPlaces,
        resolvedPlaces,
        removePlace,
        togglePlace,
        setExistingPlaces,
        isPlaceSelected,
        onPlaceSelection,
        flushPendingPlaces,
        resetPlacesState,
    };
};
let placeResourcesStore = null;
export const usePlaceResourcesStore = () => {
    if (placeResourcesStore === null) {
        placeResourcesStore = usePlaceResources();
    }
    return placeResourcesStore;
};
export const destroyPlaceResourcesStore = () => {
    placeResourcesStore = null;
};
