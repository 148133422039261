import { computed, defineComponent } from '@vue/composition-api';
import { PaginationSelect, TextInput } from '@/components/Inputs';
import { BASE_INPUT_STYLE } from '@/components/Inputs/_components/BaseInput';
import { mdiMagnify } from '@mdi/js';
import { Icon } from '@/components/Icon';
import { useRouteQuery } from '@/composables';
import { FILTER_PARAMS } from '@/constants/filters';
export default defineComponent({
    components: { PaginationSelect, TextInput, Icon },
    props: {
        query: Object,
    },
    setup(_, ctx) {
        const { routeQuery, updateQueryParameter } = useRouteQuery(ctx);
        const searchValue = computed(() => {
            return routeQuery.value?.[FILTER_PARAMS.SEARCH] ?? '';
        });
        const onInput = (value) => {
            updateQueryParameter({
                route: ctx.root.$route,
                parameter: FILTER_PARAMS.SEARCH,
                value,
            });
        };
        return { BASE_INPUT_STYLE, mdiMagnify, searchValue, onInput };
    },
});
