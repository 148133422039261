<template>
  <div v-if="loading">
    <LoadingSpinner class="w-10 h-10 mx-auto mt-4" :is-loading="loading" />
  </div>
  <div v-else-if="brand" class="mb-16">
    <select-input
      id="object-type-select"
      v-model="objectType"
      url="object-types"
      :query="{ brandId: brand.id, hasAssignedForm: true }"
      option-label-name="name"
      option-value-name="id"
      :placeholder="$t('forms.object-type')"
      :label="$t('forms.set-object-type')"
      class="mt-4"
      autocomplete
    />
    <template>
      <object-form
        v-if="objectType"
        id="objectForm"
        :key="forceUpdateForm"
        ref="objectForm"
        :brand="brand"
        :is-show-map-in-form="false"
        :coordinates="coordinates"
        :clear="objectSubmissionEndSuccessfully"
        :content="objectFormDefaultContent"
        enable-object-type-update
        in-offer
        :offer-report="reportId"
        @set-coordinates="coordinates = $event"
        @on-submit="(data, tags) => $emit('submit', data, tags)"
      />

      <div class="w-full flex justify-center pb-5 md:pb-0">
        <btn
          :is-loading="objectSubmissionInProgress"
          type="submit"
          form="objectForm"
          :disabled="!objectType"
          class="w-48 mt-4 mr-3"
        >
          {{ $t('common.save') }}
        </btn>
        <btn theme="secondary" class="w-48 mt-4 mr-3" @click="closeObjectForm">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </template>
    <yes-no-modal
      :show="showConfirmationModal"
      :content="modalContent"
      @update:show="showConfirmationModal = $event"
    />
  </div>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import { mapActions, mapState } from 'vuex';
import isEqual from 'lodash.isequal';
import { YesNoModal } from '@/components/Modal';
import ObjectForm from '@/views/Brand/Objects/Forms/ObjectForm';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import { SelectInput } from '@/components/Inputs';

export default {
  components: {
    SelectInput,
    LoadingSpinner,
    ObjectForm,
    YesNoModal,
    Btn,
  },
  props: {
    editedContent: {
      type: Object,
      default: null,
    },
    selectedObjectType: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reportId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      referenceObjectForm: null,
      showConfirmationModal: false,
      objectSubmissionInProgress: false,
      objectSubmissionEndSuccessfully: false,
      objectType: null,
      coordinates: null,
      forceUpdateForm: 0,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
    ...mapState('object', { objectTypeDetails: 'objectType' }),
    objectFormDefaultContent() {
      return { ...this.editedContent, role: 'place-admin' };
    },
    modalContent() {
      return {
        title: this.$t('add-place-view.modal-title'),
        description: this.$t('add-place-view.modal-description'),
        btns: {
          yes: () => this.$emit('close-object-tab'),
          no: () => {
            this.showConfirmationModal = false;
          },
        },
      };
    },
  },
  watch: {
    objectType: {
      async handler(value) {
        if (value) {
          await this.fetchObjectTypeDetails(value.id);
          this.forceUpdateForm++;

          this.updateReferenceForm();
        } else {
          this.clearObjectType();
        }
      },
      immediate: true,
    },
    loading: {
      handler(value) {
        if (!value) {
          this.updateReferenceForm();

          if (this.editedContent) {
            this.coordinates = {
              lat: this.editedContent.lat,
              lng: this.editedContent.lng,
            };
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.selectedObjectType) {
      await this.fetchObjectTypeDetails(this.selectedObjectType.id);

      this.objectType = this.objectTypeDetails;
    }
  },
  methods: {
    ...mapActions('object', ['fetchObjectTypeDetails', 'clearObjectType']),
    getObjectFormData() {
      const { formData, tagsUploadQueue, tagsDeleteQueue } = this.$refs
        .objectForm || {
        formData: {},
        tagsUploadQueue: [],
        tagsDeleteQueue: [],
      };

      return {
        form: formData,
        tagsChange: [...tagsDeleteQueue, ...tagsUploadQueue].length > 0,
      };
    },
    isObjectFormFilled() {
      if (!this.referenceObjectForm) return false;

      const { form, tagsChange } = this.getObjectFormData();

      return tagsChange || !isEqual(form, this.referenceObjectForm);
    },
    closeObjectForm() {
      if (this.isObjectFormFilled()) {
        this.showConfirmationModal = true;
      } else {
        this.$emit('close-object-tab');
      }
    },
    updateReferenceForm() {
      this.$nextTick(() => {
        const { form } = this.getObjectFormData();

        this.referenceObjectForm = form;
      });
    },
  },
};
</script>
