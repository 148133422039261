import EmptyView from '@/views/EmptyView';
import BrandLayout from '@/layouts/BrandLayout/BrandLayout.vue';
import BrandUsers from '@/views/Brand/Users/Users.vue';
import AddBrandUser from '@/views/Brand/Users/AddUser.vue';
import EditBrandUser from '@/views/Brand/Users/EditUser.vue';
import BrandUserDetails from '@/views/Brand/Users/UserDetails.vue';
import BrandPlaces from '@/views/Brand/Places/Places.vue';
import AddBrandPlace from '@/views/Brand/Places/AddPlace.vue';
import EditBrandPlace from '@/views/Brand/Places/EditPlace.vue';
import ObjectTypes from '@/views/Brand/ObjectTypes/ObjectTypes.vue';
import ObjectsGroup from '@/views/Brand/ObjectsGroup/ObjectsGroup.vue';
import Objects from '@/views/Brand/Objects/Objects.vue';
import AddObject from '@/views/Brand/Objects/AddObject.vue';
import ObjectDetails from '@/views/Brand/Objects/ObjectDetails.vue';
import ObjectInOfferDetails from '@/views/Brand/Objects/ObjectInOfferDetails';
import EditObject from '@/views/Brand/Objects/EditObject.vue';
import MapView from '@/views/Brand/Map/Map.vue';
import Categories from '@/views/Brand/Settings/Categories/Categories.vue';
import AddCategory from '@/views/Brand/Settings/Categories/Add.vue';
import EditCategory from '@/views/Brand/Settings/Categories/Edit.vue';
import Structures from '@/views/Brand/Settings/Structures/Structures.vue';
import AddStructure from '@/views/Brand/Settings/Structures/Add.vue';
import FormEditor from '@/views/Brand/Settings/Forms/FormEditor.vue';
import Reports from '@/views/Brand/Applications/Report/Reports.vue';
import ReportDetails from '@/views/Brand/Applications/Report/Details/Details.vue';
import Forms from '@/views/Brand/Settings/Forms/Forms.vue';
import Offers from '@/views/Brand/Applications/Offer/Offers';
import AddOffer from '@/views/Brand/Applications/Offer/Add';
import EditOffer from '@/views/Brand/Applications/Offer/Edit';
import OfferDetails from '@/views/Brand/Applications/Offer/Details/Details.vue';
import PlaceDashboard from '@/views/Brand/PlaceDashboard/PlaceDashboard';
import Theme from '@/views/Brand/Settings/Theme/Theme';
import { ADMIN, PLACE_ADMIN, STRUCTURE_ADMIN } from '@/store/modules/user';
import {
  providePagination,
  provideQueryObject,
  provideReportsParams,
} from '@/util/routing';
import { PAGE_404 } from '@/router/routes/static.route';
import { ClientRouteName } from '@/router/router.constants';
import { Profile } from '@/views/Profile';
import { Notifications } from '@/views/Profile/Notifications';
import { Vacations } from '@/views/Profile/Vacations';

export default [
  {
    path: '/brand/:id',
    name: 'Brand',
    component: BrandLayout,
    meta: {
      isAuthRequired: true,
      requiredRole: PLACE_ADMIN,
    },
    redirect: PAGE_404,
    children: [
      {
        path: 'reports',
        name: ClientRouteName.REPORTS,
        props: provideReportsParams,
        component: Reports,
        meta: {
          submenu: true,
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'reports/:reportId/details',
        name: ClientRouteName.REPORT_DETAILS,
        component: ReportDetails,
        meta: {
          submenu: true,
          matchName: 'Reports',
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'offers',
        name: ClientRouteName.OFFERS,
        props: providePagination,
        component: Offers,
        meta: {
          submenu: true,
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'offers/add',
        name: ClientRouteName.ADD_OFFER,
        props: true,
        component: AddOffer,
        meta: {
          submenu: true,
          matchName: 'Offers',
          requiredRole: ADMIN,
          contractorAccess: true,
        },
      },
      {
        path: 'offers/:offerId/details',
        name: ClientRouteName.OFFER_DETAILS,
        component: OfferDetails,
        meta: {
          submenu: true,
          matchName: 'Offers',
          contractorAccess: true,
        },
      },
      {
        path: 'offers/:offerId/edit',
        name: ClientRouteName.EDIT_OFFER,
        component: EditOffer,
        meta: {
          submenu: true,
          matchName: 'Offers',
          requiredRole: ADMIN,
          contractorAccess: true,
        },
      },
      {
        path: 'offers/:offerId/objects/:objectId',
        name: ClientRouteName.OBJECT_IN_OFFER_DETAILS,
        component: ObjectInOfferDetails,
        meta: {
          matchName: 'Offers',
          contractorAccess: true,
        },
      },
      {
        path: 'places',
        name: ClientRouteName.PLACES,
        props: providePagination,
        component: BrandPlaces,
        meta: {
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'places/new',
        name: ClientRouteName.ADD_PLACE,
        component: AddBrandPlace,
        meta: {
          requiredRole: STRUCTURE_ADMIN,
          matchName: 'Places',
        },
      },
      {
        path: 'places/:placeId/details',
        name: ClientRouteName.PLACE_DETAILS,
        component: PlaceDashboard,
        meta: {
          matchName: 'Places',
          contractorAccess: true,
        },
      },
      {
        path: 'places/:placeId/edit',
        name: ClientRouteName.EDIT_PLACE,
        component: EditBrandPlace,
        meta: {
          requiredRole: STRUCTURE_ADMIN,
          matchName: 'Places',
        },
      },
      {
        path: 'object-types',
        name: ClientRouteName.OBJECT_TYPES,
        props: providePagination,
        component: ObjectTypes,
        meta: {
          matchName: 'ObjectTypes',
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'object-types/objects',
        name: ClientRouteName.OBJECTS,
        component: Objects,
        props: providePagination,
        meta: {
          matchName: 'ObjectTypes',
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'objects-group/:objectsGroupId',
        name: 'ObjectsGroup',
        component: ObjectsGroup,
        props: providePagination,
        meta: {
          matchName: 'ObjectTypes',
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'objects/new',
        name: ClientRouteName.ADD_OBJECT,
        props: true,
        component: AddObject,
        meta: {
          matchName: 'ObjectTypes',
          requiredRole: ADMIN,
        },
      },
      {
        path: 'object-types/:objectTypeId/objects/:objectId/details',
        name: ClientRouteName.OBJECT_DETAILS,
        component: ObjectDetails,
        meta: {
          matchName: 'ObjectTypes',
          contractorAccess: true,
        },
      },
      {
        path: 'object-types/:objectTypeId/objects/:objectId/edit',
        name: ClientRouteName.EDIT_OBJECT,
        component: EditObject,
        meta: {
          matchName: 'ObjectTypes',
          requiredRole: ADMIN,
        },
      },
      {
        path: 'map',
        name: ClientRouteName.MAP,
        component: MapView,
        props: provideQueryObject,
        meta: {
          contractorAccess: true,
          sidebar: true,
        },
      },
      {
        path: 'dashboard',
        name: ClientRouteName.PLACE_DASHBOARD,
        component: PlaceDashboard,
        props: provideQueryObject,
        meta: {
          requiredRole: PLACE_ADMIN,
        },
      },
      {
        path: 'settings',
        name: ClientRouteName.SETTINGS,
        component: EmptyView,
        meta: {
          requiredRole: ADMIN,
        },
        redirect: PAGE_404,
        children: [
          {
            path: 'users',
            name: ClientRouteName.USERS,
            props: providePagination,
            component: BrandUsers,
            meta: {
              requiredRole: STRUCTURE_ADMIN,
            },
          },
          {
            path: 'users/new',
            name: ClientRouteName.ADD_USER,
            component: AddBrandUser,
            meta: {
              matchName: 'Users',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'users/:userId/edit',
            name: ClientRouteName.EDIT_USER,
            component: EditBrandUser,
            meta: {
              matchName: 'Users',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'users/:userId/details',
            name: ClientRouteName.USER_DETAILS,
            component: BrandUserDetails,
            meta: {
              matchName: 'Users',
              requiredRole: STRUCTURE_ADMIN,
            },
          },
          {
            path: 'forms',
            name: ClientRouteName.FORMS,
            component: Forms,
            props: providePagination,
            meta: {
              requiredRole: ADMIN,
            },
          },
          {
            path: 'forms/add/:type',
            name: ClientRouteName.ADD_ONE_FORM,
            component: FormEditor,
            props: true,
            meta: {
              matchName: 'Forms',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'forms/:formId/edit',
            name: ClientRouteName.EDIT_ONE_FORM,
            component: FormEditor,
            props: true,
            meta: {
              matchName: 'Forms',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'categories',
            name: ClientRouteName.CATEGORIES,
            component: Categories,
            meta: {
              requiredRole: ADMIN,
            },
          },
          {
            path: 'categories/add',
            name: ClientRouteName.ADD_CATEGORY,
            component: AddCategory,
            meta: {
              matchName: 'Categories',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'categories/:categoryId/edit',
            name: ClientRouteName.EDIT_CATEGORY,
            component: EditCategory,
            props: true,
            meta: {
              matchName: 'Categories',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'categories/:categoryId/forms/add',
            name: ClientRouteName.ADD_FORM,
            component: FormEditor,
            props: true,
            meta: {
              matchName: 'Categories',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'categories/:categoryId/forms/:formId/edit',
            name: ClientRouteName.EDIT_FORM,
            component: FormEditor,
            meta: {
              matchName: 'Categories',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'structures',
            name: ClientRouteName.STRUCTURES,
            component: Structures,
            meta: {
              requiredRole: ADMIN,
            },
          },
          {
            path: 'structures/add',
            name: ClientRouteName.ADD_STRUCTURE,
            component: AddStructure,
            meta: {
              matchName: 'Structures',
              requiredRole: ADMIN,
            },
          },
          {
            path: 'theme',
            name: ClientRouteName.THEME,
            component: Theme,
            meta: {
              requiredRole: ADMIN,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/profile',
    name: ClientRouteName.PROFILE,
    component: Profile,
    meta: {
      isAuthRequired: true,
      contractorAccess: true,
    },
    redirect: PAGE_404,
    children: [
      {
        path: 'notifications',
        name: ClientRouteName.NOTIFICATIONS,
        component: Notifications,
        meta: {
          contractorAccess: true,
        },
      },
      {
        path: 'vacations',
        name: ClientRouteName.VACATIONS,
        component: Vacations,
        meta: {
          contractorAccess: true,
        },
      },
    ],
  },
];
