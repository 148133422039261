export default {
  props: {
    checkbox: {
      type: Boolean,
      default: false,
    },
    selectionKey: {
      type: String,
      default: 'id',
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    selectAllBtn: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Function,
      default: () => false,
    },
    forceUpdateSelection: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectAll: false,
    };
  },
  watch: {
    selectedIds() {
      this.setSelectAllBtn();
    },
    rows: {
      immediate: true,
      handler() {
        this.setSelectAllBtn();
      },
    },
    forceUpdateSelection() {
      this.setSelectAllBtn();
    },
  },
  methods: {
    setSelectAllBtn() {
      this.selectAll = this.formattedRows.every((row) => {
        return this.isSelected(row) || this.selectedIds.includes(row.id);
      });
    },
    selectSingleRow(id) {
      const ids = [];
      const items = [];

      const [selectedId] = this.selectedIds;

      if (selectedId !== id) {
        ids.push(id);
        items.push(
          this.formattedRows.find((row) => row[this.selectionKey] === id),
        );
      }

      return { ids, items };
    },
    updateSelectedRows(id) {
      let ids = [...this.selectedIds];
      let items = [...this.selectedItems];

      if (ids.includes(id)) {
        ids = ids.filter((row) => row !== id);
        items = items.filter((row) => row[this.selectionKey] !== id);
      } else {
        ids.push(id);
        items.push(
          this.formattedRows.find((row) => row[this.selectionKey] === id),
        );
      }

      return { ids, items };
    },
    selectRow(id, isChecked) {
      const { ids, items } = this.singleSelect
        ? this.selectSingleRow(id)
        : this.updateSelectedRows(id);

      const item = this.formattedRows.find(
        (row) => row[this.selectionKey] === id,
      );

      this.$emit('select', ids, items, {
        isChecked,
        id,
        item,
      });

      this.$nextTick(() => this.setSelectAllBtn());
    },
    onSelectAll(isChecked) {
      let updatedIds = [...this.selectedIds];
      let updatedItems = [...this.selectedItems];

      if (this.selectAll) {
        this.formattedRows.forEach((row) => {
          if (!updatedIds.includes(row[this.selectionKey])) {
            updatedIds.push(row[this.selectionKey]);
            updatedItems.push(row);
          }
        });
      } else {
        const rowIds = this.formattedRows.map((row) => row[this.selectionKey]);

        updatedIds = updatedIds.filter((id) => !rowIds.includes(id));
        updatedItems = updatedItems.filter(
          (row) => !rowIds.includes(row[this.selectionKey]),
        );
      }
      this.$emit('select', updatedIds, updatedItems, { isChecked });
    },
  },
};
