var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fetch-data',{attrs:{"url":"objects","params":_vm.computedQuery,"debounce":_vm.FILTER_DEBOUNCE},on:{"data-deliver":_vm.updateList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var results = ref.results;
var pagination = ref.pagination;
return [_c('div',[(isLoading)?_c('div',{staticClass:"spinner-area"},[_c('icon-spinner',{staticClass:"spinner stroke-primary",attrs:{"width":"64px","height":"64px"}})],1):_vm._e(),(!_vm.isEmpty(results))?_c('div',{staticClass:"flex flex-wrap w-full",class:{ 'opacity-50': isLoading }},_vm._l((results),function(object){return _c('card',{key:object.id,staticClass:"mr-4 mt-4",attrs:{"cover-img":"","link":{
          name: 'ObjectDetails',
          params: { objectId: object.id, objectTypeId: object.objectTypeId },
        },"image":object.cdnImageUrl,"alt":"","card-class":"p-2.5 h-70 w-60 shadow-card","link-class":"font-normal pt-1"}},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(object.internalNumber))]),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(object.address))])])])}),1):_vm._e(),_c('div',{staticClass:"flex w-full mt-4 mb-3 justify-center"},[(!_vm.isEmpty(results))?_c('pagination',{staticClass:"mt-2",attrs:{"current-page":_vm.computedQuery.page || 1,"page-count":_vm.lastPage},on:{"change-page":_vm.changePage}}):_vm._e()],1),(pagination)?_c('entries-counter',{attrs:{"url":"object-types","to":pagination.to,"from":pagination.from,"filtered-entries":pagination.total}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }