import { onUnmounted, ref } from '@vue/composition-api';
const LONG_PRESS_TIME = 500;
export const useLongPress = (handler) => {
    const timer = ref();
    const startTimer = () => {
        timer.value = setTimeout(function () {
            handler();
        }, LONG_PRESS_TIME);
    };
    const clearTimer = () => {
        if (timer.value) {
            clearTimeout(timer.value);
        }
    };
    onUnmounted(() => clearTimer());
    return {
        touchstart: startTimer,
        touchend: clearTimer,
    };
};
