import Vue from 'vue';
import { rootOptions } from '@/main';
export const createVueComponentControl = ({ component, props, tagName, eventListeners, }) => {
    const componentInstance = Vue.extend(component);
    const instance = new componentInstance({
        ...rootOptions,
        propsData: {
            ...props,
        },
    });
    instance.$mount();
    eventListeners?.map(({ name, handler }) => {
        instance.$on(name, handler);
    });
    const container = document.createElement(tagName);
    container.appendChild(instance.$el);
    return container;
};
