import { defineComponent } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import { mdiAlertOctagonOutline } from '@mdi/js';
import { getReportMessages, } from '@/api/requests/reports';
import { useInfiniteData } from '@/store/_composables/use-infinite-data';
import { CHAT_MESSAGES_PER_PAGE } from '@/views/Brand/Applications/Report/_constants';
import { useChat } from '@/store/_composables/use-chat';
export default defineComponent({
    components: { Icon },
    props: {
        report: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, ctx) {
        const { setBackwardCursor, setForwardCursor, setIsForwardListCompleted, setIsBackwardListCompleted, } = useInfiniteData();
        const { scrollToMessageId } = useChat();
        const getMessages = async () => {
            const scrollToMessage = document.getElementById(scrollToMessageId.value);
            if (scrollToMessage) {
                scrollToMessage.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
                return;
            }
            //TODO: promise all
            const backwardData = await getReportMessages(props.report.id, {
                cursor: props.report.requiredActionMessageCursor,
                direction: 'backward',
                perPage: CHAT_MESSAGES_PER_PAGE,
            });
            const forwardData = await getReportMessages(props.report.id, {
                cursor: props.report.requiredActionMessageCursor,
                direction: 'forward',
                perPage: CHAT_MESSAGES_PER_PAGE,
            });
            ctx.emit('input', [
                ...backwardData.results.reverse(),
                ...forwardData.results.slice(1),
            ]);
            setIsBackwardListCompleted(backwardData.nextPageCursor ? false : true);
            setIsForwardListCompleted(forwardData.nextPageCursor ? false : true);
            setBackwardCursor(backwardData.nextPageCursor);
            setForwardCursor(forwardData.nextPageCursor);
        };
        return { mdiAlertOctagonOutline, getMessages };
    },
});
