import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import Tree from '@/components/Tree/Tree.vue';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { getRootStructure, getStructuresList, } from '@/api/requests/structures';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { Icon } from '@/components/Icon';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { usePromise, useToggle } from '@/composables';
import { mdiInformationOutline } from '@mdi/js';
import SettingsContentWrapper from '@/layouts/BrandLayout/Components/SettingsContentWrapper.vue';
export default defineComponent({
    name: 'Structures',
    components: {
        SettingsContentWrapper,
        LoadingSpinner,
        Breadcrumbs,
        Btn,
        Tree,
        EllipsisMenu,
        Tooltip,
        Icon,
    },
    setup(props, context) {
        const { $route, $router } = context.root;
        const brandId = $route.params.id;
        const { loading, error, result: rootStructure, } = usePromise(getRootStructure(brandId));
        const { state: isCardActive, toggle: toggleCardActivity } = useToggle();
        const rootStructureChildren = ref(null);
        watch(isCardActive, async () => {
            if (rootStructureChildren.value !== null) {
                return;
            }
            const { results } = await getStructuresList({
                parentId: rootStructure.value?.id,
            });
            rootStructureChildren.value = results;
        });
        const onEditStructure = (id) => {
            $router.push({ name: 'AddStructure', params: { structureId: id } });
        };
        const isNodeExpandable = (node) => {
            return node.hasChildren || (node.places || []).length > 0;
        };
        const loadStructureChildren = async (node) => {
            const { results, pagination } = await getStructuresList({
                parentId: node.id,
            });
            return { children: results, pagination };
        };
        const loadMoreChildren = async (node, currentPagination) => {
            const { results, pagination } = await getStructuresList({
                parentId: node.id,
                limit: currentPagination.value.perPage,
                page: currentPagination.value.currentPage + 1,
            });
            return { children: results, pagination };
        };
        return {
            mdiInformationOutline,
            loading,
            error,
            rootStructure,
            onEditStructure,
            isCardActive,
            toggleCardActivity,
            rootStructureChildren,
            isNodeExpandable,
            loadStructureChildren,
            loadMoreChildren,
        };
    },
});
