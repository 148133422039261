import { getFullName } from '@/util/user';
import { createReportDetailsRoute } from '@/router/utils';
import { handleTranslations, } from '@/layouts/_components/Notifications/_util/notification-factory';
export const handleUserAssignedToReportNotification = (notification) => {
    const { type, payload: { brand, user, report }, } = notification;
    return {
        getTranslations() {
            const translationPlaceholders = {
                ...report,
                user: getFullName(user),
            };
            return handleTranslations(type, translationPlaceholders);
        },
        getRoute() {
            return createReportDetailsRoute(brand.id, report.id);
        },
    };
};
