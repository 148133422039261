import { computed, defineComponent } from '@vue/composition-api';
import { SystemRole } from '@/api/requests/users';
import { getFullName } from '@/util/user';
import { getUserAvatar } from '@/util/avatars';
import ImageView from '@/components/ImageView/ImageView.vue';
export default defineComponent({
    components: { ImageView },
    props: {
        user: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const role = computed(() => {
            const { systemRole, internalRole, homeOrganization } = props.user;
            if (systemRole === SystemRole.CONTRACTOR)
                return homeOrganization?.name;
            return internalRole;
        });
        const imgSrc = computed(() => getUserAvatar(props.user));
        const name = computed(() => getFullName(props.user));
        return { name, imgSrc, role };
    },
});
