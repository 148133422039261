var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('backdrop-loader'):_c('ul',{class:{ horizontal: _vm.horizontal }},[_c('filter-group',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_vm._l((_vm.reportItems),function(item,index){return _c('li',{key:((item.name) + "-" + index),staticClass:"menu-item",class:[
      { active: _vm.isActive(item.name) },
      [
        _vm.hasRequiredActionMessages(item)
          ? 'hover:bg-red-lighter'
          : 'hover:bg-gray-lightest' ] ]},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.onItemClick(item.name)}}},[_c('icon',{staticClass:"icon",class:[_vm.hasRequiredActionMessages(item) ? 'text-red' : 'text-black'],attrs:{"icon":item.icon,"size":_vm.horizontal ? _vm.IconSize.MD : _vm.IconSize.SM}}),_c('span',{staticClass:"leading-none uppercase md:capitalize hidden w-full md:flex justify-between",class:{
          'font-bold': _vm.hasRequiredActionMessages(item),
        }},[_c('span',[_vm._v(_vm._s(item.label))]),(_vm.hasRequiredActionMessages(item))?_c('span',{staticClass:"font-bold block pr-5 text-red"},[_vm._v(" "+_vm._s(_vm.requiredActionMessagesCount)+" ")]):_vm._e()])],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }