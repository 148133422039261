export const validateCheckboxValues = (checkboxValues) => {
    if (!Array.isArray(checkboxValues)) {
        return false;
    }
    return checkboxValues.some((checkboxValue) => checkboxValue);
};
export const isUrlValid = (value) => {
    try {
        const url = new URL(value);
        const hostWithoutPrefix = url.host.replace('www.', '');
        const isProtocolValid = url.origin.startsWith('http://') || url.origin.startsWith('https://');
        const areHostDotsValid = hostWithoutPrefix.includes('.') && !hostWithoutPrefix.endsWith('.');
        return isProtocolValid && areHostDotsValid;
    }
    catch {
        return false;
    }
};
