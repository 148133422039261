<template>
  <div>
    <h3 class="text-2xl my-4">{{ $t(`place-dashboard.your-objects`) }}</h3>
    <fetch-data v-slot="{ results }" url="object-types" :params="{ brandId }">
      <tabs>
        <tab
          v-for="(objectType, index) in results"
          :key="objectType.id"
          :name="objectType.name"
          :selected="index === 0"
        >
          <fetch-data
            v-slot="{ results: objects }"
            url="objects"
            :params="{
              placeIds: placeId,
              brandId,
              'objectTypeIds[]': objectType.id,
              internalStatus: onlyActive ? OBJECT_STATUS_MAP.ACTIVE : undefined,
            }"
          >
            <div class="wrapper">
              <card
                v-for="object in objects"
                :key="object.id"
                :image="object.imageUrl"
                class="card"
                number-title
                :number="object.internalNumber"
                cover-img
                :link="getRoute(object, objectType)"
                :alt="
                  $t('object.object-alt', {
                    internalNumber: object.internalNumber,
                  })
                "
              >
                <div class="card-footer">
                  <p>{{ object.address }}</p>
                  <p>{{ object.postalCode }} {{ object.city }}</p>
                </div>
              </card>
            </div>
          </fetch-data>
          <div class="text-center mt-4">
            <btn
              class="w-50 secondary mt-2"
              @click="
                $router.push({
                  name: 'Objects',
                  params: {
                    objectTypeId: objectType.id,
                  },
                })
              "
            >
              {{ $t(`place-dashboard.show-all`) }}
            </btn>
          </div>
        </tab>
      </tabs>
    </fetch-data>
  </div>
</template>

<script>
import Tab from '@/components/Tabs/Tab';
import Tabs from '@/components/Tabs/Tabs';
import FetchData from '@/components/DataProvider/FetchData';
import Card from '@/components/Card/Card';
import Btn from '@/components/Button/Button';
import { OBJECT_STATUS_MAP } from '@/constants/object';

export default {
  components: { Card, FetchData, Tabs, Tab, Btn },
  props: {
    brandId: {
      type: String,
      default: '',
    },
    placeId: {
      type: String,
      default: '',
    },
    onlyActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OBJECT_STATUS_MAP,
    };
  },
  methods: {
    getRoute(object, objectType) {
      const name =
        object.internalStatus === OBJECT_STATUS_MAP.IN_OFFER
          ? 'ObjectInOfferDetails'
          : 'ObjectDetails';

      return {
        name,
        params: { objectTypeId: objectType.id, objectId: object.id },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @apply flex flex-wrap w-full;
}

.card {
  @apply w-36 h-50 mr-4 mt-4;

  &-footer {
    @apply w-full px-2 text-xs font-semibold overflow-hidden;

    p {
      @apply whitespace-no-wrap overflow-x-hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
