import { defineComponent, ref } from '@vue/composition-api';
import { TextInput } from '@/components/Inputs';
import { Btn, BtnTheme } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { mdiMagnify } from '@mdi/js';
import FiltersSvg from '@/assets/icons/filters.svg';
export default defineComponent({
    components: { Icon, TextInput, Btn },
    props: {
        filtersApplied: {
            type: Boolean,
            default: false,
        },
        filterDivider: Boolean,
        hideFiltersBtn: Boolean,
        filtersBtnBorder: Boolean,
        activeSearch: Boolean,
    },
    setup() {
        const search = ref('');
        const isSearchActive = ref(false);
        const showSearchInput = () => {
            isSearchActive.value = true;
        };
        const onBlur = () => {
            if (!search.value) {
                isSearchActive.value = false;
            }
        };
        return {
            mdiMagnify,
            FiltersSvg,
            BtnTheme,
            search,
            isSearchActive,
            showSearchInput,
            onBlur,
        };
    },
});
