import { ref } from '@vue/composition-api';
export const useConfirmation = () => {
    const requestConfirmation = ref(false);
    const confirmationCallback = ref(() => { });
    const onConfirm = () => {
        confirmationCallback.value();
        requestConfirmation.value = false;
    };
    const actionWithConfirmation = (shouldAskConfirmation, handler) => {
        if (shouldAskConfirmation) {
            confirmationCallback.value = handler;
            requestConfirmation.value = true;
        }
        else {
            handler();
        }
    };
    return { requestConfirmation, actionWithConfirmation, onConfirm };
};
