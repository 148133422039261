import { defineComponent, onMounted, ref, } from '@vue/composition-api';
import { mdiCalendarMonth } from '@mdi/js';
import Icon from '@/components/Icon/Icon.vue';
import { format } from 'date-fns';
import { mdiChevronDown } from '@mdi/js';
import { isTextTruncated } from '@/util/dom';
export default defineComponent({
    methods: { format },
    components: { Icon },
    props: {
        user: Object,
        vacation: Object,
    },
    setup() {
        const messageRef = ref(null);
        const isMessageExpandable = ref(false);
        const isExpanded = ref(false);
        onMounted(() => {
            if (!messageRef.value) {
                return;
            }
            isMessageExpandable.value = isTextTruncated(messageRef.value);
        });
        return {
            messageRef,
            isExpanded,
            isMessageExpandable,
            mdiCalendarMonth,
            mdiChevronDown,
        };
    },
});
