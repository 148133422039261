import { BTN_THEMES } from '@/store/modules/ui';
import { defineComponent, ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { getObjectListQuery } from '@/views/Brand/Applications/Components/Resources/resources.utils';
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import { ResourceType } from '@/constants/resources';
import ResourcesModal from '@/views/Brand/Applications/Components/Resources/ResourcesModal.vue';
import ModalDisplay from '@/components/Modal/ModalDisplay.vue';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
export default defineComponent({
    components: {
        LoadingSpinner,
        ModalDisplay,
        ResourcesModal,
        ConfirmModal,
        FetchData,
    },
    props: {
        reportId: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { authUserId } = useAuthUser();
        const queryParams = { userId: authUserId.value };
        const showSelectPlacesModal = ref(true);
        const formattedSinglePlaceLabel = ({ name, number }) => {
            return `${name} (${number})`;
        };
        const isSinglePlace = (places) => {
            return places.length === 1;
        };
        return {
            BTN_THEMES,
            queryParams,
            formattedSinglePlaceLabel,
            getObjectListQuery,
            ResourceType,
            showSelectPlacesModal,
            isSinglePlace,
        };
    },
});
