var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('select-input',{staticClass:"mt-2 mb-5",attrs:{"id":"structureId","url":"structures","query":{ brandId: _vm.brandId },"placeholder":_vm.$t('filters.structure-placeholder'),"label":_vm.$t('filters.structure'),"option-label-name":"name","option-value-name":"id","autofocus":"","clearable":""},model:{value:(_vm.placeFilters.structure),callback:function ($$v) {_vm.$set(_vm.placeFilters, "structure", $$v)},expression:"placeFilters.structure"}}),_c('select-input',{staticClass:"mt-2 mb-5",attrs:{"id":"userId","url":"users","query":{
      brandId: _vm.brandId,
      systemRoles: [_vm.SystemRole.PLACE_MANAGER],
    },"placeholder":_vm.$t('filters.place-admin-placeholder'),"label":_vm.$t('filters.place-admin'),"option-value-name":"id","debounce":"","autofocus":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var selection = ref.selection;
return [_c('span',[_vm._v(_vm._s(selection.firstName)+" "+_vm._s(selection.lastName))])]}},{key:"option",fn:function(ref){
    var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName))])]}}]),model:{value:(_vm.placeFilters.user),callback:function ($$v) {_vm.$set(_vm.placeFilters, "user", $$v)},expression:"placeFilters.user"}}),_c('select-input',{staticClass:"mt-2",attrs:{"query":{ brandId: _vm.brandId, relation: 'place' },"url":"tags","debounce":"","multiple":"","autocomplete":"","autofocus":"","clearable":"","option-value-name":"id","option-label-name":"name","placeholder":_vm.$t('filters.tags-placeholder'),"label":_vm.$t('filters.tags')},model:{value:(_vm.placeFilters.tags),callback:function ($$v) {_vm.$set(_vm.placeFilters, "tags", $$v)},expression:"placeFilters.tags"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }