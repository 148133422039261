import { formatMoney } from '@/util/formatters';
export const getCellValue = (ctx, column, stats) => {
    if (column.key === 'name') {
        return stats.name;
    }
    return column.isPercent
        ? `${stats[column.key] || 0} %`
        : `${formatMoney(stats[column.key] || 0, ctx.root.$t('common.currency'))}`;
};
export const getCellStyle = (column, stats) => {
    if (!column.isPercent) {
        return '';
    }
    return (stats[column.key] || 0) > 100 ? 'text-red' : 'text-green';
};
