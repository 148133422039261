import { EXPAND_BUTTON_POSITION } from '@/constants/ui';

export default {
  props: {
    expandableRows: {
      type: Boolean,
      default: false,
    },
    expandButtonPosition: {
      type: String,
      default: EXPAND_BUTTON_POSITION.START,
      validate: (value) =>
        Object.values(EXPAND_BUTTON_POSITION).includes(value),
    },
    expandButtonStyle: {
      type: String,
    },
  },
  data() {
    return {
      expandedRows: [],
      EXPAND_BUTTON_POSITION,
    };
  },
  methods: {
    onExpandRow(index) {
      if (this.expandedRows.includes(index)) {
        this.expandedRows = this.expandedRows.filter((row) => row !== index);
      } else {
        this.expandedRows.push(index);
      }
    },
  },
};
