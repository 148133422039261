<template>
  <div class="bg-neutral min-h-full">
    <overlap-sidebar
      width="50%"
      side="right"
      :is-open="isOpen"
      @close="toggleSidebar"
    >
      <tabs class="pb-48">
        <tab :name="$t('categories-view.add-category')" selected>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onAddCategory)">
              <div class="pb-4 mt-4 flex justify-end">
                <btn class="w-30" type="submit">{{ $t('common.add') }}</btn>
              </div>

              <image-uploader icon :img-url.sync="iconUrl" />

              <ValidationProvider v-slot="{ errors }" rules="required">
                <text-input
                  v-model="categoryName"
                  :label="$t('categories-view.name')"
                  :placeholder="$t('categories-view.name-input-placeholder')"
                  :error-message="errors[0]"
                />
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </tab>
      </tabs>
    </overlap-sidebar>
    <div class="px-10 py-8">
      <div class="mb-6">
        <breadcrumbs
          v-if="brand"
          :path="[
            {
              name: `${$t('categories-view.categories')}`,
              url: { name: 'Categories' },
            },
            { name: brand.name },
            { name: `${$t('categories-view.add-category')}` },
          ]"
        />
      </div>
      <div
        v-if="!isCategoryCreated"
        class="w-50 h-50 flex items-center justify-center border border-dashed border-gray-light bg-white cursor-pointer"
        @click="isOpen = true"
      >
        <icon :icon="mdiPlus" :size="IconSize.XL" />
      </div>
      <div v-else class="w-3/5">
        <tree-category-add
          class="mt-4"
          :label="newCategory.name"
          :nodes="newCategory.children"
          :parent-id="newCategory.id"
          :icon="newCategory.iconUrl"
          root
          :depth="-1"
          @categoryUpdated="onUpdateCategory"
        />
      </div>
    </div>
    <footer-menu class="bg-white">
      <div class="flex justify-between">
        <div class="flex items-center">
          <btn disabled class="w-48 mr-3">
            <span>{{ $t('common.save') }}</span>
          </btn>
        </div>
        <router-link :to="{ name: 'Categories' }">
          <btn theme="none">{{ $t('common.cancel') }}</btn>
        </router-link>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import { Icon, IconSize } from '@/components/Icon';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar.vue';
import TreeCategoryAdd from '@/components/Tree/TreeCategoryAdd';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import { TextInput } from '@/components/Inputs';
import VueStore from '@/store';
import { mdiPlus } from '@mdi/js';

export default {
  name: 'AddCategory',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    Icon,
    ImageUploader,
    OverlapSidebar,
    Tabs,
    Tab,
    TreeCategoryAdd,
    TextInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      mdiPlus,
      IconSize,
      isOpen: false,
      categoryName: '',
      iconUrl: '',
      subCategoryName: '',
      newCategory: {},
      loading: false,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      categories: 'categories',
      currentCategory: 'currentCategory',
      error: 'error',
    }),
    isCategoryCreated() {
      return Object.keys(this.newCategory).length !== 0;
    },
  },
  watch: {
    categories(newVal) {
      if (newVal) {
        let found = newVal.results?.find(
          (category) => category.id === this.newCategory.id,
        );
        if (found !== undefined) {
          this.newCategory = { ...found };
        }
      }
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  methods: {
    ...mapActions('brand', [
      'addCategory',
      'addRootCategory',
      'fetchBrandCategory',
    ]),
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    async onAddCategory() {
      const { data } = await this.addRootCategory({
        id: this.$route.params.id,
        data: {
          name: this.categoryName,
          ...(this.iconUrl && { iconUrl: this.iconUrl }),
        },
      });

      this.newCategory = data;
      this.isOpen = false;
      this.$router.push({ name: 'Categories' });
    },
    onUpdateCategory() {
      const brandId = this.$route.params.id || VueStore.getters['user/brand'];
      const categoryId = this.newCategory.id;

      this.fetchBrandCategory({ brandId, categoryId });
    },
  },
};
</script>
