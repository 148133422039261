<template>
  <ul class="category-list" :class="{ 'pb-2 pt-4': !selected }">
    <li v-for="(category, i) in categories" :key="category.id">
      <div
        v-if="selected"
        class="category selected"
        :class="{ 'opacity-75': i >= hover }"
      >
        {{ category.name }}
        <button
          type="button"
          class="delete-button"
          :disabled="loading"
          @click="$emit('unselect', category)"
          @mouseover="() => (hover = i)"
          @mouseleave="() => (hover = categories.length + 1)"
        >
          <icon :icon="mdiClose" :size="IconSize.XXS" />
        </button>
      </div>
      <button
        v-else
        type="button"
        class="category"
        :disabled="loading"
        @click="$emit('select', category)"
      >
        {{ category.name }}
      </button>
    </li>
    <li v-if="showPagination">
      <button
        type="button"
        class="category"
        :disabled="loading"
        @click="$emit('load-more')"
      >
        ...
      </button>
    </li>
  </ul>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';

export default {
  components: {
    Icon,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IconSize,
      mdiClose,
      hover: this.categories.length + 1,
    };
  },
};
</script>

<style scoped lang="scss">
.category-list {
  @apply flex flex-wrap border-b-2 border-gray-lightest;
}

.category {
  @apply h-8 px-4 bg-yellow-light rounded text-xxs font-bold transition-all duration-300 mr-2 mb-2;

  &:hover {
    @apply bg-yellow;
  }

  &:disabled {
    @apply bg-gray-dark;
  }

  &.selected {
    @apply bg-gray-darkest text-white flex items-center font-normal relative mr-4 mb-4;

    .delete-button {
      @apply absolute h-4 w-4 rounded-full bg-orange -top-2 -right-2 text-gray-darkest flex items-center justify-center;
    }
  }
}
</style>
