import { defineComponent } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import { mdiTrayArrowDown } from '@mdi/js';
export default defineComponent({
    components: {
        Icon,
    },
    props: {
        fileName: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    setup() {
        return {
            IconSize,
            mdiTrayArrowDown,
        };
    },
});
