import { computed, defineComponent, onBeforeUnmount, ref, watch, } from '@vue/composition-api';
import MessageTemplate from '@/components/MessageTemplate/MessageTemplate.vue';
import Btn from '@/components/Button/Button.vue';
import TaskForm from '@/views/Brand/Applications/Forms/TaskForm.vue';
import TaskChatForm from '@/views/Brand/Applications/Forms/TaskChatForm.vue';
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import Recipients from '@/views/Brand/Applications/Components/Recipients/Recipients.vue';
import { addSupervisorToReport, deleteSupervisorFromReport, ReportType, updateChatReport, updateFormReport, } from '@/api/requests/reports';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { useTaskEditor } from '@/views/Brand/Applications/Report/_composables/use-task-editor';
import { addReportDescription, handleReportReceivers, handleReportResources, updateReportDescription, } from '@/views/Brand/Applications/Report/_utils/add-report-assets';
import { useReportDetails } from '@/views/Brand/Applications/Report/_composables/use-report-details';
import { useReportRecipients } from '@/views/Brand/Applications/Report/_composables/use-report-recipients';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import { getTrimmedText } from '@/util/text';
export default defineComponent({
    components: {
        LoadingSpinner,
        Recipients,
        ConfirmModal,
        TaskChatForm,
        TaskForm,
        MessageTemplate,
        Btn,
    },
    props: {
        reportId: {
            type: String,
            required: true,
        },
        closeReportRequest: {
            type: Number,
            default: 0,
        },
        closeReportCallback: {
            type: Function,
            default: () => { },
        },
    },
    setup(props, ctx) {
        const { formId, loading, requestConfirmation, confirmViewChange, onConfirm, close, taskFormReference, } = useTaskEditor(props, ctx);
        const uploadInProgress = ref(false);
        const isCategorySelected = ref(false);
        const { loading: loadingReport, report, message, error, } = useReportDetails(props.reportId);
        const { users, supervisor, assignedUsers, removedUserIds, onAddUser, decisionMakerIds, removedDecisionMakerIds, removeUser, revertRemoveUser, onSelectUsers, loadAllUsers, loadingUsers, onSetUsers, removeUsers, } = useReportRecipients(report.value);
        const formWithCategory = computed(() => {
            return report.value?.type === ReportType.FORM;
        });
        const updateMessage = (description, fileTokens) => {
            return message.value
                ? updateReportDescription({
                    message: message.value,
                    description,
                    fileTokens,
                })
                : addReportDescription({
                    reportId: props.reportId,
                    message: description,
                    fileTokens,
                });
        };
        const handleSupervisorUpdate = async () => {
            if (!report.value) {
                return;
            }
            if (!supervisor.value && report.value.supervisor) {
                return await deleteSupervisorFromReport(report.value.id, report.value.supervisor.id);
            }
            if (supervisor.value &&
                supervisor.value?.id !== report.value?.supervisor?.id) {
                await addSupervisorToReport(report.value.id, supervisor.value?.id);
            }
        };
        const updateReportAssets = async ({ resources, description, fileTokens, }) => {
            await Promise.all([
                handleReportReceivers({
                    reportId: props.reportId,
                    users: users.value,
                    removedUserIds: removedUserIds.value,
                    decisionMakerIds: decisionMakerIds.value,
                }),
                handleReportResources(props.reportId, resources),
                updateMessage(description, fileTokens),
            ]);
            await handleSupervisorUpdate();
        };
        const onSubmitChatForm = async (form) => {
            loading.value = true;
            try {
                await updateChatReport(props.reportId, {
                    title: getTrimmedText(form.title),
                });
                await updateReportAssets(form);
                ctx.root.$toast.success(ctx.root.$t('edit-task.task-updated'));
            }
            finally {
                loading.value = false;
                ctx.emit('form-submit');
            }
        };
        const onSubmitCategoryForm = async (form) => {
            loading.value = true;
            try {
                if (form.categoryForm) {
                    const { formVersionId, values } = form.categoryForm;
                    await updateFormReport(props.reportId, {
                        formVersionId,
                        values,
                        title: getTrimmedText(form.title),
                    });
                    await updateReportAssets(form);
                    ctx.root.$toast.success(ctx.root.$t('edit-task.task-updated'));
                }
            }
            finally {
                loading.value = false;
                ctx.emit('form-submit');
            }
        };
        const { resetPlacesState } = usePlaceResourcesStore();
        const { resetObjectsState } = useObjectResourcesStore();
        const onCategoryUpdate = (category) => {
            isCategorySelected.value = category !== null;
        };
        watch(report, (value) => {
            if (value) {
                supervisor.value = report.value?.supervisor || null;
                loadAllUsers(props.reportId, value.creator.id);
            }
        });
        onBeforeUnmount(() => {
            resetPlacesState();
            resetObjectsState();
        });
        return {
            report,
            users,
            supervisor,
            loading,
            loadingReport,
            formId,
            requestConfirmation,
            onAddUser,
            onSubmitChatForm,
            onSubmitCategoryForm,
            confirmViewChange,
            close,
            onConfirm,
            removedUserIds,
            decisionMakerIds,
            removedDecisionMakerIds,
            removeUser,
            revertRemoveUser,
            formWithCategory,
            message,
            error,
            taskFormReference,
            onSelectUsers,
            uploadInProgress,
            onCategoryUpdate,
            isCategorySelected,
            assignedUsers,
            loadingUsers,
            onSetUsers,
            removeUsers,
        };
    },
});
