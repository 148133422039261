<template>
  <div>
    <p v-if="label" class="sort-label">{{ label }}</p>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'FilterWrapper',
  props: {
    label: {
      type: String,
      default: null,
    },
    parameter: {
      type: String,
      required: true,
    },
    value: {
      type: null,
    },
    forceUpdate: {
      type: [Boolean, Number],
      default: false,
    },
  },
  computed: {
    ...mapState('routes', ['filters']),
    ...mapGetters('routes', ['routeFilters', 'filtersApplied']),
  },
  watch: {
    forceUpdate: {
      handler() {
        this.applyFilter();
      },
      deep: true,
    },
    value: {
      handler() {
        this.applyFilter();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('routes', ['updateFilter', 'setRoutePagination']),
    async applyFilter() {
      this.updateFilter({
        route: this.$route,
        parameter: this.parameter,
        value: this.value,
      });
    },
  },
};
</script>
<style lang="scss">
.sort-label {
  @apply uppercase text-sm text-gray-dark font-semibold;
}
</style>
