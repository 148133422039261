import 'vue-swatches/dist/vue-swatches.css';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import i18n from './i18n';
import Toast from 'vue-toastification';
import App from './App.vue';
import router from './router';
import store from './store';
import PortalVue from 'portal-vue';
import VueSocketIO from 'vue-socket.io';
import { io } from 'socket.io-client';
import './assets/tailwind.css';
import './styles/main.scss';
import debounceInput from './directives/debounce-input';
import toastOptions from '@/config/toast';
// @ts-ignore
import vClickOutside from 'v-click-outside';
import { LOCAL_STORAGE_KEY, useLocalStorage, } from '@/composables/use-local-storage';
Vue.use(VueCompositionAPI);
Vue.use(Toast, toastOptions);
Vue.use(vClickOutside);
Vue.use(PortalVue);
const { storedValue } = useLocalStorage(LOCAL_STORAGE_KEY.OBJEKTO);
Vue.use(new VueSocketIO({
    debug: process.env.NODE_ENV === 'development',
    connection: io(process.env.VUE_APP_API_URL, {
        transportOptions: {
            polling: {
                extraHeaders: {
                    Authorization: storedValue.value?.user.token,
                },
            },
        },
        autoConnect: false,
    }),
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_',
    },
}));
Vue.config.productionTip = false;
// @ts-ignore
Vue.directive('debounceInput', debounceInput);
export const rootOptions = {
    router,
    store,
    i18n,
};
export default new Vue({
    ...rootOptions,
    render: (h) => h(App),
}).$mount('#app');
