export const OBJECT_STATUS_MAP = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  IN_OFFER: 'in-offer',
};

export const OBJECT_STATUS_COLORS = {
  [OBJECT_STATUS_MAP.ACTIVE]: 'bg-green',
  [OBJECT_STATUS_MAP.INACTIVE]: 'bg-gray-dark',
  [OBJECT_STATUS_MAP.IN_OFFER]: 'bg-blue-dark',
};

export const OBJECT_LOCALIZATION = {
  IN_BUILDING: 'inBuilding',
  ON_BUILDING: 'onBuilding',
  NEXT_TO_BUILDING: 'nextToBuilding',
  OUTSIDE: 'outside',
};
