export default {
  methods: {
    getConfirmModalObject(type, name) {
      return {
        type: 'confirm',
        title: this.$t('confirm-delete-modal.modal-title', {
          type: this.$t(`confirm-delete-modal.${type}`),
        }),
        description: this.$t('confirm-delete-modal.modal-description', {
          name,
          type: this.$t(`confirm-delete-modal.${type}`),
        }),
        btnText: this.$t('confirm-delete-modal.modal-btn'),
      };
    },
  },
};
