<template>
  <div class="upload-area">
    <div>
      <vue-dropzone
        id="dropzone"
        ref="dropzone"
        class="upload-drag-zone"
        :options="dropzoneOptions"
        use-custom-slot
        @vdropzone-success="handleFile"
        @vdropzone-file-added="onStart"
        @vdropzone-complete="onComplete"
        @vdropzone-error="onError"
        @vdropzone-removed-file="onRemoved"
      >
        <icon
          class="mt-1 mx-auto mb-0 bg-primary text-white rounded-full"
          :icon="mdiPlus"
          size="xl"
        />
        <p class="text-lg">{{ $t('file-upload.drag-file-here') }}</p>
        <p class="text-sm mb-3">{{ $t('file-upload.or-choose-a-file') }}</p>
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import vueDropzone from 'vue2-dropzone';
import { extension } from '@/util/formatters';
import { apiUrl } from '@/api';
import { mdiPlus } from '@mdi/js';
import {
  LOCAL_STORAGE_KEY,
  useLocalStorage,
} from '@/composables/use-local-storage';

export default {
  components: {
    Icon,
    vueDropzone,
  },
  model: {
    event: 'update:file',
    prop: 'file',
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    acceptedFiles: {
      type: String,
      default: null,
    },
  },
  data() {
    const { storedValue } = useLocalStorage(LOCAL_STORAGE_KEY.OBJEKTO);
    const authToken = storedValue.value?.user.token;
    return {
      mdiPlus,
      isUploaded: false,
      isUploading: false,
      extension: '',
      dropzoneOptions: {
        url: `${apiUrl}/files`,
        headers: { Authorization: authToken },
        maxFilesize: 100,
        maxFiles: 1,
        timeout: 0,
        createImageThumbnails: false,
        addRemoveLinks: true,
        acceptedFiles: this.acceptedFiles,
        dictCancelUpload: "<span class='dz-cancel'>x</span>",
        dictRemoveFile: `<svg class="fill-primary" width="24" height="24">
        <path d="M16.423 22H7.654a2.195 2.195 0 01-2.192-2.192V7.385H4V5.923h4.385v-.736A2.189 2.189 0 0110.571 3h2.935a2.189 2.189 0 012.186 2.187v.736h4.385v1.462h-1.462v12.423A2.195 2.195 0 0116.423 22zm-9.5-14.615v12.423a.723.723 0 00.731.731h8.769a.723.723 0 00.731-.731V7.385zm3.648-2.923a.709.709 0 00-.724.725v.736h4.385v-.736a.709.709 0 00-.725-.725zm2.2 14.615h-1.463V8.846h1.462v10.23zm-2.923 0H8.385V8.846h1.461v10.23zm5.845 0h-1.462V8.846h1.461v10.23z"/>
        </svg>`,
        previewTemplate: this.template(),
      },
    };
  },
  mounted() {
    this.$emit('update:is-error', false);
  },
  methods: {
    template: function () {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-details flex items-center">
                    <div class="extension-icon mr-2">
                      <span class="extension dz-icon"></span>
                    </div>
                    <div class="dz-filename font-semibold text-sm"><span data-dz-name></span></div>
                    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    <svg width="34px" height="34px" class="dz-spinner spinner stroke-primary" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke-width="5" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="matrix(1,0,0,1,0,0)" ></circle></svg>
                    </svg>
                </div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
              </div>
      `;
    },
    handleFile(file, response) {
      const extensionName = extension(file.name);
      file.previewElement.querySelector(
        '.extension-icon .extension',
      ).textContent = extensionName;

      this.$emit('update:file', {
        name: file.name,
        url: response.url,
        extension: extensionName,
      });
    },
    onStart() {
      this.$emit('update:is-uploading', true);
      this.error = false;
    },
    onComplete() {
      this.$emit('update:is-uploading', false);
    },
    onError(file) {
      file.previewElement.querySelector(
        '.extension-icon .extension',
      ).textContent = 'x';
      this.$emit('update:is-error', true);
    },
    onRemoved(file) {
      if (file && this.file && file.name === this.file.name)
        this.$emit('update:file', null);
    },
  },
};
</script>

<style lang="scss">
.upload-area {
  @apply max-h-40 overflow-auto mb-8;
}
.dz-started {
  .dz-message {
    @apply hidden;
  }
}
.dz-error {
  .dz-icon {
    @apply lowercase text-base mb-1;
  }
  .dz-error-message {
    @apply absolute left-0 text-2xs text-red;
    left: 49px;
    bottom: 9px;
  }
}
.dz-icon {
  @apply uppercase text-primary text-3xs;
}
.dz-message {
  @apply py-1 w-full cursor-pointer;
  &:hover {
    @apply bg-gray-lighter;
  }
}
.dz-drag-hover {
  .dz-message {
    @apply bg-gray-lighter;
  }
}
.dz-cancel {
  padding: 0px 12px 4px;
  @apply block font-semibold relative z-50;
}
.dz-spinner {
  @apply absolute z-30;
  right: 11px;
}
.dz-complete {
  .dz-spinner {
    @apply hidden;
  }
}
.dz-progress {
  @apply w-full absolute;
  bottom: -1px;
}
.dz-upload {
  height: 2px;
  border-radius: 3px;
  @apply bg-primary block;
}
.dz-filename {
  @apply max-w-full overflow-hidden whitespace-no-wrap;
  max-width: 300px;
}
.dz-error .dz-filename {
  @apply relative;
  top: -6px;
}
.dz-preview {
  @apply flex w-full relative items-center justify-between pr-3 py-0.5;
}
.upload-drag-zone {
  @apply relative flex flex-col items-center rounded border border-gray-light;
}
.uploaded-file {
  @apply flex justify-between items-center w-full rounded border border-gray-light py-1 mb-8;
}
.upload-title {
  @apply text-sm mb-0;
}
</style>
