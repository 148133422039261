import { defineComponent } from '@vue/composition-api';
import { Btn, BtnTheme } from '@/components/Button';
export default defineComponent({
    components: { Btn },
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
        },
    },
    setup() {
        return {
            BtnTheme,
        };
    },
});
