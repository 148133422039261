import { computed, ref, watch } from '@vue/composition-api';
import { useMapState } from '@/components/Map/composables/use-map-state';
export const useDataSetsDelivery = (props, ctx) => {
    const { settings } = useMapState();
    const { dataSets, setMarkers } = props;
    const deliveredDataSetsStatusMap = ref(new Map());
    const deliveredDataSets = ref(new Map());
    const getDeliverDataKey = (url) => url.split('/').join('-');
    const areAllDataSetsDelivered = computed(() => {
        const deliveredDataSetsArray = Array.from(deliveredDataSetsStatusMap.value.values());
        if (!dataSets) {
            return true;
        }
        return deliveredDataSetsArray.every((deliverDataSet) => deliverDataSet);
    });
    const setDeliveredDataStatus = (event, isDelivered) => {
        if (!event.url) {
            return;
        }
        // Vue does not react to the Map's set() method - assignment is necessary here to trigger watchers
        deliveredDataSetsStatusMap.value = new Map(deliveredDataSetsStatusMap.value.set(getDeliverDataKey(event.url), isDelivered));
        ctx.emit('data-status-change', areAllDataSetsDelivered.value);
    };
    const handleDataPending = (event) => {
        setDeliveredDataStatus(event, false);
    };
    const setDeliveredDataSet = (fetchedMapDataSet, dataSet) => {
        // Vue does not react to the Map's set() method - assignment is necessary here to trigger watchers
        deliveredDataSets.value = new Map(deliveredDataSets.value.set(getDeliverDataKey(fetchedMapDataSet.url), {
            dataSet,
            fetchedMapDataSet,
        }));
    };
    const handleDataDeliver = (fetchedMapDataSet, dataSet) => {
        setDeliveredDataStatus(fetchedMapDataSet, true);
        setDeliveredDataSet(fetchedMapDataSet, dataSet);
        setMarkers({ fetchedMapDataSet, dataSet });
    };
    const rerenderDeliveredDataSetsMarkers = () => {
        for (const { dataSet, fetchedMapDataSet, } of deliveredDataSets.value.values()) {
            setMarkers({ fetchedMapDataSet, dataSet, center: false });
        }
    };
    watch(settings, rerenderDeliveredDataSetsMarkers);
    return {
        handleDataPending,
        areAllDataSetsDelivered,
        handleDataDeliver,
    };
};
