import { createOfferDetailsRoute } from '@/router/utils';
import { handleTranslations, } from '@/layouts/_components/Notifications/_util/notification-factory';
export const handleOfferNotification = (notification) => {
    const { type, payload: { brand, offer }, } = notification;
    return {
        getTranslations() {
            const translationPlaceholders = {
                ...offer,
            };
            return handleTranslations(type, translationPlaceholders);
        },
        getRoute() {
            return createOfferDetailsRoute(brand.id, offer.id);
        },
    };
};
