<script>
import api from '@/api';
import isEqual from 'lodash.isequal';
import { fetchAllItems } from '@/util/api';
import debounce from 'lodash.debounce';

export default {
  name: 'FetchData',
  props: {
    url: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    forceUpdate: {
      type: [Boolean, Number],
      default: false,
    },
    fetchAll: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: [Number, Boolean],
      default: false,
    },
  },
  data() {
    return {
      data: [],
      loading: true,
    };
  },
  computed: {
    getDataCallback() {
      return this.debounce
        ? debounce(this.getData, this.debounce)
        : this.getData;
    },
  },
  watch: {
    url: {
      handler() {
        this.fetch(this.url, this.params);
      },
      immediate: true,
    },
    forceUpdate(val) {
      if (val) this.fetch(this.url, this.params);
    },
    params: {
      handler(val, prev) {
        if (!isEqual(val, prev)) this.fetch(this.url, this.params);
      },
      deep: true,
    },
  },
  methods: {
    async fetchData(url, params) {
      if (this.fetchAll) {
        const results = await fetchAllItems(url, params);
        return { data: { results } };
      }

      return api.get(url, { params });
    },
    async getData(url, params) {
      try {
        this.loading = true;
        this.$emit('data-pending', { url: this.url });
        const { data } = await this.fetchData(url, params);

        this.data = data;
        this.loading = false;
        this.$emit('data-deliver', { ...this.data, url: this.url });
      } catch {
        this.loading = false;
        throw new Error('Data error!');
      }
    },
    fetch(url, params) {
      this.loading = true;

      return this.getDataCallback(url, params);
    },
  },
  render(createElement) {
    return createElement(
      'div',
      this.$scopedSlots.default &&
        this.$scopedSlots.default({
          data: this.data,
          results: this.data.results,
          fetch: this.fetch,
          pagination: this.data.pagination,
          isLoading: this.loading,
        }),
    );
  },
};
</script>
