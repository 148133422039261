import Checkbox from './Checkbox.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import FormElement from '@/components/Inputs/_components/FormElement/FormElement.vue';
import { formElementProps } from '@/components/Inputs/_components/FormElement';
import InputLabel from '@/components/Inputs/_components/InputLabel/InputLabel.vue';
export default defineComponent({
    components: { InputLabel, FormElement, Checkbox },
    props: {
        ...formElementProps,
        value: {
            type: [Array, String],
        },
        label: String,
        required: Boolean,
        items: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
            required: true,
        },
        customLabel: Boolean,
        singleSelect: Boolean,
    },
    setup(props, ctx) {
        const getInitialValue = () => {
            if (props.singleSelect)
                return props.value || null;
            else if (Array.isArray(props.value) &&
                props.value.length === props.items?.length) {
                return [...props.value];
            }
            else {
                return props.items.map(() => false);
            }
        };
        const checkboxValues = ref(getInitialValue());
        const onInput = (index) => {
            if (props.singleSelect) {
                checkboxValues.value =
                    checkboxValues.value === props.items[index]
                        ? null
                        : props.items[index];
            }
            else if (checkboxValues.value) {
                checkboxValues.value[index] = !checkboxValues.value[index];
            }
            ctx.emit('input', checkboxValues.value);
        };
        watch(() => props.value, () => {
            checkboxValues.value = getInitialValue();
        });
        return {
            onInput,
            checkboxValues,
        };
    },
});
