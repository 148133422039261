import { mdiCheck, mdiClose } from '@mdi/js';
import { defineComponent } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import { Btn, BtnTheme } from '@/components/Btn';
import PencilSvg from '@/assets/icons/pencil.svg';
import { useAuthUser } from '@/composables';
import { SystemRole } from '@/api/requests/users';
export default defineComponent({
    components: { Btn, Icon },
    props: {
        isEdit: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const { hasRole } = useAuthUser();
        const showActions = hasRole(SystemRole.SUPER_ADMIN) ||
            hasRole(SystemRole.ADMIN) ||
            hasRole(SystemRole.BRAND_ADMIN);
        return { IconSize, BtnTheme, PencilSvg, mdiCheck, mdiClose, showActions };
    },
});
