<template>
  <div class="sidebar" :class="[side]" :style="{ width: slideTransition }">
    <div class="h-full overflow-x-hidden">
      <div
        class="sidebar-scroll-container"
        :style="{
          width: sideBarWidth > 100 ? `${sideBarWidth}px` : `${sideBarWidth}vw`,
        }"
      >
        <div class="sidebar-container" :class="opacityTransition">
          <slot />
        </div>
      </div>
    </div>
    <button
      v-if="showCustomButton"
      type="button"
      class="sidebar-toggle focus:outline-none sidebar-toggle-custom-button"
      :class="toggleButton"
      @click="$emit('custom-button-click')"
    >
      <icon :icon="mdiClose" />
    </button>
    <button
      type="button"
      class="sidebar-toggle focus:outline-none"
      :class="toggleButton"
      @click="$store.commit('ui/toggleSidebar')"
    >
      <icon :icon="iconName" />
    </button>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import { mapState } from 'vuex';
import { mdiClose, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export default {
  components: {
    Icon,
  },
  props: {
    sideBarWidth: {
      type: Number,
      default: 328,
    },
    showCustomButton: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return { mdiClose, mdiChevronLeft, mdiChevronRight };
  },
  computed: {
    ...mapState('ui', {
      isOpen: (state) => state.sidebar.isOpen,
    }),
    iconName() {
      if (this.side === 'left') {
        return this.isOpen ? mdiChevronLeft : mdiChevronRight;
      } else {
        return this.isOpen ? mdiChevronRight : mdiChevronLeft;
      }
    },
    slideTransition() {
      if (!this.isOpen) {
        return '24px';
      }
      return this.sideBarWidth > 100
        ? `${this.sideBarWidth}px`
        : `${this.sideBarWidth}%`;
    },
    opacityTransition() {
      if (!this.isOpen) {
        return 'sidebar-container-transition';
      }
      return null;
    },
    toggleButton() {
      return `sidebar-toggle-${this.side}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.sidebar {
  @apply relative items-stretch w-82 bg-white z-10 flex-shrink-0;
  will-change: width;
}
.sidebar-scroll-container {
  @apply px-4 overflow-x-hidden overflow-y-auto pt-8 h-full;
  max-height: 100%;

  @screen md {
    @apply px-6;
  }

  @screen lg {
    @apply px-10;
  }
}
.left {
  @apply border-r border-r-gray-light;
}
.right {
  @apply border-l border-l-gray-light;
}

.sidebar-toggle {
  @apply rounded-full absolute top-10 w-8 h-8 shadow-input text-primary bg-white flex justify-center items-center z-20;
  top: 8rem;
}
.sidebar-toggle-left {
  @apply -right-8;
}
.sidebar-toggle-right {
  @apply -left-8;
}
.sidebar-toggle-custom-button {
  @apply z-50 shadow-none;
}
.sidebar-container {
  @apply z-10 relative duration-500 ease-in min-h-full;
  opacity: 1;
}
</style>
