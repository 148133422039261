import { defineComponent } from '@vue/composition-api';
import { Btn, BtnTheme } from '@/components/Btn';
import { useMobileView } from '@/composables';
export default defineComponent({
    components: {
        Btn,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        removedUserIds: {
            type: Array,
            default: () => [],
        },
        loading: Boolean,
    },
    setup() {
        const { isMobileView } = useMobileView();
        return { BtnTheme, isMobileView };
    },
});
