<template>
  <transition name="fade">
    <div v-show="show" class="nav-overlay" @click.self="$emit('close')">
      <div class="nav-collapse">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.nav-overlay {
  @apply fixed w-full h-full top-0 left-0 z-30 overflow-y-auto;
  background-color: rgba($primary-dark, 0.4);
}

.nav-collapse {
  @apply fixed top-0 left-0 z-50 w-full py-4 px-2 bg-white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
