var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fetch-data',{staticClass:"relative",attrs:{"url":"places","params":_vm.placeQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [(results && results.length)?_c('table-display',{attrs:{"rows":results,"columns":_vm.columns,"is-row-expanded":_vm.isPlaceDetailsExpanded,"table-style":_vm.TableStyle.Resources},on:{"record-click":function($event){return _vm.$emit('open', $event.id)}},scopedSlots:_vm._u([{key:"expandButtonCell",fn:function(ref){
var row = ref.row;
return [_c('expand-button',{attrs:{"is-expanded":_vm.isPlaceDetailsExpanded(row),"button-style":_vm.ExpandButtonStyle.ARROW},on:{"expand":function($event){return _vm.toggleExpandPlaceDetails(row)}}})]}},{key:"checkboxCell",fn:function(ref){
var row = ref.row;
return [_c('checkbox',{attrs:{"table":"","value":_vm.isSelected(row)},on:{"input":function($event){return _vm.$emit('select', row)}}})]}},{key:"expandedRow",fn:function(ref){
var row = ref.row;
return [_c('place-preview',{attrs:{"place-id":row ? row.id : '',"hide-details-btn":"","sidebar":""}})]}}],null,true)}):(!isLoading)?_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('common.no-results')))]):_vm._e(),_c('resources-pagination',{attrs:{"path":"places","limit":_vm.RESOURCE_LIMIT,"pagination":pagination,"query":_vm.placeQuery,"is-loading":isLoading},model:{value:(_vm.activePage),callback:function ($$v) {_vm.activePage=$$v},expression:"activePage"}}),(isLoading)?_c('table-loader'):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }