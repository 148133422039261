import VueI18n from '@/i18n';
import { getFullName } from '@/util/user';
import { createReportDetailsRoute } from '@/router/utils';
import { handleTranslations, } from '@/layouts/_components/Notifications/_util/notification-factory';
export const handleUpdatedReportStatusNotification = (notification) => {
    const { type, payload: { brand, report, user, status }, } = notification;
    return {
        getTranslations() {
            const translationPlaceholders = {
                ...report,
                reportStatus: VueI18n.t(`report-status.${status}`),
                user: getFullName(user),
            };
            return handleTranslations(type, translationPlaceholders);
        },
        getRoute() {
            return createReportDetailsRoute(brand.id, report.id);
        },
    };
};
