import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useState, useActions } = createNamespacedHelpers('infiniteData');
export const useInfiniteData = () => {
    const { backwardCursor, forwardCursor, isBackwardListCompleted, isForwardListCompleted, } = useState([
        'backwardCursor',
        'forwardCursor',
        'isBackwardListCompleted',
        'isForwardListCompleted',
    ]);
    const { setBackwardCursor, setForwardCursor, setIsBackwardListCompleted, setIsForwardListCompleted, } = useActions([
        'setBackwardCursor',
        'setForwardCursor',
        'setIsBackwardListCompleted',
        'setIsForwardListCompleted',
    ]);
    return {
        backwardCursor,
        forwardCursor,
        isBackwardListCompleted,
        isForwardListCompleted,
        setBackwardCursor,
        setForwardCursor,
        setIsBackwardListCompleted,
        setIsForwardListCompleted,
    };
};
