import { defineComponent } from '@vue/composition-api';
import TreeNode from '@/components/Tree/TreeNode.vue';
export default defineComponent({
    name: 'Tree',
    components: {
        TreeNode,
    },
    props: {
        nodes: {
            type: Array,
            required: true,
        },
        isExpandable: {
            type: Function,
            required: true,
        },
        loadChildren: {
            type: Function,
            required: true,
        },
        loadMore: {
            type: Function,
            required: true,
        },
        silentUpdate: Function,
        disabled: Boolean,
        updateCount: Number,
    },
});
