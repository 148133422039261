export default {
  namespaced: true,
  state: {
    backwardCursor: null,
    forwardCursor: null,
    isBackwardListCompleted: false,
    isForwardListCompleted: false,
  },
  mutations: {
    SET_BACKWARD_CURSOR(state, cursor) {
      state.backwardCursor = cursor;
    },
    SET_FORWARD_CURSOR(state, cursor) {
      state.forwardCursor = cursor;
    },
    SET_IS_BACKWARD_LIST_COMPLETED(state, value) {
      state.isBackwardListCompleted = value;
    },
    SET_IS_FORWARD_LIST_COMPLETED(state, value) {
      state.isForwardListCompleted = value;
    },
  },
  actions: {
    setBackwardCursor({ commit }, cursor) {
      commit('SET_BACKWARD_CURSOR', cursor);
    },
    setForwardCursor({ commit }, cursor) {
      commit('SET_FORWARD_CURSOR', cursor);
    },
    setIsBackwardListCompleted({ commit }, value) {
      commit('SET_IS_BACKWARD_LIST_COMPLETED', value);
    },
    setIsForwardListCompleted({ commit }, value) {
      commit('SET_IS_FORWARD_LIST_COMPLETED', value);
    },
  },
};
