<template>
  <overlap-sidebar
    width="50%"
    side="right"
    :is-open="value"
    @close="$emit('input', false)"
  >
    <tabs>
      <tab :name="$t('categories-view.edit-category')" selected>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="pb-4 mt-4 flex justify-end">
              <btn class="w-30" type="submit">{{ $t('common.save') }}</btn>
            </div>
            <template v-if="category.id === rootCategoryId">
              <image-uploader v-model="iconUrl" icon />
            </template>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <text-input
                v-model="categoryName"
                :label="$t('categories-view.name')"
                :placeholder="$t('categories-view.name-input-placeholder')"
                :error-message="errors[0]"
              />
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </tab>
    </tabs>
  </overlap-sidebar>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import Btn from '@/components/Button/Button.vue';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import { TextInput } from '@/components/Inputs';

export default {
  components: {
    Btn,
    ImageUploader,
    OverlapSidebar,
    Tabs,
    Tab,
    TextInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    rootCategoryId: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoryName: '',
      iconUrl: '',
    };
  },
  watch: {
    category: {
      immediate: true,
      handler({ name, iconUrl }) {
        this.categoryName = name || '';
        this.iconUrl = iconUrl || '';
      },
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  methods: {
    onSubmit() {
      this.$emit('save-changes', {
        name: this.categoryName,
        iconUrl: this.iconUrl,
      });
    },
  },
};
</script>
