<template>
  <header ref="navBar" class="nav-bar">
    <preview-mode-bar />
    <div v-if="mobileView" class="nav-inner">
      <logo />
      <div class="flex items-center">
        <notifications />
        <toggler v-model="showMenu" class="z-60" />
      </div>
      <nav-collapse :show="showMenu" @close="showMenu = false">
        <locale-switcher class="mb-4" />
        <user-section />
        <nav-items :items="navigation" />
      </nav-collapse>
    </div>
    <div v-else class="nav-inner">
      <logo />
      <nav-items :items="navigation" />
      <div class="flex items-center">
        <notifications class="mb-1" />
        <account-info />
        <locale-switcher />
      </div>
    </div>
  </header>
</template>

<script>
import LocaleSwitcher from '@/layouts/Components/LocaleSwitcher.vue';
import Logo from '@/layouts/Components/Logo.vue';
import NavCollapse from '@/layouts/Components/NavCollapse.vue';
import NavItems from '@/layouts/Components/NavItems.vue';
import Notifications from '@/layouts/_components/Notifications/Notifications.vue';
import Toggler from '@/layouts/Components/Toggler.vue';
import AccountInfo from '@/layouts/Components/AccountInfo.vue';

import { mapState, mapGetters } from 'vuex';
import PreviewModeBar from '@/layouts/_components/PreviewModeBar/PreviewModeBar';
import { onMounted, onUpdated, ref } from '@vue/composition-api';
import { useUi } from '@/composables';

export default {
  components: {
    PreviewModeBar,
    LocaleSwitcher,
    Logo,
    NavCollapse,
    NavItems,
    Notifications,
    Toggler,
    AccountInfo,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    ...mapGetters('user', ['isSuperAdmin', 'isContractorUser']),
    navigation() {
      const items = [
        {
          to: 'Clients',
          label: 'clients',
        },
      ];

      if (this.isSuperAdmin) {
        items.unshift({
          to: 'Admins',
          label: 'employees',
        });
      }

      if (!this.isContractorUser) {
        items.push(
          {
            to: 'GeneralObjectTypes',
            label: 'objects',
          },
          {
            label: 'contractors',
            to: 'Contractors',
          },
        );
      }

      return items;
    },
  },
  watch: {
    mobileView(active) {
      if (!active) {
        this.showMenu = false;
      }
    },
    $route() {
      this.showMenu = false;
    },
  },
  setup(_, ctx) {
    const height = ref(0);
    const { setOffset } = useUi();

    const setHeight = () => {
      height.value = ctx.refs.navBar.getBoundingClientRect().height;
      setOffset({ component: 'navBar', value: height.value });
    };

    onMounted(setHeight);
    onUpdated(setHeight);
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  @apply w-full z-60 fixed top-0 bg-white shadow-navbar;
}

.nav-inner {
  @apply flex justify-between items-center px-4 w-full h-16;

  @screen md {
    @apply px-10;
  }
}
</style>
