var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-2xl my-4"},[_vm._v(_vm._s(_vm.$t("place-dashboard.your-objects")))]),_c('fetch-data',{attrs:{"url":"object-types","params":{ brandId: _vm.brandId }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
return [_c('tabs',_vm._l((results),function(objectType,index){return _c('tab',{key:objectType.id,attrs:{"name":objectType.name,"selected":index === 0}},[_c('fetch-data',{attrs:{"url":"objects","params":{
            placeIds: _vm.placeId,
            brandId: _vm.brandId,
            'objectTypeIds[]': objectType.id,
            internalStatus: _vm.onlyActive ? _vm.OBJECT_STATUS_MAP.ACTIVE : undefined,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var objects = ref.results;
return [_c('div',{staticClass:"wrapper"},_vm._l((objects),function(object){return _c('card',{key:object.id,staticClass:"card",attrs:{"image":object.imageUrl,"number-title":"","number":object.internalNumber,"cover-img":"","link":_vm.getRoute(object, objectType),"alt":_vm.$t('object.object-alt', {
                  internalNumber: object.internalNumber,
                })}},[_c('div',{staticClass:"card-footer"},[_c('p',[_vm._v(_vm._s(object.address))]),_c('p',[_vm._v(_vm._s(object.postalCode)+" "+_vm._s(object.city))])])])}),1)]}}],null,true)}),_c('div',{staticClass:"text-center mt-4"},[_c('btn',{staticClass:"w-50 secondary mt-2",on:{"click":function($event){return _vm.$router.push({
                name: 'Objects',
                params: {
                  objectTypeId: objectType.id,
                },
              })}}},[_vm._v(" "+_vm._s(_vm.$t("place-dashboard.show-all"))+" ")])],1)],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }