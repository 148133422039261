import { defineComponent } from '@vue/composition-api';
import { OfferObjectStatus, } from '@/api/requests/offers';
import { useObjectAccept } from '@/composables';
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import DecisionBtns from '@/views/Brand/Applications/Components/Offers/Costs/_components/DecisionBtns.vue';
import StatusBtn from '@/views/Brand/Applications/Components/Offers/Costs/_components/StatusBtn.vue';
export default defineComponent({
    components: { StatusBtn, DecisionBtns, ConfirmModal },
    props: {
        level: {
            type: String,
            required: true,
        },
        objectRelation: {
            type: Object,
            required: true,
        },
        offerId: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        return {
            ...useObjectAccept(ctx, {
                relation: props.objectRelation,
                level: props.level,
            }),
            OfferObjectStatus,
        };
    },
});
