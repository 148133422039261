import { computed, defineComponent, onUnmounted, ref, watch, } from '@vue/composition-api';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { useBrand, useRouteQuery, useUi } from '@/composables';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import ActionRequireMessageItem from '@/views/Brand/Applications/Report/_components/action-required-messages/action-require-message-item/action-require-message-item.vue';
import TablePagination from '@/views/_components/Table/TablePagination.vue';
import { REPORTS_TAB_NAME, ReportTab, reportTabIndexMap, } from '@/views/Brand/Applications/Components/ReportMenu/ReportMenuItems.constants';
import { getRequiredActionMessages, } from '@/api/requests/reports';
export default defineComponent({
    components: {
        TablePagination,
        ActionRequireMessageItem,
        LoadingSpinner,
    },
    setup(_, ctx) {
        const { brand } = useBrand();
        const { updatesCounter, update } = useUpdatesCounter();
        const { routeQuery } = useRouteQuery(ctx);
        const { setTab } = useUi();
        const requiredActionMessagesData = ref(null);
        const isLoading = ref(true);
        const handleUpdate = () => {
            update();
            ctx.emit('update');
        };
        //@ts-ignore
        const params = computed(() => {
            return {
                page: routeQuery.value.page,
                limit: 10,
                brandId: brand.value.id,
                sortBy: '-requiredActionMarkedAt',
                q: routeQuery.value.q,
            };
        });
        onUnmounted(() => {
            setTab({
                name: REPORTS_TAB_NAME,
                index: reportTabIndexMap[ReportTab.REPORTS_LIST],
            });
        });
        watch(updatesCounter, async () => {
            requiredActionMessagesData.value = await getRequiredActionMessages(params.value);
            isLoading.value = false;
        }, { immediate: true });
        watch(routeQuery, update, { deep: true });
        return {
            isLoading,
            requiredActionMessagesData,
            params,
            updatesCounter,
            handleUpdate,
        };
    },
});
