import { computed, ref, } from '@vue/composition-api';
import isEmpty from 'lodash.isempty';
import { loadAllResults } from '@/api/requests/pagination';
import { getObjectsList, removeObjectsFromGroup, } from '@/api/requests/objects';
import { objectsToXlsx } from '@/util/xlsx';
import { getSinglePlace } from '@/api/requests/places';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import { SelectionMode } from '@/store/_composables/use-resources';
import { mapId } from '@/util/common';
import { useBrand, useRouteQuery, useAuthUser, } from '@/composables';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { SystemRole } from '@/api/requests/users';
import { useViewer } from '@/composables/use-viewer';
export const useObjectListActions = (props, ctx) => {
    const { routeQuery } = useRouteQuery(ctx);
    const { hasRole: hasViewerRole } = useViewer(ctx);
    const { hasRole } = useAuthUser();
    const { brand } = useBrand();
    const { selectedEntries } = props;
    const showCreateGroupModal = ref(false);
    const showAddToGroupModal = ref(false);
    const { update, updatesCounter } = useUpdatesCounter();
    const { isAdmin, isObjektoAdmin } = useAuthUser();
    const getPlacesToSelect = async () => {
        const placeIds = [];
        selectedEntries.value.forEach((object) => {
            const placeId = object.place?.id;
            if (placeId && !placeIds.includes(placeId))
                placeIds.push(placeId);
        });
        return Promise.all(placeIds.map(getSinglePlace));
    };
    const openAddReport = async () => {
        const placesToSelect = await getPlacesToSelect();
        const { onPlaceSelection, setPlaceSelectionMode } = usePlaceResourcesStore();
        const { onObjectSelection, setObjectSelectionMode } = useObjectResourcesStore();
        setPlaceSelectionMode(SelectionMode.INSTANT);
        onPlaceSelection(mapId(placesToSelect), placesToSelect);
        setPlaceSelectionMode(SelectionMode.DELAYED);
        setObjectSelectionMode(SelectionMode.INSTANT);
        onObjectSelection(mapId(selectedEntries.value), selectedEntries.value);
        setObjectSelectionMode(SelectionMode.DELAYED);
        ctx.root.$router.push({
            name: 'Reports',
            params: {
                addReport: true,
            },
        });
    };
    const groupObjectsListActions = computed(() => ({
        delete: {
            name: ctx.root.$t('common.delete'),
            disabled: isEmpty(selectedEntries.value),
            handler: async () => {
                const selectedObjectIds = selectedEntries.value.map((object) => object.id);
                try {
                    const { objectsGroupId } = ctx.root.$router.currentRoute.params;
                    await removeObjectsFromGroup(objectsGroupId, {
                        objectIds: selectedObjectIds,
                    });
                    update();
                    ctx.root.$toast.success(ctx.root.$t('object-list-view.removed-successfully'));
                    selectedEntries.value = [];
                }
                catch (e) {
                    alert('error');
                }
            },
        },
    }));
    const rawObjectsListsActions = computed(() => {
        const isBrandAdmin = hasRole(SystemRole.BRAND_ADMIN) || hasViewerRole(SystemRole.BRAND_ADMIN);
        const showGroupActions = (isAdmin.value && !isBrandAdmin) || isObjektoAdmin.value;
        const groupActions = {
            createGroup: {
                name: ctx.root.$t('objects-groups.create-group'),
                disabled: isEmpty(selectedEntries.value),
                handler: () => (showCreateGroupModal.value = true),
            },
            addToGroup: {
                name: ctx.root.$t('objects-groups.add-to-group'),
                disabled: isEmpty(selectedEntries.value),
                handler: () => (showAddToGroupModal.value = true),
            },
        };
        return {
            ...(showGroupActions && groupActions),
            createTask: {
                name: ctx.root.$t('brand-places-list-view.create-report'),
                disabled: isEmpty(selectedEntries.value),
                handler: openAddReport,
            },
            exportAll: {
                name: ctx.root.$t('ellipsis-menu.export-all'),
                async handler() {
                    const objects = await loadAllResults(getObjectsList, {
                        ...routeQuery.value,
                        brandId: brand.value?.id,
                    });
                    objectsToXlsx(objects);
                },
            },
            exportSelected: {
                name: ctx.root.$t('ellipsis-menu.export-selected'),
                disabled: isEmpty(selectedEntries.value),
                handler() {
                    objectsToXlsx(selectedEntries.value);
                },
            },
        };
    });
    return {
        updatesCounter,
        showCreateGroupModal,
        showAddToGroupModal,
        objectsListsActions: props.isGroupObjectsList
            ? groupObjectsListActions
            : rawObjectsListsActions,
    };
};
