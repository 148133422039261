import { ResourceType } from '@/constants/resources';
import ResourceField from '@/views/Brand/Applications/Components/Resources/ResourceField.vue';
import ResourcesModal from '@/views/Brand/Applications/Components/Resources/ResourcesModal.vue';
import { useValidation } from '@/composables';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { getPlacesList } from '@/api/requests/places';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import { getUniqueListBy, mapId } from '@/util/common';
import difference from 'lodash.difference';
import differenceBy from 'lodash.differenceby';
import { getUsersList } from '@/api/requests/users';
import { SelectionMode } from '@/store/_composables/use-resources';
import { useReportResources } from '@/views/Brand/Applications/Report/_composables/use-report-resources';
import { useRemovePlaceResource } from '@/views/Brand/Applications/Components/Resources/_composables';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
export default defineComponent({
    components: {
        ResourcesModal,
        ResourceField,
    },
    props: {
        users: {
            type: Array,
            default: () => [],
        },
        validateResources: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        reportId: {
            type: String,
        },
    },
    setup(props, ctx) {
        useValidation(ctx);
        const activeTab = ref(ResourceType.PLACES);
        const showModal = ref(false);
        const { mustContainObjects, mustContainPlaces } = useReportResources();
        const toggledPlace = ref(null);
        const fetchedPlaces = ref([]);
        const { updatesCounter: toggleUpdateCounter, update: updateToggleCounter } = useUpdatesCounter();
        const { addedPlaces, removedPlaces, togglePlace, onPlaceSelection, clearPendingPlaces, setPlaceSelectionMode, } = usePlaceResourcesStore();
        const { addedObjects, removedObjects, toggleObject, clearPendingObjects } = useObjectResourcesStore();
        const { getToggledPlaceUsers, getPlacesUsers } = useRemovePlaceResource({
            toggleUpdateCounter,
            fetchedPlaces,
            addedPlaces,
            toggledPlace,
            reportId: props.reportId,
        });
        const openResourceModal = (tab) => {
            activeTab.value = tab;
            showModal.value = true;
        };
        const handleTogglePlaceSelect = (place) => {
            togglePlace(place);
            toggledPlace.value = place;
            updateToggleCounter();
        };
        watch(showModal, (newVal, oldVal) => {
            if (!newVal && oldVal) {
                clearPendingPlaces();
                clearPendingObjects();
            }
        });
        watch(addedObjects, async (value, prevValue) => {
            const newObjects = difference(value, prevValue);
            if (newObjects.length) {
                const { results } = await getPlacesList({
                    objectIds: mapId(newObjects),
                    ...(props.reportId && { relatedReportId: props.reportId }),
                });
                const placesToSelect = results.filter((place) => !place.isRelatedToReport &&
                    !addedPlaces.value.find(({ id }) => id === place.id));
                if (placesToSelect.length) {
                    setPlaceSelectionMode(SelectionMode.INSTANT);
                    onPlaceSelection(mapId(placesToSelect), placesToSelect);
                    setPlaceSelectionMode(SelectionMode.DELAYED);
                }
            }
        });
        watch(() => ({
            removedPlaces,
            toggledPlace,
        }), async () => {
            const [toggledPlaceUsers, placesUsers] = await Promise.all([
                getToggledPlaceUsers(),
                getPlacesUsers(),
            ]);
            const usersFromPlaces = placesUsers.map(({ users }) => users).flat();
            const uniqueUsersFromPlaces = getUniqueListBy(usersFromPlaces, 'id');
            const uniqueAllPlacesUsers = getUniqueListBy([...usersFromPlaces, ...toggledPlaceUsers], 'id');
            const otherUsers = differenceBy(props.users, uniqueAllPlacesUsers, 'id');
            const usersToSet = [...uniqueUsersFromPlaces, ...otherUsers];
            const usersToRemove = differenceBy(props.users, usersToSet, 'id');
            fetchedPlaces.value.length > 0
                ? ctx.emit('remove-users', usersToRemove)
                : ctx.emit('set-users', usersToSet);
        }, { deep: true, immediate: true });
        watch(addedPlaces, async (value, prevValue) => {
            const newPlaces = prevValue !== undefined ? difference(value, prevValue) : value;
            if (newPlaces.length) {
                const { results } = await getUsersList({
                    excludeSelf: true,
                    placeIds: mapId(newPlaces),
                    ...(props.reportId && { relatedReportId: props.reportId }),
                });
                const reportRelatedUsers = results.filter((place) => !place.isRelatedToReport);
                const users = getUniqueListBy([...reportRelatedUsers, ...props.users], 'id');
                ctx.emit('select-users', users);
            }
        }, { deep: true, immediate: true });
        return {
            mapId,
            handleTogglePlaceSelect,
            ResourceType,
            activeTab,
            showModal,
            openResourceModal,
            addedPlaces,
            removedPlaces,
            togglePlace,
            addedObjects,
            removedObjects,
            toggleObject,
            mustContainPlaces,
            mustContainObjects,
            fetchedPlaces,
        };
    },
});
