import { getPropertyArray } from '@/util/formatters';
import {
  onSelectResource,
  setStateForMultipleResources,
} from '@/util/resources';

export default {
  namespaced: true,
  state: {
    editedMessageId: null,
    selectedPlaces: [],
    removedPlaces: [],
    selectedObjects: [],
    removedObjects: [],
    mustContainObjects: false,
    mustContainPlaces: false,
  },
  getters: {
    selectedPlaceIds: (state) => getPropertyArray(state.selectedPlaces, 'id'),
    selectedObjectIds: (state) => getPropertyArray(state.selectedObjects, 'id'),
    removedPlaceIds: (state) => getPropertyArray(state.removedPlaces, 'id'),
    removedObjectIds: (state) => getPropertyArray(state.removedObjects, 'id'),
    isEditing: (state) => state.editedMessageId !== null,
  },
  mutations: {
    SET_SELECTED_PLACES(state, places) {
      state.selectedPlaces = [...places];
    },
    SET_SELECTED_OBJECTS(state, objects) {
      state.selectedObjects = [...objects];
    },
    SET_REMOVED_PLACES(state, places) {
      state.removedPlaces = [...places];
    },
    SET_REMOVED_OBJECTS(state, objects) {
      state.removedObjects = [...objects];
    },
    SET_EDITED_MESSAGE_ID(state, editedMessageId) {
      state.editedMessageId = editedMessageId;
    },
    SET_OBJECTS_REQUIREMENT(state, value) {
      state.mustContainObjects = value;
    },
    SET_PLACES_REQUIREMENT(state, value) {
      state.mustContainPlaces = value;
    },
  },
  actions: {
    setPlaces({ commit }, places) {
      commit('SET_SELECTED_PLACES', places);
    },
    setObjects({ commit }, objects) {
      commit('SET_SELECTED_OBJECTS', objects);
    },
    setPlacesRequirement({ commit }, value) {
      commit('SET_PLACES_REQUIREMENT', value);
    },
    setObjectsRequirement({ commit }, value) {
      commit('SET_OBJECTS_REQUIREMENT', value);
    },
    setRemovedPlaces({ commit }, places) {
      commit('SET_REMOVED_PLACES', places);
    },
    setRemovedObjects({ commit }, objects) {
      commit('SET_REMOVED_OBJECTS', objects);
    },
    removeSelectedPlace({ state, commit }, placeId) {
      commit(
        'SET_SELECTED_PLACES',
        state.selectedPlaces.filter(({ id }) => id !== placeId),
      );
    },
    removeSelectedObject({ state, commit }, objectId) {
      commit(
        'SET_SELECTED_OBJECTS',
        state.selectedObjects.filter(({ id }) => id !== objectId),
      );
    },
    selectPlace({ state, commit }, place) {
      const { removed, selected } = onSelectResource(
        place,
        {
          removed: state.removedPlaces,
          selected: state.selectedPlaces,
        },
        (place) => !!place.isRelatedToReport,
      );

      commit('SET_SELECTED_PLACES', selected);
      commit('SET_REMOVED_PLACES', removed);
    },
    selectObject({ state, commit }, object) {
      const { removed, selected } = onSelectResource(
        object,
        {
          removed: state.removedObjects,
          selected: state.selectedObjects,
        },
        (object) => !!object.isRelatedToReport,
      );

      commit('SET_SELECTED_OBJECTS', selected);
      commit('SET_REMOVED_OBJECTS', removed);
    },
    clearReportResources({ commit }) {
      commit('SET_SELECTED_OBJECTS', []);
      commit('SET_REMOVED_OBJECTS', []);
      commit('SET_SELECTED_PLACES', []);
      commit('SET_REMOVED_PLACES', []);
    },
    selectAllPlaces({ state, commit }, { places, select = false }) {
      const { removed, selected } = setStateForMultipleResources(
        places,
        {
          removed: state.removedPlaces,
          selected: state.selectedPlaces,
        },
        {
          select,
          isEdited: (place) => !!place.isRelatedToReport,
        },
      );

      commit('SET_SELECTED_PLACES', selected);
      commit('SET_REMOVED_PLACES', removed);
    },
    selectAllObjects({ state, commit }, { objects, select = false }) {
      const { removed, selected } = setStateForMultipleResources(
        objects,
        {
          removed: state.removedObjects,
          selected: state.selectedObjects,
        },
        {
          select,
          isEdited: (object) => !!object.isRelatedToReport,
        },
      );

      commit('SET_SELECTED_OBJECTS', selected);
      commit('SET_REMOVED_OBJECTS', removed);
    },
    setEditedMessageId({ commit }, editedMessageId) {
      commit('SET_EDITED_MESSAGE_ID', editedMessageId);
    },
  },
};
