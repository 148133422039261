export var BtnShape;
(function (BtnShape) {
    BtnShape["SQUARED"] = "squared";
    BtnShape["ROUNDED"] = "rounded";
    BtnShape["PILLED"] = "pilled";
})(BtnShape || (BtnShape = {}));
export var BtnSize;
(function (BtnSize) {
    BtnSize["XS"] = "xs";
    BtnSize["SM"] = "sm";
    BtnSize["MD"] = "md";
    BtnSize["LG"] = "lg";
    BtnSize["XL"] = "xl";
})(BtnSize || (BtnSize = {}));
export const BTN_LOADING_SPINNER_SIZE_MAP = {
    [BtnSize.XS]: 8,
    [BtnSize.SM]: 16,
    [BtnSize.MD]: 24,
    [BtnSize.LG]: 32,
    [BtnSize.XL]: 40,
};
export var BtnTheme;
(function (BtnTheme) {
    BtnTheme["PRIMARY"] = "primary";
    BtnTheme["PRIMARY_OUTLINE"] = "primary-outline";
    BtnTheme["SECONDARY"] = "secondary";
    BtnTheme["GRAY"] = "gray";
    BtnTheme["GRAY_OUTLINE"] = "gray-outline";
    BtnTheme["TRANSPARENT_WHITE"] = "transparent-white";
    BtnTheme["TRANSPARENT_BLACK"] = "transparent-black";
    BtnTheme["YELLOW_LIGHT"] = "yellow-light";
    BtnTheme["ORANGE"] = "orange";
})(BtnTheme || (BtnTheme = {}));
