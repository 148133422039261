import VueStore from '@/store';
import { fetchObjectData } from '@/views/Brand/Objects/mixins/fetchObjectData';
import { defineComponent } from '@vue/composition-api';
import { ObjectDetailsPlacePreview } from '@/views/Brand/Objects/_constants';
import ObjectDetailsTemplate from '@/views/Brand/Objects/Components/ObjectDetailsTemplate.vue';
import ObjectHeader from '@/views/Brand/Objects/Components/ObjectHeader.vue';
export default defineComponent({
    name: 'ObjectDetails',
    components: {
        ObjectDetailsTemplate,
        ObjectHeader,
    },
    props: {
        placePreview: {
            type: String,
            default: ObjectDetailsPlacePreview.POPOVER,
        },
        isModalChildren: Boolean,
    },
    async beforeRouteEnter(to, from, next) {
        await Promise.all([
            fetchObjectData(to),
            VueStore.dispatch('object/fetchObjectTypeDetails', to.params.objectTypeId),
        ]);
        next();
    },
    async beforeRouteUpdate(to, from, next) {
        await Promise.all([
            fetchObjectData(to),
            VueStore.dispatch('object/fetchObjectTypeDetails', to.params.objectTypeId),
        ]);
        next();
    },
});
