import Btn from '@/components/Button/Button.vue';
import { Icon } from '@/components/Icon';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import NotificationList from '@/layouts/_components/Notifications/NotificationList.vue';
import InfiniteLoading from 'vue-infinite-loading';
import isEqual from 'lodash.isequal';
import { computed, defineComponent, onBeforeMount, ref, } from '@vue/composition-api';
import { useNotifications } from '@/layouts/_components/Notifications/_composables/use-notifications';
import { TYPE as ToastType } from 'vue-toastification';
import { mdiBellOutline } from '@mdi/js';
import { parseNotification } from '@/layouts/_components/Notifications/_util/notification-factory';
import { PortalTarget } from '@/constants/portal';
import { MAX_NOTIFICATIONS_COUNT } from '@/layouts/_components/Notifications/_constants';
export default defineComponent({
    name: 'Notifications',
    computed: {
        PortalTarget() {
            return PortalTarget;
        },
    },
    components: {
        Btn,
        Icon,
        NotificationList,
        InfiniteLoading,
        Tooltip,
    },
    setup(props, ctx) {
        const open = ref(false);
        const connection = ref(null);
        const { notifications, page, unread, pagination, fetchNotifications, getUnreadNotificationCount, markAllNotificationsAsRead, markNotificationAsRead, } = useNotifications();
        const receiveMessage = (message) => {
            const translations = parseNotification(message).getTranslations();
            ctx.root.$toast(translations.title, {
                type: ToastType.DEFAULT,
                onClick: () => {
                    markNotificationAsRead(message.id);
                },
            });
        };
        const showNotifications = async () => {
            if (!open.value && pagination.value?.currentPage !== page.value) {
                await fetchNotifications();
            }
            open.value = !open.value;
        };
        const onReadAllNotifications = async () => {
            await markAllNotificationsAsRead();
            page.value = 1;
            await fetchNotifications(true);
            open.value = !open.value;
        };
        const onClickNotification = async (notification) => {
            if (!notification.isRead)
                await markNotificationAsRead(notification.id);
            const route = parseNotification(notification).getRoute();
            if (!route)
                return;
            if (route.name !== ctx.root.$route.name ||
                !isEqual(route.params, ctx.root.$route.params)) {
                ctx.root.$router.push(route);
                //@ts-ignore
            }
            else
                ctx.root.$router.go();
            open.value = false;
        };
        const isToday = (notificationTime) => {
            const today = new Date().setHours(0, 0, 0, 0);
            const notificationDate = new Date(notificationTime).setHours(0, 0, 0, 0);
            return today === notificationDate;
        };
        const infiniteHandler = async ($state) => {
            if (allLoaded.value) {
                $state.complete();
                return;
            }
            page.value++;
            await fetchNotifications();
            if ($state) {
                $state.loaded();
            }
        };
        const notificationsToday = computed(() => notifications.value.filter((notification) => {
            return isToday(notification.createdAt);
        }));
        const notificationsOlder = computed(() => notifications.value.filter((notification) => {
            return !isToday(notification.createdAt);
        }));
        const allLoaded = computed(() => {
            return (!!pagination.value &&
                pagination.value.currentPage === pagination.value.lastPage);
        });
        const unreadNotificationsCount = computed(() => {
            if (unread.value > MAX_NOTIFICATIONS_COUNT) {
                return `+${MAX_NOTIFICATIONS_COUNT}`;
            }
            return unread.value;
        });
        onBeforeMount(() => {
            ctx.root.$socket.connect();
            getUnreadNotificationCount();
        });
        return {
            unreadNotificationsCount,
            mdiBellOutline,
            open,
            connection,
            notificationsToday,
            notificationsOlder,
            allLoaded,
            onReadAllNotifications,
            onClickNotification,
            infiniteHandler,
            notifications,
            unread,
            getUnreadNotificationCount,
            showNotifications,
            receiveMessage,
            markNotificationAsRead,
            fetchNotifications,
            page,
        };
    },
    sockets: {
        notification(data) {
            // @ts-ignore
            this.receiveMessage(data);
            // @ts-ignore
            this.page = 1;
            // @ts-ignore
            this.fetchNotifications(true);
            // @ts-ignore
            this.getUnreadNotificationCount();
        },
    },
});
