<template>
  <btn
    v-if="isAuthorizedToManageOffers"
    :class="buttonClass"
    @click="$router.push({ name: 'AddOffer' })"
  >
    <icon :icon="mdiPlus" />
    {{ $t(text) }}
  </btn>
</template>

<script>
import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button.vue';
import { mdiPlus } from '@mdi/js';

export default {
  components: { Btn, Icon },
  props: {
    isAuthorizedToManageOffers: Boolean,
    text: {
      type: String,
      default: 'common.add',
    },
    buttonClass: {
      type: String,
      default: 'w-26',
    },
  },
  setup() {
    return { mdiPlus };
  },
};
</script>
