<template>
  <div class="pb-20">
    <offer-object-filters @filter-objects="objectFilters = $event" />
    <fetch-data
      v-show="allObjects.length"
      v-slot="{ results, isLoading, pagination }"
      url="objects"
      :params="query"
      @data-deliver="allObjects = $event.results"
    >
      <offer-object-table
        v-if="results && results.length"
        :data="results"
        :is-object-selected="
          (object) =>
            isIncludedInOffer(object, selectedObjectIds, removedObjectIds)
        "
        @select="$emit('select-object', [$event], allObjects)"
        @edit="$emit('edit-object', $event)"
      />
      <p v-else-if="!isLoading" class="mt-4">{{ $t('common.no-results') }}</p>
      <resources-pagination
        v-model="activePage"
        path="objects"
        :limit="RESOURCE_LIMIT"
        :pagination="pagination"
        :query="query"
        :is-loading="isLoading"
      />
      <table-loader v-if="isLoading" />
    </fetch-data>
    <template v-if="objectsAddedInOffer.length">
      <p class="object-title">
        {{ $t('add-brand-offer-view.unsaved-objects') }}:
      </p>
      <offer-object-table
        :data="objectsAddedInOffer"
        :is-selected="
          (object) =>
            isIncludedInOffer(object, selectedObjectIds, removedObjectIds)
        "
        @select="$emit('select-object', [$event], objectsAddedInOffer)"
        @edit="$emit('edit-object', $event)"
      />
    </template>
    <div class="flex justify-center mt-6">
      <btn
        :theme="BTN_THEMES.SECONDARY"
        class="w-48"
        @click="$emit('open-object-tab')"
      >
        <span class="flex items-center">
          {{ $t('add-brand-offer-view.add-object') }}
          <icon :icon="mdiPlusCircleOutline" class="text-primary ml-2" />
        </span>
      </btn>
    </div>
  </div>
</template>
<script>
import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button';
import OfferObjectFilters from '@/views/Brand/Applications/Components/Offers/OfferObjectFilters';
import { BTN_THEMES } from '@/store/modules/ui';
import { mapGetters, mapState } from 'vuex';
import objectStatus from '@/views/Brand/Objects/mixins/objectStatus';
import FetchData from '@/components/DataProvider/FetchData';
import OfferObjectTable from '@/views/Brand/Applications/Components/Offers/OfferObjectTable';
import { RESOURCE_LIMIT } from '@/constants/pagination';
import { ResourcesPagination, TableLoader } from '@/views/_components/Table';
import { mdiPlusCircleOutline } from '@mdi/js';

export default {
  components: {
    OfferObjectTable,
    FetchData,
    Icon,
    Btn,
    OfferObjectFilters,
    TableLoader,
    ResourcesPagination,
  },
  mixins: [objectStatus],
  props: {
    isIncludedInOffer: {
      type: Function,
      default: () => false,
    },
    columns: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    forceUpdate: {
      type: Number,
      default: 0,
    },
    report: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      mdiPlusCircleOutline,
      BTN_THEMES,
      RESOURCE_LIMIT,
      allObjects: [],
      objectFilters: {},
      inOfferObjects: [],
      activePage: 1,
      inOfferActivePage: 1,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    ...mapState('offer', {
      offer: 'currentOffer',
    }),
    ...mapState('offerResources', [
      'objectsAddedInOffer',
      'forceCheckboxUpdate',
      'objectsToSelect',
    ]),
    ...mapGetters('offerResources', ['selectedObjectIds', 'removedObjectIds']),
    query() {
      return {
        ...this.objectFilters,
        brandId: this.currentBrandId(this.$route),
        ...(this.edit && { assignedOfferId: this.offer.id }),
        limit: RESOURCE_LIMIT,
        page: this.activePage,
      };
    },
  },
};
</script>
<style scoped>
.object-title {
  @apply text-primary text-sm mt-4 font-semibold uppercase;
}
</style>
