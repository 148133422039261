import { computed, defineComponent } from '@vue/composition-api';
import { formatDistanceToNowStrict } from 'date-fns';
import { getDateFnsLocale } from '@/i18n';
import { parseNotification, } from '@/layouts/_components/Notifications/_util/notification-factory';
export default defineComponent({
    name: 'Notification',
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const notificationData = computed(() => {
            return parseNotification(props.notification);
        });
        const title = computed(() => {
            return notificationData.value.getTranslations().title;
        });
        const body = computed(() => {
            return notificationData.value.getTranslations().body;
        });
        const creationDistance = computed(() => {
            return formatDistanceToNowStrict(new Date(props.notification.createdAt), {
                locale: getDateFnsLocale(),
                addSuffix: true,
            });
        });
        return { body, title, creationDistance };
    },
});
