import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import { BottomMenu } from '@/components/Menus';
import { BadgeOptions } from '@/views/Brand/Applications/Report/Details/_components/badge-options';
import OfferModal from '@/views/Brand/Applications/Report/_components/OfferModal/OfferModal.vue';
import { useActionRequired } from '@/views/Brand/Applications/Report/_composables/use-action-required';
const Emit = {
    TOGGLE_OFFER_MODAL: 'toggle-offer-modal',
    CLOSE_MENU: 'close-menu',
    ADD_FILE: 'add-file',
    ENABLE_ACTION_REQUIRED_MODE: 'enable-action-required-mode',
    REOPEN_REPORT: 'reopen-report',
    CLOSE_REPORT: 'close-report',
    SELECT_OFFER: 'select-offer',
};
export default defineComponent({
    components: { OfferModal, BadgeOptions, BottomMenu },
    props: {
        showOfferModal: Boolean,
        isActionRequiredModeEnabled: Boolean,
        isAuthorizedToCreateActionRequired: Boolean,
        showAddAssetsBottomMenu: Boolean,
        selectedOffer: Object,
        messages: Array,
        report: Object,
    },
    setup(props, ctx) {
        const { isActionRequiredInReport } = useActionRequired();
        const hasRequiredActionMessage = ref(false);
        watch(() => props.messages, async () => {
            if (!props.report) {
                return;
            }
            hasRequiredActionMessage.value = await isActionRequiredInReport(props.report?.id);
        }, { immediate: true });
        const addAssetsMenuItems = computed(() => {
            return [
                {
                    label: ctx.root.$t('report.add-offer'),
                    handler: () => {
                        ctx.emit(Emit.TOGGLE_OFFER_MODAL, true);
                        ctx.emit(Emit.CLOSE_MENU);
                    },
                },
                {
                    label: ctx.root.$t('report.add-attachment'),
                    handler: (e) => ctx.emit(Emit.ADD_FILE, e),
                    isAddFileHandler: true,
                },
                ...(props.isAuthorizedToCreateActionRequired
                    ? [
                        {
                            label: ctx.root.$t('report.add-action-required'),
                            disabled: hasRequiredActionMessage.value,
                            handler: () => {
                                ctx.emit(Emit.ENABLE_ACTION_REQUIRED_MODE);
                                ctx.emit(Emit.CLOSE_MENU);
                            },
                        },
                    ]
                    : []),
            ];
        });
        return { addAssetsMenuItems, Emit, hasRequiredActionMessage };
    },
});
