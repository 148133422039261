import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useState, useActions } = createNamespacedHelpers('object');
export const useObjects = () => {
    const { objectType } = useState(['objectType']);
    const { fetchObjectTypeDetails, fetchObjectDetails } = useActions([
        'fetchObjectTypeDetails',
        'fetchObjectDetails',
        'fetchAttachments',
    ]);
    return { objectType, fetchObjectTypeDetails, fetchObjectDetails };
};
