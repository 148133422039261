<template>
  <div class="field-with-label" :class="{ error: isError }">
    <div class="label-wrapper">
      <label class="label" :for="id">{{ label }}</label>
      <p v-if="errorMsg" class="error-message">
        {{ errorMsg }}
      </p>
    </div>
    <div class="flex-grow flex-shrink overflow-hidden">
      <div class="flex" :class="{ 'justify-end': !withInput && !withSearch }">
        <input
          v-if="withInput"
          :id="id"
          :value="value"
          :disabled="disabled"
          class="field-input"
          @input="$emit('input', $event)"
        />
        <search
          v-else-if="withSearch"
          v-model="search"
          @input="$emit('search', $event)"
        />
        <button
          v-if="withArrow"
          class="arrow"
          type="button"
          @click="$emit('arrow-click')"
        >
          <icon
            :icon="mdiChevronDown"
            class="arrow-icon"
            :class="{ rotate: rotateArrow }"
          />
        </button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { Icon } from '@/components/Icon';
import Search from '@/components/Expandable/components/Search';
import { mdiChevronDown } from '@mdi/js';

export default {
  components: { Search, Icon },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    withArrow: {
      type: Boolean,
      default: false,
    },
    withInput: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rotateArrow: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isError: {
      type: [Boolean, String],
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiChevronDown,
      search: '',
    };
  },
  computed: {
    errorMsg() {
      return typeof this.isError === 'string' ? this.isError : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.field-with-label {
  @apply bg-white border border-white flex justify-between items-stretch rounded rounded-md pr-2 box-border min-h-10 overflow-hidden;

  .label-wrapper {
    @apply text-sm font-semibold text-center pt-2.5 bg-gray-lighter flex-grow-0 rounded-l min-w-20 px-3;
  }

  .field-input {
    @apply h-10 rounded-r px-4 py-2 overflow-x-hidden whitespace-no-wrap w-full text-sm;
    text-overflow: ellipsis;
    flex-grow: 1;

    &:disabled {
      @apply bg-white;
    }
  }

  .arrow {
    @apply bg-gray-lighter rounded w-6 h-6 ml-2 mt-2 flex-grow-0 flex-shrink-0;

    &-icon {
      @apply text-gray-darker transition-transform duration-500;

      &.rotate {
        @apply transform -rotate-180;
      }
    }
  }

  &.error {
    @apply border-red;

    .error-message {
      @apply text-red text-xs w-20 mt-2 font-normal;
    }
  }
}
</style>
