import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        option: Object,
        optionLabelName: String,
        selected: Boolean,
        focused: Boolean,
        disabled: Boolean,
    },
});
