var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fetch-data',{staticClass:"relative",attrs:{"url":"objects","params":_vm.objectsQuery,"force-update":_vm.updatesCounter,"debounce":_vm.Debounce.DEFAULT},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [(results && results.length)?_c('table-display',{staticClass:"mt-4",attrs:{"rows":results,"columns":_vm.columns,"sort":_vm.sortConfig,"is-row-expanded":_vm.isObjectExpanded,"is-row-clickable":false,"table-style":_vm.TableStyle.Flat},on:{"record-click":function($event){return _vm.$emit('open', $event.id)},"sort":_vm.sort},scopedSlots:_vm._u([{key:"checkboxHeader",fn:function(){return [_c('popover',{attrs:{"offset":"45, 5"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var attrs = ref.attrs;
var togglePopoverVisibility = ref.togglePopoverVisibility;
var isOpened = ref.isOpened;
return [_c('div',{staticClass:"list-header-checkbox",class:[isOpened ? 'active' : 'hovered']},[_c('list-header-checkbox',{staticClass:"py-2 px-1.5",attrs:{"list-items":results,"selected-items":_vm.selectedEntries},on:{"input":function($event){return _vm.onSelectAll(results)}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}}),_c('button',_vm._b({staticClass:"py-1",attrs:{"size":"sm"},on:{"click":function($event){return togglePopoverVisibility()}}},'button',attrs,false),[_c('icon',{staticClass:"text-gray-darkest",attrs:{"icon":_vm.mdiChevronDown}})],1)],1)]}}],null,true)},[_c('showing-menu',{staticClass:"text-sm px-2",attrs:{"items":_vm.objectsListsActions}})],1)]},proxy:true},{key:"checkboxCell",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex gap-4"},[_c('checkbox',{attrs:{"table":"","value":_vm.selectedEntries.some(function (entry) { return entry.id === row.id; })},on:{"input":function($event){return _vm.changeState(row, $event)}}}),_c('star-toggle',{attrs:{"value":row.isImportant},on:{"input":function($event){return _vm.changeImportanceStatus(row, $event)}}}),(!_vm.isGroupObjectsList)?_c('expand-button',{attrs:{"is-expanded":_vm.isObjectExpanded(row)},on:{"expand":function($event){return _vm.toggleExpandObjectDetails(row)}}}):_vm._e()],1)]}},{key:"placeCell",fn:function(ref){
var value = ref.value;
return [_c('place-with-popover',{attrs:{"place":value,"display-property":"number"}})]}},{key:"internalStatusCell",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('status-toggler',{attrs:{"compact":_vm.isMobileView,"colors":_vm.OBJECT_STATUS_COLORS,"options":_vm.getStatusOptions(value),"current-status":value,"status-translation":"object-status"},on:{"change-status":function (status) { return _vm.changeStatus(row.id, status); }}})]}},{key:"actionsCell",fn:function(ref){
var row = ref.row;
return [_c('table-actions',{attrs:{"actions":_vm.getObjectActions(row)}})]}},{key:"expandedRow",fn:function(ref){
var row = ref.row;
return [_c('object-table-expanded-row',{attrs:{"object-type-id":row.objectTypeId,"object-id":row ? row.id : ''}})]}}],null,true)}):(!isLoading)?_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('common.no-results')))]):_vm._e(),_c('table-pagination',{attrs:{"path":"objects","pagination":pagination,"is-loading":isLoading,"query":_vm.objectsQuery}}),(isLoading)?_c('table-loader'):_vm._e()]}}])}),(_vm.showCreateGroupModal || _vm.showAddToGroupModal)?_c('portal',{attrs:{"to":"modals"}},[_c('objects-group-modal',{attrs:{"selected-objects":_vm.selectedObjectsIds,"show":_vm.showCreateGroupModal,"action-type":_vm.ObjectsGroupModalActionType.CREATE_GROUP},on:{"close":function($event){_vm.showCreateGroupModal = false},"submit":_vm.clearSelections}}),_c('add-to-objects-group-modal',{attrs:{"selected-objects":_vm.selectedObjectsIds,"show":_vm.showAddToGroupModal},on:{"submit":_vm.clearSelections,"close":function($event){_vm.showAddToGroupModal = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }