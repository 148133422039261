import { defineComponent, onMounted, ref, } from '@vue/composition-api';
import ChatReportPlacesList from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/ChatReportPlacesList.vue';
import ChatReportObjectsList from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/ChatReportObjectsList.vue';
import ChatUserArea from '@/views/Brand/Applications/Report/Chat/ChatUserArea.vue';
import { WysiwygInput } from '@/components/WysiwygInput';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { CustomFormTable } from '@/views/_components/CustomForms';
import { Icon } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
import { useMobileView } from '@/composables';
import { getSingleUser } from '@/api/requests/users';
export default defineComponent({
    name: 'ChatReportDetails',
    components: {
        CustomFormTable,
        ChatUserArea,
        ChatReportPlacesList,
        ChatReportObjectsList,
        WysiwygInput,
        Icon,
    },
    props: {
        report: {
            type: Object,
            required: true,
        },
        firstMessage: {
            type: Object,
            required: true,
        },
        reportPlaces: {
            type: Object,
            required: true,
        },
        reportObjects: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const getMentionSuggestions = createMentionUserFn();
        const { isMobileView } = useMobileView();
        const reportCreator = ref(null);
        onMounted(async () => {
            reportCreator.value = await getSingleUser(props.report.creator.id);
        });
        return { getMentionSuggestions, isMobileView, mdiClose, reportCreator };
    },
});
