<template>
  <button
    v-click-outside="{ handler: close, events: ['click', 'mousedown'] }"
    class="ellipsis-container"
    :class="{ 'ellipsis-absolute': absolute, 'ellipsis-disabled': emptyMenu }"
    :style="absolute && { top }"
    @click="toggle"
  >
    <icon :icon="mdiDotsVertical" class="mr-2" :class="iconFill" />
    <showing-menu
      v-show="isMenuOpen && !emptyMenu"
      ref="menu"
      :items="items"
      :append-to-body="appendToBody"
    />
  </button>
</template>

<script>
import Popper from 'popper.js';
import ShowingMenu from '@/components/EllipsisMenu/ShowingMenu.vue';
import { Icon } from '@/components/Icon';
import isEmpty from 'lodash.isempty';
import { mdiDotsVertical } from '@mdi/js';

export default {
  name: 'EllipsisMenu',
  components: {
    ShowingMenu,
    Icon,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
    iconFill: {
      type: String,
      default: 'fill-current',
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: '50%',
    },
  },
  data() {
    return {
      isMenuOpen: false,
      menu: null,
      popperInstance: null,
      mdiDotsVertical,
    };
  },
  computed: {
    emptyMenu() {
      return isEmpty(this.items);
    },
  },
  watch: {
    isMenuOpen(value) {
      if (value) this.popperInstance.scheduleUpdate();
    },
  },
  mounted() {
    this.menu = this.$refs.menu.$el;

    if (this.appendToBody) {
      this.$el.removeChild(this.menu);
      document.body.appendChild(this.menu);
    }

    this.popperInstance = new Popper(this.$el, this.menu, {
      placement: 'left-start',
      removeOnDestroy: true,
      positionFixed: true,
      modifiers: {
        offset: {
          offset: '20, -10',
        },
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
      },
    });
  },
  beforeDestroy() {
    this.popperInstance.destroy();
  },
  methods: {
    toggle() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    close() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis-container {
  @apply cursor-pointer w-5 relative;

  &:focus {
    @apply shadow rounded;
  }
}

.ellipsis-disabled {
  @apply pointer-events-none opacity-50;
}

.ellipsis-absolute {
  @apply absolute right-0;
  transform: translateY(-50%);
}
</style>
