import { NAVY } from '@/themes';

export const COORDINATES_TAB = 'coordinates_tab';
export const DOCUMENTS_TAB = 'documents_tab';
export const MULTIMEDIA_TAB = 'multimedia_tab';
export const USER_TAB = 'user_tab';
export const PLACE_TAB = 'place_tab';
export const OBJECT_TAB = 'object_tab';
export const ADD_OBJECT_TAB = 'add_object_tab';

export const RIGHT_SIDEBAR = 'right';
export const LEFT_SIDEBAR = 'left';

export const BTN_THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  YELLOW_LIGHT: 'yellow-light',
  YELLOWISH: 'yellowish', // TODO some better naming ?
  ORANGE: 'orange',
  NONE: 'none',
};

export const INPUT_STYLE = {
  DEFAULT: 'default',
  SPLIT: 'split',
  TRANSPARENT: 'transparent',
};

export const CHECKBOX_THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const CONTROLLER_DIRECTION = {
  NEXT: 'next',
  PREV: 'prev',
};

export default {
  namespaced: true,
  state: {
    sidebar: {
      isOpen: true,
      side: 'left',
    },
    sideMenu: {
      isOpen: false,
      side: 'left',
    },
    theme: NAVY,
    mobileView: {
      breakpoint: 768,
      active: false,
    },
    offsets: {
      navBar: 0,
      navBarBrand: 0,
    },
    tabs: {
      forms: 0,
      objects: 0,
      places: 0,
      reports: 0,
    },
  },
  getters: {
    theme: (state) => state.theme,
    isMobileViewActive: (state) => state.mobileView.active,
    isSidebarOpen: (state) => state.sidebar.isOpen,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebar.isOpen = !state.sidebar.isOpen;
    },
    toggleSideMenu(state) {
      state.sideMenu.isOpen = !state.sideMenu.isOpen;
    },
    setSidebar(state, payload) {
      state.sidebar.isOpen = payload;
    },
    setMobileView(state, active) {
      state.mobileView.active = active;
    },
    SET_THEME(state, payload) {
      state.theme = payload;
    },
    setOffset(state, { component, value }) {
      state.offsets[component] = value;
    },
    SET_TAB(state, { name, index }) {
      state.tabs[name] = index;
    },
  },
  actions: {
    changeTheme({ commit }, payload) {
      Object.entries(payload).forEach(([key, value]) => {
        let kebabKey = key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
        document.body.style.setProperty(`--${kebabKey}`, value);
      });
      commit('SET_THEME', payload);
    },
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    },
    toggleSideMenu({ commit }) {
      commit('toggleSideMenu');
    },
    setSidebar({ commit }, payload) {
      commit('setSidebar', payload);
    },
    setMobileView({ commit, state }, width) {
      const active = width < state.mobileView.breakpoint;

      commit('setMobileView', active);
    },
    setOffset({ commit }, payload) {
      commit('setOffset', payload);
    },
    setTab({ commit }, payload) {
      commit('SET_TAB', payload);
    },
  },
};
