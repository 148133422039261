import { DEFAULT_LIMIT } from '@/constants/pagination';
import { computed, defineComponent } from '@vue/composition-api';
import { useBrand, useExpandDetails, useMobileView } from '@/composables';
import { useObjectStatus } from '@/composables/use-object-status';
import ObjectPreview from '@/views/Brand/Objects/ObjectPreview.vue';
import { useObjectResourcesStore } from '@/store/object-resources';
import { ExpandButtonStyle } from '@/components/Table/TableGrid/table.constants';
import { Checkbox } from '@/components/Inputs';
import { GenericTable } from '@/components/Table/__new__/_components/TableGeneric.vue';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton.vue';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { TableStyle } from '@/components/Table/__new__';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';
import { genericTableRowSize } from '@/components/Table/__new__/_components/TableCellGeneric';
export default defineComponent({
    components: {
        ObjectPreview,
        Checkbox,
        TableDisplay: GenericTable(),
        ExpandButton,
        StatusToggler,
        InfiniteData,
    },
    props: {
        reportId: {
            type: String,
        },
        query: {
            type: Object,
            default: () => ({}),
        },
        containerSelector: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { isMobileView } = useMobileView();
        const { getStatusOptions, OBJECT_STATUS_COLORS } = useObjectStatus();
        const { isObjectSelected, onObjectSelection } = useObjectResourcesStore();
        const onSelectObject = (object) => onObjectSelection([object.id], [object]);
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'internalNumber',
                    label: ctx.root.$t('add-brand-report-view.no').toString(),
                    cellClass: 'font-bold pl-0',
                    sortable: true,
                },
                {
                    key: 'place',
                    label: ctx.root.$t('add-brand-report-view.place').toString(),
                    hideDefaultContent: true,
                    cellClass: 'font-bold',
                    sortable: true,
                },
                {
                    key: 'address',
                    label: ctx.root.$t('add-brand-report-view.address').toString(),
                    formatter: (address, row) => `${row.city}, ${address}`,
                    gridColumn: '1fr',
                },
                {
                    key: 'internalStatus',
                    label: ctx.root.$t('object-status.status').toString(),
                    sortable: true,
                    hideDefaultContent: true,
                },
                {
                    key: 'expandButton',
                    cellClass: 'pl-0',
                },
            ];
        });
        const objectQuery = computed(() => {
            return {
                brandId: brand.value?.id,
                ...props.query,
                ...(props.reportId && { relatedReportId: props.reportId }),
                limit: DEFAULT_LIMIT,
            };
        });
        const { isExpanded: isObjectDetailsExpanded, toggleExpandedItems: toggleExpandObjectDetails, } = useExpandDetails((object) => object.id);
        const getObjectRowSize = () => {
            return isMobileView.value
                ? genericTableRowSize.SMALL
                : genericTableRowSize.MEDIUM;
        };
        return {
            objectQuery,
            columns,
            isObjectDetailsExpanded,
            toggleExpandObjectDetails,
            ExpandButtonStyle,
            isObjectSelected,
            onSelectObject,
            TableStyle,
            OBJECT_STATUS_COLORS,
            getStatusOptions,
            getObjectRowSize,
        };
    },
});
