import { ref, watchEffect } from '@vue/composition-api';
import { getFirstReportMessage, getReport, } from '@/api/requests/reports';
import { usePromise } from '@/composables';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
export const useReportDetails = (reportId) => {
    const report = ref(null);
    const message = ref(null);
    const { loading, result, error } = usePromise(Promise.all([getReport(reportId), getFirstReportMessage(reportId)]));
    watchEffect(() => {
        if (result.value) {
            const [reportData, reportMessage] = result.value;
            report.value = reportData;
            message.value = reportMessage;
            const { resetPlacesState, setPlaceSelectionKey } = usePlaceResourcesStore();
            const { resetObjectsState, setObjectSelectionKey } = useObjectResourcesStore();
            resetPlacesState();
            setPlaceSelectionKey('isRelatedToReport');
            resetObjectsState();
            setObjectSelectionKey('isRelatedToReport');
        }
    });
    return { loading, error, report, message };
};
