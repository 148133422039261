export const calcTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const { width } = context.measureText(text);
    return width;
};
export const upperCaseToKebabCase = (str) => str.toLowerCase().replaceAll('_', '-');
export const getTrimmedText = (text) => {
    return text.replace('/\0/g', '').trim();
};
export const getTextFromHtml = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
};
