import { computed, defineComponent } from '@vue/composition-api';
import { getFileExtension, isMultimediaFile } from '@/util/files';
import { PreviewWrapper } from '@/views/Brand/Applications/Components/Preview/PreviewWrapper';
import { ProgressBar, PROGRESS_RANGE } from '@/components/ProgressBar';
import { mdiClose } from '@mdi/js';
import { IconSize } from '@/components/Icon';
import DownloadButton from '@/views/Brand/Applications/Components/Preview/DownloadButton/DownloadButton.vue';
export default defineComponent({
    components: { PreviewWrapper, ProgressBar, DownloadButton },
    props: {
        file: {
            type: Object,
            required: true,
        },
        uploadingFiles: {
            type: Array,
            default: () => [],
        },
        downloadButton: Boolean,
        closeButton: Boolean,
        isSimplified: Boolean,
    },
    setup(props, ctx) {
        const uploadingFileProgress = computed(() => {
            const progress = props.uploadingFiles?.find((file) => file.name === props.file.fileName)?.progress;
            return progress ?? null;
        });
        const isFileUploaded = computed(() => {
            return (uploadingFileProgress.value === PROGRESS_RANGE.MAX ||
                uploadingFileProgress.value === null);
        });
        const fileExtension = computed(() => {
            return getFileExtension(props.file.fileName);
        });
        const multimediaFile = computed(() => {
            return isMultimediaFile(fileExtension.value);
        });
        const fileLabel = computed(() => {
            return fileExtension.value || ctx.root.$t('files.file').toString();
        });
        const image = computed(() => {
            if (!multimediaFile.value) {
                return;
            }
            return { src: props.file.url, alt: props.file.fileName };
        });
        return {
            image,
            fileLabel,
            mdiClose,
            IconSize,
            uploadingFileProgress,
            isFileUploaded,
        };
    },
});
