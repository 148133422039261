<template>
  <button
    v-if="mobileView"
    class="table-btn focus:outline-none block"
    @click="toggleSidebar"
  >
    <icon :icon="FiltersSvg" class="fill-primary" />
  </button>
</template>

<script>
import { Icon } from '@/components/Icon';
import { mapActions, mapState } from 'vuex';
import FiltersSvg from '@/assets/icons/filters.svg';

export default {
  name: 'FilterToggler',
  components: {
    Icon,
  },
  data() {
    return {
      FiltersSvg,
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
  },
  methods: {
    ...mapActions('ui', ['toggleSidebar']),
  },
};
</script>
