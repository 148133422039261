export var RESPONSE_MESSAGE_KEY;
(function (RESPONSE_MESSAGE_KEY) {
    RESPONSE_MESSAGE_KEY["ABORT"] = "abort";
})(RESPONSE_MESSAGE_KEY || (RESPONSE_MESSAGE_KEY = {}));
export const METHOD = {
    GET: 'get',
    DELETE: 'delete',
    HEAD: 'head',
    OPTIONS: 'options',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    PURGE: 'purge',
    LINK: 'unlink',
    UNLINK: 'unlink',
};
