export const useConfirmModal = (ctx) => {
    const getConfirmModalObject = (type, name) => {
        return {
            type: 'confirm',
            title: ctx.root.$t('confirm-delete-modal.modal-title', {
                type: ctx.root.$t(`confirm-delete-modal.${type}`),
            }),
            description: ctx.root.$t('confirm-delete-modal.modal-description', {
                name,
                type: ctx.root.$t(`confirm-delete-modal.${type}`),
            }),
            btnText: ctx.root.$t('confirm-delete-modal.modal-btn'),
        };
    };
    return { getConfirmModalObject };
};
