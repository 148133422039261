<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      v-if="brand"
      :id="id"
      ref="form"
      class="form py-1 pb-48"
      @submit.prevent="handleSubmit(pushFormData)"
      @reset.prevent="reset"
    >
      <image-uploader
        v-model="imageUrl"
        :label="$t('place-form.logo-label')"
        class="mt-6"
        :class="{ fill: imageUrl }"
      />
      <div class="input">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="form.number"
            :label="$t('place-form.place-number')"
            :placeholder="$t('place-form.place-number-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
          ></text-input>
        </ValidationProvider>
      </div>
      <div class="input">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="form.name"
            :label="$t('place-form.place-name')"
            :placeholder="$t('place-form.place-name-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
          ></text-input>
        </ValidationProvider>
      </div>
      <div class="input">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <select-input
            id="placeStructure"
            v-model="selectedStructure"
            url="structures"
            :query="{ brandId: brand.id, hasChildren: false }"
            :label="$t('place-form.structure')"
            :placeholder="$t('place-form.structure-placeholder')"
            :error-message="errors[0]"
            option-value-name="id"
            option-label-name="name"
            class="my-4"
            required
            autocomplete
          />
        </ValidationProvider>
      </div>
      <div class="input">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="form.address"
            :label="$t('place-form.address')"
            :placeholder="$t('place-form.address-placeholder-1')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
          ></text-input>
        </ValidationProvider>
      </div>
      <div class="flex -mt-1">
        <div class="input w-1/4 mr-4">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ regex: postalCodeRegex, required: true }"
          >
            <text-input
              v-model="form.postalCode"
              :placeholder="$t('place-form.address-placeholder-2')"
              :error-message="errors[0]"
              :disabled="isLoading"
              required
            ></text-input>
          </ValidationProvider>
        </div>
        <div class="input w-3/4">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <text-input
              v-model="form.city"
              :placeholder="$t('place-form.address-placeholder-3')"
              :error-message="errors[0]"
              :disabled="isLoading"
              required
            ></text-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="flex items-end -mt-1">
        <div class="input w-1/2">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <text-input
              v-model="form.lat"
              :label="$t('place-form.gps')"
              :helper-label="`(${$t('place-form.gps-helper-text')})`"
              :placeholder="$t('place-form.lat-placeholder')"
              :error-message="errors[0]"
              :disabled="isLoading"
              type="number"
              min="-90"
              max="90"
              step="any"
              required
              debounce
              @focus="$emit('set-tab', COORDINATES_TAB)"
              @input="emitPlaceCoordinates"
            ></text-input>
          </ValidationProvider>
        </div>
        <div class="input w-1/2 ml-4">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <text-input
              v-model="form.lng"
              :placeholder="$t('place-form.lng-placeholder')"
              :error-message="errors[0]"
              :disabled="isLoading"
              type="number"
              min="-180"
              max="180"
              step="any"
              required
              debounce
              @focus="$emit('set-tab', COORDINATES_TAB)"
              @input="emitPlaceCoordinates"
            ></text-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="input">
        <div class="flex mt-4">
          <select-input
            id="place-tags"
            v-model="tags"
            :query="{ relation: 'place', brandId: brand.id }"
            url="tags"
            :label="$t('place-preview.tags')"
            :placeholder="$t('place-preview.tags-placeholder')"
            option-label-name="name"
            option-value-name="id"
            autocomplete
            multiple
            clearable
            @enter="handleEnter"
          />
        </div>
      </div>
      <div class="flex items-end">
        <div class="w-3/4">
          <select-input
            id="placeAdmin"
            :key="usersListKey"
            v-model="selectedUser"
            url="users"
            :query="{ brandId: brand.id }"
            :label="$t('place-form.place-manager')"
            :placeholder="$t('place-form.place-manager-placeholder')"
            option-value-name="id"
            class="w-3/4 my-4"
            clearable
            autocomplete
            no-margin
          >
            <template #selection="{ selection }">
              <span>{{ selection.firstName }} {{ selection.lastName }}</span>
            </template>

            <template #option="{ option }">
              <span>{{ option.firstName }} {{ option.lastName }}</span>
            </template>
          </select-input>
        </div>
        <div class="w-1/4 pl-4">
          <btn
            theme="secondary"
            type="button"
            class="w-full mb-4"
            @click="$emit('open-user-form')"
          >
            {{ $t('place-form.add-new') }}
          </btn>
        </div>
      </div>
      <div class="input">
        <ValidationProvider v-slot="{ errors }">
          <text-input
            v-model="form.concept"
            :label="$t('place-form.place-concept')"
            :placeholder="$t('place-form.place-concept-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
          ></text-input>
        </ValidationProvider>
      </div>
      <div class="input">
        <ValidationProvider v-slot="{ errors }">
          <text-input
            v-model="form.type"
            :label="$t('place-form.place-type')"
            :placeholder="$t('place-form.place-type-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
          ></text-input>
        </ValidationProvider>
      </div>
      <div class="input">
        <ValidationProvider v-slot="{ errors }">
          <text-input
            v-model="form.size"
            :label="$t('place-form.place-size')"
            :placeholder="$t('place-form.place-size-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
          ></text-input>
        </ValidationProvider>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { TextInput, SelectInput } from '@/components/Inputs';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import Btn from '@/components/Button/Button.vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';
import { postalCodeRegex } from '@/util/form';
import { COORDINATES_TAB } from '@/store/modules/ui';
import manageTagsMixin from '@/mixins/manageTagsMixin';

export default {
  name: 'PlaceForm',
  components: {
    TextInput,
    SelectInput,
    ImageUploader,
    ValidationProvider,
    ValidationObserver,
    Btn,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    usersListKey: {
      type: Number,
      default: 1,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    brand: {
      type: Object,
      default: () => ({}),
    },
    clear: Boolean,
    isLoading: Boolean,
    coordinates: {
      type: Object,
      default: null,
    },
  },
  mixins: [manageTagsMixin],
  data() {
    return {
      postalCodeRegex,
      form: {
        name: '',
        number: '',
        address: '',
        postalCode: '',
        city: '',
        lat: null,
        lng: null,
        concept: null,
        type: null,
        size: null,
      },
      imageUrl: '',
      selectedStructure: null,
      selectedUser: null,
      isActive: true,
      COORDINATES_TAB,
      savedUserTags: [],
    };
  },
  watch: {
    clear(val) {
      if (val) this.clearForm();
    },
    content: {
      handler(newContent) {
        if (newContent !== null) {
          this.selectedUser = newContent.user ? newContent.user : null;
        }
      },
    },
    coordinates: {
      immediate: true,
      handler(coordinates) {
        this.form = { ...this.form, ...coordinates };
      },
    },
    tags: {
      immediate: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          this.updateQueues(this.savedUserTags, newValue);
        }
      },
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
    extend('regex', {
      ...regex,
      message: this.$t('errors.error-regex'),
    });

    this.setInitialData();
    this.savedUserTags = [...this.tags];
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    pushFormData() {
      this.$emit(
        'on-submit',
        {
          ...this.form,
          ...(this.imageUrl && { imageUrl: this.imageUrl }),
          structureId: this.selectedStructure?.id,
          userId: this.selectedUser ? this.selectedUser.id : null,
        },
        {
          tags: this.tags,
          tagsToSave: this.tagsUploadQueue,
          tagsToDelete: this.tagsDeleteQueue,
        },
      );
    },
    setInitialData() {
      const {
        name,
        number,
        address,
        postalCode,
        city,
        tags,
        imageUrl,
        structure,
        user,
        lat,
        lng,
        concept,
        type,
        size,
      } = this.content;
      this.form.name = name || '';
      this.form.number = number || '';
      this.form.address = address || '';
      this.form.postalCode = postalCode || '';
      this.form.city = city || '';
      this.form.lat = lat || null;
      this.form.lng = lng || null;
      this.form.concept = concept || null;
      this.form.type = type || null;
      this.form.size = size || null;
      this.tags = tags || [];
      this.imageUrl = imageUrl || '';
      this.selectedStructure = structure || null;
      this.selectedUser = user || null;
    },
    clearForm() {
      this.form.name = '';
      this.form.number = '';
      this.form.address = '';
      this.form.postalCode = '';
      this.form.city = '';
      this.form.lat = null;
      this.form.lng = null;
      this.form.concept = null;
      this.form.type = null;
      this.form.size = null;
      this.imageUrl = '';
      this.selectedStructure = null;
      this.selectedUser = null;
      this.tags = [];
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    getCoordinateValue(coordinate) {
      const parsedCoordinate = parseFloat(coordinate);
      if (isNaN(parsedCoordinate)) return null;

      return parsedCoordinate;
    },
    emitPlaceCoordinates() {
      const { lat, lng } = this.form;

      this.$emit('set-coordinates', {
        lat: this.getCoordinateValue(lat),
        lng: this.getCoordinateValue(lng),
      });
    },
    handleEnter(value) {
      this.createNewTag(value, () => {
        this.tags.push({ name: value.enteredValue });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  @apply mt-4 block;
  span.required {
    &::after {
      content: '*';
      @apply text-red;
    }
  }
  span {
    @apply my-1 block;
  }
}
.toggle-label {
  @apply mt-6 flex items-center;
  .toggle {
    @apply mt-0;
  }
}
</style>
