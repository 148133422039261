<template>
  <div>
    <div class="flex justify-between items-end">
      <form class="w-62">
        <text-input
          v-model="search"
          :placeholder="$t('common.search')"
          :prepend-icon="mdiMagnify"
          clearable
          class="mt-6"
        />
      </form>
      <btn class="w-30" type="submit" @click.native="onAssignForms">
        {{ $t('common.save') }}
      </btn>
    </div>
    <table-list
      v-if="brand"
      :key="componentKey"
      :content="`brands/${brand.id}/forms`"
      checkbox
      single-select
      :query="query"
      :per-page="10"
      :cols="{
        createdAt: {
          label: $t('categories-view.createdAt'),
          formatter: date,
        },
        title: $t('categories-view.title'),
      }"
      :actions="{
        edit: onEditBtnClick,
        duplicate: onDuplicateBtnClick,
      }"
      class="hover-click justify-start"
      :selected-ids="selectedFormIds"
      ellipsis-only
      @select="formsToAdd = $event"
    ></table-list>
  </div>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import TableList from '@/components/Table/TableList.vue';
import { date, label } from '@/util/formatters';
import { mapActions, mapGetters, mapState } from 'vuex';
import difference from 'lodash.difference';
import { TextInput } from '@/components/Inputs';
import { mdiMagnify } from '@mdi/js';

export default {
  components: {
    Btn,
    TableList,
    TextInput,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiMagnify,
      search: '',
      formsToAdd: [],
      componentKey: 0,
      selectedFormIds: this.selectedItems,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      error: 'error',
      currentForm: 'currentForm',
      currentTreeCategory: 'currentTreeCategory',
    }),
    ...mapGetters('brand', ['currentBrandId']),
    query() {
      return {
        ...(this.search && { q: this.search }),
        type: 'report',
      };
    },
  },
  watch: {
    selectedItems(value) {
      this.selectedFormIds = value;
    },
    formsToAdd(value) {
      this.selectedFormIds = value;
    },
  },
  methods: {
    ...mapActions('brand', ['fetchForm', 'addForm']),
    forceRerender() {
      this.componentKey += 1;
    },
    onAssignForms() {
      const removedForms = difference(this.selectedItems, this.formsToAdd);

      if (this.formsToAdd.length) {
        this.$emit('submit', { add: this.formsToAdd, remove: removedForms });
      }
    },
    onEditBtnClick(id) {
      this.$router.push({ name: 'EditForm', params: { formId: id } });
    },
    async onDuplicateBtnClick(id) {
      await this.fetchForm(id);

      await this.addForm({
        data: {
          components: this.currentForm.components,
          title: this.currentForm.title,
          decisionMakersIds: this.currentForm.decisionMakers.map((item) => {
            return item.id;
          }),
          brandId: this.currentBrandId(this.$route),
          type: this.currentForm.type,
          categoryId: this.currentForm.category?.id || this.currentTreeCategory,
        },
      });

      this.forceRerender();
    },
    date,
    label,
  },
};
</script>
