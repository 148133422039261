import { defineComponent } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import { mdiCheck, mdiClose } from '@mdi/js';
export default defineComponent({
    components: { Icon },
    props: {
        loading: Boolean,
    },
    setup() {
        return {
            mdiCheck,
            mdiClose,
            IconSize,
        };
    },
});
