var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fetch-data',{staticClass:"relative",attrs:{"url":"reports","params":_vm.reportsQuery,"force-update":_vm.updatesCounter,"debounce":_vm.Debounce.DEFAULT},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var isLoading = ref.isLoading;
var pagination = ref.pagination;
return [(results && results.length)?_c('table-display',{attrs:{"rows":results,"columns":_vm.columns,"sort":_vm.sortConfig,"row-class":_vm.getReportClassName,"row-size":_vm.getReportRowSize(),"table-style":_vm.TableStyle.Flat},on:{"record-click":function($event){return _vm.$emit('open', $event.id)},"sort":_vm.sort},scopedSlots:_vm._u([{key:"checkboxHeader",fn:function(){return [_c('popover',{attrs:{"offset":"45, 5"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var attrs = ref.attrs;
          var togglePopoverVisibility = ref.togglePopoverVisibility;
          var isOpened = ref.isOpened;
          var closePopover = ref.closePopover;
return [_c('div',{staticClass:"list-header-checkbox",class:[isOpened ? 'active' : 'hovered']},[_c('list-header-checkbox',{staticClass:"py-2 px-1.5",attrs:{"list-items":results,"selected-items":_vm.selectedEntries},on:{"input":function($event){return _vm.onSelectAll(results)}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}}),_c('button',_vm._b({staticClass:"py-1",attrs:{"size":"sm"},on:{"click":function($event){return togglePopoverVisibility()}}},'button',attrs,false),[_c('icon',{staticClass:"text-gray-darkest",attrs:{"icon":_vm.mdiChevronDown}})],1),(isOpened)?_c('showing-menu',{staticClass:"text-sm px-2 absolute top-10 mt-1",attrs:{"items":_vm.getReportsListActions(closePopover)}}):_vm._e()],1)]}}],null,true)})]},proxy:true},{key:"checkboxCell",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"checkbox-cell"},[(_vm.isMobileView)?_c('status-toggler',{staticClass:"-ml-1",attrs:{"disabled":"","compact":_vm.isMobileView,"compact-variant":_vm.StatusButtonVariant.SIMPLE,"colors":_vm.REPORT_STATUS_COLORS,"options":_vm.getStatusOptions(row.status),"current-status":row.status,"status-translation":"report-status"},on:{"change-status":function (status) { return _vm.changeReportStatus(row.id, status); }},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e(),_c('div',{staticClass:"hidden md:flex gap-4"},[_c('checkbox',{attrs:{"table":"","value":_vm.selectedEntries.some(function (entry) { return entry.id === row.id; })},on:{"input":function($event){return _vm.changeState(row, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}),_c('star-toggle',{attrs:{"value":row.isStarred},on:{"input":function($event){return _vm.handleStarReport(row)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1)],1)]}},{key:"numberByBrandCell",fn:function(ref){
          var value = ref.value;
          var row = ref.row;
return [_c('div',{class:{ 'font-bold': _vm.isReportMessageUnread(row) }},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"creatorCell",fn:function(ref){
          var value = ref.value;
          var row = ref.row;
return [_c('span',{class:{ 'font-bold': _vm.isReportMessageUnread(row) }},[_vm._v(" "+_vm._s(_vm.getFullName(value))+" "),(row.numberOfUnseenMessages > 0 && _vm.isMobileView)?_c('span',[_vm._v(" ("+_vm._s(row.numberOfUnseenMessages)+") ")]):_vm._e()]),(_vm.isMobileView)?_c('span',[_c('title-with-description',{staticClass:"text-xs",attrs:{"report":row,"show-message-in-new-line":""}})],1):_vm._e(),(row.numberOfUnseenMessages > 0 && !_vm.isMobileView)?_c('span',{staticClass:"text-xs font-bold text-black"},[_vm._v(" ("+_vm._s(row.numberOfUnseenMessages)+") ")]):_vm._e(),(_vm.isMobileView)?_c('span',{staticClass:"text-xs",class:{ 'font-bold': _vm.isReportMessageUnread(row) }},[_vm._v(" "+_vm._s(_vm.date(row.updatedAt))+" ")]):_vm._e()]}},{key:"titleCell",fn:function(ref){
          var row = ref.row;
return [_c('title-with-description',{attrs:{"report":row}})]}},{key:"placesCell",fn:function(ref){
          var value = ref.value;
return [(value.length > 0)?_c('div',{staticClass:"bg-blue-light rounded-md px-2.5 py-1 font-semibold text-3xs flex"},[_c('div',{staticClass:"max-w-32 truncate"},[_vm._v(" "+_vm._s(value[0].name)+" ")]),(value.length > 1)?_c('div',[_vm._v(", +"+_vm._s(value.length - 1))]):_vm._e()]):_vm._e()]}},{key:"expandableHeader",fn:function(){return [(!_vm.isMobileView)?_c('div',{staticClass:"flex gap-4 items-center justify-end"},[_c('div',[_vm._v(_vm._s(_vm.$t('brand-reports-list-view.date-updated')))]),_c('div',[_vm._v(_vm._s(_vm.$t('brand-reports-list-view.status')))])]):_vm._e()]},proxy:true},{key:"expandableCell",fn:function(ref){
          var row = ref.row;
          var isRowHovered = ref.isRowHovered;
return [_c('expandable-cell',{attrs:{"row":row,"update":_vm.update,"is-row-hovered":isRowHovered,"change-report-status":_vm.changeReportStatus,"status-options":_vm.getStatusOptions(row.status),"actions":_vm.getActions(row)},on:{"edit":function($event){return _vm.$emit('edit', $event)}}})]}}],null,true)}):(!isLoading)?_c('NoResults',{attrs:{"message":_vm.$t('brand-reports-list-view.no-reports')},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('add-report-button',{attrs:{"open-popup":_vm.openPopup,"text":_vm.$t('brand-reports-list-view.add-a-report'),"button-class":"w-fit"}})]},proxy:true}])}):_vm._e(),_c('table-pagination',{staticClass:"px-4 md:px-0",attrs:{"path":"reports","pagination":pagination,"show-entries-counter":results && results.length,"is-loading":isLoading,"query":_vm.reportsQuery}}),(isLoading)?_c('table-loader',{staticClass:"mt-5"}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }