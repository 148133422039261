<template>
  <split-layout :left-width="57" :right-width="43" right-fluid>
    <template #leftSide>
      <offer-details-header />
      <div>
        <offer-title-description
          :title="offer.title"
          :description="offer.description"
        />
        <offer-place-object-list
          :loading="loading"
          include-objects
          details
          class="mb-5"
        />
      </div>
      <offer-costs
        :key="`${offer.id}-list`"
        :sum="offer.objectsMonthlyPriceSum"
        @relation-update="onRelationUpdate"
        @mouse-enter-row="highlightedRow = $event"
        @mouse-leave-row="highlightedRow = null"
      />

      <object-cards
        v-if="showObjectCards"
        :key="objectCardsUpdateKey"
        :loading="loading"
      />

      <custom-form-preview
        v-if="offer.components"
        :components="offer.components"
        :values="offer.values"
        value-class-name="font-thin text-xs mt-1 mb-4"
        label-class-name="text-xs font-semibold"
        class="mb-4 mt-6"
      />
    </template>
    <template #rightSide>
      <offer-objects-map
        :offer-id="offer.id"
        :active-marker-id="highlightedRow ? highlightedRow.id : null"
      />
    </template>
  </split-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VueStore from '@/store';
import { OFFER_STATUS_MAP } from '@/constants/offer';
import OfferObjectsMap from '@/views/Brand/Applications/Components/Offers/OfferObjectsMap.vue';
import OfferTitleDescription from '@/views/Brand/Applications/Components/Offers/OfferTitleDescription.vue';
import OfferPlaceObjectList from '@/views/Brand/Applications/Components/Offers/OfferPlaceObjectList.vue';
import ObjectCards from '@/views/Brand/Applications/Components/ObjectCards.vue';
import OfferCosts from '@/views/Brand/Applications/Components/Offers/Costs/OfferCosts.vue';
import { CustomFormPreview } from '@/views/_components/CustomForms';
import { getObjectsList } from '@/api/requests/objects';

import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
import SplitLayout from '@/layouts/SplitLayout.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { OfferDetailsHeader } from '@/views/Brand/Applications/Offer/Details/components/offer-details-header';

export default defineComponent({
  name: 'OfferDetails',
  components: {
    SplitLayout,
    OfferCosts,
    OfferPlaceObjectList,
    OfferTitleDescription,
    OfferObjectsMap,
    CustomFormPreview,
    ObjectCards,
    OfferDetailsHeader,
  },
  data() {
    return {
      loading: false,
      lastRoute: { name: 'Offers' },
      OFFER_STATUS_MAP,
      objectRelationsCount: 0,
      CONTROLLER_DIRECTION,
      objectCardsUpdateKey: 0,
    };
  },
  computed: {
    ...mapState('offer', {
      offer: 'currentOffer',
    }),
    ...mapGetters('offer', ['nextOffer', 'prevOffer']),
    offerHeader() {
      return `${this.$t('add-brand-offer-view.offer').toUpperCase()}: ${
        this.offer.internalNumber
      }`;
    },
    showObjectCards() {
      return this.objectRelationsCount && this.objectRelationsCount <= 20;
    },
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('offer/fetchOffer', to.params.offerId);

    next((vm) => {
      if (from.path !== '/') vm.lastRoute = from;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await VueStore.dispatch('offer/fetchOffer', to.params.offerId);

    next();
  },
  async beforeRouteLeave(to, from, next) {
    await VueStore.dispatch('attachment/clearAttachmentList');
    next();
  },
  watch: {
    offer: {
      async handler(value) {
        if (value) await this.checkObjectRelationCount();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('offer', ['fetchOffer']),
    async onRelationUpdate(value) {
      await this.fetchOffer(this.$route.params.offerId);
      if (value) {
        this.objectCardsUpdateKey++;
      }
    },
    async checkObjectRelationCount() {
      this.loading = true;

      try {
        const { pagination } = await getObjectsList({
          offerId: this.offer.id,
          limit: 1,
        });

        this.objectRelationsCount = pagination.total;
      } finally {
        this.loading = false;
      }
    },
  },
  setup() {
    const highlightedRow = ref(null);

    return { highlightedRow };
  },
});
</script>
