import { defineComponent, ref, watch } from '@vue/composition-api';
import { setPlaceObjectTypeBudget } from '@/api/requests/places';
import { ImageSize } from '@/components/ImageView';
import { IMAGE_PLACEHOLDER_URL } from '@/constants/common';
import BudgetActions from '@/views/Brand/Places/_components/budget-tree-node/_components/budget-actions/BudgetActions.vue';
import { TextInput } from '@/components/Inputs';
import { formatMoney } from '@/util/formatters';
import { useRouteQuery } from '@/composables';
export default defineComponent({
    components: { TextInput, BudgetActions },
    computed: {
        ImageSize() {
            return ImageSize;
        },
    },
    props: {
        place: {
            type: Object,
            required: true,
        },
        structure: {
            type: Object,
            required: true,
        },
        editLevel: Number,
        objectType: Object,
    },
    setup(props, ctx) {
        const expenses = ref(0);
        const isEdit = ref(false);
        const budget = ref(0);
        const currentLevel = props.structure?.path?.split('.').length - 1;
        const budgetExists = currentLevel <= (props.editLevel || 0);
        const { routeQuery } = useRouteQuery(ctx);
        const onEdit = (place) => {
            budget.value = place.budget || 0;
            isEdit.value = true;
        };
        const onAccept = async (place) => {
            if (!props.objectType) {
                return;
            }
            await setPlaceObjectTypeBudget(place.id, props.objectType?.id, {
                budget: parseInt(budget.value.toString()),
            });
            isEdit.value = false;
            ctx.emit('on-accept');
        };
        const onReject = () => {
            budget.value = props.place.budget || 0;
            isEdit.value = false;
        };
        const highlightSearchResults = (name) => {
            const regex = new RegExp(routeQuery.value.q, 'gi');
            return name.replace(regex, `<span class="bg-yellow">$&</span>`);
        };
        watch(() => props.place, () => {
            budget.value = props.place.budget || 0;
        }, { deep: true, immediate: true });
        return {
            highlightSearchResults,
            IMAGE_PLACEHOLDER_URL,
            expenses,
            onEdit,
            onAccept,
            onReject,
            formatMoney,
            isEdit,
            budget,
            budgetExists,
        };
    },
});
