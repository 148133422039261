export const OFFER_STATUS_MAP = {
  ACTIVE: 'active',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  FINISHED: 'finished',
  ACCEPTED: 'accepted',
};

export const OFFER_STATUS_COLORS = {
  [OFFER_STATUS_MAP.ACTIVE]: 'bg-green',
  [OFFER_STATUS_MAP.APPROVED]: 'bg-green',
  [OFFER_STATUS_MAP.REJECTED]: 'bg-red',
  [OFFER_STATUS_MAP.FINISHED]: 'bg-blue-dark',
};
