import { defineComponent, ref } from '@vue/composition-api';
import { useActionRequired } from '@/views/Brand/Applications/Report/_composables/use-action-required';
import { addReportMessage, } from '@/api/requests/reports';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { useBrand } from '@/composables';
import UserAvatar from '@/views/_components/UserAvatar.vue';
import { getFullName } from '@/util/user';
import { getUserAvatar } from '@/util/avatars';
import { WysiwygInput } from '@/components/WysiwygInput';
import { mdiAlertOctagonOutline } from '@mdi/js';
import Icon from '@/components/Icon/Icon.vue';
import TimeSinceDisplay from '@/views/Brand/Applications/Report/_components/time-since-display/TimeSinceDisplay.vue';
import { ActionRequiredResponseInput } from '@/views/Brand/Applications/Report/_components/action-required-response-input';
export default defineComponent({
    methods: { getUserAvatar },
    components: {
        ActionRequiredResponseInput,
        TimeSinceDisplay,
        Icon,
        WysiwygInput,
        UserAvatar,
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const fileTokens = ref([]);
        const getMentionSuggestions = createMentionUserFn({
            accessToBrandId: brand.value.id,
        });
        const { requireActionUser, requireActionUserResponseMessage, isRequiredActionUser, } = useActionRequired({
            user: props.message.requiredActionUser,
        });
        const onActionRequiredMessageSend = async () => {
            await addReportMessage({
                requiredActionMessageId: props.message.id,
                reportId: props.message.report.id,
                message: requireActionUserResponseMessage.value,
                fileTokens: fileTokens.value,
                placeIds: [],
                objectIds: [],
            });
            ctx.emit('add-response');
            ctx.root.$toast.success(ctx.root.$t('report.successfully-replied'));
        };
        return {
            fileTokens,
            mdiAlertOctagonOutline,
            getMentionSuggestions,
            requireActionUser,
            requireActionUserResponseMessage,
            isRequiredActionUser,
            onActionRequiredMessageSend,
            getFullName,
        };
    },
});
