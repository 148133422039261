import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';
import { GenericTable, TableStyle } from '@/components/Table/__new__';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { AcceptanceLevel } from '@/api/requests/offers';
import { getDisplayPrice } from '@/util/price';
import ObjectAcceptCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/ObjectAcceptCell.vue';
import { createObjectInOfferDetailsRoute } from '@/router/utils';
import { useBrand } from '@/composables';
const { useState } = createNamespacedHelpers('offer');
export default defineComponent({
    components: {
        ObjectAcceptCell,
        InfiniteData,
        TableDisplay: GenericTable(),
    },
    props: {
        sum: {
            type: Number,
            default: 0,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { currentOffer } = useState(['currentOffer']);
        const getObjectRoute = (object) => {
            const { id, objectType } = object;
            if (!objectType || !brand.value || !currentOffer.value)
                return '';
            return createObjectInOfferDetailsRoute(brand.value.id, id, objectType.id, currentOffer.value.id);
        };
        const columns = computed(() => {
            return [
                {
                    key: 'id',
                    label: ctx.root.$t('offer-costs.name').toString(),
                    formatter: () => ctx.root.$t('offer-costs.object').toString(),
                    cellClass: 'font-bold',
                    hideInMobile: true,
                },
                {
                    key: 'internalNumber',
                    label: ctx.root.$t('offer-costs.number').toString(),
                    cellClass: 'font-bold',
                },
                {
                    key: 'quantity',
                    label: ctx.root.$t('offer-costs.quantity').toString(),
                    formatter: () => '1',
                    cellClass: 'font-bold',
                    hideInMobile: true,
                },
                {
                    key: 'unit',
                    label: ctx.root.$t('offer-costs.unit').toString(),
                    formatter: () => ctx.root.$t('offer-costs.pc').toString(),
                    cellClass: 'font-bold',
                    hideInMobile: true,
                },
                {
                    key: 'monthlyPrice',
                    label: ctx.root.$t('offer-costs.price').toString(),
                    cellClass: 'font-bold',
                },
                {
                    key: 'centralStatus',
                    label: ctx.root.$t('offer-costs.central').toString(),
                    cellClass: 'flex justify-center',
                    hideDefaultContent: true,
                },
                {
                    key: 'structureStatus',
                    label: ctx.root.$t('offer-costs.structure').toString(),
                    cellClass: 'flex justify-center p-0',
                    hideDefaultContent: true,
                },
                {
                    key: 'placeStatus',
                    label: ctx.root.$t('offer-costs.place').toString(),
                    cellClass: 'flex justify-center',
                    hideDefaultContent: true,
                },
            ];
        });
        const url = computed(() => {
            return `objects`;
        });
        const objectQuery = computed(() => {
            return {
                offerId: currentOffer.value.id,
            };
        });
        const formattedSum = computed(() => getDisplayPrice(props.sum));
        const updateKey = ref(0);
        const updatedByCentral = ref(false);
        watch(updateKey, () => {
            ctx.emit('relation-update', updatedByCentral.value);
            updatedByCentral.value = false;
        });
        const onRelationUpdate = (isUpdatedByCentral) => {
            updatedByCentral.value = isUpdatedByCentral;
            updateKey.value++;
        };
        return {
            AcceptanceLevel,
            formattedSum,
            TableStyle,
            url,
            columns,
            currentOffer,
            updateKey,
            getObjectRoute,
            objectQuery,
            onRelationUpdate,
        };
    },
});
