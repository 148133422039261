<template>
  <div v-if="userId">
    <fetch-data v-slot="{ data, isLoading }" :url="`users/${userId}`">
      <div v-if="isLoading" class="h-40 flex items-center justify-center">
        <loading-spinner is-loading class-names="h-12 w-12" />
      </div>
      <div v-else class="user-preview">
        <div class="avatar">
          <img src="@/assets/default-avatar.png" class="m-0" />
        </div>
        <div class="w-full pl-4">
          <div class="flex flex-col mb-2 gap-2 flex-wrap">
            <div
              class="mb-2 xs:mb-0 text-lg font-semibold mr-2 max-w-80 truncate md:max-w-none"
            >
              {{ data.firstName }} {{ data.lastName }}
            </div>
            <btn
              class="w-30"
              @click="
                $router.push({
                  name: 'UserDetails',
                  params: { userId: data.id },
                })
              "
            >
              {{ $t('common.details') }}
            </btn>
          </div>
          <div class="flex flex-col gap-1 text-xs">
            <div class="flex gap-2">
              <span class="text-gray-dark">{{ $t('common.phone') }}:</span>
              <div class="truncate">
                {{ data.phone }}
              </div>
            </div>
            <div class="flex gap-2">
              <span class="text-gray-dark">{{ $t('common.email') }}:</span>
              <div class="truncate">
                <a class="text-links" :href="`mailto:${data.email}`">
                  {{ data.email }}
                </a>
              </div>
            </div>
            <div class="flex gap-2">
              <span class="text-gray-dark">
                {{ $t('common.internal-role') }}:
              </span>
              <div class="truncate uppercase">
                {{ data.internalRole ? data.internalRole : '-' }}
              </div>
            </div>
            <div class="flex gap-2">
              <span class="text-gray-dark">{{ $t('common.role') }}:</span>
              <div class="truncate uppercase">
                {{ $t(`roles.${data.role ? data.role : 'no-role'}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </fetch-data>
  </div>
</template>
<script>
import FetchData from '@/components/DataProvider/FetchData';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import Btn from '@/components/Button/Button.vue';

export default {
  components: {
    LoadingSpinner,
    FetchData,
    Btn,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.user-preview {
  @apply flex flex-col items-center;

  @screen sm {
    @apply flex-row items-start;
  }

  .avatar {
    @apply mb-8 flex justify-center;

    @screen md {
      @apply w-52 mr-8 mb-0 block;
    }
  }

  .info {
    @apply mt-3;
    li {
      @apply my-2 text-sm leading-snug;
      span {
        @apply w-20 text-gray-dark inline-block;
      }
      b {
        @apply font-semibold;
      }
    }
  }
}
</style>
