const createClusterMarkerContent = (count) => {
    const content = document.createElement('div');
    content.innerHTML = `
<div class="relative">
    <img src="/img/cluster-marker.svg" alt="Cluster marker" class="mx-auto w-10" />
    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">${count}</span>
</div>
    `;
    return content;
};
export class ClusterMarkerRenderer {
    render({ position, count, }) {
        return new google.maps.marker.AdvancedMarkerElement({
            position,
            content: createClusterMarkerContent(count),
        });
    }
}
