<template>
  <div @mouseenter="show = true" @mouseleave="show = false">
    <slot></slot>
    <div v-show="isTooltipVisible" ref="tip" class="tip">
      <span v-if="label">{{ label }}</span>
      <slot v-else name="label" />
    </div>
  </div>
</template>
<script>
import Popper from 'popper.js';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  data() {
    return {
      show: false,
      popperInstance: null,
      tip: null,
    };
  },
  watch: {
    isTooltipVisible(value) {
      if (value) this.popperInstance.scheduleUpdate();
    },
  },
  computed: {
    isTooltipVisible() {
      return this.show && !this.disabled;
    },
  },
  mounted() {
    this.tip = this.$refs.tip;

    if (this.appendToBody) {
      this.$el.removeChild(this.tip);
      document.body.appendChild(this.tip);
    }

    this.popperInstance = new Popper(this.$el, this.tip, {
      placement: 'bottom',
      removeOnDestroy: true,
      positionFixed: true,
      modifiers: {
        offset: {
          offset: '0, 5',
        },
        flip: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
      },
    });
  },
  beforeDestroy() {
    this.popperInstance.destroy();
  },
};
</script>
<style scoped lang="scss">
.tip {
  @apply relative z-60 bg-gray-darker z-60 rounded px-4 py-2 text-sm font-normal text-white max-w-56;
}
</style>
