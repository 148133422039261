import api from '@/api';
import { KFC } from '@/themes';

export default {
  namespaced: true,
  state: {
    details: null,
    categories: {},
    contractors: {},
    forms: {},
    user: null,
    place: null,
    error: false,
    currentCategory: {},
    currentTreeCategory: null,
    selectedForms: [],
    currentForm: null,
    currentOfferForm: null,
  },
  getters: {
    brandTheme(state) {
      const styles = state.details?.styles;

      return styles || KFC;
    },
    currentBrandId: (state, root, rootGetters) => (route) => {
      const { id } = route.params;
      return id || rootGetters.user.brand;
    },
  },
  mutations: {
    SET_CATEGORIES(state, { data }) {
      state.categories = data;
    },
    SET_CONTRACTORS(state, { data }) {
      state.contractors = data;
    },
    SET_FORMS(state, { data }) {
      state.forms = data;
    },
    SET_CURRENT_FORM(state, { data }) {
      state.currentForm = data;
    },
    SET_CURRENT_OFFER_FORM(state, { data }) {
      state.currentOfferForm = data;
    },
    SET_BRAND(state, { data }) {
      state.details = data;
      state.error = false;
    },
    SET_BRAND_USER(state, { data }) {
      state.user = data;
      state.error = false;
    },
    SET_BRAND_PLACE(state, { data }) {
      state.place = data;
      state.error = false;
    },
    SET_ERROR(state) {
      state.error = true;
    },
    SET_CURRENT_CATEGORY(state, { data }) {
      state.currentCategory = data;
    },
    SET_SELECTED_FORMS(state, { data }) {
      state.selectedForms = data;
    },
    SET_TREE_CATEGORY(state, { data }) {
      state.currentTreeCategory = data;
    },
    CLEAR_PLACE(state) {
      state.place = null;
    },
  },
  actions: {
    async fetchBrandContractors({ commit }) {
      try {
        commit('SET_CONTRACTORS', await api.get(`/contractors`));
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    async fetchBrandForms({ commit }, id) {
      try {
        const forms = await api.get(`/brands/${id}/forms`);

        commit('SET_FORMS', forms);
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    async fetchForm({ commit }, id) {
      try {
        const form = await api.get(`/forms/${id}`);

        commit('SET_CURRENT_FORM', form);

        const { offerFormId } = form.data;

        if (offerFormId) {
          commit(
            'SET_CURRENT_OFFER_FORM',
            await api.get(`/forms/${offerFormId}`),
          );
        }
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    async fetchBrandInfo({ dispatch }, brandId) {
      await dispatch('fetchBrandDetails', brandId);
    },
    async createBrand(ctx, data) {
      await api.post('/brands', data);
    },
    async updateBrand(ctx, { brandId, data }) {
      await api.patch(`/brands/${brandId}`, data);
    },
    async fetchBrandDetails({ commit }, id) {
      commit('SET_BRAND', { data: null });
      try {
        commit('SET_BRAND', await api.get(`/brands/${id}`));
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },

    async addBrandUser(ctx, { form }) {
      return await api.post('users', form);
    },

    async updateBrandUser(ctx, { userId, data }) {
      await api.patch(`/users/${userId}`, data);
    },

    async fetchBrandUserDetails({ commit }, id) {
      commit('SET_BRAND_USER', { data: null });
      try {
        const user = await api.get(`/users/${id}`);
        user.data.structures ??= [];
        user.data.places ??= [];
        commit('SET_BRAND_USER', user);
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },

    async addBrandPlace(ctx, form) {
      return await api.post('places', form);
    },
    async updateBrandPlace(ctx, { placeId, data }) {
      await api.patch(`/places/${placeId}`, data);
    },

    async deleteBrandPlaceImage(ctx, placeId) {
      await api.delete(`/places/${placeId}/image`);
    },

    async getFirstPlaceId(ctx, id) {
      const { data } = await api.get('/places', { params: { brandId: id } });
      return data.results?.[0]?.id;
    },

    async fetchBrandPlaceDetails({ commit }, id) {
      commit('SET_BRAND_PLACE', { data: null });
      try {
        commit('SET_BRAND_PLACE', await api.get(`/places/${id}`));
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    addCategory(ctx, params) {
      return api.post(`/categories/${params.id}`, params.data);
    },
    addRootCategory(ctx, params) {
      return api.post(`/categories/root`, {
        ...params.data,
        brandId: params.id,
      });
    },
    addSubCategory(ctx, params) {
      return api.post(`/categories/sub`, {
        ...params.data,
        parentId: params.id,
      });
    },
    editCategory(ctx, params) {
      return api.patch(`/categories/${params.id}`, params.data);
    },
    deleteCategoryIcon(ctx, id) {
      return api.delete(`categories/${id}/icon`);
    },
    deleteCategory(ctx, id) {
      return api.delete(`/categories/${id}`);
    },
    setMainCategory({ commit }, data) {
      commit('SET_CURRENT_CATEGORY', { data });
    },
    async fetchMainCategory({ commit }, payload) {
      let result = {};
      commit('SET_CURRENT_CATEGORY', { data: null });
      const params = new URLSearchParams();
      params.append('parentId', null);

      try {
        result = await api.get(`/brands/${payload.brandId}/categories`, {
          params,
        });
        commit('SET_CURRENT_CATEGORY', result);
      } catch (err) {
        commit('SET_ERROR', 1);
      }

      return result;
    },
    async fetchBrandCategory({ commit }, payload) {
      let result = {};
      commit('SET_CATEGORIES', { data: null });
      const params = new URLSearchParams();
      params.append('brandId', payload.brandId);
      params.append('parentId', payload.categoryId);

      try {
        result = await api.get(`/categories`, {
          params,
        });
        commit('SET_CATEGORIES', result);
      } catch (err) {
        commit('SET_ERROR', 1);
      }

      return result;
    },
    async fetchBrandSubCategory({ commit }, payload) {
      let result = {};
      const params = new URLSearchParams();
      params.append('brandId', payload.brandId);
      params.append('parentId', payload.categoryId);

      try {
        result = await api.get(`/categories`, {
          params,
        });
      } catch (err) {
        commit('SET_ERROR', 1);
      }

      return result;
    },
    async fetchBrandCategoryForms({ commit }, payload) {
      let result = {};
      const params = new URLSearchParams();
      params.append('categoryId', payload.categoryId);
      if (payload.type) params.append('type', payload.type);

      try {
        result = await api.get(`/brands/${payload.brandId}/forms`, { params });
      } catch (err) {
        commit('SET_ERROR', 1);
      }
      return result;
    },
    addForm(ctx, { data }) {
      return api.post(`/forms`, data);
    },
    clearFormCategory(ctx, id) {
      return api.patch(`/forms/${id}`, {
        categoryId: null,
      });
    },
    async editCategoryWithForm(ctx, payload) {
      return await api.patch(`/forms/${payload.formId}`, {
        categoryId: payload.categoryId,
      });
    },
    async editForm(ctx, { formId, data }) {
      return await api.patch(`/forms/${formId}`, data);
    },
    async fetchObjectType(ctx, id) {
      const { data } = await api.get(`object-types/${id}`);

      return data;
    },
    clearPlace({ commit }) {
      commit('CLEAR_PLACE');
    },
    clearCategories({ commit }) {
      commit('SET_CATEGORIES', { data: {} });
    },
  },
};
