import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import { Debounce } from '@/constants/debounce';
import { mdiChevronDown, mdiChevronUp, mdiClose } from '@mdi/js';
import { useToggle } from '@/composables';
import { getUid } from '@/util/common';
import { FormElement, formElementProps, } from '@/components/Inputs/_components/FormElement';
import { BaseInput, baseInputProps, } from '@/components/Inputs/_components/BaseInput';
import { SelectDropdown, } from './_components/SelectDropdown';
import { useKeyboardNavigation } from './_composables/use-keyboard-navigation';
import { useSelectOptions } from '@/components/Inputs/SelectInput/_composables/use-select-options';
import SelectInputInner from '@/components/Inputs/SelectInput/_components/SelectInputInner/SelectInputInner.vue';
import OverflowWrapper from '@/components/OverflowWrapper/OverflowWrapper.vue';
export default defineComponent({
    name: 'SelectInput',
    components: {
        SelectInputInner,
        SelectDropdown,
        FormElement,
        BaseInput,
        OverflowWrapper,
    },
    props: {
        id: String,
        value: null,
        options: {
            type: Array,
            default: () => [],
        },
        optionValueName: {
            type: String,
            default: 'value',
        },
        optionLabelName: {
            type: String,
            default: 'label',
        },
        url: String,
        query: {
            type: Object,
            default: () => ({}),
        },
        disableItem: Function,
        debounce: Boolean,
        delay: {
            type: Number,
            default: Debounce.DEFAULT,
        },
        autofocus: Boolean,
        multiple: Boolean,
        autocomplete: Boolean,
        placeholder: String,
        singleLine: Boolean,
        withoutIcon: Boolean,
        hideAutocomplete: Boolean,
        loading: Boolean,
        optionsDimensionsClass: String,
        dropdownPosition: String,
        ...formElementProps,
        ...baseInputProps,
    },
    setup(props, ctx) {
        const input = ref(null);
        const isFocused = ref(false);
        const isHovered = ref(false);
        const debouncedEvent = ref(null);
        const parsedId = computed(() => {
            return props.id ?? `input-${getUid()}`;
        });
        const onFocus = () => {
            ctx.emit('focus');
            isFocused.value = true;
        };
        const onBlur = () => {
            if (debouncedEvent.value) {
                debouncedEvent.value.flush();
            }
            ctx.emit('blur');
            isFocused.value = false;
        };
        const listId = `list-${getUid()}`;
        const { isOptionSelected, selectedValues, loadedOptions, parsedOptions, optionsUpdateCounter, unselectValue, clearValues, handleSelect, update, } = useSelectOptions(props, ctx);
        const isCloseIconDisplayed = computed(() => {
            return (props.clearable && isHovered.value && selectedValues.value.length > 0);
        });
        const parsedAppendIcon = computed(() => {
            if (props.withoutIcon) {
                return '';
            }
            else if (isCloseIconDisplayed.value) {
                return mdiClose;
            }
            return isOpened.value ? mdiChevronUp : mdiChevronDown;
        });
        const autocompleteSearch = ref('');
        const { state: isOpened, toggle: toggleOptionArea } = useToggle();
        const openOptionArea = () => {
            if (isOpened.value === false) {
                isOpened.value = true;
                update();
            }
        };
        const closeOptionArea = () => {
            autocompleteSearch.value = '';
            if (isOpened.value === true) {
                isOpened.value = false;
            }
        };
        const onSelect = () => {
            autocompleteSearch.value = '';
            closeOptionArea();
        };
        const { focusedOptionIndex, focusNextOption, focusPrevOption, handleEnter, } = useKeyboardNavigation({
            options: parsedOptions,
            handleSelect: (option) => handleSelect(option, onSelect),
            openOptionArea: openOptionArea,
            autocompleteValue: autocompleteSearch,
            ctx: ctx,
        });
        watch(isOpened, (newIsOpened, oldIsOpened) => {
            if (!newIsOpened && oldIsOpened) {
                focusedOptionIndex.value = null;
            }
        });
        const handleClick = () => {
            if (props.disabled)
                return;
            if (props.autocomplete) {
                openOptionArea();
                input.value?.focus();
            }
            else {
                toggleOptionArea();
            }
        };
        const handleAppendIconClick = () => {
            if (isCloseIconDisplayed.value) {
                clearValues();
            }
        };
        const computedQuery = computed(() => {
            const query = {
                ...props.query,
                limit: 25,
            };
            if (autocompleteSearch.value.length > 0) {
                query.q = autocompleteSearch.value;
            }
            return query;
        });
        return {
            selectedValues,
            unselectValue,
            handleAppendIconClick,
            parsedOptions,
            isFocused,
            isHovered,
            parsedAppendIcon,
            parsedId,
            onFocus,
            onBlur,
            listId,
            focusedOptionIndex,
            focusNextOption,
            focusPrevOption,
            handleEnter,
            handleClick,
            isOpened,
            closeOptionArea,
            handleSelect,
            isOptionSelected,
            autocompleteSearch,
            computedQuery,
            loadedOptions,
            onSelect,
            optionsUpdateCounter,
        };
    },
});
