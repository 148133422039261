<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      :id="id"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ regex: hexColorRegex, required: true }"
      >
        <color-input
          id="color-input-1"
          v-model="colors.primary"
          :label="$t('theme-view.primary-label')"
          :placeholder="$t('theme-view.color-placeholder')"
          :is-error="errors[0]"
          :disabled="disabled"
          required
          class="mt-6"
          @input="$emit('update-theme', colors)"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ regex: hexColorRegex, required: true }"
      >
        <color-input
          id="color-input-2"
          v-model="colors.primaryLight"
          :label="$t('theme-view.primary-light-label')"
          :placeholder="$t('theme-view.color-placeholder')"
          required
          :is-error="errors[0]"
          :disabled="disabled"
          class="mt-6"
          @input="$emit('update-theme', colors)"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ regex: hexColorRegex, required: true }"
      >
        <color-input
          id="color-input-3"
          v-model="colors.primaryHover"
          :label="$t('theme-view.primary-hover-label')"
          :placeholder="$t('theme-view.color-placeholder')"
          required
          :is-error="errors[0]"
          :disabled="disabled"
          class="mt-6"
          @input="$emit('update-theme', colors)"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ regex: hexColorRegex, required: true }"
      >
        <color-input
          id="color-input-4"
          v-model="colors.links"
          :label="$t('theme-view.links-label')"
          :placeholder="$t('theme-view.color-placeholder')"
          required
          :is-error="errors[0]"
          :disabled="disabled"
          class="mt-6"
          @input="$emit('update-theme', colors)"
        />
      </ValidationProvider>
    </form>
  </ValidationObserver>
</template>
<script>
import ColorInput from '@/components/FormElements/ColorInput';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { regex, required } from 'vee-validate/dist/rules';
import { hexColorRegex } from '@/util/form';

export default {
  components: { ColorInput, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
    theme: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: {},
      hexColorRegex,
    };
  },
  watch: {
    theme: {
      handler(val) {
        this.colors = { ...val };
      },
      immediate: true,
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
    extend('regex', {
      ...regex,
      message: this.$t('errors.error-color-regex'),
    });
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
