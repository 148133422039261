<template>
  <div>
    <table-list
      :dashboard="true"
      content="offers"
      :cols="columns"
      :no-data-text-key="`place-dashboard.no-offers`"
      :row-formatter="statusFormatter"
      class="hover-click text-center"
      ellipsis-only
      :actions="getActions"
      status-toggler
      :status-colors="OFFER_STATUS_COLORS"
      :status-options="getStatusOptions"
      :status-config="statusConfig"
      :mobile-cols="[1, 3]"
      :entries-counter="false"
      :query="{ brandId: id, placeId }"
      @change-status="(status, offer) => changeOfferStatus(offer.id, status)"
      @record-click="$emit('open', $event)"
    />
    <div class="text-center">
      <btn
        class="w-50 secondary"
        @click="
          $router.push({
            name: 'Offers',
          })
        "
      >
        {{ $t(`place-dashboard.all-offers`) }}
      </btn>
    </div>
  </div>
</template>

<script>
import TableList from '@/components/Table/TableList';
import Btn from '@/components/Button/Button';
import { date } from '@/util/formatters';
import { mapActions, mapGetters, mapState } from 'vuex';
import offerStatus from '@/views/Brand/Applications/mixins/offerStatus';

export default {
  name: 'TableTab',
  components: {
    TableList,
    Btn,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    placeId: {
      type: String,
      required: true,
    },
  },
  mixins: [offerStatus],
  computed: {
    ...mapState('applications', ['statusColors']),
    ...mapGetters('user', ['hasAdminRights', 'isAuthorizedToMakeChanges']),
    columns() {
      return {
        internalNumber: {
          label: '#',
          colClass: ['w-12', 'lg:w-16', 'xxl:w-24'],
        },
        createdAt: {
          label: this.$t('brand-offers.created'),
          formatter: date,
          colClass: ['w-26'],
        },
        title: {
          label: this.$t('brand-offers.title'),
        },
        report: {
          label: this.$t('brand-offers.report'),
          formatter: (value) => value.title,
        },
      };
    },
  },
  data() {
    return {
      forceUpdateKey: 0,
      listUpdatesCounter: 0,
    };
  },
  methods: {
    ...mapActions('common', ['delete']),
    ...mapActions('applications', ['editReport']),
    statusFormatter(row) {
      if (row?.creator?.internalRole) {
        return { ...row, role: row.creator.internalRole };
      }
      return row;
    },
    changeOfferStatus(id, status) {
      this.editOffer({
        data: {
          status,
        },
        id,
      }).then(() => {
        this.forceUpdateKey++;
      });
    },
    archiveOffer(id) {
      this.delete({ type: 'offers', id }).then(() => {
        this.forceUpdateKey++;
      });
    },
    getActions(row) {
      if ((!this.isContractorUser && !this.hasAdminRights) || !row) return {};

      const { status } = row;

      const approve = {
        approve: (id) => this.changeOfferStatus(id, 'approved'),
      };
      const reject = { reject: (id) => this.changeOfferStatus(id, 'rejected') };
      const archive = { archive: (id) => this.archiveOffer(id) };
      const finish = { finish: (id) => this.changeOfferStatus(id, 'finished') };
      const edit = {
        edit: (id) =>
          this.$router.push({ name: 'EditOffer', params: { offerId: id } }),
      };

      return {
        ...(this.hasAdminRights && {
          ...(status === 'active' && { ...approve, ...reject }),
          ...(status === 'approved' && finish),
        }),
        ...(this.isAuthorizedToManageOffers && {
          ...(status === 'active' && edit),
          ...archive,
        }),
      };
    },
    async onStatusUpdate(status, object) {
      await this.updateStatus(object.id, status);

      this.listUpdatesCounter++;
    },
  },
};
</script>
