const format = (val, formatter, row) => {
  return formatter ? formatter(val, row) : val;
};

const merge = (src, options) => {
  let formatted = options.format;
  options.props.forEach((el, index) => {
    formatted = formatted.replace(`$${index}`, src[el]);
  });
  return formatted;
};

export { format, merge };
