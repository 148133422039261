import { computed, defineComponent } from '@vue/composition-api';
import { useCustomForm, } from '@/views/_components/CustomForms';
export default defineComponent({
    props: {
        components: {
            type: Array,
            default: () => [],
        },
        values: {
            type: Array,
            default: () => [],
        },
        itemClassName: {
            type: String,
            default: '',
        },
        labelClassName: {
            type: String,
            default: 'label mt-4',
        },
        valueClassName: {
            type: String,
            default: '',
        },
        maxFields: Number,
        formatLabel: {
            type: Function,
            default: (label) => label,
        },
    },
    setup(props) {
        const { formFields } = useCustomForm(props.values, props.components);
        const limitedFields = computed(() => {
            return props.maxFields
                ? formFields.value.slice(0, props.maxFields)
                : formFields.value;
        });
        return { limitedFields };
    },
});
