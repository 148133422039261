import { ref } from '@vue/composition-api';
export const usePromise = (promise) => {
    const loading = ref(true);
    const result = ref(null);
    const error = ref(null);
    const fetchData = async (promise) => {
        loading.value = true;
        try {
            result.value = await promise;
        }
        catch (err) {
            error.value = err;
        }
        finally {
            loading.value = false;
        }
    };
    fetchData(promise);
    return { loading, result, error, fetchData };
};
