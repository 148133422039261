<template>
  <div class="flex items-center">
    <div class="flex-shrink-0 flex-grow-0 pl-6">
      <slot name="left"></slot>
    </div>
    <div class="text-center flex-grow flex-shrink">
      <div class="w-full flex items-center justify-center text-xxs">
        <div class="return-button">
          <router-link v-if="route" type="button" :to="route">
            <div class="flex gap-1 items-center justify-center">
              <icon :icon="mdiChevronLeft" :size="IconSize.SM" class="mt-0.5" />
              <div>{{ routeLabel.toLowerCase() }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <h1 class="header">{{ title }}</h1>
      <p class="text-xs">{{ subTitle }}</p>
      <div class="date-wrapper">
        <p v-if="createdAt" class="mx-1">
          <span class="font-semibold">{{ $t('common.created') }}:</span>
          {{ formatDate(createdAt) }}
        </p>
        <p v-if="updatedAt" class="mx-1">
          <span class="font-semibold">{{ $t('common.modified') }}:</span>
          {{ formatDate(updatedAt) }}
        </p>
      </div>
    </div>
    <div class="flex-shrink-0 flex-grow-0 pr-6">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiChevronLeft } from '@mdi/js';

export default {
  components: { Icon },
  props: {
    createdAt: {
      type: [Date, String],
      default: null,
    },
    updatedAt: {
      type: [Date, String],
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    route: {
      type: Object,
      default: null,
    },
    routeLabel: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return { IconSize, mdiChevronLeft };
  },
  methods: {
    formatDate(value) {
      const date = typeof value === 'string' ? new Date(value) : value;

      return date.toLocaleDateString(this.$i18n.locale);
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  @apply text-header font-semibold text-primary mt-2;
}

.return-button {
  @apply bg-white rounded-md border-gray-dark border py-0 relative pl-1 pr-2 whitespace-no-wrap font-bold;
  width: min-content;

  &-inner {
    @apply text-3xs leading-compact font-semibold flex items-center justify-center flex-no-wrap;
  }
}

.date-wrapper {
  @apply flex justify-center text-3xs mt-1;
}
</style>
