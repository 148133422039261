import { TextInput, SelectInput } from '@/components/Inputs';
import { SwitchInput } from '@/components/Inputs';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ADMIN, PLACE_ADMIN, STRUCTURE_ADMIN, WITHOUT_ROLE, } from '@/store/modules/user';
import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import { useBrand, useValidation } from '@/composables';
import { loadAllResults } from '@/api/requests/pagination';
import { getStructuresList } from '@/api/requests/structures';
import { getPlacesList } from '@/api/requests/places';
import Tag from '@/components/Tag/Tag.vue';
import { mapId } from '@/util/common';
import { mdiPlus } from '@mdi/js';
const createEmptyFormData = () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    internalRole: '',
});
export default defineComponent({
    name: 'UserForm',
    components: {
        TextInput,
        SelectInput,
        SwitchInput,
        ValidationProvider,
        ValidationObserver,
        Tag,
    },
    props: {
        id: String,
        isLoading: Boolean,
        user: Object,
        enforcedRole: String,
    },
    setup(props, ctx) {
        useValidation(ctx);
        const { brand } = useBrand();
        const userRoleOptions = computed(() => [
            {
                label: ctx.root.$t(`roles.${ADMIN}`).toString(),
                value: ADMIN,
            },
            {
                label: ctx.root.$t(`roles.${STRUCTURE_ADMIN}`).toString(),
                value: STRUCTURE_ADMIN,
            },
            {
                label: ctx.root.$t(`roles.${PLACE_ADMIN}`).toString(),
                value: PLACE_ADMIN,
            },
            {
                label: ctx.root.$t(`roles.${WITHOUT_ROLE}`).toString(),
                value: WITHOUT_ROLE,
            },
        ]);
        const isEditForm = computed(() => props.user !== undefined);
        const formData = ref(createEmptyFormData());
        const role = ref(null);
        const isActive = ref(false);
        const managedStructures = ref([]);
        const managedPlaces = ref([]);
        const selectedStructures = ref([]);
        const selectedPlaces = ref([]);
        watch(() => props.user, async () => {
            if (props.enforcedRole) {
                role.value = {
                    label: ctx.root.$t(`roles.${props.enforcedRole}`).toString(),
                    value: props.enforcedRole,
                };
            }
            if (!props.user) {
                return;
            }
            formData.value.firstName = props.user.firstName;
            formData.value.lastName = props.user.lastName;
            formData.value.email = props.user.email;
            formData.value.phone = props.user.phone || '';
            formData.value.internalRole = props.user.internalRole || '';
            role.value = {
                label: ctx.root.$t(`roles.${props.user.role}`).toString(),
                value: props.user.role,
            };
            isActive.value = props.user.status === 'registered';
            if (props.user?.role === STRUCTURE_ADMIN) {
                // TODO add proper typing for getStructuresList as PaginatedStructureDto
                const structures = await loadAllResults(getStructuresList, {
                    managedByUserId: props.user.id,
                });
                managedStructures.value = structures.map((structure) => ({
                    item: structure,
                    isDeleted: false,
                }));
            }
            else if (props.user?.role === PLACE_ADMIN) {
                const places = await loadAllResults(getPlacesList, {
                    managedByUserId: props.user.id,
                });
                managedPlaces.value = places.map((place) => ({
                    item: place,
                    isDeleted: false,
                }));
            }
        }, { deep: true, immediate: true });
        const pushFormData = () => {
            let managedResourceIds = [];
            if (role.value.value === STRUCTURE_ADMIN) {
                const undeletedStructures = managedStructures.value
                    .filter(({ isDeleted }) => !isDeleted)
                    .map(({ item }) => item);
                managedResourceIds = [
                    ...mapId(selectedStructures.value),
                    ...mapId(undeletedStructures),
                ];
            }
            else if (role.value.value === PLACE_ADMIN) {
                const undeletedPlaces = managedPlaces.value
                    .filter(({ isDeleted }) => !isDeleted)
                    .map(({ item }) => item);
                managedResourceIds = [
                    ...mapId(selectedPlaces.value),
                    ...mapId(undeletedPlaces),
                ];
            }
            ctx.emit('submit', {
                ...formData.value,
                role: role.value.value,
                managedResourceIds,
                ...(isEditForm.value && {
                    status: isActive.value ? 'registered' : 'inactive',
                }),
            });
        };
        const handleDelete = (element) => {
            element.isDeleted = !element.isDeleted;
        };
        return {
            mdiPlus,
            STRUCTURE_ADMIN,
            PLACE_ADMIN,
            brand,
            userRoleOptions,
            formData,
            role,
            isActive,
            pushFormData,
            managedStructures,
            managedPlaces,
            selectedStructures,
            selectedPlaces,
            handleDelete,
            isEditForm,
        };
    },
});
