import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import { ref } from '@vue/composition-api';
export default defineComponent({
    props: {
        items: {
            type: Array,
            required: true,
        },
        show: Boolean,
    },
    setup(props, ctx) {
        const file = ref([]);
        const onFileClick = () => {
            if (!file.value) {
                return;
            }
            file.value[0].value = '';
            file.value[0].click();
            ctx.emit('close');
        };
        return { file, onFileClick };
    },
});
