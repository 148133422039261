import { DEFAULT_LIMIT } from '@/constants/pagination';
import { computed, defineComponent } from '@vue/composition-api';
import { useBrand, useExpandDetails, useMobileView } from '@/composables';
import PlacePreview from '@/views/Brand/Places/PlacePreview.vue';
import { ExpandButtonStyle } from '@/components/Table/TableGrid/table.constants';
import { Checkbox } from '@/components/Inputs';
import { GenericTable } from '@/components/Table/__new__/_components/TableGeneric.vue';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton.vue';
import { TableStyle } from '@/components/Table/__new__';
import { usePlaceResourcesStore } from '@/store/place-resources';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';
import { genericTableRowSize } from '@/components/Table/__new__/_components/TableCellGeneric';
export default defineComponent({
    components: {
        PlacePreview,
        Checkbox,
        TableDisplay: GenericTable(),
        ExpandButton,
        InfiniteData,
    },
    props: {
        reportId: {
            type: String,
        },
        query: {
            type: Object,
            default: () => ({}),
        },
        containerSelector: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { isMobileView } = useMobileView();
        const { isPlaceSelected, onPlaceSelection } = usePlaceResourcesStore();
        const onSelectPlace = (place) => onPlaceSelection([place.id], [place]);
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'number',
                    label: ctx.root.$t('brand-places-list-view.number').toString(),
                    cellClass: 'font-bold pl-0',
                },
                {
                    key: 'name',
                    label: ctx.root.$t('brand-places-list-view.name').toString(),
                    cellClass: 'font-bold',
                    gridColumn: '1fr',
                },
                {
                    key: 'expandButton',
                },
            ];
        });
        const placeQuery = computed(() => ({
            brandId: brand.value?.id,
            ...props.query,
            ...(props.reportId && { relatedReportId: props.reportId }),
            limit: DEFAULT_LIMIT,
        }));
        const { isExpanded: isPlaceDetailsExpanded, toggleExpandedItems: toggleExpandPlaceDetails, } = useExpandDetails((place) => place.id);
        const getPlaceRowSize = () => {
            return isMobileView.value
                ? genericTableRowSize.SMALL
                : genericTableRowSize.MEDIUM;
        };
        return {
            placeQuery,
            columns,
            isPlaceDetailsExpanded,
            toggleExpandPlaceDetails,
            ExpandButtonStyle,
            isPlaceSelected,
            onSelectPlace,
            TableStyle,
            getPlaceRowSize,
        };
    },
});
