import { computed, defineComponent } from '@vue/composition-api';
import { getFullName } from '@/util/user';
import { isReportMessageUnread } from '@/views/Brand/Applications/Report/_components/ReportTable/_utils';
export default defineComponent({
    props: {
        report: {
            type: Object,
            required: true,
        },
        showMessageInNewLine: Boolean,
    },
    setup(props) {
        const messageText = computed(() => {
            const { firstMessage } = props.report;
            let formattedDescription = firstMessage?.message.replace(/<.*?>/g, '') || '';
            firstMessage?.users.forEach((user) => {
                formattedDescription = formattedDescription.replaceAll(`@${user.id}`, `@${getFullName(user)}`);
            });
            return formattedDescription;
        });
        return {
            isReportMessageUnread,
            messageText,
        };
    },
});
