import { defineComponent, computed } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import { PreviewWrapper } from '@/views/Brand/Applications/Components/Preview/PreviewWrapper';
import OfferSvg from '@/assets/icons/offer.svg';
export default defineComponent({
    components: { PreviewWrapper, Icon },
    props: {
        offer: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const offerTitle = computed(() => `${ctx.root.$t('report.offer')} - ${props.offer.title}`);
        const offerCaption = computed(() => {
            const date = new Date(props.offer.createdAt);
            return `${props.offer.internalNumber}, ${date.toLocaleDateString(ctx.root.$i18n.locale)}`;
        });
        const openOfferTab = () => {
            if (props.offer) {
                const routeData = ctx.root.$router.resolve({
                    name: 'OfferDetails',
                    params: { offerId: props.offer.id },
                });
                window.open(routeData.href, '_blank');
            }
        };
        return { OfferSvg, offerTitle, offerCaption, openOfferTab };
    },
});
