var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.hexColorRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('color-input',{staticClass:"mt-6",attrs:{"id":"color-input-1","label":_vm.$t('theme-view.primary-label'),"placeholder":_vm.$t('theme-view.color-placeholder'),"is-error":errors[0],"disabled":_vm.disabled,"required":""},on:{"input":function($event){return _vm.$emit('update-theme', _vm.colors)}},model:{value:(_vm.colors.primary),callback:function ($$v) {_vm.$set(_vm.colors, "primary", $$v)},expression:"colors.primary"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.hexColorRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('color-input',{staticClass:"mt-6",attrs:{"id":"color-input-2","label":_vm.$t('theme-view.primary-light-label'),"placeholder":_vm.$t('theme-view.color-placeholder'),"required":"","is-error":errors[0],"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('update-theme', _vm.colors)}},model:{value:(_vm.colors.primaryLight),callback:function ($$v) {_vm.$set(_vm.colors, "primaryLight", $$v)},expression:"colors.primaryLight"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.hexColorRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('color-input',{staticClass:"mt-6",attrs:{"id":"color-input-3","label":_vm.$t('theme-view.primary-hover-label'),"placeholder":_vm.$t('theme-view.color-placeholder'),"required":"","is-error":errors[0],"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('update-theme', _vm.colors)}},model:{value:(_vm.colors.primaryHover),callback:function ($$v) {_vm.$set(_vm.colors, "primaryHover", $$v)},expression:"colors.primaryHover"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.hexColorRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('color-input',{staticClass:"mt-6",attrs:{"id":"color-input-4","label":_vm.$t('theme-view.links-label'),"placeholder":_vm.$t('theme-view.color-placeholder'),"required":"","is-error":errors[0],"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('update-theme', _vm.colors)}},model:{value:(_vm.colors.links),callback:function ($$v) {_vm.$set(_vm.colors, "links", $$v)},expression:"colors.links"}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }