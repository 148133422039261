import axios from 'axios';
import store from '../store';
import router from '@/router';
import VueInstance from '@/main';
import { v4 as uuidv4 } from 'uuid';
import { METHOD, RESPONSE_MESSAGE_KEY } from '@/constants/api';
import {
  LOCAL_STORAGE_KEY,
  useLocalStorage,
} from '@/composables/use-local-storage';
import { upperCaseToKebabCase } from '@/util/text';

const getBrowserClientId = () => {
  const { storedValue, setStoredValue } = useLocalStorage(
    LOCAL_STORAGE_KEY.CLIENT_ID,
  );
  let clientId = storedValue.value;

  if (!clientId) {
    clientId = uuidv4();

    setStoredValue(clientId);
  }

  return clientId;
};

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const { storedValue } = useLocalStorage(LOCAL_STORAGE_KEY.OBJEKTO);
  const storedUser = storedValue.value?.user;
  const storedViewer = storedUser.viewer;
  const blockAccess =
    storedViewer &&
    config.method !== METHOD.GET &&
    !config.url.includes('login');

  config.headers['request-id'] = uuidv4();
  config.headers['client-id'] = getBrowserClientId();
  config.headers['Authorization'] = storedUser.token;

  if (storedViewer) {
    config.headers['viewer-id'] = storedViewer.id;
  }

  if (blockAccess) {
    VueInstance.$toast.info(VueInstance.$t('api.preview-mode-info'));
    return;
  }

  return config;
});

api.interceptors.response.use(
  (res) => res,
  (err) => {
    let errMsg;

    if (err.message === RESPONSE_MESSAGE_KEY.ABORT) {
      return Promise.reject(err);
    }

    const { status, data, config } = err.response;
    const requestId = config.headers['request-id'];

    if ([403, 401].includes(status)) {
      if (!store.getters['user/isLogged']) {
        return Promise.reject(err);
      }

      store.commit('user/SET_VIEWER', null);
      store.commit('user/CLEAR_AUTH');
      router.push({ name: 'Login' });
      errMsg = VueInstance.$t('api.auth-error', { requestId });
    } else {
      errMsg = VueInstance.$t('api.general-error', { requestId });
      const { error, message, info } = data;

      if (error === 'USER_EMAIL_NOT_FOUND') {
        errMsg = VueInstance.$t(`api.${upperCaseToKebabCase(error)}`, info);
      } else {
        switch (status) {
          case 400:
            errMsg = `${VueInstance.$t('api.bad-parameters-error', {
              requestId,
            })}: ${message}`;
            break;
          case 404:
            errMsg = VueInstance.$t('api.not-found', { requestId });
            break;
          case 409:
            errMsg = `${VueInstance.$t('api.conflict-error', {
              requestId,
            })}: ${message}`;
            break;
          case 422:
            errMsg = `${VueInstance.$t('api.unprocessable-entity', {
              requestId,
            })}: ${message}`;
        }
      }
    }
    VueInstance.$toast.error(errMsg);
    return Promise.reject(err);
  },
);

export const apiUrl = process.env.VUE_APP_API_URL;

export default api;
