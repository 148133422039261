export const TABLE_STYLE = {
  FLAT: 'flat',
  SEPARATED: 'separated',
};

// TODO: remove and use enum

export const EXPAND_BUTTON_POSITION = {
  START: 'start',
  END: 'end',
};

export const EXPAND_BUTTON_STYLE = {
  ARROW: 'arrow',
};

export const OPTION_HEIGHT = 44;
