export default {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_AUTHORITY,
    redirectUri: `${process.env.VUE_APP_AMREST_URL}/login`,
    replyUri: `${process.env.VUE_APP_AMREST_URL}/login`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};
