<template>
  <div :class="gridDisplay ? 'grid-upload-list' : 'my-4'">
    <template v-if="!isEmpty(sortedUploadListWithThumbnails)">
      <div
        v-for="(files, key) in sortedUploadListWithThumbnails"
        :key="`date-${key}`"
      >
        <h3 v-if="!gridDisplay" class="font-bold w-full text-2xs">{{ key }}</h3>
        <div class="flex flex-wrap mb-4 -mx-2" :class="{ 'pr-2': gridDisplay }">
          <upload-item
            v-for="file in files.withThumbs"
            :id="file.id"
            :key="`file-${file.id}`"
            :date="date(file.createdAt)"
            :old-file="file.updatedAt"
            :url="file.url"
            :type="file.type"
            :read-only="readOnly"
            :instant-deletion="!useDeletionQueue"
          >
            {{ file.name }}
          </upload-item>
          <div
            v-for="(group, index) in groupIn3(files.normal)"
            :key="`${key}-group-${index}`"
          >
            <upload-item
              v-for="file in group"
              :id="file.id"
              :key="`file-${file.id}`"
              :date="date(file.createdAt)"
              :old-file="file.updatedAt"
              :url="file.url"
              :type="file.type"
              :read-only="readOnly"
              :instant-deletion="!useDeletionQueue"
            >
              {{ file.name }}
            </upload-item>
          </div>
        </div>
      </div>
    </template>
    <span v-else>-</span>
  </div>
</template>

<script>
import UploadItem from '@/components/Uploader/UploadItem.vue';
import { date } from '@/util/formatters';
import { extension } from '@/util/formatters';
import isEmpty from 'lodash.isempty';
import { isMultimediaFile } from '@/util/files';

export default {
  components: {
    UploadItem,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'document',
    },
    gridDisplay: {
      type: Boolean,
      default: false,
    },
    useDeletionQueue: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sortedUploadListWithThumbnails() {
      const sorted = {};
      this.list.forEach((obj) => {
        if (obj.type === this.type) {
          const creationDate = date(obj.createdAt);
          if (!sorted[creationDate]) {
            this.$set(sorted, creationDate, { normal: [], withThumbs: [] });
          }
          if (isMultimediaFile(extension(obj.url)))
            sorted[creationDate].withThumbs.push(obj);
          else sorted[creationDate].normal.push(obj);
        }
      });
      return sorted;
    },
  },
  methods: {
    date,
    isEmpty,
    groupIn3(list) {
      let arr = [],
        listCopy = [...list];
      while (listCopy.length !== 0) arr.push(listCopy.splice(0, 3));
      return arr;
    },
  },
};
</script>
<style>
.grid-upload-list {
  @apply flex flex-wrap;
}
</style>
