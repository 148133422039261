import { Btn, BtnTheme } from '@/components/Btn';
import { computed, defineComponent } from '@vue/composition-api';
import { useAuthUser, useMobileView } from '@/composables';
import { ReportStatus, } from '@/api/requests/reports';
import { useActionRequired } from '@/views/Brand/Applications/Report/_composables/use-action-required';
export default defineComponent({
    components: { Btn },
    props: {
        report: Object,
        selectedOffer: Object,
        messages: Array,
        hasRequiredActionMessage: Boolean,
    },
    setup() {
        const { isMobileView } = useMobileView();
        const { isAdmin, isContractor, isObjektoAdmin } = useAuthUser();
        const { isAuthorizedToCreateActionRequired } = useActionRequired();
        const isAdminOrContractor = computed(() => isAdmin.value || isContractor.value);
        const canAddOffer = computed(() => isContractor.value || isObjektoAdmin.value);
        return {
            canAddOffer,
            isMobileView,
            isAdminOrContractor,
            ReportStatus,
            BtnTheme,
            isAuthorizedToCreateActionRequired,
        };
    },
});
