<template>
  <settings-content-wrapper :query="routeQuery" @select-limit="selectLimit">
    <div>
      <div class="flex flex-wrap items-center justify-end gap-2">
        <btn
          class="w-26"
          @click="
            $router.push({ name: 'AddOneForm', params: { type: formType } })
          "
        >
          {{ $t('common.add') }}
        </btn>
      </div>
      <tabs class="mt-4" @select-tab="selectActiveTab">
        <tab
          v-for="(type, index) in formTypes"
          :key="index"
          :name="$t(`brand-forms-list-view.${type}`)"
          :selected="index === tabs.forms"
        >
          <table-list
            v-if="brand && tabs.forms === index"
            :content="`brands/${brand.id}/forms`"
            :query="{ type }"
            :force-update="componentKey"
            :loading="deletionInProgress"
            :cols="formCols"
            :actions="actions"
            class="hover-click start-left"
            ellipsis-only
            route-component="Forms"
            checkbox
            :selected-ids="selectedForms"
            @select="selectedForms = $event"
          ></table-list>
        </tab>
      </tabs>
    </div>
  </settings-content-wrapper>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
// import FormsSidebar from '@/views/Brand/Settings/Forms/Sidebar.vue';
import TableList from '@/components/Table/TableList.vue';
import Tab from '@/components/Tabs/Tab';
import Tabs from '@/components/Tabs/Tabs';
import { mapActions, mapState } from 'vuex';
import { date, uppercase, label } from '@/util/formatters';
import { FORM_TYPE } from '@/constants/form';
import routeQuery from '@/mixins/query/routeQuery';
import SettingsContentWrapper from '@/layouts/BrandLayout/Components/SettingsContentWrapper.vue';

export default {
  name: 'Forms',
  components: {
    SettingsContentWrapper,
    Btn,
    TableList,
    // FormsSidebar,
    Tabs,
    Tab,
  },
  mixins: [routeQuery],
  data() {
    return {
      search: '',
      formType: null,
      componentKey: 0,
      formTypes: [FORM_TYPE.REPORT, FORM_TYPE.OBJECT],
      deletionInProgress: false,
      selectedForms: [],
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      error: 'error',
      currentForm: 'currentForm',
    }),
    ...mapState('ui', {
      tabs: 'tabs',
    }),
    formCols() {
      return {
        createdAt: {
          label: this.$t('categories-view.createdAt'),
          formatter: date,
          colClass: ['w-36'],
        },
        title: {
          label: this.$t('categories-view.title'),
          colClass: ['w-auto'],
        },
        ...(this.formType === FORM_TYPE.OBJECT && {
          objectType: {
            label: this.$t('forms.object-type'),
            formatter: (objectType) => objectType?.name || '-',
          },
        }),
      };
    },
    actions() {
      return {
        edit: this.onEditBtnClick,
        ...(this.formType === FORM_TYPE.REPORT && {
          duplicate: this.onDuplicateBtnClick,
        }),
        archive: this.onArchiveBtnClick,
      };
    },
  },
  mounted() {
    this.formType = this.formTypes[this.tabs.forms];
  },
  methods: {
    ...mapActions('ui', ['setTab']),
    ...mapActions('common', ['delete']),
    ...mapActions('brand', ['fetchForm', 'addForm']),
    forceRerender() {
      this.componentKey += 1;
    },
    selectActiveTab(index) {
      this.formType = this.formTypes[index];
      this.setTab({ index, name: 'forms' });
    },
    onEditBtnClick(id) {
      this.$router.push({
        name: 'EditOneForm',
        params: { type: this.formType, formId: id },
      });
    },
    async onDuplicateBtnClick(id) {
      const brandId = this.$route.params.id || this.brand.id;
      await this.fetchForm(id);
      await this.addForm({
        data: {
          components: this.currentForm.components,
          title: this.currentForm.title,
          ...(this.currentForm.decisionMakers.length && {
            decisionMakersIds: this.currentForm.decisionMakers.map((item) => {
              return item.id;
            }),
          }),
          brandId,
          type: this.currentForm.type,
          ...(this.currentForm.objectTypeId && {
            objectTypeId: this.currentForm.objectTypeId,
          }),
        },
      });
      this.forceRerender();
    },
    async onArchiveBtnClick(id) {
      this.deletionInProgress = true;
      try {
        await this.delete({ type: 'forms', id });
        this.$toast.success(
          this.$t('brand-forms-list-view.removed-successfully'),
        );
        this.forceRerender();
      } finally {
        this.deletionInProgress = false;
      }
    },
    date,
    uppercase,
    label,
  },
};
</script>
<style lang="scss">
.start-left {
  table tr td:nth-child(2) {
    width: 70%;
  }
}
</style>
