<template>
  <div class="expandable-elements">
    <field-with-label
      :id="`input-${id}`"
      :label="label"
      :value="value"
      :with-input="!isExpanded"
      :with-search="isExpanded && withSearch"
      :with-arrow="!fixedExpanded"
      :rotate-arrow="expand"
      disabled
      :is-error="isError"
      :class="{ 'h-full': expand || fixedExpanded }"
      @input="$emit('input', $event)"
      @arrow-click="expand = !expand"
      @search="$emit('search', $event)"
    >
      <div v-show="expand || fixedExpanded" class="expanded-content">
        <slot></slot>
      </div>
    </field-with-label>
  </div>
</template>
<script>
import FieldWithLabel from '@/components/Expandable/components/FieldWithLabel';
export default {
  components: { FieldWithLabel },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    fixedExpanded: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isError: {
      type: [Boolean, String],
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  computed: {
    isExpanded() {
      return this.expand || this.fixedExpanded;
    },
  },
};
</script>
<style scoped>
.expanded-content {
  @apply pb-2 pl-4 pr-0 h-full;
}
</style>
