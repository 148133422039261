<template>
  <div>
    <div
      v-for="(formElement, i) in components"
      :key="`form-element-${i}`"
      class="mb-6"
    >
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ required: formElement.isRequired }"
      >
        <custom-form-element
          :id="`form-element-${i}`"
          v-model="values[i]"
          :config="formElement"
          :is-error="errors[0]"
          :disabled="isLoading"
          @input="emitValue"
        />
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { CustomFormElement } from '@/views/_components/CustomForms';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { getObjectType } from '@/api/requests/object-types';
import { useValidation } from '@/composables';

export default {
  components: {
    CustomFormElement,
    ValidationProvider,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    enableObjectTypeUpdate: {
      type: Boolean,
      default: false,
    },
    objectType: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formVersion: null,
      formId: null,
      values: [],
      components: [],
      initialFormValuesApplied: false,
    };
  },
  computed: {
    updatedObjectType() {
      if (!this.content?.objectType || !this.objectType) return false;

      return (
        this.enableObjectTypeUpdate &&
        this.content.objectType.id !== this.objectType?.id
      );
    },
  },
  watch: {
    clear(value) {
      if (value) {
        this.values = this.components.map(this.getDefaultValue);
      }
    },
    objectType: {
      async handler(value, previousValue) {
        const hasObjectTypeChanged = value.id !== previousValue?.id;

        if (!value) {
          return;
        }

        const objectType = await getObjectType(value.id);

        if (!objectType.form) {
          return (this.components = []);
        }

        if (hasObjectTypeChanged) {
          await this.setDefaultFormValues();
        }

        if (!this.initialFormValuesApplied && this.content?.objectType) {
          this.initialFormValuesApplied = true;
          return;
        }

        await this.setDefaultFormValues();
        this.initialFormValuesApplied = true;
      },
    },
  },
  async created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });

    if (
      this.content?.formVersion?.components?.length &&
      !this.updatedObjectType
    ) {
      this.setFormValues();
    } else if (this.objectType?.form) {
      await this.setDefaultFormValues(this.objectType.form.id);
    }
    this.emitValue();
  },
  methods: {
    ...mapActions('object', ['fetchForm', 'fetchObjectTypeForm']),
    getDefaultValue({ type, items }) {
      return type === 'checkbox_list'
        ? JSON.stringify(items.map(() => false))
        : '';
    },
    emitValue() {
      this.$emit('get-form-values', {
        ...(this.formVersion && {
          formVersion: this.formVersion,
          formId: this.formId,
        }),
        values: this.values,
      });
    },
    setFormValues() {
      this.components = this.content.formVersion.components;
      this.values = this.content.values;
    },
    async getForm() {
      if (this.objectType.form) {
        return this.fetchForm(this.objectType.form.id);
      }

      return this.fetchObjectTypeForm(this.objectType.id);
    },
    async setDefaultFormValues() {
      const { id, version, components } = await this.getForm();
      this.components = components;
      this.values = components.map(this.getDefaultValue);
      this.formId = id;
      this.formVersion = version;
    },
  },
  setup(_, ctx) {
    useValidation(ctx);
  },
};
</script>
