import { useConfirmModal } from '@/composables';
import { computed, ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables/use-auth-user';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { UserRole } from '@/api/requests/users';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
const { useActions: useCommonActions } = createNamespacedHelpers('common');
export const usePlaceActions = (ctx) => {
    const listUpdatesCounter = ref(0);
    const deletionInProgress = ref(false);
    const { isAdmin, hasRoleAccessOrHigher } = useAuthUser();
    const structureAdminOrHigherAccess = computed(() => hasRoleAccessOrHigher.value(UserRole.STRUCTURE_ADMIN));
    const onEditBtnClick = (id) => {
        ctx.root.$router.push({ name: 'EditPlace', params: { placeId: id } });
    };
    const { delete: deletePlace } = useCommonActions(['delete']);
    const onRemoveBtnClick = async (id) => {
        deletionInProgress.value = true;
        try {
            await deletePlace({ type: 'places', id });
            listUpdatesCounter.value++;
            ctx.root.$toast.success(ctx.root.$t('brand-places-list-view.removed-successfully'));
        }
        finally {
            deletionInProgress.value = false;
        }
    };
    const { getConfirmModalObject } = useConfirmModal(ctx);
    const getPlaceActions = (place) => {
        if (!structureAdminOrHigherAccess.value)
            return [];
        const placeActions = [
            {
                name: 'edit',
                handler: () => onEditBtnClick(place.id),
                icon: EditSvg,
            },
        ];
        if (isAdmin.value)
            placeActions.push({
                name: 'delete',
                handler: () => onRemoveBtnClick(place.id),
                modal: getConfirmModalObject('place', place.name),
                icon: DeleteSvg,
            });
        return placeActions;
    };
    return {
        getPlaceActions,
        listUpdatesCounter,
    };
};
