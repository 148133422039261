import { defineComponent, ref } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import ArrowSvg from '@/assets/icons/arrow-down.svg';
export default defineComponent({
    components: {
        Icon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    setup() {
        const isOpen = ref(false);
        return {
            isOpen,
            ArrowSvg,
            IconSize,
        };
    },
});
