import api from '@/api';

export default {
  namespaced: true,
  state: {
    rootStructure: null,
    error: null,
    structureList: [],
    isLoading: false,
    deletedStructures: [],
  },
  mutations: {
    SET_ROOT_STRUCTURE(state, { data }) {
      state.rootStructure = data?.results[0];
    },
    CLEAR_STATE(state) {
      state.rootStructure = null;
      state.error = null;
      state.structureList = [];
      state.isLoading = false;
      state.deletedStructures = [];
    },
    SET_ERROR(state, err) {
      state.error = err;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    CLEAR_STRUCTURE_LIST(state) {
      state.structureList = [];
    },
    UPDATE_STRUCTURE_LIST(state, { index, data }) {
      state.structureList[index] = data;
    },
    SAVE_TO_STRUCTURE_LIST(state, structure) {
      state.structureList.push(structure);
    },
    REMOVE_FROM_STRUCTURE_LIST(state, id) {
      state.structureList = state.structureList.filter(
        (structure) => structure.id !== id,
      );
    },
    DELETE_STRUCTURE(state, id) {
      state.deletedStructures.push(id);
    },
  },
  getters: {
    unsavedChanges: (state) =>
      state.structureList.length || state.deletedStructures.length,
  },
  actions: {
    async fetchRootStructure({ commit }, brandId) {
      commit('SET_LOADING', true);
      commit('SET_ROOT_STRUCTURE', { data: null });

      try {
        const params = new URLSearchParams();
        params.append('parentId', null);
        params.append('brandId', brandId);

        const { data } = await api.get(`/structures`, { params });

        commit('SET_ROOT_STRUCTURE', { data });
        commit('SET_LOADING', false);

        return data;
      } catch (err) {
        commit('SET_LOADING', false);
        commit('SET_ERROR', err);
      }
    },
    fetchChildStructures(ctx, { brandId, params }) {
      return api.get(`/structures`, { params: { brandId, ...params } });
    },
    saveChanges({ dispatch }) {
      return Promise.all([
        dispatch('deleteStructures'),
        dispatch('publishStructures'),
      ]);
    },
    publishStructures({ state, commit }) {
      if (!state.structureList.length) {
        return Promise.resolve();
      }

      const ids = state.structureList.map((structure) => structure.id);

      return api.put(`/structures/publish`, { ids }).then((response) => {
        commit('CLEAR_STRUCTURE_LIST');

        return response;
      });
    },
    async deleteStructures({ state }) {
      if (!state.deletedStructures.length) {
        return Promise.resolve();
      }

      for (const id of state.deletedStructures) {
        await api.delete(`/structures/${id}`);
      }
    },
    clearState({ commit }) {
      commit('CLEAR_STATE');
    },
    saveToStructureList({ commit }, id) {
      commit('SAVE_TO_STRUCTURE_LIST', id);
    },
    addStructure(ctx, params) {
      return api.post(`/structures/${params.id}`, params.data);
    },
    async editStructure({ state, commit }, { id, data }) {
      const unpublishedIndex = state.structureList.findIndex(
        (structure) => structure.id === id,
      );

      const response = await api.patch(`/structures/${id}`, data);

      if (unpublishedIndex !== -1) {
        commit('UPDATE_STRUCTURE_LIST', {
          index: unpublishedIndex,
          data: response.data,
        });
      }
    },
    async updateStructureUser(ctx, { id, userId }) {
      return await api.patch(`/structures/${id}`, { userId: userId });
    },
    deleteStructure({ commit }, id) {
      commit('REMOVE_FROM_STRUCTURE_LIST', id);
      commit('DELETE_STRUCTURE', id);
    },
    updatePlace(ctx, params) {
      return api.patch(`/places/${params.id}`, params.data);
    },
    fetchStructurePlaces(ctx, { brandId, structureId }) {
      return api.get('places', { params: { brandId, structureId } });
    },
  },
};
