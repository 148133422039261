import { SORT_TYPE } from '@/constants/filters';

export default {
  data() {
    return {
      sortByQuery: null,
    };
  },
  computed: {
    tableSorting() {
      if (!this.sortByQuery) return null;

      const type = this.sortByQuery.slice(0, 1);
      const column = this.sortByQuery.slice(1);

      return {
        column: column,
        type: SORT_TYPE[type],
      };
    },
  },
  methods: {
    desc(val) {
      return `-${val}`;
    },
    asc(val) {
      return `+${val}`;
    },
    changeSorting({ col, type }) {
      switch (type) {
        case SORT_TYPE.ASC:
          this.sortByQuery = this.asc(col);
          break;
        case SORT_TYPE.DESC:
          this.sortByQuery = this.desc(col);
          break;
        default:
          this.sortByQuery = null;
      }
    },
  },
};
