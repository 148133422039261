import { computed, onMounted } from '@vue/composition-api';
import { DEFAULT_CARD_LIMIT, ROUTE_CARD_LIMIT } from '@/constants/pagination';
import { useRouteQuery } from '@/composables/use-route-query';
export const useCardPagination = (ctx) => {
    const { setRoutePagination } = useRouteQuery(ctx);
    const limit = computed(() => {
        if (ctx.root.$route.name) {
            return ROUTE_CARD_LIMIT[ctx.root.$route.name] || DEFAULT_CARD_LIMIT;
        }
        return DEFAULT_CARD_LIMIT;
    });
    onMounted(() => {
        setRoutePagination({
            route: ctx.root.$route,
            pagination: { limit: limit.value },
        });
    });
    const changePage = (page) => {
        setRoutePagination({ route: ctx.root.$route, pagination: { page } });
    };
    return { changePage };
};
