<template>
  <expandable-wrapper
    :id="id"
    :fixed-expanded="fixedExpanded"
    :value="value"
    :label="label"
    :is-error="isError"
    :with-search="withSearch"
    @search="search = $event"
  >
    <Scrollbar :id="`container-${id}`" class="scrollable-container">
      <items-list
        v-if="addedItems.length"
        :items="computedAddedItems"
        :fields="fields"
        :read-only="readOnly"
        :class="{ 'border-b-2 mb-2': computedAddedItems.length && !!url }"
        :no-data-text="
          isSearchActive && [...addedItems, ...fetchedItems].length > 0
        "
        @remove="$emit('remove', $event)"
      />
      <infinite-data
        v-if="url"
        :url="url"
        :query="computedQuery"
        enable-query-watcher
        :container-selector="`#container-${id}`"
        @data-deliver="onDataDeliver"
      >
        <template #default="{ items }">
          <items-list
            :items="items.map(formatter)"
            :fields="fields"
            :deleted-item-ids="deletedItemIds"
            :read-only="readOnly"
            @remove="$emit('remove', $event)"
            @undo-remove="$emit('undo-remove', $event)"
          />
        </template>
      </infinite-data>
    </Scrollbar>
  </expandable-wrapper>
</template>
<script>
import ExpandableWrapper from '@/components/Expandable/components/ExpandableWrapper';
import ItemsList from '@/components/Expandable/components/ItemsList';
import InfiniteData from '@/components/DataProvider/InfiniteData';
import Scrollbar from '@/components/Scrollbar/Scrollbar';
export default {
  components: { Scrollbar, InfiniteData, ItemsList, ExpandableWrapper },
  props: {
    url: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    fixedExpanded: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    deletedItemIds: {
      type: Array,
      default: () => [],
    },
    addedItems: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: [Boolean, String],
      default: false,
    },
    valueField: {
      type: String,
      required: true,
    },
    formatter: {
      type: Function,
      default: (el) => el,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    searchFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fetchedItems: [],
      search: '',
    };
  },
  watch: {
    deletedItemIds() {
      this.updateValue();
    },
    addedItems() {
      this.updateValue();
    },
  },
  computed: {
    computedValue() {
      return [...this.addedItems, ...this.fetchedItems]
        .filter(({ id }) => !this.deletedItemIds.includes(id))
        .map((item) => item[this.valueField])
        .join(', ');
    },
    isSearchActive() {
      return this.withSearch && !!this.search;
    },
    computedQuery() {
      return {
        ...this.query,
        ...(this.isSearchActive && { q: this.search }),
      };
    },
    computedAddedItems() {
      return this.addedItems.filter(this.applySearch);
    },
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    updateValue() {
      this.$emit('input', this.computedValue);
    },
    onDataDeliver(items) {
      this.fetchedItems = items.map(this.formatter);

      this.updateValue();
    },
    getComparableValue(value) {
      return `${value}`.toLowerCase();
    },
    applySearch(item) {
      if (!this.isSearchActive) return item;

      return (
        this.searchFields
          .map((field) => item[field])
          .filter((value) => {
            return (
              this.getComparableValue(value).indexOf(
                this.getComparableValue(this.search),
              ) !== -1
            );
          }).length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable-container {
  @apply mt-2;
  height: calc(100% - 3rem);
}
</style>
