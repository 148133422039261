export var CheckboxTheme;
(function (CheckboxTheme) {
    CheckboxTheme["PRIMARY"] = "primary";
    CheckboxTheme["SECONDARY"] = "secondary";
    CheckboxTheme["NONE"] = "none";
})(CheckboxTheme || (CheckboxTheme = {}));
export const checkboxProps = {
    value: Boolean,
    default: Boolean,
    disabled: {
        type: Boolean,
        default: false,
    },
    indeterminate: Boolean,
    name: String,
    error: Boolean,
    size: {
        type: String,
        default: 'md',
    },
    table: Boolean,
    tableHeader: Boolean,
    theme: {
        type: String,
        default: CheckboxTheme.PRIMARY,
    },
    loading: Boolean,
    withoutSpacing: Boolean,
};
