import { ref } from '@vue/composition-api';
import axios from 'axios';
import { RESPONSE_MESSAGE_KEY } from '@/constants/api';
export const useAbort = () => {
    const sources = ref(new Map());
    const addSource = (sourceName) => sources.value.set(sourceName, axios.CancelToken.source());
    const abort = (sourceName) => {
        sources.value.get(sourceName)?.cancel(RESPONSE_MESSAGE_KEY.ABORT);
        sources.value.delete(sourceName);
    };
    const getSourceCancelToken = (sourceName) => sources.value.get(sourceName)?.token;
    return {
        abort,
        addSource,
        getSourceCancelToken,
    };
};
