import { mapState, mapGetters, mapActions } from 'vuex';
import { OBJECT_TAB, PLACE_TAB, BTN_THEMES } from '@/store/modules/ui';
import VueStore from '@/store';

export default {
  beforeRouteLeave(to, from, next) {
    VueStore.dispatch('offerResources/clearOfferState');
    VueStore.dispatch('applications/clearState');
    next();
  },
  data() {
    return {
      submissionInProgress: false,
      submissionEndSuccessfully: false,
      redirectAfterSubmission: false,
      objectsAddedInOffer: [],
      BTN_THEMES,
      forceUpdateSelection: 0,
    };
  },
  computed: {
    ...mapState('ui', {
      showSidebar: (state) => state.sidebar.isOpen,
      mobileView: (state) => state.mobileView.active,
    }),
    ...mapState('attachment', ['uploadQueue']),
    ...mapGetters('attachment', ['uploads']),
    ...mapGetters('user', ['isAuthorizedToMakeChanges']),
    ...mapGetters('offerResources', ['selectedObjectIds', 'selectedPlaceIds']),
    sidebarTabs() {
      const tabs = [
        { name: PLACE_TAB, label: this.$t('add-brand-offer-view.places') },
      ];

      if (this.showObjectsTab)
        tabs.unshift({
          name: OBJECT_TAB,
          label: this.$t('add-brand-offer-view.objects'),
        });

      return tabs;
    },
  },
  methods: {
    ...mapActions('offerResources', ['clearOfferState', 'updateResources']),
    getRedirectRoute(reportId) {
      if (reportId) {
        return {
          name: 'ReportDetails',
          params: { reportId },
        };
      }

      return {
        name: 'Offers',
      };
    },
  },
};
