import isEqual from 'lodash.isequal';

export function fillInForm(emptyForm, data) {
  const newContent = {};

  const formKeys = Object.keys(emptyForm);

  Object.entries(data).forEach(([key, value]) => {
    if (formKeys.includes(key)) newContent[key] = value;
  });

  return newContent;
}

export const postalCodeRegex = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,8}[a-zA-Z0-9]$/;

export const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const phoneRegex = /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const decimalRegex = (decimalPlaces) =>
  new RegExp(`^-?\\d+(\\.\\d{1,${decimalPlaces}})?$`);

export const getUpdatedValues = (content, newValues) => {
  const updatedValues = {};

  Object.entries(newValues).forEach(([key, value]) => {
    if (!isEqual(value, content[key])) updatedValues[key] = value;
  });

  return updatedValues;
};

export const hasUnsavedChanges = (form, defaultForm) => {
  return !isEqual(form, defaultForm);
};
