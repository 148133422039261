<template>
  <div>
    <ValidationProvider v-slot="{ errors }" rules="required">
      <text-input
        :value="title"
        :label="$t('add-brand-report-view.title')"
        class="my-6"
        :is-error="errors[0]"
        required
        :disabled="loading"
        :input-style="INPUT_STYLE.SPLIT"
        @input="$emit('update-title', $event)"
      ></text-input>
    </ValidationProvider>

    <ValidationProvider v-slot="{ errors }" rules="richTextRequired">
      <div class="flex">
        <div
          class="bg-gray-lighter px-4 flex items-center justify-center uppercase font-bold text-xs border border-white rounded-l"
        >
          {{ $t('add-brand-report-view.description') }}
          <span class="text-red pl-1 pr-0.5">*</span>
        </div>
        <wysiwyg-input
          class="w-full h-full border border-white"
          borderless
          dense
          required
          :class="{ 'border-red rounded': isWysiwygInitialized && errors[0] }"
          :disabled="loading"
          :value="description"
          :hidden-actions="hiddenActions"
          @input="handleOnDescriptionChange($event)"
        />
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import TextInput from '@/components/FormElements/TextInput';
import { INPUT_STYLE } from '@/store/modules/ui';
import extendValidationRules from '@/mixins/extendValidationRules';
import { WysiwygInput } from '@/components/WysiwygInput';
import { useValidation } from '@/composables';
import { ref } from '@vue/composition-api';
import { ActionName } from '@/components/WysiwygInput/wysiwyg.constants';

export default {
  components: {
    WysiwygInput,
    TextInput,
    ValidationProvider,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    brandId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [extendValidationRules],
  data() {
    return {
      INPUT_STYLE,
    };
  },
  setup(_, ctx) {
    useValidation(ctx);
    const isWysiwygInitialized = ref(false);

    const handleOnDescriptionChange = (value) => {
      const wysiwygInitializeValue = '<p></p>';

      if (value !== wysiwygInitializeValue) {
        isWysiwygInitialized.value = true;
      }

      ctx.emit('update-description', value);
    };

    const hiddenActions = [ActionName.MENTION, ActionName.ATTACHMENT];

    return { isWysiwygInitialized, handleOnDescriptionChange, hiddenActions };
  },
};
</script>
