<template>
  <select-input
    :value="computedValue"
    :options="options"
    :label="label"
    :placeholder="$t('object-status.status-placeholder')"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :required="required"
    :disabled="disabled"
    :clearable="withClearButton"
    @input="$emit('input', $event)"
  />
</template>
<script>
import { SelectInput } from '@/components/Inputs';
import { OBJECT_STATUS_MAP } from '@/constants/object';

export default {
  components: {
    SelectInput,
  },
  props: {
    value: {
      type: [Object, String],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    showInOffer: {
      type: Boolean,
      default: false,
    },
    withClearButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      const defaultOptions = [
        {
          label: this.$t('object-status.active'),
          value: OBJECT_STATUS_MAP.ACTIVE,
        },
        {
          label: this.$t('object-status.inactive'),
          value: OBJECT_STATUS_MAP.INACTIVE,
        },
      ];

      if (this.showInOffer || this.value === OBJECT_STATUS_MAP.IN_OFFER) {
        defaultOptions.push({
          label: this.$t('object-status.in-offer'),
          value: OBJECT_STATUS_MAP.IN_OFFER,
        });
      }

      return defaultOptions;
    },
    computedValue() {
      if (this.value === '') {
        return null;
      }
      return this.value;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && typeof val === 'string') {
          const option = this.options.find(({ value }) => value === val) || '';

          this.$emit('input', option);
        }
      },
    },
  },
};
</script>
