import {
  checkFiltersMatch,
  getQueryFromFilters,
  getRouteKey,
} from '@/util/filters';
import cloneDeep from 'lodash.clonedeep';
import { addQueryToLocation } from '@/util/routing';

export default {
  namespaced: true,
  state: {
    loading: false,
    filters: new Map(),
    initialFilters: new Map(),
    filterConfig: new Map(),
    pagination: new Map(),
    previousPage: {},
    previousRoute: {},
    isInitialized: false,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_ROUTE_FILTERS(state, { name, query }) {
      // Vue does not react to the Map's set() method - assignment is necessary here to trigger watchers
      state.filters = new Map(state.filters.set(name, query));
    },
    SET_INITIAL_FILTERS(state, { name, query }) {
      // Vue does not react to the Map's set() method - assignment is necessary here to trigger watchers
      state.initialFilters = new Map(state.initialFilters.set(name, query));
      state.isInitialized = true;
    },
    SET_ROUTE_CONFIG(state, { name, config }) {
      // Vue does not react to the Map's set() method - assignment is necessary here to trigger watchers
      state.filterConfig = new Map(state.filterConfig.set(name, config));
    },
    SET_PREVIOUS_PAGE(state, previousPage) {
      state.previousPage = previousPage;
    },
    SET_PREVIOUS_ROUTE(state, previousRoute) {
      state.previousRoute = previousRoute;
    },
    SET_ROUTE_PAGINATION(state, { name, pagination }) {
      const currentPagination = state.pagination.get(name);
      const computedPagination = { ...currentPagination, ...pagination };

      if (
        !pagination.page &&
        pagination.limit &&
        currentPagination?.limit &&
        pagination.limit !== currentPagination.limit
      ) {
        computedPagination.page = 1;
      }

      // Vue does not react to the Map's set() method - assignment is necessary here to trigger watchers
      state.pagination = new Map(
        state.pagination.set(name, computedPagination),
      );
    },
  },
  getters: {
    routeFilters: (state) => (route) => state.filters.get(getRouteKey(route)),
    initialRouteFilters: (state) => (route) =>
      state.initialFilters.get(getRouteKey(route)),
    filtersApplied: (state, getters) => (route) => {
      return !checkFiltersMatch(
        getters.routeFilters(route),
        getters.initialRouteFilters(route),
      );
    },
    queryFormatters: (state) => (route) =>
      state.filterConfig.get(getRouteKey(route))?.queryFormatters || {},
    filterQuery: (state, getters) => (route) =>
      getQueryFromFilters(
        getters.routeFilters(route),
        getters.queryFormatters(route),
      ),
    pagination: (state) => (route) => state.pagination.get(getRouteKey(route)),
    previousPage: (state) => state.previousPage,
    routeQuery: (state, getters) => (route) => ({
      ...getters.filterQuery(route),
      ...getters.pagination(route),
    }),
  },
  actions: {
    setRouteFilters({ commit, getters }, { route, query }) {
      if (getters.routeFilters(route)) {
        addQueryToLocation(route, getters.routeQuery(route));
      } else {
        commit('SET_ROUTE_FILTERS', {
          name: getRouteKey(route),
          query: cloneDeep(query),
        });
      }
    },
    setRouteConfig({ commit }, { route, config }) {
      commit('SET_ROUTE_CONFIG', { name: getRouteKey(route), config });
    },
    setInitialRouteFilters({ commit, getters }, { route, query }) {
      if (!getters.initialRouteFilters(route)) {
        commit('SET_INITIAL_FILTERS', {
          name: getRouteKey(route),
          query: cloneDeep(query),
        });
      }
    },
    resetRouteFilters({ commit, getters }, route) {
      commit('SET_ROUTE_FILTERS', {
        name: getRouteKey(route),
        query: cloneDeep(getters.initialRouteFilters(route)),
      });
    },
    clearRouteFilters({ commit }, route) {
      commit('SET_ROUTE_FILTERS', {
        name: getRouteKey(route),
        query: {},
      });
    },
    updateFilter({ commit, getters }, { route, parameter, value }) {
      const query = {
        ...cloneDeep(getters.routeFilters(route)),
        [parameter]: value,
      };

      commit('SET_ROUTE_FILTERS', { name: getRouteKey(route), query });

      addQueryToLocation(route, getters.routeQuery(route));
    },
    setPreviousPage({ commit }, previousPage) {
      commit('SET_PREVIOUS_PAGE', previousPage);
    },
    setPreviousRoute({ commit }, previousRoute) {
      commit('SET_PREVIOUS_ROUTE', previousRoute);
    },
    setRoutePagination(
      { commit, getters, state },
      { route, pagination, updatePath = true },
    ) {
      commit('SET_ROUTE_PAGINATION', { name: getRouteKey(route), pagination });

      if (updatePath && state.isInitialized)
        addQueryToLocation(route, getters.routeQuery(route));
    },
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading);
    },
  },
};
