import { defineComponent } from '@vue/composition-api';
import { useCustomForm } from '@/views/_components/CustomForms';
export default defineComponent({
    props: {
        components: {
            type: Array,
            default: () => [],
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const { formFields } = useCustomForm(props.values, props.components);
        return { formFields };
    },
});
