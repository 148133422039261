import { computed } from '@vue/composition-api';
import { markReportAsUnseen } from '@/api/requests/reports';
export const useReportTableActions = ({ selectedEntries, changeSelectedEntriesState, update }, ctx) => {
    const markReportsAsUnseen = async () => {
        const selectedReportsIds = selectedEntries.value.map((report) => report.id);
        await markReportAsUnseen({ reportIds: selectedReportsIds });
    };
    const handlePopupAction = async (callback, closePopup) => {
        await callback();
        closePopup();
        update();
        changeSelectedEntriesState(selectedEntries.value, false);
    };
    const isSeenReportExists = computed(() => selectedEntries.value.some((report) => report.isSeen));
    const getReportsListActions = (closePopup) => ({
        createGroup: {
            name: ctx.root.$t('brand-reports-list-view.mark-as-unread'),
            disabled: !isSeenReportExists.value,
            handler: () => handlePopupAction(markReportsAsUnseen, closePopup),
        },
    });
    return { getReportsListActions };
};
