import RoleAccept from '@/views/Brand/Objects/_components/Offer/RoleAccept.vue';
import { ACCEPT_BUTTON_TYPES } from '@/store/modules/user';
import { OfferObjectStatus } from '@/constants/offer-object';
import { computed, defineComponent } from '@vue/composition-api';
import { usePromise } from '@/composables';
import { AcceptanceLevel } from '@/api/requests/offers';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { getSingleObject } from '@/api/requests/objects';
export default defineComponent({
    name: 'ObjectAccept',
    components: {
        LoadingSpinner,
        RoleAccept,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
        offerId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const getData = () => getSingleObject(props.object.id, { offerId: props.offerId });
        const { loading, result: objectData, fetchData } = usePromise(getData());
        const acceptButtons = computed(() => [
            AcceptanceLevel.CENTRAL,
            AcceptanceLevel.STRUCTURE,
            AcceptanceLevel.PLACE,
        ]);
        const updateData = () => fetchData(getData());
        return {
            loading,
            objectData,
            ACCEPT_BUTTON_TYPES,
            OfferObjectStatus,
            AcceptanceLevel,
            acceptButtons,
            updateData,
        };
    },
});
