<template>
  <sidebar-layout
    right-sidebar
    footer
    :sidebar-tabs="sidebarTabs"
    :active-tab="activeTab"
    content-class="p-0"
    header-class="border-b-0"
    @set-tab="activeTab = $event"
  >
    <template #sidebarHeader>
      <tabs-header v-model="activeTab" :tabs="sidebarTabs" />
    </template>
    <template #sidebar>
      <offer-tabs
        :active-tab="activeTab"
        show-objects
        :place-columns="placeColumns"
        :object-columns="objectColumns"
      />
    </template>
    <template #contentHeader>
      <page-header
        :title="$t('add-brand-offer-view.new-offer')"
        :created-at="new Date()"
      />
    </template>
    <template>
      <offer-form
        id="brandOfferForm"
        :clear="submissionEndSuccessfully"
        :loading="submissionInProgress"
        @on-submit="handleSubmission"
      />
    </template>
    <template #footer>
      <div class="flex justify-between">
        <div class="flex items-center">
          <btn
            :disabled="submissionInProgress"
            :is-loading="submissionInProgress"
            class="md:w-48 mr-3"
            type="submit"
            form="brandOfferForm"
            @click="redirectAfterSubmission = true"
          >
            <span v-if="!submissionInProgress">{{ $t('common.save') }}</span>
          </btn>
          <btn
            :theme="BTN_THEMES.SECONDARY"
            :disabled="submissionInProgress"
            :compact="mobileView"
            :is-loading="submissionInProgress"
            class="w-48 md:w-auo compact-btn"
            type="submit"
            form="brandOfferForm"
            @click="redirectAfterSubmission = false"
          >
            <span v-if="!submissionInProgress">
              {{ $t('common.save-and-add-another') }}
            </span>
          </btn>
        </div>
        <router-link :to="{ name: 'Offers' }">
          <btn :theme="BTN_THEMES.NONE">{{ $t('common.cancel') }}</btn>
        </router-link>
      </div>
    </template>
  </sidebar-layout>
</template>
<script>
import Btn from '@/components/Button/Button';
import VueStore from '@/store';
import { mapActions } from 'vuex';
import offerMixin from '@/views/Brand/Applications/mixins/offerView';
import OfferTabs from '@/views/Brand/Applications/Components/Offers/OfferTabs';
import OfferForm from '@/views/Brand/Applications/Forms/OfferForm';
import PageHeader from '@/components/PageHeader/PageHeader';
import SidebarLayout from '@/layouts/SidebarLayout/SidebarLayout';
import TabsHeader from '@/components/TabsHeader/TabsHeader';
import resourcesMixin from '@/views/Brand/Applications/mixins/resources';

export default {
  components: {
    TabsHeader,
    SidebarLayout,
    PageHeader,
    OfferForm,
    Btn,
    OfferTabs,
  },
  mixins: [offerMixin, resourcesMixin],
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch('attachment/clearAttachmentList'),
      VueStore.dispatch('attachment/clearUploadQueue'),
    ]);
    next();
  },
  mounted() {
    this.activeTab = this.sidebarTabs[0]?.name;
  },
  methods: {
    ...mapActions('applications', ['fetchObject']),
    ...mapActions('offer', ['addOffer']),
    async handleSubmission(formData, { includeObjects }) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;

      try {
        const { data } = await this.addOffer(formData);

        await this.updateResources({
          offerId: data.id,
          addedPlaceIds: this.selectedPlaceIds,
          addedObjectIds: includeObjects ? this.selectedObjectIds : [],
        });

        this.submissionEndSuccessfully = true;

        if (this.redirectAfterSubmission)
          this.$router.push(this.getRedirectRoute());
        else {
          this.clearOfferState();
        }

        this.$toast.success(this.$t(`add-brand-offer-view.offer-added`));
      } catch {
        this.submissionEndSuccessfully = false;
      }

      this.submissionInProgress = false;
    },
  },
};
</script>
