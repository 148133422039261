import api from '@/api';
export var NotificationType;
(function (NotificationType) {
    NotificationType["ADDED_NEW_STRUCTURE"] = "added-new-structure";
    NotificationType["UPDATED_REPORT_STATUS"] = "updated-report-status";
    NotificationType["TAGGED_IN_COMMENT"] = "tagged-in-a-comment";
    NotificationType["ADDED_NEW_COMMENT"] = "added-new-comment";
    NotificationType["CREATED_OFFER"] = "created-offer";
    NotificationType["UPDATED_OFFER"] = "updated-offer";
    NotificationType["UPDATED_OFFER_STATUS"] = "updated-offer-status";
    NotificationType["PLACE_CREATED"] = "place-created";
    NotificationType["OBJECT_CREATED"] = "object-created";
    NotificationType["OBJECT_ACCEPTED"] = "object-accepted";
    NotificationType["OBJECT_REJECTED"] = "object-rejected";
    NotificationType["OBJECT_REVERTED"] = "object-reverted";
    NotificationType["USER_ASSIGNED_TO_REPORT"] = "user-assigned-to-report";
    NotificationType["USER_MARKED_IN_REPORT_MESSAGE"] = "user-marked-in-report-message";
    NotificationType["OFFER_ASSIGNED_TO_REPORT"] = "offer-assigned-to-report";
    NotificationType["ADDED_NEW_REPORT_MESSAGE"] = "added-new-report-message";
    NotificationType["ADDED_REQUIRED_ACTION_MESSAGE"] = "added-required-action-message";
    NotificationType["ANSWERED_REQUIRED_ACTION_MESSAGE"] = "answered-required-action-message";
})(NotificationType || (NotificationType = {}));
export var EmailNotificationSettingType;
(function (EmailNotificationSettingType) {
    EmailNotificationSettingType["USER_MARKED_IN_REPORT_MESSAGE"] = "user-marked-in-report-message";
    EmailNotificationSettingType["ADDED_NEW_REPORT_MESSAGE"] = "added-new-report-message";
    EmailNotificationSettingType["USER_ASSIGNED_TO_REPORT"] = "user-assigned-to-report";
    EmailNotificationSettingType["PLACE_CREATED"] = "place-created";
    EmailNotificationSettingType["COMMENT_CREATED"] = "comment-created";
    EmailNotificationSettingType["CREATED_OFFER"] = "created-offer";
    EmailNotificationSettingType["UPDATED_OFFER"] = "updated-offer";
    EmailNotificationSettingType["UPDATED_OFFER_STATUS"] = "updated-offer-status";
    EmailNotificationSettingType["OBJECT_ACCEPTED"] = "object-accepted";
    EmailNotificationSettingType["OBJECT_REJECTED"] = "object-rejected";
    EmailNotificationSettingType["OBJECT_REVERTED"] = "object-reverted";
    EmailNotificationSettingType["OFFER_ASSIGNED_TO_REPORT"] = "offer-assigned-to-report";
})(EmailNotificationSettingType || (EmailNotificationSettingType = {}));
export var EmailNotificationSettingRatio;
(function (EmailNotificationSettingRatio) {
    EmailNotificationSettingRatio["ONCE_A_DAY"] = "once-a-day";
    EmailNotificationSettingRatio["ALL_THE_TIME"] = "all-the-time";
    EmailNotificationSettingRatio["DISABLED"] = "disabled";
})(EmailNotificationSettingRatio || (EmailNotificationSettingRatio = {}));
export var CommentType;
(function (CommentType) {
    CommentType["OBJECT"] = "object";
})(CommentType || (CommentType = {}));
export const getNotifications = async (params) => {
    const { data } = await api.get('/notifications', {
        params,
    });
    return data;
};
export const readAllNotifications = async () => {
    await api.patch('/notifications');
};
export const readNotification = async (id) => {
    await api.patch(`/notifications/${id}`);
};
