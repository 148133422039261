import { computed, defineComponent } from '@vue/composition-api';
import { TableStyle } from '@/components/Table/__new__';
import { genericTableRowSize } from '@/components/Table/__new__/_components/TableCellGeneric/TableCellGeneric.constants';
class TableCellGeneric {
    define() {
        return defineComponent({
            props: {
                column: {
                    type: Object,
                    required: true,
                },
                row: {
                    type: Object,
                    required: true,
                },
                lastInRow: {
                    type: Boolean,
                    default: false,
                },
                firstInRow: {
                    type: Boolean,
                    default: false,
                },
                isNumberCell: {
                    type: Boolean,
                    default: false,
                },
                expanded: {
                    type: Boolean,
                    default: false,
                },
                tableStyle: String,
                to: {
                    type: Function,
                },
                isRowClickable: {
                    type: Boolean,
                    default: () => false,
                },
                rowSize: {
                    type: String,
                    default: genericTableRowSize.MEDIUM,
                },
                isRowHovered: {
                    type: Boolean,
                    default: false,
                },
            },
            setup(props) {
                const cellContent = computed(() => {
                    const { formatter, key } = props.column;
                    const defaultValue = key ? props.row[key] : '-';
                    return formatter
                        ? formatter(defaultValue, props.row)
                        : defaultValue;
                });
                const defaultCellPadding = computed(() => {
                    if (props.tableStyle === TableStyle.Resources)
                        return 'px-2';
                    if (props.firstInRow)
                        return 'pl-4 pr-2';
                    if (props.lastInRow)
                        return 'pr-4 pl-2';
                    return 'px-3';
                });
                return { cellContent, defaultCellPadding };
            },
        });
    }
}
const main = new TableCellGeneric().define();
export function GenericTableCell() {
    return main;
}
export default main;
