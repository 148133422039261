import { OFFER_STATUS_MAP, OFFER_STATUS_COLORS } from '@/constants/offer';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      OFFER_STATUS_COLORS,
      OFFER_STATUS_MAP,
    };
  },
  computed: {
    ...mapGetters('user', ['hasAdminRights']),
    statusConfig() {
      return {
        translation: 'offer-status',
      };
    },
  },
  methods: {
    isActive(status) {
      return status === OFFER_STATUS_MAP.ACTIVE;
    },
    isApproved(status) {
      return status === OFFER_STATUS_MAP.APPROVED;
    },
    getAvailableStatuses(status, currentStatus) {
      if (!this.hasAdminRights) return false;

      switch (status) {
        case OFFER_STATUS_MAP.APPROVED:
        case OFFER_STATUS_MAP.REJECTED:
          return this.isActive(currentStatus);
        case OFFER_STATUS_MAP.FINISHED:
          return this.isApproved(currentStatus);
        default:
          return false;
      }
    },
    getStatusOptions(currentStatus) {
      const statuses = Object.values(OFFER_STATUS_MAP);
      const currentStatusIndex = statuses.indexOf(currentStatus);

      return statuses
        .slice(currentStatusIndex + 1)
        .filter((status) => this.getAvailableStatuses(status, currentStatus))
        .filter((status) => status !== OFFER_STATUS_MAP.REJECTED);
    },
  },
};
