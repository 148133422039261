import { SORT_TYPE } from '@/constants/filters';

export default {
  props: {
    sortingEnabled: {
      type: Boolean,
      default: false,
    },
    sorting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sortingType: SORT_TYPE.NONE,
      sortingColumn: null,
    };
  },
  watch: {
    sorting: {
      handler(value) {
        this.sortingColumn = value?.column || null;
        this.sortingType = value?.type || SORT_TYPE.NONE;
      },
      immediate: true,
    },
  },
  methods: {
    changeSortingAttr(col) {
      const isStatusCol = col === this.computedStatusConfig.key;

      const sortableCol = isStatusCol || this.cols[col].sort !== false;

      if (!this.sortingEnabled || !sortableCol) return;

      if (this.sortingColumn !== col) this.sortingType = SORT_TYPE.NONE;

      this.sortingColumn = col;

      switch (this.sortingType) {
        case SORT_TYPE.NONE:
          this.sortingType = SORT_TYPE.DESC;
          break;
        case SORT_TYPE.DESC:
          this.sortingType = SORT_TYPE.ASC;
          break;
        case SORT_TYPE.ASC:
          this.sortingType = SORT_TYPE.NONE;
          this.sortingColumn = null;
          break;
      }

      this.$emit('sort-change', {
        col: this.sortingColumn,
        type: this.sortingType,
      });
    },
  },
};
