import { computed, defineComponent } from '@vue/composition-api';
import { getFullName } from '@/util/user';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
export default defineComponent({
    components: {
        Tooltip,
    },
    props: {
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const initials = computed(() => {
            return props.firstName[0] + props.lastName[0];
        });
        const fullName = getFullName({
            firstName: props.firstName,
            lastName: props.lastName,
        });
        return {
            initials,
            fullName,
        };
    },
});
