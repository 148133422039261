import { getSingleUser, SystemRole, UserRole } from '@/api/requests/users';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useState, useGetters, useActions } = createNamespacedHelpers('user');
import router from '@/router';
import { computed } from '@vue/composition-api';
import { useAuthUser } from '@/composables/use-auth-user';
export const useViewer = (ctx) => {
    const { hasRole: hasUserRole, initialRouteName } = useAuthUser();
    const { setViewer } = useActions(['setViewer']);
    const { viewer } = useState(['viewer']);
    const { isPreviewMode } = useGetters(['isPreviewMode']);
    const showUserPreview = computed(() => {
        return hasUserRole(SystemRole.SUPER_ADMIN) && !viewer.value;
    });
    const hasRole = (role) => {
        return viewer.value?.systemRole === role;
    };
    const previewUser = async (id, initialListRoute) => {
        const user = await getSingleUser(id);
        if (user.role === UserRole.NONE) {
            return ctx.root.$toast.warning(ctx.root.$t('api.invalid-viewer-role'));
        }
        setViewer({ ...user, initialListRoute });
        await router.push({ name: initialRouteName.value(user.role) });
    };
    const exitPreviewMode = () => {
        if (ctx.root.$route.name !== viewer.value.initialListRoute) {
            router.push({ name: viewer.value.initialListRoute });
        }
        setViewer(null);
    };
    return {
        showUserPreview,
        isPreviewMode,
        previewUser,
        exitPreviewMode,
        viewer,
        hasRole,
    };
};
