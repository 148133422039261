var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{ref:"popupOverlay",staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('close')}}},[_c('div',{staticClass:"bottom-menu"},[_vm._m(0),_c('ul',{staticClass:"bottom-menu-list"},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"bottom-menu-item",class:[
          { hidden: item.hide },
          { 'text-gray-light cursor-not-allowed': item.disabled } ],on:{"click":function () {
            if (item.disabled) {
              return;
            }
            item.isAddFileHandler ? _vm.onFileClick(item) : item.handler();
          }}},[_vm._v(" "+_vm._s(item.label)+" "),(item.isAddFileHandler)?_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"file",refInFor:true,attrs:{"type":"file","multiple":"","accept":"*"},on:{"change":item.handler}}):_vm._e()])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"decorator-wrapper"},[_c('div',{staticClass:"decorator-item"})])}]

export { render, staticRenderFns }