<template>
  <div>
    <slot :items="list" :unloaded-items="unloadedItems" />
    <infinite-loading
      v-if="shouldFetch && !isEmpty"
      ref="inf"
      spinner="waveDots"
      :force-use-infinite-wrapper="containerSelector"
      :identifier="infiniteId"
      :direction="direction"
      @infinite="infiniteHandler"
    >
      <div slot="no-more"></div>
      <div slot="no-results"><slot name="no-results"></slot></div>
      <div v-if="customSpinner" slot="spinner"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import isEqual from 'lodash.isequal';

import api from '@/api';

export default {
  components: {
    InfiniteLoading,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    containerSelector: {
      type: String,
      default: '',
    },
    startPage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    enableQueryWatcher: {
      type: Boolean,
      default: false,
    },
    customSpinner: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'bottom',
    },
    shouldFetch: {
      type: Boolean,
      default: true,
    },
    forceUpdate: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data() {
    return {
      page: this.startPage,
      list: [],
      isEmpty: false,
      queue: [],
      state: null,
      infiniteId: +new Date(),
      unloadedItems: null,
    };
  },
  watch: {
    async query(value, oldValue) {
      if (this.enableQueryWatcher && !isEqual(value, oldValue)) {
        await this.reload();
      }
    },
    forceUpdate(val) {
      if (val) this.reload();
    },
    async url() {
      await this.reload();
    },
  },
  async mounted() {
    await this.infiniteHandler();

    this.$emit('data-deliver', this.list);
  },
  methods: {
    async infiniteHandler($state) {
      if ($state) this.state = $state;

      if (this.queue[this.queue.length - 1] !== this.page) {
        this.queue.push(this.page);
        const { data } = await api.get(this.url, {
          params: {
            page: this.page,
            limit: this.limit,
            ...this.query,
          },
        });

        if (data.results.length) {
          if (this.page === this.startPage) {
            this.direction === 'top'
              ? (this.list = data.results.reverse())
              : (this.list = data.results);
          } else {
            this.direction === 'top'
              ? this.list.unshift(...data.results.reverse())
              : this.list.push(...data.results);
          }

          if (this.state) this.state.loaded();

          this.page++;
        } else {
          if (this.page === 1) {
            this.list = [];
            this.isEmpty = true;
          }
          if (this.state) this.state.complete();
        }

        this.$emit('update', data.results);
        this.unloadedItems = Math.max(
          data.pagination.total - data.pagination.to,
          0,
        );
      }
    },
    async reload() {
      this.isEmpty = false;
      this.page = this.startPage;
      this.infiniteId += 1;
      this.queue = [];
      await this.infiniteHandler();

      this.$emit('data-deliver', this.list);
    },
  },
};
</script>
