import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { useBrand, useMobileView, useRouteQuery, useUi } from '@/composables';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import VueStore from '@/store';
import { getIntValue } from '@/util/filters';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '@/constants/pagination';
import { useReportPopup } from '@/views/Brand/Applications/Report/_composables/use-report-popup';
import ReportsLayout from '@/views/Brand/Applications/Report/_components/ReportsLayout.vue';
import { ReportTable } from '@/views/Brand/Applications/Report/_components/ReportTable';
import BrandSearchBar from '@/layouts/BrandLayout/Components/BrandSearchBar.vue';
import router from '@/router';
import { ActionRequiredMessages } from '@/views/Brand/Applications/Report/_components/action-required-messages';
import { ReportTab, reportTabIndexMap, } from '@/views/Brand/Applications/Components/ReportMenu/ReportMenuItems.constants';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
export default defineComponent({
    name: 'Reports',
    components: {
        ActionRequiredMessages,
        FilterToggler,
        ReportsLayout,
        ReportTable,
        BrandSearchBar,
    },
    props: {
        addReport: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const { routeQuery, selectLimit, setupPreviousPage } = useRouteQuery(ctx);
        const { brand } = useBrand();
        const { update: updateActionRequiredMessages, updatesCounter: actionRequiredMessagesUpdatesCounter, } = useUpdatesCounter();
        const updateList = ref(0);
        const { isMobileView } = useMobileView();
        const { tabs } = useUi();
        const { reportPopup, closeReportCallback, closeReportRequest, onChangeRoute, onCloseReportPopup, } = useReportPopup(props.addReport);
        const onFormSubmit = () => {
            reportPopup.value = false;
            updateList.value++;
        };
        const editedReport = ref(null);
        const isActionRequiredTab = computed(() => tabs.value.reports === reportTabIndexMap[ReportTab.ACTION_REQUIRED]);
        const onEditReport = (reportId) => {
            editedReport.value = reportId;
            reportPopup.value = true;
        };
        watch(reportPopup, (value) => {
            if (!value)
                editedReport.value = null;
        });
        const onOpenReport = (reportId) => {
            setupPreviousPage();
            router.push({
                name: 'ReportDetails',
                params: { reportId },
                query: routeQuery.value,
            });
        };
        return {
            brand,
            routeQuery,
            selectLimit,
            onFormSubmit,
            updateList,
            onEditReport,
            onOpenReport,
            editedReport,
            reportPopup,
            closeReportCallback,
            closeReportRequest,
            onChangeRoute,
            onCloseReportPopup,
            isMobileView,
            isActionRequiredTab,
            actionRequiredMessagesUpdatesCounter,
            updateActionRequiredMessages,
        };
    },
    beforeRouteEnter(to, form, next) {
        const { page, limit } = to.query;
        VueStore.dispatch('routes/setRoutePagination', {
            route: to,
            pagination: {
                page: getIntValue(page, DEFAULT_PAGE),
                limit: getIntValue(limit, DEFAULT_LIMIT),
            },
            updatePath: false,
        });
        next();
    },
    beforeRouteLeave(to, from, next) {
        this.onChangeRoute(next);
    },
    beforeRouteUpdate(to, from, next) {
        this.onChangeRoute(next);
    },
});
