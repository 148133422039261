import { defineComponent } from '@vue/composition-api';
import RadioButton from './RadioButton.vue';
import { formElementProps } from '@/components/Inputs/_components/FormElement';
import FormElement from '@/components/Inputs/_components/FormElement/FormElement.vue';
import InputLabel from '@/components/Inputs/_components/InputLabel/InputLabel.vue';
export default defineComponent({
    components: { InputLabel, FormElement, RadioButton },
    props: {
        ...formElementProps,
        value: String,
        name: String,
        label: String,
        items: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
            required: true,
        },
        required: Boolean,
    },
});
