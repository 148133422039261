export const createConfirmModalData = (i18n, type, name) => ({
    type: 'confirm',
    title: i18n.t('confirm-delete-modal.modal-title', {
        type: i18n.t(`confirm-delete-modal.${type}`),
    }),
    description: i18n.t('confirm-delete-modal.modal-description', {
        name,
        type: i18n.t(`confirm-delete-modal.${type}`),
    }),
    btnText: i18n.t('confirm-delete-modal.modal-btn'),
});
