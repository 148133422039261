<template>
  <component
    :is="tagName"
    class="tag"
    :class="[{ 'pl-3': !deleteBtn }, tagStyle, theme]"
  >
    <button
      v-if="deleteBtn"
      type="button"
      class="icon-button focus:outline-none"
      :class="[tagStyle, theme]"
      @click="$emit('delete', $event)"
    >
      <icon :size="IconSize.SM" :icon="deleteBtnIcon" class="cursor-pointer" />
    </button>
    <slot></slot>
  </component>
</template>
<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
import { TAG_STYLE, TAG_THEME } from '@/components/Tag/tag.constants';

export default {
  components: { Icon },
  props: {
    tagName: {
      type: String,
      default: 'li',
    },
    deleteBtn: {
      type: Boolean,
      default: true,
    },
    deleteBtnIcon: {
      type: [String, Object],
      default: mdiClose,
    },
    tagStyle: {
      type: String,
      default: TAG_STYLE.ROUNDED,
    },
    theme: {
      type: String,
      default: TAG_THEME.NONE,
    },
  },
  setup() {
    return { IconSize };
  },
};
</script>
<style scoped lang="scss">
.tag {
  &.rounded {
    @apply pr-3 mr-2 my-1 rounded-full text-2xs flex items-center uppercase overflow-hidden h-8;
  }

  &.rectangular {
    @apply mr-2 my-1 rounded text-2xs flex items-center capitalize overflow-hidden h-7 font-semibold pr-3;
    min-width: fit-content;
  }

  &.none {
    @apply border border-gray-dark;
  }

  &.yellow-light {
    @apply bg-yellow-light border border-yellow-dark;
  }
}

.icon-button {
  &.rounded {
    @apply flex items-center justify-center rounded-full w-8 h-8 mr-0.5;
  }

  &.rectangular {
    @apply flex items-center justify-center h-full px-1 mr-1 py-4;
  }

  &.none {
    &:hover {
      @apply bg-gray-lightest;
    }
  }

  &.yellow-light {
    &:hover {
      @apply text-yellow-dark;
    }
  }
}
</style>
