export const providePagination = (route) => {
  const page = Number.parseInt(route.query.page, 10);
  return {
    query: {
      ...route.query,
      page: !route.query.page || Number.isNaN(page) ? 1 : page,
    },
  };
};

export const provideReportsParams = (route) => {
  const { addReport, placesToSelect, objectsToSelect } = route.params;

  return {
    ...providePagination(route),
    addReport,
    placesToSelect,
    objectsToSelect,
  };
};

export const provideQueryObject = (route) => {
  return {
    query: {
      ...route.query,
    },
  };
};

export const hasParentCategoryNotChanged = (
  to,
  from,
  toParentRoute,
  fromParentRoute,
) => {
  return (
    toParentRoute === fromParentRoute &&
    (!to.meta.submenu || !from.meta.submenu)
  );
};

export const isRouteNavigationIsWithinSubMenu = (
  to,
  from,
  toParentRoute,
  fromParentRoute,
) => {
  return (
    to.meta.submenu && from.meta.submenu && toParentRoute !== fromParentRoute
  );
};

export const extractParentRouteName = (route) => {
  return route.meta.matchName || route.name;
};

export const matchRouteWithName = (route, name) => {
  return route.name === name || route.meta.matchName === name;
};

export const getObjectRoute = (object, brandId) => {
  const { id, objectType } = object;

  return {
    name: 'ObjectDetails',
    params: {
      objectId: id,
      objectTypeId: objectType.id,
      ...(brandId && { id: brandId }),
    },
  };
};

export const getOfferObjectRoute = (relation, offerId, brandId) => {
  return {
    name: 'ObjectInOfferDetails',
    params: {
      objectId: relation.object.id,
      offerId: offerId,
      ...(brandId && { id: brandId }),
    },
  };
};

export const getPlaceRoute = (place, brandId) => {
  const { id } = place;

  return {
    name: 'PlaceDetails',
    params: { placeId: id, ...(brandId && { id: brandId }) },
  };
};

export const addQueryToLocation = (route, query) => {
  history.pushState(
    {},
    null,
    route.path +
      '?' +
      Object.keys(query)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
        })
        .join('&'),
  );
};

export const getParamsFromLocation = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams.entries());
};
