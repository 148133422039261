<template>
  <div class="wrapper w-full flex justify-center">
    <div v-if="images.length > 1" class="buttons-wrapper flex justify-between">
      <button class="button ml-5" @click="prev">
        <icon :icon="mdiChevronLeft" :size="IconSize.LG" class="icon-button" />
      </button>
      <button class="button mr-5" @click="next">
        <icon :icon="mdiChevronRight" :size="IconSize.LG" class="icon-button" />
      </button>
    </div>

    <div
      class="image-wrapper"
      :class="{
        'bg-gray-lightest': fillBackgroundWithColor,
        'background-pattern': fillBackgroundWithPattern,
      }"
    >
      <image-modal
        v-if="withModalPreview"
        :image-url="activeItem.url"
        alt="image-carousel"
      >
        <image-view
          :src="activeItem.url"
          alt="image-carousel"
          :cover-img="coverImg"
        />
      </image-modal>
      <image-view
        v-else
        :src="activeItem.url"
        alt="image-carousel"
        :cover-img="coverImg"
      />
    </div>
    <ul v-if="images.length > 2" class="dots-wrapper">
      <li
        v-for="(item, index) in images"
        :key="item.id"
        class="dot"
        :class="{ 'dot-active': index === activeIndex }"
        @click="set(index)"
      ></li>
    </ul>
  </div>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import ImageView from '@/components/ImageView/ImageView.vue';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { ImageModal } from '@/components/Modal';

export default {
  name: 'ImageCarousel',
  components: {
    Icon,
    ImageView,
    ImageModal,
  },
  props: {
    images: {
      type: Array,
      default() {
        return [];
      },
    },
    coverImg: {
      type: Boolean,
      default: true,
    },
    withModalPreview: {
      type: Boolean,
      default: true,
    },
    fillBackgroundWithColor: Boolean,
    fillBackgroundWithPattern: Boolean,
  },
  data() {
    return {
      IconSize,
      mdiChevronLeft,
      mdiChevronRight,
      activeIndex: 0,
    };
  },
  computed: {
    activeItem() {
      return this.images[this.activeIndex];
    },
    itemsLength() {
      return this.images.length - 1;
    },
  },
  methods: {
    next() {
      if (this.activeIndex === this.itemsLength) {
        this.activeIndex = 0;
      } else {
        this.activeIndex++;
      }
    },
    prev() {
      if (this.activeIndex === 0) {
        this.activeIndex = this.itemsLength;
      } else {
        this.activeIndex--;
      }
    },
    set(id) {
      this.activeIndex = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 385px;
  @apply relative;
}
.background-pattern {
  background-image: url('../../assets/dark-background-pattern.png');
}

.button {
  @apply w-10 h-10 flex justify-center items-center bg-gray-lighter rounded-full;

  .icon-button {
    @apply text-gray-darker;
  }

  &:disabled {
    @apply cursor-default opacity-25;
  }
}

.image-wrapper {
  @apply h-full w-full flex justify-center items-center;
}

.buttons-wrapper {
  top: 50%;
  transform: translateY(-50%);
  @apply w-full absolute left-0;
}
.dots-wrapper {
  min-width: 150px;
  bottom: 5%;
  @apply absolute transform -translate-x-1/2 left-1/2 flex justify-center items-center;
}
.dot {
  @apply bg-white opacity-75 border border-white w-3 h-3 rounded-full cursor-pointer mx-2 shadow-well;
}
.dot-active {
  @apply opacity-100;
}
</style>
