import FilterGroup from '@/components/Filters/FilterGroup.vue';
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import { computed, defineComponent, onUnmounted, ref, watch, } from '@vue/composition-api';
import { useBrand } from '@/composables';
import { ReportFilters, ReportMenuItem, } from '@/views/Brand/Applications/Components/Sidebar/sidebar.constants';
import { ReportStatus } from '@/api/requests/reports';
import { SelectInput } from '@/components/Inputs';
import Checkbox from '@/components/Inputs/Checkbox/Checkbox.vue';
export default defineComponent({
    props: {
        activeMenuItem: {
            type: String,
        },
    },
    components: {
        Checkbox,
        FilterWrapper,
        FilterGroup,
        SelectInput,
    },
    setup(props) {
        const { brand } = useBrand();
        const brandId = computed(() => brand.value?.id);
        const filters = ref({
            [ReportFilters.SEARCH]: '',
            [ReportFilters.PLACES]: [],
            [ReportFilters.CREATOR]: null,
            [ReportFilters.CATEGORY]: null,
            [ReportFilters.SORT_BY]: null,
            [ReportFilters.STATUS]: [ReportStatus.PENDING, ReportStatus.ACTIVE],
            [ReportFilters.SUPERVISOR]: null,
            [ReportFilters.IS_STARRED]: false,
        });
        const clearSuperVisor = () => {
            if (props.activeMenuItem !== ReportMenuItem.MY_TASKS) {
                filters.value[ReportFilters.SUPERVISOR] = null;
            }
        };
        watch(() => props.activeMenuItem, clearSuperVisor);
        onUnmounted(clearSuperVisor);
        return { brandId, ReportFilters, filters, ReportMenuItem };
    },
});
