<template>
  <div>
    <filters
      @search="onSearch"
      @open-filters="filtersOpened = !filtersOpened"
    />
    <select-input
      v-if="filtersOpened"
      v-model="tags"
      :query="{ brandId: currentBrandId($route), relation: 'place' }"
      url="tags"
      autocomplete
      multiple
      option-value-name="id"
      option-label-name="name"
      :placeholder="$t('add-brand-report-view.tags-placeholder')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SelectInput } from '@/components/Inputs';
import Filters from '@/components/ResourcesWrapper/_components/Filters.vue';

export default {
  components: { SelectInput, Filters },
  data() {
    return {
      search: '',
      tags: [],
      filtersOpened: false,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    placeFilters() {
      return {
        ...(this.search && { q: this.search }),
        ...(this.tags.length && { tags: this.tags.map(({ name }) => name) }),
      };
    },
  },
  watch: {
    placeFilters: {
      handler() {
        this.updateFilters();
      },
      deep: true,
    },
  },
  methods: {
    onSearch(value) {
      this.search = value;

      this.updateFilters();
    },
    updateFilters() {
      this.$emit('filter-places', this.placeFilters);
    },
  },
};
</script>
