var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"form",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(handleSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('resources',{staticClass:"px-6",attrs:{"loading":_vm.isLoading,"report-id":_vm.reportId,"validate-resources":_vm.validateResources,"users":_vm.users},on:{"select-users":function($event){return _vm.$emit('select-users', $event)},"set-users":function($event){return _vm.$emit('set-users', $event)},"remove-users":function($event){return _vm.$emit('remove-users', $event)}}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var resetCategories = ref.reset;
return [_c('category-selector',{attrs:{"is-error":_vm.validateCategories && !!errors[0],"loading":_vm.isLoading,"confirm-category-unselect":_vm.unsavedCategoryChanges},on:{"reset":function($event){return _vm.onResetCategories(resetCategories)}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}],null,true)}),(_vm.category)?_c('div',{staticClass:"px-6"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('category-form',{attrs:{"category":_vm.category,"loading":_vm.isLoading,"is-error":_vm.validateCategories && errors[0],"category-form":_vm.editedForm},on:{"set-decision-makers":function($event){return _vm.$emit('set-decision-makers', $event)},"set-contractors":function($event){return _vm.$emit('set-contractors', $event)},"form-updated":_vm.setCategoryFormChanges},model:{value:(_vm.form.categoryForm),callback:function ($$v) {_vm.$set(_vm.form, "categoryForm", $$v)},expression:"form.categoryForm"}})]}}],null,true)}),_c('wysiwyg-editor',{attrs:{"report":_vm.report,"message":_vm.message},on:{"mention":function($event){return _vm.$emit('mention', $event)},"update-files":function($event){_vm.form.fileTokens = $event},"upload-in-progress":function($event){return _vm.$emit('upload-in-progress', $event)}},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }