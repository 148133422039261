<template>
  <div v-click-outside="close" class="status">
    <status-button
      ref="statusToggler"
      :status="$t(`${statusTranslation}.${currentStatus}`)"
      :color="activeColor"
      :disabled="disabled"
      :compact="compact"
      :compact-variant="compactVariant"
      @click="showStatusDropdown = true"
    />
    <div
      v-show="showStatusDropdown && options.length"
      ref="statusDropdown"
      class="status-dropdown"
      @touchstart.stop
      @mousedown.stop
    >
      <ul class="status-list">
        <li>
          <status-button
            :status="$t(`${statusTranslation}.${currentStatus}`)"
            :color="activeColor"
            :compact="compact"
            :compact-variant="compactVariant"
            @click="showStatusDropdown = false"
          />
        </li>
        <li v-for="(status, i) in options" :key="i">
          <status-button
            :status="$t(`${statusTranslation}.${status}`)"
            :compact="compact"
            :compact-variant="compactVariant"
            :color="colors[status]"
            @click="selectStatus(status)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import StatusButton from '@/components/StatusToggler/StatusButton';
import Popper from 'popper.js';
import { StatusButtonVariant } from '@/components/StatusToggler/StatusToggler.constants';

export default {
  components: { StatusButton },
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    statusTranslation: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    compact: Boolean,
    compactVariant: {
      type: String,
      default: StatusButtonVariant.OUTLINE,
    },
  },
  data() {
    return {
      showStatusDropdown: false,
      popperInstance: null,
      dropdown: null,
    };
  },
  computed: {
    activeColor() {
      return this.colors[this.currentStatus];
    },
  },
  watch: {
    showStatusDropdown(value) {
      if (value && this.popperInstance) this.popperInstance.scheduleUpdate();
    },
  },
  mounted() {
    this.dropdown = this.$refs.statusDropdown;

    this.appendDropdownToBody();

    this.popperInstance = new Popper(
      this.$refs.statusToggler.$el,
      this.dropdown,
      {
        placement: 'bottom-center',
        removeOnDestroy: true,
        positionFixed: true,
        modifiers: {
          offset: {
            offset: '0, -33',
          },
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
        },
      },
    );
  },
  beforeDestroy() {
    this.popperInstance.destroy();
  },
  methods: {
    close() {
      this.showStatusDropdown = false;
    },
    selectStatus(status) {
      this.$emit('change-status', status);

      this.$nextTick(this.close);
    },
    appendDropdownToBody() {
      this.$el.removeChild(this.dropdown);
      document.body.appendChild(this.dropdown);
    },
  },
};
</script>

<style scoped lang="scss">
.status {
  &-dropdown {
    @apply w-26 shadow-well rounded bg-white z-60 border border-gray-dark;
  }

  &-list {
    li {
      @apply my-2 flex justify-center;
    }
  }
}
</style>
