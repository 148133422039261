import { defineComponent } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import { useToggle } from '@/composables';
import { mdiChevronDown } from '@mdi/js';
export default defineComponent({
    components: { Icon },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { state: expanded, toggle } = useToggle(!!props.item.expanded);
        return { mdiChevronDown, IconSize, expanded, toggle };
    },
});
