<template>
  <!--  NOTE: Copy of existing modal with changes in styling-->
  <div
    v-if="show"
    ref="popupOverlay"
    class="popup-overlay"
    :class="{ 'items-center': centered }"
    @click.self="close"
  >
    <loading-spinner
      v-if="!isLoaded"
      :is-loading="!isLoaded"
      class="justify-center w-full flex"
    />
    <div
      v-show="isLoaded"
      :class="[type, isMobileFullScreen ? 'h-full' : 'h-auto', 'popup']"
    >
      <div
        ref="popupInner"
        :class="[
          isMobileFullScreen ? 'inner-full-screen' : 'inner',
          variant === 'default' ? 'p-6' : '',
          variant === 'secondary' ? 'p-0 overflow-hidden rounded-lg mt-30' : '',
        ]"
      >
        <button
          :class="[
            'close focus:outline-none absolute top-0 right-0',
            { 'z-10': alwaysOnTopCloseBtn },
            closeBtnClass,
            variant === 'secondary' ? 'py-3 pr-4' : 'p-2 md:p-4',
          ]"
          @click="close"
        >
          <icon class="cursor-pointer" :icon="mdiClose"></icon>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { mdiClose } from '@mdi/js';
import { useMobileView } from '@/composables';

export default {
  components: {
    Icon,
    LoadingSpinner,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    isLoaded: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'normal',
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    alwaysOnTopCloseBtn: {
      type: Boolean,
      default: false,
    },
    closeBtnClass: String,
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      mdiClose,
      popupOverlayHeight: 0,
      popupHeight: 0,
    };
  },
  setup(props) {
    const { isMobileView } = useMobileView();
    const isMobileFullScreen = props.isFullScreen && isMobileView.value;

    return {
      isMobileFullScreen,
    };
  },
  computed: {
    scrollbarWidth() {
      return window.innerWidth - document.documentElement.clientWidth;
    },
    centered() {
      return this.popupHeight + 180 < this.popupOverlayHeight;
    },
  },
  watch: {
    isLoaded: {
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.popupHeight = this.$refs.popupInner.clientHeight;
            this.popupOverlayHeight = this.$refs.popupOverlay.clientHeight;
          }
        });
      },
    },
  },
  mounted() {
    document.body.style.paddingRight = `${this.scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';
  },
  destroyed() {
    document.body.style.paddingRight = '0';
    document.body.style.overflow = 'auto';
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/_variables.scss';
.popup-overlay {
  @apply fixed w-screen h-screen top-0 left-0 z-100 overflow-y-auto flex justify-center items-center;
  background: rgba($primary-dark, 0.4);
}
.popup {
  @apply z-50 flex items-center text-center justify-center w-full;

  &.media {
    @apply transform-none;
    left: auto;
    top: auto;
    @screen md {
      @apply max-w-modal;
    }
    .close {
      @apply p-6;
    }
    .inner {
      @apply w-auto p-2;
    }
  }
  .inner {
    @apply bg-white w-11/12 rounded-lg min-h-60 flex flex-col items-center justify-center relative;

    @screen sm {
      @apply w-136;
    }
  }

  .inner-full-screen {
    @apply bg-white w-screen h-full flex flex-col items-center relative;

    @screen md {
      @apply w-11/12 min-h-60 rounded-lg p-6 justify-center;
    }
  }
}
</style>
