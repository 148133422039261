<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      v-if="brand"
      :id="id"
      ref="form"
      class="form py-1 mb-8"
      :class="{ 'pb-48': !inOffer }"
      @submit.prevent="handleSubmit(pushFormData)"
      @reset.prevent="reset"
    >
      <image-uploader
        v-model="imageUrl"
        :label="$t('object-form.logo-label')"
        class="mt-6"
        :class="{ fill: imageUrl }"
      />
      <template v-if="!inOffer && enableObjectTypeUpdate">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <select-input
            id="object-type"
            v-model="selectedObjectType"
            url="object-types"
            :query="{
              brandId: brand.id,
              ...(!!content && { hasAssignedForm: true }),
            }"
            :label="$t('object-form.object-type')"
            :placeholder="$t('object-form.object-type-placeholder')"
            :error-message="errors[0]"
            option-label-name="name"
            option-value-name="id"
            class="my-4"
            required
            autocomplete
          />
        </ValidationProvider>
      </template>
      <text-input
        v-if="content"
        :value="content.internalNumber"
        disabled
        :label="$t('object-form.object-number')"
      />
      <ValidationProvider v-slot="{ errors }" rules="required">
        <select-input
          id="place"
          v-model="selectedPlace"
          url="places"
          :label="$t('object-form.place')"
          :placeholder="$t('object-form.place-placeholder')"
          :error-message="errors[0]"
          option-label-name="name"
          option-value-name="id"
          :query="{
            brandId: brand.id,
            ...(offerReport ? { reportId: offerReport } : {}),
          }"
          class="my-4"
          required
          autocomplete
          clearable
        />
      </ValidationProvider>
      <Localization v-model="localization" />
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="form.address"
          :label="$t('object-form.address')"
          :placeholder="$t('object-form.address-placeholder-1')"
          :error-message="errors[0]"
          :disabled="isLoading || onProperty"
          class="mt-4"
          required
        />
      </ValidationProvider>
      <div class="flex">
        <PostalCode
          v-model="form.postalCode"
          :is-loading="isLoading"
          :disabled="onProperty"
        />
        <ValidationProvider v-slot="{ errors }" class="w-3/4" rules="required">
          <text-input
            v-model="form.city"
            :placeholder="$t('object-form.address-placeholder-3')"
            :error-message="errors[0]"
            :disabled="isLoading || onProperty"
            required
            class="my-4"
          />
        </ValidationProvider>
      </div>
      <Coordinates
        :value="coordinates"
        :is-loading="isLoading"
        :clear="clear"
        :helper-text="!inOffer"
        @input="$emit('set-coordinates', $event)"
        @set-tab="$emit('set-tab', $event)"
      />
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model.number="form.monthlyPrice"
          :label="$t('object-form.price')"
          :placeholder="$t('object-form.price-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          type="number"
          required
          class="my-4"
        />
      </ValidationProvider>

      <ValidationProvider v-if="!inOffer" v-slot="{ errors }" rules="required">
        <status-select
          v-model="internalStatus"
          :label="$t('object-form.internal-status')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="my-4"
        />
      </ValidationProvider>

      <custom-object-form
        :content="content"
        :is-loading="isLoading"
        :clear="clear"
        :enable-object-type-update="enableObjectTypeUpdate"
        :object-type="selectedObjectType || objectType"
        @get-form-values="objectTypeForm = $event"
      />

      <text-input
        v-model="videoUrl"
        :label="$t('object-form.link')"
        :placeholder="$t('object-form.link-placeholder')"
        :disabled="isLoading"
        class="my-4"
      />
      <div class="flex mt-4">
        <select-input
          id="objects-tags"
          v-model="tags"
          url="tags"
          :label="$t('place-preview.tags')"
          :placeholder="$t('place-preview.tags-placeholder')"
          option-label-name="name"
          option-value-name="id"
          autocomplete
          multiple
          clearable
          @enter="handleEnter"
        />
      </div>
      <select-input
        v-if="!inOffer"
        v-model="selectedContractors"
        url="organizations"
        :label="$t('object-form.contractor')"
        :query="computedQuery"
        :placeholder="$t('object-form.choose-contractor')"
        option-label-name="name"
        option-value-name="id"
        class="my-4"
        autocomplete
        clearable
        multiple
      />
      <switch-input
        v-if="localization === OBJECT_LOCALIZATION.OUTSIDE"
        v-model="showDirectionAngle"
        class="my-5"
        :label="$t('object-form.direction-on-map')"
        :label-position="LabelPosition.RIGHT"
      />
      <angle-picker v-if="showDirectionAngle" v-model="form.directionAngle" />
    </form>
  </ValidationObserver>
</template>

<script>
import { LabelPosition, SwitchInput, TextInput } from '@/components/Inputs';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CustomObjectForm from '@/views/Brand/Objects/Forms/CustomObjectForm';
import Coordinates from '@/views/Brand/Objects/Forms/Components/Coordinates';
import PostalCode from '@/views/Brand/Objects/Forms/Components/PostalCode';
import StatusSelect from '@/views/Brand/Objects/Forms/Components/StatusSelect';
import Localization from '@/views/Brand/Objects/Forms/Components/Localization';
import manageTagsMixin from '@/mixins/manageTagsMixin';
import objectForm from '@/views/Brand/Objects/mixins/objectForm';
import { CONTRACTOR_TYPE } from '@/store/modules/user';
import { SelectInput } from '@/components/Inputs';
import { AnglePicker } from '@/components/AnglePicker';
import { MapSettings } from '@/components/Map';
import { mapActions } from 'vuex';
import { OBJECT_LOCALIZATION } from '@/constants/object';

export default {
  name: 'ObjectForm',
  components: {
    SwitchInput,
    SelectInput,
    Localization,
    StatusSelect,
    PostalCode,
    Coordinates,
    CustomObjectForm,
    TextInput,
    ImageUploader,
    ValidationProvider,
    ValidationObserver,
    AnglePicker,
  },
  mixins: [objectForm, manageTagsMixin],
  data() {
    return {
      showDirectionAngle: false,
      savedUserTags: [],
    };
  },
  computed: {
    OBJECT_LOCALIZATION() {
      return OBJECT_LOCALIZATION;
    },
    computedQuery() {
      return {
        ...this.brandId,
        type: CONTRACTOR_TYPE,
      };
    },
    isEdited() {
      return !!this.content;
    },
  },
  methods: {
    ...mapActions('map', ['setSetting']),
    handleEnter(value) {
      this.createNewTag(value, () => {
        this.tags.push({ name: value.enteredValue });
      });
    },
  },
  created() {
    if (this.isEdited) {
      this.savedUserTags = [...this.tags];

      if (this.form.directionAngle != null) {
        this.showDirectionAngle = true;
      }
    }
  },
  watch: {
    tags: {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.isEdited ? oldValue : newValue) {
          this.updateQueues(this.savedUserTags, newValue);
        }
      },
    },
    showDirectionAngle() {
      if (this.showDirectionAngle) {
        this.setSetting({
          setting: MapSettings.SHOW_OBJECT_DIRECTION,
          isEnabled: true,
        });
        return (this.form.directionAngle = this.form.directionAngle ?? 0);
      }
      this.setSetting({
        setting: MapSettings.SHOW_OBJECT_DIRECTION,
        isEnabled: false,
      });
      this.form.directionAngle = null;
    },
    form: {
      handler(value) {
        if (this.localization !== OBJECT_LOCALIZATION.OUTSIDE) {
          this.form.directionAngle = null;
          this.showDirectionAngle = false;
        }

        if (value.directionAngle != null) {
          this.setSetting({
            setting: MapSettings.SHOW_OBJECT_DIRECTION,
            isEnabled: true,
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  setup() {
    return { LabelPosition };
  },
};
</script>
