import { mdiArrowRightThin } from '@mdi/js';
import { Icon, IconSize } from '@/components/Icon';
import { defineComponent, onMounted, onUnmounted, ref, } from '@vue/composition-api';
export default defineComponent({
    components: {
        Icon,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    setup(props, ctx) {
        const circle = ref(null);
        const arrow = ref(null);
        const handle = ref(null);
        const dragging = ref(false);
        const angle = ref(0);
        onMounted(() => (angle.value = props.value));
        const injectListeners = () => {
            document.addEventListener('mousemove', handleDrag);
            document.addEventListener('mouseup', stopDragging);
            document.addEventListener('touchmove', handleDrag);
            document.addEventListener('touchend', stopDragging);
        };
        const clearListeners = () => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', stopDragging);
            document.removeEventListener('touchmove', handleDrag);
            document.removeEventListener('touchend', stopDragging);
        };
        const startDragging = (event) => {
            dragging.value = true;
            updateAngle(event);
            injectListeners();
        };
        const stopDragging = () => {
            dragging.value = false;
            ctx.emit('input', Math.floor(angle.value));
            clearListeners();
        };
        const handleDrag = (event) => {
            event.preventDefault();
            if (dragging.value) {
                updateAngle(event);
            }
        };
        const updateAngle = (event) => {
            const rect = circle.value?.getBoundingClientRect();
            if (!rect) {
                return;
            }
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            let clientX, clientY;
            if (event.touches) {
                clientX = event.touches[0].clientX;
                clientY = event.touches[0].clientY;
            }
            else {
                clientX = event.clientX;
                clientY = event.clientY;
            }
            const dx = clientX - centerX;
            const dy = clientY - centerY;
            const calculatedAngle = Math.atan2(dy, dx);
            angle.value =
                (calculatedAngle >= 0
                    ? calculatedAngle
                    : 2 * Math.PI + calculatedAngle) *
                    (180 / Math.PI);
            updateHandlePosition();
        };
        const updateHandlePosition = () => {
            if (!circle.value || !handle.value || !arrow.value) {
                return;
            }
            const { width, height } = circle.value.getBoundingClientRect();
            const arrowElement = arrow.value.$el;
            const radius = circle.value?.offsetWidth / 2;
            const xOffset = width / 2;
            const yOffset = height / 2;
            const angleRad = (angle.value * Math.PI) / 180;
            const x = Math.round(radius + radius * Math.cos(angleRad));
            const y = Math.round(radius + radius * Math.sin(angleRad));
            handle.value.style.transform = `translate(${x - handle.value.offsetWidth / 2 - xOffset}px, ${y - handle.value.offsetHeight / 2 - yOffset}px)`;
            arrowElement.style.transform = `rotate(${angle.value}deg)`;
        };
        onMounted(() => {
            updateHandlePosition();
        });
        onUnmounted(() => {
            clearListeners();
        });
        return {
            mdiArrowRightThin,
            IconSize,
            startDragging,
            stopDragging,
            circle,
            arrow,
            handle,
        };
    },
});
