var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('settings-content-wrapper',[_c('div',{staticClass:"pb-4 mb-4 flex justify-between h-16 border-b border-b-gray-light"},[_c('breadcrumbs',{staticClass:"self-start",attrs:{"path":[
        {
          name: ("" + (_vm.$t('categories-view.categories'))),
        } ]}}),_c('router-link',{attrs:{"to":{ name: 'AddCategory' }}},[_c('btn',{staticClass:"w-30 self-end"},[_vm._v(_vm._s(_vm.$t('common.add')))])],1)],1),(!_vm.categories)?_c('div',[_c('loading-spinner',{staticClass:"h-10 w-10",attrs:{"is-loading":""}})],1):_vm._e(),(_vm.categories)?_c('div',{staticClass:"flex bg-neutral flex-wrap"},_vm._l((_vm.categories.results),function(category){return _c('tree-category-root',{key:category.id,staticClass:"mr-4 mb-4 max-w-50",attrs:{"id":category.id,"icon":category.cdnIconUrl,"category":category,"name":category.name,"context-menu":{
        edit: {
          name: _vm.$t('common.edit'),
          handler: function () {
            _vm.onEditCategory(category.id, category);
          },
        },
      },"active":_vm.selectedCategory === category.id},on:{"close":function($event){_vm.selectedCategory = null},"click":function($event){_vm.selectedCategory =
          category.id === _vm.selectedCategory ? null : category.id}}})}),1):_vm._e(),(_vm.categories && _vm.categories.results && !_vm.categories.results.length)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('categories-view.no-categories')))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }