import { ValidationObserver, ValidationProvider } from 'vee-validate';
import extendValidationRules from '@/mixins/extendValidationRules';
import Modal from '@/components/Modal/__new__/Modal.vue';
import Btn from '@/components/Button/Button.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBrand, useValidation } from '@/composables';
import UserOption from '@/views/Brand/Applications/Components/Recipients/UserOption.vue';
import { SelectInput } from '@/components/Inputs';
import { mdiMagnify } from '@mdi/js';
import { getFullName } from '@/util/user';
export default defineComponent({
    components: {
        UserOption,
        ValidationObserver,
        ValidationProvider,
        Btn,
        Modal,
        SelectInput,
    },
    mixins: [extendValidationRules],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        selectedUsers: {
            type: Array,
            default: () => [],
        },
        removedUserIds: {
            type: Array,
            default: () => [],
        },
        reportId: {
            type: String,
            default: null,
        },
        creatorId: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        useValidation(ctx);
        const { brand } = useBrand();
        const selectedUser = ref(null);
        const userFormRef = ref(null);
        const addUser = () => {
            ctx.emit('input', selectedUser.value);
            if (props.removedUserIds.some((id) => selectedUser.value?.id === id)) {
                ctx.emit('revert-remove', selectedUser.value?.id);
            }
            selectedUser.value = null;
            if (userFormRef.value)
                userFormRef.value.reset();
        };
        const usersQuery = computed(() => {
            return {
                ...(props.reportId && { relatedReportId: props.reportId }),
                accessToBrandId: brand.value.id,
                omitUserIds: [props.creatorId],
            };
        });
        const isUserSelected = (user) => {
            return (props.selectedUsers.some(({ id }) => user.id === id) &&
                !props.removedUserIds.some((id) => user.id === id));
        };
        return {
            mdiMagnify,
            getFullName,
            selectedUser,
            addUser,
            isUserSelected,
            usersQuery,
            userFormRef,
        };
    },
});
