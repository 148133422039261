export var BASE_INPUT_STYLE;
(function (BASE_INPUT_STYLE) {
    BASE_INPUT_STYLE["DEFAULT"] = "default";
    BASE_INPUT_STYLE["UNDERLINED"] = "underlined";
    BASE_INPUT_STYLE["ROUNDED"] = "rounded";
    BASE_INPUT_STYLE["NONE"] = "none";
})(BASE_INPUT_STYLE || (BASE_INPUT_STYLE = {}));
export const baseInputProps = {
    label: String,
    labelForId: String,
    required: Boolean,
    disabled: Boolean,
    focused: Boolean,
    clearable: Boolean,
    transparent: Boolean,
    error: Boolean,
    prependIcon: [String, Object],
    appendIcon: [String, Object],
    inputStyle: {
        type: String,
        default: BASE_INPUT_STYLE.DEFAULT,
    },
};
