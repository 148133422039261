<template>
  <div class="tree-menu w-full">
    <div v-if="root" class="mb-4">
      <tree-category-root
        :name="label"
        :icon="icon"
        read-only
        :context-menu="{
          edit: {
            name: $t('common.edit'),
            handler: () => {
              onEditCategory({ id: parentId, name: label, iconUrl: icon });
            },
          },
          delete: {
            name: $t('common.delete'),
            handler: () => {
              onDeleteCategory(parentId);
            },
          },
        }"
      />
    </div>
    <div v-else>
      <div
        v-if="!root && label"
        :style="indent"
        :class="{ 'text-primary': !nodes }"
        class="flex items-center font-semibold mb-4"
      >
        <icon
          v-if="
            hasChildren || children.length || (childForms && childForms.length)
          "
          :icon="mdiMenuDown"
          class="mr-2 flex-shrink-0"
          :class="nodes ? 'fill-gray-dark' : 'fill-primary'"
        />
        <div class="px-4 py-2 h-8 flex flex-grow items-center justify-between">
          <span class="cursor-pointer" @click="onExpand(parentId)">
            {{ label }}
          </span>
          <div class="relative">
            <ellipsis-menu class="mr-1 text-primary" :items="items" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="children" class="w-full">
      <tree-category-add
        v-for="node in children"
        :key="node.id"
        :nodes="children"
        :has-children="node.hasChildren"
        :label="node.name"
        :parent-id="node.id"
        :depth="depth + 1"
        @categoryUpdated="onUpdateCategory"
        @editCategory="onEditCategory"
        @addForm="onAddForm"
        @openForm="onOpenForm"
        @forceRerender="forceRerender"
      />
    </div>
    <div
      :style="indentAddNew"
      :class="{ 'text-primary': !nodes }"
      class="flex items-center font-semibold cursor-pointer mb-4"
    >
      <div
        class="px-4 py-2 border border-dashed border-gray-light flex flex-grow items-start justify-between"
      >
        <ul v-if="childForms.length">
          <li
            v-for="(form, index) in childForms"
            :key="form.id"
            class="flex flex-row my-3 form-item"
          >
            <icon
              :icon="DeleteSvg"
              class="delete-icon"
              @click.native="deleteForm(form.id, index)"
            />
            <router-link
              :to="{ name: 'EditForm', params: { formId: form.id } }"
              class="text-primary font-semibold"
            >
              {{ form.title }}
            </router-link>
          </li>
        </ul>
        <div v-else>
          <btn
            v-if="!displayInput"
            theme="secondary"
            class="px-4 whitespace-no-wrap"
            :size="mobileView ? 'sm' : 'md'"
            @click.native="onCreateSubcategory"
          >
            {{ $t('tree.add-subcategory') }}
          </btn>
          <text-input
            v-else
            ref="input"
            v-model="subCategoryName"
            v-click-outside="hideInput"
            tree-category
            class="w-full mr-5"
            @keyup.enter="onAddSubCategory(parentId)"
          />
        </div>

        <ellipsis-menu
          v-if="!hasChildren && !childForms.length"
          class="mr-1 mt-3 text-primary"
          :absolute="false"
          :items="{
            createForm: {
              name: $t('tree.create-form'),
              handler: () => {
                onCreateForm(parentId);
              },
            },
            addForm: {
              name: $t('tree.add-form'),
              handler: () => {
                onAddForm();
              },
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import Btn from '@/components/Button/Button.vue';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { Icon } from '@/components/Icon';
import { TextInput } from '@/components/Inputs';
import TreeCategoryRoot from '@/components/Tree/TreeCategoryRoot';
import { FORM_TYPE } from '@/constants/form';
import { mdiMenuDown } from '@mdi/js';
import DocumentSvg from '@/assets/icons/document.svg';
import DeleteSvg from '@/assets/icons/delete.svg';

export default {
  name: 'TreeCategoryAdd',
  components: {
    Btn,
    EllipsisMenu,
    Icon,
    TextInput,
    TreeCategoryRoot,
  },
  props: {
    label: {
      default: '',
      type: String,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    nodes: {
      default: () => [],
      type: Array,
    },
    depth: {
      type: Number,
      default: 0,
    },
    parentId: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    root: {
      type: Boolean,
    },
  },
  data() {
    return {
      mdiMenuDown,
      DocumentSvg,
      DeleteSvg,
      displayInput: false,
      subCategoryName: '',
      subcategories: [],
      forms: [],
      selectedItems: [],
      childForms: [],
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    ...mapGetters('brand', ['currentBrandId']),
    marginBase() {
      return this.mobileView ? 16 : 32;
    },
    children: {
      get: function () {
        return this.root ? this.nodes : this.subcategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    indent() {
      return { marginLeft: `${this.depth * this.marginBase}px` };
    },
    indentAddNew() {
      return { marginLeft: `${(this.depth + 1) * this.marginBase}px` };
    },
    items() {
      const hasDocument = this.childForms.find(
        (item) => item.category.id === this.parentId,
      );
      return {
        edit: {
          name: this.$t('common.edit'),
          handler: () => {
            this.onEditCategory({ id: this.parentId, name: this.label });
          },
        },
        ...(!(this.hasChildren || hasDocument) && {
          delete: {
            name: this.$t('common.delete'),
            handler: () => {
              this.onDeleteCategory(this.parentId);
            },
          },
        }),
      };
    },
  },
  mounted() {
    const categoryId = this.parentId;

    if (categoryId) {
      this.onExpand(categoryId);
    }
  },
  methods: {
    ...mapActions('brand', [
      'addCategory',
      'addSubCategory',
      'deleteCategory',
      'clearFormCategory',
      'fetchBrandSubCategory',
      'fetchBrandCategoryForms',
    ]),
    ...mapMutations('brand', ['SET_TREE_CATEGORY', 'SET_SELECTED_FORMS']),
    forceRerender() {
      this.$emit('forceRerender');
    },
    async deleteForm(id, index) {
      await this.clearFormCategory(id);
      this.childForms.splice(index, 1);
    },
    async onExpand(categoryId) {
      const { data: subCategoryData } = await this.fetchBrandSubCategory({
        brandId: this.currentBrandId(this.$route),
        categoryId,
      });
      this.subcategories = subCategoryData?.results;

      const { data: formsData } = await this.fetchBrandCategoryForms({
        brandId: this.currentBrandId(this.$route),
        categoryId,
        type: 'report',
      });

      this.childForms = formsData?.results;
    },
    async onAddSubCategory(id) {
      await this.addSubCategory({
        id: id,
        data: {
          name: this.subCategoryName,
        },
      });
      this.onExpand(id);
      this.$emit('categoryUpdated');

      this.hideInput();
    },
    onUpdateCategory() {
      this.$emit('categoryUpdated');
    },
    onCreateSubcategory() {
      this.displayInput = true;
      this.$nextTick(() => {
        this.$refs.input.focusInput();
      });
    },
    hideInput() {
      this.displayInput = false;
      this.subCategoryName = '';
    },
    onEditCategory(data) {
      this.$emit('editCategory', data);
    },
    async onDeleteCategory(id) {
      await this.deleteCategory(id);

      this.$emit('categoryUpdated');
      this.$toast.success(this.$t('categories-view.category-deleted'));
      if (this.depth > 0) {
        this.forceRerender();
      }
    },
    onCreateForm(id) {
      this.SET_TREE_CATEGORY({ data: this.parentId });
      this.$router.push({
        name: 'AddForm',
        params: { categoryId: id, type: FORM_TYPE.REPORT },
      });
    },
    async onAddForm() {
      const categoryId = this.parentId;

      const { data } = await this.fetchBrandCategoryForms({
        brandId: this.currentBrandId(this.$route),
        categoryId,
      });

      this.forms = data?.results;
      this.selectedItems = this.forms?.map((form) => form.id);
      this.SET_SELECTED_FORMS({ data: this.selectedItems });
      this.SET_TREE_CATEGORY({ data: this.parentId });
      this.$emit('openForm');
    },
    onOpenForm() {
      this.$emit('openForm');
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  .delete-icon {
    @apply mr-2 text-primary;
    &:hover {
      @apply opacity-75;
    }
  }
}
</style>
