import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import { Popover } from '@/components/Popover';
import { Btn, BtnTheme } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { mdiDotsVertical } from '@mdi/js';
import { useActionRequired } from '@/views/Brand/Applications/Report/_composables/use-action-required';
import { BottomMenu } from '@/components/Menus';
import { useMobileView } from '@/composables';
const Emit = {
    CREATE_MOBILE_REQUIRED_ACTION: 'create-mobile-required-action',
    CREATE_REQUIRED_ACTION: 'create-required-action',
    DELETE_REQUIRED_ACTION: 'delete-required-action',
    CLOSE_MOBILE_MESSAGE_MENU: 'close-mobile-message-menu',
    DELETE_MESSAGE: 'delete-message',
    ON_EDIT: 'on-edit',
};
export default defineComponent({
    components: { BottomMenu, Icon, Btn, Popover },
    props: {
        showMobileMenu: Boolean,
        isHovered: Boolean,
        isRequiredActionMessage: Boolean,
        isAuthorizedToCreateActionRequired: Boolean,
        disableDelete: Boolean,
        messages: Array,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const { isActionRequiredInReport } = useActionRequired();
        const hasRequiredActionMessage = ref(false);
        const { reportId } = ctx.root.$route.params;
        watch(() => props.messages, async () => {
            if (!reportId) {
                return;
            }
            hasRequiredActionMessage.value = await isActionRequiredInReport(reportId);
        }, { immediate: true });
        const mobileMessageMenuItems = computed(() => {
            return [
                {
                    label: ctx.root.$t('common.delete'),
                    handler: () => ctx.emit(Emit.DELETE_MESSAGE),
                },
                {
                    label: ctx.root.$t('common.edit'),
                    handler: () => ctx.emit(Emit.ON_EDIT),
                },
                ...(props.isRequiredActionMessage
                    ? [
                        {
                            label: ctx.root.$t('report.remove-required-action'),
                            handler: () => ctx.emit(Emit.DELETE_REQUIRED_ACTION),
                            hide: !props.isRequiredActionMessage,
                        },
                    ]
                    : [
                        {
                            label: ctx.root.$t('report.action-required'),
                            handler: () => ctx.emit(Emit.CREATE_REQUIRED_ACTION),
                            hide: !props.isAuthorizedToCreateActionRequired,
                            disabled: hasRequiredActionMessage.value,
                        },
                    ]),
            ];
        });
        return {
            BtnTheme,
            Emit,
            mdiDotsVertical,
            hasRequiredActionMessage,
            mobileMessageMenuItems,
            isMobileView,
        };
    },
});
