<template>
  <content-wrapper
    :query="routeQuery"
    without-horizontal-spacing
    class="md:px-4"
    @select-limit="selectLimit"
  >
    <div>
      <portal :to="PortalTarget.FLOATING_ACTION_BUTTONS">
        <floating-action-button
          v-if="isAuthorizedToManageOffers"
          :icon="mdiPlus"
          @click="$router.push({ name: 'AddOffer' })"
        />
      </portal>

      <offers-table
        :force-update-key="forceUpdateKey"
        :actions="getActions"
        :is-authorized-to-manage-offers="isAuthorizedToManageOffers"
      />
    </div>

    <portal :to="PortalTarget.SIDEBAR">
      <div class="w-full flex justify-center items-center pt-6">
        <add-offer-button
          :is-authorized-to-manage-offers="isAuthorizedToManageOffers"
        />
      </div>
    </portal>
  </content-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import offerStatus from '@/views/Brand/Applications/mixins/offerStatus';
import routeQuery from '@/mixins/query/routeQuery';
import {
  DEFAULT_QUERY_FORMATTERS,
  DEFAULT_VALUE_GETTERS,
} from '@/constants/filters';
import { TABLE_STYLE } from '@/constants/ui';
import { PortalTarget } from '@/constants/portal';
import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper';
import { mdiPlus } from '@mdi/js';
import { useMobileView } from '@/composables';
import { FloatingActionButton } from '@/components/FloatingActionButton';
import OffersTable from '@/views/Brand/Applications/Offer/_components/OffersTable.vue';
import AddOfferButton from '@/views/Brand/Applications/Offer/_components/AddOfferButton.vue';

export default {
  components: {
    OffersTable,
    ContentWrapper,
    FloatingActionButton,
    AddOfferButton,
  },
  mixins: [offerStatus, routeQuery],
  data() {
    return {
      forceUpdateKey: 0,
      selectedOffers: [],
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    ...mapGetters('user', [
      'isContractorUser',
      'hasAdminRights',
      'isSystemAdmin',
    ]),
    ...mapState('user', {
      userBrand: 'brand',
      userRole: 'role',
    }),
    ...mapState('applications', ['statusColors']),
    isAuthorizedToManageOffers() {
      return this.isContractorUser || this.isSystemAdmin;
    },
    brandId() {
      return this.currentBrandId(this.$route);
    },
  },
  methods: {
    ...mapActions('offer', ['editOffer', 'setOfferList']),
    ...mapActions('common', ['delete']),
    ...mapActions('routes', ['setRouteConfig']),
  },
  mounted() {
    this.setRouteConfig({
      route: this.$route,
      config: {
        queryFormatters: DEFAULT_QUERY_FORMATTERS,
        valueGetters: DEFAULT_VALUE_GETTERS,
      },
    });
  },
  setup() {
    const { isMobileView } = useMobileView();

    return { PortalTarget, mdiPlus, TABLE_STYLE, isMobileView };
  },
};
</script>
