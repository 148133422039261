import api from '@/api';
import { getFileUploadProgress } from '@/util/files';
import axios from 'axios';
export var ReportStatus;
(function (ReportStatus) {
    ReportStatus["PENDING"] = "pending";
    ReportStatus["ACTIVE"] = "active";
    ReportStatus["FINISHED"] = "finished";
})(ReportStatus || (ReportStatus = {}));
export var ReportType;
(function (ReportType) {
    ReportType["CHAT"] = "chat";
    ReportType["FORM"] = "form";
})(ReportType || (ReportType = {}));
export const MAX_ASSET_REQUEST_SIZE = 100;
export const createFormReport = async (payload) => {
    const { data } = await api.post('reports/form', payload);
    return data;
};
export const createChatReport = async (payload) => {
    const { data } = await api.post('reports/chat', payload);
    return data;
};
export const getReports = async (params) => {
    const { data } = await api.get('reports', { params });
    return data;
};
export const getSingleReport = async (reportId) => {
    const { data } = await api.get(`reports/${reportId}`);
    return data;
};
export const deleteReport = async (reportId) => {
    const { data } = await api.delete(`reports/${reportId}`);
    return data;
};
export const updateReportUsers = async (reportId, payload) => {
    await api.put(`reports/${reportId}/users`, payload);
};
export const removeUsersFromReport = async (reportId, payload) => {
    await api.delete(`/reports/${reportId}/users`, { data: payload });
};
export const addPlacesToReport = async (reportId, payload) => {
    await api.post(`reports/${reportId}/places`, payload);
};
export const removePlacesFromReport = async (reportId, payload) => {
    await api.delete(`reports/${reportId}/places`, { data: payload });
};
export const addObjectsToReport = async (reportId, payload) => {
    await api.post(`reports/${reportId}/objects`, payload);
};
export const removeObjectsFromReport = async (reportId, payload) => {
    await api.delete(`reports/${reportId}/objects`, { data: payload });
};
export const addReportMessage = async (payload) => {
    const { data } = await api.post('report-messages', payload);
    return data;
};
export const uploadFile = async (payload, fileUploadData) => {
    const formData = new FormData();
    const { file, setFileProgress, cancelToken } = fileUploadData;
    for (const [key, value] of Object.entries(payload.fields)) {
        formData.append(key, value);
    }
    formData.append('file', file);
    await axios.post(payload.url, formData, {
        onUploadProgress: ({ loaded, total }) => {
            setFileProgress({
                name: file.name,
                progress: getFileUploadProgress(loaded, total),
            });
        },
        cancelToken,
    });
};
const createReportFileUpload = async (payload) => {
    const { data } = await api.post('reports/files', payload);
    return data;
};
export const updateReportMessage = async (messageId, payload) => {
    const { data } = await api.patch(`report-messages/${messageId}`, payload);
    return data;
};
export const getReportMessage = async (reportMessageId) => {
    const { data } = await api.get(`report-messages/${reportMessageId}`);
    return data;
};
export const uploadReportFile = async (fileUploadData) => {
    const data = await createReportFileUpload({
        fileName: fileUploadData.file.name,
        fileSize: fileUploadData.file.size,
    });
    await uploadFile(data, fileUploadData);
    return data.uploadToken;
};
export const getReport = async (reportId) => {
    const { data } = await api.get(`reports/${reportId}`);
    return data;
};
export const getReportMessages = async (reportId, params) => {
    const { data } = await api.get(`reports/${reportId}/messages`, {
        params,
    });
    return data;
};
export const getFirstReportMessage = async (reportId) => {
    const { results } = await getReportMessages(reportId, {
        perPage: 1,
    });
    return results[0];
};
export const deleteReportMessage = async (reportMessageId) => {
    await api.delete(`report-messages/${reportMessageId}`);
};
export const updateFormReport = async (reportId, payload) => {
    await api.patch(`reports/form/${reportId}`, payload);
};
export const updateChatReport = async (reportId, payload) => {
    await api.patch(`reports/chat/${reportId}`, payload);
};
export const addDecisionMaker = async (reportId, userId) => {
    await api.post(`reports/${reportId}/users/decision-makers/${userId}`);
};
export const deleteDecisionMaker = async (reportId, userId) => {
    await api.delete(`reports/${reportId}/users/decision-makers/${userId}`);
};
export const updateReportStatus = async (reportId, status) => {
    await api.patch(`reports/${reportId}/status`, { status });
};
export const markReportAsSeen = async (payload) => {
    await api.post(`reports/mark-as-seen`, payload);
};
export const markReportAsUnseen = async (payload) => {
    await api.post(`reports/mark-as-unseen`, payload);
};
export const markReportAsStarred = async (payload) => {
    await api.post(`reports/mark-as-starred`, payload);
};
export const unmarkReportAsStarred = async (payload) => {
    await api.post(`reports/unmark-as-starred`, payload);
};
export const addSupervisorToReport = async (reportId, userId) => {
    await api.post(`reports/${reportId}/users/supervisor/${userId}`);
};
export const deleteSupervisorFromReport = async (reportId, userId) => {
    await api.delete(`reports/${reportId}/users/supervisor/${userId}`);
};
export const getPreviousReport = async (reportId, params) => {
    const { data } = await api.get(`reports/${reportId}/prev`, { params });
    return data;
};
export const getNextReport = async (reportId, params) => {
    const { data } = await api.get(`reports/${reportId}/next`, { params });
    return data;
};
export const getRequiredActionMessages = async (params) => {
    const { data } = await api.get(`/reports/requiring-action/messages`, { params });
    return data;
};
