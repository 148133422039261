import { FORM_ELEMENT_TYPE, FORM_TYPE } from '@/constants/form';
import FetchData from '@/components/DataProvider/FetchData.vue';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import CategoryFormFields from '@/views/Brand/Applications/Components/Categories/CategoryFormFields.vue';
import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import { useBrand } from '@/composables';
import { useAuthUser } from '@/composables/use-auth-user';
import isEqual from 'lodash.isequal';
import { useReportResources } from '@/views/Brand/Applications/Report/_composables/use-report-resources';
export default defineComponent({
    components: {
        CategoryFormFields,
        LoadingSpinner,
        FetchData,
    },
    props: {
        category: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => ({
                form: null,
                values: [],
            }),
        },
        isError: {
            type: [Boolean, String],
            default: null,
        },
        categoryForm: {
            type: Object,
            default: null,
        },
    },
    setup(props, ctx) {
        const { authUserId } = useAuthUser();
        const { brand } = useBrand();
        const { setObjectsRequirement, setPlacesRequirement } = useReportResources();
        const formValues = ref([
            ...(props.value?.values || []),
        ]);
        const defaultFormValues = ref([]);
        const form = ref(props.categoryForm);
        const decisionMakers = ref([]);
        const suggestedContractors = ref([]);
        const params = computed(() => ({
            categoryId: props.category?.id,
            type: FORM_TYPE.REPORT,
        }));
        const getDefaultCheckboxValue = (component) => {
            return JSON.stringify(component.items.map(() => false));
        };
        const getDefaultValue = (component) => {
            return component.type === FORM_ELEMENT_TYPE.CHECKBOX
                ? getDefaultCheckboxValue(component)
                : '';
        };
        const setForm = ({ results }) => {
            const categoryForm = results[0];
            if (categoryForm) {
                form.value = categoryForm;
                setObjectsRequirement(categoryForm.mustContainObjects);
                setPlacesRequirement(categoryForm.mustContainPlaces);
                decisionMakers.value = categoryForm.decisionMakers || [];
                suggestedContractors.value = categoryForm.suggestedContractors || [];
                formValues.value = (form.value?.components || []).map((component) => getDefaultValue(component));
            }
            else {
                decisionMakers.value = [];
                suggestedContractors.value = [];
            }
        };
        const updateValue = () => {
            const payload = form.value
                ? {
                    // TODO: unify DTOs for forms (field is called either versionId  or formVersionId)
                    // @ts-ignore
                    formVersionId: form.value.versionId || form.value.formVersionId,
                    values: formValues.value,
                }
                : null;
            ctx.emit('input', payload);
            if (form.value) {
                ctx.emit('set-decision-makers', decisionMakers.value.filter(({ id }) => id !== authUserId.value));
                ctx.emit('set-contractors', suggestedContractors.value.filter(({ id }) => id !== authUserId.value));
            }
        };
        watch(formValues, (value, prevValue) => {
            if (prevValue.length === 0) {
                defaultFormValues.value = [...value];
            }
            ctx.emit('form-updated', isEqual(defaultFormValues.value, value));
            updateValue();
        }, { deep: true });
        return { formValues, form, brand, params, setForm };
    },
});
