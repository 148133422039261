import debounce from 'lodash.debounce';

const DEFAULT_DEBOUNCE = 300;
const EVENT = 'input';

export default {
  bind(el, { arg, value }) {
    const debounceDelay = arg ? parseInt(arg) : DEFAULT_DEBOUNCE;

    el.debounceInputHandler = debounce(value, debounceDelay);

    el.addEventListener(EVENT, el.debounceInputHandler);
  },
  unbind(el) {
    el.removeEventListener(EVENT, el.debounceInputHandler);
  },
};
