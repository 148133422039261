import { getFullName } from '@/util/user';
import { computed, defineComponent, onBeforeUnmount, } from '@vue/composition-api';
import { SwitchInput, SwitchInputTheme, SwitchSize } from '@/components/Inputs';
import UserAvatar from '@/views/_components/UserAvatar.vue';
import { SYSTEM_ROLES } from '@/store/modules/user';
import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
import { useSupervisor } from '@/views/Brand/Applications/Components/Recipients/UsersList/composables/use-supervisor';
import { useDecisionMaker } from '@/views/Brand/Applications/Components/Recipients/UsersList/composables/use-decision-maker';
import { useMobileView } from '@/composables';
import { UserOptionActionColumn } from '@/views/Brand/Applications/Components/Recipients/UsersList/components/UserOptionActionColumn';
export default defineComponent({
    components: { UserAvatar, SwitchInput, UserOptionActionColumn },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        removedUserIds: {
            type: Array,
            default: () => [],
        },
        decisionMakerIds: {
            type: Array,
            default: () => [],
        },
        removedDecisionMakerIds: {
            type: Array,
            default: () => [],
        },
        showNoDataMessage: {
            type: Boolean,
            default: false,
        },
        reportId: {
            type: String,
            default: null,
        },
        supervisor: {
            type: Object,
            default: null,
        },
        enableSupervisorUpdate: {
            type: Boolean,
            default: false,
        },
        editOnSave: {
            type: Boolean,
            default: false,
        },
        loading: Boolean,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const { toggleSupervisorHandle, isSupervisorToggleDisabled, getSupervisorStatus, showSupervisorToggle, showSupervisorColumn, } = useSupervisor({
            supervisor: props.supervisor,
            enableSupervisorUpdate: props.enableSupervisorUpdate,
            editOnSave: props.editOnSave,
        }, ctx);
        const { toggleDecisionMakerStatus, getDecisionMakerStatus } = useDecisionMaker({
            removedDecisionMakerIds: props.removedDecisionMakerIds,
            editOnSave: props.editOnSave,
        }, ctx);
        const toggleSize = computed(() => isMobileView.value ? SwitchSize.MEDIUM : SwitchSize.SMALL);
        onBeforeUnmount(() => ctx.emit(RECIPIENTS_EVENTS.USERS_LIST_UNMOUNT));
        return {
            SwitchInputTheme,
            SYSTEM_ROLES,
            getSupervisorStatus,
            isSupervisorToggleDisabled,
            toggleSupervisorHandle,
            getDecisionMakerStatus,
            getFullName,
            toggleDecisionMakerStatus,
            showSupervisorToggle,
            showSupervisorColumn,
            isMobileView,
            toggleSize,
        };
    },
});
