<template>
  <div>
    <div
      v-if="category"
      class="card"
      :class="{ active }"
      @click.stop="onClick(category.id)"
    >
      <div class="menu" @click.stop>
        <ellipsis-menu class="mr-1 text-primary" :items="contextMenu" />
      </div>
      <div class="icon-container">
        <div class="card-icon w-26 h-26 flex items-center justify-center">
          <image-view
            :src="icon"
            :alt="$t('common.category-alt', { name })"
            :size="ImageSize.CARD"
            without-padding
          />
        </div>
      </div>
      <div class="name">{{ name }}</div>
    </div>
    <div v-if="active">
      <tree-category
        v-if="children && children.length"
        :nodes="children"
        show-children
        :depth="-1"
        class="mt-6"
      />
      <template v-if="formsList && formsList.length">
        <div
          v-for="form in formsList"
          :key="form.id"
          class="flex mb-4 mt-4 ml-2"
        >
          <icon :icon="DocumentSvg" class="flex-shrink-0 mr-2 text-primary" />
          <router-link
            :to="{ name: 'EditForm', params: { formId: form.id } }"
            class="text-primary font-semibold"
          >
            {{ form.title }}
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { Icon } from '@/components/Icon';
import TreeCategory from './TreeCategory.vue';
import { mapActions, mapGetters } from 'vuex';
import { ImageView, ImageSize } from '@/components/ImageView';
import DocumentSvg from '@/assets/icons/document.svg';

export default {
  name: 'TreeCategoryRoot',
  components: {
    EllipsisMenu,
    Icon,
    TreeCategory,
    ImageView,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: 'client',
    },
    icon: {
      type: String,
      default: null,
    },
    contextMenu: {
      type: Object,
      default() {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DocumentSvg,
      children: null,
      formsList: null,
      ImageSize,
    };
  },
  computed: mapGetters('brand', ['currentBrandId']),
  methods: {
    ...mapActions('brand', [
      'fetchBrandCategoryForms',
      'fetchBrandSubCategory',
    ]),
    async onClick(categoryId) {
      if (this.readOnly) return;

      this.$emit('click', categoryId);

      if (this.active) return;

      const { data: formData } = await this.fetchBrandCategoryForms({
        brandId: this.currentBrandId(this.$route),
        categoryId,
        type: 'report',
      });
      this.formsList = formData?.results;

      const { data: subCategoryData } = await this.fetchBrandSubCategory({
        brandId: this.currentBrandId(this.$route),
        categoryId,
      });
      this.children = subCategoryData?.results;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @apply w-50 h-50 rounded border-solid border border-gray-light box-border outline-none text-gray-darkest bg-white cursor-pointer flex flex-col;
}

.active {
  @apply text-primary border-primary;
}

.menu {
  @apply relative h-8 mb-1 flex-shrink-0 text-center;
}

.icon-container {
  @apply flex items-center justify-center;
}

.card-icon {
  @apply flex justify-center items-center overflow-hidden;
  .image {
    @apply max-w-full max-h-full;
  }
}

.name {
  @apply py-5 font-semibold text-center;
}
</style>
