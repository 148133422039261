export const getMarkerLatLng = (marker) => {
    const { position } = marker;
    if (!position) {
        return null;
    }
    if (position instanceof google.maps.LatLng) {
        return { lat: position.lat(), lng: position.lng() };
    }
    return { lat: position.lat, lng: position.lng };
};
