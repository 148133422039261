import { computed, defineComponent } from '@vue/composition-api';
import { differenceInDays, differenceInHours, differenceInMinutes, } from 'date-fns';
export default defineComponent({
    props: {
        timestamp: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const formattedCreatedAt = computed(() => {
            if (!props.timestamp) {
                return null;
            }
            const currentDate = new Date();
            const givenDate = new Date(props.timestamp);
            const minutesDiff = differenceInMinutes(currentDate, givenDate) || 1;
            const hoursDiff = differenceInHours(currentDate, givenDate) || 1;
            const daysDiff = differenceInDays(currentDate, givenDate) || 1;
            const getMessage = (unit, diff) => ctx.root.$t(`report.send-ago`, {
                count: ctx.root.$tc(`units.${unit}`, diff),
            });
            switch (true) {
                case minutesDiff <= 60:
                    return getMessage('minute', minutesDiff);
                case hoursDiff <= 24:
                    return getMessage('hour', hoursDiff);
                default:
                    return getMessage('day', daysDiff);
            }
        });
        return {
            formattedCreatedAt,
        };
    },
});
