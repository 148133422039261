import PageHeader from '@/components/PageHeader/PageHeader.vue';
import NavigationArrow from '@/components/NavigationArrow/NavigationArrow.vue';
import { computed, defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import { ClientRouteName } from '@/router/router.constants';
import { getNextOffer, getPreviousOffer } from '@/api/requests/offers';
import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
import { useOffer } from '@/composables/user-offer';
export default defineComponent({
    components: { NavigationArrow, PageHeader },
    setup(props, ctx) {
        const { currentOffer } = useOffer();
        const query = ctx.root.$route.query;
        const highlightedRow = ref(null);
        const offers = ref({
            prev: null,
            next: null,
        });
        const offerHeader = computed(() => {
            return `${ctx.root.$t('add-brand-offer-view.offer').toUpperCase()}: ${currentOffer.value.internalNumber}`;
        });
        const getOffers = async () => {
            const params = ctx.root.$route.params;
            const [{ id: prevId }, { id: nextId }] = await Promise.all([
                getPreviousOffer(params.offerId, {
                    brandId: params.id,
                    ...query,
                }),
                getNextOffer(params.offerId, { brandId: params.id, ...query }),
            ]);
            offers.value.prev = prevId;
            offers.value.next = nextId;
        };
        onMounted(() => {
            getOffers();
        });
        watch(() => ctx.root.$route.params, () => {
            getOffers();
        }, { deep: true });
        return {
            highlightedRow,
            query,
            offers,
            ClientRouteName,
            CONTROLLER_DIRECTION,
            currentOffer,
            offerHeader,
        };
    },
});
