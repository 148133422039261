<template>
  <select-input
    id="limit"
    :value="selectedValue"
    :options="sortOptions"
    :input-style="BASE_INPUT_STYLE.ROUNDED"
    hide-autocomplete
    option-value-name="value"
    option-label-name="label"
    class="pagination-select"
    no-margin
    @input="$emit('select-limit', $event.value)"
  />
</template>

<script>
import { SelectInput } from '@/components/Inputs/SelectInput';
import { DEFAULT_LIMIT } from '@/constants/pagination';
import { BASE_INPUT_STYLE } from '@/components/Inputs/_components/BaseInput';

export default {
  name: 'PaginationSelect',
  components: {
    SelectInput,
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    sortOptions() {
      return [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
      ];
    },
    selectedValue() {
      const entries = this.query.limit || DEFAULT_LIMIT;

      return this.sortOptions.find(({ value }) => value === entries);
    },
  },
  mounted() {
    this.$emit('select-limit', this.selectedValue.value);
  },
  setup() {
    return { BASE_INPUT_STYLE };
  },
};
</script>

<style scoped lang="scss">
.pagination-select {
  @apply ml-6;
  width: min-content;
}
</style>
