<template>
  <settings-content-wrapper :query="routeQuery" @select-limit="selectLimit">
    <div>
      <div class="flex flex-wrap gap-2 items-center justify-end">
        <btn v-if="hasAdminRights" class="w-26" :to="{ name: 'AddUser' }">
          {{ $t('common.add') }}
        </btn>
      </div>
      <table-list
        v-if="brand"
        route-component="Users"
        content="users"
        :query="{ brandId: brand.id }"
        :cols="cols"
        :actions="actions"
        :selected-ids="selectedUsers"
        :status-colors="USER_STATUS_COLORS"
        :status-config="statusConfig"
        checkbox
        expandable-rows
        @select="selectedUsers = $event"
      >
        <template #details="{ row }">
          <user-preview :user-id="row ? row.id : ''" />
        </template>
      </table-list>
    </div>
  </settings-content-wrapper>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import TableList from '@/components/Table/TableList.vue';
import UserPreview from '@/views/Brand/Users/UserPreview';

import { mapGetters, mapState, mapActions } from 'vuex';
import { date, uppercase, fontNormal } from '@/util/formatters';
import { USER_STATUS_COLORS } from '@/constants/user';
import routeQuery from '@/mixins/query/routeQuery';
import {
  DEFAULT_QUERY_FORMATTERS,
  DEFAULT_VALUE_GETTERS,
} from '@/constants/filters';
import { useAuthUser } from '@/composables';
import router from '@/router';
import { computed } from '@vue/composition-api';
import { useViewer } from '@/composables/use-viewer';
import SettingsContentWrapper from '@/layouts/BrandLayout/Components/SettingsContentWrapper.vue';

export default {
  name: 'Users',
  components: {
    SettingsContentWrapper,
    Btn,
    TableList,
    UserPreview,
  },
  data() {
    return {
      search: '',
      selectedUsers: [],
      USER_STATUS_COLORS,
      statusConfig: {
        translation: 'user-status',
      },
    };
  },
  mixins: [routeQuery],
  computed: {
    ...mapState('brand', {
      brand: 'details',
      error: 'error',
    }),
    ...mapGetters('user', ['hasAdminRights']),
    cols() {
      return {
        firstName: {
          label: this.$t('brand-users-list-view.first-name'),
          colClass: ['w-24', 'md:w-32', 'xl:w-auto'],
        },
        lastName: {
          label: this.$t('brand-users-list-view.last-name'),
          colClass: ['w-24', 'md:w-32', 'xl:w-auto'],
        },
        internalRole: {
          label: this.$t('brand-users-list-view.internal-role'),
          formatter: (value) => fontNormal(value),
        },
        role: {
          label: this.$t('brand-users-list-view.role'),
          formatter: (el) => uppercase(fontNormal(this.$t(`roles.${el}`))),
        },
        status: {
          label: this.$t('brand-users-list-view.status'),
          status: true,
          disabled: true,
        },
        createdAt: {
          label: this.$t('brand-users-list-view.date'),
          formatter: (value) => fontNormal(date(value)),
        },
      };
    },
  },
  methods: {
    ...mapActions('routes', ['setRouteConfig']),
    onEditBtnClick(id) {
      this.$router.push({ name: 'EditUser', params: { userId: id } });
    },
  },
  mounted() {
    this.setRouteConfig({
      route: this.$route,
      config: {
        queryFormatters: DEFAULT_QUERY_FORMATTERS,
        valueGetters: DEFAULT_VALUE_GETTERS,
      },
    });
  },
  setup(props, ctx) {
    const { isAdmin } = useAuthUser();
    const { previewUser, showUserPreview } = useViewer(ctx);

    const handleEditBtnClick = (id) => {
      router.push({ name: 'EditUser', params: { userId: id } });
    };

    const actions = computed(() => {
      return {
        ...(showUserPreview.value && {
          preview: (id) => previewUser(id, 'Users'),
        }),
        ...(isAdmin && { edit: handleEditBtnClick }),
      };
    });

    return { actions };
  },
};
</script>
