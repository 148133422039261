import { getFullName } from '@/util/user';
import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import Modal from '@/components/Modal/__new__/Modal.vue';
import { Btn, BtnTheme } from '@/components/Button';
import UsersList from '@/views/Brand/Applications/Components/Recipients/UsersList/UsersList.vue';
import AddUserModal from '@/views/Brand/Applications/Components/Recipients/AddUserModal.vue';
import { TextInput } from '@/components/Inputs';
import { mdiMagnify } from '@mdi/js';
import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
export default defineComponent({
    components: {
        AddUserModal,
        Btn,
        Modal,
        UsersList,
        TextInput,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        editOnSave: {
            type: Boolean,
            default: false,
        },
        reportId: {
            type: String,
            default: null,
        },
        removedUserIds: {
            type: Array,
            default: () => [],
        },
        decisionMakerIds: {
            type: Array,
            default: () => [],
        },
        removedDecisionMakerIds: {
            type: Array,
            default: () => [],
        },
        creatorId: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        loading: {
            type: Boolean,
        },
        supervisor: {
            type: Object,
            default: null,
        },
        enableSupervisorUpdate: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const search = ref('');
        const showAddUserModal = ref(false);
        const normalize = (text) => {
            return text.trim().toLocaleLowerCase();
        };
        const filteredUsers = computed(() => {
            if (search.value === '')
                return props.value;
            return props.value.filter((user) => {
                const testValues = [getFullName(user), user.email];
                return testValues.some((value) => {
                    const testValue = normalize(value);
                    const testPhrase = normalize(search.value);
                    return testValue.includes(testPhrase);
                });
            });
        });
        const updateUsers = (user) => {
            if (!props.value.find(({ id }) => id === user.id)) {
                ctx.emit('input', [...props.value, user]);
                ctx.emit('add', user.id);
                showAddUserModal.value = false;
            }
        };
        const deleteUser = (user) => {
            ctx.emit('remove', user.id);
        };
        const usersQuery = computed(() => {
            return {
                ...(search.value && { q: search.value }),
                reportId: props.reportId,
                omitUserIds: [props.creatorId],
            };
        });
        const update = ref(0);
        watch(() => props.loading, () => {
            update.value++;
        });
        return {
            mdiMagnify,
            update,
            BtnTheme,
            search,
            showAddUserModal,
            filteredUsers,
            updateUsers,
            deleteUser,
            usersQuery,
            RECIPIENTS_EVENTS,
        };
    },
});
