import { defineComponent } from '@vue/composition-api';
import { IMAGE_PLACEHOLDER_URL } from '@/constants/common';
import { ImageView, ImageSize, ImageShape } from '@/components/ImageView';
import DownloadButton from '@/views/Brand/Applications/Components/Preview/DownloadButton/DownloadButton.vue';
import { mdiClose, mdiTrayArrowDown } from '@mdi/js';
import { Icon, IconSize } from '@/components/Icon';
export default defineComponent({
    components: { DownloadButton, ImageView, Icon },
    props: {
        image: Object,
        title: {
            type: String,
            default: '',
        },
        caption: {
            type: String,
            default: '',
        },
        disableCloseButton: {
            type: Boolean,
            default: false,
        },
        download: String,
        isSimplified: Boolean,
        closeButton: Boolean,
    },
    setup() {
        return {
            IMAGE_PLACEHOLDER_URL,
            ImageSize,
            ImageShape,
            mdiClose,
            mdiTrayArrowDown,
            IconSize,
        };
    },
});
