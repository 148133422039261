import { ResourceType } from '@/constants/resources';
import Btn from '@/components/Button/Button.vue';
import PlaceTab from '@/views/Brand/Applications/Components/Resources/PlaceTab.vue';
import ObjectTab from '@/views/Brand/Applications/Components/Resources/ObjectTab.vue';
import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import ResourcesWrapper from '@/components/ResourcesWrapper/ResourcesWrapper.vue';
import cloneDeep from 'lodash.clonedeep';
import ResourcesFilters from '@/views/Brand/Applications/Components/Filters/ResourcesFilters.vue';
import isEmpty from 'lodash.isempty';
import { getObjectListQuery, getPlaceListQuery, } from '@/views/Brand/Applications/Components/Resources/resources.utils';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import { BTN_THEMES } from '@/store/modules/ui';
import Modal from '@/components/Modal/__new__/Modal.vue';
export default defineComponent({
    components: {
        ResourcesFilters,
        ResourcesWrapper,
        PlaceTab,
        Modal,
        ObjectTab,
        Btn,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        activeTab: {
            type: String,
            default: ResourceType.PLACES,
        },
        reportId: {
            type: String,
        },
        title: String,
        message: String,
        hideFiltersBtn: Boolean,
        cancelBtn: Boolean,
        hideHeader: Boolean,
    },
    setup(props, ctx) {
        const componentType = ref('modal');
        const tab = ref(props.activeTab);
        const tabs = computed(() => {
            return Object.values(ResourceType).map((tab) => ({
                name: tab,
                label: ctx.root.$t(`resources.${tab}`),
            }));
        });
        watch(() => props.activeTab, (value) => {
            tab.value = value;
        });
        const { flushPendingPlaces, pendingPlaces, addedPlaces } = usePlaceResourcesStore();
        const { flushPendingObjects, pendingObjects } = useObjectResourcesStore();
        const isButtonDisabled = computed(() => {
            return (pendingPlaces.value.length === 0 && pendingObjects.value.length === 0);
        });
        const addResources = () => {
            flushPendingPlaces();
            flushPendingObjects();
            ctx.emit('submit', addedPlaces.value);
            ctx.emit('input', false);
        };
        const toggleModalDisplay = (value) => {
            if (showFilters.value) {
                return (showFilters.value = false);
            }
            ctx.emit('input', value);
        };
        const query = ref({
            [ResourceType.OBJECTS]: {},
            [ResourceType.PLACES]: {},
        });
        const onSearch = (q, tab) => {
            const updatedQuery = cloneDeep(query.value);
            updatedQuery[tab].q = q;
            query.value = updatedQuery;
        };
        const showFilters = ref(false);
        const objectsQuery = computed(() => {
            return getObjectListQuery(query.value[ResourceType.OBJECTS]);
        });
        const placeQuery = computed(() => {
            return getPlaceListQuery(query.value[ResourceType.PLACES]);
        });
        const filtersApplied = computed(() => {
            return !!Object.entries(query.value[tab.value]).find(([key, value]) => key !== 'q' && !isEmpty(value));
        });
        return {
            componentType,
            ResourceType,
            isButtonDisabled,
            tab,
            tabs,
            addResources,
            toggleModalDisplay,
            onSearch,
            query,
            objectsQuery,
            placeQuery,
            showFilters,
            filtersApplied,
            BTN_THEMES,
        };
    },
});
