<template>
  <div class="field">
    <h2 class="field-title">{{ title }}</h2>
    <wysiwyg
      readonly
      dense
      :value="description"
      hide-toolbar
      borderless
      content-class="p-0"
    />
  </div>
</template>
<script>
import Wysiwyg from '@/components/WysiwygInput/WysiwygInput.vue';

export default {
  components: { Wysiwyg },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.field {
  @apply bg-white rounded-md p-3.5 mt-4;

  &-title {
    @apply text-header pb-1 font-semibold;
  }

  &-description {
    @apply text-sm;
  }
}
</style>
