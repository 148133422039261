var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.brand)?_c('fetch-data',{attrs:{"url":"/categories","params":{
      brandId: _vm.brand.id,
      parentId: _vm.parentId,
      hasDescendantWithForm: true,
      limit: 8,
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var results = ref.results;
    var isLoading = ref.isLoading;
    var pagination = ref.pagination;
return [(isLoading)?_c('div',{staticClass:"w-full flex justify-center"},[_c('loading-spinner',{staticClass:"h-10 w-10",attrs:{"is-loading":""}})],1):_vm._e(),_c('category-list',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedCategory),expression:"!selectedCategory"}],attrs:{"categories":results,"loading":_vm.loading,"show-pagination":pagination && pagination.currentPage < pagination.lastPage},on:{"select":function($event){return _vm.$emit('select', $event)},"load-more":function () { return (_vm.showCategoryModal = true); }}})]}}],null,false,2219619443)}):_vm._e(),(_vm.selectedCategory && _vm.selectedCategory.hasChildren)?_c('category-tree',{attrs:{"parent-id":_vm.selectedCategory.id,"selected-categories":_vm.selectedCategories,"level":_vm.level + 1,"loading":_vm.loading},on:{"select":function($event){return _vm.$emit('select', $event)}}}):_vm._e(),(_vm.showCategoryModal)?_c('portal',{attrs:{"to":"modals"}},[_c('category-modal',{attrs:{"id":"category-modal","show":_vm.showCategoryModal,"parent-id":_vm.parentId},on:{"select":_vm.onSelectCategory,"close":function () { return (_vm.showCategoryModal = false); }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }