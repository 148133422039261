import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { computed } from '@vue/composition-api';
const { useState, useActions } = createNamespacedHelpers('ui');
export const useUi = () => {
    const { offsets, sidebar, sideMenu, tabs } = useState([
        'offsets',
        'sidebar',
        'sideMenu',
        'tabs',
    ]);
    const offset = computed(() => offsets.value.navBarBrand);
    const { setOffset, changeTheme, toggleSidebar, toggleSideMenu, setTab } = useActions([
        'setOffset',
        'changeTheme',
        'toggleSidebar',
        'toggleSideMenu',
        'setTab',
    ]);
    return {
        tabs,
        offset,
        sidebar,
        sideMenu,
        changeTheme,
        toggleSidebar,
        toggleSideMenu,
        setOffset,
        setTab,
    };
};
