<template>
  <modal @update:show="$emit('update:show', $event)">
    <h2>{{ content.title }}</h2>
    <p>{{ content.description }}</p>
    <btn class="mt-6 w-48" @click="content.handler">
      {{ content.btnText }}
    </btn>
  </modal>
</template>

<script>
import Btn from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Modal,
    Btn,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('table', ['focusedRowData']),
  },
};
</script>
