<template>
  <div>
    <div class="flex justify-between items-end">
      <form class="w-62">
        <text-input
          v-model="search"
          :placeholder="$t('common.search')"
          :prepend-icon="mdiMagnify"
          clearable
          class="mt-6"
          debounce
        />
      </form>
      <btn class="w-30" type="submit" @click="onAddPlaces">{{ btnText }}</btn>
    </div>

    <table-list
      checkbox
      with-thumbs
      :selected-ids="selectedPlaces"
      content="places"
      :query="computedQuery"
      :cols="columns"
      :per-page="8"
      class="mt-4"
      @select="selectedPlaces = $event"
    />
  </div>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import TableList from '@/components/Table/TableList.vue';
import { TextInput } from '@/components/Inputs';
import { mdiMagnify } from '@mdi/js';
import { fontNormal } from '@/util/formatters';
import { mapState } from 'vuex';

export default {
  components: {
    Btn,
    TableList,
    TextInput,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      selectedPlaces: this.selectedItems,
      mdiMagnify,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      structures: 'structures',
      error: 'error',
    }),
    btnText() {
      const action = this.editMode ? 'save' : 'add';

      return this.$t(`common.${action}`);
    },
    columns() {
      return {
        number: {
          label: this.$t('structures-view.no'),
        },
        name: {
          label: this.$t('structures-view.name'),
        },
        address: {
          label: this.$t('structures-view.address'),
          merge: {
            props: ['address', 'postalCode', 'city'],
            format: `$0, $1 $2`,
          },
          formatter: fontNormal,
        },
      };
    },
    computedQuery() {
      const query = { brandId: this.brand.id };
      if (this.search.length > 0) {
        query.q = this.search;
      }
      return query;
    },
  },
  methods: {
    onAddPlaces() {
      this.$emit('submit', this.selectedPlaces);
    },
  },
};
</script>
<div>

</div>
