import { Icon, IconSize } from '@/components/Icon';
import UsersModal from '@/views/Brand/Applications/Components/Recipients/UsersModal.vue';
import { getFullName } from '@/util/user';
import { defineComponent, ref, computed } from '@vue/composition-api';
import RecipientTags from '@/views/Brand/Applications/Components/Recipients/RecipientTags.vue';
import { mdiPlus } from '@mdi/js';
import { SelectInput } from '@/components/Inputs';
import UserOption from '@/views/Brand/Applications/Components/Recipients/UserOption.vue';
import { useBrand } from '@/composables';
import difference from 'lodash.difference';
export default defineComponent({
    components: {
        RecipientTags,
        UsersModal,
        Icon,
        SelectInput,
        UserOption,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        users: {
            type: Array,
            default: () => [],
        },
        edit: {
            type: Boolean,
            default: false,
        },
        editOnSave: {
            type: Boolean,
            default: false,
        },
        reportId: {
            type: String,
            default: null,
        },
        supervisor: {
            type: Object,
            default: null,
        },
        removedUserIds: {
            type: Array,
            default: () => [],
        },
        decisionMakerIds: {
            type: Array,
            default: () => [],
        },
        removedDecisionMakerIds: {
            type: Array,
            default: () => [],
        },
        creatorId: {
            type: String,
            required: true,
        },
        isCategoryForm: Boolean,
        isCategorySelected: Boolean,
        loadingUsers: Boolean,
    },
    setup(props, ctx) {
        const showUsersModal = ref(false);
        const page = ref(0);
        const assignedUsers = ref([]);
        const search = ref('');
        const { brand } = useBrand();
        const removeUser = (id) => {
            if (props.edit && props.users.some((user) => user.id === id)) {
                ctx.emit('remove-user', id);
            }
            ctx.emit('input', props.value.filter((user) => user.id !== id));
        };
        const showAddUserButton = computed(() => props.isCategorySelected ||
            (!props.isCategoryForm && props.value.length > 0));
        const usersQuery = computed(() => {
            return {
                ...(props.reportId && { relatedReportId: props.reportId }),
                accessToBrandId: brand.value.id,
                omitUserIds: [props.creatorId],
            };
        });
        const onInput = (updatedUsers) => {
            if (props.edit) {
                const removedUsers = difference(props.value, updatedUsers);
                removedUsers.forEach((removedUser) => {
                    if (props.users.some((user) => user.id === removedUser.id)) {
                        ctx.emit('remove-user', removedUser.id);
                    }
                });
                const addedUsers = difference(updatedUsers, props.value);
                addedUsers.forEach((addedUser) => {
                    if (props.users.some((user) => user.id === addedUser.id)) {
                        ctx.emit('revert-remove-user', addedUser.id);
                    }
                });
            }
            ctx.emit('input', updatedUsers || []);
        };
        const isUserSelected = (option) => {
            return props.value.some((user) => user.id === option.id);
        };
        const classNames = computed(() => {
            const classes = [];
            classes.push(props.edit || !showAddUserButton.value ? 'max-w-120' : 'max-w-88');
            if (props.loadingUsers) {
                classes.push('loading');
            }
            return classes;
        });
        const availableRecipients = computed(() => {
            return props.value.filter((recipient) => !props.removedUserIds.includes(recipient.id));
        });
        return {
            availableRecipients,
            mdiPlus,
            IconSize,
            showUsersModal,
            getFullName,
            removeUser,
            page,
            assignedUsers,
            showAddUserButton,
            search,
            usersQuery,
            onInput,
            isUserSelected,
            classNames,
        };
    },
});
