import { defineComponent } from '@vue/composition-api';
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';
import { TimeSinceDisplay } from '@/views/Brand/Applications/Report/_components/time-since-display';
export default defineComponent({
    components: { TimeSinceDisplay, Icon },
    props: {
        user: Object,
        isEdit: Boolean,
        hideText: Boolean,
        createdAt: String,
    },
    setup() {
        return {
            mdiClose,
            IconSize,
        };
    },
});
