import api from '@/api';
import { AZURE_DOMAINS } from '@/store/modules/azure';

export default {
  namespaced: true,
  actions: {
    async delete(ctx, { type, id }) {
      await api.delete(`${type}/${id}`);
    },
  },
  getters: {
    isProdDomain() {
      const PROD_DOMAINS = [...AZURE_DOMAINS, 'https://objek.to'];
      return PROD_DOMAINS.includes(window.location.origin);
    },
  },
};
