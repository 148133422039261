import CategoryList from '@/components/FormElements/CategoryList.vue';
import CategoryTree from '@/views/Brand/Applications/Components/Categories/CategoryTree.vue';
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useBrand } from '@/composables';
export default defineComponent({
    components: { ConfirmModal, CategoryTree, CategoryList },
    props: {
        value: {
            type: Object,
            default: null,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        confirmCategoryUnselect: Boolean,
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const showConfirmModal = ref(false);
        const categoryToUnselect = ref(null);
        const selectedCategories = ref([]);
        watch(selectedCategories, (value) => {
            const lastCategory = value?.[value.length - 1] || null;
            const selectorValue = lastCategory?.hasChildren ? null : lastCategory;
            ctx.emit('input', selectorValue);
        });
        const applyErrorClass = ref(false);
        watch(() => props.isError, (value) => {
            if (value) {
                applyErrorClass.value = true;
                setTimeout(() => {
                    applyErrorClass.value = false;
                    ctx.emit('reset');
                }, 1000);
            }
        });
        const setSelectedCategories = () => {
            const parents = props.value?.parents;
            if (parents?.length) {
                selectedCategories.value = [
                    ...parents
                        // TODO: remove mapping once hasChildren flag is added (BE)
                        .map((category) => ({
                        ...category,
                        hasChildren: true,
                    })),
                    props.value,
                ];
            }
        };
        onMounted(setSelectedCategories);
        watch(() => props.value, setSelectedCategories);
        const onUnselect = (category) => {
            categoryToUnselect.value = category;
            if (props.confirmCategoryUnselect) {
                showConfirmModal.value = true;
            }
            else {
                unselectCategory();
            }
        };
        const onCloseModal = () => {
            showConfirmModal.value = false;
            categoryToUnselect.value = null;
        };
        const unselectCategory = () => {
            const index = selectedCategories.value.findIndex(({ id }) => id === categoryToUnselect?.value?.id);
            selectedCategories.value = selectedCategories.value.slice(0, index);
            onCloseModal();
        };
        return {
            brand,
            showConfirmModal,
            applyErrorClass,
            onUnselect,
            unselectCategory,
            selectedCategories,
            onCloseModal,
        };
    },
});
