import { MapDataType } from '@/components/Map/types';
export const DEFAULT_MAP_POSITION = { lat: 52, lng: 19.5 };
export const DEFAULT_MAP_ZOOM = 6;
export const DEFAULT_SINGLE_MARKER_ZOOM = 15;
export const POSITION_INPUT_FRACTION_DIGITS = 6;
export const DEFAULT_INTERACTIVE_MARKER_PATH = '/img/marker2.svg';
export const DEFAULT_OBJECT_MARKER_COLOR = '#FFAA00';
export const DEFAULT_PLACE_MARKER_COLOR = '#DB0012';
export const DEFAULT_PLACE_MARKER = '/img/marker.svg';
export const DEFAULT_OBJECT_MARKER = '/img/marker2.svg';
export const MARKER_DIRECTION_ARROW = '/img/direction-arrow.svg';
export const MIN_MARKER_OFFSET = 0.00001;
export const MAX_MARKER_OFFSET = 0.0001;
export const CLUSTER_ICON = {
    LABEL_COLOR: '#ffffff',
    LABEL_FONT_SIZE: '12px',
    SIZE: 45,
};
export const CUSTOM_MARKER_OPTIONS = {
    width: 35,
    height: 35,
    padding: 5,
    background: '#ffffff',
    borderRadius: '0.25rem',
};
export const defaultMarkerColorMap = {
    [MapDataType.PLACE]: DEFAULT_PLACE_MARKER_COLOR,
    [MapDataType.OBJECT]: DEFAULT_OBJECT_MARKER_COLOR,
};
