import { TabsPosition, tabsPositionClassMap } from './tabs.constants';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useMobileView } from '@/composables';
export default defineComponent({
    props: {
        position: {
            type: String,
            default: TabsPosition.LEFT,
        },
        activeTab: {
            type: String,
            required: true,
        },
        tabs: {
            type: Object,
            required: true,
        },
        compressedHeader: Boolean,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const activeTabName = ref(props.activeTab ?? Object.keys(props.tabs)[0]);
        const internalTabs = computed(() => {
            return Object.values(props.tabs).map((tab) => {
                return { isActive: tab.name === activeTabName.value, ...tab };
            });
        });
        const selectTab = (selectedTab) => {
            if (!selectedTab.name) {
                return;
            }
            activeTabName.value = selectedTab.name;
            ctx.emit('select-tab', selectedTab);
        };
        return {
            activeTabName,
            TabsPosition,
            tabsPositionClassMap,
            isMobileView,
            selectTab,
            internalTabs,
        };
    },
});
