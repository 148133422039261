var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('settings-content-wrapper',[_c('div',{staticClass:"pb-4 mb-4 flex justify-between h-16 border-b border-b-gray-light"},[_c('breadcrumbs',{staticClass:"self-start",attrs:{"path":[
        {
          name: ("" + (_vm.$t('structures-view.structures'))),
        } ]}}),_c('router-link',{attrs:{"to":{ name: 'AddStructure' }}},[_c('btn',{staticClass:"w-30 self-end"},[_vm._v(_vm._s(_vm.$t('common.add')))])],1)],1),_c('div',{staticClass:"inline-block min-w-full md:min-w-1/2"},[(_vm.loading)?[_c('loading-spinner',{staticClass:"h-10 w-10",attrs:{"is-loading":""}})]:(_vm.error)?[_c('p',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('common.error-api'))+".")])]:[_c('div',{staticClass:"card",class:{ active: _vm.isCardActive },on:{"click":_vm.toggleCardActivity}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.rootStructure.name))]),_c('ellipsis-menu',{staticClass:"mr-1 text-primary",attrs:{"items":{
            edit: {
              name: _vm.$t('common.edit'),
              handler: function () {
                _vm.onEditStructure(null);
              },
            },
          }},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1),(_vm.rootStructureChildren)?_c('tree',{attrs:{"nodes":_vm.rootStructureChildren,"is-expandable":_vm.isNodeExpandable,"load-children":_vm.loadStructureChildren,"load-more":_vm.loadMoreChildren},scopedSlots:_vm._u([{key:"label",fn:function(ref){
          var node = ref.node;
return [_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(node.name))])]}},{key:"append",fn:function(ref){
          var node = ref.node;
return [(node.users.length > 0)?_c('tooltip',{staticClass:"ml-6",attrs:{"label":((node.users[0].firstName) + " " + (node.users[0].lastName)),"append-to-body":""}},[_c('icon',{staticClass:"flex-shrink-0 fill-primary mt-1",attrs:{"icon":_vm.mdiInformationOutline}})],1):_vm._e()]}},{key:"pre-children",fn:function(ref){
          var node = ref.node;
return [(node.places.length > 0)?_c('ul',{staticClass:"text-primary pl-8"},_vm._l((node.places),function(facility,index){return _c('li',{key:index,staticClass:"py-2"},[_c('router-link',{attrs:{"to":{
                  name: 'PlaceDetails',
                  params: { placeId: facility.id },
                }}},[_vm._v(" "+_vm._s(facility.name)+" ")])],1)}),0):_vm._e()]}}],null,false,3713002831)}):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }