import VueStore from '@/store';

export const fetchBrandData = async (route) => {
  const brandId = VueStore.getters['brand/currentBrandId'](route);
  await VueStore.dispatch('brand/fetchBrandInfo', brandId);

  await VueStore.dispatch(
    'ui/changeTheme',
    VueStore.getters['brand/brandTheme'],
  );
};
