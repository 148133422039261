import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        label: String,
        id: {
            type: String,
            required: true,
        },
        required: Boolean,
    },
});
