import { getObjectTypeByBudget } from '@/api/requests/object-types';
import { getStructuresList } from '@/api/requests/structures';
import { ref, watch } from '@vue/composition-api';
import { DEFAULT_STATS } from '@/views/Brand/Places/_components/budget-summary/_constants';
export const useBudgetSummary = (ctx, updatesCounter) => {
    const isLoading = ref(false);
    const summaryStats = ref(DEFAULT_STATS);
    const objectTypes = ref([]);
    const objectTypesStats = ref([]);
    const params = ctx.root.$route.params;
    const brandId = params.id;
    const fetchObjectTypes = async () => {
        try {
            isLoading.value = true;
            const { results } = await getObjectTypeByBudget({
                brandId,
            });
            objectTypes.value = results;
        }
        finally {
            isLoading.value = false;
        }
    };
    const fetchSummaryStats = async () => {
        try {
            isLoading.value = true;
            const { results } = await getStructuresList({
                parentId: 'null',
                brandId,
                withBudget: true,
            });
            const rootStructure = results[0];
            summaryStats.value = {
                monthlyBudget: rootStructure?.totalBudget || 0,
                monthlyExpenses: rootStructure?.expenses || 0,
                budgetUtilization: rootStructure?.budgetUtilization || 0,
                annualExpenses: rootStructure?.expenses
                    ? rootStructure?.expenses * 12
                    : 0,
            };
        }
        finally {
            isLoading.value = false;
        }
    };
    const fetchObjectTypesStats = async () => {
        try {
            isLoading.value = true;
            await Promise.all(objectTypes.value.map(async (objectType) => {
                const { results } = await getStructuresList({
                    parentId: 'null',
                    brandId,
                    objectTypeId: objectType.id,
                    withBudget: true,
                });
                const root = results[0];
                const stats = {
                    id: objectType.id,
                    name: objectType.name,
                    monthlyBudget: root?.totalBudget || 0,
                    monthlyExpenses: root?.expenses || 0,
                    budgetUtilization: root?.budgetUtilization || 0,
                    annualExpenses: root?.expenses ? root.expenses * 12 : 0,
                    objectType: objectType,
                };
                const existingObjectTypeStats = objectTypesStats.value.find((objectTypesStats) => objectTypesStats.id === stats.id);
                if (!existingObjectTypeStats) {
                    return (objectTypesStats.value = [
                        ...objectTypesStats.value,
                        stats,
                    ].sort((a, b) => b.monthlyExpenses - a.monthlyExpenses));
                }
                const updatedItems = objectTypesStats.value.map((objectTypeStats) => {
                    return objectTypeStats.id === stats.id ? stats : objectTypeStats;
                });
                objectTypesStats.value = updatedItems;
            }));
        }
        finally {
            isLoading.value = false;
        }
    };
    const fetchObjectTypesWithStats = async () => {
        await fetchObjectTypes();
        await fetchObjectTypesStats();
    };
    watch(updatesCounter, async () => {
        await Promise.all([fetchSummaryStats(), fetchObjectTypesWithStats()]);
    }, { immediate: true });
    watch(isLoading, () => ctx.emit('is-loading', isLoading));
    return {
        objectTypesStats,
        summaryStats,
        isLoading,
    };
};
