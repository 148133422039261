<template>
  <checkbox-group
    v-if="type === 'checkbox_list'"
    :id="id"
    :value="JSON.parse(value)"
    name="reportFormCheckbox"
    :label="config.title"
    :required="config.isRequired"
    :error="typeof isError === 'boolean' ? isError : undefined"
    :error-message="typeof isError === 'string' ? isError : undefined"
    :items="config.items"
    :disabled="disabled"
    @input="$emit('input', JSON.stringify($event))"
  ></checkbox-group>
  <radio-group
    v-else-if="type === 'radio_list'"
    :id="id"
    name="reportFormRadio"
    :value="value"
    :label="config.title"
    :required="config.isRequired"
    :error="typeof isError === 'boolean' ? isError : undefined"
    :error-message="typeof isError === 'string' ? isError : undefined"
    :items="config.items"
    :disabled="disabled"
    @input="$emit('input', $event)"
  ></radio-group>
  <text-input
    v-else
    type="textarea"
    :value="value"
    :placeholder="config.description"
    :label="config.title"
    :required="config.isRequired"
    :error="typeof isError === 'boolean' ? isError : undefined"
    :error-message="typeof isError === 'string' ? isError : undefined"
    :disabled="disabled"
    class-name="border border-gray-light"
    autosize
    :rows="1"
    @input="$emit('input', $event)"
  ></text-input>
</template>
<script>
import { TextInput, CheckboxGroup, RadioGroup } from '@/components/Inputs';

export default {
  components: { RadioGroup, CheckboxGroup, TextInput },
  props: {
    config: {
      type: Object,
      required: true,
    },
    isError: {
      type: [String, Boolean],
      default: false,
    },
    value: {
      type: [String, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    type() {
      return this.config.type;
    },
  },
};
</script>
