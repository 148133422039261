export default {
  namespaced: true,
  state: {
    scrollToMessageId: null,
  },
  mutations: {
    SET_SCROLL_TO_MESSAGE_ID(state, value) {
      state.scrollToMessageId = value;
    },
  },
  actions: {
    setScrollToMessageId({ commit }, id) {
      commit('SET_SCROLL_TO_MESSAGE_ID', id);
    },
  },
};
