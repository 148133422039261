import { createObjectDetailsRoute } from '@/router/utils';
import { handleTranslations, } from '@/layouts/_components/Notifications/_util/notification-factory';
export const handleObjectCreatedNotification = (notification) => {
    const { type, payload: { brand, object, objectType }, } = notification;
    return {
        getTranslations() {
            const translationPlaceholders = {
                ...object,
            };
            return handleTranslations(type, translationPlaceholders);
        },
        getRoute() {
            return createObjectDetailsRoute(brand.id, object.id, objectType.id);
        },
    };
};
