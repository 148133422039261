<template>
  <div class="xl:grid grid-cols-2 gap-10">
    <expandable-list
      id="place-list"
      v-model="places"
      class="mt-4"
      :class="details ? 'max-h-70' : 'h-70'"
      :url="placeUrl"
      :label="$t('add-brand-report-view.places')"
      :added-items="placesToSelect"
      :deleted-item-ids="removedPlaceIds"
      :fields="placeFields"
      :formatter="({ place }) => place"
      value-field="number"
      :fixed-expanded="!details"
      :read-only="details"
      with-search
      :search-fields="['number', 'name']"
      @remove="updateSelect($event, updatePlaceSelection, false)"
      @undo-remove="updateSelect($event, updatePlaceSelection, true)"
    />

    <template v-if="includeObjects">
      <expandable-list
        id="object-list"
        v-model="objects"
        class="max-h-70 mt-4"
        :url="objectUrl"
        :query="objectQuery"
        :label="$t('add-brand-report-view.objects')"
        value-field="internalNumber"
        :added-items="objectsToSelect"
        :deleted-item-ids="removedObjectIds"
        :fields="objectFields"
        :fixed-expanded="!details"
        :read-only="details"
        with-search
        :search-fields="['internalNumber']"
        @remove="updateSelect($event, updateObjectSelection, false)"
        @undo-remove="updateSelect($event, updateObjectSelection, true)"
      />
    </template>
  </div>
</template>
<script>
import { OBJECT_TAB, PLACE_TAB } from '@/store/modules/ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import ExpandableList from '@/components/Expandable/ExpandableList';

export default {
  components: { ExpandableList },
  props: {
    includeObjects: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      places: '',
      objects: '',
      OBJECT_TAB,
      PLACE_TAB,
      placeFields: [
        {
          key: 'number',
          class: 'font-semibold flex-shrink-0',
        },
        {
          key: 'name',
        },
      ],
      objectFields: [
        {
          key: 'internalNumber',
          class: 'font-semibold flex-shrink-0',
        },
        {
          key: ['address', 'city'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    ...mapGetters('offerResources', ['removedObjectIds', 'removedPlaceIds']),
    ...mapState('offer', ['currentOffer']),
    ...mapState('offerResources', {
      placesToSelect: 'placesToSelect',
      objectsToSelect: 'objectsToSelect',
    }),
    offerObjectQuery() {
      return {
        brandId: this.currentBrandId(this.$route),
        offerId: this.currentOffer.id,
      };
    },
    fetchItems() {
      return this.edit || this.details;
    },
    placeUrl() {
      return this.fetchItems && this.currentOffer
        ? `offers/${this.currentOffer.id}/place-relations`
        : null;
    },
    objectUrl() {
      return this.fetchItems ? `objects` : null;
    },
    objectQuery() {
      return this.currentOffer
        ? {
            offerId: this.currentOffer.id,
          }
        : {};
    },
  },
  methods: {
    ...mapActions('offerResources', [
      'updatePlaceSelection',
      'updateObjectSelection',
      'forceUpdateCheckboxes',
    ]),
    updateSelect(item, callback, selected) {
      this.forceUpdateCheckboxes();

      callback({ item, selected });
    },
  },
};
</script>
