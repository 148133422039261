export default {
  props: {
    statusOptions: {
      type: Function,
      default: () => [],
    },
    statusColors: {
      type: Object,
      default: () => ({}),
    },
    statusConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultStatusConfig: {
        translation: 'report-status',
        disabled: false,
      },
    };
  },
  computed: {
    computedStatusConfig() {
      return {
        ...this.defaultStatusConfig,
        ...this.statusConfig,
      };
    },
  },
  methods: {
    onStatusChange(newStatus, row) {
      this.$emit('change-status', newStatus, row);
    },
  },
};
