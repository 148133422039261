import { defineComponent, ref, computed, watchEffect, onUnmounted, watch, } from '@vue/composition-api';
import Popper from 'popper.js';
import { getUid } from '@/util/common';
import { useToggle } from '@/composables';
export default defineComponent({
    name: 'Menu',
    props: {
        value: Boolean,
        id: String,
        placement: {
            type: String,
            default: 'bottom',
        },
        offset: {
            type: String,
            default: '0, 0',
        },
    },
    setup(props, ctx) {
        const trigger = ref(null);
        const popover = ref(null);
        const { state: isOpened, toggle: togglePopoverVisibility } = useToggle(props.value);
        const generatedId = getUid();
        const parsedId = props.id ?? `popover-${generatedId}`;
        const attrs = computed(() => ({
            'aria-haspopup': 'true',
            'aria-expanded': isOpened.value.toString(),
            'aria-controls': parsedId,
        }));
        const openPopover = () => {
            isOpened.value = true;
        };
        const closePopover = () => {
            isOpened.value = false;
        };
        let popperInstance;
        watchEffect(() => {
            if (trigger.value && popover.value) {
                popperInstance = new Popper(trigger.value, popover.value, {
                    placement: props.placement,
                    removeOnDestroy: true,
                    positionFixed: true,
                    modifiers: {
                        offset: {
                            offset: props.offset,
                        },
                        flip: {
                            enabled: false,
                        },
                        hide: {
                            enabled: false,
                        },
                    },
                });
            }
        });
        watch(() => props.value, () => {
            if (props.value !== isOpened.value)
                togglePopoverVisibility();
        });
        watch(isOpened, (value) => ctx.emit('input', value));
        onUnmounted(() => {
            if (popperInstance) {
                popperInstance.destroy();
            }
        });
        return {
            trigger,
            popover,
            parsedId,
            isOpened,
            attrs,
            openPopover,
            closePopover,
            togglePopoverVisibility,
        };
    },
});
