<template>
  <div class="max-h-screen relative">
    <dashboard-header />
    <main
      class="bg-gray-lighter overflow-auto h-screen"
      :style="{ paddingTop: `${offset}px` }"
    >
      <div v-show="loading" class="mt-56">
        <loading-spinner is-loading />
      </div>
      <router-view v-show="!loading" />
    </main>
  </div>
</template>

<script>
import DashboardHeader from '@/layouts/DashboardLayout/Components/DashboardHeader.vue';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import { mapState } from 'vuex';

export default {
  name: 'DashboardLayout',
  components: {
    DashboardHeader,
    LoadingSpinner,
  },
  computed: {
    ...mapState('ui', {
      offset: (state) => state.offsets.navBar,
    }),
    ...mapState('routes', ['loading']),
  },
};
</script>
