import { computed, defineComponent } from '@vue/composition-api';
import CollapseMenu from '@/components/CollapseMenu/CollapseMenu.vue';
import { MenuItems } from '@/views/Brand/Applications/Components/Sidebar/sidebar.constants';
import ReportMenuItems from '@/views/Brand/Applications/Components/ReportMenu/ReportMenuItems.vue';
export default defineComponent({
    props: {
        actionRequiredMessagesUpdatesCounter: Number,
    },
    components: {
        ReportMenuItems,
        CollapseMenu,
    },
    setup(_, ctx) {
        const menuItems = computed(() => [
            {
                name: MenuItems.REPORTS,
                label: ctx.root.$t('brand-reports-list-view.reports').toString(),
                expanded: true,
            },
        ]);
        return {
            menuItems,
            MenuItems,
        };
    },
});
