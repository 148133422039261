var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('infinite-data',{staticClass:"relative",attrs:{"url":"places","query":_vm.placeQuery,"container-selector":_vm.containerSelector,"enable-query-watcher":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [(items && items.length)?_c('table-display',{attrs:{"rows":items,"columns":_vm.columns,"is-row-expanded":_vm.isPlaceDetailsExpanded,"table-style":_vm.TableStyle.Resources,"row-size":_vm.getPlaceRowSize()},on:{"record-click":function($event){return _vm.$emit('open', $event.id)}},scopedSlots:_vm._u([{key:"expandButtonCell",fn:function(ref){
var row = ref.row;
return [_c('expand-button',{attrs:{"is-expanded":_vm.isPlaceDetailsExpanded(row),"button-style":_vm.ExpandButtonStyle.ARROW},on:{"expand":function($event){return _vm.toggleExpandPlaceDetails(row)}}})]}},{key:"checkboxCell",fn:function(ref){
var row = ref.row;
return [_c('checkbox',{attrs:{"table":"","value":_vm.isPlaceSelected(row)},on:{"input":function($event){return _vm.onSelectPlace(row)}}})]}},{key:"expandedRow",fn:function(ref){
var row = ref.row;
return [_c('place-preview',{attrs:{"place-id":row ? row.id : '',"hide-details-btn":"","sidebar":""}})]}}],null,true)}):_vm._e()]}},{key:"no-results",fn:function(){return [_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('common.no-results'))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }