import { getUsersList } from '@/api/requests/users';
import { ref, watch } from '@vue/composition-api';
import difference from 'lodash/difference';
import { useAuthUser } from '@/composables/use-auth-user';
import { loadAllResults } from '@/api/requests/pagination';
import { getPropertyArray } from '@/util/formatters';
export const useReportRecipients = (report) => {
    const decisionMakerIds = ref([]);
    const users = ref([]);
    const assignedUsers = ref([]);
    const removedUserIds = ref([]);
    const removedDecisionMakerIds = ref([]);
    const { authUserId } = useAuthUser();
    const loadingUsers = ref(false);
    const supervisor = ref(report?.supervisor || null);
    watch(users, (value, prevValue) => {
        const removedUsers = difference(prevValue, value);
        decisionMakerIds.value = decisionMakerIds.value.filter((id) => !removedUsers.some((user) => user.id === id));
    });
    const addFormUsers = (formUsers) => {
        const newUsers = formUsers.filter((formUser) => !users.value.some((user) => user.id === formUser.id));
        users.value = [...users.value, ...newUsers];
    };
    const addFormDecisionMakers = (formDecisionMakers) => {
        addFormUsers(formDecisionMakers);
        const newDecisionMakerIds = formDecisionMakers
            .filter((decisionMaker) => !decisionMakerIds.value.includes(decisionMaker.id))
            .map(({ id }) => id);
        decisionMakerIds.value = [
            ...decisionMakerIds.value,
            ...newDecisionMakerIds,
        ];
    };
    const onAddUser = (user) => {
        const isReportCreator = (report && report.creator.id === user.id) || user.id === authUserId.value;
        if (isReportCreator) {
            return;
        }
        const isRemoved = removedUserIds?.value.find((id) => id === user.id);
        const isAdded = user.isRelatedToReport || users.value.find(({ id }) => id === user.id);
        if (isRemoved) {
            removedUserIds.value = removedUserIds.value.filter((id) => id !== user.id);
        }
        else if (!isAdded)
            users.value.push(user);
    };
    const removeUser = (id) => {
        removedUserIds.value.push(id);
        if (decisionMakerIds.value.includes(id))
            decisionMakerIds.value = decisionMakerIds.value.filter((item) => item !== id);
        else if (removedDecisionMakerIds.value.includes(id))
            removedDecisionMakerIds.value = removedDecisionMakerIds.value.filter((item) => item !== id);
    };
    const revertRemoveUser = (id) => {
        removedUserIds.value = removedUserIds.value.filter((userId) => userId !== id);
    };
    const onSelectUsers = (updatedUsers) => {
        users.value = updatedUsers;
    };
    const removeUsers = (usersToRemove) => {
        usersToRemove.forEach((user) => removeUser(user.id));
    };
    const onSetUsers = (usersToSet) => {
        users.value = usersToSet;
    };
    const loadAllUsers = async (reportId, creatorId) => {
        loadingUsers.value = true;
        try {
            users.value = await loadAllResults(getUsersList, {
                reportId: reportId,
                omitUserIds: [creatorId],
            });
            const initialDecisionMakers = getPropertyArray(users.value.filter((user) => user.isReportDecisionMaker), 'id');
            decisionMakerIds.value = initialDecisionMakers;
            assignedUsers.value = [...users.value];
        }
        finally {
            loadingUsers.value = false;
        }
    };
    return {
        users,
        assignedUsers,
        decisionMakerIds,
        removedUserIds,
        removedDecisionMakerIds,
        onAddUser,
        addFormDecisionMakers,
        addFormUsers,
        removeUser,
        revertRemoveUser,
        onSelectUsers,
        onSetUsers,
        loadAllUsers,
        loadingUsers,
        removeUsers,
        supervisor,
    };
};
