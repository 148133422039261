import { defineComponent, ref, computed, onMounted, } from '@vue/composition-api';
import { getUid } from '@/util/common';
import debounce from 'lodash.debounce';
import autosize from 'autosize';
import { Debounce } from '@/constants/debounce';
import { FormElement, formElementProps, } from '@/components/Inputs/_components/FormElement';
import { BaseInput, baseInputProps, } from '@/components/Inputs/_components/BaseInput';
import { mdiClose } from '@mdi/js';
export default defineComponent({
    name: 'TextInput',
    components: { FormElement, BaseInput },
    props: {
        value: null,
        type: {
            type: String,
            default: 'text',
        },
        id: String,
        readonly: Boolean,
        debounce: Boolean,
        delay: {
            type: Number,
            default: Debounce.DEFAULT,
        },
        autosize: Boolean,
        autofocus: Boolean,
        rounded: Boolean,
        ...formElementProps,
        ...baseInputProps,
    },
    setup(props, ctx) {
        const parsedListeners = computed(() => {
            // eslint-disable-next-line no-unused-vars
            const { input, focus, blur, ...rest } = ctx.listeners;
            return rest;
        });
        const input = ref(null);
        const isFocused = ref(false);
        const debouncedEvent = ref(null);
        const isTextarea = computed(() => props.type === 'textarea');
        const tag = computed(() => isTextarea.value ? 'textarea' : 'input');
        const parsedId = computed(() => {
            return props.id ?? `input-${getUid()}`;
        });
        const onInput = (e) => {
            const target = e.target;
            if (!props.debounce) {
                ctx.emit('input', target.value);
            }
            else {
                debouncedEvent.value = debounce(() => {
                    ctx.emit('input', target.value);
                }, props.delay);
                debouncedEvent.value();
            }
        };
        const onFocus = () => {
            ctx.emit('focus');
            isFocused.value = true;
        };
        const onBlur = () => {
            if (debouncedEvent.value) {
                debouncedEvent.value.flush();
            }
            ctx.emit('blur');
            isFocused.value = false;
        };
        const appendClearIcon = computed(() => {
            if (props.clearable && props.value) {
                return mdiClose;
            }
            return null;
        });
        onMounted(() => {
            if (props.autosize && isTextarea.value) {
                autosize(input.value);
            }
            if (props.autofocus) {
                input.value.focus();
            }
        });
        return {
            parsedListeners,
            isFocused,
            input,
            tag,
            parsedId,
            isTextarea,
            appendClearIcon,
            onInput,
            onFocus,
            onBlur,
        };
    },
});
