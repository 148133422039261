import { computed, defineComponent } from '@vue/composition-api';
import { mdiViewListOutline, mdiViewGridOutline } from '@mdi/js';
import { Icon } from '@/components/Icon';
import { ListViewType } from './listViewSelect.constants';
export default defineComponent({
    components: { Icon },
    props: {
        items: {
            type: Object,
            required: true,
        },
        activeView: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const iconMap = {
            [ListViewType.BASIC]: mdiViewListOutline,
            [ListViewType.TILES]: mdiViewGridOutline,
        };
        const listViewItems = computed(() => {
            return Object.entries(props.items).map(([key, value]) => {
                return {
                    active: key === props.activeView,
                    icon: iconMap[key],
                    handler: value,
                };
            });
        });
        return {
            mdiViewGridOutline,
            mdiViewListOutline,
            listViewItems,
            ListViewType,
        };
    },
});
