import { useResources } from '@/store/_composables/use-resources';
const useObjectResources = () => {
    const { selectionMode: objectSelectionMode, setSelectionMode: setObjectSelectionMode, selectionKey: objectSelectionKey, setSelectionKey: setObjectSelectionKey, existingResources: existingObjects, setExistingResources: setExistingObjects, addedResources: addedObjects, removedResources: removedObjects, pendingResources: pendingObjects, clearPendingResources: clearPendingObjects, resolvedResources: resolvedObjects, removeResource: removeObject, toggleResource: toggleObject, isResourceSelected: isObjectSelected, onResourceSelection: onObjectSelection, flushPendingResources: flushPendingObjects, resetResourcesState: resetObjectsState, } = useResources();
    return {
        objectSelectionMode,
        setObjectSelectionMode,
        objectSelectionKey,
        setObjectSelectionKey,
        existingObjects,
        addedObjects,
        removedObjects,
        pendingObjects,
        clearPendingObjects,
        resolvedObjects,
        removeObject,
        toggleObject,
        setExistingObjects,
        isObjectSelected,
        onObjectSelection,
        flushPendingObjects,
        resetObjectsState,
    };
};
let objectResourcesStore = null;
export const useObjectResourcesStore = () => {
    if (objectResourcesStore === null) {
        objectResourcesStore = useObjectResources();
    }
    return objectResourcesStore;
};
export const destroyObjectResourcesStore = () => {
    objectResourcesStore = null;
};
