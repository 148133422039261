<template>
  <div
    :class="{
      [inputStyle]: true,
      'input-focus': isFocused,
      'input-error': isError,
    }"
  >
    <label v-if="label" :for="id" class="flex">
      <span class="text-input-label">
        {{ label }}
        <span v-if="label && required" class="required">*</span>
      </span>
      <strong v-if="helperLabel" class="text-input-helper-label">
        {{ helperLabel }}
      </strong>
    </label>
    <div class="input-wrapper">
      <input
        v-if="type !== 'textarea'"
        :id="id"
        ref="input"
        class="text-input input-border"
        :class="[
          { error: isError },
          treeCategory ? 'input-tree' : '',
          className,
        ]"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        :min="min"
        :max="max"
        :name="name"
        :step="step"
        @change="handleInput"
        @input="handleInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <textarea
        v-else
        :id="id"
        ref="input"
        class="text-input text-area"
        :class="[
          { error: isError },
          treeCategory ? 'input-tree' : '',
          className,
        ]"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :rows="rows"
        :disabled="disabled"
        @change="handleInput"
        @input="handleInput"
        @focus="onFocus"
        @blur="onBlur"
      ></textarea>
      <span v-if="errorMsg" class="text-input-error">
        {{ errorMsg }}
      </span>
      <div class="icon-wrapper">
        <icon
          v-if="icon"
          :icon="icon"
          class="input-icon"
          @click.native="$refs.input.focus()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import debounce from 'lodash.debounce';
import autosize from 'autosize';
import { INPUT_STYLE } from '@/store/modules/ui';

export default {
  components: { Icon },
  props: {
    inputStyle: {
      type: String,
      default: INPUT_STYLE.DEFAULT,
      validate: (value) => Object.entries(INPUT_STYLE).includes(value),
    },
    value: {
      type: [String, Number],
      default: '',
    },
    icon: {
      type: [String, Object],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    helperLabel: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
      required: false,
    },
    className: {
      type: String,
      default: null,
    },
    listenOn: {
      type: String,
      default: 'change',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isError: {
      type: [String, Boolean],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: String,
      default: null,
      required: false,
    },
    max: {
      type: String,
      default: null,
      required: false,
    },
    step: {
      type: String,
      default: null,
      required: false,
    },
    treeCategory: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 300,
    },
    autosize: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: [Number, String],
      default: null,
      required: false,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    id() {
      if (this.label) return this.label.replace(/[^A-Z0-9]/gi, '_');
      return false;
    },
    debouncedEmit() {
      return debounce((ev, val) => {
        this.$emit(ev, val);
      }, this.delay);
    },
    computedListenOn() {
      return this.debounce ? 'input' : this.listenOn;
    },
    errorMsg() {
      return typeof this.isError === 'string' ? this.isError : '';
    },
  },
  mounted() {
    if (this.autosize && this.type === 'textarea') {
      autosize(this.$refs.input);
    }
  },
  methods: {
    getValue(target) {
      const { value, valueAsNumber } = target;

      return this.type === 'number' ? valueAsNumber : value;
    },
    handleInput(e) {
      const value = this.getValue(e.target);

      if (this.computedListenOn === e.type) {
        if (this.debounce) this.debouncedEmit('input', value);
        else this.$emit('input', value);
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    onFocus(e) {
      this.$emit('focus', e);
      this.isFocused = true;
    },
    onBlur(e) {
      this.$emit('blur', e);
      this.isFocused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mixins/text-input/style.scss';
</style>
