var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('with-side-navigations',{attrs:{"view-width":50,"side-bar-width":50,"side":"right","footer":""},scopedSlots:_vm._u([{key:"sideNavigation",fn:function(ref){
var setButton = ref.setButton;
return [_c('div',[_c('tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(_vm.userFormOpened)?[_c('tab',{attrs:{"name":_vm.$t('edit-place-view.add-user'),"reference":_vm.USER_TAB}},[_c('add-user-tab',{ref:"userForm",on:{"close-user-tab":function($event){return _vm.closeUserTab(setButton)},"add-user":_vm.onAddUser}})],1)]:[_c('tab',{attrs:{"name":_vm.$t('edit-place-view.coordinates'),"reference":_vm.COORDINATES_TAB}},[_c('clustered-map',{attrs:{"interactive":"","interactive-marker":{ icon: _vm.DEFAULT_PLACE_MARKER },"disabled-settings":[
                _vm.MapSettings.SHOW_OBJECT_NUMBER_LABEL,
                _vm.MapSettings.SHOW_PLACE_NUMBER_LABEL ]},model:{value:(_vm.coordinates),callback:function ($$v) {_vm.coordinates=$$v},expression:"coordinates"}})],1),(_vm.attachments.multimedia)?_c('tab',{attrs:{"name":_vm.$t('edit-place-view.multimedia'),"reference":_vm.MULTIMEDIA_TAB}},[_c('file-uploader',{attrs:{"list":_vm.multimedia,"type":"multimedia","target":"places"}})],1):_vm._e(),(_vm.attachments.document)?_c('tab',{attrs:{"name":_vm.$t('edit-place-view.documents'),"reference":_vm.DOCUMENTS_TAB}},[_c('file-uploader',{attrs:{"list":_vm.docs,"type":"document","target":"places"}})],1):_vm._e()]],2)],1)]}},{key:"default",fn:function(ref){
              var setButton = ref.setButton;
return [_c('div',{staticClass:"container-fluid bg-neutral"},[_c('breadcrumbs',{staticClass:"mt-2 mb-1",attrs:{"path":[
          { name: _vm.$t('edit-place-view.places'), url: _vm.placesRoute },
          { name: _vm.brand ? _vm.brand.name : '', url: _vm.placesRoute },
          { name: _vm.$t('edit-place-view.edit-place') } ]}}),(_vm.place)?_c('place-form',{attrs:{"id":"placeForm","users-list-key":_vm.usersListKey,"brand":_vm.brand,"content":_vm.computedPlace,"clear":_vm.placeSubmissionEndSuccessfully,"coordinates":_vm.coordinates},on:{"on-submit":_vm.handlePlaceSubmission,"open-user-form":function($event){return _vm.openUserForm(setButton)},"set-tab":function($event){_vm.activeTab = $event},"set-coordinates":function($event){_vm.coordinates = $event}}}):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex"},[_c('btn',{staticClass:"w-48 mr-3",attrs:{"is-loading":_vm.placeSubmissionInProgress,"type":"submit","form":"placeForm"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1),_c('btn',{attrs:{"theme":"none"},on:{"click":function($event){return _vm.$router.push(_vm.placesRoute)}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }