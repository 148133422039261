<template>
  <div class="px-10 bg-neutral mt-5 pb-36">
    <h1 class="text-3xl">
      {{
        mode === FORM_MODE.ADD ? $t('forms.add-form') : $t('forms.edit-form')
      }}
      {{ $t(`forms.form-${formType}`) }}
    </h1>
    <form-fields
      id="custom-forms"
      :form-type="formType"
      :category-id="categoryId"
      :last-route="lastRoute"
      :mode="mode"
      :loading="loading"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';
import { FORM_MODE, FORM_TYPE } from '@/constants/form';
import FormFields from '@/views/Brand/Settings/Forms/Components/FormFields';
import isEmpty from 'lodash.isempty';

export default {
  components: {
    FormFields,
  },
  props: {
    type: {
      type: String,
      default: '',
      validate: (type) => Object.values(FORM_TYPE).includes(type),
    },
    categoryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      FORM_MODE,
      FORM_TYPE,
      mode: FORM_MODE.ADD,
      loading: false,
      lastRoute: { name: 'Forms' },
      formType: this.type,
    };
  },
  computed: {
    ...mapState('brand', {
      currentCategory: 'currentCategory',
      currentForm: 'currentForm',
    }),
  },
  async beforeRouteEnter(to, from, next) {
    const brandId = VueStore.getters['brand/currentBrandId'](to);
    const category = VueStore.state['brand/currentCategory'];

    if (category) {
      await VueStore.dispatch('brand/fetchBrandCategory', {
        brandId,
        categoryId: category.parentId,
      });
    }

    next((vm) => {
      if (to.params.formId) vm.mode = FORM_MODE.EDIT;
      if (from.path !== '/') vm.lastRoute = from;
    });
  },
  beforeRouteLeave(from, to, next) {
    VueStore.commit('brand/SET_CURRENT_FORM', { data: null });
    VueStore.commit('brand/SET_CURRENT_OFFER_FORM', { data: null });
    next();
  },
  async created() {
    const { formId } = this.$route.params;

    if (formId) {
      this.loading = true;

      try {
        await this.fetchForm(formId);

        this.formType = this.currentForm.type;
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('brand', ['fetchForm', 'addForm', 'editForm']),
    redirect() {
      this.$router.push(this.lastRoute);
    },
    onSubmitOfferForm(payload) {
      const isNewForm =
        this.mode === FORM_MODE.ADD ||
        (this.currentForm && !this.currentForm.offerFormId);

      const callback = isNewForm ? this.addForm : this.editForm;

      return callback(payload);
    },
    async onSubmitMainForm(payload) {
      const callback =
        this.mode === FORM_MODE.ADD ? this.addForm : this.editForm;

      await callback(payload);
    },
    async onSubmit({ addOffer, payload }) {
      this.loading = true;
      const mainPayload = { ...payload[this.formType] };

      try {
        if (addOffer && !isEmpty(payload[FORM_TYPE.OFFER].data)) {
          const { data } = await this.onSubmitOfferForm(
            payload[FORM_TYPE.OFFER],
          );

          if (
            this.mode === FORM_MODE.ADD ||
            this.currentForm.offerFormId !== data.id
          )
            mainPayload.data.offerFormId = data.id;
        }

        if (!isEmpty(mainPayload.data))
          await this.onSubmitMainForm(mainPayload);

        this.$toast.success(this.$t('forms.added-successfully'));

        this.redirect();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
