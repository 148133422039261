import { defineComponent, ref, computed, watch, } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { mdiDotsHorizontal, mdiMenuDown, mdiMenuRight } from '@mdi/js';
export default defineComponent({
    name: 'TreeNode',
    components: {
        Icon,
        Btn,
        Tooltip,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        isExpandable: {
            type: Function,
            required: true,
        },
        loadChildren: {
            type: Function,
            required: true,
        },
        loadMore: {
            type: Function,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        },
        silentUpdate: Function,
        reloadCount: Number,
        disabled: Boolean,
        updateCount: Number,
    },
    setup(props, ctx) {
        const loading = ref(false);
        const children = ref(null);
        const pagination = ref(null);
        const showChildren = ref(false);
        const arrowIcon = computed(() => showChildren.value ? mdiMenuDown : mdiMenuRight);
        const toggleChildrenVisibility = async () => {
            if (props.disabled)
                return;
            if (children.value === null) {
                const data = await props.loadChildren(props.node);
                children.value = data.children;
                pagination.value = data.pagination;
            }
            showChildren.value = !showChildren.value;
        };
        const loadMoreChildren = async () => {
            loading.value = true;
            const data = await props.loadMore(props.node, pagination);
            loading.value = false;
            children.value = [...(children.value || []), ...data.children];
            pagination.value = data.pagination;
        };
        const itemStyle = computed(() => {
            return {
                paddingLeft: `${props.level * 2}rem`,
            };
        });
        const getAllLoadedNodes = async () => {
            const promises = [props.loadChildren(props.node)];
            if (pagination.value) {
                promises.push(...Array.from({
                    length: pagination.value.currentPage - 1,
                }, (_, index) => {
                    return props.loadMore(props.node, ref({
                        ...pagination.value,
                        currentPage: index + 1,
                    }));
                }));
            }
            const result = await Promise.all(promises);
            return result.reduce((acc, node) => [...acc, ...(node?.children || [])], []);
        };
        const updateChildrenSilently = async () => {
            const updatedChildren = await getAllLoadedNodes();
            if (props.silentUpdate && children.value) {
                props.silentUpdate(children, updatedChildren);
            }
        };
        watch(() => props.reloadCount, async () => {
            await updateChildrenSilently();
            reloadChildrenCount.value++;
        });
        watch(() => props.updateCount, updateChildrenSilently);
        const reloadChildrenCount = ref(0);
        const onReloadData = async () => {
            await updateChildrenSilently();
            ctx.emit('reload-data');
        };
        const reloadNodeData = async () => {
            await updateChildrenSilently();
            reloadChildrenCount.value++;
            ctx.emit('reload-data');
        };
        return {
            mdiDotsHorizontal,
            showChildren,
            arrowIcon,
            children,
            pagination,
            loading,
            toggleChildrenVisibility,
            loadMoreChildren,
            itemStyle,
            onReloadData,
            reloadChildrenCount,
            reloadNodeData,
        };
    },
});
