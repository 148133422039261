import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { computed } from '@vue/composition-api';
import { REPORT_STATUS_COLORS } from '@/constants/report';
import { deleteReport, markReportAsUnseen, ReportStatus, updateReportStatus, } from '@/api/requests/reports';
const { useGetters: useUserGetters } = createNamespacedHelpers('user');
export const useReportStatus = ({ hideEditAction, update }, ctx) => {
    const { isAuthorizedToMakeChanges } = useUserGetters([
        'isAuthorizedToMakeChanges',
    ]);
    const statusConfig = computed(() => {
        return {
            translation: 'report-status',
        };
    });
    const getStatusOptions = (currentStatus) => {
        const statuses = Object.values(ReportStatus);
        return statuses.filter((status) => status !== currentStatus);
    };
    const changeReportStatus = async (reportId, status) => {
        await updateReportStatus(reportId, status);
        update();
    };
    const archiveReport = async (id) => {
        await deleteReport(id);
        update();
    };
    const markAsUnseen = async (id) => {
        await markReportAsUnseen({ reportIds: [id] });
        update();
    };
    const getActions = (row) => {
        const { status, creator } = row;
        const isFinished = status === ReportStatus.FINISHED;
        return {
            archive: {
                name: ctx.root.$i18n.t('ellipsis-menu.archive'),
                handler: () => archiveReport(row.id),
            },
            ...(!isFinished && {
                finish: {
                    name: ctx.root.$i18n.t('ellipsis-menu.finish'),
                    handler: () => changeReportStatus(row.id, ReportStatus.FINISHED),
                },
            }),
            ...(isAuthorizedToMakeChanges.value([creator.id]) &&
                !hideEditAction && {
                edit: {
                    name: ctx.root.$i18n.t('ellipsis-menu.edit'),
                    handler: () => ctx.emit('edit', row.id),
                },
            }),
            ...(row.isSeen && {
                unseen: {
                    name: ctx.root.$i18n.t('ellipsis-menu.mark-as-unseen'),
                    handler: () => markAsUnseen(row.id),
                },
            }),
        };
    };
    return {
        REPORT_STATUS_COLORS,
        getStatusOptions,
        statusConfig,
        getActions,
        changeReportStatus,
    };
};
