import { computed, defineComponent } from '@vue/composition-api';
import { getFullName, getRoleTranslationKey } from '@/util/user';
import { format, isThisYear, isToday } from 'date-fns';
import { getDateFnsLocale } from '@/i18n';
import UserAvatar from '@/views/_components/UserAvatar.vue';
import { mdiPhone, mdiPhoneOutline } from '@mdi/js';
import { IconSize } from '@/components/Icon';
import Icon from '@/components/Icon/Icon.vue';
export default defineComponent({
    name: 'ChatUserArea',
    components: { Icon, UserAvatar },
    props: {
        user: {
            type: Object,
            required: true,
        },
        updatedAt: {
            type: Date,
            required: true,
        },
        showPhoneNumber: Boolean,
    },
    setup(props) {
        const parsedUpdatedAt = computed(() => {
            if (isToday(props.updatedAt)) {
                return format(props.updatedAt, 'p', { locale: getDateFnsLocale() });
            }
            if (isThisYear(props.updatedAt)) {
                return format(props.updatedAt, 'PPPP', { locale: getDateFnsLocale() });
            }
            return format(props.updatedAt, 'PPP', { locale: getDateFnsLocale() });
        });
        return {
            getFullName,
            getRoleTranslationKey,
            parsedUpdatedAt,
            mdiPhone,
            IconSize,
            mdiPhoneOutline,
        };
    },
});
