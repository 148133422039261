<template>
  <div class="container-fluid">
    <div class="md:w-1/2 pt-8 pb-48 px-4 md:px-12">
      <breadcrumbs
        :path="[
          { name: $t('add-brand-user-view.users'), url: { name: 'Users' } },
          { name: brand ? brand.name : '', url: brandRoute },
          { name: $t('add-brand-user-view.add-user') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <user-form
        id="brandUserForm"
        :key="clearCounter"
        :is-loading="submissionInProgress"
        @submit="handleSubmission"
      />
    </div>
    <footer-menu class="bg-white">
      <div class="flex justify-center flex-wrap md:justify-between">
        <div class="flex">
          <btn
            :is-loading="redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="brandUserForm"
            class="w-30 mr-1 md:w-48 md:mr-3"
            @click="redirectAfterSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
          <btn
            :is-loading="!redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="brandUserForm"
            class="w-30 mr-1 md:w-48 md:mr-3"
            theme="secondary"
            :compact="mobileView"
            @click="redirectAfterSubmission = false"
          >
            {{ $t('brand-user-form.save-and-add-another') }}
          </btn>
        </div>
        <btn theme="none" class="mt-2 xs:mt-0" :to="{ name: 'Users' }">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import UserForm from '@/views/Brand/Users/Forms/UserForm.vue';
import { mapActions, mapState } from 'vuex';
import { PLACE_ADMIN, STRUCTURE_ADMIN } from '@/store/modules/user';
import { createClientUser } from '@/api/requests/users';

export default {
  name: 'AddUser',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    UserForm,
  },
  data() {
    return {
      redirectAfterSubmission: false,
      submissionInProgress: false,
      submissionEndSuccessfully: false,
      clearCounter: 0,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    brandRoute() {
      if (this.brand) return { name: 'Users', params: { id: this.brand.id } };
      else return {};
    },
  },
  methods: {
    ...mapActions('brand', ['addBrandUser', 'updateBrandPlace']),
    ...mapActions('structures', ['updateStructureUser']),
    getPlacePayload(place, user) {
      return {
        placeId: place.id,
        data: { userId: user.id },
      };
    },
    getStructurePayload(structure, user) {
      return {
        id: structure.id,
        userId: user.id,
      };
    },
    getRoleActions(role) {
      switch (role) {
        case PLACE_ADMIN:
          return {
            getPayload: this.getPlacePayload,
            update: this.updateBrandPlace,
          };
        case STRUCTURE_ADMIN:
          return {
            getPayload: this.getStructurePayload,
            update: this.updateStructureUser,
          };
        default:
          return {};
      }
    },
    async handleSubmission(formData) {
      this.submissionInProgress = true;
      try {
        await createClientUser({ ...formData, brandId: this.brand.id });

        this.$toast.success(this.$t('add-brand-user-view.added-successfully'));
        if (this.redirectAfterSubmission) {
          await this.$router.push(this.brandRoute);
        }
        this.clearCounter += 1;
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
