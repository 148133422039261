export const loadAllResults = async (getItemsListFn, params) => {
    const loadedResults = [];
    let currentPage = 1;
    for (;;) {
        // @ts-ignore // TODO can we somehow enforce in TS that QueryParams must be an object type containing { limit?: number; page?: number }
        const { results } = await getItemsListFn({
            ...params,
            limit: 100,
            page: currentPage,
        });
        loadedResults.push(...results);
        if (results.length < 100) {
            return loadedResults;
        }
        currentPage += 1;
    }
};
