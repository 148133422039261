import { defineComponent } from '@vue/composition-api';
import InfoBar from '@/components/InfoBar/InfoBar.vue';
import { useViewer } from '@/composables/use-viewer';
import { mdiExitToApp } from '@mdi/js';
import { useMobileView } from '@/composables';
import { Icon } from '@/components/Icon';
export default defineComponent({
    components: { InfoBar, Icon },
    props: {
        sidebar: Boolean,
    },
    setup(props, ctx) {
        const { viewer, exitPreviewMode, isPreviewMode } = useViewer(ctx);
        const { isMobileView } = useMobileView();
        return {
            isPreviewMode,
            viewer,
            isMobileView,
            exitPreviewMode,
            mdiExitToApp,
        };
    },
});
