import { ref } from '@vue/composition-api';
export const useReportPopup = (initialState = false) => {
    const closeReportRequest = ref(0);
    const closeReportCallback = ref(() => { });
    const reportPopup = ref(initialState);
    const onChangeRoute = (next) => {
        if (reportPopup.value) {
            closeReportRequest.value++;
            closeReportCallback.value = next;
        }
        else {
            next();
        }
    };
    const onCloseReportPopup = () => {
        closeReportRequest.value++;
        closeReportCallback.value = () => (reportPopup.value = false);
    };
    return {
        reportPopup,
        closeReportCallback,
        closeReportRequest,
        onCloseReportPopup,
        onChangeRoute,
    };
};
