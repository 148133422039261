import api from '@/api';

export default {
  namespaced: true,
  state: {
    details: null,
    error: false,
    brands: [],
  },
  mutations: {
    SET_CLIENT(state, { data }) {
      state.details = data;
      state.error = false;
    },
    SET_BRANDS(state, { data }) {
      state.brands = data?.results || data;
      state.error = false;
    },
    SET_ERROR(state) {
      state.error = true;
    },
  },
  actions: {
    addClient(ctx, params) {
      return api.post('/organizations', params);
    },
    async updateClient({ commit }, { id, data }) {
      commit('SET_CLIENT', await api.patch(`/organizations/${id}`, data));
    },
    async fetchClientDetails({ commit }, id) {
      commit('SET_CLIENT', { data: null });
      try {
        commit('SET_CLIENT', await api.get(`/organizations/${id}`));
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    removeClient(ctx, id) {
      return api.delete(`/organizations/${id}`);
    },
    async fetchClientBrands({ commit }, id) {
      commit('SET_BRANDS', { data: null });
      try {
        commit(
          'SET_BRANDS',
          await api.get('/brands', { params: { limit: 30, clientId: id } }),
        );
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
  },
};
