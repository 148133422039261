import { ref, computed, readonly } from '@vue/composition-api';
export var SelectionMode;
(function (SelectionMode) {
    SelectionMode["INSTANT"] = "INSTANT";
    SelectionMode["DELAYED"] = "DELAYED";
})(SelectionMode || (SelectionMode = {}));
const useResourcesList = () => {
    const resources = ref([]);
    const setResources = (newResources) => {
        resources.value = newResources;
    };
    const clearResources = () => {
        resources.value = [];
    };
    const isInResources = (resource) => resources.value.some(({ id }) => id === resource.id);
    const removeFromResources = (resource) => {
        resources.value = resources.value.filter(({ id }) => id !== resource.id);
    };
    return {
        resources,
        setResources,
        clearResources,
        isInResources,
        removeFromResources,
    };
};
export const useResources = () => {
    const selectionMode = ref(SelectionMode.DELAYED);
    const setSelectionMode = (newSelectionMode) => {
        selectionMode.value = newSelectionMode;
    };
    // selectionKey should be used if there is a list with pagination that has info if relation with some entity exists (relatedReportId)
    // if selectionKey will be `null` then existingResources data will be used to determine existing relations
    const selectionKey = ref(null);
    const setSelectionKey = (newSelectionKey) => {
        selectionKey.value = newSelectionKey;
    };
    const { resources: existingResources, setResources: setExistingResources, clearResources: clearExistingResources, isInResources: isInExistingResources, } = useResourcesList();
    const { resources: addedResources, clearResources: clearAddedResources, isInResources: isInAddedResources, removeFromResources: removeFromAddedResources, } = useResourcesList();
    const { resources: removedResources, clearResources: clearRemovedResources, isInResources: isInRemovedResources, removeFromResources: removeFromRemovedResources, } = useResourcesList();
    const { resources: pendingResources, clearResources: clearPendingResources, isInResources: isInPendingResources, removeFromResources: removeFromPendingResources, } = useResourcesList();
    const resolvedResources = computed(() => {
        const resources = existingResources.value.filter((existingResource) => !isInRemovedResources(existingResource));
        resources.push(...addedResources.value);
        return resources;
    });
    const removeResource = (resource) => {
        if (isInAddedResources(resource)) {
            removeFromAddedResources(resource);
        }
        else {
            removedResources.value = [...removedResources.value, resource];
        }
    };
    const toggleResource = (resource) => {
        if (isInAddedResources(resource)) {
            return removeFromAddedResources(resource);
        }
        if (isInRemovedResources(resource)) {
            return removeFromRemovedResources(resource);
        }
        removedResources.value = [...removedResources.value, resource];
    };
    const initialResourceState = (resource) => {
        if (selectionKey.value === null) {
            return isInExistingResources(resource);
        }
        return Boolean(resource[selectionKey.value]);
    };
    const isResourceSelected = (resource) => {
        if (isInPendingResources(resource)) {
            return !initialResourceState(resource);
        }
        if (isInRemovedResources(resource)) {
            return false;
        }
        if (isInAddedResources(resource)) {
            return true;
        }
        return initialResourceState(resource);
    };
    const applySelectionChanges = (affectedResources) => {
        for (const affectedResource of affectedResources) {
            if (isInRemovedResources(affectedResource)) {
                removeFromRemovedResources(affectedResource);
                continue;
            }
            if (isInAddedResources(affectedResource)) {
                removeFromAddedResources(affectedResource);
                continue;
            }
            if (initialResourceState(affectedResource)) {
                removedResources.value = [...removedResources.value, affectedResource];
                continue;
            }
            addedResources.value = [...addedResources.value, affectedResource];
        }
    };
    const onResourceSelection = (affectedIds, affectedResources) => {
        if (selectionMode.value === SelectionMode.INSTANT) {
            return applySelectionChanges(affectedResources);
        }
        for (const affectedResource of affectedResources) {
            if (isInPendingResources(affectedResource)) {
                removeFromPendingResources(affectedResource);
                continue;
            }
            pendingResources.value = [...pendingResources.value, affectedResource];
        }
    };
    const flushPendingResources = () => {
        applySelectionChanges(pendingResources.value);
        pendingResources.value = [];
    };
    const resetResourcesState = () => {
        selectionKey.value = null;
        clearExistingResources();
        clearAddedResources();
        clearRemovedResources();
        clearPendingResources();
    };
    return {
        selectionMode: readonly(selectionMode),
        setSelectionMode,
        selectionKey: readonly(selectionKey),
        setSelectionKey,
        existingResources: readonly(existingResources),
        setExistingResources,
        addedResources: readonly(addedResources),
        removedResources: readonly(removedResources),
        pendingResources: readonly(pendingResources),
        clearPendingResources,
        resolvedResources,
        removeResource,
        toggleResource,
        isResourceSelected,
        onResourceSelection,
        flushPendingResources,
        resetResourcesState,
    };
};
