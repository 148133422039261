import { MapDataType, } from '@/components/Map/types';
import { getNestedValue } from '@/util/common';
import { rotateSVG, setSvgFillColor, stringifyAsset } from '@/util/svg';
import { DEFAULT_OBJECT_MARKER, DEFAULT_PLACE_MARKER, defaultMarkerColorMap, MapSettings, MARKER_DIRECTION_ARROW, } from '@/components/Map';
import { useMapState } from '@/components/Map/composables/use-map-state';
export const useMarkerIcon = () => {
    const { settings } = useMapState();
    const getMarkerColor = (dataSet, mapDataSetItem) => {
        const { colorAccessor, type } = dataSet;
        const { color } = mapDataSetItem;
        if (colorAccessor) {
            const nestedColor = getNestedValue(mapDataSetItem, colorAccessor);
            if (nestedColor) {
                return nestedColor;
            }
        }
        return (color ?? defaultMarkerColorMap[type]);
    };
    const getCustomMarkerIcon = ({ iconAccessor }, mapDataSetItem) => {
        if (mapDataSetItem.iconUrl) {
            return mapDataSetItem.iconUrl;
        }
        if (!iconAccessor) {
            return null;
        }
        return getNestedValue(mapDataSetItem, iconAccessor);
    };
    const getColoredDefaultMarkerIcon = (svg, color) => {
        return setSvgFillColor(svg, color);
    };
    const getPreparedMarkerIcons = async () => {
        const directionMarker = await stringifyAsset(MARKER_DIRECTION_ARROW);
        const defaultMarkersMap = {
            [MapDataType.OBJECT]: await stringifyAsset(DEFAULT_OBJECT_MARKER),
            [MapDataType.PLACE]: await stringifyAsset(DEFAULT_PLACE_MARKER),
        };
        return { directionMarker, defaultMarkersMap };
    };
    const resolveMarkerIcon = (mapDataSetItem, dataSet, { defaultMarkersMap, directionMarker }) => {
        const color = getMarkerColor(dataSet, mapDataSetItem);
        const isDirectionIcon = mapDataSetItem.directionAngle != null &&
            settings.value.get(MapSettings.SHOW_OBJECT_DIRECTION);
        const isCustomIcon = Boolean(mapDataSetItem.iconUrl || dataSet.iconAccessor);
        switch (true) {
            case isDirectionIcon:
                return rotateSVG(directionMarker, mapDataSetItem.directionAngle);
            case isCustomIcon:
                return getCustomMarkerIcon(dataSet, mapDataSetItem);
            default:
                return getColoredDefaultMarkerIcon(defaultMarkersMap[dataSet.type], color);
        }
    };
    return {
        resolveMarkerIcon,
        getPreparedMarkerIcons,
        getMarkerColor,
    };
};
