import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useState, useActions } = createNamespacedHelpers('chat');
export const useChat = () => {
    const { scrollToMessageId } = useState(['scrollToMessageId']);
    const { setScrollToMessageId } = useActions(['setScrollToMessageId']);
    return {
        scrollToMessageId,
        setScrollToMessageId,
    };
};
