import { VueRenderer } from '@tiptap/vue-2';
import tippy, { sticky } from 'tippy.js';
import MentionList from '@/components/WysiwygInput/_components/MentionList.vue';
import { Keys } from '@/components/WysiwygInput/wysiwyg.constants';
import { useMobileView } from '@/composables';
export const suggestion = (parent, getMentionSuggestions) => {
    const { isMobileView } = useMobileView();
    const componentInstance = new VueRenderer(MentionList, {
        parent,
        propsData: {
            items: [],
            command: () => { },
        },
    });
    let popup;
    const mobileSuggestionClass = 'bg-white overflow-auto max-h-52 border-gray border -ml-4 w-screen absolute left-0 bottom-0';
    const suggestionConfig = {
        allowSpaces: false,
        items: async ({ query }) => await getMentionSuggestions(query),
        render: () => {
            return {
                onStart: (props) => {
                    componentInstance.updateProps(props);
                    if (isMobileView) {
                        componentInstance.element.className = mobileSuggestionClass;
                    }
                    // The Tiptap library has API compatible with tippy - it doesn't provide the reference element which causes undesired behaviour in Popper.js
                    const [tippyInstance] = tippy('body', {
                        // @ts-ignore
                        getReferenceClientRect: props.clientRect,
                        appendTo: () => document.body,
                        content: componentInstance.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'top-start',
                        sticky: 'reference',
                        plugins: [sticky],
                    });
                    popup = tippyInstance;
                },
                onUpdate(props) {
                    componentInstance.updateProps(props);
                    popup.setProps({
                        // @ts-ignore
                        getReferenceClientRect: props.clientRect,
                    });
                },
                onKeyDown(props) {
                    if (props.event.key === Keys.ESCAPE) {
                        popup.hide();
                        return true;
                    }
                    // @ts-ignore
                    return componentInstance.ref?.onKeyDown(props);
                },
                onExit() {
                    popup.destroy();
                },
            };
        },
    };
    return {
        mentionComponent: componentInstance.ref,
        suggestionConfig,
    };
};
