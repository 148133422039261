import { ref } from '@vue/composition-api';
export const useExpandDetails = (mapToStringValue) => {
    const expandedIds = ref([]);
    const isExpanded = (item) => {
        return expandedIds.value.some((id) => id === mapToStringValue(item));
    };
    const toggleExpandedItems = (item) => {
        if (isExpanded(item))
            expandedIds.value = expandedIds.value.filter((id) => id !== mapToStringValue(item));
        else
            expandedIds.value.push(mapToStringValue(item));
    };
    return {
        expandedIds,
        toggleExpandedItems,
        isExpanded,
    };
};
