import api from '@/api';
import { STRUCTURE_ADMIN, PLACE_ADMIN } from '@/store/modules/user';

export const REPORT_FORM_TYPE = 'report';

export const ADMIN_ACCESS = 'admin_access';
export const CONTRACTOR_ACCESS = 'contractor_access';
export const DEFAULT_ACCESS = 'default_access';
export const PLACE_ACCESS = 'place_access';

export const REPORT_STATUS_MAP = {
  PENDING: 'pending',
  ACTIVE: 'active',
  FINISHED: 'finished',
};

// TODO: statuses will be updated in separate PR
// TODO: additional statuses: new, action
export const REPORT_STATUS_COLORS = {
  active: 'bg-green',
  approved: 'bg-green',
  in_progress: 'bg-green',
  finished: 'bg-white border border-gray-darker',
  rejected: 'bg-red',
  canceled: 'bg-red',
  archived: 'bg-gray-dark',
};

export default {
  namespaced: true,
  state: {
    currentReport: null,
    projectData: {
      ids: [],
      name: '',
    },
    projectReports: [],
    project: null,
    statusColors: {
      active: {
        color: 'text-white',
        borderColor: 'border-green',
        backgroundColor: 'bg-green',
        customClass: 'min-w-24 font-semibold',
      },
      approved: {
        color: 'text-white',
        borderColor: 'border-green',
        backgroundColor: 'bg-green',
        customClass: 'min-w-24 font-semibold',
      },
      in_progress: {
        color: 'text-white',
        borderColor: 'border-green',
        backgroundColor: 'bg-green',
        customClass: 'min-w-24 font-semibold',
      },
      finished: {
        color: 'text-white',
        borderColor: 'border-blue-500',
        backgroundColor: 'bg-blue-500',
        customClass: 'min-w-24 font-semibold',
      },
      rejected: {
        color: 'text-white',
        borderColor: 'border-red',
        backgroundColor: 'bg-red',
        customClass: 'min-w-24 font-semibold',
      },
      canceled: {
        color: 'text-white',
        borderColor: 'border-red',
        backgroundColor: 'bg-red',
        customClass: 'min-w-24 font-semibold',
      },
      archived: {},
    },
  },
  mutations: {
    SET_CURRENT_REPORT(state, { data }) {
      state.currentReport = data;
    },
    SET_NEW_PROJECT_DATA(state, data) {
      state.projectData = data;
    },
    SET_PROJECT(state, { data }) {
      const name = data ? data.name : '';

      state.project = data;

      state.projectData.name = name;
    },
    SET_PROJECT_REPORTS(state, { data }) {
      const reports = data ? data.results : [];

      state.projectReports = reports;

      state.projectData.ids = reports.map((entry) => entry.id);
    },
  },
  actions: {
    addReport(ctx, data) {
      return api.post('/reports', data);
    },
    editReport(ctx, { data, reportId }) {
      return api.patch(`/reports/${reportId}`, data);
    },
    async fetchReport({ commit }, reportId) {
      const report = await api.get(`reports/${reportId}`);
      commit('SET_CURRENT_REPORT', report);

      return report;
    },
    getBrandPlace(ctx, id) {
      return api.get(`/places/${id}`);
    },
    getBrandCategory(ctx, id) {
      return api.get(`/categories/${id}`);
    },
    getBrandUser(ctx, id) {
      return api.get(`/users/${id}`);
    },
    getBrandStructure(ctx, id) {
      return api.get(`/structures/${id}`);
    },
    addReportComment(ctx, { data, reportId }) {
      return api.post(`/reports/${reportId}/comments`, data);
    },
    setProjectData({ commit }, data) {
      commit('SET_NEW_PROJECT_DATA', data);
    },
    fetchObject(ctx, objectId) {
      return api.get(`objects/${objectId}`);
    },
    async fetchProject({ commit }, id) {
      commit('SET_PROJECT', await api.get(`projects/${id}`));
    },
    async fetchProjectReports({ commit }, projectId) {
      commit(
        'SET_PROJECT_REPORTS',
        await api.get(`projects/${projectId}/reports`),
      );
    },
    clearState({ commit }) {
      commit('SET_PROJECT', { data: null });
      commit('SET_PROJECT_REPORTS', { data: null });
      commit('SET_CURRENT_REPORT', { data: null });
    },
    clearProject({ commit }) {
      commit('SET_NEW_PROJECT_DATA', { ids: [], title: [] });
      commit('SET_PROJECT', { data: null });
      commit('SET_PROJECT_REPORTS', { data: null });
    },
    addProject(ctx, { brandId, name }) {
      return api.post('projects', { name, brandId });
    },
    async updateProjectReports(ctx, { id, data }) {
      await api.put(`/projects/${id}/reports`, data);
    },
    editProject(ctx, { id, data }) {
      return api.patch(`projects/${id}`, data);
    },
    fetchStructurePlaces(ctx, { id, params }) {
      params.append('structureId', id);

      return api.get('/places', { params });
    },
    async fetchRootStructure(ctx, brandId) {
      const params = new URLSearchParams();
      params.append('parentId', null);
      params.append('brandId', brandId);

      const { data } = await api.get('/structures', { params });

      const { results } = data;

      return results[0];
    },
    fetchForm(ctx, id) {
      return api.get(`forms/${id}`);
    },
    fetchBrandForms(ctx, { brandId, params }) {
      return api.get(`brands/${brandId}/forms`, { params });
    },
    createAttachmentRelation(ctx, { reportId, attachmentId }) {
      return api.post(`reports/${reportId}/attachments/${attachmentId}`);
    },
    async fetchUserStructure({ rootState, dispatch }) {
      if (!rootState.user.id) return null;

      const { data } = await api.get(`users/${rootState.user.id}`);

      const { role, places, structures } = data;
      const { structureId: placeStructureId } = places[0] || {};

      switch (role) {
        case STRUCTURE_ADMIN:
          return structures[0] || null;
        case PLACE_ADMIN:
          if (placeStructureId) {
            const { data } = await dispatch(
              'getBrandStructure',
              placeStructureId,
            );

            return data;
          }
          return null;
        default:
          return null;
      }
    },
  },
};
