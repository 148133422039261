import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'ProgressBar',
    props: {
        uploadingFileProgress: {
            type: [Number, null],
            default: null,
        },
        isFileUploaded: {
            type: Boolean,
            required: true,
        },
    },
});
