import { defineComponent, ref, watch } from '@vue/composition-api';
import { TextInput, SelectInput, CheckboxGroup } from '@/components/Inputs';
import { useBrand } from '@/composables';
import { OrganizationType } from '@/api/requests/organizations';
import { ObjectStatus } from '@/api/requests/objects';
import { Debounce } from '@/constants/debounce';
import { EMPTY_OBJECT_FILTERS, } from '@/views/Brand/Applications/Components/Resources/resources.constants';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
export default defineComponent({
    components: {
        CheckboxGroup,
        SelectInput,
        TextInput,
    },
    props: {
        value: {
            type: Object,
            default: () => ({ ...EMPTY_OBJECT_FILTERS }),
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const objectFilters = ref(cloneDeep(props.value));
        watch(() => props.value, (value, prevValue) => {
            // Equality check to prevent emitting input event due to changed reference & causing infinite loop
            if (!isEqual(value, prevValue))
                objectFilters.value = cloneDeep(value);
        });
        watch(objectFilters, (value) => ctx.emit('input', cloneDeep(value)), {
            deep: true,
        });
        return {
            Debounce,
            ObjectStatus,
            OrganizationType,
            brandId: brand.value?.id,
            objectFilters,
        };
    },
});
