import { defineComponent } from '@vue/composition-api';
import { OfferObjectStatus } from '@/api/requests/offers';
import { Icon, IconSize } from '@/components/Icon';
import { mdiCheck, mdiClose, mdiRestore } from '@mdi/js';
import { Popover, useHoverPopover } from '@/components/Popover';
export default defineComponent({
    components: { Icon, Popover },
    props: {
        canUserRevertAction: Boolean,
        loading: Boolean,
        status: String,
        user: String,
        date: String,
    },
    setup(props) {
        const { popover, onMouseEnter, onMouseLeave } = useHoverPopover();
        const handleMouseLeave = () => {
            if (!props.canUserRevertAction) {
                onMouseLeave();
            }
        };
        const handleMouseEnter = () => {
            if (!props.canUserRevertAction) {
                onMouseEnter();
            }
        };
        return {
            mdiCheck,
            mdiClose,
            mdiRestore,
            IconSize,
            OfferObjectStatus,
            popover,
            handleMouseEnter,
            handleMouseLeave,
        };
    },
});
