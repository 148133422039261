import { ref } from '@vue/composition-api';
import { DEFAULT_OBJECT_MARKER, DEFAULT_PLACE_MARKER, MARKER_DIRECTION_ARROW, } from '@/components/Map/constants/common';
import isEmpty from 'lodash.isempty';
import { useMapState } from '@/components/Map/composables/use-map-state';
import { dataSetTypeSettingMap, MapSettings } from '@/components/Map/constants';
import { rotateSVG, setSvgFillColor, stringifyAsset } from '@/util/svg';
export const useMapRoutes = (props) => {
    const directionsService = ref(null);
    const directionsRenderer = ref(null);
    const infoWindow = ref(null);
    const markers = ref([]);
    const { settings } = useMapState();
    const { map } = props;
    const initializeDirections = () => {
        directionsService.value = new google.maps.DirectionsService();
        directionsRenderer.value = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
        });
        directionsRenderer.value.setMap(map.value);
    };
    const createLatLngPosition = (position) => {
        return new google.maps.LatLng(position.lat, position.lng);
    };
    const clearMarkers = () => {
        if (isEmpty(markers.value)) {
            return;
        }
        markers.value.map((marker) => {
            marker.map = null;
        });
    };
    const getRouteMarkerIcon = async (icon, color) => {
        const stringifiedMarker = await stringifyAsset(icon);
        if (!color) {
            return icon;
        }
        return setSvgFillColor(stringifiedMarker, color);
    };
    const getMarkerLabel = (mapRoute) => {
        const { label, labelFormatter, type } = mapRoute;
        if (!label || !settings.value.get(dataSetTypeSettingMap[type])) {
            return null;
        }
        return labelFormatter ? labelFormatter(label) : label.toString();
    };
    const resolveRouteMarkerIcon = (directionAngle, directionMarker, icon) => {
        const isDirectionIcon = directionAngle != null &&
            settings.value.get(MapSettings.SHOW_OBJECT_DIRECTION);
        if (isDirectionIcon) {
            return rotateSVG(directionMarker, directionAngle);
        }
        return icon;
    };
    const buildContent = (route) => {
        const label = getMarkerLabel(route);
        const content = document.createElement('div');
        const labelElement = label
            ? `<div class="bg-white rounded-1 p-0.5 shadow-card font-semibold text-center" style="font-size: 0.8rem">${label}</div>`
            : '';
        content.innerHTML = `
      ${labelElement}
      <img src="${route.icon}" class="mx-auto" />
    `;
        return content;
    };
    const initializeRouteMarkers = async (origin, destination) => {
        const originMarker = new google.maps.marker.AdvancedMarkerElement({
            position: origin.position,
            map: map.value,
            content: buildContent(origin),
        });
        const destinationMarker = new google.maps.marker.AdvancedMarkerElement({
            position: destination.position,
            map: map.value,
            content: buildContent(destination),
        });
        markers.value.push(originMarker, destinationMarker);
    };
    const createInfoWindow = () => {
        const directions = directionsRenderer.value?.getDirections();
        const route = directions?.routes[0].legs[0];
        const distance = route?.distance?.text;
        const duration = route?.duration?.text;
        const path = directions?.routes[0].overview_path || [];
        const midpoint = path[Math.round(path?.length / 2)];
        return new google.maps.InfoWindow({
            content: `${distance} <br /> ${duration}`,
            position: midpoint,
        });
    };
    const getRoutesIcons = async (routeStart, routeEnd) => {
        const originIcon = await getRouteMarkerIcon(DEFAULT_OBJECT_MARKER, routeStart.markerColor);
        const destinationIcon = await getRouteMarkerIcon(DEFAULT_PLACE_MARKER, routeEnd.markerColor);
        return { originIcon, destinationIcon };
    };
    const getRoutesPositions = (routeStart, routeEnd) => {
        const originPosition = createLatLngPosition(routeStart.position);
        const destinationPosition = createLatLngPosition(routeEnd.position);
        return { originPosition, destinationPosition };
    };
    const calculateAndDisplayRoute = async (routeStart, routeEnd) => {
        clearMarkers();
        const directionMarker = await stringifyAsset(MARKER_DIRECTION_ARROW);
        const { originPosition, destinationPosition } = getRoutesPositions(routeStart, routeEnd);
        const { originIcon, destinationIcon } = await getRoutesIcons(routeStart, routeEnd);
        directionsService.value
            ?.route({
            origin: originPosition,
            destination: destinationPosition,
            travelMode: google.maps.TravelMode.DRIVING,
        })
            .then(async (response) => {
            if (infoWindow.value) {
                infoWindow.value.close();
            }
            directionsRenderer.value?.setDirections(response);
            await initializeRouteMarkers({
                position: originPosition,
                icon: resolveRouteMarkerIcon(routeStart.directionAngle, directionMarker, routeStart.customIcon ?? originIcon),
                label: routeStart.label,
                labelFormatter: routeStart.labelFormatter,
                type: routeStart.type,
                directionAngle: routeStart.directionAngle,
            }, {
                position: destinationPosition,
                icon: resolveRouteMarkerIcon(routeEnd.directionAngle, directionMarker, destinationIcon),
                label: routeEnd.label,
                labelFormatter: routeEnd.labelFormatter,
                type: routeEnd.type,
                directionAngle: routeEnd.directionAngle,
            });
            infoWindow.value = createInfoWindow();
            infoWindow.value.open(map.value);
        })
            .catch((error) => console.error('Directions request failed', error));
    };
    return {
        initializeDirections,
        calculateAndDisplayRoute,
    };
};
