export var ImageSize;
(function (ImageSize) {
    ImageSize["ATTACHMENT"] = "360xauto";
    ImageSize["CARD"] = "260x260";
    ImageSize["THUMB"] = "50x50";
    ImageSize["THUMB_SM"] = "32x32";
})(ImageSize || (ImageSize = {}));
export var ImageShape;
(function (ImageShape) {
    ImageShape["SQUARED"] = "squared";
    ImageShape["ROUNDED"] = "rounded";
})(ImageShape || (ImageShape = {}));
