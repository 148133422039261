<template>
  <div class="flex flex-col">
    <div class="flex">
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        class="w-full mb-1"
      >
        <text-input
          v-model="formElement.title"
          class="flex-grow"
          :placeholder="$t('forms.title')"
          :error-message="errors[0]"
          :disabled="disabled"
          required
        />
      </ValidationProvider>
      <select-input
        v-model="option"
        class="ml-12 w-2/5"
        :options="options"
        option-label-name="name"
        option-value-name="type"
        selected-label="name"
        :disabled="disabled"
      />
    </div>
    <div
      v-if="formElement.type && formElement.type === 'text_input'"
      class="mt-4"
    >
      <ValidationProvider v-slot="{ errors }" rules="required" class="w-full">
        <text-input
          v-model="formElement.description"
          type="textarea"
          class="w-full"
          :error-message="errors[0]"
          :disabled="disabled"
          required
          autosize
        />
      </ValidationProvider>
    </div>
    <div
      v-if="formElement.type && formElement.type === 'radio_list'"
      class="mt-4"
    >
      <div
        v-for="(item, index) in formElement.items"
        :key="index"
        class="flex mb-5"
      >
        <radio-button name="radio" :disabled="disabled" />
        <ValidationProvider v-slot="{ errors }" rules="required" class="w-full">
          <text-input
            v-model="formElement.items[index]"
            class="w-full"
            :error-message="errors[0]"
            :disabled="disabled"
            required
          />
        </ValidationProvider>
        <button type="button" :disabled="disabled" @click="removeItem(index)">
          <icon
            v-if="index > 0"
            :icon="DeleteSvg"
            class="text-primary m-2 cursor-pointer"
          />
        </button>
      </div>
      <button type="button" :disabled="disabled" @click="addItem">
        <icon
          :icon="mdiPlusCircleOutline"
          class="text-primary mr-3 cursor-pointer"
        />
      </button>
    </div>
    <div
      v-if="formElement.type && formElement.type === 'checkbox_list'"
      class="mt-4"
    >
      <div
        v-for="(item, index) in formElement.items"
        :key="index"
        class="flex mb-5"
      >
        <checkbox name="checkbox" :disabled="disabled" />
        <ValidationProvider v-slot="{ errors }" rules="required" class="w-full">
          <text-input
            v-model="formElement.items[index]"
            class="w-full"
            :error-message="errors[0]"
            :disabled="disabled"
            required
          />
        </ValidationProvider>
        <button type="button" :disabled="disabled" @click="removeItem(index)">
          <icon
            v-if="index > 0"
            :icon="DeleteSvg"
            class="text-primary m-2 cursor-pointer"
          />
        </button>
      </div>
      <button type="button" :disabled="disabled" @click="addItem">
        <icon
          :icon="mdiPlusCircleOutline"
          class="text-primary mr-3 cursor-pointer"
        />
      </button>
    </div>
    <div class="mt-4 pt-3 border-t border-t-gray-light flex items-center">
      <button type="button" :disabled="disabled" @click="onDelete">
        <icon :icon="DeleteSvg" class="text-primary mr-3" />
      </button>
      <button type="button" :disabled="disabled" @click="onClone">
        <icon :icon="DocumentSvg" class="text-primary mr-6" />
      </button>
      <checkbox
        v-model="formElement.isRequired"
        size="sm"
        :disabled="disabled"
      />
      <p class="text-sm font-semibold">{{ $t('forms.required') }}</p>
    </div>
  </div>
</template>

<script>
import {
  TextInput,
  SelectInput,
  Checkbox,
  RadioButton,
} from '@/components/Inputs';
import { Icon } from '@/components/Icon';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mdiPlusCircleOutline } from '@mdi/js';
import DocumentSvg from '@/assets/icons/document.svg';
import DeleteSvg from '@/assets/icons/delete.svg';

export default {
  name: 'FormComponent',
  components: {
    SelectInput,
    TextInput,
    Icon,
    Checkbox,
    RadioButton,
    ValidationProvider,
  },
  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiPlusCircleOutline,
      DocumentSvg,
      DeleteSvg,
      options: [
        {
          type: 'text_input',
          name: this.$t('forms.text'),
        },
        {
          type: 'radio_list',
          name: this.$t('forms.single-choice'),
        },
        {
          type: 'checkbox_list',
          name: this.$t('forms.checkbox'),
        },
      ],
      formElement: this.component,
    };
  },
  computed: {
    option: {
      get: function () {
        return this.options.find((option) => {
          return option.type === this.formElement.type;
        });
      },
      set: function (newValue) {
        this.formElement.type = newValue.type;
        if (newValue.type === 'text_input') {
          delete this.formElement.items;
          this.formElement.description = '';
        } else {
          delete this.formElement.description;
          this.formElement.items = [null];
        }
      },
    },
  },
  watch: {
    formElement: {
      deep: true,
      handler(newValue) {
        this.$emit('updateComponent', { component: newValue });
      },
    },
    component(value) {
      this.formElement = value;
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  methods: {
    onDelete() {
      this.$emit('deleteComponent', this.formElement);
    },
    onClone() {
      this.$emit('cloneComponent', this.formElement);
    },
    updateComponent(data) {
      this.$emit('updateComponent', { component: this.formElement, data });
    },
    addItem() {
      this.formElement.items.push(null);
      this.formElement = { ...this.formElement }; // hax to trigger watcher
    },
    removeItem(idx) {
      this.formElement.items.splice(idx, 1);
      this.formElement = { ...this.formElement }; // hax to trigger watcher
    },
  },
};
</script>
