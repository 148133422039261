<template>
  <div class="side-navigation-wrapper">
    <sidebar
      v-if="isLeft && !mobileView"
      :show-custom-button="isCustomButton"
      :side-bar-width="sideBarWidth"
      side="left"
      :class="{ 'mb-22': footer }"
      @custom-button-click="onButtonClick"
    >
      <slot name="sideNavigation" :set-button="setButton" />
    </sidebar>
    <div
      class="with-sidebar"
      :class="{ wide: sideBarWidth === 328, 'mb-22': footer }"
      :style="{ width: `${viewWidth}%` }"
    >
      <slot :set-button="setButton" />
    </div>
    <sidebar
      v-if="isRight && !mobileView"
      :show-custom-button="isCustomButton"
      :side-bar-width="sideBarWidth"
      side="right"
      :class="{ 'mb-22': footer }"
      @custom-button-click="onButtonClick"
    >
      <slot name="sideNavigation" :set-button="setButton" />
    </sidebar>
    <overlap-sidebar
      v-if="mobileView"
      width="90%"
      :side="side"
      :is-open="isOpen"
      @close="toggleSidebar"
    >
      <slot name="sideNavigation" />
    </overlap-sidebar>
    <footer-menu v-if="footer">
      <slot name="footer"></slot>
    </footer-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Sidebar from '@/components/Sidebar/Sidebar.vue';
import FooterMenu from '@/components/Footer/FooterMenu';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar';

export default {
  name: 'WithSideNavigation',
  components: {
    FooterMenu,
    OverlapSidebar,
    Sidebar,
  },
  props: {
    sideBarWidth: {
      type: Number,
      default: 328,
    },
    viewWidth: {
      type: Number,
      default: null,
    },
    side: {
      type: String,
      default: '',
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRight: this.side === 'right',
      isLeft: this.side === 'left',
      isCustomButton: false,
      onButtonClick: () => {},
    };
  },
  computed: {
    ...mapState('ui', {
      isOpen: (state) => state.sidebar.isOpen,
      mobileView: (state) => state.mobileView.active,
    }),
  },
  methods: {
    ...mapActions('ui', ['toggleSidebar']),
    setButton({ show, onClick }) {
      this.isCustomButton = show;
      this.onButtonClick = onClick ? onClick : () => {};
    },
  },
};
</script>

<style lang="scss" scoped>
.side-navigation-wrapper {
  @apply flex h-full;
}

.with-sidebar {
  @apply pt-8 pb-8 px-4 flex-grow duration-500 ease-in overflow-auto;
  will-change: width;

  @screen md {
    @apply px-10;
  }
  @screen xxl {
    @apply px-12;
  }
}
</style>
