<template>
  <ValidationProvider v-slot="{ errors }" rules="required">
    <select-input
      id="localization"
      :value="computedValue"
      :options="localizationOptions"
      :label="$t('object-form.localization')"
      :placeholder="$t('common.select')"
      :error="!!errors[0]"
      :error-message="errors[0]"
      @input="$emit('input', $event ? $event.value : '')"
    />
  </ValidationProvider>
</template>
<script>
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { SelectInput } from '@/components/Inputs';
import { OBJECT_LOCALIZATION } from '@/constants/object';

export default {
  components: { ValidationProvider, SelectInput },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    localizationOptions() {
      return Object.values(OBJECT_LOCALIZATION).map((value) => ({
        value,
        label: this.$t(`object-form.${value}`),
      }));
    },
    computedValue() {
      return this.localizationOptions.find(({ value }) => value === this.value);
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
};
</script>
