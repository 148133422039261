import api from '@/api';
import { getPropertyArray } from '@/util/formatters';

export default {
  namespaced: true,
  state: {
    objectType: null,
    error: null,
    details: null,
    objectList: [],
  },
  mutations: {
    SET_OBJECT_TYPE(state, { data }) {
      state.objectType = data;
    },
    SET_ERROR(state) {
      state.error = true;
    },
    SET_OBJECT(state, { data }) {
      state.details = data;
      state.error = false;
    },
    SET_OBJECT_LIST(state, objects) {
      state.objectList = objects;
    },
  },
  getters: {
    currentObjectIndex: (state) =>
      state.details ? state.objectList.indexOf(state.details.id) : -1,
    nextObject: (state, getters) => {
      if (
        [-1, state.objectList.length - 1].includes(getters.currentObjectIndex)
      )
        return null;

      return state.objectList[getters.currentObjectIndex + 1];
    },
    prevObject: (state, getters) => {
      if ([-1, 0].includes(getters.currentObjectIndex)) return null;

      return state.objectList[getters.currentObjectIndex - 1];
    },
  },
  actions: {
    async addObjectType(ctx, data) {
      return await api.post(`/object-types`, data);
    },

    async fetchObjectTypeDetails({ commit }, id) {
      commit('SET_OBJECT_TYPE', await api.get(`/object-types/${id}`));
    },

    async deleteObjectTypeImage(ctx, id) {
      return await api.delete(`/object-types/${id}/image`);
    },

    async deleteObjectTypeIcon(ctx, id) {
      return await api.delete(`/object-types/${id}/icon`);
    },

    async updateObjectType(ctx, { objectTypeId, data }) {
      return await api.patch(`/object-types/${objectTypeId}`, data);
    },

    async deleteObjectType(ctx, id) {
      return await api.delete(`/object-types/${id}`);
    },

    async addObject(ctx, form) {
      return await api.post('objects', form);
    },

    updateObject(ctx, { objectId, data }) {
      return api.patch(`/objects/${objectId}`, data);
    },

    async deleteObjectImage(ctx, objectId) {
      await api.delete(`/objects/${objectId}/image`);
    },

    async fetchObjectDetails({ commit }, id) {
      commit('SET_OBJECT', { data: null });
      try {
        commit('SET_OBJECT', await api.get(`/objects/${id}`));
      } catch (err) {
        commit('SET_ERROR', 1);
      }
    },
    async fetchForm(ctx, id) {
      const { data } = await api.get(`forms/${id}`);

      return data;
    },
    clearObjectType({ commit }) {
      commit('SET_OBJECT_TYPE', { data: null });
    },
    clearObjectDetails({ commit }) {
      commit('SET_OBJECT', { data: null });
    },
    addObjectComment(ctx, { data, objectId }) {
      return api.post(`/objects/${objectId}/comments`, data);
    },
    async fetchObjectTypeForm({ dispatch }, objectTypeId) {
      const { data } = await api.get(`object-types/${objectTypeId}`);

      if (data.form) {
        return dispatch('fetchForm', data.form.id);
      }

      return null;
    },
    setObjectList({ commit }, objects) {
      commit('SET_OBJECT_LIST', getPropertyArray(objects, 'id'));
    },
  },
};
