import Vue from 'vue';
import { ref } from '@vue/composition-api';
import { Loader } from '@googlemaps/js-api-loader';
import { DEFAULT_MAP_POSITION, DEFAULT_MAP_ZOOM, DEFAULT_SINGLE_MARKER_ZOOM, MAX_MARKER_OFFSET, MIN_MARKER_OFFSET, } from '@/components/Map/constants/common';
import { MarkerClusterer, SuperClusterAlgorithm, } from '@googlemaps/markerclusterer';
import { rootOptions } from '@/main';
import InfoPopup from '@/components/Map/components/InfoPopup.vue';
import { ClusterMarkerRenderer } from '@/components/Map/utils/cluster-marker-renderer';
import { generateRandomNumber, getNestedValue } from '@/util/common';
import isEmpty from 'lodash.isempty';
import { dataSetTypeSettingMap } from '@/components/Map/constants';
import { ClusterRadius, MapEvent, MapZoomLevel, } from '@/components/Map/types';
import { useMapState } from '@/components/Map/composables/use-map-state';
import { useMarkerIcon } from '@/components/Map/composables/use-marker-icon';
import { createMarkerContent } from '@/components/Map/utils/create-marker-content';
import { getMarkerLatLng } from '@/components/Map/utils/get-marker-lat-lng';
export const useClusteredMap = () => {
    const map = ref(null);
    const isMapLoaded = ref(false);
    const markerClusterer = ref(null);
    const markers = ref(new Map());
    const highlightedMarker = ref(null);
    const activeWindowInfo = ref(null);
    const mapListenerHandle = ref(null);
    const markersListenersHandlers = ref([]);
    const clusterRadius = ref(ClusterRadius.MASSIVE);
    const visibleMarkers = ref({
        clustered: [],
        single: [],
    });
    const { resolveMarkerIcon, getPreparedMarkerIcons } = useMarkerIcon();
    const { settings } = useMapState();
    /**
     * Map
     */
    const loadMap = async () => {
        const loader = new Loader({
            apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        });
        await Promise.all([
            loader.importLibrary('maps'),
            loader.importLibrary('marker'),
            loader.importLibrary('places'),
        ]);
        isMapLoaded.value = true;
    };
    const initializeMap = async (id) => {
        await loadMap();
        const mapElement = document.getElementById(`map-${id}`);
        if (!mapElement) {
            throw new Error('Map element was not found');
        }
        map.value = new google.maps.Map(mapElement, {
            center: DEFAULT_MAP_POSITION,
            zoom: DEFAULT_MAP_ZOOM,
            mapTypeControl: false,
            mapId: `map-${id}`,
        });
    };
    const resolveClusterRadius = (zoom) => {
        switch (true) {
            case zoom >= MapZoomLevel.CITIES_100:
                return ClusterRadius.NONE;
            default:
                return ClusterRadius.MASSIVE;
        }
    };
    const setClusterRadius = () => {
        const zoom = map.value?.getZoom() || MapZoomLevel.COUNTRIES;
        clusterRadius.value = resolveClusterRadius(zoom);
    };
    const setMapListeners = () => {
        mapListenerHandle.value = map.value?.addListener(MapEvent.IDLE, () => {
            setClusterRadius();
            setupVisibleMarkers();
        });
    };
    const clearMapListeners = () => {
        if (mapListenerHandle.value) {
            google.maps.event.removeListener(mapListenerHandle.value);
            mapListenerHandle.value = null;
        }
    };
    /**
     * Map components
     */
    const createInfoWindow = (dataSet, markerDataItem) => {
        const infoWindowInstance = new google.maps.InfoWindow();
        const infoWindowComponent = Vue.extend(InfoPopup);
        const infoWindowComponentInstance = new infoWindowComponent({
            ...rootOptions,
            propsData: {
                type: dataSet.type,
                id: markerDataItem.id,
                hidePreviewButton: dataSet.hidePreviewButton,
                close: () => infoWindowInstance.close(),
            },
        });
        infoWindowComponentInstance.$mount();
        infoWindowInstance.setContent(infoWindowComponentInstance.$el);
        return infoWindowInstance;
    };
    /**
     * Map markers
     */
    const initializeMarkerClusterer = async () => {
        markerClusterer.value = new MarkerClusterer({
            algorithm: new SuperClusterAlgorithm({
                radius: clusterRadius.value,
            }),
            map: map.value,
            renderer: new ClusterMarkerRenderer(),
        });
    };
    const isMarkerInMapBounds = (marker) => {
        const bounds = map.value?.getBounds();
        if (!marker.position || !bounds) {
            return false;
        }
        return bounds.contains(marker.position);
    };
    const isMarkerPositionExists = (marker) => {
        const markerPosition = getMarkerLatLng(marker);
        if (markerPosition === null) {
            return false;
        }
        return visibleMarkers.value.single.some((addedMarker) => {
            const addedMarkerPosition = getMarkerLatLng(addedMarker);
            return (addedMarkerPosition !== null &&
                markerPosition.lat === addedMarkerPosition.lat &&
                markerPosition.lng === addedMarkerPosition.lng);
        });
    };
    const generatePositionWithOffset = (position) => {
        const offset = generateRandomNumber(MIN_MARKER_OFFSET, MAX_MARKER_OFFSET, 6);
        return {
            lat: position.lat + offset,
            lng: position.lng + offset,
        };
    };
    const setVisibleMarkers = () => {
        Array.from(markers.value.values()).map((markersSet) => markersSet
            .filter(({ marker }) => isMarkerInMapBounds(marker))
            .map(({ marker }) => {
            const markerPosition = getMarkerLatLng(marker);
            if (markerPosition && isMarkerPositionExists(marker)) {
                marker.position = generatePositionWithOffset(markerPosition);
            }
            if (clusterRadius.value === ClusterRadius.NONE) {
                return visibleMarkers.value.single.push(marker);
            }
            visibleMarkers.value.clustered.push(marker);
        }));
    };
    const highlightActiveMarker = (activeMarkerId, dataSets) => {
        if (!activeMarkerId) {
            return;
        }
        dataSets?.map(({ name, type }) => {
            markers.value.get(name || type)?.map((marker) => {
                if (marker.id === activeMarkerId) {
                    highlightedMarker.value = marker;
                }
            });
        });
        if (!highlightedMarker.value) {
            return;
        }
    };
    const cleanupVisibleMarkers = () => {
        visibleMarkers.value.single.map((marker) => {
            marker.map = null;
        });
        markerClusterer.value?.clearMarkers();
        visibleMarkers.value.clustered = [];
        visibleMarkers.value.single = [];
    };
    const placeVisibleMarkersOnMap = () => {
        visibleMarkers.value.single.map((singleMarker) => {
            singleMarker.map = map.value;
        });
        markerClusterer.value?.addMarkers(visibleMarkers.value.clustered);
    };
    const setupVisibleMarkers = () => {
        cleanupVisibleMarkers();
        setVisibleMarkers();
        placeVisibleMarkersOnMap();
    };
    const getMarkerDataItem = (fetchedData, id) => {
        if (fetchedData?.results) {
            return fetchedData?.results.find((result) => result.id === id);
        }
        return fetchedData.id === id ? fetchedData : null;
    };
    const clearMarkersListeners = () => {
        markersListenersHandlers.value.map((markerListenerHandle) => {
            google.maps.event.removeListener(markerListenerHandle);
        });
        markersListenersHandlers.value = [];
    };
    const injectInfoWindowInMarkers = (fetchedData, dataSet) => {
        markers.value.get(dataSet.name || dataSet.type)?.map(({ marker, id }) => {
            const markerDataItem = getMarkerDataItem(fetchedData, id);
            if (!markerDataItem) {
                return;
            }
            const markerListenerHandle = marker.addListener('click', () => {
                const infoWindow = createInfoWindow(dataSet, markerDataItem);
                if (activeWindowInfo.value) {
                    activeWindowInfo.value.close();
                }
                infoWindow.open({
                    anchor: marker,
                    map: map.value,
                });
                activeWindowInfo.value = infoWindow;
            });
            markersListenersHandlers.value = [
                ...markersListenersHandlers.value,
                markerListenerHandle,
            ];
        });
    };
    const getParsedDataSetItems = (fetchedData) => {
        if (fetchedData?.results) {
            return fetchedData.results?.map((fetchedItem) => fetchedItem);
        }
        return [fetchedData];
    };
    const getMarkerLabel = (mapDataSetItem, dataSet) => {
        const { labelAccessor, labelFormatter } = dataSet;
        if (!labelAccessor ||
            !settings.value.get(dataSetTypeSettingMap[dataSet.type])) {
            return null;
        }
        const label = getNestedValue(mapDataSetItem, labelAccessor).toString();
        return labelFormatter ? labelFormatter(label) : label.toString();
    };
    const createMarkerSet = async (fetchedData, dataSet) => {
        const mapDataSetItems = getParsedDataSetItems(fetchedData);
        const preparedIcons = await getPreparedMarkerIcons();
        return Promise.all(mapDataSetItems.map(async (mapDataSetItem) => {
            const { id, lat, lng } = mapDataSetItem;
            const marker = new google.maps.marker.AdvancedMarkerElement({
                position: {
                    lat,
                    lng,
                },
                content: createMarkerContent(resolveMarkerIcon(mapDataSetItem, dataSet, preparedIcons), getMarkerLabel(mapDataSetItem, dataSet)),
            });
            return {
                marker,
                id,
            };
        }));
    };
    const isSingleMarker = () => {
        const markersSetsArray = Array.from(markers.value.values());
        const singleMarkersSets = markersSetsArray.filter((markersSet) => markersSet.length === 1);
        return markersSetsArray.length == 1 && singleMarkersSets.length === 1;
    };
    const getMarkersSetsArray = () => {
        return Array.from(markers.value.values());
    };
    const centerMapToFitMultipleMarkers = (dataSet) => {
        const bounds = new google.maps.LatLngBounds();
        const filteredMarkers = Array.from(markers.value.entries())
            .filter(([key]) => {
            return dataSet.excludeFromCentring ? key !== dataSet.name : true;
        })
            .map(([, markers]) => markers)
            .flat();
        filteredMarkers.map(({ marker }) => {
            if (marker) {
                bounds.extend(marker.position);
            }
        });
        map.value?.fitBounds(bounds);
    };
    const centerMapToFitSingleMarker = () => {
        const markersSetsArray = getMarkersSetsArray();
        const position = markersSetsArray.flat()[0].marker.position;
        map.value?.setCenter(position);
        map.value?.setZoom(DEFAULT_SINGLE_MARKER_ZOOM);
    };
    const areMarkersSetsEmpty = () => {
        const markersSetsArray = getMarkersSetsArray();
        return markersSetsArray.every((markersSet) => isEmpty(markersSet));
    };
    const centerMap = (dataSet) => {
        if (areMarkersSetsEmpty()) {
            return;
        }
        if (isSingleMarker()) {
            return centerMapToFitSingleMarker();
        }
        centerMapToFitMultipleMarkers(dataSet);
    };
    const setMarkers = async ({ dataSet, fetchedMapDataSet, center = true, }) => {
        const markersKey = dataSet.name || dataSet.type;
        const markersSet = await createMarkerSet(fetchedMapDataSet, dataSet);
        markers.value.set(markersKey, markersSet);
        setClusterRadius();
        injectInfoWindowInMarkers(fetchedMapDataSet, dataSet);
        setVisibleMarkers();
        setupVisibleMarkers();
        if (center) {
            centerMap(dataSet);
        }
    };
    return {
        map,
        isMapLoaded,
        initializeMap,
        setMapListeners,
        clearMapListeners,
        setMarkers,
        initializeMarkerClusterer,
        setVisibleMarkers,
        clearMarkersListeners,
        highlightActiveMarker,
    };
};
