<template>
  <Modal :show="show" @update:show="$emit('close')">
    <h2 class="font-normal text-header pb-2">{{ title }}</h2>
    <p>{{ message }}</p>
    <div class="flex justify-center pt-4">
      <btn class="modal-button mr-4" @click="$emit('confirm')">
        {{ confirmBtnText || $t('common.yes') }}
      </btn>
      <btn
        class="modal-button"
        :theme="BTN_THEMES.TERTIARY"
        @click="$emit('close')"
      >
        {{ closeBtnText || $t('common.no') }}
      </btn>
    </div>
  </Modal>
</template>

<script>
import { BTN_THEMES } from '@/store/modules/ui';
import Modal from '@/components/Modal/__new__/Modal';
import Btn from '@/components/Button/Button';

export default {
  components: { Modal, Btn },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmBtnText: {
      type: String,
    },
    closeBtnText: {
      type: String,
    },
  },
  data() {
    return {
      BTN_THEMES,
    };
  },
};
</script>

<style scoped lang="scss">
.modal-button {
  @apply w-30 uppercase font-normal text-sm flex items-center;
}
</style>
