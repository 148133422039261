<template>
  <ul class="w-full h-auto">
    <li
      v-for="(item, i) in items"
      :key="item.id || i"
      class="item"
      :class="{ 'removed-item': deletedItemIds.includes(item.id) }"
    >
      <div class="item-fields">
        <div
          v-for="(field, j) in fields"
          :key="`field-${j}`"
          :class="field.class"
          class="item-field"
        >
          {{ getContent(item, field.key) }}
        </div>
      </div>
      <div v-if="!readOnly" class="button-wrapper">
        <button
          v-if="deletedItemIds.includes(item.id)"
          type="button"
          class="remove-button"
          @click="$emit('undo-remove', item)"
        >
          {{ $t('common.restore') }}
        </button>
        <button v-else type="button" @click="$emit('remove', item)">
          <icon :icon="mdiClose" :size="IconSize.XS" class="text-primary" />
        </button>
      </div>
    </li>
    <li v-if="noDataText && items.length === 0" class="no-results">
      {{ $t('common.no-results') }}
    </li>
  </ul>
</template>
<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';

export default {
  components: { Icon },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    deletedItemIds: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { IconSize, mdiClose };
  },
  methods: {
    getContent(item, key) {
      const keys = Array.isArray(key) ? key : [key];

      return keys.map((key) => item[key]).join(', ');
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  @apply pb-2 flex justify-between pr-2 text-sm items-center;

  .button-wrapper {
    @apply flex-grow-0 flex-shrink-0;

    button {
      @apply flex justify-center items-center;

      &.remove-button {
        @apply text-3xs;
      }
    }
  }

  &-fields {
    @apply flex flex-no-wrap overflow-hidden;
  }

  &-field {
    @apply whitespace-no-wrap overflow-hidden pr-1;
    text-overflow: ellipsis;
  }

  &.removed-item {
    @apply text-gray-dark;
  }
}

.no-results {
  @apply text-xs text-gray-dark italic text-center;
}
</style>
