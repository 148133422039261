import { MapDataType } from '@/components/Map/types';
export var MAP_SETTING_EVENT;
(function (MAP_SETTING_EVENT) {
    MAP_SETTING_EVENT["SET_MAP_STYLE"] = "set-map-style";
    MAP_SETTING_EVENT["SETTING_CHANGED"] = "settings-changed";
})(MAP_SETTING_EVENT || (MAP_SETTING_EVENT = {}));
export var MapSettings;
(function (MapSettings) {
    MapSettings["SHOW_OBJECT_NUMBER_LABEL"] = "show-object-number-label";
    MapSettings["SHOW_PLACE_NUMBER_LABEL"] = "show-place-number-label";
    MapSettings["SHOW_OBJECT_DIRECTION"] = "show-object-direction";
})(MapSettings || (MapSettings = {}));
export const dataSetTypeSettingMap = {
    [MapDataType.PLACE]: MapSettings.SHOW_PLACE_NUMBER_LABEL,
    [MapDataType.OBJECT]: MapSettings.SHOW_OBJECT_NUMBER_LABEL,
};
export const DEFAULT_MAP_SETTINGS_VALUES = [
    [MapSettings.SHOW_OBJECT_NUMBER_LABEL, true],
    [MapSettings.SHOW_PLACE_NUMBER_LABEL, true],
    [MapSettings.SHOW_OBJECT_DIRECTION, false],
];
