import { computed, defineComponent } from '@vue/composition-api';
import { TableStyle } from '@/components/Table/__new__';
import SortIcon from '@/components/Table/__new__/_components/SortIcon.vue';
import { SortDir } from '@/composables/use-sort';
class TableHeaderGeneric {
    define() {
        return defineComponent({
            components: { SortIcon },
            props: {
                column: {
                    type: Object,
                    required: true,
                },
                sort: {
                    type: Object,
                },
                last: {
                    type: Boolean,
                    default: false,
                },
                first: {
                    type: Boolean,
                    default: false,
                },
                tableStyle: String,
            },
            setup(props, ctx) {
                const onSort = () => {
                    if (props.column.sortable) {
                        ctx.emit('sort', props.column.key);
                    }
                };
                const defaultHeaderPadding = computed(() => {
                    if (props.tableStyle === TableStyle.Resources)
                        return 'px-2';
                    if (props.first)
                        return 'pl-4 pr-2';
                    if (props.last)
                        return 'pr-4 pl-2';
                    return 'px-3';
                });
                const showSortIcon = computed(() => {
                    return (!!props.column.sortable && props.sort?.field === props.column.key);
                });
                return {
                    defaultHeaderPadding,
                    SortDir,
                    onSort,
                    showSortIcon,
                };
            },
        });
    }
}
const main = new TableHeaderGeneric().define();
export function GenericTableHeader() {
    return main;
}
export default main;
