import { onMounted, onUnmounted, ref, getCurrentInstance, watch, } from '@vue/composition-api';
import { Editor } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Mention from '@tiptap/extension-mention';
import { suggestion } from '@/components/WysiwygInput/suggestion';
import { MENTION_CLASS_NAME } from '@/components/WysiwygInput/wysiwyg.constants';
import { isUrlValid } from '@/util/validation';
export const useTextEditor = (ctx, { content, readonly, placeholder, contentClass, getMentionSuggestions, }) => {
    const editor = ref(null);
    const mentionList = ref(null);
    onMounted(() => {
        const extensions = ref([StarterKit]);
        const instance = getCurrentInstance();
        if (!instance) {
            return;
        }
        if (placeholder) {
            extensions.value.push(Placeholder.configure({
                placeholder,
            }));
        }
        extensions.value.push(Link.configure({
            HTMLAttributes: {
                class: 'text-blue underline',
            },
            validate: (href) => isUrlValid(href),
        }));
        const { suggestionConfig, mentionComponent } = suggestion(instance.proxy, getMentionSuggestions);
        extensions.value.push(Mention.configure({
            HTMLAttributes: {
                class: MENTION_CLASS_NAME,
            },
            // @ts-ignore
            suggestion: suggestionConfig,
        }));
        mentionList.value = mentionComponent;
        mentionList.value.$on('mention', (e) => {
            ctx.emit('mention', e);
        });
        editor.value = new Editor({
            content: content.value,
            editable: !readonly.value,
            extensions: extensions.value,
            parseOptions: {
                preserveWhitespace: true,
            },
            editorProps: {
                attributes: {
                    class: contentClass.value,
                },
            },
        });
    });
    onUnmounted(() => {
        mentionList.value?.$destroy();
        editor.value?.destroy();
    });
    watch(content, () => {
        if (content.value !== editor.value?.getHTML()) {
            editor.value?.commands.setContent(content.value);
        }
    });
    watch(readonly, () => {
        editor.value?.setEditable(!readonly.value);
    });
    watch(contentClass, () => {
        editor.value?.setOptions({
            editorProps: { attributes: { class: contentClass.value } },
        });
    });
    return editor;
};
