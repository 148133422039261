import { getFullName } from '@/util/user';
import { createOfferDetailsRoute } from '@/router/utils';
import { handleTranslations, } from '@/layouts/_components/Notifications/_util/notification-factory';
export const handleOfferAssignedToReportNotification = (notification) => {
    const { type, payload: { brand, offer, report, user }, } = notification;
    return {
        getTranslations() {
            const translationPlaceholders = {
                offerTitle: offer.title,
                reportTitle: report.title,
                reportNumber: report.numberByBrand,
                user: getFullName(user),
            };
            return handleTranslations(type, translationPlaceholders);
        },
        getRoute() {
            return createOfferDetailsRoute(brand.id, offer.id);
        },
    };
};
