// eslint-disable-next-line no-useless-escape
export const fileNameRegex = /^[\w~`!@#$%^&*()+={}|),:;\[\] ?"'<>.\/\\_-]*$/;
export const multimediaExtensions = ['png', 'jpg', 'jpeg', 'gif'];
export const isMultimediaFile = (extension) => multimediaExtensions.includes(extension.toLowerCase());
export const getMIMESubtype = (mimeType) => mimeType.split('/')[1];
export const normalizePreviewFile = (file) => {
    return {
        id: file.id,
        // @ts-ignore
        name: file.file?.name || file.fileName || file.name,
        // @ts-ignore
        src: file.previewImage || file.url || file.src,
        // @ts-ignore
        timestamp: file.file?.lastModified || file.timestamp,
    };
};
export const getUploadTokens = (files) => {
    return files.map(({ url }) => {
        const urlElements = url.split('/');
        return urlElements[urlElements.length - 1];
    });
};
export const getFileDataUrl = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};
export const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex < 1) {
        return '';
    }
    return fileName.slice(lastDotIndex + 1);
};
export const isImageFile = (fileName) => {
    return isMultimediaFile(getFileExtension(fileName));
};
export const getFileUploadProgress = (loaded, total) => {
    return Math.round((loaded / total) * 100);
};
export const readFileAsText = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(new Error('Failed to read file'));
        };
        reader.readAsText(file);
    });
};
