<template>
  <div>
    <template v-if="showAdditionalFirstBtn">
      <button class="pagination-page-button mr-3" @click="changePage(1)">
        1
      </button>
      <span v-if="morePagesLeft" class="text-gray-dark">...</span>
    </template>

    <button
      v-for="(page, i) in pageBtns"
      :key="i"
      class="pagination-page-button"
      :class="{ active: page === currentPage }"
      @click="changePage(page)"
    >
      {{ page }}
    </button>

    <template v-if="showAdditionalLastBtn">
      <span v-if="morePagesRight" class="text-gray-dark">...</span>
      <button class="pagination-page-button" @click="changePage(pageCount)">
        {{ pageCount }}
      </button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    visiblePages() {
      return Math.min(this.pageCount, 5);
    },
    pageBtns() {
      const { rightBtns, leftBtns } = this.getPageBtns();

      return [...leftBtns, this.currentPage, ...rightBtns];
    },
    showAdditionalFirstBtn() {
      return !this.pageBtns.includes(1);
    },
    showAdditionalLastBtn() {
      return !this.pageBtns.includes(this.pageCount);
    },
    morePagesLeft() {
      return this.pageBtns.length && this.pageBtns[0] > 2;
    },
    morePagesRight() {
      return (
        this.pageBtns.length &&
        this.pageCount - this.pageBtns[this.visiblePages - 1] > 1
      );
    },
  },
  methods: {
    changePage(page) {
      if (page !== this.currentPage) {
        this.$emit('change-page', page);
      }
    },
    getPageBtns() {
      let leftBtns = Math.min(
        this.currentPage - 1,
        Math.floor((this.visiblePages - 1) / 2),
      );

      let rightBtns = this.visiblePages - 1 - leftBtns;

      const overBoundry = this.currentPage + rightBtns - this.pageCount;

      if (overBoundry > 0) {
        leftBtns += overBoundry;
        rightBtns -= overBoundry;
      }

      return {
        leftBtns: Array.from(
          { length: leftBtns },
          (_, i) => this.currentPage - leftBtns + i,
        ),
        rightBtns: Array.from(
          { length: rightBtns },
          (_, i) => this.currentPage + i + 1,
        ),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-page-button {
  @apply outline-none text-gray-dark mx-2 text-sm;

  &.active {
    @apply text-gray-darkest;
  }
}
</style>
