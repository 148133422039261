<template>
  <div id="app">
    <router-view />
    <portal-target name="modals"></portal-target>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { clearCookies } from '@/util/browser';

export default {
  computed: {
    ...mapState('routes', ['previousRoutes']),
    ...mapState('user', {
      userId: 'id',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      isLogged: 'isLogged',
    }),
    ...mapGetters('azure', ['azureAuthEnabled']),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
  },
  watch: {
    $route() {
      this.setSidebar(!this.mobileView);
    },
  },
  mounted() {
    if (this.azureAuthEnabled) {
      this.setupAzureIntegration();
      this.setAzureUser();
    }

    this.setView();

    if (!this.isBrowserDataCleared()) {
      clearCookies();
      localStorage.clear();
      this.setBrowserCleared();
      this.clearState();
    }

    window.addEventListener('resize', this.setView);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setView);
  },
  methods: {
    ...mapActions('ui', ['setMobileView', 'setSidebar']),
    ...mapActions('user', ['setBrowserCleared', 'clearState']),
    ...mapGetters('user', ['isBrowserDataCleared']),
    ...mapActions('azure', ['setAzureUser', 'setupAzureIntegration']),
    setView() {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight / 100}px`,
      );
      this.setMobileView(window.innerWidth);
    },
  },
};
</script>
