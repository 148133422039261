export var MenuItems;
(function (MenuItems) {
    MenuItems["REPORTS"] = "reports";
})(MenuItems || (MenuItems = {}));
export var ReportFilters;
(function (ReportFilters) {
    ReportFilters["SEARCH"] = "q";
    ReportFilters["SORT_BY"] = "sortBy";
    ReportFilters["CREATOR"] = "creatorId";
    ReportFilters["PLACES"] = "placeIds";
    ReportFilters["CATEGORY"] = "categoryId";
    ReportFilters["STATUS"] = "status";
    ReportFilters["SUPERVISOR"] = "supervisorId";
    ReportFilters["IS_STARRED"] = "isStarred";
})(ReportFilters || (ReportFilters = {}));
export var ReportMenuItem;
(function (ReportMenuItem) {
    ReportMenuItem["ALL"] = "all";
    ReportMenuItem["MY_TASKS"] = "my-tasks";
    ReportMenuItem["FINISHED"] = "finished";
    ReportMenuItem["ACTION_REQUIRED"] = "action-required";
})(ReportMenuItem || (ReportMenuItem = {}));
export const REPORTS_LIST_ROUTE_NAME = 'Reports';
