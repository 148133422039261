import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: String,
        name: {
            type: String,
            default: 'radio',
        },
        id: String,
        checked: Boolean,
        disabled: Boolean,
        error: Boolean,
        size: {
            type: String,
            default: 'md',
        },
    },
});
