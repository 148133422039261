<template>
  <article>
    <div
      class="flex gap-4 items-center w-full"
      :class="tabsPositionClassMap[position]"
    >
      <slot name="prepend-header"></slot>
      <header class="tabs w-full" :class="{ compressed: compressedHeader }">
        <ul class="tabs-list">
          <li v-for="(tab, index) in tabs" :key="index">
            <div
              class="tabs-item"
              :class="[
                { 'is-active': tab.isActive },
                compressedHeader ? 'compressed' : 'default',
              ]"
              @click="selectTab(tab, index)"
            >
              <span v-if="tab.shortName && mobileView">
                {{ tab.shortName }}
              </span>
              <span v-else>{{ tab.name }}</span>
            </div>
          </li>
        </ul>
      </header>
      <slot name="append-header"></slot>
    </div>

    <section>
      <slot></slot>
    </section>
  </article>
</template>

<script>
import { mapState } from 'vuex';
import { TabsPosition, tabsPositionClassMap } from './tabs.constants';

export default {
  props: {
    value: {
      type: String,
      default: null,
      required: false,
    },
    position: {
      type: String,
      default: TabsPosition.LEFT,
    },
    compressedHeader: Boolean,
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
  },
  watch: {
    value() {
      this.setActiveTab();
    },
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    if (this.value) this.setActiveTab();
  },
  methods: {
    setActiveTab() {
      const selectedIndex = this.tabs.findIndex(
        ({ reference }) => reference === this.value,
      );

      if (selectedIndex !== -1)
        this.selectTab(this.tabs[selectedIndex], selectedIndex);
    },
    selectTab(selectedTab, index) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name;
      });

      this.$emit('select-tab', index, selectedTab);
      this.$emit('input', selectedTab.reference);
    },
  },
  setup() {
    return { TabsPosition, tabsPositionClassMap };
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  @apply border-b border-gray-dark flex items-end justify-between;

  &.compressed {
    width: min-content;
  }
}

.tabs-list {
  @apply flex gap-8;

  @screen md {
    @apply flex gap-0;
  }
}

.tabs-item {
  @apply py-0.5 h-full relative cursor-pointer text-gray-dark font-semibold uppercase whitespace-no-wrap;

  &.default {
    @apply text-sm;
    @screen md {
      @apply mr-8;
    }
    @screen lg {
      @apply mr-10;
    }
  }

  &.compressed {
    @apply text-xxs;

    @screen md {
      @apply px-4;
    }
    @screen lg {
      @apply px-5;
    }
  }
}

.is-active {
  @apply text-primary;
  &::after {
    @apply absolute -bottom-1px left-0 right-0 w-full bg-primary h-0.5;
    content: '';
  }
}

.add-button {
  @apply mb-5 w-26;
}
</style>
