<template>
  <div class="flex items-end mb-4">
    <ValidationProvider
      v-slot="{ errors }"
      rules="required|decimal"
      class="w-1/2 mr-4"
    >
      <text-input
        v-model.number="lat"
        :label="$t('object-form.gps')"
        :helper-label="
          helperText ? `(${$t('object-form.gps-helper-text')})` : ''
        "
        :placeholder="$t('object-form.lat-placeholder')"
        :error-message="errors[0]"
        :disabled="isLoading"
        type="number"
        min="-90"
        max="90"
        step="any"
        required
        debounce
        @focus="$emit('set-tab', COORDINATES_TAB)"
        @input="emitObjectCoordinates"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      rules="required|decimal"
      class="w-1/2"
    >
      <text-input
        v-model.number="lng"
        :placeholder="$t('object-form.lng-placeholder')"
        :error-message="errors[0]"
        :disabled="isLoading"
        type="number"
        min="-180"
        max="180"
        step="any"
        required
        debounce
        @focus="$emit('set-tab', COORDINATES_TAB)"
        @input="emitObjectCoordinates"
      />
    </ValidationProvider>
  </div>
</template>
<script>
import { COORDINATES_TAB } from '@/store/modules/ui';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { TextInput } from '@/components/Inputs';
import { decimalRegex } from '@/util/form';
import { COORDINATES_DECIMAL_PLACES } from '@/constants/map';

export default {
  components: { ValidationProvider, TextInput },
  props: {
    value: {
      type: Object,
      default: () => ({
        lat: null,
        lng: null,
      }),
    },
    isLoading: Boolean,
    clear: Boolean,
    helperText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      COORDINATES_TAB,
      lat: null,
      lng: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.lat = value.lat || null;
          this.lng = value.lng || null;
        }
      },
    },
    clear(value) {
      if (value) {
        this.lat = null;
        this.lng = null;

        this.emitObjectCoordinates();
      }
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty').toString(),
    });
    extend('decimal', {
      validate(value) {
        return decimalRegex(COORDINATES_DECIMAL_PLACES).test(value.toString());
      },
      message: this.$t('errors.error-decimal').toString(),
    });
  },
  methods: {
    getCoordinateValue(coordinate) {
      if (typeof coordinate !== 'number' || isNaN(coordinate)) return null;

      return parseFloat(coordinate);
    },
    emitObjectCoordinates() {
      const payload = {
        lat: this.getCoordinateValue(this.lat),
        lng: this.getCoordinateValue(this.lng),
      };
      this.$emit('input', payload);
    },
  },
};
</script>
