import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'NoResults',
    props: {
        message: {
            type: String,
            default: '',
        },
    },
});
