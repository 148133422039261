<template>
  <button
    class="status-wrapper"
    :disabled="disabled"
    :class="[{ compact }, compactVariant]"
    @click="$emit('click', $event)"
  >
    <span :class="color" class="dot"></span>
    <span v-if="!compact" class="status" :class="textClass">{{ status }}</span>
  </button>
</template>

<script>
import { StatusButtonVariant } from '@/components/StatusToggler/StatusToggler.constants';

export default {
  props: {
    status: {
      type: String,
      default: 'active',
    },
    color: {
      type: String,
      default: 'bg-blue-dark',
    },
    textClass: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    compactVariant: {
      type: String,
      default: StatusButtonVariant.OUTLINE,
    },
    compact: Boolean,
  },
};
</script>

<style scoped lang="scss">
.status-wrapper {
  @apply font-normal text-3xs text-center rounded-full cursor-pointer flex items-center;
  padding: 0 6px;

  &.outline {
    @apply border border-gray w-22 h-6 bg-white;
  }

  &.simple {
    @apply w-3;

    .dot {
      @apply w-2.5 h-2.5;
    }
  }

  &:disabled {
    @apply cursor-default;
  }

  .status {
    @apply block text-ellipsis whitespace-no-wrap overflow-hidden text-center flex-grow flex-shrink leading-4;
  }

  &.compact {
    @apply w-6 p-0 justify-center;
  }
}

.dot {
  @apply rounded-full flex-shrink-0 flex-grow-0 w-3 h-3;
}
</style>
