<template>
  <div class="filters-container">
    <filter-group v-slot="{ resetFilters, filtersApplied }" v-model="filters">
      <div class="flex items-center justify-between">
        <h1 class="text-lg font-semibold">{{ $t('common.filter') }}</h1>
        <btn
          v-if="filtersApplied"
          theme="secondary"
          class="w-26"
          :disabled="disabled"
          @click="resetFilters"
        >
          {{ $t('common.reset') }}
        </btn>
      </div>
      <filter-wrapper
        :parameter="FILTER_PARAMS.STRUCTURE"
        :value="filters[FILTER_PARAMS.STRUCTURE]"
      >
        <select-input
          id="structureId"
          v-model="filters[FILTER_PARAMS.STRUCTURE]"
          url="structures"
          :label="$t('filters.structure')"
          :query="{ brandId }"
          :placeholder="$t('filters.structure-placeholder')"
          option-label-name="name"
          option-value-name="id"
          class="mt-2 mb-5"
          autocomplete
          :disabled="disabled"
          clearable
        />
      </filter-wrapper>
      <filter-wrapper
        :parameter="FILTER_PARAMS.USER"
        :value="filters[FILTER_PARAMS.USER]"
      >
        <select-input
          id="userId"
          v-model="filters[FILTER_PARAMS.USER]"
          :disabled="disabled"
          url="users"
          :label="$t('filters.place-admin')"
          :query="{
            brandId,
            systemRoles: [SYSTEM_ROLES.PLACE_MANAGER],
          }"
          :placeholder="$t('filters.place-admin-placeholder')"
          autocomplete
          clearable
          option-value-name="id"
          class="mt-2 mb-5"
        >
          <template #selection="{ selection }">
            <span>{{ selection.firstName }} {{ selection.lastName }}</span>
          </template>

          <template #option="{ option }">
            <span>{{ option.firstName }} {{ option.lastName }}</span>
          </template>
        </select-input>
      </filter-wrapper>
      <filter-wrapper
        :parameter="FILTER_PARAMS.TAGS"
        :value="filters[FILTER_PARAMS.TAGS]"
      >
        <select-input
          v-model="filters[FILTER_PARAMS.TAGS]"
          :disabled="disabled"
          :query="{ brandId, relation: 'place' }"
          :placeholder="$t('filters.tags-placeholder')"
          :label="$t('filters.tags')"
          url="tags"
          class="mt-2"
          autocomplete
          multiple
          option-value-name="id"
          option-label-name="name"
        />
      </filter-wrapper>
    </filter-group>
  </div>
</template>

<script>
import FilterGroup from '@/components/Filters/FilterGroup.vue';
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import Btn from '@/components/Button/Button';
import { PLACE_ADMIN, SYSTEM_ROLES } from '@/store/modules/user';
import { mapGetters } from 'vuex';
import { FILTER_PARAMS } from '@/constants/filters';
import { SelectInput } from '@/components/Inputs';

export default {
  name: 'PlacesSidebar',
  props: {
    disabled: Boolean,
  },
  components: {
    SelectInput,
    FilterGroup,
    FilterWrapper,
    Btn,
  },
  data() {
    return {
      FILTER_PARAMS,
      filters: {
        [FILTER_PARAMS.SEARCH]: '',
        [FILTER_PARAMS.STRUCTURE]: null,
        [FILTER_PARAMS.TAGS]: [],
        [FILTER_PARAMS.SORT_BY]: null,
      },
      PLACE_ADMIN,
      SYSTEM_ROLES,
    };
  },
  computed: {
    ...mapGetters('brand', ['currentBrandId']),
    brandId() {
      return this.currentBrandId(this.$route);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-container {
  @apply px-4 pt-8 h-full;
  max-height: 100%;

  @screen md {
    @apply px-6;
  }

  @screen lg {
    @apply px-6;
  }
}
</style>
