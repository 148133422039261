<template>
  <modal :show="show" @update:show="$emit('update:show', $event)">
    <h2>{{ content.title }}</h2>
    <p>{{ content.description }}</p>
    <slot />
    <div class="flex pb-1">
      <btn class="mt-4 w-24 mr-6" @click="content.btns.yes">
        {{ $t('common.yes') }}
      </btn>
      <btn theme="secondary" class="mt-4 w-24" @click="content.btns.no">
        {{ $t('common.no') }}
      </btn>
    </div>
  </modal>
</template>

<script>
import Btn from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal.vue';

export default {
  components: {
    Modal,
    Btn,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>
