import api from '@/api';
export const getObjectType = async (id) => {
    const { data } = await api.get(`object-types/${id}`);
    return data;
};
export const getObjectTypeByBudget = async (params) => {
    const { data } = await api.get(`object-types/by-expenses`, {
        params,
    });
    return data;
};
