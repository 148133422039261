import { defineComponent, ref, watch } from '@vue/composition-api';
import { SystemRole } from '@/api/requests/users';
import { useBrand } from '@/composables';
import { EMPTY_PLACE_FILTERS, } from '@/views/Brand/Applications/Components/Resources/resources.constants';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { SelectInput } from '@/components/Inputs';
export default defineComponent({
    components: { SelectInput },
    props: {
        value: {
            type: Object,
            default: () => ({
                ...EMPTY_PLACE_FILTERS,
            }),
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const placeFilters = ref(cloneDeep(props.value));
        watch(placeFilters, () => ctx.emit('input', cloneDeep(placeFilters.value)), { deep: true });
        watch(() => props.value, (value, prevValue) => {
            // Equality check to prevent emitting input event due to changed reference & causing infinite loop
            if (!isEqual(value, prevValue))
                placeFilters.value = cloneDeep(value);
        });
        return {
            SystemRole,
            placeFilters,
            brandId: brand.value?.id,
        };
    },
});
