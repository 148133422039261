var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{ref:"popupOverlay",staticClass:"popup-overlay",class:{ 'items-center': _vm.centered },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.close.apply(null, arguments)}}},[(!_vm.isLoaded)?_c('loading-spinner',{staticClass:"justify-center w-full flex",attrs:{"is-loading":!_vm.isLoaded}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}],class:[_vm.type, _vm.isMobileFullScreen ? 'h-full' : 'h-auto', 'popup']},[_c('div',{ref:"popupInner",class:[
        _vm.isMobileFullScreen ? 'inner-full-screen' : 'inner',
        _vm.variant === 'default' ? 'p-6' : '',
        _vm.variant === 'secondary' ? 'p-0 overflow-hidden rounded-lg mt-30' : '' ]},[_c('button',{class:[
          'close focus:outline-none absolute top-0 right-0',
          { 'z-10': _vm.alwaysOnTopCloseBtn },
          _vm.closeBtnClass,
          _vm.variant === 'secondary' ? 'py-3 pr-4' : 'p-2 md:p-4' ],on:{"click":_vm.close}},[_c('icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.mdiClose}})],1),_vm._t("default")],2)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }