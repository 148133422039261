import { mapActions, mapGetters } from 'vuex';
import { getIntValue } from '@/util/filters';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '@/constants/pagination';
import VueStore from '@/store';

export default {
  computed: {
    ...mapGetters('routes', { savedQuery: 'routeQuery' }),
    routeQuery() {
      return this.savedQuery(this.$route);
    },
  },
  beforeRouteEnter(to, form, next) {
    const { page, limit } = to.query;

    VueStore.dispatch('routes/setRoutePagination', {
      route: to,
      pagination: {
        page: getIntValue(page, DEFAULT_PAGE),
        limit: getIntValue(limit, DEFAULT_LIMIT),
      },
      updatePath: false,
    });
    next();
  },
  methods: {
    ...mapActions('routes', ['setRoutePagination']),
    selectLimit(limit) {
      this.setRoutePagination({ route: this.$route, pagination: { limit } });
    },
  },
};
