import { defineComponent, ref, watch } from '@vue/composition-api';
import Checkbox from './Checkbox.vue';
import isEmpty from 'lodash.isempty';
import { CheckboxTheme } from '@/components/Inputs';
export default defineComponent({
    components: { Checkbox },
    props: {
        selectedItems: {
            type: Array,
            default: () => [],
        },
        listItems: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const indeterminate = ref(false);
        const allSelected = ref(false);
        const setSelectionState = () => {
            const { selectedItems, listItems } = props;
            if (isEmpty(selectedItems)) {
                indeterminate.value = false;
                allSelected.value = false;
                return;
            }
            if (listItems?.length !== selectedItems?.length) {
                indeterminate.value = listItems?.length !== selectedItems?.length;
                allSelected.value = false;
                return;
            }
            allSelected.value = true;
        };
        watch(() => props.selectedItems, setSelectionState);
        return { indeterminate, allSelected, CheckboxTheme };
    },
});
