<template>
  <with-side-navigations
    :view-width="50"
    :side-bar-width="50"
    side="right"
    footer
  >
    <template #sideNavigation="{ setButton }">
      <div>
        <tabs v-model="activeTab">
          <template v-if="userFormOpened">
            <tab :name="$t('edit-place-view.add-user')" :reference="USER_TAB">
              <add-user-tab
                ref="userForm"
                @close-user-tab="closeUserTab(setButton)"
                @add-user="onAddUser"
              />
            </tab>
          </template>
          <template v-else>
            <tab
              :name="$t('edit-place-view.coordinates')"
              :reference="COORDINATES_TAB"
            >
              <clustered-map
                v-model="coordinates"
                interactive
                :interactive-marker="{ icon: DEFAULT_PLACE_MARKER }"
                :disabled-settings="[
                  MapSettings.SHOW_OBJECT_NUMBER_LABEL,
                  MapSettings.SHOW_PLACE_NUMBER_LABEL,
                ]"
              />
            </tab>
            <tab
              v-if="attachments.multimedia"
              :name="$t('edit-place-view.multimedia')"
              :reference="MULTIMEDIA_TAB"
            >
              <file-uploader
                :list="multimedia"
                type="multimedia"
                target="places"
              />
            </tab>
            <tab
              v-if="attachments.document"
              :name="$t('edit-place-view.documents')"
              :reference="DOCUMENTS_TAB"
            >
              <file-uploader :list="docs" type="document" target="places" />
            </tab>
          </template>
        </tabs>
      </div>
    </template>
    <template #default="{ setButton }">
      <div class="container-fluid bg-neutral">
        <breadcrumbs
          :path="[
            { name: $t('edit-place-view.places'), url: placesRoute },
            { name: brand ? brand.name : '', url: placesRoute },
            { name: $t('edit-place-view.edit-place') },
          ]"
          class="mt-2 mb-1"
        ></breadcrumbs>

        <place-form
          v-if="place"
          id="placeForm"
          :users-list-key="usersListKey"
          :brand="brand"
          :content="computedPlace"
          :clear="placeSubmissionEndSuccessfully"
          :coordinates="coordinates"
          @on-submit="handlePlaceSubmission"
          @open-user-form="openUserForm(setButton)"
          @set-tab="activeTab = $event"
          @set-coordinates="coordinates = $event"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="placeSubmissionInProgress"
            type="submit"
            form="placeForm"
            class="w-48 mr-3"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.push(placesRoute)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </template>
  </with-side-navigations>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import PlaceForm from '@/views/Brand/Places/Forms/PlaceForm.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import FileUploader from '@/components/Uploader/FileUploader.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';
import {
  COORDINATES_TAB,
  DOCUMENTS_TAB,
  MULTIMEDIA_TAB,
  USER_TAB,
} from '@/store/modules/ui';
import mapValues from 'lodash.mapvalues';
import AddUserTab from '@/views/Brand/Places/Components/AddUserTab';
import manageTagsMixin from '@/mixins/manageTagsMixin';
import { ClusteredMap, MapSettings } from '@/components/Map';
import { DEFAULT_PLACE_MARKER } from '@/components/Map/constants/common';

export default {
  name: 'EditPlace',
  components: {
    ClusteredMap,
    Breadcrumbs,
    Btn,
    PlaceForm,
    Tabs,
    Tab,
    WithSideNavigations,
    FileUploader,
    AddUserTab,
  },
  mixins: [manageTagsMixin],
  data() {
    return {
      placeSubmissionInProgress: false,
      placeSubmissionEndSuccessfully: false,
      userFormOpened: false,
      newUser: null,
      usersListKey: 1,
      activeTab: COORDINATES_TAB,
      coordinates: null,
      COORDINATES_TAB,
      MULTIMEDIA_TAB,
      DOCUMENTS_TAB,
      USER_TAB,
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
      place: 'place',
    }),
    ...mapState('attachment', ['uploadQueue', 'deleteQueue', 'attachments']),
    computedPlace() {
      return { ...this.place, ...(this.newUser && { user: this.newUser }) };
    },
    placesRoute() {
      return this.brand
        ? { name: 'Places', params: { id: this.brand.id } }
        : {};
    },
    docs() {
      return [...this.uploadQueue, ...this.attachments.document];
    },
    multimedia() {
      return [...this.uploadQueue, ...this.attachments.multimedia];
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch('brand/fetchBrandPlaceDetails', to.params['placeId']),
      VueStore.dispatch('attachment/clearUploadQueue'),
      VueStore.dispatch('attachment/clearDeleteQueue'),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params['placeId'],
        target: 'places',
        type: 'document',
      }),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params['placeId'],
        target: 'places',
        type: 'multimedia',
      }),
    ]);

    next();
  },
  watch: {
    place: {
      immediate: true,
      handler({ lng, lat }) {
        this.coordinates = { lat, lng };
      },
    },
  },
  methods: {
    ...mapActions('brand', ['updateBrandPlace', 'deleteBrandPlaceImage']),
    ...mapActions('attachment', ['saveAttachments', 'deleteAttachments']),
    ...mapActions('ui', ['setSidebar']),
    ...mapActions('tags', ['addPlaceTag', 'deletePlaceTag']),
    openUserForm(setButton) {
      this.setSidebar(true);
      setButton({
        show: true,
        onClick: () => {
          this.$refs.userForm.closeUserForm();
        },
      });
      this.userFormOpened = true;

      this.$nextTick(() => {
        this.activeTab = USER_TAB;
      });
    },
    closeUserTab(setButton) {
      this.userFormOpened = false;
      this.activeTab = COORDINATES_TAB;
      this.showConfirmationModal = false;

      setButton({ show: false });
    },
    onAddUser(user) {
      this.newUser = user;
      this.usersListKey += 1;
    },
    async saveTags(tagsToDelete = [], tagsToSave = []) {
      const promises = [
        ...tagsToDelete.map(({ id }) => {
          return this.deletePlaceTag({
            placeId: this.place.id,
            tagId: id,
          });
        }),
        ...tagsToSave.map(({ name }) => {
          return this.addPlaceTag({
            placeId: this.place.id,
            tagName: name,
          });
        }),
      ];

      await Promise.all(promises);
    },
    async handlePlaceSubmission(formData, { tagsToDelete, tagsToSave }) {
      this.placeSubmissionEndSuccessfully = false;
      this.placeSubmissionInProgress = true;
      try {
        if (this.place.imageUrl && !formData.imageUrl) {
          await this.deleteBrandPlaceImage(this.place.id);
        }

        await this.updateBrandPlace({
          placeId: this.place.id,
          data: mapValues(formData, (field) => (field === '' ? null : field)),
        });

        if (this.uploadQueue.length) {
          await this.saveAttachments({
            id: this.place.id,
          });
        }

        if (this.deleteQueue.length) await this.deleteAttachments();

        await this.saveTags(tagsToDelete, tagsToSave);

        this.$toast.success(this.$t('edit-place-view.updated-successfully'));
        this.$router.push(this.placesRoute);

        this.placeSubmissionEndSuccessfully = true;
      } finally {
        this.placeSubmissionInProgress = false;
      }
    },
  },
  setup() {
    return { DEFAULT_PLACE_MARKER, MapSettings };
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
