export var MapDataType;
(function (MapDataType) {
    MapDataType["OBJECT"] = "object";
    MapDataType["PLACE"] = "place";
})(MapDataType || (MapDataType = {}));
export var MapEvent;
(function (MapEvent) {
    MapEvent["BOUNDS_CHANGED"] = "bounds_changed";
    MapEvent["CENTER_CHANGED"] = "center_changed";
    MapEvent["CLICK"] = "click";
    MapEvent["CONTEXTMENU"] = "contextmenu";
    MapEvent["DBLCLICK"] = "dblclick";
    MapEvent["DRAG"] = "drag";
    MapEvent["DRAGEND"] = "dragend";
    MapEvent["DRAGSTART"] = "dragstart";
    MapEvent["HEADING_CHANGED"] = "heading_changed";
    MapEvent["IDLE"] = "idle";
    MapEvent["MAPTYPEID_CHANGED"] = "maptypeid_changed";
    MapEvent["MOUSEMOVE"] = "mousemove";
    MapEvent["MOUSEOUT"] = "mouseout";
    MapEvent["MOUSEOVER"] = "mouseover";
    MapEvent["PROJECTION_CHANGED"] = "projection_changed";
    MapEvent["RESIZE"] = "resize";
    MapEvent["RIGHTCLICK"] = "rightclick";
    MapEvent["TILESLOADED"] = "tilesloaded";
    MapEvent["TILT_CHANGED"] = "tilt_changed";
    MapEvent["ZOOM_CHANGED"] = "zoom_changed";
})(MapEvent || (MapEvent = {}));
export var MapZoomLevel;
(function (MapZoomLevel) {
    MapZoomLevel[MapZoomLevel["WORLD"] = 0] = "WORLD";
    MapZoomLevel[MapZoomLevel["CONTINENTS"] = 1] = "CONTINENTS";
    MapZoomLevel[MapZoomLevel["REGIONS"] = 4] = "REGIONS";
    MapZoomLevel[MapZoomLevel["COUNTRIES"] = 7] = "COUNTRIES";
    MapZoomLevel[MapZoomLevel["PROVINCES"] = 10] = "PROVINCES";
    MapZoomLevel[MapZoomLevel["CITIES_100"] = 11] = "CITIES_100";
    MapZoomLevel[MapZoomLevel["CITIES_50"] = 12] = "CITIES_50";
    MapZoomLevel[MapZoomLevel["DISTRICTS"] = 13] = "DISTRICTS";
    MapZoomLevel[MapZoomLevel["STREETS"] = 16] = "STREETS";
    MapZoomLevel[MapZoomLevel["BUILDINGS_ROOMS"] = 19] = "BUILDINGS_ROOMS";
})(MapZoomLevel || (MapZoomLevel = {}));
export var ClusterRadius;
(function (ClusterRadius) {
    ClusterRadius[ClusterRadius["NONE"] = 0] = "NONE";
    ClusterRadius[ClusterRadius["TINY"] = 10] = "TINY";
    ClusterRadius[ClusterRadius["SMALL"] = 25] = "SMALL";
    ClusterRadius[ClusterRadius["MEDIUM"] = 50] = "MEDIUM";
    ClusterRadius[ClusterRadius["LARGE"] = 75] = "LARGE";
    ClusterRadius[ClusterRadius["HUGE"] = 100] = "HUGE";
    ClusterRadius[ClusterRadius["MASSIVE"] = 150] = "MASSIVE";
})(ClusterRadius || (ClusterRadius = {}));
export var SearchBoxEvent;
(function (SearchBoxEvent) {
    SearchBoxEvent["PLACES_CHANGED"] = "places_changed";
    SearchBoxEvent["PLACE_CHANGED"] = "place_changed";
    SearchBoxEvent["SEARCH"] = "search";
    SearchBoxEvent["INPUT"] = "input";
    SearchBoxEvent["FOCUS"] = "focus";
    SearchBoxEvent["BLUR"] = "blur";
    SearchBoxEvent["KEYDOWN"] = "keydown";
    SearchBoxEvent["KEYUP"] = "keyup";
    SearchBoxEvent["CLEAR"] = "clear";
})(SearchBoxEvent || (SearchBoxEvent = {}));
