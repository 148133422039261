import { defineComponent } from '@vue/composition-api';
import { useAuthUser, useMobileView } from '@/composables';
import { mdiTrayArrowDown } from '@mdi/js';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { Btn, BtnTheme } from '@/components/Btn';
export default defineComponent({
    components: { Btn, FilterToggler },
    props: {
        actions: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: String,
            required: true,
        },
    },
    setup() {
        const { isMobileView } = useMobileView();
        const { isAdmin, isObjektoAdmin } = useAuthUser();
        return {
            isMobileView,
            isAdmin,
            isObjektoAdmin,
            mdiTrayArrowDown,
            BtnTheme,
        };
    },
});
