import { mapActions, mapState } from 'vuex';
import { mdiMenuDown, mdiMenuRight } from '@mdi/js';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    places: {
      type: Array,
      default: () => [],
    },
    coordinator: {
      type: Object,
      default: null,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nodes: [],
      pagination: null,
      isLoading: false,
      error: false,
      showChildren: true,
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    ...mapState('user', {
      userBrand: 'brand',
    }),
    brandId() {
      return this.$route.params.id || this.userBrand;
    },
    marginBase() {
      return this.mobileView ? 16 : 32;
    },
    indent() {
      return { marginLeft: `${this.depth * this.marginBase}px` };
    },
    indentAddNew() {
      return { marginLeft: `${(this.depth + 1) * this.marginBase}px` };
    },
    indentList() {
      return { marginLeft: `${(this.depth + 1) * this.marginBase + 16}px` };
    },
    iconName() {
      return this.showChildren ? mdiMenuDown : mdiMenuRight;
    },
    tooltipLabel() {
      return `${this.coordinator.firstName} ${this.coordinator.lastName}`;
    },
    computedParams() {
      return {
        limit: 20,
        parentId: this.parentId,
        ...(this.pagination && { page: this.pagination.currentPage + 1 }),
        ...(this.search && { q: this.search }),
      };
    },
  },
  mounted() {
    if (this.hasChildren) {
      this.fetchStructures();
    }
  },
  methods: {
    ...mapActions('structures', ['fetchChildStructures']),
    async fetchStructures() {
      this.isLoading = true;
      this.error = false;

      try {
        const { data } = await this.fetchChildStructures({
          brandId: this.selectedBrandId || this.brandId,
          params: this.computedParams,
        });

        this.nodes = [...this.nodes, ...data.results];
        this.pagination = data.pagination;
      } catch (err) {
        this.error = err;
      }
      this.isLoading = false;
    },
  },
  watch: {
    search() {
      this.fetchStructures();
    },
  },
};
