var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden md:flex gap-2 mb-0.5 ml-8"},[_c('tooltip',{attrs:{"label":_vm.$t('tooltip-hints.previous-report')}},[_c('navigation-arrow',{staticClass:"px-1",attrs:{"size":_vm.BtnSize.SM,"direction":_vm.CONTROLLER_DIRECTION.PREV,"disabled":!_vm.reports.prev,"to":{
        name: _vm.ClientRouteName.REPORT_DETAILS,
        params: { reportId: _vm.reports.prev },
        query: _vm.query,
      }}})],1),_c('tooltip',{attrs:{"label":_vm.$t('tooltip-hints.next-report')}},[_c('navigation-arrow',{staticClass:"px-1",attrs:{"size":_vm.BtnSize.SM,"direction":_vm.CONTROLLER_DIRECTION.NEXT,"disabled":!_vm.reports.next,"to":{
        name: _vm.ClientRouteName.REPORT_DETAILS,
        params: { reportId: _vm.reports.next },
        query: _vm.query,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }