<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    shortName: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler(value) {
        this.isActive = value;
      },
    },
  },
};
</script>
