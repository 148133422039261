<template>
  <div class="search">
    <input
      v-debounce-input="(e) => $emit('input', e.target.value)"
      :value="value"
      class="search-input"
      :class="value ? 'pr-6' : 'pr-4'"
      type="search"
    />
    <button
      v-if="value"
      type="button"
      class="reset-button"
      @click="$emit('input', '')"
    >
      <icon :icon="mdiClose" :size="IconSize.XXS" class="text-primary" />
    </button>
  </div>
</template>
<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose } from '@mdi/js';

export default {
  components: { Icon },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return { IconSize, mdiClose };
  },
};
</script>
<style scoped lang="scss">
.search {
  @apply w-full mr-0 my-2 ml-4 relative;

  &-input {
    @apply h-10 rounded-r pl-4 py-2 overflow-x-hidden whitespace-no-wrap w-full border border-gray-light rounded-3xl h-6 text-sm flex-grow text-ellipsis;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      @apply hidden;
    }

    &:disabled {
      @apply bg-white;
    }

    &:focus {
      @apply outline-none border border-gray-darkest;
    }
  }

  .reset-button {
    @apply absolute right-0 mr-2;
  }
}
</style>
