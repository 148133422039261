import api from '@/api';

export const fetchAllItems = async (url, params = {}, fetched = []) => {
  const { data } = await api.get(url, {
    params: { ...params, limit: params.limit ?? 100 },
  });

  const {
    results,
    pagination: { currentPage, to, total },
  } = data;

  const fetchedData = [...fetched, ...results];

  if (to >= total) {
    return fetchedData;
  }

  return fetchAllItems(url, { ...params, page: currentPage + 1 }, fetchedData);
};
