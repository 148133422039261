import { useBrand } from '@/composables';
import { computed, defineComponent } from '@vue/composition-api';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';
export default defineComponent({
    components: { ClusteredMap },
    props: {
        offerId: {
            type: String,
            required: true,
        },
        activeMarkerId: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const { brand } = useBrand();
        const dataSets = computed(() => {
            return [
                {
                    name: 'offer-objects',
                    url: 'objects/map',
                    query: {
                        brandId: brand.value?.id,
                        offerId: props.offerId,
                    },
                    type: MapDataType.OBJECT,
                    fetchAll: true,
                    labelAccessor: ['internalNumber'],
                    labelFormatter: (label) => `#${label}`,
                },
                {
                    name: 'places',
                    url: 'places',
                    query: {
                        isPositionDefined: true,
                        brandId: brand.value?.id,
                        offerId: props.offerId,
                    },
                    type: MapDataType.PLACE,
                    fetchAll: true,
                    labelAccessor: ['number'],
                    labelFormatter: (label) => `#${label}`,
                },
                {
                    name: 'hardcoded-objects',
                    url: 'objects/map-hardcoded',
                    query: {
                        brandId: brand.value?.id,
                    },
                    type: MapDataType.OBJECT,
                    fetchAll: true,
                    excludeFromCentring: true,
                    labelAccessor: ['internalNumber'],
                    labelFormatter: (label) => `#${label}`,
                },
            ];
        });
        return { dataSets };
    },
});
