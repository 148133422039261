import { defineComponent } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import { baseInputProps } from '@/components/Inputs/_components/BaseInput/base-input.constants';
import InputLabel from '@/components/Inputs/_components/InputLabel/InputLabel.vue';
export default defineComponent({
    name: 'BaseInput',
    components: { InputLabel, Icon },
    props: {
        ...baseInputProps,
    },
});
