<template>
  <ul :class="subnav ? 'sub-nav' : 'nav-items'">
    <li
      v-for="(item, i) in items"
      :key="i"
      :class="{
        'bg-gray-lighter':
          backgroundOnActive && !subnav && isActive(item, $route),
      }"
    >
      <nav-item
        :to="item.to"
        :active="isActive(item, $route) || (item.subnav && activeIndex === i)"
        :subnav="subnav"
        :active-sub="subnav && isActive(item, $route)"
        :category="!!item.subnav"
        @click.native="handleItemClick(item, i)"
      >
        {{ $t(`${localeView}.${item.label}`) }}
      </nav-item>
      <ul v-if="mobileView && item.subnav && activeIndex === i">
        <li v-for="(subItem, j) in item.subnav" :key="j">
          <nav-item
            :to="subItem.to"
            :active-sub="isActive(subItem, $route)"
            subnav
            @click.native="$emit('close')"
          >
            {{ $t(`${localeView}.${subItem.label}`) }}
          </nav-item>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import NavItem from './NavItem.vue';
import { mapState } from 'vuex';

export default {
  components: {
    NavItem,
  },
  props: {
    activeCategory: {
      type: Number,
      default: -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Function,
      default: (item, route) =>
        route.name === item.to || route.meta.matchName === item.to,
    },
    subnav: {
      type: Boolean,
      default: false,
    },
    localeView: {
      type: String,
      default: 'navbar',
    },
    backgroundOnActive: Boolean,
  },
  data() {
    return {
      activeIndex: this.activeCategory,
    };
  },
  computed: mapState('ui', { mobileView: (state) => state.mobileView.active }),
  methods: {
    handleItemClick(item, index) {
      if (!this.mobileView) return;

      this.activeIndex = index;

      if (!item.subnav) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-items {
  @screen md {
    @apply h-16 flex overflow-hidden;
  }

  li {
    @apply min-w-0 mx-2;

    @screen md {
      @apply mx-0 overflow-x-hidden;
    }
  }
}

.sub-nav {
  @apply h-12 w-screen left-0 top-16 flex justify-center flex-wrap bg-primary text-white;
}
</style>
