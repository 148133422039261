import Vue from 'vue';
import VueI18n from 'vue-i18n';
import getBrowserLocale from '@/util/i18n/get-browser-locale';
import { supportedLocalesInclude } from './util/i18n/supported-locales';
import { enUS, pl } from 'date-fns/locale';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || 'en';
  }
}

const i18n = new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  pluralizationRules: {
    /**
     *  E.g. pluralization implementation in translation json:
     * "minute": "{n} minut | {n} minutę | {n} minuty",
     */
    pl: function (choice) {
      switch (true) {
        case choice === 0 || choice >= 5:
          return 0;
        case choice === 1:
          return 1;
        case choice >= 2 && choice <= 4:
          return 2;
        default:
          return 0;
      }
    },
  },
});

const dateFnsLocale = { en: enUS, pl };

export const getDateFnsLocale = () => dateFnsLocale[i18n.locale];

export default i18n;
