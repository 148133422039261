import { Icon } from '@/components/Icon';
import { defineComponent, onMounted, onUpdated, ref, watch, } from '@vue/composition-api';
import { ScrollDirection } from '@/constants/scroll';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
export default defineComponent({
    components: { Icon },
    props: {
        shouldUpdate: {
            type: Number,
            default: 0,
        },
        scrollOffset: {
            type: Number,
            default: 120,
        },
        light: {
            type: Boolean,
            default: false,
        },
        neutral: {
            type: Boolean,
            default: false,
        },
        noBorder: Boolean,
    },
    setup(props, ctx) {
        const enableScrollRight = ref(false);
        const enableScrollLeft = ref(false);
        const tolerance = ref(1);
        const wrapperRef = ref(null);
        const checkScroll = () => {
            if (wrapperRef.value) {
                const { offsetWidth, scrollWidth, scrollLeft } = wrapperRef.value;
                enableScrollRight.value =
                    scrollLeft + offsetWidth < scrollWidth - tolerance.value;
                enableScrollLeft.value = scrollLeft > 0;
            }
            else {
                enableScrollRight.value = false;
                enableScrollLeft.value = false;
            }
        };
        const scroll = (dir, event) => {
            event.stopPropagation();
            const { scrollWidth, scrollLeft, offsetWidth } = wrapperRef.value;
            const offset = scrollLeft + dir * props.scrollOffset;
            const newPosition = dir === ScrollDirection.RIGHT
                ? Math.min(scrollWidth - offsetWidth, offset)
                : Math.max(0, offset);
            wrapperRef.value.scrollTo({
                left: newPosition,
                behavior: 'smooth',
            });
            ctx.root.$nextTick(checkScroll);
        };
        watch(() => props.shouldUpdate, checkScroll);
        watch(enableScrollRight, (value) => {
            if (!value)
                ctx.emit('scroll-end');
        });
        onUpdated(checkScroll);
        onMounted(checkScroll);
        return {
            mdiChevronLeft,
            mdiChevronRight,
            enableScrollRight,
            enableScrollLeft,
            scroll,
            checkScroll,
            ScrollDirection,
            wrapperRef,
        };
    },
});
