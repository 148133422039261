import { Modal } from '@/components/Modal';
import { computed, defineComponent, onMounted, ref, } from '@vue/composition-api';
import { useMobileView, useObjects } from '@/composables';
import { useAttachment } from '@/composables/use-attachment';
import ObjectDetails from '@/views/Brand/Objects/ObjectDetails.vue';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { ObjectDetailsPlacePreview } from '@/views/Brand/Objects/_constants';
export default defineComponent({
    components: { LoadingSpinner, ObjectDetails, Modal },
    props: {
        object: {
            type: Object,
            required: true,
        },
        show: Boolean,
    },
    setup(props, ctx) {
        const { isMobileView } = useMobileView();
        const { fetchObjectTypeDetails, fetchObjectDetails } = useObjects();
        const { fetchAttachments } = useAttachment(ctx);
        const loading = ref(true);
        const modalWidth = computed(() => {
            if (isMobileView.value) {
                return '100vw';
            }
            return loading.value ? '50rem' : 'auto';
        });
        onMounted(async () => {
            try {
                loading.value = true;
                await Promise.all([
                    fetchAttachments({
                        id: props.object.id,
                        target: 'objects',
                        type: 'document',
                    }),
                    fetchAttachments({
                        id: props.object.id,
                        target: 'objects',
                        type: 'multimedia',
                    }),
                    fetchObjectDetails(props.object.id),
                    fetchObjectTypeDetails(props.object.objectTypeId),
                ]);
            }
            finally {
                loading.value = false;
            }
        });
        return {
            ObjectDetailsPlacePreview,
            modalWidth,
            isMobileView,
            loading,
        };
    },
});
