var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'div',{tag:"component",staticClass:"cell",class:[
    {
      last: _vm.lastInRow,
      first: _vm.firstInRow,
      number: _vm.isNumberCell,
      expanded: _vm.expanded,
      'cursor-pointer': _vm.isRowClickable,
    },
    _vm.rowSize,
    _vm.tableStyle,
    _vm.defaultCellPadding,
    _vm.column.cellClass ],attrs:{"to":_vm.to ? _vm.to(_vm.row) : null,"role":"cell"}},[_c('span',{staticClass:"cell-inner"},[_vm._t(((_vm.column.key) + "Cell"),null,{"row":_vm.row,"isRowHovered":_vm.isRowHovered,"value":_vm.row[_vm.column.key]}),(!_vm.column.hideDefaultContent)?_c('span',[_vm._v(_vm._s(_vm.cellContent))]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }