<template>
  <Modal :show="show" @update:show="$emit('close')">
    <h2 class="text-header">{{ $t('add-task.all-categories') }}</h2>
    <InfiniteData
      url="/categories"
      :query="{
        brandId: brand.id,
        parentId,
        hasDescendantWithForm: true,
        limit: 20,
      }"
      :container-selector="`#${id}`"
      custom-spinner
    >
      <template #default="{ items }">
        <div :id="id" class="h-42 overflow-auto mb-6">
          <category-list
            :categories="items"
            class="border-none"
            @select="$emit('select', $event)"
          />
        </div>
      </template>

      <template #spinner>
        <div class="w-full flex justify-center">
          <loading-spinner is-loading class="h-10 w-10" />
        </div>
      </template>
    </InfiniteData>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal/__new__/Modal';
import CategoryList from '@/components/FormElements/CategoryList';
import InfiniteData from '@/components/DataProvider/InfiniteData';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';

export default {
  components: { InfiniteData, CategoryList, Modal, LoadingSpinner },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
  },
};
</script>
