import Modal from '@/components/Modal/__new__/Modal.vue';
import { TextInput } from '@/components/Inputs';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { mdiMagnify, mdiChevronLeft } from '@mdi/js';
import UserOption from '@/views/Brand/Applications/Components/Recipients/UserOption.vue';
import { Icon } from '@/components/Icon';
import { BASE_INPUT_STYLE } from '@/components/Inputs/_components/BaseInput';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { Debounce } from '@/constants/debounce';
import { useAuthUser, useBrand } from '@/composables';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
export default defineComponent({
    components: { LoadingSpinner, FetchData, Icon, UserOption, TextInput, Modal },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, ctx) {
        const search = ref('');
        const { brand } = useBrand();
        const { reportId } = ctx.root.$route.params;
        const { authUserId } = useAuthUser();
        const { updatesCounter, update } = useUpdatesCounter();
        const usersQuery = computed(() => {
            return {
                ...(reportId && { reportId: reportId }),
                accessToBrandId: brand.value.id,
                omitUserIds: [authUserId.value],
                q: search.value,
                limit: 20,
            };
        });
        const handleSelectUser = (user) => {
            ctx.emit('set-user', user);
            ctx.emit('input', false);
        };
        watch(search, () => {
            update();
        });
        return {
            mdiMagnify,
            mdiChevronLeft,
            search,
            BASE_INPUT_STYLE,
            Debounce,
            usersQuery,
            updatesCounter,
            handleSelectUser,
        };
    },
});
