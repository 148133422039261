<template>
  <button class="focus:outline-none" @click="$emit('input', !value)">
    <icon :icon="iconName" class="text-primary" :size="iconSize" />
  </button>
</template>

<script>
import { Icon, IconSize } from '@/components/Icon';
import { mdiClose, mdiMenu } from '@mdi/js';

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: IconSize.XL,
    },
  },
  data() {
    return {
      mdiClose,
      mdiMenu,
    };
  },
  computed: {
    iconName() {
      return this.value ? mdiClose : mdiMenu;
    },
  },
};
</script>
