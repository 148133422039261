import { defineComponent } from '@vue/composition-api';
import { BtnShape, BtnSize, BtnTheme } from '@/components/Btn/btn.constants';
import { Icon, IconSize } from '@/components/Icon';
import { Btn } from '@/components/Btn';
export default defineComponent({
    name: 'FloatingActionButton',
    components: {
        Btn,
        Icon,
    },
    props: {
        positionClass: {
            type: String,
            default: 'right-4 bottom-4',
        },
        icon: String,
        shape: {
            type: String,
            default: BtnShape.ROUNDED,
        },
        size: {
            type: String,
            default: BtnSize.XL,
        },
        theme: {
            type: String,
            default: BtnTheme.PRIMARY,
        },
    },
    setup() {
        return { BtnTheme, IconSize };
    },
});
