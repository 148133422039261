import { defineComponent } from '@vue/composition-api';
import { getFullName } from '@/util/user';
import { getUserAvatar } from '@/util/avatars';
import { ImageView } from '@/components/ImageView';
export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    components: { ImageView },
    setup() {
        return {
            getUserAvatar,
            getFullName,
        };
    },
});
