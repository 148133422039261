import { onMounted, ref, } from '@vue/composition-api';
import debounce from 'lodash.debounce';
import { isInViewport } from '@/util/dom';
export const useRequiredMessageViewportListener = (ctx) => {
    const actionRequiredElement = ref(null);
    const scrollContainerElement = ref(null);
    const isActionRequiredInViewport = ref(false);
    onMounted(() => {
        scrollContainerElement.value?.$el.addEventListener('scroll', debounce(() => {
            if (!actionRequiredElement.value || !scrollContainerElement.value) {
                return;
            }
            isActionRequiredInViewport.value = isInViewport(actionRequiredElement.value.$el, scrollContainerElement.value.$el);
            ctx.emit('required-message-in-viewport', isActionRequiredInViewport.value);
        }, 500));
    });
    return {
        isActionRequiredInViewport,
        actionRequiredElement,
        scrollContainerElement,
    };
};
