var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',[_c('portal',{attrs:{"to":_vm.PortalTarget.SIDEBAR}},[_c('object-types-sidebar')],1),_c('div',{staticClass:"flex justify-between pb-10"},[_c('div',{staticClass:"flex-grow"},[_c('div',{staticClass:"flex mb-2"},[_c('breadcrumbs',{staticClass:"mt-2 mb-1",attrs:{"path":[
            {
              name: _vm.$t('object-types-view.heading'),
              url: { name: 'ObjectTypes' },
            } ]}}),_c('filter-toggler',{staticClass:"mr-4"})],1),_c('fetch-data',{attrs:{"url":"object-types","params":_vm.computedQuery,"force-update":_vm.listUpdatesCounter,"debounce":_vm.FILTER_DEBOUNCE},on:{"data-deliver":_vm.updateList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isLoading = ref.isLoading;
          var pagination = ref.pagination;
return [_c('div',{staticClass:"container"},[(isLoading)?_c('div',{staticClass:"spinner-area"},[_c('icon-spinner',{staticClass:"spinner stroke-primary",attrs:{"width":"64px","height":"64px"}})],1):_vm._e(),(_vm.objectTypesList.length)?_c('div',{staticClass:"flex flex-wrap",class:{ 'opacity-50': isLoading }},_vm._l((_vm.objectTypesList),function(objectType){return _c('card',{key:objectType.id,staticClass:"mt-4 mr-4",attrs:{"image":objectType.cdnImageUrl,"alt":_vm.$t('object-types-view.object-type-alt', {
                  name: objectType.name,
                }),"cover-img":"","link":{
                name: 'Objects',
                query: { objectTypeIds: [objectType.id] },
              }}},[_vm._v(" "+_vm._s(objectType.name)+" ")])}),1):_vm._e(),_c('div',{staticClass:"flex w-full my-3 justify-center"},[(_vm.objectTypesList.length)?_c('pagination',{staticClass:"mt-2",attrs:{"page-count":_vm.lastPage,"current-page":_vm.routeQuery.page || 1},on:{"change-page":_vm.changePage}}):_vm._e()],1),(pagination)?_c('entries-counter',{attrs:{"url":"object-types","to":pagination.to,"from":pagination.from,"filtered-entries":pagination.total,"initial-query":{ brandId: _vm.brandId }}}):_vm._e()],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }