import { SystemRole } from '@/api/requests/users';
import { EmailNotificationSettingType } from '@/api/requests/notifications';
import { filterRolesByExclusion } from '@/util/user';
export const getExcludedEmailNotificationAccessRoles = (type) => {
    switch (type) {
        case EmailNotificationSettingType.PLACE_CREATED:
            return [
                SystemRole.PLACE_MANAGER,
                SystemRole.STRUCTURE_MANAGER,
                SystemRole.CONTRACTOR,
            ];
        case EmailNotificationSettingType.UPDATED_OFFER_STATUS:
            return filterRolesByExclusion([SystemRole.CONTRACTOR]);
        case EmailNotificationSettingType.OBJECT_ACCEPTED:
            return filterRolesByExclusion([SystemRole.CONTRACTOR]);
        case EmailNotificationSettingType.OBJECT_REJECTED:
            return filterRolesByExclusion([SystemRole.CONTRACTOR]);
        case EmailNotificationSettingType.OBJECT_REVERTED:
            return filterRolesByExclusion([SystemRole.CONTRACTOR]);
        default:
            return [];
    }
};
